const FORM_WIDTH = 960;
const FOOTER_HEIGHT = 64;

export const styles = theme => ({
  paper: {
    width: "800px",
    padding: "24px",
    overflowY: "visible"
  },
  formGrid: {
    padding: "0 24px 24px 24px"
  },
  helperText: {
    margin: 0,
    position: "absolute",
    bottom: -15,
    color: theme.palette.primary.light,
    height: "1em"
  },
  helperTextError: {
    color: "red !important"
  },
  actionButtons: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "24px 24px 0 0"
  },
  inputSuffix: {
    "& p": {
      width: "max-content"
    }
  },
  formContent: {
    overflowY: "visible"
  },
  actionsBlock: {
    display: "flex",
    flexDirection: "column",
    maxWidth: 40,
    justifyContent: "space-between",
    margin: "-24px 0 -36px -40px"
  },
  formWrapper: {
    display: "flex",
    padding: "24px 0 36px 12px",
    maxWidth: 800
  },
  pageFormWrapper: {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    overflow: "hidden",
    overflowY: "auto"
  },
  pageFormContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflow: "auto",
    paddingTop: 16,
    height: "auto"
  },
  pageFormContent: {
    width: "100%",
    maxWidth: FORM_WIDTH
  },
  pageFormFooter: {
    display: "flex",
    width: "100%",
    height: FOOTER_HEIGHT,
    justifyContent: "center",
    alignItems: "flex-start",
    position: "relative",
    background: "none",
    boxShadow: "none",
    paddingRight: 0
  },
  pageFormActions: {
    display: "flex",
    width: "100%",
    maxWidth: FORM_WIDTH,
    paddingRight: 8,
    justifyContent: "flex-end"
  }
});
