import React from "react";

import CloseIcon from "@material-ui/icons/Close";
import { DckActionCreators } from "dck-redux";
import { useSelector } from "react-redux";

import {
  ActionCreators,
  isEmptyObject,
  Selectors,
  useDispatchActions
} from "srx-shared";
import { ActionButton } from "srx-shared/mui";

import { WeightHistory } from "@items/types";

import { WeightHistoryItem } from "../weightHistoryItem";

const actions = {
  loadWeightHistory: () => DckActionCreators.itemsLoad(WeightHistory),
  clearFilters: () => DckActionCreators.clearItemSearchFilters(WeightHistory),
  resetSelectedFilter: field => ActionCreators.setSelectedFilter(field, false),
  setParams: (field, data) =>
    DckActionCreators.setItemData(WeightHistory, field, data)
};

const params = ["cellIndex", "smartShelfId", "distributorId"];

export function ClearFilters() {
  const { loadWeightHistory, clearFilters, resetSelectedFilter, setParams } =
    useDispatchActions(actions);

  const filters = useSelector(state =>
    Selectors.selectItemSearchFilters(state, WeightHistory)
  );
  const cell = useSelector(state =>
    Selectors.getItemData(state, WeightHistory, "cellIndex")
  );
  const distributorId = useSelector(state =>
    Selectors.getItemData(state, WeightHistory, "distributorId")
  );

  const disabled = Boolean(isEmptyObject(filters) && !cell && !distributorId);

  const handleClick = () => {
    clearFilters();
    params.forEach(param => setParams(param, null));
    WeightHistoryItem.filters.forEach(el =>
      resetSelectedFilter(el.field, false)
    );
    loadWeightHistory();
  };

  return (
    <ActionButton
      icon={CloseIcon}
      title="Clear Filters"
      onClick={handleClick}
      disabled={disabled}
    />
  );
}
