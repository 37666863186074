/* eslint no-template-curly-in-string: "off" */
import * as V from "yup";

import { mapFormFieldsFor } from "srx-shared";

import { ShipToTemplateSettings } from "@items/types";
import { getAvoidDotsError } from "@utils";

import { InfoMessage, LabelWithTooltip } from "../Common/Messages";

V.addMethod(V.number, "avoidDots", function () {
  return this.transform(getAvoidDotsError);
});

const isExpirationAlarmOn = form => Boolean(!form.values.enableExpirationAlarm);
const isAutoExpireOn = form => Boolean(!form.values.enableAutoExpire);

export const LotSerializationSettingsItem = {
  type: ShipToTemplateSettings,
  fieldsConfig: {
    infoMessage: {
      Component: InfoMessage
    },
    enableExpirationAlarm: {
      label: (
        <LabelWithTooltip
          title="The software will notify about the expiration of a serial number within the number of days specified in the Days Until Expiration count down"
          label="Expiration Alarm"
        />
      ),
      size: 6,
      type: "switch",
      noHelperText: true,
      onChange: (value, form) => {
        if (value === false && !form.values["daysUntilExpirationAlarm"])
          form.setFieldValue("daysUntilExpirationAlarm", 0, true);
      }
    },
    daysUntilExpirationAlarm: {
      label: "Days Until Expiration",
      type: "number",
      checkDisabled: isExpirationAlarmOn,
      NumberProps: {
        min: 0,
        max: 9999999,
        integer: true
      }
    },
    enableAutoExpire: {
      label: (
        <LabelWithTooltip
          title="The software will automatically change serial numbers status from Available to Expired within the number of days specified in the Days Until Expiration count down"
          label="Enable Auto-Expire"
        />
      ),
      size: 6,
      type: "switch",
      noHelperText: true,
      onChange: (value, form) => {
        if (value === false && !form.values["daysUntilAutoExpiration"])
          form.setFieldValue("daysUntilAutoExpiration", 0, true);
      }
    },
    daysUntilAutoExpiration: {
      label: "Days Until Expiration",
      type: "number",
      checkDisabled: isAutoExpireOn,
      NumberProps: {
        min: 0,
        max: 9999999,
        integer: true
      }
    }
  },
  schema: V.object({
    daysUntilAutoExpiration: V.number()
      .label("Days Until Expiration")
      .typeError("${label} must be a integer")
      .required(),
    daysUntilExpirationAlarm: V.number()
      .label("Days Until Expiration")
      .typeError("${label} must be a integer")
      .required()
  }),

  fields: [
    "enableExpirationAlarm",
    "daysUntilExpirationAlarm",
    "enableAutoExpire",
    "daysUntilAutoExpiration",
    "infoMessage"
  ]
};

LotSerializationSettingsItem.formConfig = mapFormFieldsFor(
  LotSerializationSettingsItem
);
