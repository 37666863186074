import PropTypes from "prop-types";

import { downloadFileFromServer } from "../../api";
import { ActionButton } from "./ActionButton";

const DownloadButton = ({ sampleFileName, labelForTemplateButton }) => {
  return sampleFileName ? (
    <ActionButton
      title={labelForTemplateButton}
      variant="template"
      onClick={() => {
        downloadFileFromServer(
          `${process.env.PUBLIC_URL}/import_templates/${sampleFileName}-template.csv`
        );
      }}
    />
  ) : null;
};

DownloadButton.propTypes = {
  sampleFileName: PropTypes.string,
  labelForTemplateButton: PropTypes.string
};

export default DownloadButton;
