import PropTypes from "prop-types";
import { useMutation } from "react-query";
import { useDispatch } from "react-redux";

import { DckActionCreators } from "../../redux";
import { SideBarItem } from "../SideBarItem";

SupportButton.propTypes = {
  support: PropTypes.object,
  open: PropTypes.bool
};

export function SupportButton(props) {
  const { support, open } = props;
  const { getSalesForceLink, ...rest } = support;

  const dispatch = useDispatch();

  const handleSuccess = ({ data }) => window.open(data, "_blank");

  const handleError = error =>
    dispatch(DckActionCreators.asyncProcessStop("", { error }));

  const mutation = useMutation(getSalesForceLink, {
    onSuccess: handleSuccess,
    onError: handleError
  });

  const handleClick = () => {
    //FIXME: SRX-23476: Temporary change link
    //mutation.mutate();
    window.open("https://storeroomlogix.force.com/support/s/", "_blank");
  };

  return (
    <SideBarItem id="support" open={open} onClick={handleClick} {...rest} />
  );
}
