import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  root: {
    margin: 5,
    border: "1px solid white",
    padding: 4,
    width: 514,
    height: 76
  },
  content: {
    height: "100%"
  },
  notAssigned: {
    fontSize: 13,
    color: "#AAAAAA",
    textAlign: "center"
  },
  label: {
    fontSize: 10,
    color: "#777",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "noWrap"
  },
  text: {
    marginTop: 8,
    fontSize: 14,
    color: "#333",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "noWrap"
  },
  linkButton: {
    justifyContent: "flex-end",
    display: "flex",
    width: "100%"
  },
  openDoor: {
    color: ({ open }) => (open ? "#4CAF50" : "#F44336"),
    backgroundColor: ({ open }) => (open ? "#E4F3E5" : "#FEE3E1"),
    borderRadius: 2,
    display: "flex",
    padding: "3px",
    justifyContent: "center"
  }
});
