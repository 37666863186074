import * as React from "react";

import { isObject, isNumber } from "util";

import FormControl from "@material-ui/core/FormControl/FormControl";
import _isArray from "lodash/isArray";
import _isEmpty from "lodash/isEmpty";
import _isFunction from "lodash/isFunction";
import _isNil from "lodash/isNil";
import _isString from "lodash/isString";
import _map from "lodash/map";
import _size from "lodash/size";
import PropTypes from "prop-types";

import SelectDropdown from "./SelectDropdown";
import SelectHelperText from "./SelectHelperText";
import SelectLabel from "./SelectLabel";

class SelectMaterial extends React.Component {
  constructor(props) {
    super(props);

    const value = props.value;

    this.state = {
      filter: "",
      hasInputFocus: false,
      selectedOption: _isNil(value) ? "" : this.getOneOrMoreSelectOptions(value)
    };
  }

  static propTypes = {
    isMulti: PropTypes.bool,
    async: PropTypes.bool,
    setRef: PropTypes.func,
    defaultOptions: PropTypes.array,
    loadOptions: PropTypes.func,
    autoComplete: PropTypes.any,
    autoFocus: PropTypes.any,
    children: PropTypes.any,
    className: PropTypes.string,
    defaultValue: PropTypes.any,
    disabled: PropTypes.bool,
    error: PropTypes.any,
    FormHelperTextProps: PropTypes.object,
    fullWidth: PropTypes.any,
    helperText: PropTypes.string,
    id: PropTypes.any,
    InputLabelProps: PropTypes.object,
    inputRef: PropTypes.any,
    label: PropTypes.any,
    multiline: PropTypes.any,
    name: PropTypes.any,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    placeholder: PropTypes.any,
    required: PropTypes.bool,
    select: PropTypes.any,
    SelectProps: PropTypes.object,
    type: PropTypes.any,
    value: PropTypes.any,
    options: PropTypes.any,
    maxOptions: PropTypes.number
  };

  componentDidUpdate(prevProps) {
    const { value } = this.props;
    if (JSON.stringify(prevProps.value) !== JSON.stringify(value))
      this.setState({
        selectedOption: _isNil(value)
          ? ""
          : this.getOneOrMoreSelectOptions(value)
      });
  }

  render() {
    const {
      async,
      setRef,
      isMulti,
      loadOptions,
      defaultOptions,
      autoComplete,
      autoFocus,
      children,
      className,
      defaultValue,
      disabled,
      error,
      FormHelperTextProps,
      fullWidth,
      helperText,
      id,
      InputLabelProps,
      inputRef,
      label,
      multiline,
      name,
      onBlur,
      onChange,
      onFocus,
      placeholder = "",
      required,
      select,
      SelectProps,
      type,
      value,
      options,
      maxOptions,
      ...other
    } = this.props;

    const helperTextId = id && helperText ? `${id}-helper-text` : undefined;

    const shrink =
      this.hasInputFocus() ||
      this.hasFilter() ||
      this.hasValue() ||
      Boolean(placeholder);

    const { hasInputFocus, selectedOption } = this.state;

    const isClearable =
      !!SelectProps && SelectProps.isClearable && this.isClearable();
    const isDisabled = disabled || (!!SelectProps && SelectProps.isDisabled);

    return (
      <FormControl
        test-id="select-box"
        aria-describedby={helperTextId}
        className={className}
        error={error}
        fullWidth={fullWidth}
        required={required}
        {...other}
      >
        <SelectLabel
          id={id}
          label={label}
          shrink={shrink}
          error={error}
          hasInputFocus={hasInputFocus}
          isDisabled={isDisabled}
          inputLabelProps={InputLabelProps}
        />
        <SelectDropdown
          setRef={setRef}
          isMulti={isMulti}
          async={async}
          loadOptions={loadOptions}
          defaultOptions={defaultOptions}
          value={selectedOption}
          placeholder={placeholder}
          options={this.getOptions(options)}
          maxOptions={maxOptions}
          selectProps={{
            ...SelectProps,
            isClearable,
            isDisabled
          }}
          data-testid="select-dropdown"
          error={error}
          hasInputFocus={hasInputFocus}
          onChange={this.handleChangeSelect}
          onFocus={this.handleGotFocus}
          onBlur={this.handleLostFocus}
        />
        <SelectHelperText
          id={helperTextId}
          helperText={helperText}
          formHelperTextProps={FormHelperTextProps}
        />
      </FormControl>
    );
  }

  getOneOrMoreSelectOptions(value) {
    if (_isArray(value)) {
      return this.getOptions(value);
    }

    return this.getSelectOption(value);
  }

  isClearable() {
    const { selectedOption } = this.state;

    if (_isEmpty(selectedOption)) {
      return false;
    }

    if (_isArray(selectedOption) && _size(selectedOption) <= 1) {
      return false;
    }

    return true;
  }

  hasInputFocus() {
    return this.state.hasInputFocus === true;
  }

  hasFilter() {
    return _isEmpty(this.state.filter) === false;
  }

  hasValue() {
    return _isEmpty(this.state.selectedOption) === false;
  }

  getOptions(options) {
    return _map(options, this.getSelectOption);
  }

  getSelectOption = option => {
    if (isObject(option)) {
      return option;
    } else if (_isString(option) || isNumber(option)) {
      return this.props.options.find(el => el.value === option);
    } else {
      return {
        label: option,
        value: option
      };
    }
  };

  handleChangeSelect = value => {
    this.setState({
      filter: "",
      selectedOption: value
    });

    const { onChange } = this.props;
    if (_isFunction(onChange)) {
      onChange(this.getValues(value), value);
    }
  };

  getValues(value) {
    if (_isNil(value)) {
      return null;
    }

    if (_isArray(value)) {
      return _map(value, this.getValue);
    }

    return this.getValue(value);
  }

  getValue(option) {
    return option.value;
  }

  handleGotFocus = event => {
    this.setState({
      hasInputFocus: true
    });

    const { onFocus } = this.props;

    if (_isFunction(onFocus)) {
      onFocus(event);
    }
  };

  handleLostFocus = event => {
    this.setState({
      hasInputFocus: false
    });

    const { onBlur } = this.props;

    if (_isFunction(onBlur)) {
      onBlur(event);
    }
  };
}

export default SelectMaterial;
