import { Grid, InputLabel } from "@material-ui/core";
import PropTypes from "prop-types";

import { DEFAULT_TIMEZONE } from "config";

Timezone.propTypes = {
  title: PropTypes.string
};

export function Timezone({ title = "Time zone:" }) {
  return (
    <Grid item xs={12} md={4}>
      <InputLabel shrink disabled>
        {title}
      </InputLabel>
      <Grid
        style={{
          marginTop: 10,
          color: "#999999",
          paddingBottom: 5,
          borderBottom: "1.5px solid #dedede"
        }}
      >
        {DEFAULT_TIMEZONE}
      </Grid>
    </Grid>
  );
}
