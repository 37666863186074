import React from "react";

import { withStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";
import cn from "classnames";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { compose } from "redux";

import { isMobile } from "../../../utils";
import { styles } from "./styles";

class LinkCellComponent extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    to: PropTypes.string,
    rel: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    history: PropTypes.object,
    onClick: PropTypes.func,
    children: PropTypes.any,
    noMobile: PropTypes.bool,
    center: PropTypes.bool
  };
  static defaultProps = {
    onClick: () => {}
  };

  handleClick = e => {
    const { history, to, rel } = this.props;
    e.stopPropagation();
    const link = to ? to : `${history.location.pathname}/${rel}`;
    link && history.push(link);
    this.props.onClick(history, link);
  };

  render() {
    const { classes, children, noMobile, center } = this.props;
    return !isMobile(this) || noMobile ? (
      <div
        className={cn(classes.root, center && classes.center)}
        onClick={this.handleClick}
      >
        <span className={classes.overflow}>{children}</span>
      </div>
    ) : (
      <div className={classes.mobile} onClick={this.handleClick}>
        {children}
      </div>
    );
  }
}

export const LinkCell = compose(
  withStyles(styles),
  withRouter,
  withWidth()
)(LinkCellComponent);
