import { REST } from "srx-shared";

import { EndPoint } from "./endpoints";

//Distributor
export const ListDistributors = (token, page, size, filters, sorting) =>
  REST.ApiGet(
    EndPoint.ListDistributorsUrl(
      page,
      size,
      REST.filtersAsURLParams(filters),
      REST.sortingAsURLParams(sorting)
    ),
    token
  );
export const AddDistributor = (token, data) =>
  REST.ApiPost(EndPoint.AddDistributorUrl(), token, data);
export const UpdateDistributor = (token, data) =>
  REST.ApiPost(EndPoint.UpdateDistributorUrl(data.id), token, data);
export const RemoveDistributor = (token, id) =>
  REST.ApiPost(EndPoint.DeleteDistributorUrl(id), token);
export const GetDistributor = (token, id) =>
  REST.ApiGet(EndPoint.GetDistributorUrl(id), token);
export const GetDistributorsMinInfo = token =>
  REST.ApiGet(EndPoint.GetDistributorsMinInfoUrl(), token);
export const SetDistributorStatus = (token, id, status) =>
  status === "ACTIVE"
    ? ActivateDistributor(token, id)
    : DeactivateDistributor(token, id);
export const ActivateDistributor = (token, id) =>
  REST.ApiPost(EndPoint.ActivateDistributorUrl(id), token);
export const DeactivateDistributor = (token, id) =>
  REST.ApiPost(EndPoint.DeactivateDistributorUrl(id), token);

//Users
export const ListUsers = (token, distributorId, page, size, filters, sorting) =>
  REST.ApiGet(
    EndPoint.ListUsersUrl(
      distributorId,
      page,
      size,
      REST.filtersAsURLParams(filters),
      REST.sortingAsURLParams(sorting)
    ),
    token
  );
export const ListUsersWithStorages = token =>
  REST.ApiGet(EndPoint.ListUsersWithStoragesUrl(), token);
export const AddUser = (token, distributorId, data) =>
  REST.ApiPost(EndPoint.AddUserUrl(distributorId), token, data);
export const UpdateUser = (token, distributorId, userId, data) =>
  REST.ApiPost(EndPoint.UpdateUserUrl(distributorId, userId), token, data);
export const RemoveUser = (token, distributorId, userId) =>
  REST.ApiPost(EndPoint.RemoveUserUrl(distributorId, userId), token);

//Base Fees
export const GetBaseFees = token =>
  REST.ApiGet(EndPoint.GetBaseFeesUrl(), token);
export const SaveBaseFees = (token, id, data) =>
  REST.ApiPost(EndPoint.SaveBaseFeesUrl(id), token, data);

//Shipto Fees
export const GetShiptoFees = token =>
  REST.ApiGet(EndPoint.GetShiptoFeesUrl(), token);
export const UpdateShiptoFees = (token, id, data) =>
  REST.ApiPut(EndPoint.UpdateShiptoFeesUrl(id), token, data);

//Triggers Monthly Fee
export const ListTriggersMonthlyFees = (token, page, size, filters, sorting) =>
  REST.ApiGet(
    EndPoint.ListTriggersMonthlyFeesUrl(
      page,
      size,
      REST.filtersAsURLParams(filters),
      REST.sortingAsURLParams(sorting)
    ),
    token
  );
export const AddTriggersMonthlyFee = (token, data) =>
  REST.ApiPost(EndPoint.AddTriggersMonthlyFeeUrl(), token, data);
export const SaveTriggersMonthlyFee = (token, id, data) =>
  REST.ApiPost(EndPoint.SaveTriggersMonthlyFeeUrl(id), token, data);
export const RemoveTriggersMonthlyFee = (token, id) =>
  REST.ApiPost(EndPoint.RemoveTriggersMonthlyFeeUrl(id), token);

//Cameras Monthly Fee
export const ListCamerasMonthlyFees = (token, page, size, filters, sorting) =>
  REST.ApiGet(
    EndPoint.ListCamerasMonthlyFeesUrl(
      page,
      size,
      REST.filtersAsURLParams(filters),
      REST.sortingAsURLParams(sorting)
    ),
    token
  );
export const SaveCamerasMonthlyFee = (token, id, data) =>
  REST.ApiPost(EndPoint.SaveCamerasMonthlyFeeUrl(id), token, data);
export const AddCamerasMonthlyFee = (token, data) =>
  REST.ApiPost(EndPoint.AddCamerasMonthlyFeeUrl(), token, data);
export const RemoveCamerasMonthlyFee = (token, id) =>
  REST.ApiPost(EndPoint.RemoveCamerasMonthlyFeeUrl(id), token, null);

//Discounts
export const GetDiscounts = (token, distributorId) =>
  REST.ApiGet(EndPoint.GetDiscountsUrl(distributorId), token);
export const UpdateDiscounts = (token, distributorId, data) =>
  REST.ApiPost(EndPoint.UpdateDiscountsUrl(distributorId), token, data);

//Documents
export const ListDocuments = (token, page, size, filters, sorting) =>
  REST.ApiGet(
    EndPoint.ListDocumentsUrl(
      page,
      size,
      REST.filtersAsURLParams(filters),
      REST.sortingAsURLParams(sorting)
    ),
    token
  );
export const ChangeDocumentStatus = (token, id, data) =>
  REST.ApiPost(EndPoint.ChangeDocumentStatusUrl(id), token, data);
export const getS3DocumentLink = (token, id) =>
  REST.ApiGet(EndPoint.GetDocumentAttachmentUrl(id), token);

//Customer Type
export const ListCustomerTypes = (token, page, size, filters, sorting) =>
  REST.ApiGet(
    EndPoint.ListCustomerTypesUrl(
      page,
      size,
      REST.filtersAsURLParams(filters),
      REST.sortingAsURLParams(sorting)
    ),
    token
  );
export const AddCustomerType = (token, data) =>
  REST.ApiPost(EndPoint.AddCustomerTypeUrl(), token, data);
export const UpdateCustomerType = (token, id, data) =>
  REST.ApiPost(EndPoint.UpdateCustomerTypeUrl(id), token, data);
export const RemoveCustomerType = (token, id) =>
  REST.ApiPost(EndPoint.RemoveCustomerTypeUrl(id), token);

//Market Type
export const ListMarketTypes = (token, page, size, filters, sorting) =>
  REST.ApiGet(
    EndPoint.ListMarketTypesUrl(
      page,
      size,
      REST.filtersAsURLParams(filters),
      REST.sortingAsURLParams(sorting)
    ),
    token
  );
export const AddMarketType = (token, data) =>
  REST.ApiPost(EndPoint.AddMarketTypeUrl(), token, data);
export const UpdateMarketType = (token, id, data) =>
  REST.ApiPost(EndPoint.UpdateMarketTypeUrl(id), token, data);
export const RemoveMarketType = (token, id) =>
  REST.ApiPost(EndPoint.RemoveMarketTypeUrl(id), token);

//Bin Type
export const ListBinTypes = (token, page, size, filters, sorting) =>
  REST.ApiGet(
    EndPoint.ListBinTypesUrl(
      page,
      size,
      REST.filtersAsURLParams(filters),
      REST.sortingAsURLParams(sorting)
    ),
    token
  );
export const AddBinType = (token, data) =>
  REST.ApiPost(EndPoint.BinTypeUrl(), token, data);
export const UpdateBinType = (token, id, data) =>
  REST.ApiPut(EndPoint.BinTypeUrl(id), token, data);
export const RemoveBinType = (token, id) =>
  REST.ApiDelete(EndPoint.BinTypeUrl(id), token);

//Locker Types
export const ListLockerTypes = (token, page, size, filters, sorting) =>
  REST.ApiGet(
    EndPoint.ListLockerTypesUrl(
      page,
      size,
      REST.filtersAsURLParams(filters),
      REST.sortingAsURLParams(sorting)
    ),
    token
  );
export const GetLockerTypes = token =>
  REST.ApiGet(EndPoint.GetLockerTypesUrl(), token);
export const AddLockerType = (token, data) =>
  REST.ApiPost(EndPoint.LockerTypeUrl(), token, data);
export const UpdateLockerType = (token, id, data) =>
  REST.ApiPut(EndPoint.LockerTypeUrl(id), token, data);
export const RemoveLockerType = (token, id) =>
  REST.ApiDelete(EndPoint.LockerTypeUrl(id), token);

//Vending Types
export const TestVendingIssueItems = (token, data) =>
  REST.ApiPost(EndPoint.TestVendingIssueItemsUrl(), token, data);

//Hardware
export const ListHardware = (token, page, size, filters, sorting) =>
  REST.ApiGet(
    EndPoint.ListHardwareUrl(
      page,
      size,
      REST.filtersAsURLParams(filters),
      REST.sortingAsURLParams(sorting)
    ),
    token
  );
export const AddHardware = (token, data) =>
  REST.ApiPost(EndPoint.HardwareUrl(), token, data);
export const UpdateHardware = (token, data) =>
  REST.ApiPut(EndPoint.HardwareUrl(), token, data);
export const RemoveHardware = (token, id) =>
  REST.ApiDelete(EndPoint.HardwareUrl(id), token);
export const GetHardware = (token, id) =>
  REST.ApiGet(EndPoint.HardwareUrl(id), token);

//Freeze variables
export const GetFreezeVariables = (token, distributorId) =>
  REST.ApiGet(EndPoint.FreezeVariablesUrl(distributorId), token);
export const UpdateFreezeVariables = (token, distributorId, data) =>
  REST.ApiPost(EndPoint.FreezeVariablesUrl(distributorId), token, data);

//Cost Savings
export const GetDefaultSrxVmiOperationalCosts = token =>
  REST.ApiGet(EndPoint.DefaultSrxVmiOperationalCostsUrl(), token);
export const UpdateDefaultSrxVmiOperationalCosts = (token, data) =>
  REST.ApiPost(EndPoint.DefaultSrxVmiOperationalCostsUrl(), token, data);

export const GetDefaultTraditionalVmiOperationalCosts = token =>
  REST.ApiGet(EndPoint.DefaultTraditionalVmiOperationalCostsUrl(), token);
export const UpdateDefaultTraditionalVmiOperationalCosts = (token, data) =>
  REST.ApiPost(
    EndPoint.DefaultTraditionalVmiOperationalCostsUrl(),
    token,
    data
  );

export const GetDefaultDistributorInventory = token =>
  REST.ApiGet(EndPoint.DefaultDistributorInventoryUrl(), token);
export const UpdateDefaultDistributorInventory = (token, data) =>
  REST.ApiPost(EndPoint.DefaultDistributorInventoryUrl(), token, data);

export const GetDefaultSuggestedMinMaxFormula = token =>
  REST.ApiGet(EndPoint.DefaultSuggestedMinMaxFormulaUrl(), token);
export const UpdateDefaultSuggestedMinMaxFormula = (token, data) =>
  REST.ApiPost(EndPoint.DefaultSuggestedMinMaxFormulaUrl(), token, data);

export const GetDefaultOperationalCosts = token =>
  REST.ApiGet(EndPoint.DefaultOperationalCostsUrl(), token);
export const UpdateDefaultOperationalCosts = (token, data) =>
  REST.ApiPost(EndPoint.DefaultOperationalCostsUrl(), token, data);

export const GetDefaultSupplyForce = token =>
  REST.ApiGet(EndPoint.DefaultSupplyForceUrl(), token);
export const UpdateDefaultSupplyForce = (token, data) =>
  REST.ApiPost(EndPoint.DefaultSupplyForceUrl(), token, data);

//Cost Savings for Distributor
export const GetSrxVmiOperationalCosts = (token, distributor) =>
  REST.ApiGet(EndPoint.SrxVmiOperationalCostsUrl(distributor), token);
export const UpdateSrxVmiOperationalCosts = (token, data, distributor) =>
  REST.ApiPost(EndPoint.SrxVmiOperationalCostsUrl(distributor), token, data);

export const GetTraditionalVmiOperationalCosts = (token, distributor) =>
  REST.ApiGet(EndPoint.TraditionalVmiOperationalCostsUrl(distributor), token);
export const UpdateTraditionalVmiOperationalCosts = (
  token,
  data,
  distributor
) =>
  REST.ApiPost(
    EndPoint.TraditionalVmiOperationalCostsUrl(distributor),
    token,
    data
  );

export const GetDistributorInventory = (token, distributor) =>
  REST.ApiGet(EndPoint.DistributorInventoryUrl(distributor), token);
export const UpdateDistributorInventory = (token, data, distributor) =>
  REST.ApiPost(EndPoint.DistributorInventoryUrl(distributor), token, data);

export const GetSuggestedMinMaxFormula = (token, distributor) =>
  REST.ApiGet(EndPoint.SuggestedMinMaxFormulaUrl(distributor), token);
export const UpdateSuggestedMinMaxFormula = (token, data, distributor) =>
  REST.ApiPost(EndPoint.SuggestedMinMaxFormulaUrl(distributor), token, data);

export const GetOperationalCosts = (token, distributor) =>
  REST.ApiGet(EndPoint.OperationalCostsUrl(distributor), token);
export const UpdateOperationalCosts = (token, data, distributor) =>
  REST.ApiPost(EndPoint.OperationalCostsUrl(distributor), token, data);

//Camera
export const GetListOfConfigurableCamera = (token, data) =>
  REST.ApiPost(EndPoint.GetListOfConfigurableCameraUrl(), token, data);
export const ConfigureCamera = (token, data) =>
  REST.ApiPost(EndPoint.ConfigureCameraUrl(), token, data);
export const GetCameraConfigStatus = (token, data) =>
  REST.ApiPost(EndPoint.GetCameraConfigStatusUrl(), token, data);

//UPC & GTIN Catalog
export const ListUpcGtinCatalogItems = (token, page, size, filters, sorting) =>
  REST.ApiGet(
    EndPoint.ListUpcGtinCatalogItemsUrl(
      page,
      size,
      REST.filtersAsURLParams(filters),
      REST.sortingAsURLParams(sorting)
    ),
    token
  );
export const AddUpcGtinCatalogItem = (token, data) =>
  REST.ApiPost(EndPoint.UpcGtinCatalogItemUrl(), token, data);
export const UpdateUpcGtinCatalogItem = (token, id, data) =>
  REST.ApiPut(EndPoint.UpcGtinCatalogItemUrl(id), token, data);
export const RemoveUpcGtinCatalogItem = (token, id) =>
  REST.ApiDelete(EndPoint.UpcGtinCatalogItemUrl(id), token);

//Import
export const ImportStatus = (token, fileFolder, fileName, type) =>
  REST.ApiGet(EndPoint.ImportStatusUrl(fileFolder, fileName, type), token);
export const ParseFile = (token, type, processId) =>
  REST.ApiPost(EndPoint.ParseFileUrl(type, processId), token);

//Locker
export const LoadLockerConfig = (token, lockerId) =>
  REST.ApiGet(EndPoint.LockerConfigUrl(lockerId), token);
export const UpdateLockerConfig = (token, lockerId, data) =>
  REST.ApiPut(EndPoint.LockerConfigUrl(lockerId), token, data);
export const GetDistributorLockers = (token, distributorId) =>
  REST.ApiGet(EndPoint.DistributorLockersUrl(distributorId), token);
export const GetAvailableLockerDoors = (token, lockerId) =>
  REST.ApiGet(EndPoint.AvailableLockerDoorsUrl(lockerId), token);
export const GetLockerLocations = (token, lockerId, doorNumber) =>
  REST.ApiGet(EndPoint.GetLockerLocationsUrl(lockerId, doorNumber), token);

//Storage
export const GetDistributorStorages = (token, distributorId) =>
  REST.ApiGet(EndPoint.DistributorStoragesUrl(distributorId), token);
export const LoadStorageConfig = (token, storageId) =>
  REST.ApiGet(EndPoint.StorageConfigUrl(storageId), token);
export const UpdateStorageConfig = (token, storageId, data) =>
  REST.ApiPut(EndPoint.StorageConfigUrl(storageId), token, data);
export const GetStorageLocations = (token, storageId, sectionNumber) =>
  REST.ApiGet(EndPoint.GetStorageLocationsUrl(storageId, sectionNumber), token);

//IotHub
export const ListIoTHubs = (token, type) =>
  REST.ApiGet(EndPoint.ListIoTHubsUrl(type), token);
export const GenerateIotHubAccessKey = (token, data) =>
  REST.ApiPost(EndPoint.GenerateIotHubAccessKeyUrl(), token, data);
export const GetUrlToDownloadIotHubImage = (token, data) =>
  REST.ApiPost(EndPoint.GetDownloadIotHubImageUrl(), token, data);

//Distributor Shelves
export const GetDistributorsShelves = (token, id) =>
  REST.ApiGet(
    EndPoint.ListSmartShelvesUrl(
      1,
      99999,
      REST.filtersAsURLParams({ distributorId: [id] }),
      ""
    ),
    token
  );
export const GetDistributorShelves = (token, id) =>
  REST.ApiGet(EndPoint.DistributorShelves(id), token);

//Smart Shelves
export const ListSmartShelfItems = (token, page, size, filters, sorting) =>
  REST.ApiGet(
    EndPoint.ListSmartShelvesUrl(
      page,
      size,
      REST.filtersAsURLParams(filters),
      REST.sortingAsURLParams(sorting)
    ),
    token
  );
export const GetSmartShelf = (token, id) =>
  REST.ApiGet(EndPoint.SmartShelfUrl(id), token);
export const AddSmartShelf = (token, data) =>
  REST.ApiPost(EndPoint.SmartShelfUrl(), token, data);
export const UpdateSmartShelf = (token, data) =>
  REST.ApiPut(EndPoint.SmartShelfUrl(), token, data);
export const RemoveSmartShelf = (token, id) =>
  REST.ApiDelete(EndPoint.SmartShelfUrl(id), token);
export const ValidateSmartShelf = (token, data) =>
  REST.ApiPost(EndPoint.ValidateSmartShelfUrl(), token, data);
export const AssignSmartShelf = (token, lockerId, data) =>
  REST.ApiPut(EndPoint.AssignSmartShelfUrl(lockerId), token, data);
export const GetSmartShelfDetails = (token, id) =>
  REST.ApiGet(EndPoint.SmartShelfDetailsfUrl(id), token);
export const PollWeightSmartShelf = (token, id, data) =>
  REST.ApiPost(EndPoint.SmartShelfPollWeightUrl(id), token, data);

//Hardware states
export const ListHardwareStates = token =>
  REST.ApiGet(EndPoint.HardwareStates(), token);
export const GetDeviceState = (token, data) =>
  REST.ApiPost(EndPoint.DeviceState(), token, data);
export const RenewDeviceState = (token, data) =>
  REST.ApiPost(EndPoint.RenewDeviceState(), token, data);

//Weight history
export const LoadWeightHistory = (token, page, size, filters, sorting) =>
  REST.ApiGet(
    EndPoint.WeightHistoryUrl(
      page,
      size,
      REST.filtersAsURLParams(filters),
      REST.sortingAsURLParams(sorting)
    ),
    token
  );
export const ExportWeightHistory = (token, filters, sorting) =>
  REST.ApiGet(
    EndPoint.ExportWeightHistoryUrl(
      REST.filtersAsURLParams(filters),
      REST.sortingAsURLParams(sorting)
    ),
    token
  );

//Attach policy
export const AttachPolicy = (token, data) =>
  REST.ApiPost(EndPoint.AttachPolicyUrl(), token, data);

//Billing
export const SendInvoiceToErp = (token, id) =>
  REST.ApiPost(EndPoint.SendInvoiceToErpUrl(id), token);

//ShipTo templates
export const GetShipToTemplatesList = token =>
  REST.ApiGet(EndPoint.ShipToTemplatesUrl(), token);

export const AddShipToTemplate = (token, data) =>
  REST.ApiPost(EndPoint.ShipToTemplatesUrl(), token, data);

export const GetShipToTemplate = (token, id) =>
  REST.ApiGet(EndPoint.ShipToTemplatesUrl(id), token);

export const UpdateShipToTemplate = (token, data) =>
  REST.ApiPut(EndPoint.ShipToTemplatesUrl(data.id), token, data);

export const DeleteShipToTemplate = (token, id) =>
  REST.ApiDelete(EndPoint.ShipToTemplatesUrl(id), token);

export const GetVmiListSettingsStructure = token =>
  REST.ApiGet(EndPoint.VmiListSettingsStructureUrl(), token);

export const GetRLSubmitIntegrationStatuses = (token, erp) =>
  REST.ApiGet(EndPoint.RLSubmitIntegrationStatusesUrl(erp), token);
