import { RingBuffer } from "srx-shared";

import { MAX_UNDO } from "config";

import {
  BaseFee,
  TriggersMonthlyFee,
  CamerasMonthlyFee,
  ShiptoFee
} from "./types";

export default {
  [BaseFee]: new RingBuffer(MAX_UNDO),
  [TriggersMonthlyFee]: new RingBuffer(MAX_UNDO),
  [CamerasMonthlyFee]: new RingBuffer(MAX_UNDO),
  [ShiptoFee]: new RingBuffer(MAX_UNDO)
};
