import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
  helperText: {
    color: theme.palette.primary.light
  },
  helperTextError: {
    color: "red !important"
  },
  link: {
    marginTop: 12,
    fontSize: 14
  },
  active: {
    cursor: "pointer"
  },
  disabled: {
    color: "#8C8C8C"
  }
}));
