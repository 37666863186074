import AdminUsers from "./AdminUsers";
import CostSavings from "./CostSavings";
import Discounts from "./Discounts";
import FreezeVariables from "./FreezeVariables";
import Info from "./Info";

export const distributorTabs = [
  {
    title: "General Info",
    key: "info",
    component: Info
  },
  {
    title: "Admin Users",
    key: "users",
    component: AdminUsers
  },
  {
    title: "Discounts",
    key: "discounts",
    component: Discounts
  },
  {
    title: "Freeze",
    key: "freeze",
    component: FreezeVariables
  },
  {
    title: "Cost Savings",
    key: "cost-savings",
    component: CostSavings
  }
];
