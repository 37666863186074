import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  container: {
    padding: 16
  },
  planogramContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center"
  },
  columnContainer: {
    padding: "0 8px 20px 8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexFlow: "column wrap",
    alignContent: "center"
  },
  doorContainer: {
    display: "flex",
    flexWrap: "nowrap",
    paddingRight: 8,
    width: 498
  },
  doorPaper: {
    display: "inline-flex",
    paddingLeft: 10,
    position: "relative",
    border: "1px solid white"
  },
  noWeightPaper: {
    background: "#cde"
  },
  mergedDoor: {
    background: "cornsilk"
  },
  selectedPaper: {
    border: "1px solid #776699"
  },
  doorNumber: {
    display: "flex",
    width: 28,
    height: 28,
    margin: "8px 0 8px 2px",
    justifyContent: "center",
    alignItems: "center",
    background: "#eee",
    color: "#777",
    fontSize: "14px",
    borderRadius: "50%"
  },
  clsLabel: {
    width: 170,
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: "8pt",
    color: "#777"
  },
  clsText: {
    width: 170,
    marginTop: 2,
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: "10pt",
    color: "#333"
  },
  clsNotAssigned: {
    fontSize: "small",
    color: "#aaa"
  },
  iconWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "8px 0"
  },
  serialContainer: {
    display: "flex",
    paddingRight: 10
  },
  serialInput: {
    flexDirection: "column",
    alignSelf: "center"
  },
  statusIcon: {
    display: "flex",
    paddingLeft: 4,
    alignItems: "center"
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
    margin: "5px 0"
  },
  label: {
    whiteSpace: "normal",
    transform: "none",
    fontSize: 10
  }
}));
