import { Grid } from "@material-ui/core";
import PropTypes from "prop-types";

import { breadcrumbsFor, editFormsFor } from "srx-shared";

import { pages } from "@routes/pages";

import DistributorName from "../DistributorName";
import { DistributorItem } from "./DistributorItem";

const Forms = editFormsFor(DistributorItem, DistributorItem.forms);

const Breadcrumbs = breadcrumbsFor();

const breadcrumbsList = () => [
  { title: pages.distributors.title, to: pages.distributors.path },
  { title: <DistributorName /> },
  { title: "General info" }
];

Info.propTypes = {
  currentDistributor: PropTypes.object
};

function Info() {
  return (
    <Grid container justify="center">
      <Breadcrumbs renderList={breadcrumbsList} />
      <Grid style={{ maxWidth: 900 }}>
        <Forms readOnly />
      </Grid>
    </Grid>
  );
}

export default Info;
