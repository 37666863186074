import React from "react";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardDateTimePicker
} from "@material-ui/pickers";
import PropTypes from "prop-types";

import { RangeDatePicker } from "../RangeDatePicker";

export const DatePickerField = props => {
  const { PickerProps, ...other } = props;
  const fieldProps = { ...PickerProps, ...other, type: "text" };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      {props.type === "datetime" ? (
        <KeyboardDateTimePicker
          {...fieldProps}
          variant="inline"
          format="MM/dd/yyyy hh:mm a"
          value={props.value || null}
          autoOk
        />
      ) : props.type === "daterange" ? (
        <RangeDatePicker {...props} />
      ) : (
        <KeyboardDatePicker
          {...fieldProps}
          variant="inline"
          format="MM/dd/yyyy"
          value={props.value || null}
          autoOk
        />
      )}
    </MuiPickersUtilsProvider>
  );
};

DatePickerField.propTypes = {
  value: PropTypes.any,
  type: PropTypes.string,
  PickerProps: PropTypes.object
};
