import { mapFormFieldsFor } from "srx-shared";
import { ExpansionGroup } from "srx-shared/mui";

import { Distributor } from "@items/types";

import { CustomCheckbox } from "./CustomFields/CustomCheckbox";
import { CustomDateField } from "./CustomFields/CustomDateField";
import { CustomTextField } from "./CustomFields/CustomTextField";
import { ReSubmitButton } from "./CustomFields/ReSubmitButton";

export const DistributorItem = {
  type: Distributor,
  name: "Distributor"
};

DistributorItem.fieldsConfig = {
  id: {
    label: "SRX Number",
    Component: CustomTextField
  },
  externalDistributorNumber: {
    label: "External Distributor Number",
    Component: CustomTextField
  },
  "address.line1": {
    label: "Address Line 1",
    Component: CustomTextField
  },
  "address.line2": {
    label: "Address Line 2",
    Component: CustomTextField
  },
  "address.city": {
    label: "City",
    Component: CustomTextField
  },
  country: {
    label: "Country",
    Component: CustomTextField
  },
  "address.state": {
    label: "State",
    Component: CustomTextField
  },
  "address.zipCode": {
    label: "Postal Code",
    Component: CustomTextField
  },
  invoiceEmail: {
    label: "Invoice Email",
    Component: CustomTextField
  },
  universalCatalog: {
    label: "Use the Distributor Catalog in the Universal Catalog data",
    type: "checkbox",
    size: 12,
    Component: CustomCheckbox
  },
  userData: {
    label: "User Data",
    type: "checkbox",
    Component: CustomCheckbox,
    size: 2
  },
  agreements: {
    label: "Agreements",
    type: "checkbox",
    Component: CustomCheckbox,
    size: 2
  },
  taxes: {
    label: "Taxes",
    type: "checkbox",
    Component: CustomCheckbox,
    size: 2
  },
  level: {
    label: "Level",
    type: "checkbox",
    Component: CustomCheckbox,
    size: 2
  },
  creditCardInfo: {
    label: "Billing Info",
    type: "checkbox",
    Component: CustomCheckbox,
    size: 2
  },
  accountStatus: {
    label: "Account Status",
    Component: CustomTextField
  },
  billingLeftDays: {
    label: "Billing left",
    Component: CustomTextField
  },
  "shipToFee.name": {
    label: "Billing Level",
    Component: CustomTextField
  },
  "taxesOn.softwareFees": {
    label: "Software Fees",
    type: "checkbox",
    Component: CustomCheckbox,
    size: 4
  },
  "taxesOn.hardwareFees": {
    label: "Hardware Fees",
    type: "checkbox",
    Component: CustomCheckbox,
    size: 4
  },
  billAllShipTo: {
    label: "Bill by all Ship-tos",
    type: "checkbox",
    Component: CustomCheckbox,
    size: 4
  },
  resubmitLastInvoice: {
    Component: ReSubmitButton
  },
  lastInvoiceDate: {
    label: "Last Successful Invoice Date",
    Component: CustomDateField
  },
  currentShipToCount: {
    label: "Ship-to count for current billing period",
    Component: CustomTextField
  },
  stripeId: {
    label: "Stripe ID",
    Component: CustomTextField
  }
};

const formConfig = {
  fields: [
    "id",
    "externalDistributorNumber",
    "address.line1",
    "address.line2",
    "address.city",
    "country",
    "address.state",
    "address.zipCode",
    "invoiceEmail",
    "universalCatalog",
    "userData",
    "agreements",
    "taxes",
    "level",
    "creditCardInfo",
    "accountStatus",
    "billingLeftDays",
    "shipToFee.name",
    "taxesOn.softwareFees",
    "taxesOn.hardwareFees",
    "billAllShipTo",
    "currentShipToCount",
    "stripeId",
    "resubmitLastInvoice",
    "lastInvoiceDate"
  ],
  groups: [
    {
      Component: ExpansionGroup,
      title: "Contact Info",
      expanded: true,
      fixed: true,
      contentProps: {
        style: { paddingBottom: 32 }
      },
      fields: [
        "id",
        "externalDistributorNumber",
        "address.line1",
        "address.line2",
        "address.city",
        "country",
        "address.state",
        "address.zipCode",
        "invoiceEmail",
        "universalCatalog"
      ]
    },
    {
      Component: ExpansionGroup,
      title: "Activation settings",
      expanded: true,
      fixed: true,
      contentProps: {
        style: { paddingBottom: 32 }
      },
      fields: ["userData", "agreements", "taxes", "level", "creditCardInfo"]
    },
    {
      Component: ExpansionGroup,
      title: "Billing settings",
      expanded: true,
      fixed: true,
      contentProps: {
        style: { paddingBottom: 32 }
      },
      fields: [
        "accountStatus",
        "billingLeftDays",
        "shipToFee.name",
        "lastInvoiceDate",
        "currentShipToCount",
        "stripeId",
        "taxesOn.softwareFees",
        "taxesOn.hardwareFees",
        "billAllShipTo",
        "resubmitLastInvoice"
      ]
    }
  ]
};

DistributorItem.forms = mapFormFieldsFor(DistributorItem, formConfig);
