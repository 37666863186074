import { createDckReducer } from "dck-redux";
import { createBrowserHistory, createMemoryHistory } from "history";
import localForage from "localforage";
import { createStore, applyMiddleware, compose } from "redux";
import { createReduxHistoryContext } from "redux-first-history";
import { createLogger } from "redux-logger";
import { persistStore, persistCombineReducers } from "redux-persist";
import createSagaMiddleware from "redux-saga";

import {
  Reducer as appReducer,
  processMiddleware,
  displayReducer,
  undoReducer
} from "srx-shared";

import rootSaga from "@sagas";

import * as ItemTypes from "./items/types";
import undoBuffers from "./items/undoBuffers";
import ProcessTypes from "./processes/types";

const createHistory = () =>
  process.env.NODE_ENV === "test"
    ? createMemoryHistory({ initialEntries: ["/"] })
    : createBrowserHistory();

const { createReduxHistory, routerMiddleware, routerReducer } =
  createReduxHistoryContext({
    history: createHistory()
  });

const sagaMiddleware = createSagaMiddleware();
const middlewares = [processMiddleware(), sagaMiddleware, routerMiddleware];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

if (
  process.env.REACT_APP_LOGGER === "console" &&
  process.env.NODE_ENV === "development"
) {
  middlewares.push(
    createLogger({
      collapsed: (getState, action, logEntry) => !logEntry.error
    })
  );
}

localForage.config({
  name: "Layouts display",
  storeName: "display"
});

const persistConfig = {
  key: "root",
  storage: localForage,
  whitelist: ["display"]
};

export function configureStore(initialState) {
  return createStore(
    persistCombineReducers(persistConfig, {
      dck: createDckReducer(ItemTypes, ProcessTypes),
      router: routerReducer,
      app: appReducer,
      display: displayReducer,
      undo: undoReducer
    }),
    initialState,
    composeEnhancers(applyMiddleware(...middlewares))
  );
}

export const store = configureStore({ undo: undoBuffers });

export const history = createReduxHistory(store);

export const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);
