import { ActionTypes as appActionTypes } from "srx-shared";
import { ActionCreators as appActionCreators } from "srx-shared";

export const ActionTypes = {
  ...appActionTypes,
  SET_DISTRIBUTOR_STATUS: "SET_DISTRIBUTOR_STATUS",
  WEIGHTHISTORY_EXPORT: "WEIGHTHISTORY_EXPORT"
};

export const ActionCreators = {
  ...appActionCreators,

  setDistributorStatus: (id, status) => {
    return {
      type: ActionTypes.SET_DISTRIBUTOR_STATUS,
      id,
      status
    };
  }
};
