/* eslint react/display-name: "off" */
/* eslint react/prop-types: "off" */
import React from "react";

import { EditButton, RemoveButton } from "srx-shared/mui";

import { SmartShelfItem } from "./SmartShelfItem";

const Actions = ({ row }) => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      width: "100%",
      margin: "3px 8px"
    }}
  >
    <EditButton row={row} item={SmartShelfItem} tip="Smart Shelf" />
    <RemoveButton
      row={row}
      item={SmartShelfItem}
      tip="Smart Shelf"
      modalProps={{ size: "sm" }}
    >
      Are you sure you want to delete Smart Shelf:
      <br />
      <pre>
        <b>{row.serialNumber}</b>
      </pre>
    </RemoveButton>
  </div>
);

export default Actions;
