import { Button, Grid } from "@material-ui/core";
import { DckActionCreators } from "dck-redux";
import PropTypes from "prop-types";

import { useDispatchActions } from "srx-shared";

import { BillingERP } from "@items/types";

const actions = {
  sendInvoiceToErp: id => DckActionCreators.itemSave(BillingERP, id)
};

ReSubmitButton.propTypes = {
  form: PropTypes.object
};

export function ReSubmitButton(props) {
  const { id, isCanBill } = props.form.values;

  const { sendInvoiceToErp } = useDispatchActions(actions);

  const handleClick = () => sendInvoiceToErp(id);

  return (
    <Grid item xs={6}>
      <Button
        color="primary"
        variant="contained"
        onClick={handleClick}
        disabled={!isCanBill}
      >
        Re-Submit Last Invoice
      </Button>
    </Grid>
  );
}
