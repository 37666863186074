import React from "react";

import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

import { styles } from "./styles";

class LoadingMessage extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    message: PropTypes.string
  };
  static defaultProps = {
    message: "Loading..."
  };
  render() {
    const { classes, message } = this.props;
    return <div className={classes.loadingMessage}>{message}</div>;
  }
}

export const Loading = withStyles(styles)(LoadingMessage);
