import Hardware from "./Hardware";
import SmartShelves from "./SmartShelves";
import WeightHistory from "./WeightHistory";

export const hardwareTabs = [
  {
    title: "Hardware",
    key: "hardware",
    component: Hardware
  },
  {
    title: "Smart Shelves",
    key: "smart-shelves",
    component: SmartShelves
  },
  {
    title: "Weight History",
    key: "weight-history",
    component: WeightHistory
  }
];
