import _set from "lodash/set";
import { takeLatest, all, put } from "redux-saga/effects";

import {
  Sagas,
  Process,
  isActionLoad,
  isActionAdd,
  isActionDelete,
  deepMerge
} from "srx-shared";

import { ActionTypes, ActionCreators } from "@actions";
import * as RestApi from "@api";
import { TriggersMonthlyFee, CamerasMonthlyFee } from "@items/types";

function* loadMonthlyFeesSaga(action) {
  const type = action.itemType;
  const proc = new Process.Load(type, RestApi[`List${type}s`], {
    pageble: true
  });
  yield proc.start();
  try {
    yield proc.callApi();
    yield proc.setEntities();
    yield proc.setTotalPages();
    yield proc.stop();
  } catch (ex) {
    yield proc.fail(ex);
  }
}

function* updateMonthlyFeesSaga(action) {
  const type = action.itemType;
  const proc = new Process(
    ActionTypes.INLINE_UPDATE,
    type,
    RestApi[`Save${type}`]
  );
  yield proc.start();
  try {
    const { data } = action;
    let row = deepMerge({}, data.row);
    const { id } = row;
    if (
      data.selectedCell === "numOfTriggers" ||
      data.selectedCell === "numOfCameras"
    ) {
      row = { ...row, ...data.value };
    } else {
      _set(row, data.selectedCell, Number(data.value));
    }
    yield proc.callApi(id, row);
    yield Sagas.updateDckItem(type, id, row);
    yield put(
      ActionCreators.undoPush(type, {
        ...data,
        row: deepMerge({}, data.original)
      })
    );
    yield proc.stop();
  } catch (ex) {
    yield proc.fail(ex);
  }
}

// undo
export function* undoUpdateMonthlyFeesSaga(action) {
  const type = action.itemType;
  const proc = new Process(
    ActionTypes.UNDO_ACTION,
    type,
    RestApi[`Save${type}`]
  );
  yield proc.start();
  try {
    const {
      data: { row }
    } = action;
    const { id } = row;

    yield proc.callApi(id, row);
    yield Sagas.updateDckItem(type, id, row);
    yield put(ActionCreators.undoPop(type));
    yield proc.stop();
  } catch (ex) {
    yield proc.fail(ex);
  }
}

function* addMonthlyFeeSaga(action) {
  const type = action.itemType;
  const proc = new Process.Add(type, RestApi[`Add${type}`]);
  yield proc.start();
  try {
    yield proc.callApi(action.data);
    yield proc.stop();
    yield loadMonthlyFeesSaga(action);
  } catch (ex) {
    yield proc.fail(ex);
  }
}

function* removeMonthlyFeeSaga(action) {
  const type = action.itemType;
  const proc = new Process.Delete(type, RestApi[`Remove${type}`]);
  yield proc.start();
  try {
    yield proc.callApi(action.id);
    yield proc.stop();
    yield put(ActionCreators.undoReset(type));
    yield loadMonthlyFeesSaga(action);
  } catch (ex) {
    yield proc.fail(ex);
  }
}

function* monthlyFeesSaga() {
  yield all([
    takeLatest(isActionLoad(TriggersMonthlyFee), loadMonthlyFeesSaga),
    takeLatest(isActionLoad(CamerasMonthlyFee), loadMonthlyFeesSaga),
    takeLatest(isActionAdd(TriggersMonthlyFee), addMonthlyFeeSaga),
    takeLatest(isActionAdd(CamerasMonthlyFee), addMonthlyFeeSaga),
    takeLatest(isActionDelete(TriggersMonthlyFee), removeMonthlyFeeSaga),
    takeLatest(isActionDelete(CamerasMonthlyFee), removeMonthlyFeeSaga),
    takeLatest(
      action =>
        action.type === ActionTypes.INLINE_UPDATE &&
        action.itemType === TriggersMonthlyFee,
      updateMonthlyFeesSaga
    ),
    takeLatest(
      action =>
        action.type === ActionTypes.INLINE_UPDATE &&
        action.itemType === CamerasMonthlyFee,
      updateMonthlyFeesSaga
    ),
    takeLatest(
      action =>
        action.type === ActionTypes.UNDO_ACTION &&
        action.itemType === TriggersMonthlyFee,
      undoUpdateMonthlyFeesSaga
    ),
    takeLatest(
      action =>
        action.type === ActionTypes.UNDO_ACTION &&
        action.itemType === CamerasMonthlyFee,
      undoUpdateMonthlyFeesSaga
    )
  ]);
}

export default monthlyFeesSaga;
