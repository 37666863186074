import React from "react";

import PropTypes from "prop-types";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";

import { Routes } from "srx-shared";

import "./index.css";
import { store } from "./redux/store";
import { routes } from "./routes";

Application.propTypes = {
  history: PropTypes.object
};

export function Application({ history }) {
  return (
    <Provider store={store}>
      <DndProvider backend={HTML5Backend}>
        <Router history={history}>
          <Routes items={routes} />
        </Router>
      </DndProvider>
    </Provider>
  );
}
