import { DckActionTypes } from "dck-redux";
import { takeEvery, all } from "redux-saga/effects";

import { isActionImport, Sagas } from "srx-shared";

import * as RestApi from "@api";
import { EndPoint } from "@api/endpoints";
import * as ItemTypes from "@items/types";

function* importSaga() {
  yield all([
    takeEvery(
      isActionImport(ItemTypes.UpcGtinCatalog),
      Sagas.startImportProcess(RestApi, EndPoint)
    ),
    takeEvery(
      action =>
        action.type === DckActionTypes.ITEMS_LOAD &&
        action.itemType.endsWith("ImportStatus"),
      Sagas.loadImportStatus(RestApi)
    )
  ]);
}

export default importSaga;
