import React from "react";

import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import { withStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";
import cn from "classnames";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { compose } from "redux";

import { isMobile } from "../../utils";
import { showAccountStatus } from "../AccountStatus/helpers";
import { AppBarLogos } from "../AppBarLogos";
import { SideBarBuildVersion } from "../SideBarBuildVersion";
import { SideBarLogo } from "../SideBarLogo";
import { SideBarNav } from "../SideBarNav";
import { UserInfo } from "../UserInfo";
import { styles } from "./styles";

class SideBarComponent extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    width: PropTypes.string,
    withMessageBar: PropTypes.bool,
    open: PropTypes.bool,
    UserProps: PropTypes.object,
    NavProps: PropTypes.object,
    logos: PropTypes.array,
    LogoProps: PropTypes.object,
    onClose: PropTypes.func,
    children: PropTypes.any,
    history: PropTypes.object,
    salesForceRedirect: PropTypes.func
  };

  selectAccount = () => {
    const { UserProps, history } = this.props;
    const path = UserProps?.profile?.path;
    path && history.push(path);
  };

  renderDesktop = () => {
    const {
      classes,
      open,
      withMessageBar,
      UserProps,
      NavProps,
      LogoProps,
      children,
      logos,
      salesForceRedirect
    } = this.props;

    const userInfo = UserProps?.userInfo;

    const clsUserInfo = cn(
      showAccountStatus(userInfo) ? classes.headerWithStatus : classes.header,
      !open && classes.collapsed
    );

    return (
      <div className={classes.root}>
        <Drawer
          variant="permanent"
          PaperProps={{ elevation: open ? 8 : 5 }}
          classes={{
            paper: cn(
              classes.drawerPaper,
              !open && classes.collapsed,
              withMessageBar && classes.topShift
            )
          }}
          open={open}
        >
          {open && (
            <AppBarLogos logos={logos} onClickLogo={this.selectAccount} />
          )}
          <UserInfo open={open} className={clsUserInfo} {...UserProps} />
          <SideBarNav open={open} {...NavProps} />
          <SideBarLogo open={open} {...LogoProps} />
          {open && children}
          <SideBarBuildVersion open={open} />
        </Drawer>
      </div>
    );
  };

  renderMobile = () => {
    const { classes, open, UserProps, NavProps, LogoProps, logos, onClose } =
      this.props;

    return (
      <Drawer
        PaperProps={{ elevation: 8 }}
        classes={{
          paper: classes.drawerPaperMobile
        }}
        open={open}
        onBackdropClick={onClose}
      >
        <AppBarLogos logos={logos} />
        <UserInfo
          {...UserProps}
          open={open}
          className={classes.headerMobile}
          onClick={onClose}
        />
        <Divider className={classes.divider} />
        <SideBarNav {...NavProps} onClick={onClose} />
        <SideBarLogo open={open} {...LogoProps} />
        <SideBarBuildVersion open={open} />
      </Drawer>
    );
  };

  render() {
    return isMobile(this) ? this.renderMobile() : this.renderDesktop();
  }
}

export const SideBar = compose(
  withStyles(styles),
  withWidth(),
  withRouter
)(SideBarComponent);
