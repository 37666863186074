export const styles = theme => ({
  buttonsWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: "100%",
    maxWidth: 40,
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      transform: "rotate(90deg)",
      height: 30
    }
  },
  disabledLabel: {
    color: "rgba(0, 0, 0, 0.26)"
  },
  selectWrapper: {
    padding: "12px 0 12px 12px",
    minHeight: 226
  },
  selectList: {
    height: 200,
    width: "100%",
    overflowY: "auto",
    outline: "none",
    margin: 0,
    padding: 0,
    border: 0
  },
  selectListOption: {
    padding: 5,
    marginRight: 3
  },
  listTitle: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between"
  }
});
