export const styles = theme => ({
  root: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: "100%",
    padding: "0 10px",
    cursor: "default"
  },
  center: {
    display: "flex",
    alignItems: "center",
    width: "min-content",
    height: "100%",
    padding: "0 10px",
    cursor: "default",
    margin: "0 auto"
  },
  overflow: {
    overflowX: "hidden",
    textOverflow: "ellipsis",
    width: "100%"
  }
});
