/* eslint-disable react/prop-types */
/* eslint-disable import/no-unresolved */
import React from "react";

import { Button } from "@material-ui/core";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";
import { compose } from "redux";

import { ActionCreators } from "../../actions";

export const autoHideDuration = 7000; // ms

export const snackbarProps = ref => {
  const onClickDismiss = key => () => ref?.current?.closeSnackbar(key);

  return {
    anchorOrigin: {
      vertical: "top",
      horizontal: "right"
    },
    hideIconVariant: true,
    preventDuplicate: true,
    autoHideDuration,
    ref,
    action: key => <Button onClick={onClickDismiss(key)}>Dismiss</Button>
  };
};

class NotifierComponent extends React.Component {
  displayed = [];
  activeSnacks = [];

  storeDisplayed = id => {
    this.displayed = [...this.displayed, id];
  };

  addActiveSnack = key => {
    this.activeSnacks = [...this.activeSnacks, key];
  };

  shouldComponentUpdate({ notifications: newSnacks = [], hideNotifications }) {
    const { notifications: currentSnacks } = this.props;
    if (hideNotifications) return true;
    let notExists = false;

    for (let i = 0; i < newSnacks.length; i += 1) {
      if (notExists) continue;
      notExists =
        notExists ||
        !currentSnacks.filter(({ key }) => newSnacks[i].key === key).length;
    }
    return notExists;
  }

  componentDidUpdate() {
    const { notifications = [], hideNotifications } = this.props;
    if (hideNotifications) {
      this.activeSnacks.forEach(snackbar => this.props.closeSnackbar(snackbar));
      this.activeSnacks = [];
      this.displayed = [];
    }

    notifications.forEach(notification => {
      // Do nothing if snackbar is already displayed
      if (this.displayed.includes(notification.key)) return;
      // Display snackbar using notistack
      const key = this.props.enqueueSnackbar(
        notification.message,
        notification.options
      );
      // Keep track of snackbars that we've displayed
      this.storeDisplayed(notification.key);
      this.addActiveSnack(key);
      // Dispatch action to remove snackbar from redux store
      this.props.removeSnackbar(notification.key);
    });
  }

  render() {
    return null;
  }
}

const mapStateToProps = state => ({
  notifications: state.app.notifications,
  hideNotifications: state.app.hideNotifications
});

const mapDispatchToProps = {
  removeSnackbar: key => ActionCreators.removeNotification(key)
};

export const Notifier = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withSnackbar
)(NotifierComponent);
