import { Grid, Checkbox } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

import { getTransparentColor, isObject } from "srx-shared";

const styles = theme => ({
  root: {
    "&$disabled": {
      color: getTransparentColor(theme.palette.primary.light, 0.2)
    }
  },
  disabled: {},
  checkboxContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center"
  }
});

const allCheckboxesIsUnchecked = values =>
  Object.keys(values)
    .filter(field => isObject(values[field]))
    .reduce(
      (accum, field) =>
        accum && !values[field].definedSku && !values[field].undefinedSku,
      true
    );

const disableAllIdentifiedSkuFields = values =>
  Object.keys(values)
    .filter(field => isObject(values[field]))
    .forEach(
      field => (values[field] = { ...values[field], definedSku: false })
    );

const isDisableIdentifiedSkuField = (name, values) =>
  name !== "sku" && !values.sku?.definedSku;

const FieldWithCheckboxesComponent = props => {
  const {
    name,
    label,
    field,
    classes,
    disabled,
    form: {
      values: { allowCribCrawlList },
      values,
      setValues
    }
  } = props;

  const onChange = value => {
    const currentValues = { ...values };
    currentValues[name] = value;
    if (!currentValues.sku.definedSku)
      disableAllIdentifiedSkuFields(currentValues);
    if (allCheckboxesIsUnchecked(currentValues))
      currentValues["allowCribCrawlList"] = false;
    setValues(currentValues);
  };

  return (
    <Grid container>
      <Grid container item xs={4} alignItems="center" style={{ fontSize: 14 }}>
        {label}
      </Grid>
      <Grid item xs={3}>
        <div style={{ maxWidth: 110 }} className={classes.checkboxContainer}>
          <Checkbox
            color="primary"
            disabled={
              !allowCribCrawlList ||
              disabled ||
              isDisableIdentifiedSkuField(name, values)
            }
            checked={Boolean(field.value?.definedSku)}
            onChange={(e, value) =>
              onChange({ ...field.value, definedSku: value })
            }
            classes={{ root: classes.root, disabled: classes.disabled }}
          />
        </div>
      </Grid>
      <Grid item xs={3}>
        <div style={{ maxWidth: 160 }} className={classes.checkboxContainer}>
          <Checkbox
            color="primary"
            disabled={!allowCribCrawlList || disabled}
            checked={Boolean(field.value?.undefinedSku)}
            onChange={(e, value) =>
              onChange({ ...field.value, undefinedSku: value })
            }
            classes={{ root: classes.root, disabled: classes.disabled }}
          />
        </div>
      </Grid>
    </Grid>
  );
};

FieldWithCheckboxesComponent.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  field: PropTypes.object,
  form: PropTypes.object,
  classes: PropTypes.object,
  disabled: PropTypes.bool
};

export const FieldWithCheckboxes = withStyles(styles)(
  FieldWithCheckboxesComponent
);
