import React from "react";

import PropTypes from "prop-types";
import Creatable from "react-select/creatable";

import { getStyles } from "./SelectDropdownStyles";

const MAX_INPUT_LENGTH = 250;

const components = {
  DropdownIndicator: null
};

const createOption = label => ({
  label,
  value: label
});

export class SelectCreatable extends React.Component {
  constructor(props) {
    super();

    const values = Array.isArray(props.value) ? props.value : [];

    this.state = {
      inputValue: "",
      values: values.map(el => createOption(el))
    };
  }

  static propTypes = {
    value: PropTypes.array,
    placeholder: PropTypes.string,
    selectProps: PropTypes.object,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func
  };

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      const values = Array.isArray(this.props.value) ? this.props.value : [];
      this.setState({
        values: values.map(el => createOption(el))
      });
    }
  }

  handleChange = values => {
    const { onChange } = this.props;
    this.setState({ values });
    onChange && onChange((values || []).map(el => el.value));
  };

  handleInputChange = inputValue => {
    if (String(inputValue).length < MAX_INPUT_LENGTH) {
      this.setState({ inputValue });
    }
  };

  handleKeyDown = event => {
    const { inputValue, values } = this.state;

    const isCorrectValue =
      inputValue && !values.some(el => el.value === inputValue);

    if ((isCorrectValue && event.key === "Tab") || event.key === "Enter") {
      event.preventDefault();
      this.updateValues();
    }
  };

  handleBlur = () => {
    const { onBlur } = this.props;
    this.updateValues();
    onBlur && onBlur();
  };

  updateValues = () => {
    const { onChange } = this.props;
    const { inputValue, values } = this.state;

    if (!inputValue || values.some(el => el.value === inputValue)) {
      return;
    }

    const newValues = [...values, createOption(inputValue)];

    this.setState({ inputValue: "", values: newValues });
    onChange && onChange(newValues.map(el => el.value));
  };

  render() {
    const { placeholder, selectProps, onFocus } = this.props;
    const { inputValue, values } = this.state;
    return (
      <Creatable
        test-id="createable-select-list"
        components={components}
        inputValue={inputValue}
        isClearable
        isMulti
        menuIsOpen={false}
        onChange={this.handleChange}
        onInputChange={this.handleInputChange}
        onKeyDown={this.handleKeyDown}
        value={values}
        styles={getStyles(this.props)}
        onFocus={onFocus}
        onBlur={this.handleBlur}
        placeholder={placeholder}
        {...selectProps}
      />
    );
  }
}
