import { plural as pluralize } from "pluralize";
import { put, call } from "redux-saga/effects";

import { ActionCreators } from "../actions";
import { ApiGet } from "../api";
import { Process } from "../processes";
import { getImportedRecordsCount, readFile, sleep } from "../utils";
import { NotificationCreators } from "../utils/notifications";
import { uploadFile } from "./upload";

const MEGABYTE = 1024 * 1024;
const MAX_FILE_SIZE = 50 * MEGABYTE;
const MAX_ITERATIONS = 1000;
const MAX_ERRORS = 50;
const FINISHED_STATUSES = [
  "Finished",
  "FinishedWithErrors",
  "Validated",
  "ValidatedWithErrors"
];
const IMPORT_STATUS_VALIDATE = "PARSE_AND_VALIDATE";
const IMPORT_STATUS_SAVE = "PARSE_AND_SAVE";

const ERRORS = {
  MAX_FILE_SIZE: () =>
    `Maximum allowed file size is ${Math.round(MAX_FILE_SIZE / MEGABYTE)} MB`,
  TOO_LONG: itemType =>
    `Import process for ${pluralize(itemType)} takes too long... aborted`,
  UNABLE_UPLOAD: itemType =>
    `Unable to upload file for importing ${pluralize(itemType)}... aborted`
};

export async function getImportStatus(Endpoint, ...args) {
  let sendEmail = false;
  let status = "";
  let data = {};
  let iteration = 0;
  let errors = 0;
  while (!(FINISHED_STATUSES.includes(status) || sendEmail)) {
    if (iteration > MAX_ITERATIONS) throw Error("TOO_LONG");
    if (errors > MAX_ERRORS) throw Error("UNABLE_UPLOAD");
    if (iteration > 0) await sleep(3000);
    iteration++;
    try {
      const response = await Endpoint(...args);
      data = response.data;
      sendEmail = data.sendEmail;
      status = data.status;
    } catch (e) {
      errors++;
    }
  }
  return { sendEmail, status, data };
}

export const startImportProcess = (RestApi, EndPoint) =>
  function* (action) {
    const { itemType, data } = action;
    const { file, validateStatus } = data;
    const proc = new Process.Import(itemType);
    yield proc.start();
    try {
      const session = yield proc.session();
      const token = session.idToken;
      if (file) {
        if (file.size > MAX_FILE_SIZE) throw Error(ERRORS.MAX_FILE_SIZE());
        const binaryData = yield readFile(file);
        const endpoint = data.endpoint || `GetUpload${pluralize(itemType)}Url`;
        const response = yield call(ApiGet, EndPoint[endpoint](data), token);
        const { url, fileFolder, fileName } = response.data;
        yield uploadFile(url, binaryData);
        yield proc.loadImportStatus({
          type: IMPORT_STATUS_VALIDATE,
          fileFolder,
          fileName
        });
      }
      if (validateStatus) {
        const { fileFolder, fileName } = validateStatus;
        yield call(RestApi.ParseFile, token, fileFolder, fileName);
        yield proc.loadImportStatus({
          type: IMPORT_STATUS_SAVE,
          fileFolder,
          fileName
        });
      }
    } catch (ex) {
      yield proc.fail(ex);
    }
  };

export const loadImportStatus = RestApi => {
  return function* loadImportStatus(action) {
    const itemType = action.itemType.split("ImportStatus")[0];
    const { type, fileFolder, fileName } = action.filteringOptions;
    const proc = new Process.Import(itemType);
    try {
      const session = yield proc.session();
      const token = session.idToken;
      const { sendEmail, status, data } = yield getImportStatus(
        RestApi.ImportStatus,
        token,
        fileFolder,
        fileName,
        type
      );
      if (
        !sendEmail &&
        type === IMPORT_STATUS_VALIDATE &&
        status.startsWith("Validated")
      ) {
        Object.assign(data, { fileFolder, fileName });
        yield put(ActionCreators.setValidateStatus(itemType, data));
        return;
      }

      const notification = sendEmail
        ? NotificationCreators.emailImportStatus
        : status.endsWith("Errors")
        ? getImportedRecordsCount(data.description)
          ? NotificationCreators.partialImport
          : NotificationCreators.errorImport
        : NotificationCreators.successImport;

      yield put(ActionCreators.pushNotification(notification(data)));
      yield put(ActionCreators.importCompleted(itemType, data));
      yield proc.stop();
    } catch (ex) {
      const error = ERRORS[ex.message](itemType);
      yield proc.fail(error);
    }
  };
};
