import React from "react";

import Tooltip from "@material-ui/core/Tooltip";
import UndoIcon from "@material-ui/icons/Undo";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import * as Selectors from "../../actions/selectors";
import { ActionButton } from "../ActionButton";
import { CELL } from "./CellNavigator";

export const undoActionFor = item => {
  class UndoButton extends React.Component {
    static propTypes = {
      undoDisabled: PropTypes.bool
    };

    state = {
      cellState: null
    };

    componentDidMount() {
      window.addEventListener("cellState", this.handleCellStateEvent);
    }

    componentWillUnmount() {
      window.removeEventListener("cellState", this.handleCellStateEvent);
    }

    handleCellStateEvent = e => this.setState({ cellState: e.detail });

    handleClick = () => window.dispatchEvent(new Event("undoEvent"));

    render() {
      const { undoDisabled } = this.props;
      const { cellState } = this.state;

      return (
        <Tooltip title="Undo cell edit (Ctrl+Z)" placement="top">
          <span>
            <ActionButton
              icon={UndoIcon}
              title="Undo"
              onClick={this.handleClick}
              disabled={undoDisabled || cellState === CELL.EDIT}
            />
          </span>
        </Tooltip>
      );
    }
  }

  const mapStateToProps = state => ({
    undoDisabled: Selectors.undoDisabled(state, item.type)
  });

  return connect(mapStateToProps)(UndoButton);
};
