/* eslint react/display-name: "off" */
/* eslint react/prop-types: "off" */
import React from "react";

import * as V from "yup";

import { mapFiltersFor, mapFormFieldsFor } from "srx-shared";
import { Cell, Header } from "srx-shared/mui";

import { Actions } from "@comp/Distributors/Distributor/AdminUsers/Actions";
import { User } from "@items/types";

const USER_POSITIONS = [
  "VMI_SPECIALIST",
  "VMI_MANAGER",
  "INSIDE_SALES_CSR",
  "ACCOUNT_MANAGER",
  "SALES_MANAGER",
  "BRANCH_MANAGER",
  "DRIVER",
  "OPERATIONS_SPECIALIST",
  "OPERATIONS_MANAGER",
  "ADMIN",
  "IT_SPECIALIST",
  "IT_MANAGER",
  "OTHER"
];

const capitalize = value =>
  value[0].toUpperCase() + value.substring(1).toLowerCase();

const formatPosition = position =>
  position
    .split("_")
    .map(el => (["VMI", "IT", "CSR"].includes(el) ? el : capitalize(el)))
    .join(" ");

const PositionOptions = USER_POSITIONS.map(el => ({
  label: formatPosition(el),
  value: el
}));

export const AdminUserItem = {
  type: User,
  name: "AdminUser",
  title: "Admin User"
};

// table
AdminUserItem.columns = [
  {
    Header: "Email",
    accessor: "email",
    Cell: props => <Cell {...props} />
  },
  {
    Header: "First Name",
    accessor: "firstName",
    Cell: props => <Cell {...props} />
  },
  {
    Header: "Last Name",
    accessor: "lastName",
    Cell: props => <Cell {...props} />
  },
  {
    Header: "Position",
    accessor: "position",
    Cell: ({ value }) => <Cell>{value && formatPosition(value)}</Cell>
  },
  {
    Header: <Header center>{"Actions"}</Header>,
    maxWidth: 100,
    resizable: false,
    sortable: false,
    Cell: ({ row }) => <Actions row={row._original} />
  }
];

// filters
AdminUserItem.filtersConfig = {
  email: {
    name: "Email"
  },
  firstName: {
    name: "First Name"
  },
  lastName: {
    name: "Last Name"
  }
};
AdminUserItem.filters = mapFiltersFor(AdminUserItem, [
  "email",
  "firstName",
  "lastName"
]);

// form fields
AdminUserItem.fieldsConfig = {
  email: {
    label: "Email",
    initialValue: "",
    required: true,
    size: 12
  },
  firstName: {
    label: "First Name",
    initialValue: "",
    required: true,
    size: 6
  },
  lastName: {
    label: "Last Name",
    initialValue: "",
    required: true,
    size: 6
  },
  position: {
    label: "Position",
    type: "select",
    initialValue: "OTHER",
    required: true,
    options: PositionOptions
  }
};

AdminUserItem.schema = V.object({
  email: V.string().label("Email").email().required().max(255).nullable(),
  firstName: V.string().label("First Name").required().max(255).nullable(),
  lastName: V.string().label("Last Name").required().max(255).nullable(),
  position: V.string().label("Position").required()
});

AdminUserItem.createForm = mapFormFieldsFor(AdminUserItem, [
  "email",
  "firstName",
  "lastName",
  "position"
]);

AdminUserItem.editForm = mapFormFieldsFor(AdminUserItem, [
  "firstName",
  "lastName",
  "position"
]);

AdminUserItem.modalEditForm = true;
