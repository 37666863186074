import React from "react";

import PropTypes from "prop-types";

export const LabelComponent = ({ label, required }) => (
  <>
    {label}
    {required && <span>{"\u00A0*"}</span>}
  </>
);

LabelComponent.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  required: PropTypes.bool
};
