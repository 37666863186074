import React, { useEffect, useRef, useState } from "react";

import { TextField } from "@material-ui/core";
import PropTypes from "prop-types";

NumberField.propTypes = {
  NumberProps: PropTypes.object,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export function NumberField(props) {
  const { NumberProps, onChange, value: propValue, ...rest } = props;
  const { min, max, integer = false } = NumberProps || {};

  const inputRef = useRef(null);

  const [displayedValue, setDisplayedValue] = useState("");

  useEffect(() => {
    if (document.activeElement !== inputRef.current) {
      setDisplayedValue(propValue);
    }
  }, [propValue]);

  const handleChange = e => {
    const value = String(e.target.value);
    let displayedValue = null;
    let newValue = null;
    const regExp = integer ? /^-?[0-9]*$/ : /^-?([0-9]+[.])?[0-9]*$/;
    if (!value) {
      displayedValue = "";
      newValue = "";
    } else if (value === "-") {
      displayedValue = "-";
      newValue = "";
    } else if (regExp.test(value)) {
      let num = Number(value);
      if (num < Number.MIN_SAFE_INTEGER) {
        displayedValue = newValue = Number.MIN_SAFE_INTEGER;
      } else if (min !== undefined && num < min) {
        displayedValue = newValue = min;
      } else if (num > Number.MAX_SAFE_INTEGER) {
        displayedValue = newValue = Number.MAX_SAFE_INTEGER;
      } else if (min !== undefined && num > max) {
        displayedValue = newValue = max;
      } else {
        displayedValue = value;
        newValue = num;
      }
    }
    if (displayedValue !== null && newValue !== null) {
      setDisplayedValue(displayedValue);
      onChange({ target: { value: newValue } });
    }
  };

  const handleBlur = () =>
    setDisplayedValue(
      ["-", ""].includes(displayedValue) ? "" : Number(displayedValue)
    );

  return (
    <TextField
      {...rest}
      type="text"
      value={displayedValue}
      onChange={handleChange}
      onBlur={handleBlur}
      inputRef={inputRef}
    />
  );
}
