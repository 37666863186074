import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
  root: {
    padding: "16px 4px 4px",
    display: "flex",
    flex: "0 0 auto",
    justifyContent: "center",
    "& div[id='divider']:not(:last-child)": {
      width: 1,
      backgroundColor: "#DEDEDE",
      margin: "0 4px"
    }
  },
  logoContainer: {
    display: "flex",
    flex: 1,
    // 4:3 max container ratio for better expirience
    maxWidth: 160,
    maxHeight: 120
  },
  aspectRatio: {
    width: "100%"
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "contain"
  }
}));
