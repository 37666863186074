import React, { useEffect } from "react";

import { Menu, MenuItem } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { ItemTypeApplication } from "../../../config";
import { DckSelectors } from "../../../redux";
import { styles } from "./styles";

InlineSelectComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  fieldConfig: PropTypes.object,
  anchorEl: PropTypes.any,
  value: PropTypes.any,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onReset: PropTypes.func,
  onBlur: PropTypes.func
};

function InlineSelectComponent(props) {
  const {
    classes,
    value,
    anchorEl,
    fieldConfig = {},
    onChange,
    onFocus,
    onReset,
    onBlur
  } = props;

  const options = useSelector(state =>
    fieldConfig.dict
      ? DckSelectors.getItemData(state, ItemTypeApplication, fieldConfig.dict)
      : fieldConfig.options
  );

  const current = (options || []).find(el => el.value === value);
  const menuItems = [...options.filter(el => el.value !== value)];
  if (current) menuItems.unshift(current);

  useEffect(() => {
    onFocus && onFocus();
  }, [onFocus]);

  const handleChange = value => onChange && onChange(value, handleBlur);
  const handleReset = () => onReset && onReset();
  const handleBlur = () => onBlur && onBlur();

  return (
    <Menu
      classes={{ paper: classes.inlineSelect }}
      anchorEl={anchorEl}
      open
      onClose={handleReset}
    >
      {menuItems.map((el, index) => (
        <MenuItem
          key={index}
          value={el.value}
          selected={el.value === value}
          onClick={() => handleChange(el.value)}
          onKeyDown={e => e.keyCode === 13 && e.stopPropagation()}
          classes={{
            root: classes.inlineItem,
            selected: classes.inlineSelected
          }}
          dense
        >
          {el.label}
        </MenuItem>
      ))}
    </Menu>
  );
}

export const InlineSelect = withStyles(styles)(InlineSelectComponent);
InlineSelect.displayName = "InlineSelect";
