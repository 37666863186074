import React from "react";

import { Grid } from "@material-ui/core";
import { DckActionCreators, DckSelectors } from "dck-redux";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
  Selectors,
  tableFor,
  filtersFor,
  editFormFor,
  editModalFor,
  createFormFor,
  actionsFor,
  breadcrumbsFor
} from "srx-shared";
import { PageContainer } from "srx-shared/mui";

import { Distributor, LockerType } from "@items/types";
import { DEVICE_TYPE } from "config";

import { CameraConfig } from "./CameraConfig";
import { HardwareItem } from "./HardwareItem";
import HelpDialog from "./HelpDialog";
import { HardwareContext } from "./context";

const HardwareTable = tableFor(HardwareItem);
const HardwareFilters = filtersFor(HardwareItem);
const HardwareCreateForm = createFormFor(HardwareItem, {
  title: "Generate a serial number",
  size: "sm"
});

const HardwareActions = actionsFor(HardwareItem, {
  CreateForm: HardwareCreateForm
});

const HardwareEditForm = editFormFor(HardwareItem);
const HardwareEditModal = editModalFor(HardwareItem, {
  EditForm: HardwareEditForm,
  size: "sm"
});

const BreadcrumbsComponent = breadcrumbsFor();

const Warning = () => (
  <Grid container justify="center">
    <div style={{ fontSize: "small", color: "brown", textAlign: "center" }}>
      Please note, all serial numbers for the unprovisioned devices will be
      removed in 24h
    </div>
  </Grid>
);

class HardwareComponent extends React.PureComponent {
  static propTypes = {
    loadDistributorDict: PropTypes.func,
    selectLocker: PropTypes.func,
    resetCurrentHardware: PropTypes.func,
    currentHardware: PropTypes.object,
    successAdd: PropTypes.bool
  };

  showDialog = (type, open) => {
    this.setState({ [`open${type}ConfigDialog`]: open });
  };

  componentDidMount() {
    const { loadDistributorDict, selectLocker } = this.props;

    loadDistributorDict();
    selectLocker();
  }

  componentDidUpdate(prevProps) {
    const { successAdd, currentHardware } = this.props;
    if (
      !prevProps.successAdd &&
      successAdd &&
      (currentHardware || {}).type === "IOTHUB"
    ) {
      this.showDialog(DEVICE_TYPE.IOTHUB, true);
    }
  }

  state = {
    openIoTHubConfigDialog: false,
    openCameraConfigDialog: false,
    showDialog: this.showDialog
  };

  handleClose = type => () => {
    this.showDialog(type, false);
    this.props.resetCurrentHardware();
  };

  renderFilters = table => <HardwareFilters table={table} />;
  renderActions = table => <HardwareActions table={table} />;

  render() {
    const { currentHardware } = this.props;
    const { openIoTHubConfigDialog, openCameraConfigDialog } = this.state;

    return (
      <PageContainer renderHeader={() => <Warning />}>
        <BreadcrumbsComponent
          renderList={() => [{ title: "Manage hardware configuration" }]}
        />
        <HardwareContext.Provider value={this.state}>
          <HardwareTable
            renderFilters={this.renderFilters}
            renderActions={this.renderActions}
          />
        </HardwareContext.Provider>
        <HardwareEditModal />
        {Boolean(currentHardware && openIoTHubConfigDialog) && (
          <HelpDialog
            item={currentHardware}
            show={openIoTHubConfigDialog}
            onClose={this.handleClose(DEVICE_TYPE.IOTHUB)}
          />
        )}
        {Boolean(currentHardware && openCameraConfigDialog) && (
          <CameraConfig
            item={currentHardware}
            show={openCameraConfigDialog}
            onClose={this.handleClose(DEVICE_TYPE.CAMERA)}
          />
        )}
      </PageContainer>
    );
  }
}

const processAdd = `${HardwareItem.type.toUpperCase()}_ADD`;

const mapStateToProps = state => ({
  currentHardware: Selectors.selectActiveItem(state, HardwareItem.type),
  successAdd: DckSelectors.selectProcessSuccess(state, processAdd)
});

const mapDispatchToProps = {
  loadDistributorDict: () =>
    DckActionCreators.itemsLoad(Distributor, { dict: true }),
  selectLocker: () => DckActionCreators.itemsSelect(LockerType),
  resetCurrentHardware: () =>
    DckActionCreators.itemMakeActive(HardwareItem.type, null)
};

export default connect(mapStateToProps, mapDispatchToProps)(HardwareComponent);
