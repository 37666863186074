import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
  helperText: {
    margin: 0,
    position: "absolute",
    bottom: -15,
    color: theme.palette.primary.light,
    height: "1em"
  },
  helperTextError: {
    color: "red !important"
  }
}));
