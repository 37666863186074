import { Tooltip } from "@material-ui/core";
import HelpIcon from "@material-ui/icons/Help";

import { getQueryParamValue } from "../../utils";

export function ContentfulId(props) {
  const { id, children } = props;

  const showId = getQueryParamValue("showId");
  const isDevMode = showId === String(1);

  const handleClick = e => {
    e.stopPropagation();
    const textArea = document.createElement("textarea");
    textArea.value = id;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    textArea.remove();
  };

  if (isDevMode && id)
    return (
      <div onClick={e => e.stopPropagation()}>
        <Tooltip interactive placement="top" title={id}>
          <HelpIcon onClick={handleClick} />
        </Tooltip>
      </div>
    );

  return children;
}
