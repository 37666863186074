import React from "react";

import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

import { Filter } from "./Filter";
import { styles } from "./styles";

class TableFiltersComponent extends React.Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    filters: PropTypes.array,
    onChange: PropTypes.func,
    onClose: PropTypes.func,
    disabled: PropTypes.bool,
    values: PropTypes.object
  };
  static defaultProps = {
    filters: [],
    onClose: () => {}
  };

  render() {
    const { item, classes, filters, onChange, onClose, disabled, values } =
      this.props;
    const orderedFilters = filters
      .filter(el => el.selected)
      .sort((a, b) => a.selected - b.selected);

    return (
      <div className={classes.filters}>
        {orderedFilters.map(filter => {
          const value = values[filter.field]
            ? filter.isMulti
              ? values[filter.field]
              : values[filter.field][0]
            : "";
          return (
            <Filter
              key={`${item.type}-filter-${filter.field}`}
              value={value}
              item={item}
              filter={filter}
              onChange={value => onChange(filter, value)}
              onClose={value => onClose(filter, value)}
              disabled={disabled}
            />
          );
        })}
      </div>
    );
  }
}

export const TableFilters = withStyles(styles)(TableFiltersComponent);
