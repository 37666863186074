import { Button, Paper } from "@material-ui/core";
import { DckActionCreators, procAdding } from "dck-redux";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { makeSequence, Selectors, useDispatchActions } from "srx-shared";

import { VendingConfig, Hardware } from "@items/types";

import { SectionCell } from "./SectionCell";
import { TroubleshootingStatusInfo } from "./TroubleshootingStatusInfo";
import { useStyles } from "./styles";

const actions = {
  sendRequest: data => DckActionCreators.itemAdd(VendingConfig, data)
};

const cellIds = makeSequence(10);

DeviceSection.propTypes = {
  id: PropTypes.number,
  sectionCounters: PropTypes.object
};

export function DeviceSection(props) {
  const { id, sectionCounters } = props;

  const { sendRequest } = useDispatchActions(actions);

  const cls = useStyles();

  const vending = useSelector(state =>
    Selectors.selectActiveItem(state, Hardware)
  );

  const adding = useSelector(state => procAdding(state, VendingConfig));

  const handleClick = () => {
    const data = {
      id: vending.value,
      cells: cellIds.map(cellId => ({
        cell: cellId + 10 * (id - 1),
        count: 1,
        merged: false
      })),
      context: {}
    };
    sendRequest(data);
  };

  const rowTroubleshootingStatus = vending.rowTroubleshootingStatus.find(
    el => el.row === id
  );

  return (
    <div className={cls.paperWrapper}>
      <TroubleshootingStatusInfo data={rowTroubleshootingStatus} />
      <Paper className={cls.paper} elevation={2}>
        <div className={cls.rowIndex}>{String.fromCharCode(64 + id)}</div>
        <div className={cls.rowContainer}>
          {cellIds.map(cellId => (
            <SectionCell
              key={cellId}
              cellId={cellId}
              cellCounter={sectionCounters[cellId]}
            />
          ))}
        </div>
      </Paper>
      <Button
        color="primary"
        variant="contained"
        style={{ height: 30, marginLeft: 15 }}
        onClick={handleClick}
        disabled={adding}
      >
        TEST
      </Button>
    </div>
  );
}
