import React from "react";

import { Button, IconButton, Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { withRouter } from "react-router";

import { styles } from "./styles";

class LinkIconButtonComponent extends React.PureComponent {
  static propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    classes: PropTypes.object.isRequired,
    icon: PropTypes.node,
    label: PropTypes.any,
    disabled: PropTypes.bool,
    tip: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    to: PropTypes.string,
    rel: PropTypes.string,
    tipPlacement: PropTypes.string,
    history: PropTypes.object,
    onClick: PropTypes.func,
    "data-testid": PropTypes.string,
    color: PropTypes.string
  };
  static defaultProps = {
    tip: "",
    tipPlacement: "top",
    onClick: () => {}
  };

  handleClick = e => {
    const { history, to, rel } = this.props;
    e.stopPropagation();
    const link = to ? to : `${history.location.pathname}/${rel}`;
    link && history.push(link);
    this.props.onClick(history, link);
  };

  render() {
    const { classes, tip, icon, label, tipPlacement, id, disabled, color } =
      this.props;

    const testId =
      this.props["data-testid"] && `${this.props["data-testid"]}-button`;

    const button = icon ? (
      <IconButton
        classes={{ root: classes.root }}
        data-testid={testId || "icon-button"}
        onClick={this.handleClick}
        id={id}
        disabled={disabled}
        color={color}
        style={!color ? { color: "#888" } : {}}
      >
        {icon}
      </IconButton>
    ) : (
      <Button
        classes={{ root: classes.textButton }}
        color="primary"
        variant="text"
        data-testid={testId || "link-button"}
        onClick={this.handleClick}
        id={id}
        disabled={disabled}
      >
        {label}
      </Button>
    );
    return tip ? (
      <Tooltip
        title={tip}
        placement={tipPlacement}
        classes={{ tooltip: classes.tooltip }}
      >
        <div data-testid="icon-button">{button}</div>
      </Tooltip>
    ) : (
      button
    );
  }
}

export const LinkIconButton = withStyles(styles)(
  withRouter(LinkIconButtonComponent)
);
