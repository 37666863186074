import React from "react";

import { withStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";
import PropTypes from "prop-types";
import { compose } from "redux";

import { isMobile } from "../../utils";
import { AppPageTabs } from "../AppPageTabs";
import { AppPageTitle } from "../AppPageTitle";
import { styles } from "./styles";

class AppBarComponent extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    width: PropTypes.string,
    title: PropTypes.any,
    logo: PropTypes.string
  };

  render() {
    const {
      classes: { appbar, mobileAppBar },
      title
    } = this.props;
    return !isMobile(this) ? (
      <>
        <AppPageTitle title={title} />
        <div className={appbar}>
          <AppPageTabs />
        </div>
      </>
    ) : (
      <div className={mobileAppBar}>
        <AppPageTabs />
      </div>
    );
  }
}

export const AppBar = compose(withStyles(styles), withWidth())(AppBarComponent);
