import { takeEvery, all, put, select } from "redux-saga/effects";

import {
  Process,
  isActionLoad,
  ItemTypeApplication,
  Selectors
} from "srx-shared";

import { ActionCreators } from "@actions";
import * as RestApi from "@api";
import * as Types from "@items/types";

export function* isDeviceAssignedToIothub(id) {
  const devices = yield select(state =>
    Selectors.getItemData(state, ItemTypeApplication, "lockers")
  );
  const device = devices?.find(el => Number(el.value) === Number(id));
  return device?.iotHub;
}

export function* loadIoTHubsSaga(action) {
  const type = action.filteringOptions;
  const proc = new Process.Load(Types.IoTHub, RestApi.ListIoTHubs);
  yield proc.start();
  try {
    yield proc.callApi(String(type).toUpperCase());
    yield put(
      ActionCreators.setItemData(Types.IoTHub, `iotHubsFor${type}`, proc.data)
    );
    yield proc.stop();
  } catch (ex) {
    yield proc.fail(ex);
  }
}

const mapDict = data =>
  (data || []).map(el => ({
    label: `${el.value}${el.deviceName ? ` - ${el.deviceName}` : ""}`,
    value: el.id,
    iotHub: el.iotHub
  }));

export function* loadDistributorLockersAndStorages(action) {
  const { id } = action.filteringOptions;
  const proc = new Process.Load(Types.Locker, RestApi.GetDistributorLockers);
  const procStorages = new Process.Load(
    Storage,
    RestApi.GetDistributorStorages
  );
  yield proc.start();
  try {
    yield proc.callApi(id);
    yield procStorages.callApi(id);
    yield proc.set(proc.data);

    const dict = [...mapDict(proc.data), ...mapDict(procStorages.data)];
    yield put(ActionCreators.setItemData(ItemTypeApplication, "lockers", dict));

    yield proc.stop();
  } catch (ex) {
    yield proc.fail(ex);
  }
}

export function* loadAvailableLockerDoors(action) {
  const proc = new Process.Load(
    Types.AvailableLockerDoor,
    RestApi.GetAvailableLockerDoors
  );
  yield proc.start();
  try {
    const { id, door } = action.filteringOptions;
    let dict = [];
    const isAssigned = yield isDeviceAssignedToIothub(id);
    if (isAssigned) {
      yield proc.callApi(id);
      if (door && !proc.data.some(el => el.id === door.id)) {
        proc.data.push(door);
      }
      dict = proc.data
        .sort((a, b) => a.number - b.number)
        .map(el => ({
          label: el.number,
          value: el.id
        }));
    }
    yield put(
      ActionCreators.setItemData(ItemTypeApplication, "lockerDoors", dict)
    );
    yield proc.stop();
  } catch (ex) {
    yield proc.fail(ex);
  }
}

export function* getIoTHubAccessKeySaga(action) {
  const proc = new Process.Load(
    Types.IoTHubAccessKey,
    RestApi.GenerateIotHubAccessKey
  );
  yield proc.start();
  try {
    yield proc.callApi(action.data || action.filteringOptions);
    yield proc.setItemData("key", proc.response.key);
    yield proc.stop();
  } catch (ex) {
    yield proc.fail(ex);
  }
}

function* getIotHubImageUrlSaga() {
  const proc = new Process.Load(
    Types.IoTHubImage,
    RestApi.GetUrlToDownloadIotHubImage
  );
  yield proc.start();
  try {
    yield proc.callApi();
    yield proc.setItemData("downloadUrl", proc.response.data);
    yield proc.stop();
  } catch (ex) {
    yield proc.fail(ex);
  }
}

function* devicesSaga() {
  yield all([
    takeEvery(isActionLoad(Types.IoTHub), loadIoTHubsSaga),
    takeEvery(isActionLoad(Types.IoTHubAccessKey), getIoTHubAccessKeySaga),
    takeEvery(isActionLoad(Types.Locker), loadDistributorLockersAndStorages),
    takeEvery(
      isActionLoad(Types.AvailableLockerDoor),
      loadAvailableLockerDoors
    ),
    takeEvery(isActionLoad(Types.IoTHubImage), getIotHubImageUrlSaga)
  ]);
}

export default devicesSaga;
