export const styles = theme => ({
  lockerCellContainer: {
    position: "relative",
    display: "inline-flex",
    cursor: "pointer",
    justifyContent: "center",
    alignItems: "center",
    background: "#fff",
    color: "#aaa",
    border: "1px solid #eee",
    borderRadius: "5px"
  },
  cellIdWrapper: {
    position: "absolute",
    left: 1,
    top: 1,
    padding: "0 3px",
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    background: "rgba(255,255,255,0.7)"
  },
  cellId: {
    display: "flex",
    height: 16,
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    borderRadius: "30%",
    fontSize: "12px"
  },
  emptyCellId: {
    color: "#aaa"
  },
  notEmptyCellId: {
    color: "#777"
  }
});
