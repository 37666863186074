import React, { useContext } from "react";

import { Paper, ClickAwayListener } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

import { SmartShelfCell } from "./SmartShelfCell";
import { StickyToolbar } from "./StickyToolbar";
import { styles } from "./styles";

const SmartShelfComponent = props => {
  const context = useContext(props.context);
  const isSelected =
    context.selectedDoor && context.selectedDoor === props.smartShelf.id;

  const handleClick = e => {
    e.stopPropagation();
    context.clearSelection();
  };

  const { classes, smartShelf } = props;
  const { smartShelfPaper, doorContainer } = classes;
  return (
    <ClickAwayListener onClickAway={handleClick}>
      <Paper className={smartShelfPaper} onClick={handleClick}>
        {isSelected ? (
          <StickyToolbar smartShelf={smartShelf} context={props.context} />
        ) : null}
        <div className={doorContainer}>
          {smartShelf.cells &&
            smartShelf.cells.map((cell, index) => (
              <SmartShelfCell
                context={props.context}
                key={index}
                door={smartShelf.id}
                cell={cell}
                location={index}
              />
            ))}
        </div>
      </Paper>
    </ClickAwayListener>
  );
};
SmartShelfComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  smartShelf: PropTypes.object,
  context: PropTypes.object
};

export const SmartShelf = withStyles(styles)(SmartShelfComponent);
