import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  dipSwitchContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  overlay: {
    width: "100%",
    height: "100%",
    background: "white"
  },
  dipSwitchBack: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: 250,
    height: 140,
    border: "solid 2px #ddd",
    borderRadius: 4,
    margin: 0
  },
  dipSwitchLegend: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    padding: "0 20px"
  },
  dipSwitchConfig: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    padding: "10px 20px"
  },
  switchElemContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  switchElemNumber: {
    paddingTop: 5
  },
  hubAddress: {
    fontSize: "small",
    color: "#999"
  },
  switchIcon: {
    width: "0.5em",
    fontSize: "32px"
  }
});
