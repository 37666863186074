/* eslint react/display-name: "off" */
/* eslint react/prop-types: "off" */
/* eslint no-template-curly-in-string: "off" */
import React from "react";

import * as V from "yup";

import { Formatters, mapFormFieldsFor } from "srx-shared";
import { Cell } from "srx-shared/mui";

import { Actions } from "@comp/Fees/Actions";
import { isValidPrice } from "@comp/Fees/isValidPrice";
import { TriggersMonthlyFee } from "@items/types";
import { getAvoidDotsError } from "@utils";

import { InlineEditRange } from "./InlineEditRange";

V.addMethod(V.number, "avoidDots", function () {
  return this.transform(getAvoidDotsError);
});

export const TriggersMonthlyFeeItem = {
  type: TriggersMonthlyFee,
  name: "Triggers Monthly Fee"
};

// table
TriggersMonthlyFeeItem.columns = [
  {
    Header: "Number of Triggers",
    accessor: "numOfTriggers",
    inline: true,
    View: ({ row }) => `${row.rangeFrom} - ${row.rangeTo}`,
    InputComponent: InlineEditRange,
    Cell: props => <Cell {...props} />
  },
  {
    Header: "Monthly fee per assigned trigger",
    accessor: "value",
    inline: true,
    View: ({ value }) => Formatters.price(value),
    Cell: props => <Cell {...props} />
  },
  {
    Header: "Actions",
    width: 70,
    resizable: false,
    sortable: false,
    Cell: ({ row }) => (
      <Actions row={row._original} item={TriggersMonthlyFeeItem} />
    )
  }
];

// form fields
TriggersMonthlyFeeItem.fieldsConfig = {
  value: {
    label: "Monthly fee per assigned trigger",
    initialValue: "",
    required: true,
    size: 12,
    inlineValidation: isValidPrice
  },
  rangeFrom: {
    label: "Min Number of Triggers",
    initialValue: "",
    required: true,
    size: 6
  },
  rangeTo: {
    label: "Max Number of Triggers",
    initialValue: "",
    required: true,
    size: 6
  }
};

TriggersMonthlyFeeItem.schema = V.object({
  value: V.number()
    .label("Monthly fee")
    .typeError("${label} must be a number")
    .required()
    .min(0)
    .lessThan(1000000)
    .checkPrecision(),
  rangeFrom: V.number()
    .label("Min Number of Triggers")
    .typeError("${label} must be a number")
    .required()
    .min(0)
    .lessThan(10000000)
    .avoidDots()
    .test({
      name: "Comparison with Max",
      message: "Min Number of Triggers must be less than Max",
      test: function (value) {
        return value < this.parent.rangeTo;
      }
    }),
  rangeTo: V.number()
    .label("Max Number of Triggers")
    .typeError("${label} must be a number")
    .required()
    .min(0)
    .lessThan(10000000)
    .avoidDots()
    .test({
      name: "Comparison with Min",
      message: "Max Number of Triggers must be more than Min",
      test: function (value) {
        return value > this.parent.rangeFrom;
      }
    })
});

TriggersMonthlyFeeItem.createForm = mapFormFieldsFor(TriggersMonthlyFeeItem, [
  "value",
  "rangeFrom",
  "rangeTo"
]);
