import { DckActionCreators, DckSelectors } from "dck-redux";
import { takeLatest, all, call, put, select } from "redux-saga/effects";

import {
  Process,
  isActionLoad,
  isActionUpdate,
  ItemTypeApplication
} from "srx-shared";

import * as RestApi from "@api";
import * as Types from "@items/types";

import { loadDistributorsShelvesSaga } from "./smartShelvesSaga";

function* loadStorageSaga(action) {
  const proc = new Process.Load(Types.Storage, RestApi.GetHardware);
  const { storageId } = action.filteringOptions;
  yield proc.start();
  try {
    yield proc.callApi(storageId);
    yield proc.set(proc.data, storageId);

    const { distributorId } = proc.data || {};
    if (distributorId) {
      yield loadDistributorsShelvesSaga({
        filteringOptions: { distributorId, type: "STORAGE" }
      });
    }

    yield proc.makeActive(storageId);
    yield proc.stop();
  } catch (ex) {
    yield proc.fail(ex);
  }
}

function* loadStorageConfigSaga(action) {
  const proc = new Process.Load(Types.StorageConfig, RestApi.LoadStorageConfig);
  const { storageId } = action.filteringOptions;
  yield proc.start();
  try {
    yield proc.callApi(storageId);
    yield proc.makeActive(null);
    yield proc.set({ id: 1, data: proc.data });
    yield proc.makeActive(1);
    yield proc.stop();
  } catch (ex) {
    yield proc.fail(ex);
  }
}

function* assignSmartShelfSaga(action) {
  const storageId = String(action.id);
  const section = action.data;
  const proc = new Process.Update(
    Types.ShelfAssociation,
    RestApi.AssignSmartShelf
  );
  yield proc.start();
  try {
    yield proc.callApi(storageId, section);
    yield proc.stop();
  } catch (ex) {
    yield proc.fail(ex);
  }
}

function* updateStorageSectionSaga(action) {
  const storageId = String(action.id);
  const section = action.data;
  const { distributorId } = section;

  const proc = new Process.Update(
    Types.StorageSection,
    RestApi.UpdateStorageConfig
  );
  yield proc.start();
  try {
    yield proc.callApi(storageId, [section]);

    if (!section.noWeight) {
      yield assignSmartShelfSaga(action);
    }

    yield loadStorageConfigSaga({
      filteringOptions: { storageId }
    });

    yield loadDistributorsShelvesSaga({
      filteringOptions: { distributorId, type: "STORAGE" }
    });

    yield proc.stop();
  } catch (ex) {
    yield proc.fail(ex);
  }
}

function* loadStorageLocationsSaga(section) {
  const { hardware, number } = section;
  const proc = new Process.Load(
    Types.StorageSection,
    RestApi.GetStorageLocations
  );
  yield proc.start();
  try {
    yield proc.set([]);
    yield proc.callApi(hardware.id, number);
    yield proc.set(proc.data);
    yield proc.stop();
  } catch (ex) {
    yield proc.fail(ex);
  }
}

const getShelves = state =>
  DckSelectors.getItemData(state, ItemTypeApplication, "distributorShelves");

function* loadAvailableDistributorShelvesSaga(action) {
  const { section } = action.filteringOptions || {};
  const { smartShelfHardware: assignedShelf } = section;

  const proc = new Process.Load(Types.StorageSection);
  yield proc.start();
  try {
    const shelves = yield select(getShelves);

    const availableShelves = (shelves || [])
      .filter(el => !el.doorConfiguration)
      .map(el => ({
        value: el.id,
        label: el.serialNumber,
        address: el.address
      }));

    if (assignedShelf) {
      availableShelves.push({
        value: assignedShelf.id,
        label: assignedShelf.serialNumber,
        address: assignedShelf.address
      });
    }
    yield put(
      DckActionCreators.setItemData(
        ItemTypeApplication,
        "availableDistributorShelves",
        availableShelves
      )
    );
    yield proc.stop();
    yield call(loadStorageLocationsSaga, section);
  } catch (ex) {
    yield proc.fail(ex);
  }
}

function* storageSaga() {
  yield all([
    takeLatest(isActionLoad(Types.Storage), loadStorageSaga),
    takeLatest(isActionLoad(Types.StorageConfig), loadStorageConfigSaga),
    takeLatest(isActionUpdate(Types.StorageSection), updateStorageSectionSaga),
    takeLatest(
      isActionLoad(Types.StorageSection),
      loadAvailableDistributorShelvesSaga
    )
  ]);
}

export default storageSaga;
