import React from "react";

import { Grid, makeStyles, Tooltip } from "@material-ui/core";
import * as PropTypes from "prop-types";

import { Formatters } from "srx-shared";

const useStyles = makeStyles({
  label: {
    color: "#555",
    fontSize: 12,
    margin: "12px 0 8px 0"
  },
  value: {
    color: "#333",
    fontSize: 14
  },
  tooltip: {
    fontSize: 13
  }
});

CellContent.propTypes = {
  label: PropTypes.string,
  value: PropTypes.number,
  extraInfo: PropTypes.object
};

export function CellContent(props) {
  const { label, value, extraInfo } = props;

  const cls = useStyles();

  const by = extraInfo?.by ? ` by ${extraInfo.by}` : "";
  const at = extraInfo?.at ? ` on ${Formatters.datetime(extraInfo.at)}` : "";
  const title = by || at ? `Last weight ${extraInfo.type}${by}${at}` : "";

  return (
    <Tooltip title={title} classes={{ tooltip: cls.tooltip }}>
      <Grid container direction="column" alignItems="center">
        <div className={cls.label}>{label}</div>
        <Tooltip
          title={`Weight: ${value} g `}
          classes={{ tooltip: cls.tooltip }}
        >
          <div className={cls.value}>
            {value
              ? `${Math.round((value + Number.EPSILON) * 100) / 100} g`
              : "-"}
          </div>
        </Tooltip>
      </Grid>
    </Tooltip>
  );
}
