export const styles = theme => ({
  messagesContainer: {
    height: 28,
    fontSize: "10pt",
    color: "#888",
    display: "flex",
    whiteSpace: "nowrap",
    justifyContent: "center"
  },
  selectedMessage: {
    padding: "0 10px"
  },
  selectedNumber: {
    color: "#333"
  },
  selectionLink: {
    color: theme.palette.primary.main,
    cursor: "pointer",
    padding: "0 10px"
  },
  headSelector: {
    position: "absolute",
    margin: 0,
    padding: 0,
    top: 11,
    left: 5
  },
  rowSelector: {
    position: "relative",
    margin: 0,
    padding: 0,
    left: 5
  }
});
