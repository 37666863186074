import React from "react";

import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { withStyles } from "@material-ui/core/styles";
import cn from "classnames";
import _cloneDeep from "lodash/cloneDeep";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { createSelector } from "reselect";

import { ActionCreators } from "../../../actions";
import { CELL, CellContext } from "../CellNavigator";
import { InlineInput } from "./InlineInput";
import { InlineSelect } from "./InlineSelect";
import { styles } from "./styles";

class EditableCellComponent extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    item: PropTypes.object,
    column: PropTypes.object,
    row: PropTypes.object,
    index: PropTypes.number,
    value: PropTypes.any,
    inlineUpdateItem: PropTypes.func,
    InlineInput: PropTypes.any,
    context: PropTypes.object
  };

  static defaultProps = {
    InlineInput
  };

  cellRef = React.createRef();

  state = {
    value: this.props.value
  };

  componentDidUpdate(prevProps) {
    const { value, row, context } = this.props;
    const { setContext } = context;

    if (prevProps.value !== value) {
      this.setState({ value });
    }
    if (prevProps.row !== row && this.isCurrent()) {
      this.setRow(row);
    }
    if (
      prevProps.context.cellState === CELL.SELECTED &&
      this.props.context.cellState === CELL.EDIT &&
      this.isCurrent()
    ) {
      this.setState({ value }, () =>
        setContext({ editableRow: _cloneDeep(row) })
      );
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { row, context, index, column, value } = this.props;
    if (row !== nextProps.row) {
      return true;
    } else if (
      context !== nextProps.context &&
      ((nextProps.context.selectedRow === nextProps.index &&
        nextProps.context.selectedCell === nextProps.column.id) ||
        (context.selectedRow === index && context.selectedCell === column.id))
    ) {
      return true;
    } else if (value !== nextProps.value) {
      return true;
    } else if (this.state.value !== nextState.value) {
      return true;
    } else {
      return false;
    }
  }

  isCurrent = () => {
    const { column, index, context } = this.props;
    const { selectedCell, selectedRow } = context;
    return selectedCell === column.id && selectedRow === index;
  };

  isSelected = () =>
    this.props.context.cellState === CELL.SELECTED && this.isCurrent();

  isEdit = () =>
    this.props.context.cellState === CELL.EDIT &&
    this.isCurrent() &&
    Boolean(this.props.context.editableRow);

  getElement = type => {
    const column = this.props.column || {};
    return (
      column.InputComponent || (type === "select" ? InlineSelect : InlineInput)
    );
  };

  handleClickAway = () => {
    const { setContext } = this.props.context;
    if (this.isSelected())
      setContext({
        cellState: null,
        selectedCell: null,
        selectedRow: null,
        editableRow: null
      });
  };

  handleClick = () => {
    const { column, index, context } = this.props;
    const { setContext } = context;
    if (!this.isCurrent()) {
      setContext({
        cellState: CELL.SELECTED,
        selectedCell: column.id,
        selectedRow: index
      });
      return;
    }
    if (this.isSelected()) {
      setContext({ cellState: CELL.EDIT });
    }
  };

  handleChange = (value, callback) => {
    const item = this.props.context.item || {};
    const column = this.props.column || {};
    const fieldConfig = (item.fieldsConfig || {})[column.id] || {};

    this.setState({ value }, () => {
      const options = {
        value: this.state.value,
        row: this.props.context.editableRow,
        setValue: this.setValue,
        setRow: this.setRow
      };
      fieldConfig.onInlineChange
        ? fieldConfig.onInlineChange(options, callback)
        : callback && callback();
    });
  };

  handleFocus = () => {};

  handleReset = () => {
    const { value, context } = this.props;
    const { setContext } = context;
    this.setState({ value }, () =>
      setContext({ cellState: CELL.SELECTED, editableRow: null })
    );
  };

  handleBlur = () => {
    const { value } = this.state;
    const { inlineUpdateItem, column, row, context, index } = this.props;
    const { item, setContext, editableRow } = context;
    if (value !== this.props.value) {
      const data = {
        value,
        row: editableRow,
        original: row,
        selectedCell: column.id,
        selectedRow: index
      };
      inlineUpdateItem(item.type, data);
    }
    setContext({ cellState: CELL.SELECTED, editableRow: null });
  };

  setValue = (value, callback) => this.setState({ value }, callback);

  setRow = (row, callback) =>
    this.props.context.setContext({ editableRow: row }, callback);

  scrollView = () => {
    const node = this.cellRef.current;
    node &&
      node.scrollIntoView &&
      node.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "center"
      });
  };

  render() {
    const { classes, context, row } = this.props;
    if (!context || !row) return null;
    const column = this.props.column || {};
    const item = context.item || {};
    const fieldConfig = (item.fieldsConfig || {})[column.id] || {};
    const type = fieldConfig.type;
    const isSelected = this.isSelected();
    const isEdit = this.isEdit();
    const Input = this.getElement(type);

    const { value } = this.state;

    const classRoot = cn(
      classes.root,
      isSelected ? classes.selected : isEdit ? classes.edit : classes.selectable
    );
    //FIXME: Unexpected behavior (SRX-5699)
    //isSelected && this.scrollView();

    return (
      <ClickAwayListener onClickAway={this.handleClickAway}>
        <div
          ref={this.cellRef}
          className={classRoot}
          onClick={this.handleClick}
        >
          {isEdit && (
            <Input
              value={value}
              row={context.editableRow}
              original={this.props.row}
              validation={column.validation}
              anchorEl={this.cellRef.current}
              fieldConfig={fieldConfig}
              onChange={this.handleChange}
              onFocus={this.handleFocus}
              onReset={this.handleReset}
              onBlur={this.handleBlur}
              setValue={this.setValue}
              setRow={this.setRow}
            />
          )}
          {!isEdit &&
            column.View &&
            column.View({
              value,
              column,
              item,
              row: this.props.row
            })}
          {!isEdit && !column.View && (
            <span className={classes.overflow}>{value}</span>
          )}
        </div>
      </ClickAwayListener>
    );
  }
}

const selectorsPull = createSelector(
  itemType => itemType,
  () => []
);

const getSelector = (itemType, index) => {
  const selectors = selectorsPull(itemType);
  let selector = selectors[index];
  if (!selector) {
    selector = createSelector(
      state => state.dck.items.getIn([itemType, "items", index]),
      data => data?.toJS() || null
    );
    selectors[index] = selector;
  }
  return selector;
};

const mapStateToProps = (state, ownProps) => {
  const { context, index } = ownProps;
  const selector =
    context && context.item ? getSelector(context.item.type, index) : null;
  const props = {
    row: selector ? selector(state) : {}
  };
  return props;
};

const mapDispatchToProps = {
  inlineUpdateItem: (itemType, data) =>
    ActionCreators.inlineUpdateItem(itemType, data)
};

const EditableCellWrapper = compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(EditableCellComponent);

export const EditableCell = props => (
  <CellContext.Consumer>
    {context => <EditableCellWrapper {...props} context={context} />}
  </CellContext.Consumer>
);

EditableCell.displayName = "EditableCell";
