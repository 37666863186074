import React from "react";

import { makeStyles } from "@material-ui/core";
import * as PropTypes from "prop-types";

import { Icons } from "srx-shared";

const useStyles = makeStyles({
  root: {
    display: "flex",
    alignItems: "center"
  },
  number: {
    width: 28,
    height: 28,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#eee",
    color: "#777",
    fontSize: 14,
    borderRadius: "50%",
    margin: 8
  },
  icon: {
    color: "#777"
  }
});

SectionState.propTypes = {
  id: PropTypes.number,
  section: PropTypes.object
};

export function SectionState(props) {
  const { id, section } = props;
  const { noWeight, smartShelfHardware } = section;

  const cls = useStyles();

  const showIcon = noWeight || smartShelfHardware?.serialNumber;

  return (
    <div className={cls.root}>
      <div className={cls.number}>{id}</div>
      {showIcon && (
        <div className={cls.icon}>
          <Icons.Weights />
        </div>
      )}
    </div>
  );
}
