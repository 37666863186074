import React from "react";

import cn from "classnames";
import PropTypes from "prop-types";

export default class Expander extends React.Component {
  static propTypes = {
    expanded: PropTypes.bool,
    onClick: PropTypes.func
  };

  state = {
    expanded: false
  };

  componentDidUpdate(prevProps) {
    if (prevProps.expanded !== this.props.expanded) {
      this.setState({ expanded: this.props.expanded });
    }
  }

  handleClick = () => {
    const expanded = !this.state.expanded;
    this.setState({ expanded });
    this.props.onClick && this.props.onClick(expanded);
  };

  render() {
    return (
      <button className="rt-header-expander" onClick={this.handleClick}>
        <div className={cn("rt-expander", this.state.expanded && "-open")}>
          {" "}
          &bull;
        </div>
      </button>
    );
  }
}
