import { DckSelectors } from "dck-redux";
import { takeLatest, all, select } from "redux-saga/effects";

import {
  Process,
  isActionLoad,
  isActionAdd,
  isActionUpdate,
  isActionDelete
} from "srx-shared";

import * as RestApi from "@api";
import { User, Distributor } from "@items/types";

function* selectCurrentDistributor() {
  const currentDistributor = yield select(state =>
    DckSelectors.selectActiveItemId(state, Distributor)
  );
  return currentDistributor;
}

function* loadUsersSaga() {
  const proc = new Process.Load(User, RestApi.ListUsers, {
    pageble: true
  });
  yield proc.start();
  try {
    const distributorId = yield selectCurrentDistributor();
    yield proc.callApi(distributorId);
    yield proc.setEntities();
    yield proc.setTotalPages();
    yield proc.stop();
  } catch (ex) {
    yield proc.fail(ex);
  }
}

function* addUsersSaga(action) {
  const proc = new Process.Add(User, RestApi.AddUser);
  yield proc.start();
  try {
    const distributorId = yield selectCurrentDistributor();
    yield proc.callApi(distributorId, action.data);
    yield proc.stop();
    yield loadUsersSaga();
  } catch (ex) {
    yield proc.fail(ex);
  }
}

function* saveUsersSaga(action) {
  const proc = new Process.Update(User, RestApi.UpdateUser);
  yield proc.start();
  try {
    const distributorId = yield selectCurrentDistributor();
    yield proc.callApi(distributorId, action.id, action.data);
    yield proc.stop();
    yield loadUsersSaga();
  } catch (ex) {
    yield proc.fail(ex);
  }
}

function* deleteUsersSaga(action) {
  const proc = new Process.Delete(User, RestApi.RemoveUser);
  yield proc.start();
  try {
    const distributorId = yield selectCurrentDistributor();
    yield proc.callApi(distributorId, action.id);
    yield proc.stop();
    yield loadUsersSaga();
  } catch (ex) {
    yield proc.fail(ex);
  }
}

function* usersSaga() {
  yield all([
    takeLatest(isActionLoad(User), loadUsersSaga),
    takeLatest(isActionAdd(User), addUsersSaga),
    takeLatest(isActionUpdate(User), saveUsersSaga),
    takeLatest(isActionDelete(User), deleteUsersSaga)
  ]);
}

export default usersSaga;
