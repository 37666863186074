import * as React from "react";

import FormHelperText from "@material-ui/core/FormHelperText/FormHelperText";
import _isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";

const SelectHelperText = props => {
  const { id, helperText, formHelperTextProps } = props;

  if (_isEmpty(helperText)) {
    return null;
  }

  return (
    <FormHelperText id={id} {...formHelperTextProps} margin="dense" filled>
      {helperText}
    </FormHelperText>
  );
};

SelectHelperText.propTypes = {
  id: PropTypes.any,
  helperText: PropTypes.string,
  formHelperTextProps: PropTypes.object
};

export default SelectHelperText;
