import { mapFormFieldsFor } from "srx-shared";

import { ShipToTemplateSettings } from "@redux/items/types";

import { LinksComponent } from "../Common/LinksComponent";

export const QRCodeCheckoutSettingsItem = {
  type: ShipToTemplateSettings,
  fieldsConfig: {
    enableCheckoutSoftware: {
      label: "Enable Checkout Software",
      type: "switch",
      size: 6,
      initialValue: "false",
      options: [
        { label: "On", value: "true" },
        { label: "Off", value: "false" }
      ]
    },
    enableQrCodeKit: {
      label: "QR Code Kit",
      type: "switch",
      size: 6,
      initialValue: "false",
      options: [
        { label: "On", value: "true" },
        { label: "Off", value: "false" }
      ]
    },
    links: {
      Component: LinksComponent
    }
  },
  fields: ["enableCheckoutSoftware", "enableQrCodeKit", "links"]
};
QRCodeCheckoutSettingsItem.formConfig = mapFormFieldsFor(
  QRCodeCheckoutSettingsItem
);
