import React from "react";

import { makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";

import { useMobile } from "../../../hooks";

const useStyles = makeStyles({
  root: {
    display: "block",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textAlign: "start"
  },
  center: {
    display: "block",
    padding: "0 10px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textAlign: "center",
    justifyContent: "center"
  }
});

Header.propTypes = {
  center: PropTypes.bool
};
export function Header(props) {
  const { children, center } = props;
  const classes = useStyles();
  const isMobile = useMobile();

  return (
    <div
      className={!isMobile ? (center ? classes.center : classes.root) : ""}
      data-testid="header-wrapper"
    >
      {children}
    </div>
  );
}
