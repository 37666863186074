import { Grid } from "@material-ui/core";
import * as PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { Icons, Selectors, HARDWARE_STATUS } from "srx-shared";

import * as Types from "@items/types";

import { useStyles } from "../styles";

const colorsStatus = {
  [HARDWARE_STATUS.RED]: {
    style: {
      backgroundColor: "brown",
      color: "white"
    },
    Icon: Icons.WeightsError
  },
  [HARDWARE_STATUS.GREY]: {
    style: {},
    Icon: () => <Icons.Weights style={{ color: "#777" }} />
  },
  [HARDWARE_STATUS.GREEN]: {
    style: {},
    Icon: Icons.WeightsOk
  },
  [HARDWARE_STATUS.ORANGE]: {
    style: {
      backgroundColor: "#EF8A20",
      color: "white"
    },
    Icon: Icons.Weights
  }
};

export function SectionState(props) {
  const { id, section } = props;

  const cls = useStyles();

  const currentHardware = useSelector(state =>
    Selectors.selectActiveItem(state, Types.Storage)
  );
  const hardwareState = useSelector(state =>
    Selectors.selectActiveItem(state, Types.HardwareStates)
  );

  let status = currentHardware?.status || HARDWARE_STATUS.GREY;

  if (status === HARDWARE_STATUS.GREEN && !section.noWeight) {
    if (section.smartShelfHardware) {
      const sectionState = hardwareState
        ? hardwareState.state[0].doors.find(
            sectionState => sectionState.number === section.number
          )
        : null;
      if (sectionState && !(sectionState.cells && sectionState.cells.length)) {
        status = HARDWARE_STATUS.ORANGE;
      }
    } else {
      status = HARDWARE_STATUS.GREY;
    }
  }

  const showIcon =
    section.noWeight || section?.smartShelfHardware?.serialNumber;

  const { Icon, style } = colorsStatus[status];

  return (
    <Grid container direction="column" style={{ width: 64 }}>
      <Grid item>
        <div className={cls.doorNumber} style={style}>
          {id}
        </div>
      </Grid>
      {showIcon && (
        <Grid item className={cls.iconWrapper}>
          {section.noWeight ? (
            <Icons.NoWeights style={{ color: "#777" }} />
          ) : (
            <Icon />
          )}
        </Grid>
      )}
    </Grid>
  );
}

SectionState.propTypes = {
  id: PropTypes.number,
  section: PropTypes.object
};
