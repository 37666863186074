import React from "react";

import { IconButton, makeStyles } from "@material-ui/core";
import IconMoreVert from "@material-ui/icons/MoreVert";
import PropTypes from "prop-types";

const useStyles = makeStyles({
  contextButtonWrapper: {
    position: "absolute",
    zIndex: 50,
    backgroundColor: "white",
    boxShadow: "-3px 0 8px -3px rgba(0, 0, 0, 0.2)",
    height: 45,
    width: 25
  },
  contextButton: {
    height: 45,
    width: 15
  }
});

ContextButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func
};
function ContextButton(props) {
  const { onClick, disabled } = props;
  const classes = useStyles();
  return (
    <div className={classes.contextButtonWrapper}>
      <IconButton
        disableRipple
        classes={{ root: classes.contextButton }}
        onClick={onClick}
        disabled={disabled}
        data-testid="context-button"
      >
        <IconMoreVert />
      </IconButton>
    </div>
  );
}

export default ContextButton;
