import React from "react";

import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/CancelOutlined";
import ApplyIcon from "@material-ui/icons/Done";
import FilterIcon from "@material-ui/icons/FilterList";
import PropTypes from "prop-types";

import { styles } from "./styles";

class AddFilterComponent extends React.Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    filters: PropTypes.array,
    onSelect: PropTypes.func,
    disabled: PropTypes.bool,
    mobile: PropTypes.bool
  };
  static defaultProps = {
    filters: [],
    onSelect: () => {}
  };

  state = {
    anchorEl: null
  };

  handleAddFilter = event => this.setState({ anchorEl: event.currentTarget });

  handleClose = () => this.setState({ anchorEl: null });

  handleSelectFilter = filter => {
    !this.props.disabled && this.props.onSelect(filter);
    this.handleClose();
  };

  disabledItem = filter =>
    typeof filter.disabled === "function" && filter.disabled(this.props);

  render() {
    const { item, classes, filters, disabled, mobile } = this.props;
    const currentFilters = filters.filter(el => !el.selected);
    const id = `${item.type}-filters-popup`;
    const { anchorEl } = this.state;
    return (
      <>
        <Button
          size="small"
          className={classes.actionButton}
          aria-owns={anchorEl ? id : undefined}
          aria-haspopup="true"
          disabled={disabled || currentFilters.length === 0}
          onClick={this.handleAddFilter}
          startIcon={<FilterIcon />}
        >
          {!mobile && "Filter"}
        </Button>
        {currentFilters.length > 0 && (
          <Menu
            id={id}
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={this.handleClose}
            disableAutoFocusItem={true}
            MenuListProps={{
              dense: true
            }}
          >
            {currentFilters.map(filter => (
              <MenuItem
                key={`${id}-${filter.field}`}
                classes={{ root: classes.dropdownListItem }}
                onClick={() => this.handleSelectFilter(filter)}
                disabled={this.disabledItem(filter)}
              >
                {`by ${filter.name}`}
              </MenuItem>
            ))}
          </Menu>
        )}
      </>
    );
  }
}

class FiltersActionComponent extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    Icon: PropTypes.any,
    title: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    mobile: PropTypes.bool
  };
  static defaultProps = {
    title: "",
    onClick: () => {}
  };

  handleClick = () => this.props.onClick();

  render() {
    const { classes, disabled, Icon, title, mobile } = this.props;
    return (
      <Button
        color="primary"
        size="small"
        className={classes.actionButton}
        disabled={disabled}
        onClick={this.handleClick}
        startIcon={<Icon />}
      >
        {!mobile && title}
      </Button>
    );
  }
}

const FiltersAction = withStyles(styles)(FiltersActionComponent);

export const AddFilter = withStyles(styles)(AddFilterComponent);

export const ApplyFilters = props => (
  <FiltersAction Icon={ApplyIcon} title="Apply" {...props} />
);

export const ClearFilters = props => (
  <FiltersAction Icon={ClearIcon} title="Clear" {...props} />
);
