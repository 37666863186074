/* eslint react/display-name: "off" */
/* eslint react/prop-types: "off" */
import * as V from "yup";

import { checkPrecision } from "@utils";

V.addMethod(V.number, "checkPrecision", function () {
  return this.test(
    "testPrecision",
    "Number must have 2 digits after decimal point",
    value => checkPrecision(value, 2)
  );
});

export const isValidPrice = value =>
  V.number().min(0).lessThan(975000).checkPrecision();

export const isValidValue = value => V.string().max(255).nullable();
