import React from "react";

import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";
import CloseIcon from "@material-ui/icons/Close";
import PropTypes from "prop-types";
import { compose } from "redux";

import { isMobile } from "../../utils";
import { styles } from "./styles";

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          data-testid="close-button"
          className={classes.closeButton}
          onClick={onClose}
          aria-label="Close"
          size="small"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const ModalDialogComponent = props => {
  const {
    classes,
    children,
    title,
    open,
    expandable,
    onClose,
    size,
    width,
    ...rest
  } = props;

  const maxWidth = isMobile({ props }) ? String(width) : size || "md";

  return (
    <Dialog
      open={open}
      data-testid="modal-dialog"
      onClose={onClose}
      classes={{ paper: expandable ? classes.paperExpandable : classes.paper }}
      aria-labelledby="customized-dialog-title"
      maxWidth={maxWidth}
      fullWidth
      transitionDuration={1}
      disableBackdropClick
      disableRestoreFocus
      {...rest}
    >
      <DialogTitle id="customized-dialog-title" onClose={onClose}>
        {title}
      </DialogTitle>
      {children}
    </Dialog>
  );
};

ModalDialogComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.any,
  title: PropTypes.string,
  open: PropTypes.bool,
  expandable: PropTypes.bool,
  onClose: PropTypes.func,
  size: PropTypes.string,
  width: PropTypes.string
};

export const ModalDialog = compose(
  withStyles(styles),
  withWidth()
)(ModalDialogComponent);
