export const styles = theme => ({
  label: {
    paddingBottom: 2,
    fontSize: "14px",
    color: "#333"
  },
  checkbox: {
    padding: 5,
    marginLeft: 7
  },
  checkboxDense: {
    padding: 0,
    marginRight: 8
  },
  helperText: {
    minHeight: 18,
    fontSize: "0.75rem",
    color: theme.palette.primary.light
  },
  option: {}
});
