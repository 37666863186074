import RefreshIcon from "@material-ui/icons/CachedOutlined";
import { DckActionCreators } from "dck-redux";
import * as PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { Selectors, HARDWARE_STATUS, useDispatchActions } from "srx-shared";
import { ActionButton } from "srx-shared/mui";

import * as Types from "@items/types";

import { useStyles } from "./styles";

const actions = {
  refreshDeviceState: locker =>
    DckActionCreators.itemSave(Types.HardwareStates, null, locker),
  loadLockerConfig: lockerId =>
    DckActionCreators.itemSelect(Types.LockerConfig, lockerId)
};

RefreshState.propTypes = {
  locker: PropTypes.object
};

export function RefreshState(props) {
  const { locker } = props;

  const cls = useStyles();
  const { id } = useParams();

  const currentHardware = useSelector(state =>
    Selectors.selectActiveItem(state, Types.Hardware)
  );

  const { refreshDeviceState, loadLockerConfig } = useDispatchActions(actions);

  const handleClick = () => {
    loadLockerConfig(id);
    refreshDeviceState(locker);
  };

  const shift = locker?.lockerType?.columnsQuantity % 2 ? 1 : 2;

  return (
    <div className={cls.planogramContainer}>
      <div
        className={cls.buttonContainer}
        style={{
          maxWidth: shift * 560
        }}
      >
        <ActionButton
          title="Refresh"
          icon={RefreshIcon}
          disabled={
            !currentHardware || currentHardware.status !== HARDWARE_STATUS.GREEN
          }
          onClick={handleClick}
        />
      </div>
    </div>
  );
}
