import React from "react";

import cn from "classnames";
import PropTypes from "prop-types";

import { CellNavigator } from "../CellNavigator";

export const TbodyEditable = props => {
  const { className, children, rows, editableColumns, item, ...rest } = props;
  const navigatorProps = { rows, editableColumns, item };
  return (
    <CellNavigator {...navigatorProps}>
      <div className={cn("rt-tbody", className)} {...rest}>
        {children}
      </div>
    </CellNavigator>
  );
};

TbodyEditable.propTypes = {
  className: PropTypes.string,
  item: PropTypes.object,
  rows: PropTypes.number,
  editableColumns: PropTypes.array
};

TbodyEditable.displayName = "TbodyEditable";
