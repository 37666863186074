import React from "react";

import { Grid } from "@material-ui/core";

import {
  tableFor,
  breadcrumbsFor,
  undoActionFor,
  actionsFor,
  createFormFor
} from "srx-shared";

export const monthlyFeePageFor = item => {
  const MonthlyFeesTable = tableFor(item, {
    sortable: false
  });
  const UndoAction = undoActionFor(item);
  const Breadcrumbs = breadcrumbsFor();
  const MonthlyFeeForm = createFormFor(item, {
    title: `Add new ${item.name}`,
    size: "sm"
  });
  const MonthlyFeesActions = actionsFor(item, {
    CreateForm: MonthlyFeeForm,
    Custom: UndoAction
  });

  const MonthlyFees = () => {
    const renderActions = table => <MonthlyFeesActions table={table} />;

    return (
      <Grid container justify="center">
        <Grid item style={{ width: 500, height: "100%" }}>
          <Breadcrumbs
            renderList={() => [{ title: "Fees" }, { title: `${item.name}s` }]}
          />
          <MonthlyFeesTable renderActions={renderActions} />
        </Grid>
      </Grid>
    );
  };

  return MonthlyFees;
};
