/* eslint-disable react/prop-types */
import { date } from "srx-shared/formatters";
import { Cell } from "srx-shared/mui";

import { ShipToTemplate } from "@items/types";

import { Actions } from "./Actions";

const formatStatus = value => {
  const style = {
    color: value === "DRAFT" ? "brown" : "green"
  };
  return <span style={style}>{value}</span>;
};

export const ShipToTemplateItem = {
  type: ShipToTemplate,
  name: "ShipToTemplate",
  title: "ShipToTemplate"
};

// table
ShipToTemplateItem.columns = [
  {
    Header: "Template name",
    accessor: "templateName",
    sortable: false,
    Cell: ({ value }) => <Cell>{value}</Cell>
  },
  {
    Header: "Created by",
    accessor: "createdBy",
    sortable: false,
    Cell: ({ value }) => <Cell>{value}</Cell>
  },
  {
    Header: "Created on",
    accessor: "createdAt",
    sortable: false,
    Cell: ({ value }) => <Cell>{date(value)}</Cell>
  },
  {
    Header: "Last edited by",
    accessor: "updatedBy",
    sortable: false,
    Cell: ({ value }) => <Cell>{value}</Cell>
  },
  {
    Header: "Last edited on",
    accessor: "updatedAt",
    sortable: false,
    Cell: ({ value }) => <Cell>{date(value)}</Cell>
  },
  {
    Header: "Status",
    accessor: "status",
    sortable: false,
    Cell: ({ value }) => <Cell>{formatStatus(value)}</Cell>
  },
  {
    Header: "Actions",
    width: 140,
    resizable: false,
    sortable: false,
    Cell: ({ row }) => <Actions row={row._original} />
  }
];
