import React from "react";

import DateFnsUtils from "@date-io/date-fns";
import {
  IconButton,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/CancelOutlined";
import {
  MuiPickersUtilsProvider,
  DateTimePicker as MuiDateTimePicker,
  DatePicker as MuiDatePicker
} from "@material-ui/pickers";
import _debounce from "lodash/debounce";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";

import { Selectors } from "../..";
import { ActionCreators } from "../../actions";
import {
  getPropTypesForDict,
  stateToPropsForDict
} from "../../actions/helpers";
import { splitFromTo } from "../../utils";
import { LabelComponent } from "../Form/LabelComponent";
import { RangeDatePicker } from "../Form/RangeDatePicker";
import { SelectField } from "../Form/SelectField";
import { styles } from "./styles";

const DEBOUNCE_DELAY = 3000;

export const DatePicker = props => (
  <MuiPickersUtilsProvider utils={DateFnsUtils}>
    <MuiDatePicker
      {...props}
      variant="inline"
      format="MM/dd/yyyy"
      autoOk
      helperText=""
      error={false}
    />
  </MuiPickersUtilsProvider>
);

export const DateTimePicker = props => (
  <MuiPickersUtilsProvider utils={DateFnsUtils}>
    <MuiDateTimePicker
      {...props}
      variant="inline"
      ampm
      format="MM/dd/yyyy HH:mm"
      autoOk
      helperText=""
      error={false}
    />
  </MuiPickersUtilsProvider>
);

class FilterComponent extends React.Component {
  static propTypes = {
    ...getPropTypesForDict(),
    item: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    filter: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    onClose: PropTypes.func,
    disabled: PropTypes.bool
  };
  static defaultProps = {
    onClose: () => {}
  };

  state = {
    open: false,
    value: this.props.value
  };

  componentDidMount() {
    const { filter = {}, selectedFilters, filters } = this.props;
    if (selectedFilters[filter.field] && !filters[filter.field])
      this.setState({ open: true });
  }

  componentDidUpdate(prevProps) {
    const { value } = this.props;
    if (JSON.stringify(prevProps.value) !== JSON.stringify(value)) {
      this.setState({ value });
    }
  }

  handleClose = () => {
    this.cancelDebounce();
    this.props.onClose(this.state.value);
  };

  handleKeyPress = event => {
    if (this.props.disabled) return;
    if (event.key === "Enter") this.instantChange(this.state.value);
  };

  handleChange = event => {
    if (this.props.disabled) return;
    const value = event.target.value;
    this.setState({ value });
    this.debounceChange(value);
  };

  handleChangeNumber = event => {
    const { disabled, filter } = this.props;
    if (disabled) return;
    const regExp = filter.isInteger ? /^[0-9]+$/ : /^[0-9]+(?:\.[0-9]*)?$/;
    const value = event.target.value;
    if (String(value).length === 0 || value.match(regExp)) {
      this.setState({ value });
      this.debounceChange(value);
    }
  };

  handleOpenSelect = () => this.setState({ open: true });

  handleCloseSelect = () => this.setState({ open: false });

  handleChangeSelect = event => {
    const value = event.target.value;
    if (this.props.disabled || value === "NO_ACTION") return;
    this.setState({ value });
    this.instantChange(value);
  };

  handleChangeMultiSelect = value => {
    if (this.props.disabled) return;
    this.setState({ value });
    this.instantChange(value);
  };

  handleChangeDate = value => {
    const {
      filter: { formatter }
    } = this.props;
    if (Number(value) === Number(this.state.value) || this.props.disabled) {
      return;
    }
    this.setState({ value });
    this.instantChange(formatter ? formatter(value) : value);
  };

  handleChangeDateRange = value => {
    value = String([value.from, value.to]);
    this.setState({ value });
    this.instantChange(value);
  };

  debounceChange = _debounce(this.props.onChange, DEBOUNCE_DELAY);

  cancelDebounce = () => this.debounceChange.cancel();

  instantChange = value => {
    this.cancelDebounce();
    this.props.onChange(value);
  };

  componentWillUnmount() {
    const { filter = {}, setSelectedFilter } = this.props;
    setSelectedFilter(filter.field, false);
    this.cancelDebounce();
  }

  render() {
    const { item, classes, filter, disabled } = this.props;
    const type = (filter && filter.type) || "text";
    const inputId = filter && `${item.type}-filter-input-${filter.field}`;
    const options = filter
      ? filter.options || this.props[filter.dict] || []
      : [];

    return filter ? (
      <div
        className={classes.filter}
        style={{ minWidth: filter.minWidth }}
        data-type="filter"
      >
        {type === "select" && filter.isMulti && (
          <FormControl className={classes.selectField} fullWidth>
            <SelectField
              data-testid="multiSelectFilter"
              options={options}
              fieldProps={{
                label: <LabelComponent label={filter.name} />,
                value: this.state.value || [],
                selector: filter.selector,
                InputLabelProps: {
                  style: {
                    marginTop: "-20px"
                  }
                },
                SelectProps: {
                  isClearable: false
                }
              }}
              isMulti={true}
              onChange={this.handleChangeMultiSelect}
            />
          </FormControl>
        )}
        {type === "select" && !filter.isMulti && (
          <FormControl className={classes.selectField} fullWidth>
            <InputLabel
              classes={{ root: classes.selectLabel }}
              htmlFor={inputId}
            >
              {filter.name}
            </InputLabel>
            <Select
              value={options.length ? this.state.value ?? "" : ""}
              onChange={this.handleChangeSelect}
              onOpen={this.handleOpenSelect}
              onClose={this.handleCloseSelect}
              classes={{
                select: classes.select
              }}
              inputProps={{ name: inputId, id: inputId }}
              MenuProps={{
                disableAutoFocusItem: true,
                MenuListProps: { dense: true }
              }}
              open={this.state.open}
              disabled={disabled}
              data-testid="selectFilter"
            >
              {options.map((el, index) => (
                <MenuItem
                  key={index}
                  value={el.value}
                  classes={{ root: classes.dropdownListItem }}
                  disabled={el.value === "NO_ACTION"}
                >
                  {el.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {type === "text" && (
          <TextField
            id={inputId}
            label={filter.name}
            classes={{ root: classes.textField }}
            fullWidth
            margin="dense"
            value={this.state.value || ""}
            onChange={this.handleChange}
            onKeyPress={this.handleKeyPress}
            disabled={disabled}
          />
        )}
        {type === "number" && (
          <TextField
            id={inputId}
            label={filter.name}
            classes={{ root: classes.textField }}
            fullWidth
            margin="dense"
            value={this.state.value || ""}
            onChange={this.handleChangeNumber}
            onKeyPress={this.handleKeyPress}
            disabled={disabled}
          />
        )}
        {type === "date" && (
          <div className={classes.dateField}>
            <DatePicker
              id={inputId}
              label={filter.name}
              value={Number(this.state.value) || this.state.value || null}
              onChange={this.handleChangeDate}
              disabled={disabled}
            />
          </div>
        )}
        {type === "datetime" && (
          <DateTimePicker
            id={inputId}
            label={filter.name}
            value={Number(this.state.value) || this.state.value || null}
            onChange={this.handleChangeDate}
            disabled={disabled}
          />
        )}
        {type === "daterange" && (
          <RangeDatePicker
            id={inputId}
            label={filter.name}
            values={splitFromTo(this.state.value)}
            onChange={this.handleChangeDateRange}
            disabled={disabled}
            required
            PickerProps={{
              margin: "none",
              disableFuture: true,
              ...filter.PickerProps
            }}
          />
        )}
        <IconButton
          className={classes.closeButton}
          data-testid="close-button"
          onClick={this.handleClose}
          disabled={disabled}
        >
          <CloseIcon className={classes.closeIcon} />
        </IconButton>
      </div>
    ) : null;
  }
}

const mapStateToProps = (state, ownProps) => ({
  selectedFilters: Selectors.selectedFilters(state),
  filters: Selectors.selectItemSearchFilters(state, ownProps.item.type),
  ...stateToPropsForDict(state)
});

const mapDispatchToProps = {
  setSelectedFilter: (field, selected) =>
    ActionCreators.setSelectedFilter(field, selected)
};

export const Filter = compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(FilterComponent);
