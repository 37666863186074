/* eslint react/display-name: "off" */
/* eslint react/prop-types: "off" */
import React from "react";

import { RemoveButton } from "srx-shared/mui";

import { TriggersMonthlyFee } from "@items/types";

export const Actions = ({ row, item }) => {
  const label = item.type === TriggersMonthlyFee ? "Trigger" : "Camera";
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
        margin: "3px 0"
      }}
    >
      <RemoveButton
        row={row}
        item={item}
        tip={item.name}
        modalProps={{ size: "sm" }}
      >
        Are you sure you want to delete {item.name}:
        <br />
        <pre>
          <b>Number of {label}s: </b>
          {row.rangeFrom} - {row.rangeTo}
          <br />
          <b>Monthly fee per assigned {label.toLowerCase()}: </b>${row.value}
        </pre>
      </RemoveButton>
    </div>
  );
};
