import MergeIcon from "@material-ui/icons/SelectAllOutlined";
import { DckActionCreators } from "dck-redux";
import PropTypes from "prop-types";

import { useDispatchActions } from "srx-shared";
import { IconButton } from "srx-shared/mui";

import { LockerConfig } from "@items/types";

const getSelectedDoorIds = doors =>
  Array.from(doors.values()).sort((a, b) => a.number - b.number);

const actions = {
  updateLockerConfig: (locker, doors) =>
    DckActionCreators.itemSave(LockerConfig, locker.id, doors)
};
MergeButton.propTypes = {
  locker: PropTypes.object,
  door: PropTypes.object,
  doors: PropTypes.object,
  selectedDoors: PropTypes.object,
  setSelectedDoors: PropTypes.func
};

export function MergeButton(props) {
  const { locker, door, doors, selectedDoors, setSelectedDoors } = props;
  const { updateLockerConfig } = useDispatchActions(actions);

  const selectedDoorsArray = getSelectedDoorIds(selectedDoors);

  const [first, ...rest] = selectedDoorsArray.reduce(
    (indexes, door) => [...indexes, ...door.indexes],
    []
  );

  const last = rest[rest.length - 1];

  const doorSelected = selectedDoors.has(door);
  const multipleSelected = selectedDoorsArray.length > 1;

  const noGaps =
    multipleSelected &&
    Object.values(doors).every(({ number, indexes }) => {
      return (
        number < first ||
        number > last ||
        indexes.length === 0 ||
        selectedDoors.has(doors[number])
      );
    });

  const allWithinSameColumn =
    multipleSelected &&
    selectedDoorsArray.every(d => d.columnNumber === doors[first].columnNumber);

  const canMerge = doorSelected && noGaps && allWithinSameColumn;

  const mergeDoors = () => {
    doors[first].indexes = [first, ...rest];
    rest.forEach(id => (doors[id].indexes = []));
    setSelectedDoors(new Set());
    updateLockerConfig(locker, Object.values(doors));
  };

  const handleClick = e => {
    e.stopPropagation();
    mergeDoors();
  };

  return (
    <IconButton
      icon={<MergeIcon />}
      tip="Merge Doors"
      disabled={!canMerge}
      data-testid="merge-doors"
      onClick={handleClick}
    />
  );
}
