import { baseFeesLoad } from "./baseFeesLoad";
import { distributorsLoad } from "./distributorsLoad";
import { hardwareLoad } from "./hardwareLoad";

export let mockResponse = {};

if (process.env.NODE_ENV === "test") {
  mockResponse = (proc, args) => {
    switch (proc.code) {
      case "DISTRIBUTOR_LOAD":
        return distributorsLoad;
      case "HARDWARE_LOAD":
        return hardwareLoad;
      case "BASEFEE_LOAD":
        return baseFeesLoad;
      default:
        return null;
    }
  };
}
