import { useHistory } from "react-router-dom";

import { ActionButton } from "srx-shared/mui";

import { pages } from "@routes/pages";

export function CustomAction() {
  const { push } = useHistory();

  const handleClick = () => {
    push(`${pages.shipToTemplates.path}/new`);
  };

  return <ActionButton variant="add" onClick={handleClick} disableRipple />;
}
