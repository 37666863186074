import React from "react";

import { FormControl, FormControlLabel, Checkbox } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";

import { styles } from "./styles";

class CheckboxComponent extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    name: PropTypes.string,
    label: PropTypes.node,
    helperText: PropTypes.string,
    noHelperText: PropTypes.bool,
    size: PropTypes.string,
    dense: PropTypes.bool,
    options: PropTypes.array,
    value: PropTypes.bool,
    disabled: PropTypes.bool,
    defaultValue: PropTypes.any,
    onChange: PropTypes.func
  };

  handleChange = (e, value) => {
    const { onChange } = this.props;
    onChange && onChange(value);
  };

  render() {
    const {
      classes,
      value = "",
      label = null,
      disabled,
      dense = false,
      helperText = "",
      noHelperText = false
    } = this.props;

    return (
      <div className={classes.root}>
        <FormControl component="fieldset" className={classes.formControl}>
          <FormControlLabel
            classes={{
              root: classes.option,
              label: classes.label
            }}
            control={
              <Checkbox
                color="primary"
                checked={Boolean(value)}
                className={dense ? classes.checkboxDense : classes.checkbox}
                onChange={this.handleChange}
              />
            }
            label={label}
            disabled={disabled}
          />
        </FormControl>
        {!noHelperText && (
          <div className={classes.helperText}>{helperText}</div>
        )}
      </div>
    );
  }
}

export const CheckboxField = withStyles(styles)(CheckboxComponent);
