import React from "react";

import { Grid, FormLabel } from "@material-ui/core";
import PropTypes from "prop-types";

export const TitleComponent = ({ text, size, style }) => {
  return (
    <Grid item xs={size} style={style || { padding: "0 12px" }}>
      <FormLabel style={{ textOverflow: "ellipsis" }}>{text}</FormLabel>
    </Grid>
  );
};

TitleComponent.propTypes = {
  text: PropTypes.string,
  size: PropTypes.number,
  style: PropTypes.object
};
