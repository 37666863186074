import { useState, useCallback } from "react";

import { Grid, Paper } from "@material-ui/core";
import ConfigureIcon from "@material-ui/icons/SettingsOutlined";
import { DckActionCreators } from "dck-redux";
import _debounce from "lodash/debounce";
import * as PropTypes from "prop-types";

import { Formatters, useDispatchActions } from "srx-shared";
import { LinkIconButton, SwitchField } from "srx-shared/mui";

import { StorageSection } from "@items/types";
import { pages } from "@routes/pages";

import { SectionState } from "./SectionState";
import { useStyles } from "./styles";

const actions = {
  updateStorageSection: (id, data) =>
    DckActionCreators.itemSave(StorageSection, id, data)
};

TroubleshootingSection.propTypes = {
  id: PropTypes.number,
  section: PropTypes.object,
  storageId: PropTypes.number
};
export function TroubleshootingSection(props) {
  const { id, section, storageId } = props;
  const { smartShelfHardware, weightPolling, debugMode } = section;
  const { address, serialNumber } = smartShelfHardware || {};

  const cls = useStyles();

  const [values, setValues] = useState({ weightPolling, debugMode });

  const { updateStorageSection } = useDispatchActions(actions);

  const debounceUpdate = useCallback(_debounce(updateStorageSection, 1000), []);

  const handleSave = values => {
    setValues(values);
    debounceUpdate(storageId, { ...section, ...values });
  };

  const handleWeightPolling = value =>
    handleSave({ weightPolling: value, debugMode: false });

  const handleDebugMode = value => handleSave({ ...values, debugMode: value });

  return (
    <Paper className={cls.root}>
      <Grid container alignItems="center" wrap="nowrap" className={cls.content}>
        <Grid item>
          <SectionState id={id} section={section} />
        </Grid>
        {!serialNumber ? (
          <Grid item xs className={cls.notAssigned}>
            Please, assign smartshelf
          </Grid>
        ) : (
          <>
            <Grid item style={{ padding: "0 20px 0 10px" }}>
              <div className={cls.label}>IoT Hub Address</div>
              <div className={cls.text}>{Formatters.hubAddress(address)}</div>
            </Grid>
            <Grid item xs>
              <SwitchField
                value={values["weightPolling"]}
                label="Weight polling"
                noHelperText
                onChange={handleWeightPolling}
              />
            </Grid>
            <Grid item>
              <SwitchField
                value={values["debugMode"]}
                label="Debug mode"
                noHelperText
                disabled={!values["weightPolling"]}
                onChange={handleDebugMode}
              />
            </Grid>
            <Grid item>
              <LinkIconButton
                to={`${pages.hardware.path}/storage/${storageId}/section/${id}`}
                icon={<ConfigureIcon />}
                color="primary"
                data-testid="section-settings"
              />
            </Grid>
          </>
        )}
      </Grid>
    </Paper>
  );
}
