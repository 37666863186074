import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

import { UploadButton } from "./UploadButton";
import { styles } from "./styles";

function ImportButtonComponent(props) {
  const { onImport } = props;

  if (!onImport) {
    process.env.NODE_ENV === "development" &&
      console.error("ImportButton without onImport handler");
    return null;
  }

  return <UploadButton {...props} />;
}

ImportButtonComponent.propTypes = {
  onImport: PropTypes.func
};

export const ImportButton = withStyles(styles)(ImportButtonComponent);
