import React from "react";

import { Button, Popover } from "@material-ui/core";
import HistoryIcon from "@material-ui/icons/History";
import * as PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

import { pages } from "@routes/pages";

import { useStyles } from "./styles";

WeightHistoryPopover.propTypes = {
  anchorEl: PropTypes.object,
  onClose: PropTypes.func,
  deviceSerialNumber: PropTypes.string,
  smartShelfId: PropTypes.number,
  cellIndex: PropTypes.number
};

export function WeightHistoryPopover(props) {
  const { anchorEl, onClose, deviceSerialNumber, smartShelfId, cellIndex } =
    props;

  const cls = useStyles();
  const { push } = useHistory();

  if (!cellIndex) return null;

  const handleClick = () =>
    push(
      `${pages.hardware.path}/device/${deviceSerialNumber}/smart-shelf/${smartShelfId}/cell/${cellIndex}/weight-history`
    );

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center"
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center"
      }}
    >
      <Button onClick={handleClick} className={cls.historyButton}>
        <HistoryIcon className={cls.actionIcon} />
        Weight history
      </Button>
    </Popover>
  );
}
