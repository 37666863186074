import cn from "classnames";
import PropTypes from "prop-types";

import { useStyles } from "./styles";

SectionCell.propTypes = {
  cellId: PropTypes.number,
  cellCounter: PropTypes.object
};

export function SectionCell(props) {
  const { cellId, cellCounter } = props;

  const cls = useStyles();

  return (
    <div className={cls.cellContainer}>
      <div
        className={cls.clsTargetCell}
        style={{ backgroundColor: cellCounter.color }}
      >
        {cellCounter.currentCount}
      </div>
      <div className={cls.cellIdWrapper}>
        <div className={cn(cls.cellId, cls.emptyCellId)}>{cellId}</div>
      </div>
    </div>
  );
}
