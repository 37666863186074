export const styles = theme => ({
  label: {
    marginTop: 2,
    fontSize: "14px",
    color: "#333"
  },
  groupLabel: {
    paddingTop: 8,
    fontSize: "0.75rem",
    color: "#555"
  },
  group: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "row",
    justifyContent: "space-between"
  },
  radio: {
    margin: "4px 2px 0 8px",
    padding: 4
  },
  helperText: {
    minHeight: 18,
    fontSize: "0.75rem",
    color: theme.palette.primary.light
  },
  disabled: {
    color: "#bbb"
  },
  option: {}
});
