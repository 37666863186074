import {
  colorClearHover,
  colorClearNormal,
  colorFocus,
  colorHover,
  colorNoFocus,
  colorDisabled,
  colorBorderDisabled,
  colorError
} from "./ColorConstants";

const styleControl = props => base => ({
  ...base,
  background: "transparent no-repeat",
  borderWidth: 0,
  borderRadius: 0,
  boxShadow: "none",
  marginRight: 0,
  border: 0,
  minHeight: 27,
  lineHeight: "initial",
  borderBottom:
    props.selectProps.isDisabled && !props.error
      ? `1px dotted ${colorBorderDisabled}`
      : "none",
  backgroundImage:
    props.selectProps.isDisabled && !props.error
      ? "unset"
      : props.error
      ? `linear-gradient(to bottom, ${colorError}, ${colorError}), linear-gradient(to bottom, ${colorNoFocus}, ${colorNoFocus})`
      : `linear-gradient(to bottom, ${colorFocus}, ${colorFocus}), linear-gradient(to bottom, ${colorNoFocus}, ${colorNoFocus})`,
  backgroundSize:
    props.hasInputFocus || props.error
      ? "100% 2px, 100% 1px"
      : "0 2px, 100% 1px",
  backgroundPosition: "50% 100%, 50% 100%",
  transition: "background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1)",
  "&:hover": !props.hasInputFocus &&
    !props.error && {
      backgroundSize: "0 2px, 100% 2px",
      backgroundImage: `linear-gradient(to bottom, ${colorHover}, ${colorHover}), linear-gradient(to bottom, ${colorHover}, ${colorHover})`
    }
});

// FIXME hasInputFocus should produce a 2px border, but such a border moves the content up so we use only 1px
/*const getBorder = (hasInputFocus, error, disabled) =>
  error
    ? `2px solid ${colorError}`
    : disabled
      ? `1px dotted ${colorBorderDisabled}`
      : hasInputFocus
        ? `2px solid ${colorFocus}`
        : `1px solid ${colorNoFocus}`;
        */

const styleValueContainer = props => base => ({
  ...base,
  padding: 0,
  marginBottom: 3,
  marginTop: -4,
  marginLeft: -2,
  marginRight: 0, //!!props.selectProps && props.selectProps.isClearable ? 25 : 0,
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis"
});

const styleIndicatorsContainer = base => ({
  ...base,
  /*   position: "absolute",
  right: 0,
  marginLeft: 8,
  marginRight: 0,
 */ backgroundColor: "transparent",
  height: "100%"
});

const styleIndicatorSeparator = base => ({
  ...base,
  width: 0
});

const styleClearIndicator = base => ({
  ...base,
  color: colorClearNormal,
  //margin: "0 4px 0 0",
  padding: 0,
  cursor: "pointer",
  "&:hover": {
    color: colorClearHover
  }
});

const styleDropdownIndicator = props => base => {
  const color = props.error
    ? colorError
    : props.selectProps.isDisabled
    ? colorDisabled
    : colorNoFocus;
  return {
    ...base,
    //margin: "0 0 0 4px",
    padding: 0,
    cursor: "pointer",
    "&:hover": {
      color
    },
    color
  };
};

const styleMenu = base => ({
  ...base,
  marginTop: 3,
  position: "absolute",
  zIndex: 100,
  boxShadow: "0 0 0 1px hsla(0,0%,0%,0.1), 0 4px 11px hsla(0,0%,0%,0.4)"
});

const styleMenuList = base => ({
  ...base,
  padding: 0
});

const styleMenuPortal = base => ({
  ...base,
  zIndex: 10
});

// enable this to change the look of an option in the list of them
const styleOption = (base, state) => ({
  ...base,
  color: state.isSelected ? "#003a70" : "#333"
});

// enable this to see the helperText
const styleContainer = base => base;

const styleNoOptionsMessage = base => ({
  ...base,
  textAlign: "left",
  color: "#ff8080"
});

const styleMultiValueRemove = props => base => ({
  ...base,
  display:
    !!props.selectProps && props.selectProps.isDisabled ? "none" : base.display
});

export const getStyles = props => ({
  container: styleContainer,
  control: styleControl(props),
  indicatorSeparator: styleIndicatorSeparator,
  clearIndicator: styleClearIndicator,
  dropdownIndicator: styleDropdownIndicator(props),
  indicatorsContainer: styleIndicatorsContainer,
  menu: styleMenu,
  menuList: styleMenuList,
  menuPortal: styleMenuPortal,
  multiValueRemove: styleMultiValueRemove(props),
  noOptionsMessage: styleNoOptionsMessage,
  option: styleOption,
  valueContainer: styleValueContainer(props)
});
