import { useState } from "react";

import {
  tableFor,
  filtersFor,
  editFormFor,
  editModalFor,
  createFormFor,
  actionsFor,
  breadcrumbsFor,
  usePageTitle
} from "srx-shared";

import { pages } from "@routes/pages";

import { ChangeStatusDialog } from "./ChangeStatusDialog";
import { DistributorItem } from "./DistributorItem";
import { DistributorContext } from "./context";

const DistributorTable = tableFor(DistributorItem, {
  layouts: true
});
const DistributorFilters = filtersFor(DistributorItem);
const DistributorCreateForm = createFormFor(DistributorItem, {
  title: "Add distributor",
  size: "md"
});

const DistributorActions = actionsFor(DistributorItem, {
  CreateForm: DistributorCreateForm
});

const DistributorEditForm = editFormFor(DistributorItem);
const DistributorEditModal = editModalFor(DistributorItem, {
  EditForm: DistributorEditForm
});

const BreadcrumbsComponent = breadcrumbsFor();

export default function DistributorsComponent() {
  usePageTitle(pages.distributors.title);
  const [openChangeStatusDialog, setOpenChangeStatusDialog] = useState(false);

  const renderFilters = table => <DistributorFilters table={table} />;
  const renderActions = table => <DistributorActions table={table} />;

  const contextValue = {
    showChangeStatusDialog: setOpenChangeStatusDialog
  };

  const handleClose = () => setOpenChangeStatusDialog(false);

  return (
    <>
      <BreadcrumbsComponent renderList={() => [{ title: "Distributors" }]} />
      <DistributorContext.Provider value={contextValue}>
        <DistributorTable
          renderFilters={renderFilters}
          renderActions={renderActions}
        />
      </DistributorContext.Provider>
      <DistributorEditModal />
      <ChangeStatusDialog open={openChangeStatusDialog} onClose={handleClose} />
    </>
  );
}
