import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
  container: {
    padding: 16,
    minWidth: 550
  },
  columnContainer: {
    padding: "0 8px 20px 8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexFlow: "column wrap",
    alignContent: "center"
  },
  doorContainer: {
    display: "flex",
    flexWrap: "nowrap",
    paddingRight: 8,
    width: 498,
    height: 76
  },
  doorPaper: {
    display: "inline-flex",
    margin: 5,
    paddingLeft: 10,
    position: "relative",
    border: "1px solid white"
  },
  selectedPaper: {
    border: "1px solid #776699"
  },
  noWeightPaper: {
    background: "#cde"
  },
  iconWrapper: {
    display: "flex",
    alignItems: "center",
    padding: "0 8px 0 4px"
  },
  clsLabel: {
    width: 170,
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: "8pt",
    color: "#777"
  },
  clsText: {
    width: 170,
    marginTop: 2,
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: "10pt",
    color: "#333"
  },
  clsNotAssigned: {
    fontSize: "small",
    color: "#aaa"
  },
  serialInput: {
    flexDirection: "column",
    alignSelf: "center"
  },
  serialContainer: {
    display: "flex",
    paddingRight: 10
  },
  doorNumber: {
    display: "flex",
    width: 28,
    height: 28,
    margin: "8px 0 8px 2px",
    justifyContent: "center",
    alignItems: "center",
    background: "#eee",
    color: "#777",
    fontSize: "14px",
    borderRadius: "50%"
  },
  label: {
    whiteSpace: "normal",
    transform: "none",
    fontSize: 10
  }
}));
