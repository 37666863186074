import React from "react";

import { withStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";
import PropTypes from "prop-types";
import { compose } from "redux";

import { isMobile } from "../../../utils";
import { EditableCell } from "../EditableCell";
import { styles } from "./styles";

class GeneralCellComponent extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    children: PropTypes.any,
    value: PropTypes.any,
    column: PropTypes.object,
    index: PropTypes.number,
    center: PropTypes.bool,
    original: PropTypes.object,
    style: PropTypes.object
  };

  render() {
    const { classes, center, children, value, style } = this.props;
    const cellValue =
      typeof children === "undefined" || children === null ? value : children;

    return !isMobile(this) ? (
      <div className={center ? classes.center : classes.root} style={style}>
        <span className={classes.overflow}>{cellValue}</span>
      </div>
    ) : (
      <div className={classes.root}>{cellValue}</div>
    );
  }
}

const GeneralCell = compose(
  withStyles(styles),
  withWidth()
)(GeneralCellComponent);

export class Cell extends React.Component {
  static propTypes = {
    classes: PropTypes.any,
    width: PropTypes.any,
    children: PropTypes.any,
    value: PropTypes.any,
    column: PropTypes.object,
    index: PropTypes.number,
    center: PropTypes.bool,
    original: PropTypes.object,
    tdProps: PropTypes.object,

    style: PropTypes.object
  };

  isEditable = () => {
    const { column = {}, original } = this.props;
    return typeof column.inline === "function"
      ? column.inline(original)
      : column.inline;
  };

  render() {
    const { value, index, column, width, classes, ...restProps } = this.props;
    const readOnly = Boolean(this.props.tdProps?.rest.readOnly);

    const props = { index, column, value };

    return this.isEditable() && !readOnly ? (
      <EditableCell {...props} />
    ) : (
      <GeneralCell {...props} {...restProps} />
    );
  }
}
