import React from "react";

import { Grid } from "@material-ui/core";
import { Tooltip } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import PropTypes from "prop-types";

import { Message } from "srx-shared/mui";

const LabelWithTooltip = ({ label, title }) => (
  <div style={{ display: "flex", alignItems: "center" }}>
    <span style={{ userSelect: "none" }}>{label}</span>
    <Tooltip title={title}>
      <InfoIcon style={{ marginLeft: 4 }} />
    </Tooltip>
  </div>
);

LabelWithTooltip.propTypes = {
  label: PropTypes.string,
  title: PropTypes.string
};

function InfoMessage(props) {
  const {
    enableAutoExpire,
    daysUntilAutoExpiration,
    enableExpirationAlarm,
    daysUntilExpirationAlarm
  } = props.form.values;

  const daysUntilExpiration =
    Number(daysUntilExpirationAlarm) + Number(daysUntilAutoExpiration);

  const expirationAlarmMessage = `The software will alarm about the expiration within ${daysUntilExpirationAlarm} days until expiration`;

  const autoExpireMessage = `The software will automatically change serial numbers status from AVAILABLE to EXPIRED within ${daysUntilAutoExpiration} days until expiration`;

  const combinatedMessage = `The software will alarm about the expiration within ${daysUntilExpiration} days until expiration 
  and automatically change serial numbers status from AVAILABLE to EXPIRED within ${daysUntilAutoExpiration} days until the Date of Expiration`;

  if (
    enableExpirationAlarm &&
    Boolean(props.form.values["daysUntilExpirationAlarm"]) &&
    enableAutoExpire &&
    Boolean(props.form.values["daysUntilAutoExpiration"])
  )
    return (
      <Grid item xs={12} style={{ padding: "12px 12px 0" }}>
        <Message variant="info">{combinatedMessage}</Message>
      </Grid>
    );

  if (
    enableExpirationAlarm &&
    Boolean(props.form.values["daysUntilExpirationAlarm"])
  )
    return (
      <Grid item xs={12} style={{ padding: "12px 12px 0" }}>
        <Message variant="info">{expirationAlarmMessage}</Message>
      </Grid>
    );

  if (enableAutoExpire && Boolean(props.form.values["daysUntilAutoExpiration"]))
    return (
      <Grid item xs={12} style={{ padding: "12px 12px 0" }}>
        <Message variant="info">{autoExpireMessage}</Message>
      </Grid>
    );

  return null;
}

InfoMessage.propTypes = {
  form: PropTypes.object
};

export { InfoMessage, LabelWithTooltip };
