import React from "react";

import { makeStyles } from "@material-ui/core";
import * as PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
  root: {
    height: 24,
    width: 24,
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 12,
    margin: "0 auto 8px",
    backgroundColor: ({ color }) => color,
    color: ({ color }) => theme.palette.getContrastText(color)
  }
}));

const CELL_NUMBER_BACKGROUND_COLORS = {
  SUCCESS: "#E2E2E2",
  WARNING: "#EF8A20",
  ERROR: "#F44336"
};

CellNumber.propTypes = {
  cell: PropTypes.object,
  status: PropTypes.string
};

export function CellNumber(props) {
  const { cell, status } = props;

  const color = CELL_NUMBER_BACKGROUND_COLORS[status];

  const cls = useStyles({ color });

  return <div className={cls.root}>{cell.idx}</div>;
}
