import React, { useEffect } from "react";

import { DckActionCreators } from "dck-redux";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { compose } from "redux";

import { withPageTitle, Selectors } from "srx-shared";
import { PageTabContainer } from "srx-shared/mui";

import { Distributor, FreezeVariables, Discounts, User } from "@items/types";

import DistributorName from "./DistributorName";
import { distributorTabs } from "./distributorTabs";

function DistributorDetails(props) {
  const {
    currentDistributor,
    getDistributor,
    resetDistributor,
    resetUsers,
    resetFreezeVariables,
    resetDiscounts
  } = props;

  const { id } = useParams();

  useEffect(() => {
    getDistributor(id);
    return () => {
      resetDistributor();
      resetUsers();
      resetFreezeVariables();
      resetDiscounts();
    };
  }, [
    getDistributor,
    resetDiscounts,
    resetDistributor,
    resetFreezeVariables,
    resetUsers,
    id
  ]);

  return currentDistributor && <PageTabContainer tabs={distributorTabs} />;
}
DistributorDetails.propTypes = {
  currentDistributor: PropTypes.object,
  getDistributor: PropTypes.func,
  resetDistributor: PropTypes.func,
  resetUsers: PropTypes.func,
  resetFreezeVariables: PropTypes.func,
  resetDiscounts: PropTypes.func
};

const mapStateToProps = state => ({
  currentDistributor: Selectors.selectActiveItem(state, Distributor)
});

const mapDispatchToProps = {
  getDistributor: id => DckActionCreators.itemSelect(Distributor, id),
  resetDistributor: () => DckActionCreators.itemMakeActive(Distributor, null),
  resetUsers: () => DckActionCreators.itemsSet(User, []),
  resetFreezeVariables: () =>
    DckActionCreators.setItemData(FreezeVariables, "values", null),
  resetDiscounts: () => DckActionCreators.setItemData(Discounts, "values", null)
};

export default compose(
  withPageTitle(<DistributorName />, "Distributor"),
  connect(mapStateToProps, mapDispatchToProps)
)(DistributorDetails);
