import React from "react";

import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import PropTypes from "prop-types";
import { compose } from "redux";

import { isMobile } from "../../../utils";
import { GoToPage } from "./GoToPage";
import { PageButton } from "./PageButton";
import PageSizeSelector from "./PageSizeSelector";
import { styles } from "./styles";

const SPREAD = <PageButton disabled page="..." />;

class PaginationComponent extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    pages: PropTypes.number,
    page: PropTypes.number,
    pageSize: PropTypes.number,
    onPageChange: PropTypes.func,
    onPageSizeChange: PropTypes.func,
    withoutSelector: PropTypes.bool,
    hasNext: PropTypes.bool
  };

  constructor(props) {
    super();
    this.state = {
      visiblePages: this.getVisiblePages(null, props.pages)
    };
  }

  componentDidUpdate(prevProps) {
    const { pages, page } = this.props;
    if (prevProps.pages !== pages) {
      this.setState(
        {
          visiblePages: this.getVisiblePages(page + 1, pages)
        },
        () => this.changePage(page + 1)
      );
    } else {
      this.changePage(page + 1);
    }
  }

  filterPages = (visiblePages, totalPages) => {
    return visiblePages.filter(page => page <= totalPages);
  };

  getVisiblePages = (page, total) => {
    if (total === -1) return [];
    if (total < 7) {
      return this.filterPages([1, 2, 3, 4, 5, 6], total);
    } else {
      if (page % 5 >= 0 && page > 4 && page + 2 < total) {
        return [1, page - 1, page, page + 1, total];
      } else if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
        return [1, total - 3, total - 2, total - 1, total];
      } else {
        return [1, 2, 3, 4, 5, total];
      }
    }
  };

  setVisiblePages = page => {
    const visiblePages = this.getVisiblePages(page, this.props.pages);
    this.setState({
      visiblePages: this.filterPages(visiblePages, this.props.pages)
    });
  };

  goToChange = page => {
    this.setVisiblePages(page + 1);
    this.props.onPageChange(page);
  };

  changePage = page => {
    const activePage = this.props.page + 1;
    if (page === activePage) return;
    this.setVisiblePages(page);
    this.props.onPageChange(page - 1);
  };

  render() {
    if (!this.props.pages) return null;
    const {
      classes,
      page,
      pages,
      pageSize,
      onPageSizeChange,
      withoutSelector,
      hasNext
    } = this.props;
    const { visiblePages } = this.state;
    const activePage = page + 1;

    return (
      <div className={classes.footer}>
        <div className={classes.pageSizeSelector}>
          {!withoutSelector ? (
            <PageSizeSelector
              pageSize={pageSize}
              onPageSizeChange={onPageSizeChange}
            />
          ) : null}
        </div>
        <div className={classes.pagination}>
          {pages !== -1 && (
            <GoToPage {...this.props} onGoToChange={this.goToChange} />
          )}
          {!isMobile(this) && (
            <IconButton
              classes={{ root: classes.pageButtonIcon }}
              size="small"
              onClick={() => this.changePage(activePage - 1)}
              disabled={activePage === 1}
              data-testid="previous-button"
            >
              <KeyboardArrowLeft />
            </IconButton>
          )}
          {pages !== -1 ? (
            visiblePages.map((page, index, array) => (
              <React.Fragment key={page}>
                {array[index - 1] + 2 <= page && SPREAD}
                <PageButton
                  onClick={() => this.changePage(page)}
                  active={activePage === page}
                  page={page}
                />
              </React.Fragment>
            ))
          ) : (
            <span className={classes.pageSpan}>{activePage}</span>
          )}
          {!isMobile(this) && (
            <IconButton
              classes={{ root: classes.pageButtonIcon }}
              size="small"
              onClick={() => this.changePage(activePage + 1)}
              disabled={pages !== -1 ? activePage === pages : !hasNext}
              data-testid="next-button"
            >
              <KeyboardArrowRight />
            </IconButton>
          )}
        </div>
      </div>
    );
  }
}

export const Pagination = compose(
  withStyles(styles),
  withWidth()
)(PaginationComponent);

export default Pagination;
