export const styles = {
  title: {
    marginRight: 16,
    whiteSpace: "nowrap",
    opacity: 0.7,
    "& > div": {
      maxWidth: 300,
      overflow: "hidden",
      textOverflow: "ellipsis"
    }
  }
};
