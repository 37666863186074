import * as itemTypes from "../items/types";

let processTypes = {
  SIGN_IN: "SIGN_IN",
  SIGN_OUT: "SIGN_OUT",
  CHECK_USER_SESSION: "CHECK_USER_SESSION",
  RESET_USER_PASSWORD: "RESET_USER_PASSWORD"
};

const processes = ["ADD", "LOAD", "REMOVE", "UPDATE", "IMPORT"];

Object.keys(itemTypes).forEach(item => {
  const ITEM = item.toUpperCase();
  processes.forEach(PROCESS => {
    const itemProcess = `${ITEM}_${PROCESS}`;
    processTypes[itemProcess] = itemProcess;
  });
});

export default processTypes;
