import * as V from "yup";

import { mapFormFieldsFor, Selectors } from "srx-shared";

import { Storage } from "@items/types";
import { store } from "@redux/store";

import { LocationsWarning } from "./LocationsWarning";
import { NoAssignedIotHubWarning } from "./NoAssignedIotHubWarning";

// hidden fields: shelfSerialNumbers

const isShelfSerialSelectDisabled = form => {
  const { noWeight } = form.values;
  const storage = Selectors.selectActiveItem(store.getState(), Storage);
  return noWeight === "true" || !storage.iotHub;
};

export const SectionConfigItem = {
  type: "SectionConfig",
  name: "SectionConfig",
  title: "Section Configuration"
};

// form fields
SectionConfigItem.fieldsConfig = {
  noWeight: {
    name: "noWeight",
    type: "radio",
    options: [
      { label: "with weights", value: "false" },
      { label: "without weights", value: "true" }
    ],
    size: 12,
    noHelperText: true
  },
  address: {
    name: "address",
    label: "IoT Hub Address",
    size: 12,
    checkDisabled: () => true
  },
  shelfSerial: {
    name: "shelfSerial",
    label: "Smart Shelf Serial Number",
    type: "select",
    dict: "availableDistributorShelves",
    size: 12,
    checkDisabled: isShelfSerialSelectDisabled,
    SelectProps: {
      isClearable: true
    }
  },
  noAssignedIotHubWarning: {
    Component: NoAssignedIotHubWarning
  },
  locationsWarning: {
    Component: LocationsWarning
  }
};

SectionConfigItem.schema = V.object({
  shelfSerial: V.number().nullable()
});

SectionConfigItem.generalForm = mapFormFieldsFor(SectionConfigItem, [
  "noWeight",
  "address",
  "shelfSerial",
  "noAssignedIotHubWarning",
  "locationsWarning"
]);
