import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
  clsLoading: {
    marginTop: 10,
    marginLeft: 56
  },
  clsNavOpen: {
    borderTop: "1px solid #eee",
    paddingTop: 6,
    flexGrow: 2
  },
  clsNavClosed: {
    paddingTop: 0,
    flexGrow: 2
  },
  clsSkeletonList: {
    borderBottom: "1px solid #eee",
    width: "100%",
    display: "flex",
    flexDirection: "column"
  },
  clsSkeletonItem: {
    display: "flex",
    alignItems: "center",
    margin: "8px 0 16px 24px"
  }
}));
