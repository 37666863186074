import IconBin from "@material-ui/icons/AllInbox";
import IconBinOutlined from "@material-ui/icons/AllInboxOutlined";
import IconDocuments from "@material-ui/icons/Assignment";
import IconDocumentsOutlined from "@material-ui/icons/AssignmentOutlined";
import IconFees from "@material-ui/icons/AttachMoney";
import IconFeesOutlined from "@material-ui/icons/AttachMoneyOutlined";
import IconCostSavings from "@material-ui/icons/CreditCard";
import IconCostSavingsOutlined from "@material-ui/icons/CreditCardOutlined";
import IconLocker from "@material-ui/icons/DeveloperBoard";
import IconLockerOutlined from "@material-ui/icons/DeveloperBoardOutlined";
import IconUpcGtinCatalog from "@material-ui/icons/ImportContacts";
import IconUpcGtinCatalogOutlined from "@material-ui/icons/ImportContactsOutlined";
import IconHardwareOutlined from "@material-ui/icons/MemoryOutlined";
import IconCustomers from "@material-ui/icons/People";
import IconCustomersOutlined from "@material-ui/icons/PeopleOutlined";
import IconSignOut from "@material-ui/icons/PowerSettingsNew";
import IconSignOutOutlined from "@material-ui/icons/PowerSettingsNewOutlined";
import IconShipToTemplates from "@material-ui/icons/Settings";
import IconShipToTemplatesOutlined from "@material-ui/icons/SettingsOutlined";
import IconMarket from "@material-ui/icons/Shop";
import IconMarketOutlined from "@material-ui/icons/ShopOutlined";
import IconDistributors from "@material-ui/icons/SupervisedUserCircle";
import IconDistributorsOutlined from "@material-ui/icons/SupervisedUserCircleOutlined";

import { Icons } from "srx-shared";

export const pages = {
  distributors: {
    id: "distributors",
    title: "Distributors",
    path: "/distributors",
    icon: <IconDistributors />,
    iconOutlined: <IconDistributorsOutlined />
  },
  shipToTemplates: {
    id: "shipto-templates",
    title: "ShipTo Templates",
    path: "/shipto-templates",
    icon: <IconShipToTemplates />,
    iconOutlined: <IconShipToTemplatesOutlined />
  },
  hardware: {
    id: "hardware",
    title: "Hardware",
    path: "/hardware",
    icon: <Icons.Hardware />,
    iconOutlined: <IconHardwareOutlined />
  },
  fees: {
    id: "fees",
    title: "Fees",
    path: "/fees",
    icon: <IconFees />,
    iconOutlined: <IconFeesOutlined />
  },
  costSavings: {
    id: "cost-savings",
    title: "Cost Savings",
    path: "/cost-savings",
    icon: <IconCostSavings />,
    iconOutlined: <IconCostSavingsOutlined />
  },
  documents: {
    id: "documents",
    title: "Documents",
    path: "/documents",
    icon: <IconDocuments />,
    iconOutlined: <IconDocumentsOutlined />
  },
  customerTypes: {
    id: "customer-types",
    title: "Customer Types",
    path: "/customer-types",
    icon: <IconCustomers />,
    iconOutlined: <IconCustomersOutlined />
  },
  marketTypes: {
    id: "market-types",
    title: "Market Types",
    path: "/market-types",
    icon: <IconMarket />,
    iconOutlined: <IconMarketOutlined />
  },
  binTypes: {
    id: "bin-types",
    title: "Bin Types",
    path: "/bin-types",
    icon: <IconBin />,
    iconOutlined: <IconBinOutlined />
  },
  lockerTypes: {
    id: "locker-types",
    title: "Locker Types",
    path: "/locker-types",
    icon: <IconLocker />,
    iconOutlined: <IconLockerOutlined />
  },
  upcGtinCatalog: {
    id: "universal-catalog",
    title: "Universal Catalog",
    path: "/universal-catalog",
    icon: <IconUpcGtinCatalog />,
    iconOutlined: <IconUpcGtinCatalogOutlined />
  },
  signOut: {
    id: "sign-out",
    title: "Sign out",
    path: "/sign-out",
    icon: <IconSignOut />,
    iconOutlined: <IconSignOutOutlined />
  }
};
