import React from "react";

import { DialogContent, DialogActions, Button } from "@material-ui/core";
import { DckSelectors } from "dck-redux";
import * as PropTypes from "prop-types";
import { connect } from "react-redux";

import { ModalDialog } from "srx-shared/mui";

import { ActionCreators } from "@actions";
import { Distributor } from "@items/types";

const styleContent = {
  paddingTop: 0,
  width: "100%"
};

const styleActions = {
  paddingRight: 16,
  paddingBottom: 16
};

class ChangeStatusDialogComponent extends React.PureComponent {
  static propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    distributor: PropTypes.object,
    setDistributorStatus: PropTypes.func
  };

  handleAction = () => {
    const { distributor, onClose, setDistributorStatus } = this.props;
    const status = distributor && distributor.status;
    const newStatus = status === "ACTIVE" ? "INACTIVE" : "ACTIVE";
    setDistributorStatus(distributor.id, newStatus);
    onClose();
  };

  render() {
    const { distributor, open, onClose } = this.props;

    const status = distributor && distributor.status;
    const action = status === "ACTIVE" ? "deactivate" : "activate";

    return (
      <ModalDialog
        open={open}
        onClose={onClose}
        title="Change distributor status"
        size="sm"
      >
        <DialogContent style={styleContent}>
          {distributor && (
            <>
              <span>
                Are you sure you want to <b>{action}</b> distributor ?
              </span>
              <br />
              <br />
              <pre>{distributor.name}</pre>
            </>
          )}
        </DialogContent>
        <DialogActions style={styleActions}>
          <Button
            onClick={onClose}
            color="default"
            variant="contained"
            data-testid="cancel-button"
          >
            Cancel
          </Button>
          <Button
            onClick={this.handleAction}
            color={action === "activate" ? "primary" : "secondary"}
            variant="contained"
            data-testid="confirm-button"
            disabled={!distributor}
          >
            {action}
          </Button>
        </DialogActions>
      </ModalDialog>
    );
  }
}

const mapStateToProps = state => ({
  distributor: DckSelectors.selectActiveItem(state, Distributor)
});

const mapDispatchToProps = {
  setDistributorStatus: (id, status) =>
    ActionCreators.setDistributorStatus(id, status)
};

export const ChangeStatusDialog = connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangeStatusDialogComponent);
