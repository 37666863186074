import { actionsFor, tableFor, useBreadcrumbs, usePageTitle } from "srx-shared";

import { ShipToTemplateItem } from "@comp/ShipToTemplates/ShipToTemplateItem";
import { pages } from "@routes/pages";

import { CustomAction } from "./CustomAction";

const ShipToTemplateTable = tableFor(ShipToTemplateItem);

const ShipToTemplatesActions = actionsFor(ShipToTemplateItem, {
  Custom: CustomAction
});

export default function ShipToTemplates() {
  usePageTitle(pages.shipToTemplates.title);

  useBreadcrumbs([{ title: "ShipTo Templates" }]);

  const renderActions = table => <ShipToTemplatesActions table={table} />;

  return <ShipToTemplateTable renderActions={renderActions} />;
}
