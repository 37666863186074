import React from "react";

import { Grid, Button, DialogActions } from "@material-ui/core";
import { Form as FormWrapper, Formik } from "formik";
import _get from "lodash/get";
import _set from "lodash/set";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import * as Selectors from "../../actions/selectors";
import { DckSelectors, DckActionCreators } from "../../redux";
import { ActionsSpinner } from "./ActionsSpinner";
import { FormField } from "./FormField";

const GroupComponent = ({ children }) => children;

class FormComponent extends React.Component {
  static propTypes = {
    id: PropTypes.string,
    readOnly: PropTypes.bool,
    renderActions: PropTypes.func,
    groupConfig: PropTypes.object,
    fieldsConfig: PropTypes.array,
    formikProps: PropTypes.object
  };

  static defaultProps = {
    renderActions: () => {}
  };

  renderField = (field, key) => (
    <FormField
      key={key}
      fieldConfig={this.props.fieldsConfig.find(el => el.name === field)}
      formInstance={this}
      disabled={this.props.readOnly}
    />
  );

  render() {
    const { id, renderActions, groupConfig, formikProps } = this.props;
    const { fields, Component, checkHidden, ...containerProps } = groupConfig;
    const GroupContainer = Component || GroupComponent;
    const hidden = checkHidden ? checkHidden(this.props) : false;

    return !hidden ? (
      <GroupContainer
        {...containerProps}
        key={id}
        form={formikProps}
        fields={fields}
      >
        <FormWrapper id={id} style={{ width: "100%" }}>
          <Grid container spacing={3} item xs={12} style={{ margin: 0 }}>
            {fields.map(this.renderField)}
          </Grid>
          {renderActions(formikProps, id)}
        </FormWrapper>
      </GroupContainer>
    ) : null;
  }
}

export const Form = FormComponent;

export const editFormsFor = (item, form) => {
  class Wrapper extends React.PureComponent {
    static propTypes = {
      initValue: PropTypes.object,
      currentItem: PropTypes.object,
      readOnly: PropTypes.bool,
      updateItem: PropTypes.func,
      updating: PropTypes.bool,
      updatingSuccess: PropTypes.bool,
      onReset: PropTypes.func
    };

    state = {
      activeFormId: null
    };

    componentDidUpdate(prevProps) {
      const { updating, updatingSuccess, onReset } = this.props;
      if (prevProps.updating && !updating && updatingSuccess && onReset) {
        onReset();
      }
    }

    renderActions = (formikProps, id) => {
      const { readOnly, onReset, updating } = this.props;
      const { dirty } = formikProps;
      return !readOnly ? (
        <DialogActions style={{ margin: "24px 4px 8px" }}>
          <ActionsSpinner
            processing={updating && this.state.activeFormId === id}
          />
          <Button
            type="reset"
            color="default"
            onClick={onReset ? onReset : () => {}}
          >
            Reset
          </Button>
          <Button
            type="submit"
            onClick={() => this.setState({ activeFormId: id })}
            color="primary"
            disabled={!dirty || updating}
          >
            Save
          </Button>
        </DialogActions>
      ) : null;
    };

    updateItem = (id, data, groupConfig) => {
      const currentItem = { ...this.props.currentItem };
      const { fields } = groupConfig;
      fields.forEach(field => _set(currentItem, field, _get(data, field)));
      this.props.updateItem(id, currentItem);
    };

    renderGroup = (groupConfig, key) => {
      const { initValue, currentItem, readOnly } = this.props;
      const { activeFormId } = this.state;
      const id = `${item.type.toLowerCase()}-page-form-${key}`;

      return (
        (initValue || currentItem) && (
          <Formik
            key={id}
            enableReinitialize={activeFormId === id || activeFormId === null}
            initialValues={initValue || currentItem || {}}
            validationSchema={form.schema}
            onSubmit={data =>
              this.updateItem(currentItem.id, data, groupConfig)
            }
          >
            {formikProps => (
              <Form
                formikProps={formikProps}
                readOnly={readOnly}
                renderActions={this.renderActions}
                id={id}
                groupConfig={groupConfig}
                fieldsConfig={form.fields}
                key={key}
              />
            )}
          </Formik>
        )
      );
    };

    render() {
      return (
        <Grid container spacing={3}>
          {form.groups && form.groups.map(this.renderGroup)}
        </Grid>
      );
    }
  }

  const process = `${item.type.toUpperCase()}_UPDATE`;

  const mapStateToProps = state => ({
    currentItem: Selectors.selectActiveItem(state, item.type),
    updating: DckSelectors.selectProcessRunning(state, process),
    updatingSuccess: DckSelectors.selectProcessSuccess(state, process)
  });

  const dispatchStateToProps = {
    updateItem: (id, data) => DckActionCreators.itemSave(item.type, id, data)
  };

  return connect(mapStateToProps, dispatchStateToProps)(Wrapper);
};
