import React from "react";

import { Button, DialogContent } from "@material-ui/core";
import { DckSelectors, DckActionCreators } from "dck-redux";
import * as PropTypes from "prop-types";
import { connect } from "react-redux";

import { generalFormFor } from "srx-shared";
import { ActionsSpinner, ModalDialog } from "srx-shared/mui";

import { CameraConfigItem } from "@items/Camera";
import { Camera } from "@items/types";

const CameraConfigForm = generalFormFor(CameraConfigItem);

class CameraConfigComponent extends React.Component {
  static propTypes = {
    item: PropTypes.object,
    show: PropTypes.bool,
    onClose: PropTypes.func,
    loadCameraConfigurableList: PropTypes.func,
    submitCameraConfig: PropTypes.func,
    success: PropTypes.bool,
    updating: PropTypes.bool
  };

  componentDidMount() {
    const { item, loadCameraConfigurableList } = this.props;
    loadCameraConfigurableList(item.value);
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.success && this.props.success) {
      this.props.onClose();
    }
  }

  handleSubmit = data => {
    const { item, submitCameraConfig } = this.props;
    submitCameraConfig({
      id: item.value,
      ip: data.ip
    });
  };

  renderActions = (form, formikProps) => {
    const { updating, onClose } = this.props;
    const { dirty } = formikProps;
    const disabled = !dirty || updating;
    return (
      <div
        style={{
          marginTop: 24,
          display: "flex",
          justifyContent: "flex-end"
        }}
      >
        <ActionsSpinner processing={updating} />
        <Button
          variant="contained"
          disabled={updating}
          onClick={onClose}
          style={{ marginRight: 16 }}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          color="secondary"
          variant="contained"
          disabled={disabled}
        >
          Configure
        </Button>
      </div>
    );
  };

  render() {
    const { show, onClose, updating } = this.props;
    return (
      <ModalDialog
        open={show}
        onClose={onClose}
        title="Configure camera"
        size="sm"
      >
        <DialogContent>
          <CameraConfigForm
            renderActions={this.renderActions}
            onSubmit={this.handleSubmit}
            processing={updating}
          />
        </DialogContent>
      </ModalDialog>
    );
  }
}

const process = `${Camera.toUpperCase()}_UPDATE`;

const mapStateToProps = state => ({
  success: DckSelectors.selectProcessSuccess(state, process),
  updating: DckSelectors.selectProcessRunning(state, process)
});

const mapDispatchToProps = {
  loadCameraConfigurableList: id => DckActionCreators.itemsLoad(Camera, { id }),
  submitCameraConfig: data => DckActionCreators.itemsSave(Camera, data)
};

export const CameraConfig = connect(
  mapStateToProps,
  mapDispatchToProps
)(CameraConfigComponent);
