import React, { useEffect, useState } from "react";

import {
  Button,
  Checkbox,
  Popover,
  IconButton,
  DialogActions
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ActiveLayerIcon from "@material-ui/icons/Layers";
import InactiveLayerIcon from "@material-ui/icons/LayersOutlined";
import cn from "classnames";
import _without from "lodash/without";
import PropTypes from "prop-types";

import { divideArrayOnParts } from "../../../../utils";
import { useStyles } from "./styles";

const COUNT_COLUMNS = 2;

HeaderContextMenu.propTypes = {
  item: PropTypes.object,
  table: PropTypes.any,
  currentLayout: PropTypes.object,
  layoutsList: PropTypes.array,
  setCurrentLayout: PropTypes.func,
  updateLayouts: PropTypes.func,
  name: PropTypes.string,
  title: PropTypes.string
};

export function HeaderContextMenu(props) {
  const {
    item,
    table,
    currentLayout = {},
    layoutsList,
    setCurrentLayout,
    updateLayouts
  } = props;
  const { showHeaderContextMenu, anchorHeaderContextMenu } = table.state;

  const [layout, setLayout] = useState(currentLayout);

  useEffect(() => {
    showHeaderContextMenu && setLayout(currentLayout);
  }, [showHeaderContextMenu, currentLayout]);

  const classes = useStyles();

  const isColumnVisible = column =>
    layout.hiddenColumns?.indexOf(column) === -1;

  const handleChangeLayout = selected => {
    const selectedLayout = layoutsList.find(layout => layout.name === selected);
    setCurrentLayout(selectedLayout);
  };

  const handleChangeView = column => {
    const hiddenColumns = isColumnVisible(column)
      ? layout.hiddenColumns.concat(column)
      : _without(layout.hiddenColumns, column);
    setLayout({ ...layout, hiddenColumns });
  };

  const handleClose = () => table.setState({ showHeaderContextMenu: false });

  const handleApply = () => {
    updateLayouts(layout);
    handleClose();
  };

  const handleReset = () =>
    updateLayouts({ ...layout, hiddenColumns: [], resized: [] });

  const renderLayoutButton = ({ name, title }, index) => {
    const isCurrent = layout.name === name;
    const Icon = isCurrent ? ActiveLayerIcon : InactiveLayerIcon;
    return (
      <Button
        key={index}
        startIcon={<Icon />}
        onClick={() => handleChangeLayout(name)}
        data-testid="layout-change"
        color="primary"
        className={cn(classes.button, isCurrent && classes.selected)}
      >
        {title}
      </Button>
    );
  };

  return (
    <Popover
      id={`${item.type}-header-context-menu`}
      open={showHeaderContextMenu}
      anchorEl={anchorHeaderContextMenu}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center"
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center"
      }}
    >
      <div className={classes.header}>
        {item.layouts.map(renderLayoutButton)}
        <IconButton
          onClick={handleClose}
          size="small"
          data-testid="layout-close"
          style={{ marginLeft: "auto" }}
        >
          <CloseIcon />
        </IconButton>
      </div>
      <div className={classes.body}>
        {divideArrayOnParts(item.headers, COUNT_COLUMNS).map((col, cIndex) => (
          <div key={cIndex}>
            {col.map((header, index) => (
              <div
                key={index}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Checkbox
                  checked={isColumnVisible(header.accessor)}
                  color="primary"
                  disabled={header.mandatory}
                  onChange={() => handleChangeView(header.accessor)}
                />
                <span>{header.title}</span>
              </div>
            ))}
          </div>
        ))}
      </div>
      <DialogActions>
        <Button onClick={handleReset} data-testid="layout-reset">
          Reset
        </Button>
        <Button
          color="primary"
          onClick={handleApply}
          data-testid="layout-apply"
        >
          Apply
        </Button>
      </DialogActions>
    </Popover>
  );
}
