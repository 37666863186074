import React from "react";

import { Skeleton } from "@material-ui/lab";
import PropTypes from "prop-types";

import { useStyles } from "./styles";

SkeletonItem.propTypes = {
  width: PropTypes.number
};

export function SkeletonItem(props) {
  const { width = 140 } = props;
  const { clsSkeletonItem } = useStyles();

  return (
    <div className={clsSkeletonItem}>
      <Skeleton
        variant="circle"
        width={24}
        height={24}
        style={{ marginRight: 16 }}
      />
      <Skeleton variant="text" width={width} height={16} />
    </div>
  );
}
