import React from "react";

import { Grid, Tooltip } from "@material-ui/core";
import WarningIcon from "@material-ui/icons/WarningOutlined";
import * as PropTypes from "prop-types";
import { useSelector } from "react-redux";

import {
  ActionCreators,
  makeSequence,
  Selectors,
  useDispatchActions
} from "srx-shared";
import { SwitchField } from "srx-shared/mui";

import { getDoorColumnNumber } from "@comp/Hardware/helpers";
import { StorageConfig } from "@items/types";

import { SectionConfig } from "./SectionConfig";
import { TroubleshootingSection } from "./TroubleshootingSection";
import { TroubleshootingStorageConfig } from "./TroubleshootingStorageConfig";
import { useStyles } from "./styles";

const actions = {
  setTrShootingMode: value =>
    ActionCreators.setItemData(StorageConfig, "trShootingMode", value)
};

Planogram.propTypes = {
  sections: PropTypes.object,
  storage: PropTypes.object
};

export function Planogram(props) {
  const { sections, storage } = props;
  const { id: storageId, distributorId, lockerType, iotHub } = storage;

  const cls = useStyles();

  const { setTrShootingMode } = useDispatchActions(actions);

  const trShootingMode = useSelector(state =>
    Selectors.getItemData(state, StorageConfig, "trShootingMode")
  );

  const noWeight = Object.values(sections).every(section => section.noWeight);

  const noSmartShelves = Object.values(sections).every(
    section => !section.smartShelfHardware
  );

  const trShootingAvailable = Boolean(!noWeight && iotHub && !noSmartShelves);

  const trShooting = Boolean(trShootingAvailable && trShootingMode);

  const { columnsQuantity } = lockerType || {};

  const columnIds = makeSequence(columnsQuantity);

  const renderSection = ({ number: id }) => {
    const Section = trShooting ? TroubleshootingSection : SectionConfig;
    return (
      <Section
        key={`section-${id}`}
        storageId={storageId}
        distributorId={distributorId}
        section={sections[id]}
        id={id}
      />
    );
  };

  const renderColumn = columnNumber => {
    const columnSections = Object.values(sections).filter(
      section => getDoorColumnNumber(section, lockerType) === columnNumber
    );

    return (
      <div key={`column-${columnNumber}`} className={cls.columnContainer}>
        {columnSections
          .filter(section => !trShooting || !section.noWeight)
          .map(renderSection)}
      </div>
    );
  };

  const handleChangeMode = value => setTrShootingMode(value);

  const title = !iotHub
    ? "Storage is not assigned to IoTHub"
    : noWeight
    ? "Troubleshooting view available only for weighted sections"
    : noSmartShelves
    ? "Storage has no assigned Smart Shelves"
    : "";

  return (
    <>
      <Grid container style={{ marginBottom: 20 }}>
        <Grid item xl={4} lg={3} md={4} sm={12} style={{ display: "flex" }}>
          <SwitchField
            value={trShooting}
            label="Troubleshooting view"
            disabled={!trShootingAvailable}
            noHelperText
            onChange={handleChangeMode}
          />
          {!trShootingAvailable && (
            <Tooltip title={title}>
              <WarningIcon style={{ color: "orange" }} />
            </Tooltip>
          )}
        </Grid>
        <Grid item xl={4} md={6} xs={12}>
          {trShooting && <TroubleshootingStorageConfig storage={storage} />}
        </Grid>
      </Grid>
      <Grid container justify="center">
        {columnIds.map(renderColumn)}
      </Grid>
    </>
  );
}
