import React, { Component } from "react";

import Popover from "@material-ui/core/Popover";
import { withStyles } from "@material-ui/core/styles";
import UserAccountIconOutlined from "@material-ui/icons/AccountBoxOutlined";
import cn from "classnames";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { compose } from "redux";

import { AccountStatus } from "../AccountStatus";
import { showAccountStatus } from "../AccountStatus/helpers";
import { styles } from "./styles";

class UserInfoComponent extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    style: PropTypes.object,
    userInfo: PropTypes.object,
    history: PropTypes.object,
    open: PropTypes.bool,
    onClick: PropTypes.func,
    profile: PropTypes.object
  };

  state = {
    anchorEl: null
  };

  onClick = e => {
    const { onClick, open } = this.props;
    onClick && onClick();
    !open && this.toggleInfo(e);
  };

  selectAccount = () => {
    const { profile, history } = this.props;
    const path = profile?.path;
    path && history.push(path);
  };

  toggleInfo = e => {
    const { anchorEl } = this.state;
    if (anchorEl) this.handleClose();
    else this.handleClick(e);
  };

  handleClick = e => this.setState({ anchorEl: e.currentTarget });
  handleClose = e => this.setState({ anchorEl: null });

  renderUserInfo = () => {
    const { classes, userInfo, profile } = this.props;

    return (
      <div
        onClick={this.selectAccount}
        className={profile?.path ? classes.profile : classes.container}
      >
        <span className={cn(classes.text, classes.bold)}>
          {userInfo.firstName || ""} {userInfo.lastName || ""}
        </span>
        <span className={classes.text}>{userInfo.email || ""}</span>
        {showAccountStatus(userInfo) && (
          <AccountStatus
            status={userInfo.accountStatus}
            billingLeftDays={userInfo.billingLeftDays}
            className={classes.statusLink}
          />
        )}
      </div>
    );
  };

  render() {
    const { classes, className, style, userInfo, open } = this.props;
    const { anchorEl } = this.state;
    const showUserInfo = Boolean(anchorEl);

    if (!userInfo) return null;

    return (
      <div
        className={cn(
          className,
          classes.info,
          !open && "-collapsed",
          userInfo.status === "INACTIVE" && classes.error
        )}
        style={style}
        role={"button"}
        onClick={this.onClick}
      >
        {open && this.renderUserInfo()}
        {!open && (
          <>
            <UserAccountIconOutlined />
            <Popover
              id="user-info"
              open={showUserInfo}
              anchorEl={anchorEl}
              onClose={this.handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center"
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center"
              }}
              classes={{ paper: classes.paper }}
            >
              {this.renderUserInfo()}
            </Popover>
          </>
        )}
      </div>
    );
  }
}

export const UserInfo = compose(
  withRouter,
  withStyles(styles)
)(UserInfoComponent);
