import React from "react";

import { withStyles } from "@material-ui/core/styles";
import { compose } from "redux";

import { withPageTitle } from "../AppPageTitle/withPageTitle";
import { styles } from "./styles";

const NotFoundComponent = () => <h3>Page not found</h3>;

export const NotFound = compose(
  withPageTitle("", "", { hidden: true }),
  withStyles(styles)
)(NotFoundComponent);
