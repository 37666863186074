import { mapFormFieldsFor } from "srx-shared";

import { ShipToTemplateSettings } from "@items/types";

export const PricingSourceSettingsItem = {
  type: ShipToTemplateSettings,
  fieldsConfig: {
    pricingSource: {
      name: "pricingSource",
      label: "Please specify which source should be used to get pricing from",
      type: "radio",
      size: 12,
      options: [
        {
          label: "Import into SRX",
          value: "SRX",
          disabled: false
        },
        {
          label: "No pricing",
          value: "NO_PRICING",
          disabled: false
        },
        {
          label: "ERP API (not supported)",
          value: "API",
          disabled: true
        }
      ],
      noHelperText: true
    },
    useCache: {
      name: "useCache",
      label: "Use 24h cache for prices",
      type: "checkbox",
      noHelperText: true,
      size: 12
    }
  },
  fields: ["pricingSource", "useCache"]
};
PricingSourceSettingsItem.formConfig = mapFormFieldsFor(
  PricingSourceSettingsItem
);
