export const styles = theme => ({
  info: {
    overflow: "visible",
    textOverflow: "ellipsis",
    display: "block",
    fontSize: "13px",
    lineHeight: "16px",
    width: "100%",
    padding: "5px 5px 0 18px",
    color: theme.palette.primary.main,
    cursor: "default",
    "&.-collapsed": {
      padding: "8px 16px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "& svg": {
        fontSize: 20
      }
    }
  },
  paper: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: "13px",
    lineHeight: "16px",
    padding: "8px 16px",
    color: theme.palette.primary.main
  },
  error: {
    background: "#ffd2d2"
  },
  bold: {
    fontWeight: "bold"
  },
  text: {
    whiteSpace: "nowrap"
  },
  status: {
    marginRight: 18,
    color: "red"
  },
  container: {
    display: "flex",
    flexDirection: "column",
    cursor: "default"
  },
  profile: {
    display: "flex",
    flexDirection: "column",
    cursor: "pointer"
  },
  divider: {
    margin: "6px -5px 6px -18px",
    backgroundColor: "rgba(0, 0, 0, 0.08)"
  },
  statusLink: {
    "&:hover": {
      fontWeight: "bold"
    }
  }
});
