import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  cellContainer: {
    position: "relative",
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#fff",
    color: "#aaa",
    border: "none",
    borderRadius: "5px",
    width: 63,
    height: 64,
    marginLeft: 3
  },
  cellIdWrapper: {
    position: "absolute",
    left: 2,
    top: 2,
    padding: "0 4px 3px 4px",
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "5px"
  },
  cellId: {
    display: "flex",
    height: 16,
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    borderRadius: "30%",
    fontSize: "12px"
  },
  emptyCellId: {
    color: "#aaa"
  },
  clsTargetCell: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    borderRadius: 4,
    background: "inherit",
    border: "1px dashed #bbb",
    fontWeight: "bold",
    fontSize: 16
  }
}));
