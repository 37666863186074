/* eslint react/display-name: "off" */
/* eslint react/prop-types: "off" */
import React from "react";

import * as V from "yup";

import { mapFiltersFor, mapFormFieldsFor } from "srx-shared";
import { Cell, Header } from "srx-shared/mui";

import { Actions } from "@comp/TypesManagementPages/Actions";
import { MarketType } from "@items/types";

export const MarketTypeItem = {
  type: MarketType,
  name: "MarketType",
  title: "Market Type"
};

// table
MarketTypeItem.columns = [
  {
    Header: "Market Type Number",
    accessor: "id",
    Cell: props => <Cell {...props} />
  },
  {
    Header: "Type Name",
    accessor: "name",
    Cell: props => <Cell {...props} />
  },
  {
    Header: <Header center>{"Actions"}</Header>,
    maxWidth: 100,
    resizable: false,
    sortable: false,
    Cell: ({ row }) => <Actions row={row._original} item={MarketTypeItem} />
  }
];

// filters
MarketTypeItem.filtersConfig = {
  name: {
    name: "Type Name"
  }
};
MarketTypeItem.filters = mapFiltersFor(MarketTypeItem, ["name"]);

// form fields
MarketTypeItem.fieldsConfig = {
  name: {
    label: "Type Name",
    required: true,
    initialValue: "",
    size: 12
  }
};

MarketTypeItem.schema = V.object({
  name: V.string().label("Type Name").required().max(255).nullable()
});

MarketTypeItem.createForm = mapFormFieldsFor(MarketTypeItem, ["name"]);

MarketTypeItem.editForm = mapFormFieldsFor(MarketTypeItem, ["name"]);

MarketTypeItem.modalEditForm = true;
