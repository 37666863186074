import React from "react";

import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import withStyles from "@material-ui/core/styles/withStyles";
import withWidth from "@material-ui/core/withWidth";
import { Field as FieldWrapper, isFunction } from "formik";
import _get from "lodash/get";
import PropTypes from "prop-types";
import { compose } from "redux";

import { isMobile } from "../../utils";
import { CheckDictField } from "./CheckDictField";
import { CheckGroupField } from "./CheckGroupField";
import { CheckboxField } from "./CheckboxField";
import { DatePickerField } from "./DatePickerField";
import { LabelComponent } from "./LabelComponent";
import { NumberField } from "./NumberField";
import { OptionsField } from "./OptionsField";
import { SelectCreatableField } from "./SelectCreatableField";
import { SelectField } from "./SelectField";
import { SwitchField } from "./SwitchField";
import { TextField } from "./TextField";
import { styles } from "./styles";

class FieldComponent extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    field: PropTypes.object,
    formInstance: PropTypes.object,
    form: PropTypes.object,
    fieldConfig: PropTypes.object,
    disabled: PropTypes.bool,
    defaultValue: PropTypes.any
  };

  handleChange = e => {
    const {
      form,
      field: { name },
      fieldConfig: { onChange, formatValue },
      form: { handleChange, setFieldTouched }
    } = this.props;

    let value = formatValue
      ? formatValue(e.target.value, form)
      : e.target.value;

    handleChange({ target: { value, name } });
    setFieldTouched(name, true, false);
    onChange && onChange(value, form);
  };

  handleSelect = value => {
    const {
      field: { name }
    } = this.props;
    this.handleChange({ target: { value, name } });
  };

  textDefault = () => {
    const {
      defaultValue,
      field: { value },
      fieldConfig: { type = "text", options = [] }
    } = this.props;

    if (
      defaultValue === null ||
      defaultValue === undefined ||
      String(defaultValue) === String(value)
    ) {
      return "";
    }
    if (type === "text") {
      return String(defaultValue) !== "" ? `Default: ${defaultValue}` : "";
    }
    if (type === "radio") {
      const option =
        options.find(el => String(defaultValue) === String(el.value)) || {};
      return option.label ? `Default: ${option.label}` : "";
    }
    return "";
  };

  setRef = name => element => {
    const { formInstance } = this.props;
    formInstance && formInstance.setState({ [`ref_${name}`]: element });
  };

  render() {
    const {
      classes,
      disabled,
      field: { name, value },
      form,
      form: { errors, touched },
      fieldConfig,
      fieldConfig: {
        label,
        size = 6,
        required = false,
        hidden = false,
        type = "text",
        dict = "",
        options = [],
        isMulti = false,
        inputSuffix = "",
        noHelperText = false,
        autoFocus = false,
        checkDisabled = () => false,
        checkHidden = () => false,
        selector,
        style = {},
        column,
        SelectProps,
        InputProps,
        InputLabelProps,
        PickerProps,
        NumberProps,
        readOnly = false
      }
    } = this.props;

    const error = Boolean(_get(touched, name) && _get(errors, name));

    const helperText = error
      ? _get(errors, name)
      : this.textDefault() || (fieldConfig || {}).helperText;
    const fieldLabel = typeof label === "function" ? label({ form }) : label;
    const fieldProps = {
      name,
      label: <LabelComponent label={fieldLabel} required={required} />,
      value,
      type,
      disabled: disabled || checkDisabled(form),
      helperText,
      form,
      error,
      selector,
      style:
        hidden || checkHidden(form) ? { ...style, display: "none" } : style,
      margin: "none",
      fullWidth: true,
      autoFocus,
      column,
      SelectProps,
      InputLabelProps
    };

    return (
      <Grid
        item
        xs={isMobile(this) ? 12 : size}
        style={{ display: hidden || checkHidden(form) ? "none" : "" }}
      >
        {(type === "text" || type === "password") && !isMulti && (
          <TextField
            {...fieldProps}
            data-testid="form-field"
            value={value !== null && value !== undefined ? value : ""}
            onChange={this.handleChange}
            inputRef={this.setRef(name)}
            trimSpacesOnBlur={true}
            FormHelperTextProps={{
              classes: {
                root: classes.helperText,
                error: classes.helperTextError
              }
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="start"
                  className={classes.inputSuffix}
                >
                  {isFunction(inputSuffix) ? inputSuffix(form) : inputSuffix}
                </InputAdornment>
              ),
              readOnly,
              ...InputProps
            }}
          />
        )}
        {type === "text" && isMulti && (
          <SelectCreatableField
            data-testid="form-field"
            fieldProps={fieldProps}
            FormHelperTextProps={{
              classes: {
                root: classes.helperText,
                error: classes.helperTextError
              }
            }}
            onChange={this.handleSelect}
          />
        )}
        {type === "number" && (
          <NumberField
            {...fieldProps}
            data-testid="form-field"
            value={value !== null && value !== undefined ? value : ""}
            onChange={this.handleChange}
            inputRef={this.setRef(name)}
            FormHelperTextProps={{
              classes: {
                root: classes.helperText,
                error: classes.helperTextError
              }
            }}
            NumberProps={NumberProps}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="start"
                  className={classes.inputSuffix}
                >
                  {inputSuffix}
                </InputAdornment>
              ),
              readOnly,
              ...InputProps
            }}
          />
        )}
        {type === "select" && (
          <SelectField
            data-testid="form-field"
            dict={dict}
            options={options}
            fieldProps={fieldProps}
            isMulti={isMulti}
            FormHelperTextProps={{
              classes: {
                root: classes.helperText,
                error: classes.helperTextError
              }
            }}
            onChange={this.handleSelect}
          />
        )}
        {type === "radio" && (
          <OptionsField
            {...fieldProps}
            data-testid="form-field"
            dict={dict}
            options={options}
            noHelperText={noHelperText}
            onChange={this.handleSelect}
          />
        )}
        {type === "checkGroup" && !dict && (
          <CheckGroupField
            {...fieldProps}
            data-testid="form-field"
            options={options}
            noHelperText={noHelperText}
            onChange={this.handleSelect}
          />
        )}
        {type === "checkGroup" && Boolean(dict) && (
          <CheckDictField
            {...fieldProps}
            data-testid="form-field"
            dict={dict}
            onChange={this.handleSelect}
            noHelperText={noHelperText}
          />
        )}
        {type === "checkbox" && (
          <CheckboxField
            {...fieldProps}
            data-testid="form-field"
            noHelperText={noHelperText}
            onChange={this.handleSelect}
          />
        )}
        {(type === "date" || type === "datetime") && (
          <DatePickerField
            {...fieldProps}
            PickerProps={PickerProps}
            data-testid="form-field"
            onChange={this.handleSelect}
          />
        )}
        {type === "switch" && (
          <SwitchField
            {...fieldProps}
            data-testid="form-field"
            noHelperText={noHelperText}
            onChange={this.handleSelect}
          />
        )}
      </Grid>
    );
  }
}

export const Field = compose(withStyles(styles), withWidth())(FieldComponent);

export class FormField extends React.PureComponent {
  static propTypes = {
    fieldConfig: PropTypes.object,
    formInstance: PropTypes.object,
    disabled: PropTypes.bool,
    defaultValue: PropTypes.any
  };

  render() {
    const { fieldConfig, formInstance, disabled, defaultValue } = this.props;
    const { Component } = fieldConfig;
    const FieldComponent = Component || Field;
    return (
      <FieldWrapper name={fieldConfig.name}>
        {({ field, form }) => (
          <FieldComponent
            field={field}
            form={form}
            formInstance={formInstance}
            fieldConfig={fieldConfig}
            disabled={disabled}
            defaultValue={defaultValue}
          />
        )}
      </FieldWrapper>
    );
  }
}
