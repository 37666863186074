import { Router } from "srx-shared";
import { NotFound } from "srx-shared/mui";

import App from "@comp/App";
import Welcome from "@comp/App/Welcome";
import CostSavings from "@comp/CostSavings";
import Distributors from "@comp/Distributors";
import Distributor from "@comp/Distributors/Distributor";
import Documents from "@comp/Documents";
import Fees from "@comp/Fees";
import Hardware from "@comp/Hardware";
import LockerConfiguration from "@comp/Hardware/Hardware/LockerConfiguration";
import StorageConfiguration from "@comp/Hardware/Hardware/StorageConfiguration";
import DoorConfig from "@comp/Hardware/Hardware/TroubleshootingConfig/DoorConfig";
import SectionConfig from "@comp/Hardware/Hardware/TroubleshootingConfig/SectionConfig";
import VendingConfiguration from "@comp/Hardware/Hardware/VendingConfiguration";
import TargetWeightHistory from "@comp/Hardware/TargetWeightHistory";
import ShipToTemplates from "@comp/ShipToTemplates";
import ShipToTemplate from "@comp/ShipToTemplates/ShipToTemplate";
import BinTypes from "@comp/TypesManagementPages/BinTypes";
import CustomerTypes from "@comp/TypesManagementPages/CustomerTypes";
import LockerTypes from "@comp/TypesManagementPages/LockerTypes";
import MarketTypes from "@comp/TypesManagementPages/MarketTypes";
import UpcGtinCatalog from "@comp/UpcGtinCatalog";

import { pages } from "./pages";

const appRoutes = [
  {
    path: "/",
    exact: true,
    component: Welcome
  },
  {
    path: `${pages.distributors.path}/:id`,
    component: Distributor
  },
  {
    path: pages.distributors.path,
    component: Distributors
  },
  {
    path: pages.shipToTemplates.path,
    component: ShipToTemplates,
    exact: true
  },
  {
    path: `${pages.shipToTemplates.path}/new`,
    component: ShipToTemplate,
    exact: true
  },
  {
    path: `${pages.shipToTemplates.path}/:id`,
    component: ShipToTemplate,
    exact: true
  },
  {
    path: `${pages.hardware.path}/locker/:id`,
    component: LockerConfiguration,
    exact: true
  },
  {
    path: `${pages.hardware.path}/storage/:id`,
    component: StorageConfiguration,
    exact: true
  },
  {
    path: `${pages.hardware.path}/vending/:id`,
    component: VendingConfiguration,
    exact: true
  },
  {
    path: `${pages.hardware.path}/storage/:storageId/section/:sectionId`,
    component: SectionConfig,
    exact: true
  },
  {
    path: `${pages.hardware.path}/locker/:lockerId/door/:doorId`,
    component: DoorConfig,
    exact: true
  },
  {
    path: `${pages.hardware.path}/device/:deviceSerialNumber/smart-shelf/:smartShelfId/cell/:cellIndex/weight-history`,
    component: TargetWeightHistory
  },
  {
    path: pages.hardware.path,
    component: Hardware
  },
  {
    path: pages.fees.path,
    component: Fees
  },
  {
    path: pages.costSavings.path,
    component: CostSavings
  },
  {
    path: pages.documents.path,
    component: Documents
  },
  {
    path: pages.customerTypes.path,
    component: CustomerTypes
  },
  {
    path: pages.marketTypes.path,
    component: MarketTypes
  },
  {
    path: pages.binTypes.path,
    component: BinTypes
  },
  {
    path: pages.lockerTypes.path,
    component: LockerTypes
  },
  {
    path: pages.upcGtinCatalog.path,
    component: UpcGtinCatalog
  },
  {
    path: pages.signOut.path,
    component: () => ""
  },
  {
    path: "/404",
    component: NotFound
  },
  {
    path: "*",
    component: NotFound
  }
];

export const routes = [
  ...Router.authRoutes,
  {
    path: "/",
    component: App,
    subroutes: appRoutes
  }
];
