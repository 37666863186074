import React from "react";

import { withStyles, Grid } from "@material-ui/core";
import PropTypes from "prop-types";

const styles = {
  box: {
    border: "1px solid #c8c8c8",
    padding: "10px 30px",
    position: "relative",
    borderRadius: 5
  },
  label: {
    position: "absolute",
    top: -12,
    left: 22,
    fontSize: 14,
    color: "#707070",
    backgroundColor: "white",
    padding: "0 10px"
  }
};

function GroupBoxComponent(props) {
  const { label = "", styles = {}, classes, children } = props;
  return (
    <Grid container className={classes.box} style={styles}>
      <Grid className={classes.label}>{label}</Grid>
      {children}
    </Grid>
  );
}
GroupBoxComponent.propTypes = {
  label: PropTypes.string,
  styles: PropTypes.object,
  classes: PropTypes.object,
  children: PropTypes.any
};

export const GroupBox = withStyles(styles)(GroupBoxComponent);
