import { useState, useCallback } from "react";

import { Grid } from "@material-ui/core";
import { DckActionCreators } from "dck-redux";
import _debounce from "lodash/debounce";
import PropTypes from "prop-types";
import * as V from "yup";

import { useDispatchActions } from "srx-shared";
import { NumberField } from "srx-shared/mui/Form/NumberField";

import { Hardware } from "@items/types";
import { getAvoidDotsError } from "@utils";

import { useStyles } from "./styles";

V.addMethod(V.number, "avoidDots", function () {
  return this.transform(getAvoidDotsError);
});

const actions = {
  updateLocker: data => DckActionCreators.itemSave(Hardware, null, data)
};

TroubleshootingLockerConfig.propTypes = {
  locker: PropTypes.object
};
export function TroubleshootingLockerConfig(props) {
  const { locker } = props;
  const { pollPeriod } = locker.troubleshootingConfig;

  const cls = useStyles();

  const [value, setValue] = useState(pollPeriod ?? 1800);

  const { updateLocker } = useDispatchActions(actions);

  const debounceUpdateLocker = useCallback(_debounce(updateLocker, 1000), []);

  const handleChange = async (value, field) =>
    V.number()
      .avoidDots()
      .min(0)
      .max(Number.MAX_SAFE_INTEGER)
      .isValid(Number(value))
      .then(result => {
        if (result) {
          setValue(value);
          debounceUpdateLocker({
            ...locker,
            troubleshootingConfig: {
              ...locker.troubleshootingConfig,
              pollPeriod: value
            }
          });
        }
      });

  return (
    <Grid item sm={6} xs={12}>
      <NumberField
        label="Polling Period for Debug mode (sec)"
        value={value}
        fullWidth
        InputLabelProps={{
          classes: { root: cls.label }
        }}
        onChange={e => handleChange(e.target.value, "pollPeriod")}
      />
    </Grid>
  );
}
