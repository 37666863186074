import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  root: {
    marginRight: 16,
    width: 240
  },
  row: {
    display: "flex",
    whiteSpace: "noWrap",
    color: "#707070"
  },
  value: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    marginLeft: 4,
    color: "#333333"
  }
});
