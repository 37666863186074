import React from "react";

import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";

import { SmartShelf } from "srx-shared/mui";

import { SmartShelfContext } from "./SmartShelfContext";

class SmartShelfComponent extends React.Component {
  static contextType = SmartShelfContext;
  static propTypes = {
    field: PropTypes.object,
    form: PropTypes.object
  };

  setSelectedDoor = selectedDoor => this.setState({ selectedDoor });
  setSelectedCells = selectedCells => this.setState({ selectedCells });
  setSelectedProducts = selectedProducts => this.setState({ selectedProducts });
  setPopoverProduct = popoverProduct => this.setState({ popoverProduct });

  clearSelection = () =>
    this.setState({
      selectedDoor: 0,
      selectedCells: new Set(),
      selectedProducts: {}
    });

  handleUpdates = cells => {
    this.props.form.setFieldValue("cells", cells, false);
  };

  state = {
    selectedDoor: 0,
    selectedCells: new Set(),
    selectedProducts: {},
    popoverProduct: null,
    setSelectedDoor: this.setSelectedDoor,
    setSelectedCells: this.setSelectedCells,
    setSelectedProducts: this.setSelectedProducts,
    setPopoverProduct: this.setPopoverProduct,
    clearSelection: this.clearSelection,
    handleUpdates: this.handleUpdates
  };

  render() {
    const {
      field: { value },
      form: { values }
    } = this.props;
    const smartShelf = { id: values.id || 1, cells: [...value] };
    return (
      <Grid item container xs={12} justify="center">
        <SmartShelfContext.Provider value={this.state}>
          <SmartShelf context={SmartShelfContext} smartShelf={smartShelf} />
        </SmartShelfContext.Provider>
      </Grid>
    );
  }
}

export default SmartShelfComponent;
