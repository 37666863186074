export const styles = theme => ({
  dialogContent: {
    color: "#505050"
  },
  block: {
    backgroundColor: "#f6f6f6",
    padding: "18px 10px 10px 10px",
    textAlign: "center"
  },
  accessKeyBlock: {
    fontSize: 14,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 38
  },
  serialNumber: {
    color: theme.palette.primary.main,
    fontWeight: "bold"
  }
});
