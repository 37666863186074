import { Grid } from "@material-ui/core";
import _get from "lodash/get";
import PropTypes from "prop-types";

import { SelectField } from "srx-shared/mui";

import { useStyles } from "./styles";

StatusSelect.propTypes = {
  field: PropTypes.object,
  fieldConfig: PropTypes.object,
  form: PropTypes.object,
  disabled: PropTypes.bool,
  downloadTemplate: PropTypes.func
};

export function StatusSelect(props) {
  const { field, fieldConfig, form, disabled } = props;

  const { name, label, size, dict, checkHidden } = fieldConfig;

  const cls = useStyles();

  const value = field.value;
  const error = _get(form.errors, name);
  const touched = _get(form.touched, name);
  const isHidden = checkHidden(form);
  const helperTextProps = {
    classes: {
      root: cls.helperText,
      error: cls.helperTextError
    }
  };

  const handleChange = value => form.setFieldValue(name, value, true);

  return (
    !isHidden && (
      <Grid item xs={12} sm={size}>
        <SelectField
          fieldProps={{
            label,
            value,
            fullWidth: true,
            error: touched && Boolean(error),
            helperText: touched && error,
            disabled
          }}
          onChange={handleChange}
          dict={dict}
          FormHelperTextProps={helperTextProps}
        />
      </Grid>
    )
  );
}
