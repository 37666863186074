import { mapFormFieldsFor } from "srx-shared";

import { ShipToTemplateSettings } from "@items/types";
import { TIME_EVERY_OPTIONS, TIME_AFTER_OPTIONS } from "config";

import { Timezone } from "../Common/Timezone";

const notOrderCloseValue = value => form =>
  value !== form.values.orderCloseLogic;

const ORDER_CLOSE_MODE = {
  MANUALLY: "MANUALLY",
  EVERY_DAY_AT: "AUTOMATICALLY_EVERY_DAY_AT",
  AFTER: "AUTOMATICALLY_AFTER"
};

const ORDER_CLOSE_OPTIONS = [
  {
    label: "Manually",
    value: ORDER_CLOSE_MODE.MANUALLY
  },
  { label: "Daily", value: ORDER_CLOSE_MODE.EVERY_DAY_AT },
  {
    label: "Automatically",
    value: ORDER_CLOSE_MODE.AFTER
  }
];

export const OrderCloseLogicSettingsItem = {
  type: ShipToTemplateSettings,
  fieldsConfig: {
    manually: {
      name: "orderCloseLogic",
      label: "Status changes from SHIPPED to DELIVERED",
      type: "radio",
      size: 12,
      initialValue: "MANUALLY",
      options: [ORDER_CLOSE_OPTIONS[0]],
      noHelperText: true
    },
    daily: {
      name: "orderCloseLogic",
      type: "radio",
      size: 4,
      options: [ORDER_CLOSE_OPTIONS[1]],
      noHelperText: true
    },
    time: {
      label: "Time",
      size: 4,
      type: "select",
      initialValue: "19:00",
      options: TIME_EVERY_OPTIONS,
      checkDisabled: notOrderCloseValue(ORDER_CLOSE_MODE.EVERY_DAY_AT)
    },
    timezone: {
      Component: Timezone,
      size: 4
    },
    automatically: {
      name: "orderCloseLogic",
      type: "radio",
      size: 4,
      options: [ORDER_CLOSE_OPTIONS[2]],
      noHelperText: true
    },
    delay: {
      label: "Delay",
      size: 4,
      type: "select",
      initialValue: "00:30",
      options: TIME_AFTER_OPTIONS,
      checkDisabled: notOrderCloseValue(ORDER_CLOSE_MODE.AFTER)
    }
  }
};

OrderCloseLogicSettingsItem.fields = [
  "manually",
  "daily",
  "time",
  "timezone",
  "automatically",
  "delay"
];

OrderCloseLogicSettingsItem.formConfig = mapFormFieldsFor(
  OrderCloseLogicSettingsItem
);
