import React, { useState } from "react";

import { Grid, Paper, ButtonBase } from "@material-ui/core";
import SuccessIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import ImageIcon from "@material-ui/icons/Image";
import WarningIcon from "@material-ui/icons/WarningOutlined";
import { procLoading } from "dck-redux";
import { useSelector } from "react-redux";

import { Icons, Selectors } from "srx-shared";
import { ActionsSpinner, Message } from "srx-shared/mui";

import { SmartShelfDetails } from "@items/types";

import { CellContent } from "./CellContent";
import { CellNumber } from "./CellNumber";
import { WeightHistoryPopover } from "./WeightHistoryPopover";
import { useStyles } from "./styles";

const CELL_STATUS_ICONS = {
  SUCCESS: <SuccessIcon style={{ color: "#4CAF50" }} />,
  WARNING: <WarningIcon style={{ color: "#EF8A20" }} />,
  ERROR: <ErrorIcon style={{ color: "#F44336" }} />
};

export function SmartShelfConfig(props) {
  const cls = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [cellIndex, setCellIndex] = useState(null);

  const smartShelfDetails = useSelector(state =>
    Selectors.getItemData(state, SmartShelfDetails, "details")
  );

  const loading = useSelector(state => procLoading(state, SmartShelfDetails));

  const getStatus = ({ calibrationWeight, currentWeight }) =>
    calibrationWeight > 0 && currentWeight > 0
      ? "SUCCESS"
      : !calibrationWeight || calibrationWeight < 0
      ? "ERROR"
      : "WARNING";

  if (loading)
    return (
      <Grid container justify="center" className={cls.root}>
        <ActionsSpinner processing />
      </Grid>
    );

  if (!smartShelfDetails)
    return (
      <Grid container justify="center" className={cls.root}>
        <Message variant="error">
          Error loading SmartShelf configuration
        </Message>
      </Grid>
    );

  const handleClick = (e, index) => {
    setAnchorEl(e.currentTarget);
    setCellIndex(index);
  };
  const handleClose = () => setAnchorEl(null);

  const {
    cellDetails,
    cellsQuantity,
    id,
    doorConfiguration: {
      hardware: { value }
    }
  } = smartShelfDetails;

  const products = cellDetails.map(el => ({
    ...el.product,
    cells: el.indexes
  }));

  return (
    <div className={cls.root} style={{ minWidth: cellsQuantity * 82 + 24 }}>
      <Grid container justify="center" spacing={1}>
        {products.map(({ cells, image, weight }, index) => (
          <Grid key={index} item>
            <Grid container direction="row" alignItems="center" spacing={1}>
              {cells.map(cell => (
                <Grid key={cell.idx} item>
                  <CellNumber cell={cell} status={getStatus(cell)} />
                  <ButtonBase
                    key={cell.idx}
                    onClick={e => handleClick(e, cell.idx)}
                  >
                    <Paper className={cls.cell}>
                      {CELL_STATUS_ICONS[getStatus(cell)]}
                      <CellContent
                        label="Calibration"
                        value={cell.calibrationWeight}
                        extraInfo={{
                          by: cell.calibratedBy,
                          at: cell.calibratedAt,
                          type: "calibrate"
                        }}
                      />
                      <CellContent
                        label="Current"
                        value={cell.currentWeight}
                        extraInfo={{ at: cell.weighedAt, type: "update" }}
                      />
                      <Icons.LedOff style={{ color: "#9e9e9e" }} />
                    </Paper>
                  </ButtonBase>
                </Grid>
              ))}
            </Grid>
            <Paper
              className={cls.cell}
              style={{ marginTop: 16, width: cells.length * (74 + 8) - 8 }}
            >
              {image && image !== "-1" ? (
                <img src={image} alt="" className={cls.image} />
              ) : (
                <ImageIcon className={cls.image} />
              )}
              <CellContent label="Product" value={weight} />
              <CellContent
                label="Current"
                value={cells.reduce(
                  (sum, cell) => sum + (cell.currentWeight || 0),
                  0
                )}
              />
            </Paper>
          </Grid>
        ))}
      </Grid>
      <WeightHistoryPopover
        anchorEl={anchorEl}
        onClose={handleClose}
        deviceSerialNumber={value}
        smartShelfId={id}
        cellIndex={cellIndex}
      />
    </div>
  );
}
