import SplitIcon from "@material-ui/icons/ViewStreamOutlined";
import { DckActionCreators } from "dck-redux";
import PropTypes from "prop-types";

import { useDispatchActions } from "srx-shared";
import { IconButton } from "srx-shared/mui";

import { LockerConfig } from "@items/types";

const actions = {
  updateLockerConfig: (locker, doors) =>
    DckActionCreators.itemSave(LockerConfig, locker.id, doors)
};

SplitButton.propTypes = {
  locker: PropTypes.object,
  door: PropTypes.object,
  doors: PropTypes.object,
  setSelectedDoors: PropTypes.func
};

export function SplitButton(props) {
  const { locker, door, doors, setSelectedDoors } = props;
  const { updateLockerConfig } = useDispatchActions(actions);

  const canSplit = door.indexes.length > 1;

  const splitDoor = () => {
    const { indexes } = door;
    indexes.forEach(index => (doors[index].indexes = [index]));
    setSelectedDoors(new Set());
    updateLockerConfig(locker, Object.values(doors));
  };

  const handleClick = () => splitDoor();

  return (
    <IconButton
      icon={<SplitIcon />}
      tip="Split Door"
      disabled={!canSplit}
      data-testid="split-door"
      onClick={handleClick}
    />
  );
}
