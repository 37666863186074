import React from "react";

import { Grid } from "@material-ui/core";
import { useSelector } from "react-redux";

import { Selectors } from "srx-shared";
import { Message } from "srx-shared/mui";

import { StorageSection } from "@items/types";

export function LocationsWarning() {
  const storageLocations = useSelector(state =>
    Selectors.selectAllItems(state, StorageSection)
  );
  const hasLocations = storageLocations && storageLocations.length > 0;

  return hasLocations ? (
    <Grid item xs={12} style={{ padding: "3px 12px" }}>
      <Message variant="warning">
        A change in weight{"'"}s status will trigger deletion of VMI{"'"}s,
        associated with the section
      </Message>
    </Grid>
  ) : null;
}
