import { Tooltip } from "@material-ui/core";
import * as PropTypes from "prop-types";

import { HARDWARE_STATUS } from "srx-shared";

const colorsStatus = {
  [HARDWARE_STATUS.RED]: "brown",
  [HARDWARE_STATUS.GREY]: "#ccc",
  [HARDWARE_STATUS.GREEN]: "lightgreen",
  [HARDWARE_STATUS.ORANGE]: "orange"
};

const getStatus = ({ status, statusDescription }) =>
  !status
    ? [HARDWARE_STATUS.GREY, "There is no information about device status"]
    : [status, statusDescription];

HardwareState.propTypes = {
  item: PropTypes.object
};
function HardwareState({ item }) {
  const [status, description] = getStatus(item);

  return (
    <Tooltip title={description || ""}>
      <div
        style={{
          width: 15,
          height: 15,
          backgroundColor: colorsStatus[status],
          borderRadius: 10
        }}
      />
    </Tooltip>
  );
}

export default HardwareState;
