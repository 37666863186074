import { mapFormFieldsFor } from "srx-shared";

import { ShipToTemplateSettings } from "@items/types";

export const RfidCheckoutCartSettingsItem = {
  type: ShipToTemplateSettings,
  fieldsConfig: {
    enableAddingToCart: {
      name: "enableAddingToCart",
      label: "Enable automated adding of the RFID tags to the Checkout Cart",
      type: "switch",
      initialValue: false,
      noHelperText: true,
      size: 12
    },
    "user.id": {
      label: "Super user",
      type: "select",
      required: true,
      size: 8,
      initialValue: ""
    }
  },
  fields: ["enableAddingToCart", "user.id"]
};

RfidCheckoutCartSettingsItem.formConfig = mapFormFieldsFor(
  RfidCheckoutCartSettingsItem
);
