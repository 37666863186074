import React from "react";

import { Divider, ListItem, ListItemIcon, withStyles } from "@material-ui/core";
import cn from "classnames";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { matchPath } from "react-router-dom";

import { styles } from "./styles";

const propTypes = {
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  divider: PropTypes.bool,
  open: PropTypes.bool,
  history: PropTypes.object,
  icon: PropTypes.node,
  iconOutlined: PropTypes.node,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  path: PropTypes.string,
  id: PropTypes.string
};

class SideBarItemComponent extends React.PureComponent {
  static propTypes = propTypes;
  onClick = () => {
    const { history, path, onClick } = this.props;
    onClick && onClick();
    path && history.push(path);
  };
  render() {
    const {
      divider,
      history,
      classes,
      path,
      icon,
      title,
      id,
      open,
      iconOutlined
    } = this.props;

    const selected =
      !divider &&
      path &&
      history &&
      !!matchPath(history.location.pathname, path);

    return divider ? (
      <Divider className={classes.divider} />
    ) : (
      <ListItem
        id={`sidebar-${id}`}
        data-testid="sidebar-item"
        button
        dense
        disableRipple
        selected={selected}
        className={cn(
          classes.root,
          selected && "-selected",
          !open && classes.collapsed
        )}
        onClick={this.onClick}
      >
        {(icon || iconOutlined) && (
          <ListItemIcon className={cn(classes.icon, selected && "-selected")}>
            {selected ? icon : iconOutlined || icon}
          </ListItemIcon>
        )}
        <span className={cn(classes.listItemText, selected && "-selected")}>
          {title || ""}
        </span>
      </ListItem>
    );
  }
}

export const SideBarItem = withRouter(withStyles(styles)(SideBarItemComponent));
