import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1000,
    position: "fixed",
    backdropFilter: "blur(10px)",
    inset: 0,
    userSelect: "none"
  },
  messageContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    backgroundColor: "#fdf3e8",
    color: "#ef8a20"
  },
  keyboardLabel: {
    padding: "1px 6px 2px 5px",
    margin: "0 6px",
    whiteSpace: "nowrap",
    color: "#555",
    backgroundColor: "white",
    border: "1px solid #bbb",
    borderRadius: 3,
    fontSize: "12px",
    fontWeight: 700
  },
  logo: {
    marginBottom: 12,
    maxWidth: 200
  }
});
