import { useEffect, useMemo } from "react";

import { Button, Grid, TextField, Tooltip } from "@material-ui/core";
import CachedIcon from "@material-ui/icons/Cached";
import { DckActionCreators } from "dck-redux";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { HARDWARE_STATUS, Selectors, useDispatchActions } from "srx-shared";
import { ExpansionPanel, Message, SelectField } from "srx-shared/mui";

import { isLockerV3 } from "@comp/Hardware/helpers";
import { HardwareStates } from "@redux/items/types";

import { useStyles } from "../styles";
import DoorState from "./DoorState";

const actions = {
  renewDeviceState: (value, id) =>
    DckActionCreators.itemSave(HardwareStates, null, { id, value }),
  loadDeviceState: lockerNumber =>
    DckActionCreators.itemSelect(HardwareStates, lockerNumber)
};

const weightOptions = [
  { value: false, label: "With Weights" },
  { value: true, label: "Without Weights" }
];

DoorSettings.propTypes = {
  door: PropTypes.object,
  locker: PropTypes.object,
  onSave: PropTypes.func,
  values: PropTypes.object,
  setValues: PropTypes.func
};

export function DoorSettings(props) {
  const { door, locker, values, setValues } = props;
  const { doorSerialNumber, noWeight, number: id } = door || {};

  const connected = locker?.status === HARDWARE_STATUS.GREEN;

  const hardwareState = useSelector(state =>
    Selectors.selectActiveItem(state, HardwareStates)
  );

  const open = useMemo(
    () =>
      hardwareState?.state[0].doors.find(
        doorState => doorState.number === door?.number
      )?.isOpen,
    [door, hardwareState]
  );

  const cls = useStyles({ open });

  const { renewDeviceState, loadDeviceState } = useDispatchActions(actions);

  useEffect(() => {
    loadDeviceState(locker.value);
  }, [loadDeviceState, locker]);

  const handleChange = (field, value) =>
    setValues({ ...values, [field]: value });

  const handleRefresh = () => renewDeviceState(locker.value, locker.id);

  return (
    <Grid item xs={12}>
      <ExpansionPanel defaultExpanded title={"Door Settings"}>
        <Grid
          container
          spacing={2}
          alignItems="center"
          style={{ padding: "0 24px 24px" }}
        >
          <Grid item xs={12} sm={6}>
            <TextField
              value={values.doorSerialNumber ?? doorSerialNumber ?? ""}
              label="Door Serial Number"
              fullWidth
              onChange={e => handleChange("doorSerialNumber", e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SelectField
              fieldProps={{
                label: "Door Type",
                value: noWeight ?? true,
                margin: "none",
                fullWidth: true,
                disabled: isLockerV3()
              }}
              onChange={value => handleChange("noWeight", value)}
              options={weightOptions}
            />
          </Grid>
          {!noWeight && values.noWeight && (
            <Grid item xs={12}>
              <Message variant="warning">
                A change in weight&apos;s status will trigger deletion of
                VMI&apos;s, associated with the door
              </Message>
            </Grid>
          )}
          <Grid item xs={12} sm={6} className={cls.title}>
            Door Configuration
          </Grid>
          <Grid item xs={12} sm={6} className={cls.justifyEnd}>
            <Tooltip title={!connected ? "Locker isn't connected" : ""}>
              <div>
                <Button
                  variant="text"
                  color="primary"
                  startIcon={<CachedIcon />}
                  onClick={handleRefresh}
                  disabled={!connected}
                >
                  Refresh
                </Button>
              </div>
            </Tooltip>
          </Grid>
          {door && (
            <DoorState door={door} id={id} locker={locker} open={open} />
          )}
        </Grid>
      </ExpansionPanel>
    </Grid>
  );
}
