export const styles = theme => ({
  root: {
    display: "flex",
    alignItems: "center",
    height: "100%"
  },
  label: {
    paddingBottom: 2,
    fontSize: "14px",
    color: "#333"
  },
  group: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "row",
    justifyContent: "space-between"
  },
  radio: {
    marginTop: 4,
    marginLeft: 8,
    padding: 4
  },
  switch: {
    marginRight: 4
  },
  helperText: {
    minHeight: 18,
    fontSize: "0.75rem",
    color: "gray"
  },
  option: {}
});
