/* eslint react/display-name: "off" */
/* eslint react/prop-types: "off" */
import React from "react";

import ApproveIcon from "@material-ui/icons/Check";
import RejectIcon from "@material-ui/icons/Close";
import DocumentIcon from "@material-ui/icons/InsertDriveFileOutlined";
import { DckActionCreators } from "dck-redux";

import { IconButton } from "srx-shared/mui";

import { getS3DocumentLink } from "@api";
import { Document } from "@items/types";
import { store } from "@redux/store";
import { DOCUMENT_STATUS } from "config";

const openDoc = row => {
  const newTab = window.open("", "_blank");
  const { token, id } = row.attachment;
  getS3DocumentLink(token, id).then(
    response => (newTab.location.href = response.data)
  );
};

const updateDocumentStatus = data =>
  store.dispatch(DckActionCreators.itemSave(Document, data.id, data));

const onApprove = row =>
  updateDocumentStatus({ ...row, status: DOCUMENT_STATUS.APPROVED });
const onReject = row =>
  updateDocumentStatus({ ...row, status: DOCUMENT_STATUS.REJECTED });

export const Actions = ({ row }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
        margin: "3px 0"
      }}
    >
      <IconButton
        tip="View document"
        onClick={() => openDoc(row)}
        icon={<DocumentIcon />}
        data-testid="view"
      />
      <IconButton
        tip="Approve"
        onClick={() => onApprove(row)}
        icon={<ApproveIcon />}
        disabled={row.status === DOCUMENT_STATUS.APPROVED}
        data-testid="approve"
      />
      <IconButton
        tip="Reject"
        onClick={() => onReject(row)}
        icon={<RejectIcon />}
        disabled={row.status === DOCUMENT_STATUS.REJECTED}
        data-testid="reject"
      />
    </div>
  );
};
