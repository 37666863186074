/* eslint react/prop-types: "off" */

import { mapInitialSettingsFor } from "srx-shared";
import { SettingsCard } from "srx-shared/mui";

import { ShipToTemplateSettings } from "@items/types";

import { AutoSubmitScheduleSettingsItem } from "./Cards/AutoSubmitScheduleSettings";
import { CribCrawlSettingsItem } from "./Cards/CribCrawlSettings";
import { RLSubmitSettingsItem } from "./Cards/RLSubmitIntegrationSettings";
import { ShipToVmiListSettingsItem } from "./Cards/VmiListSettings";
import { CheckoutSoftwareSettingsItem } from "./Cards/checkoutSoftwareSettings";
import { CriticalMinAlertSettingsItem } from "./Cards/criticalMinAlertSettings";
import { LotSerializationSettingsItem } from "./Cards/lotSerializationSettings";
import { MultiLocationsSettingsItem } from "./Cards/multiLocationsSettings";
import { OrderCloseLogicSettingsItem } from "./Cards/orderCloseLogicSettings";
import { PricingSourceSettingsItem } from "./Cards/pricingSourceSetting";
import { PricingViewSettingsItem } from "./Cards/pricingViewSettings";
import { PutAwaySettingsItem } from "./Cards/putAwaySettings";
import { QRCodeCheckoutSettingsItem } from "./Cards/qRCodeCheckoutSettings";
import { ReorderListSettingsItem } from "./Cards/reorderListSettings";
import { RfidAutoTransitSettingsItem } from "./Cards/rfidAutoTransitSettings";
import { RfidCheckoutCartSettingsItem } from "./Cards/rfidCheckoutCartSettings";
import { VMIListIntegrationSettingsItem } from "./Cards/vmiListIntegrationSettings";

export const ShipToSettingsItem = {
  type: ShipToTemplateSettings
};

// sections
ShipToSettingsItem.sectionsConfig = {
  autoSubmitSettings: {
    title: "Auto Submit Schedule",
    section: "autoSubmitSettings",
    formConfig: AutoSubmitScheduleSettingsItem.formConfig,
    Form: SettingsCard,
    renderActions: () => {},
    useDefaultTitle: "Use Customer’s settings"
  },
  replenishmentListSettings: {
    title: "Reorder List Settings",
    section: "replenishmentListSettings",
    formConfig: ReorderListSettingsItem.formConfig,
    Form: SettingsCard,
    renderActions: () => {},
    useDefaultTitle: "Use Customer’s settings"
  },
  multiLocationsSettings: {
    title: "Super Ship-to",
    section: "multiLocationsSettings",
    formConfig: MultiLocationsSettingsItem.formConfig,
    Form: SettingsCard,
    renderActions: () => {},
    useDefaultTitle: "Use Customer’s settings"
  },
  checkoutSoftwareSettings: {
    title: "Reorder Controls",
    section: "checkoutSoftwareSettings",
    formConfig: CheckoutSoftwareSettingsItem.formConfig,
    Form: SettingsCard,
    renderActions: () => {},
    useDefaultTitle: "Use Customer’s settings"
  },
  putAwaySettings: {
    title: "Put away settings",
    section: "putAwaySettings",
    formConfig: PutAwaySettingsItem.formConfig,
    Form: SettingsCard,
    renderActions: () => {},
    useDefaultTitle: "Use Customer’s settings"
  },
  serialNumberSettings: {
    title: "Lot & Serialization Settings",
    section: "serialNumberSettings",
    formConfig: LotSerializationSettingsItem.formConfig,
    Form: SettingsCard,
    renderActions: () => {},
    useDefaultTitle: "Use Customer’s settings"
  },
  pricingSourceSettings: {
    title: "Pricing information sources",
    section: "pricingSourceSettings",
    formConfig: PricingSourceSettingsItem.formConfig,
    Form: SettingsCard,
    renderActions: () => {},
    useDefaultTitle: "Use Customer’s settings"
  },
  allowSettings: {
    title: "QR Code Kit & Checkout Portal",
    section: "allowSettings",
    formConfig: QRCodeCheckoutSettingsItem.formConfig,
    Form: SettingsCard,
    renderActions: () => {},
    useDefaultTitle: "Use Customer’s settings"
  },
  pricingViewSettings: {
    title: "Pricing View",
    section: "pricingViewSettings",
    formConfig: PricingViewSettingsItem.formConfig,
    Form: SettingsCard,
    renderActions: () => {},
    useDefaultTitle: "Use Customer’s settings"
  },
  criticalMinAlertSettings: {
    title: "Stock Out & Critical Min Alerts",
    section: "criticalMinAlertSettings",
    formConfig: CriticalMinAlertSettingsItem.formConfig,
    Form: SettingsCard,
    renderActions: () => {},
    useDefaultTitle: "Use Customer’s settings"
  },
  rfidAutoCheckoutCartSettings: {
    title: "Automated checkout cart for the RFID tags",
    section: "rfidAutoCheckoutCartSettings",
    formConfig: RfidCheckoutCartSettingsItem.formConfig,
    Form: SettingsCard,
    renderActions: () => {},
    useDefaultTitle: "Use Customer’s settings",
    readOnly: true
  },
  rfidTransitSettings: {
    title: "RFID settings",
    section: "rfidTransitSettings",
    formConfig: RfidAutoTransitSettingsItem.formConfig,
    Form: SettingsCard,
    renderActions: () => {},
    useDefaultTitle: "Use Customer’s settings"
  },
  orderCloseSettings: {
    title: "Order Close Logic",
    section: "orderCloseSettings",
    formConfig: OrderCloseLogicSettingsItem.formConfig,
    Form: SettingsCard,
    renderActions: () => {},
    useDefaultTitle: "Use Customer’s settings"
  },
  rlSubmitIntegrationSettings: {
    title: "Reorder List Submit Integration",
    section: "rlSubmitIntegrationSettings",
    formConfig: RLSubmitSettingsItem.formConfig,
    Form: SettingsCard,
    useDefaultTitle: "Use Customer’s settings",
    renderActions: () => {}
  },
  cribCrawlSettings: {
    title: "CribCrawl Settings",
    section: "cribCrawlSettings",
    formConfig: CribCrawlSettingsItem.formConfig,
    Form: SettingsCard,
    renderActions: () => {},
    useDefaultTitle: "Use Customer’s settings"
  },
  vmiListSettings: {
    title: "VMI List",
    formConfig: ShipToVmiListSettingsItem.formConfig,
    section: "vmiListSettings",
    Form: SettingsCard,
    renderActions: () => {},
    useDefaultTitle: "Use Customer’s settings"
  },
  vmiIntegrationSetting: {
    title: "VMI List Integration",
    section: "vmiIntegrationSetting",
    formConfig: VMIListIntegrationSettingsItem.formConfig,
    Form: SettingsCard,
    useDefaultTitle: "Use Customer’s settings",
    renderActions: () => {}
  }
};

ShipToSettingsItem.sections = [
  "serialNumberSettings",
  "replenishmentListSettings",
  "orderCloseSettings",
  "checkoutSoftwareSettings",
  "autoSubmitSettings",
  "multiLocationsSettings",
  "rlSubmitIntegrationSettings",
  "pricingSourceSettings",
  "pricingViewSettings",
  "criticalMinAlertSettings",
  "rfidTransitSettings",
  "rfidAutoCheckoutCartSettings",
  "cribCrawlSettings",
  "putAwaySettings",
  "allowSettings",
  "vmiListSettings",
  "vmiIntegrationSetting"
];

ShipToSettingsItem.initialValues = mapInitialSettingsFor(ShipToSettingsItem);
