import { Button } from "@material-ui/core";
import { Formik } from "formik";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

import { Form } from "srx-shared/mui";

import { pages } from "@routes/pages";

import { SettingsStatusIcon } from "./SettingsStatusIcon";
import { ShipToTemplateNameItem } from "./ShipToTemplateNameItem";

const form = ShipToTemplateNameItem.formConfig;

ShipToTemplateNameForm.propTypes = {
  invalidSettings: PropTypes.object,
  onSubmit: PropTypes.func,
  initialValues: PropTypes.object
};

export function ShipToTemplateNameForm(props) {
  const { onSubmit, invalidSettings, initialValues } = props;

  const { push } = useHistory();

  const settingsErrors = Object.values(invalidSettings);

  const isError = Boolean(settingsErrors.length);

  const handleCancel = () => push(pages.shipToTemplates.path);

  const renderActions = formikProps => (
    <div style={{ display: "flex", alignItems: "center", marginRight: 10 }}>
      <Button
        onClick={handleCancel}
        color="default"
        variant="contained"
        style={{ minWidth: 100, marginLeft: 16 }}
        data-testid="cancel-button"
      >
        Cancel
      </Button>
      <Button
        type="submit"
        color="primary"
        variant="contained"
        style={{ minWidth: 100, marginLeft: 16 }}
        data-testid="confirm-button"
        disabled={isError}
      >
        Save
      </Button>
    </div>
  );

  return (
    <div
      style={{
        display: "flex",
        margin: "0 auto 20px auto",
        maxWidth: "1600px",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={form.schema}
      >
        {formikProps => (
          <Form
            {...formikProps}
            form={form}
            renderActions={renderActions}
            vertical
          />
        )}
      </Formik>
      <SettingsStatusIcon settingsErrors={settingsErrors} isError={isError} />
    </div>
  );
}
