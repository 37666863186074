/* eslint react/display-name: "off" */
/* eslint react/prop-types: "off" */
import { Grid } from "@material-ui/core";
import { DckSelectors } from "dck-redux";
import * as V from "yup";

import { mapFormFieldsFor, isObject } from "srx-shared";
import { TitleComponent } from "srx-shared/mui";

import { ShipToTemplateSettings } from "@items/types";
import { store } from "@redux/store";
import { VMI_TYPES_OPTIONS } from "config";

import { FieldWithCheckboxes } from "./CribCrawlSettingsFields";

const onChangeAllowCribCrawlList = (value, form) => {
  const { setValues } = form;
  const values = { ...form.values };
  const settings = DckSelectors.getItemData(
    store.getState(),
    ShipToTemplateSettings,
    "settings"
  );
  const defaultSettings = settings["cribCrawlSettings"].defaultSettings;

  Object.keys(values)
    .filter(field => isObject(values[field]))
    .forEach(
      field =>
        (values[field] = value
          ? defaultSettings[field]
          : { definedSku: false, undefinedSku: false })
    );
  values.allowCribCrawlList = value;
  setValues(values);
};

export const CribCrawlSettingsItem = {
  type: ShipToTemplateSettings,
  fieldsConfig: {
    allowCribCrawlList: {
      label: "Allow CribCrawl list",
      type: "switch",
      size: 9,
      noHelperText: true,
      onChange: onChangeAllowCribCrawlList
    },
    requiredStepsAndFieldsLabel: {
      type: "component",
      Component: () => (
        <TitleComponent
          text="Required fields"
          size={4}
          style={{ padding: "12px 0 0 0" }}
        />
      )
    },
    identifiedSkuLabel: {
      type: "component",
      Component: () => (
        <TitleComponent
          text="Identified SKU"
          style={{ paddingBottom: 0 }}
          size={3}
        />
      )
    },
    unidentifiedSkuLabel: {
      type: "component",
      Component: () => (
        <TitleComponent
          text="UFO (Unidentified SKU)"
          style={{ paddingBottom: 0 }}
          size={3}
        />
      )
    },
    sku: {
      type: "component",
      Component: props => (
        <FieldWithCheckboxes {...props} name="sku" label="SKU" />
      )
    },
    picture: {
      type: "component",
      Component: props => (
        <FieldWithCheckboxes {...props} name="picture" label="Picture" />
      )
    },
    minMax: {
      type: "component",
      Component: props => (
        <FieldWithCheckboxes {...props} name="minMax" label="Min / Max" />
      )
    },
    location: {
      type: "component",
      Component: props => (
        <FieldWithCheckboxes {...props} name="location" label="Location" />
      )
    },
    triggerType: {
      type: "component",
      Component: props => (
        <FieldWithCheckboxes
          {...props}
          name="triggerType"
          label="Trigger Type"
        />
      )
    },
    binType: {
      type: "component",
      Component: props => (
        <FieldWithCheckboxes {...props} name="binType" label="Bin Type" />
      )
    },
    ohi: {
      type: "component",
      Component: props => (
        <FieldWithCheckboxes {...props} name="ohi" label="OHI" />
      )
    },
    defaultTriggerLabel: {
      type: "component",
      Component: () => (
        <Grid
          item
          xs={4}
          container
          alignItems="center"
          style={{ fontSize: 14, display: "flex" }}
        >
          Default Trigger
        </Grid>
      )
    },
    defaultTrigger: {
      name: "defaultTrigger",
      label: "Default Trigger",
      type: "select",
      options: VMI_TYPES_OPTIONS,
      size: 8,
      SelectProps: {
        isClearable: true
      }
    },
    locationsLabel: {
      type: "component",
      Component: () => (
        <Grid
          item
          xs={4}
          container
          alignItems="center"
          style={{ fontSize: 14, display: "flex" }}
        >
          Default Locations
        </Grid>
      )
    },
    locationNames1: {
      name: "locationNames1",
      label: "Location Names 1",
      isMulti: true,
      size: 8
    },
    locationNames2: {
      name: "locationNames2",
      label: "Location Names 2",
      isMulti: true,
      size: 8
    },
    locationNames3: {
      name: "locationNames3",
      label: "Location Names 3",
      isMulti: true,
      size: 8
    },
    locationNames4: {
      name: "locationNames4",
      label: "Location Names 4",
      isMulti: true,
      size: 8
    },
    horizontalSpace: {
      type: "component",
      Component: () => <Grid item xs={4} />
    },
    verticalSpace: {
      type: "component",
      Component: () => <Grid item xs={12} style={{ height: 25 }} />
    }
  },
  schema: V.object({
    locationNames1: V.array().label("Location Names 1").max(5),
    locationNames2: V.array().label("Location Names 2").max(5),
    locationNames3: V.array().label("Location Names 3").max(5),
    locationNames4: V.array().label("Location Names 4").max(5)
  }),
  fields: [
    "allowCribCrawlList",
    "requiredStepsAndFieldsLabel",
    "identifiedSkuLabel",
    "unidentifiedSkuLabel",
    "sku",
    "picture",
    "minMax",
    "location",
    "triggerType",
    "binType",
    "ohi",
    "verticalSpace",
    "defaultTriggerLabel",
    "defaultTrigger",
    "verticalSpace",
    "locationsLabel",
    "locationNames1",
    "horizontalSpace",
    "locationNames2",
    "horizontalSpace",
    "locationNames3",
    "horizontalSpace",
    "locationNames4"
  ]
};

CribCrawlSettingsItem.formConfig = mapFormFieldsFor(CribCrawlSettingsItem);
