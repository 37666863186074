import React from "react";

import ActivateIcon from "@material-ui/icons/ToggleOffOutlined";
import DeactivateIcon from "@material-ui/icons/ToggleOn";
import { DckActionCreators } from "dck-redux";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { IconButton } from "srx-shared/mui";

import { Distributor } from "@items/types";

import { DistributorContext } from "./context";

class ChangeStatusButtonComponent extends React.Component {
  static contextType = DistributorContext;
  static propTypes = {
    row: PropTypes.object,
    selectDistributor: PropTypes.func
  };

  handleClick = () => {
    const { row, selectDistributor } = this.props;
    const { showChangeStatusDialog } = this.context;
    selectDistributor(row.id);
    showChangeStatusDialog(true);
  };

  render() {
    const { status } = this.props.row;
    return (
      <>
        {status === "INACTIVE" && (
          <IconButton
            icon={<ActivateIcon />}
            tip={"Activate distributor"}
            onClick={this.handleClick}
            data-testid="activate"
          />
        )}
        {status === "ACTIVE" && (
          <IconButton
            icon={<DeactivateIcon />}
            tip={"Deactivate distributor"}
            onClick={this.handleClick}
            data-testid="deactivate"
          />
        )}
      </>
    );
  }
}

ChangeStatusButtonComponent.defaultProps = {
  row: {},
  selectDistributor: () => {}
};

const mapDispatchToProps = {
  selectDistributor: id => DckActionCreators.itemMakeActive(Distributor, id)
};

export const ChangeStatusButton = connect(
  null,
  mapDispatchToProps
)(ChangeStatusButtonComponent);
