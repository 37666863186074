import PropTypes from "prop-types";
import { ReactTableDefaults } from "react-table";

import { ThWithMultiSortComponent } from "./ThWithMultiSortComponent";

const { ThComponent: DefaultThComponent } = ReactTableDefaults;

ThComponent.propTypes = {
  columnProps: PropTypes.object
};
export function ThComponent(props) {
  const { columnProps, ...rest } = props;
  const { multiSort } = columnProps;

  return multiSort ? (
    <ThWithMultiSortComponent {...props} />
  ) : (
    <DefaultThComponent {...rest} />
  );
}
