import { useState } from "react";

import { DialogActions, DialogContent, Button } from "@material-ui/core";
import ActivateIcon from "@material-ui/icons/ToggleOffOutlined";
import DeactivateIcon from "@material-ui/icons/ToggleOn";
import { DckActionCreators } from "dck-redux";
import PropTypes from "prop-types";

import { useDispatchActions } from "srx-shared";
import { IconButton, ModalDialog } from "srx-shared/mui";

import { ShipToTemplate } from "@items/types";

const styleContent = {
  paddingTop: 0,
  width: "100%"
};

const styleActions = {
  paddingRight: 16,
  paddingBottom: 16
};

const actions = {
  updateStatus: data => DckActionCreators.itemsSave(ShipToTemplate, data)
};

ChangeStatus.propTypes = {
  row: PropTypes.object
};

export function ChangeStatus(props) {
  const { status, templateName, id } = props.row;

  const { updateStatus } = useDispatchActions(actions);

  const [open, setOpen] = useState(false);

  const handleClick = () => setOpen(true);

  const handleClose = () => setOpen(false);

  const isActiveStatus = status === "ACTIVE";

  const handleAction = () => {
    updateStatus({ id, status: isActiveStatus ? "DRAFT" : "ACTIVE" });
    setOpen(false);
  };

  const action = isActiveStatus ? "deactivate" : "activate";
  const iconProps = isActiveStatus
    ? {
        icon: <DeactivateIcon />,
        tip: "Deactivate template",
        "data-testid": "deactivate"
      }
    : {
        icon: <ActivateIcon />,
        tip: "Activate template",
        "data-testid": "activate"
      };

  return (
    <>
      <IconButton {...iconProps} onClick={handleClick} />
      {open && (
        <ModalDialog
          open
          onClose={handleClose}
          title="Change template status"
          size="sm"
        >
          <DialogContent style={styleContent}>
            <span>
              Are you sure you want to <b>{action}</b> template?
            </span>
            <br />
            <br />
            <pre>{templateName}</pre>
          </DialogContent>
          <DialogActions style={styleActions}>
            <Button
              onClick={handleClose}
              color="default"
              variant="contained"
              data-testid="cancel-button"
            >
              Cancel
            </Button>
            <Button
              onClick={handleAction}
              color={action === "activate" ? "primary" : "secondary"}
              variant="contained"
              data-testid="confirm-button"
            >
              {action}
            </Button>
          </DialogActions>
        </ModalDialog>
      )}
    </>
  );
}
