import React from "react";

import CachedIcon from "@material-ui/icons/Cached";
import { DckActionCreators } from "dck-redux";

import { useDispatchActions } from "srx-shared";
import { ActionButton } from "srx-shared/mui";

import { WeightHistory } from "@items/types";

const actions = {
  loadWeightHistory: () => DckActionCreators.itemsLoad(WeightHistory)
};

export function Refresh() {
  const { loadWeightHistory } = useDispatchActions(actions);

  const handleClick = () => loadWeightHistory();

  return (
    <ActionButton icon={CachedIcon} title="Refresh" onClick={handleClick} />
  );
}
