export const styles = theme => ({
  label: {
    paddingBottom: 4,
    transform: "translate(0, 24px) scale(1)"
  },
  focused: {
    color: theme.palette.primary.light
  },
  error: {
    color: "brown !important"
  },
  shrink: {
    transform: "translate(0, 1.5px) scale(0.75)"
  },
  disabled: { color: "#bbb !important" }
});

export const theme = theme => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: "rgba(0, 0, 0, 0.08)",
    primary: "#e3f2fd"
  }
});
