export const defaultSettings = {
  id: null,
  serialNumberSettings: {
    settings: {
      enableAutoExpire: false,
      daysUntilAutoExpiration: 0,
      enableExpirationAlarm: false,
      daysUntilExpirationAlarm: 0,
      useDefault: false
    },
    defaultSettings: {
      enableAutoExpire: false,
      daysUntilAutoExpiration: 0,
      enableExpirationAlarm: false,
      daysUntilExpirationAlarm: 0
    },
    useDefault: false
  },
  replenishmentListSettings: {
    settings: {
      email: "",
      pricingNotAvailableBehavior: "AS_A_QUOTE",
      orderSubmitSettings: false
    },
    defaultSettings: {
      email: "",
      pricingNotAvailableBehavior: "AS_A_QUOTE",
      orderSubmitSettings: true
    },
    useDefault: false
  },
  orderCloseSettings: {
    settings: {
      orderCloseLogic: "MANUALLY",
      time: "19:00",
      delay: "00:30"
    },
    defaultSettings: {
      orderCloseLogic: "MANUALLY",
      time: "19:00",
      delay: "00:30"
    },
    useDefault: false
  },
  checkoutSoftwareSettings: {
    settings: {
      reorderControls: "ADD_AT_MIN",
      labelOptions: ["TRACK_OHI"]
    },
    defaultSettings: {
      reorderControls: "ADD_AT_MIN",
      labelOptions: ["TRACK_OHI"]
    },
    useDefault: false
  },
  multiLocationsSettings: {
    settings: {
      multiLocationsEnabled: true
    },
    defaultSettings: {
      multiLocationsEnabled: true
    },
    useDefault: false
  },
  pricingSourceSettings: {
    settings: {
      pricingSource: "SRX",
      useCache: true
    },
    defaultSettings: {
      availablePricingSources: [
        {
          label: "string",
          value: "API",
          disabled: true
        }
      ],
      pricingSource: "SRX",
      useCache: true
    },
    useDefault: false
  },
  autoSubmitSettings: {
    settings: {
      time: "00:00",
      freqDays: ["MONDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "TUESDAY"],
      submitImmediately: true,
      autoSubmitAsOrder: true,
      autoSubmit: false
    },
    defaultSettings: {
      time: "00:00",
      freqDays: ["MONDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "TUESDAY"],
      submitImmediately: false,
      autoSubmitAsOrder: false,
      autoSubmit: false
    },
    useDefault: false
  },
  rlSubmitIntegrationSettings: {
    settings: {
      integration: "EMAIL",
      metadata: {}
    },
    defaultSettings: {
      integration: "EMAIL",
      metadata: {
        erp: "",
        erpEmail: "",
        orderStatus: "",
        quoteStatus: ""
      }
    },
    useDefault: false
  },
  pricingViewSettings: {
    settings: {
      enablePricingView: true,
      useDefault: true
    },
    defaultSettings: {
      enablePricingView: true
    },
    useDefault: false
  },
  criticalMinAlertSettings: {
    settings: {
      enabled: false,
      enableStockOut: false,
      alertEmails: "",
      useDefault: false
    },
    defaultSettings: {
      enabled: false,
      enableStockOut: false,
      alertEmails: ""
    },
    useDefault: false
  },
  rfidTransitSettings: {
    settings: {
      enabled: true,
      time: "12:15",
      freqDays: ["MONDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "TUESDAY"],
      useDefault: false
    },
    defaultSettings: {
      enabled: true,
      time: "00:00",
      freqDays: ["MONDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "TUESDAY"],
      useDefault: false
    }
  },
  rfidAutoCheckoutCartSettings: {
    settings: {
      user: {
        id: null
      },
      enableAddingToCart: false,
      useDefault: false
    },
    defaultSettings: {
      user: null,
      enableAddingToCart: false
    }
  },
  cribCrawlSettings: {
    settings: {
      allowCribCrawlList: true,
      sku: { definedSku: true, undefinedSku: true },
      picture: { definedSku: false, undefinedSku: true },
      minMax: { definedSku: true, undefinedSku: true },
      location: { definedSku: true, undefinedSku: true },
      triggerType: { definedSku: true, undefinedSku: false },
      binType: { definedSku: true, undefinedSku: true },
      ohi: { definedSku: true, undefinedSku: true },
      defaultTrigger: [],
      locationNames1: [],
      locationNames2: [],
      locationNames3: [],
      locationNames4: []
    },
    defaultSettings: {
      allowCribCrawlList: true,
      sku: { definedSku: true, undefinedSku: true },
      picture: { definedSku: false, undefinedSku: true },
      minMax: { definedSku: true, undefinedSku: true },
      location: { definedSku: true, undefinedSku: true },
      triggerType: { definedSku: true, undefinedSku: false },
      binType: { definedSku: true, undefinedSku: true },
      ohi: { definedSku: true, undefinedSku: true },
      defaultTrigger: [],
      locationNames1: [],
      locationNames2: [],
      locationNames3: [],
      locationNames4: []
    },
    useDefault: false
  },
  putAwaySettings: {
    settings: {
      email: null,
      deliveryLocationValidationLabel: false,
      deliveryLocationValidationVending: false,
      deliveryLocationValidationButton: false,
      deliveryLocationValidationRfid: false,
      deliveryLocationValidationStorage: false,
      useDefault: false
    },
    defaultSettings: {
      email: null,
      deliveryLocationValidationLabel: false,
      deliveryLocationValidationVending: false,
      deliveryLocationValidationButton: false,
      deliveryLocationValidationRfid: false,
      deliveryLocationValidationStorage: false
    },
    useDefault: false
  },
  allowSettings: {
    settings: {
      enableCheckoutSoftware: false,
      enableQrCodeKit: false,
      useDefault: false
    },
    defaultSettings: {
      enableCheckoutSoftware: false,
      enableQrCodeKit: false
    },
    useDefault: false
  },
  vmiListSettings: {
    settings: {
      asset: null,
      autoSubmit: null,
      createdOn: null,
      customerSku: null,
      distributorSku: null,
      enableVmiList: false,
      inventoryStatus: null,
      lifecycleStatus: null,
      limits: null,
      location: null,
      lot: null,
      manufacturerSku: null,
      ohi: null,
      ownedBy: null,
      packageConversion: null,
      serialization: null,
      suggestedMinMax: null,
      surplus: null,
      triggerType: null,
      useDefault: false,
      weights: null
    },
    defaultSettings: {
      asset: null,
      autoSubmit: null,
      createdOn: null,
      customerSku: null,
      distributorSku: null,
      enableVmiList: false,
      inventoryStatus: null,
      lifecycleStatus: null,
      limits: null,
      location: null,
      lot: null,
      manufacturerSku: null,
      ohi: null,
      ownedBy: null,
      packageConversion: null,
      serialization: null,
      suggestedMinMax: null,
      surplus: null,
      triggerType: null,
      useDefault: false,
      weights: null
    },
    useDefault: false
  },
  vmiIntegrationSetting: {
    settings: {
      useErpApi: false,
      manual: true,
      importing: true,
      interval: "DAILY",
      time: "07:00",
      dayOfWeek: "MONDAY",
      zoneId: null,
      autoValidation: false,
      cribCrawlAutoSequenceLocation: false,
      autoDeletion: false,
      triggerType: "LABEL",
      min: 0,
      max: 1,
      emails: "",
      useDefault: true
    },
    defaultSettings: {
      useErpApi: false,
      manual: true,
      importing: true,
      interval: "DAILY",
      time: "07:00",
      dayOfWeek: "MONDAY",
      zoneId: null,
      autoValidation: false,
      cribCrawlAutoSequenceLocation: false,
      autoDeletion: false,
      triggerType: "LABEL",
      min: 0,
      max: 1,
      emails: ""
    },
    useDefault: false
  },
  shipToInventoryAdjustmentSetting: {
    enabled: true,
    currentShipTos: null,
    availableShipTos: null,
    useAll: true
  }
};
