export const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  paper: {
    margin: 0,
    position: "absolute",
    top: 70,
    overflowY: "auto",
    maxHeight: `calc(100% - ${theme.spacing(12) + 70}px)`
  },
  paperExpandable: {
    margin: 0,
    position: "absolute",
    top: 70,
    overflowY: "visible !important",
    maxHeight: `calc(100% - ${theme.spacing(12) + 70}px)`
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(),
    top: theme.spacing(),
    color: theme.palette.grey[500]
  }
});
