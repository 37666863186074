import React, { useEffect, useState } from "react";

import { Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import { ActionCreators } from "../../actions";
import { PATH_TO_ASSETS, ItemTypeApplication } from "../../config";
import { usePrevious } from "../../hooks";
import { DckSelectors } from "../../redux";
import { ctrlKeyLabel } from "../../utils";
import { captureException } from "../../utils/sentry";
import { Message } from "../Message";
import { useStyles } from "./styles";

export function useNewVersionAvailable() {
  const currentVersion = window.SRX_PLATFORM_VERSION;
  const actualVersion = useSelector(state =>
    DckSelectors.getItemData(state, ItemTypeApplication, "actualBuildVersion")
  );
  return currentVersion && actualVersion
    ? currentVersion !== actualVersion
    : false;
}

export function withNewVersionAvailable(Component) {
  return (props: any) => {
    const newVersionAvailable = useNewVersionAvailable();

    return <Component newVersionAvailable={newVersionAvailable} {...props} />;
  };
}

function ReloadAppMessageComponent() {
  const cls = useStyles();

  return (
    <div className={cls.messageContainer}>
      <Message variant="warning">
        A new version of the portal is available. Reload the page to try
        updates!
      </Message>
      <Button
        variant="contained"
        color="secondary"
        style={{ margin: "0 16px", padding: "4px 16px", minWidth: 80 }}
        onClick={() => window.location.reload()}
      >
        reload
      </Button>
    </div>
  );
}

export const ReloadAppMessage = React.memo(ReloadAppMessageComponent);

export function VersionDiscrepancyHardReloadMessageComponent() {
  const currentVersion = window.SRX_PLATFORM_VERSION;
  const ctrlKey = ctrlKeyLabel();
  const cls = useStyles();

  const [isOldVersion, setIsOldVersion] = useState(false);

  const actualVersion = useSelector(state =>
    DckSelectors.getItemData(state, ItemTypeApplication, "actualBuildVersion")
  );

  const prevActualVersion = usePrevious(actualVersion);

  const dispatch = useDispatch();

  useEffect(() => {
    !actualVersion && dispatch(ActionCreators.checkBuildVersion());
  }, [actualVersion, dispatch]);

  useEffect(() => {
    if (
      !prevActualVersion &&
      actualVersion &&
      currentVersion &&
      actualVersion !== currentVersion
    ) {
      const error = `Old build version. Actual: ${actualVersion}. Current: ${currentVersion}`;
      setIsOldVersion(true);
      captureException(new Error(error), "api-call");
      console.warn(error);
    }
  }, [actualVersion, currentVersion, prevActualVersion]);

  if (!isOldVersion) return null;

  return (
    <div className={cls.root}>
      <img src={`${PATH_TO_ASSETS}/srx-logo.svg`} alt="" className={cls.logo} />
      <Message variant="error">
        You have an old version of the app. Please, press
        <span className={cls.keyboardLabel}>{`${ctrlKey} + F5`}</span>or
        <span className={cls.keyboardLabel}>{`${ctrlKey} + Shift + R`}</span>to
        reload the page.
      </Message>
    </div>
  );
}

export const VersionDiscrepancyHardReloadMessage = React.memo(
  VersionDiscrepancyHardReloadMessageComponent
);
