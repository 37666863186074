import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  header: {
    display: "flex",
    justifyContent: "space-between",
    padding: "12px 8px"
  },
  body: {
    display: "flex",
    padding: "0px 8px",
    maxHeight: 400,
    overflow: "auto"
  },
  button: {
    fontWeight: "bold",
    fontSize: 13,
    padding: "6px 12px"
  },
  selected: {
    backgroundColor: "#F0F3FA"
  }
});
