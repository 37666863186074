import React, { useState, useEffect, useCallback } from "react";

import DateFnsUtils from "@date-io/date-fns";
import { TextField, withStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import PropTypes from "prop-types";

import { IconButton } from "../../IconButton";
import { styles } from "./styles";

const DATE_FORMAT = "MM/dd/yyyy";

const getDateFrom = value => new Date(value).setHours(0, 0, 0, 0);

const getDateTo = value => new Date(value).setHours(23, 59, 59, 999);

const getCorrectRange = range => {
  const correctRange = { ...range };
  if (!correctRange.from && !correctRange.to) {
    correctRange.from = null;
    correctRange.to = null;
  } else if (!correctRange.from && correctRange.to) {
    correctRange.from = getDateFrom(correctRange.to);
    correctRange.to = getDateTo(correctRange.to);
  } else if (correctRange.from && !correctRange.to) {
    correctRange.from = getDateFrom(correctRange.from);
    correctRange.to = getDateTo(new Date());
  } else if (correctRange.from > correctRange.to) {
    correctRange.from = getDateFrom(correctRange.from);
    correctRange.to = getDateTo(correctRange.from);
  } else {
    correctRange.from = getDateFrom(correctRange.from);
    correctRange.to = getDateTo(correctRange.to);
  }
  return correctRange;
};

const RangeDatePickerComponent = props => {
  const {
    classes,
    label,
    PickerProps,
    required = false,
    onChange,
    values: propRange
  } = props;

  const [range, setRange] = useState({ from: null, to: null });

  useEffect(() => {
    const propCorrectRange = getCorrectRange(propRange);
    const correctRange = getCorrectRange(range);
    if (
      propCorrectRange.from !== correctRange.from ||
      propCorrectRange.to !== correctRange.to
    ) {
      setRange(propCorrectRange);
    }
  }, [propRange, range]);

  const handleChange = values => {
    const range = getCorrectRange(values);
    setRange(range);
    onChange && onChange(range);
  };

  const handleClear = () => {
    const range = { from: null, to: null };
    setRange(range);
    onChange && onChange(range);
  };

  const isMarginNone = PickerProps.margin === "none";

  const TextFieldFrom = useCallback(
    props => (
      <TextField
        margin="normal"
        {...props}
        placeholder="from"
        InputLabelProps={{
          shrink: true
        }}
        InputProps={{ className: classes.startDateInput }}
        helperText=""
        onChange={() => {}}
      />
    ),
    [classes.startDateInput]
  );

  const TextFieldTo = useCallback(
    props => (
      <TextField
        margin="normal"
        {...props}
        placeholder="to"
        InputLabelProps={{
          shrink: true
        }}
        InputProps={{ className: classes.endDateInput }}
        helperText=""
        onChange={() => {}}
      />
    ),
    [classes.endDateInput]
  );

  return (
    <div className={classes.root}>
      {label && (
        <span className={classes.label} style={{ top: isMarginNone ? 0 : 15 }}>
          {label}
        </span>
      )}
      <div className={classes.field}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <>
            <DatePicker
              variant="inline"
              autoOk
              {...PickerProps}
              format={DATE_FORMAT}
              value={range.from}
              onChange={value =>
                handleChange({ ...range, from: getDateFrom(value) })
              }
              style={{ height: 48, justifyContent: "flex-end", flexGrow: 1 }}
              TextFieldComponent={TextFieldFrom}
            />
            <div
              className={classes.divider}
              style={{ bottom: isMarginNone ? 5 : 15 }}
            >
              ─
            </div>
            <DatePicker
              variant="inline"
              autoOk
              {...PickerProps}
              format={DATE_FORMAT}
              value={range.to}
              onChange={value =>
                handleChange({ ...range, to: getDateTo(value) })
              }
              minDate={range.from}
              style={{ height: 48, justifyContent: "flex-end", flexGrow: 1 }}
              TextFieldComponent={TextFieldTo}
            />
            {!required && (
              <IconButton
                onClick={handleClear}
                className={classes.clearButton}
                icon={<CloseIcon className={classes.clearIcon} />}
                data-testid="clear"
              />
            )}
          </>
        </MuiPickersUtilsProvider>
      </div>
    </div>
  );
};
RangeDatePickerComponent.propTypes = {
  values: PropTypes.object,
  classes: PropTypes.object,
  PickerProps: PropTypes.object,
  label: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func
};

export const RangeDatePicker = withStyles(styles)(RangeDatePickerComponent);
