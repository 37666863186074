import React from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";

import { getItemData } from "../../actions/selectors";
import { DckSelectors } from "../../redux";
import { ActionButton } from "../ActionButton";

export const exportBlock = ({ type }, params = []) => {
  const exportProcess = `${type.toUpperCase()}_EXPORT`;
  class Wrapper extends React.Component {
    static propTypes = {
      exportItem: PropTypes.func,
      exporting: PropTypes.bool,
      labelForExportButton: PropTypes.string,
      exportDisabled: PropTypes.bool,
      onExport: PropTypes.func
    };

    onExport = () => {
      const data = params.reduce((arr, param) => {
        arr[param] = this.props[param] || "";
        return arr;
      }, {});
      this.props.exportItem({ type, ...data });
    };

    render() {
      const { exporting, exportDisabled, labelForExportButton } = this.props;
      return (
        <ActionButton
          onClick={this.onExport}
          disabled={exportDisabled || exporting}
          title={labelForExportButton}
          variant="export"
        />
      );
    }
  }

  const mapStateParams = state => {
    if (params.length === 0) return {};
    const mapping = params.reduce(
      (mapping, param) => ({
        ...mapping,
        [param]: getItemData(state, type, param) || null
      }),
      {}
    );
    return mapping;
  };

  const mapStateToProps = state => ({
    ...mapStateParams(state),
    exporting: DckSelectors.selectProcessRunning(state, exportProcess)
  });

  const mapDispatchToProps = {
    exportItem: data => ({
      type: exportProcess,
      data
    })
  };

  return connect(mapStateToProps, mapDispatchToProps)(Wrapper);
};
