import React from "react";

import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

import { ActionButton } from "../ActionButton";
import { TableActionsContainer } from "./ActionsContainer";
import { styles } from "./styles";

export const actionsFor = (
  item,
  {
    CreateForm = null,
    ImportBlock = null,
    ExportBlock = null,
    Custom = null
  } = {}
) => {
  class Wrapper extends React.Component {
    static propTypes = {
      classes: PropTypes.object.isRequired,
      table: PropTypes.object,
      beforeShowCreateForm: PropTypes.func, // check conditions before showing create dialog, return true if ok to show
      loading: PropTypes.bool,
      disableAddButton: PropTypes.bool,
      addButtonTitle: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      importProps: PropTypes.object,
      exportProps: PropTypes.object,
      customProps: PropTypes.object
    };

    state = {
      openCreateDialog: false
    };

    componentDidUpdate(prevProps, prevState) {
      if (prevState.openCreateDialog !== this.state.openCreateDialog)
        global.currentAction = this.state.openCreateDialog ? "edit" : null;
    }

    showCreateDialog = () => {
      const { beforeShowCreateForm, table } = this.props;
      const openCreateDialog = beforeShowCreateForm
        ? beforeShowCreateForm(table)
        : true;
      this.setState({ openCreateDialog });
    };

    hideCreateDialog = () => this.setState({ openCreateDialog: false });

    render() {
      const {
        disableAddButton,
        importProps,
        exportProps,
        customProps,
        addButtonTitle
      } = this.props;
      const { openCreateDialog } = this.state;
      return (
        <TableActionsContainer>
          {Custom && <Custom {...customProps} />}
          {ImportBlock && <ImportBlock {...importProps} />}
          {ExportBlock && <ExportBlock {...exportProps} />}
          {CreateForm && (
            <>
              <ActionButton
                variant="add"
                onClick={this.showCreateDialog}
                disableRipple
                disabled={disableAddButton}
                title={addButtonTitle}
              />
              <CreateForm
                open={openCreateDialog}
                onClose={this.hideCreateDialog}
              />
            </>
          )}
        </TableActionsContainer>
      );
    }
  }

  return withStyles(styles)(Wrapper);
};
