/* eslint react/display-name: "off" */
/* eslint react/prop-types: "off" */
import React from "react";

import { Formatters } from "srx-shared";
import { Cell } from "srx-shared/mui";

import { isValidPrice } from "@comp/Fees/isValidPrice";
import { BaseFee } from "@items/types";

export const BaseFeeItem = {
  type: BaseFee,
  name: "BaseFee"
};

// table
BaseFeeItem.columns = [
  {
    Header: "Name",
    accessor: "name",
    Cell: props => <Cell {...props} />
  },
  {
    Header: "Price",
    accessor: "value",
    inline: true,
    View: ({ value }) => Formatters.price(value),
    Cell: props => <Cell {...props} />
  }
];

// form fields
BaseFeeItem.fieldsConfig = {
  value: {
    inlineValidation: isValidPrice
  }
};
