import main from "@material-ui/core/colors/indigo";
import { createMuiTheme } from "@material-ui/core/styles";

import { deepMerge } from "../utils";

const defaultColors = {
  primary: "#003a70",
  secondary: "#EF7E5C",
  blue: "#436BBB",
  grey: "#F0F0F0",
  btnHover: "rgba(217,225,241,0.5)"
};

const defaultTheme = {
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: 12
      }
    },
    MuiFormControlLabel: {
      root: {
        fontWeight: "normal",
        fontSize: "small",
        whiteSpace: "nowrap",
        overflow: "hidden",
        color: "#555"
      }
    },
    MuiFormLabel: {
      root: {
        fontWeight: "normal",
        fontSize: "small",
        whiteSpace: "nowrap",
        overflow: "hidden",
        color: "#555",
        "&$error": {
          color: "brown !important"
        }
      }
    },
    MuiTabs: {
      flexContainer: {
        maxWidth: "100%"
      },
      fixed: {
        overflowX: "auto",
        overflowY: "hidden"
      }
    },
    MuiButton: {
      contained: {
        fontWeight: "bold"
      },
      containedPrimary: {
        color: "white"
      },
      containedSecondary: {
        color: "white"
      }
    }
  },
  typography: {
    useNextVariants: true,
    fontFamily: ["Lato"]
  },
  palette: {
    primary: {
      main: defaultColors.primary
    },
    secondary: {
      main: defaultColors.secondary
    }
  },
  color: {
    ...defaultColors
  },
  zIndex: {
    drawer: 800
  },
  mixins: {
    loadingBar: {
      height: 3,
      background: main[100],
      color: main[300]
    },
    messageBar: {
      height: 48
    },
    appBar: {
      height: 48,
      indicator: {
        height: 4,
        color: "white"
      }
    },
    pageBar: {
      height: 36
    },
    sideBar: {
      width: 240,
      minWidth: 56
    }
  }
};

export function createTheme(theme = {}) {
  return createMuiTheme(deepMerge(defaultTheme, theme));
}
