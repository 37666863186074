import React, { useEffect, useMemo } from "react";

import { DckActionCreators } from "dck-redux";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import {
  breadcrumbsFor,
  usePageTitle,
  useDispatchActions,
  Selectors
} from "srx-shared";

import { Storage, StorageConfig } from "@items/types";
import { pages } from "@routes/pages";

import { Planogram } from "./Planogram";
import { useStyles } from "./styles";

const BreadcrumbsComponent = breadcrumbsFor();

const { itemsLoad, itemMakeActive } = DckActionCreators;

const actions = {
  loadStorage: storageId => itemsLoad(Storage, { storageId }),
  loadStorageConfig: storageId => itemsLoad(StorageConfig, { storageId }),
  clearStorage: () => itemMakeActive(Storage, null),
  clearStorageConfig: () => itemMakeActive(StorageConfig, null)
};

export default function StorageConfiguration() {
  usePageTitle("Planogram");

  const { id } = useParams();

  const cls = useStyles();

  const storage = useSelector(state =>
    Selectors.selectActiveItem(state, Storage)
  );
  const storageConfig = useSelector(state =>
    Selectors.selectActiveItem(state, StorageConfig)
  );

  const { loadStorage, loadStorageConfig, clearStorage, clearStorageConfig } =
    useDispatchActions(actions);

  useEffect(() => {
    loadStorage(id);
    loadStorageConfig(id);
    return () => {
      clearStorage();
      clearStorageConfig();
    };
  }, [id, loadStorage, loadStorageConfig, clearStorage, clearStorageConfig]);

  const sections = useMemo(
    () =>
      storageConfig?.data.reduce(
        (acc, section) => ({ ...acc, [section.number]: section }),
        {}
      ),
    [storageConfig]
  );

  if (!storageConfig || !storage) return null;

  const breadcrumbsList = () => [
    { title: pages.hardware.title, to: pages.hardware.path },
    { title: "Planogram" },
    { title: storage.value }
  ];

  return (
    <div className={cls.container}>
      <BreadcrumbsComponent renderList={breadcrumbsList} />
      <Planogram sections={sections} storage={storage} />
    </div>
  );
}
