/* eslint react/display-name: "off" */
/* eslint react/prop-types: "off" */
import React from "react";

import { Formatters, mapFiltersFor } from "srx-shared";
import { Cell, Header } from "srx-shared/mui";

import { Actions } from "@comp/Documents/Actions";
import { Document } from "@items/types";

export const DocumentItem = {
  type: Document,
  name: "Document",
  title: "Document"
};

// table
DocumentItem.columns = [
  {
    Header: "From distributor",
    accessor: "distributorName",
    Cell: ({
      row: {
        _original: { distributorId }
      },
      value
    }) => (
      <Cell>
        {Formatters.link(`/distributors/${distributorId}`, value, false)}
      </Cell>
    )
  },
  {
    Header: "Uploaded by",
    accessor: "uploadedBy",
    Cell: ({ value }) => <Cell>{value}</Cell>
  },
  {
    Header: "Uploaded at",
    accessor: "uploadedAt",
    Cell: ({ value }) => <Cell>{Formatters.datetime(value)}</Cell>
  },
  {
    Header: "Type",
    accessor: "type",
    sortable: false,
    width: 120,
    Cell: ({ value }) => <Cell>{value}</Cell>
  },
  {
    Header: "Status",
    accessor: "status",
    width: 120,
    Cell: ({ value }) => <Cell>{Formatters.docstatus(value)}</Cell>
  },
  {
    Header: <Header center>{"Actions"}</Header>,
    maxWidth: 140,
    resizable: false,
    sortable: false,
    Cell: ({ row }) => <Actions row={row._original} />
  }
];

// filters
DocumentItem.filtersConfig = {
  distributorName: {
    name: "Distributor"
  },
  uploadedBy: {
    name: "Uploaded User"
  },
  dateFrom: {
    name: "Date from",
    type: "datetime",
    formatter: value => value.getTime()
  },
  dateTo: {
    name: "Date to",
    type: "datetime",
    formatter: value => value.getTime()
  }
};
DocumentItem.filters = mapFiltersFor(DocumentItem, [
  "distributorName",
  "uploadedBy",
  "dateFrom",
  "dateTo"
]);
