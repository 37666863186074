/* eslint react/display-name: "off" */
/* eslint react/prop-types: "off" */
import React from "react";

import * as V from "yup";

import { mapFiltersFor, mapFormFieldsFor } from "srx-shared";
import { Cell, Header } from "srx-shared/mui";

import { Actions } from "@comp/TypesManagementPages/Actions";
import { LockerType } from "@items/types";
import { getAvoidDotsError } from "@utils";

V.addMethod(V.number, "avoidDots", function () {
  return this.transform(getAvoidDotsError);
});

export const LockerTypeItem = {
  type: LockerType,
  name: "LockerType",
  title: "Locker Type"
};

// table
LockerTypeItem.columns = [
  {
    Header: "Locker Type Name",
    accessor: "name",
    Cell: props => <Cell {...props} />
  },
  {
    Header: "Doors",
    accessor: "doorsQuantity",
    Cell: props => <Cell {...props} />
  },
  {
    Header: "Max Cells w/o Weights",
    accessor: "cellsWithoutWeightQuantity",
    Cell: props => <Cell {...props} />
  },
  {
    Header: "Columns",
    accessor: "columnsQuantity",
    Cell: props => <Cell {...props} />
  },
  {
    Header: <Header center>{"Actions"}</Header>,
    maxWidth: 100,
    resizable: false,
    sortable: false,
    Cell: ({ row }) => <Actions row={row._original} item={LockerTypeItem} />
  }
];

// filters
LockerTypeItem.filtersConfig = {
  name: {
    name: "Locker Type Name"
  },
  doorsQuantity: {
    name: "Doors",
    type: "number",
    isInteger: true
  },
  cellsWithoutWeightQuantity: {
    name: "Max Cells w/o Weights",
    type: "number",
    isInteger: true
  },
  columnsQuantity: {
    name: "Columns",
    type: "number",
    isInteger: true
  }
};
LockerTypeItem.filters = mapFiltersFor(LockerTypeItem, [
  "name",
  "doorsQuantity",
  "cellsWithoutWeightQuantity",
  "columnsQuantity"
]);

// form fields
LockerTypeItem.fieldsConfig = {
  name: {
    label: "Locker Type Name",
    initialValue: "",
    required: true
  },
  doorsQuantity: {
    label: "Doors",
    initialValue: 12,
    required: true
  },
  cellsWithoutWeightQuantity: {
    label: "Max Cells w/o Weights",
    initialValue: "",
    required: true
  },
  columnsQuantity: {
    label: "Columns",
    initialValue: 2,
    required: true
  }
};

LockerTypeItem.schema = V.object({
  name: V.string().label("Locker Type Name").required().max(255).nullable(),
  doorsQuantity: V.number()
    .typeError("Doors must be an integer number")
    .label("Doors")
    .required()
    .avoidDots()
    .min(1)
    .max(24)
    .nullable(),
  cellsWithoutWeightQuantity: V.number()
    .typeError("Max Cells must be an integer number")
    .label("Max Cells w/o Weights")
    .required()
    .avoidDots()
    .min(1)
    .max(99)
    .nullable(),
  columnsQuantity: V.number()
    .typeError("Columns must be an integer number")
    .label("Columns")
    .required()
    .avoidDots()
    .min(1)
    .max(3)
    .nullable()
});

LockerTypeItem.createForm = mapFormFieldsFor(LockerTypeItem, [
  "name",
  "doorsQuantity",
  "cellsWithoutWeightQuantity",
  "columnsQuantity"
]);

LockerTypeItem.editForm = mapFormFieldsFor(LockerTypeItem, [
  "name",
  "doorsQuantity",
  "cellsWithoutWeightQuantity",
  "columnsQuantity"
]);

LockerTypeItem.modalEditForm = true;
