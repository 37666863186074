import React from "react";

import PropTypes from "prop-types";
import NumberFormat from "react-number-format";

export const NumberFormatCustom = ({ inputRef, onChange, name, ...other }) => (
  <NumberFormat
    {...other}
    getInputRef={inputRef}
    onValueChange={values => {
      onChange({
        target: {
          name,
          value: values.value
        }
      });
    }}
    allowNegative={false}
  />
);

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired
};
