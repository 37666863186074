export const styles = theme => ({
  root: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: "100%",
    padding: "0 10px",
    cursor: "pointer"
  },
  overflow: {
    overflowX: "hidden",
    width: "100%",
    textOverflow: "ellipsis"
  },
  mobile: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: "100%",
    cursor: "pointer"
  },
  center: {
    textAlign: "center"
  }
});
