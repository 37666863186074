import React from "react";
import { useState } from "react";

import { Button } from "@material-ui/core";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

import { UnassignDialog } from "./UnassignDialog";

export function UnassignButton(props) {
  const [open, setOpen] = useState(false);

  const handleClick = () => setOpen(true);

  const handleClose = () => setOpen(false);

  return (
    <>
      <Button
        variant="text"
        color="primary"
        startIcon={<HighlightOffIcon />}
        onClick={handleClick}
      >
        Unassign
      </Button>
      {open && <UnassignDialog {...props} onClose={handleClose} />}
    </>
  );
}
