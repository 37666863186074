import { useSelector } from "react-redux";

import { Selectors } from "srx-shared";

import { LockerDoor } from "@items/types";

export function LocationsWarning() {
  const lockerLocations = useSelector(state =>
    Selectors.selectAllItems(state, LockerDoor)
  );

  return lockerLocations.length > 0 ? (
    <div
      style={{
        fontSize: "small",
        color: "brown",
        margin: "-12px 32px 8px 12px"
      }}
    >
      A change in weight{"'"}s status will trigger deletion of VMI{"'"}s,
      associated with the door
    </div>
  ) : null;
}
