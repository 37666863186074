/* eslint react/display-name: "off" */
/* eslint react/prop-types: "off" */
import React from "react";

import * as V from "yup";

import { mapFiltersFor, mapFormFieldsFor } from "srx-shared";
import { Cell, Header } from "srx-shared/mui";

import { Actions } from "@comp/TypesManagementPages/Actions";
import { CustomerType } from "@items/types";

export const CustomerTypeItem = {
  type: CustomerType,
  name: "CustomerType",
  title: "Customer Type"
};

// table
CustomerTypeItem.columns = [
  {
    Header: "Customer Type Number",
    accessor: "id",
    Cell: props => <Cell {...props} />
  },
  {
    Header: "Type Name",
    accessor: "name",
    Cell: props => <Cell {...props} />
  },
  {
    Header: <Header center>{"Actions"}</Header>,
    maxWidth: 100,
    resizable: false,
    sortable: false,
    Cell: ({ row }) => <Actions row={row._original} item={CustomerTypeItem} />
  }
];

// filters
CustomerTypeItem.filtersConfig = {
  name: {
    name: "Type Name"
  }
};
CustomerTypeItem.filters = mapFiltersFor(CustomerTypeItem, ["name"]);

// form fields
CustomerTypeItem.fieldsConfig = {
  name: {
    label: "Type Name",
    initialValue: "",
    required: true,
    size: 12
  }
};

CustomerTypeItem.schema = V.object({
  name: V.string().label("Type Name").required().max(255).nullable()
});

CustomerTypeItem.createForm = mapFormFieldsFor(CustomerTypeItem, ["name"]);

CustomerTypeItem.editForm = mapFormFieldsFor(CustomerTypeItem, ["name"]);

CustomerTypeItem.modalEditForm = true;
