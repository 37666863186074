import React from "react";

import { Grid } from "@material-ui/core";

import {
  tableFor,
  breadcrumbsFor,
  undoActionFor,
  actionsFor
} from "srx-shared";

import { BaseFeeItem } from "./BaseFeeItem";

const BaseFeesTable = tableFor(BaseFeeItem, {
  sortable: false,
  pageble: false
});
const UndoAction = undoActionFor(BaseFeeItem);
const Breadcrumbs = breadcrumbsFor();

const BaseFeesActions = actionsFor(BaseFeeItem, {
  Custom: UndoAction
});

export const BaseFees = () => {
  const renderActions = table => <BaseFeesActions table={table} />;
  return (
    <Grid container justify="center">
      <Grid item style={{ width: 500, height: "100%" }}>
        <Breadcrumbs
          renderList={() => [{ title: "Fees" }, { title: "Base Fees" }]}
        />
        <BaseFeesTable renderActions={renderActions} />
      </Grid>
    </Grid>
  );
};
