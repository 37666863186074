import { all, fork } from "redux-saga/effects";

import { Sagas } from "srx-shared";

import shipToTemplatesSaga from "@sagas/shipToTemplatesSaga";

import attachPolicySaga from "./attachPolicySaga";
import baseFeesSaga from "./baseFeesSaga";
import cameraConfigSaga from "./cameraConfigSaga";
import costSavingSaga from "./costSavingSaga";
import devicesSaga from "./devicesSaga";
import discountsSaga from "./discountsSaga";
import distributorsSaga from "./distributorsSaga";
import documentsSaga from "./documentsSaga";
import freezeVariablesSaga from "./freezeVariablesSaga";
import hardwareSaga from "./hardwareSaga";
import importSaga from "./importSaga";
import lockerConfigSaga from "./lockerConfigSaga";
import managedTypesSaga from "./managedTypesSaga";
import monthlyFeesSaga from "./monthlyFeeSaga";
import shiptoFeesSaga from "./shiptoFeesSaga";
import smartShelvesSaga from "./smartShelvesSaga";
import storageSaga from "./storageSaga";
import upcGtinCatalogSaga from "./upcGtinCatalogSaga";
import usersSaga from "./usersSaga";
import vendingConfigSaga from "./vendingConfigSaga";
import weightHistorySaga from "./weightHistorySaga";

function* rootSaga() {
  yield all([
    fork(Sagas.accountSaga),
    //
    fork(distributorsSaga),
    fork(usersSaga),
    fork(discountsSaga),
    fork(monthlyFeesSaga),
    fork(baseFeesSaga),
    fork(shiptoFeesSaga),
    fork(documentsSaga),
    fork(managedTypesSaga),
    fork(hardwareSaga),
    fork(devicesSaga),
    fork(freezeVariablesSaga),
    fork(costSavingSaga),
    fork(cameraConfigSaga),
    fork(upcGtinCatalogSaga),
    fork(importSaga),
    fork(lockerConfigSaga),
    fork(storageSaga),
    fork(smartShelvesSaga),
    fork(vendingConfigSaga),
    fork(weightHistorySaga),
    fork(attachPolicySaga),
    fork(shipToTemplatesSaga)
  ]);
}

export default rootSaga;
