import * as React from "react";

import { InputAdornment } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import _debounce from "lodash/debounce";
import _isArray from "lodash/isArray";
import _isEmpty from "lodash/isEmpty";
import _isNil from "lodash/isNil";
import _some from "lodash/some";
import PropTypes from "prop-types";
import Select, { components } from "react-select";
import AsyncSelect from "react-select/async";

import { getStyles } from "./SelectDropdownStyles";

class SelectDropdown extends React.Component {
  static spaces = /\s/;
  static SENSITIVITY = { sensitivity: "base" };

  static propTypes = {
    isMulti: PropTypes.bool,
    async: PropTypes.bool,
    setRef: PropTypes.func,
    defaultOptions: PropTypes.array,
    loadOptions: PropTypes.func,
    value: PropTypes.any,
    placeholder: PropTypes.string,
    options: PropTypes.array,
    maxOptions: PropTypes.number,
    selectProps: PropTypes.object,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func
  };

  selectRef = null;

  componentDidUpdate(prevProps) {
    if (
      !prevProps.selectProps.isDisabled &&
      this.props.selectProps.isDisabled
    ) {
      this.props.onBlur && this.props.onBlur();
      this.selectRef.onMenuClose && this.selectRef.onMenuClose();
    }
  }

  setRef = ref => {
    this.selectRef = ref;
    this.props.setRef && this.props.setRef(ref);
  };

  FocusedPlaceholder = (
    <div style={{ display: "flex", alignItems: "center" }}>
      <SearchIcon />
      Enter to search
    </div>
  );

  MoreItemsMessage = (
    <div
      style={{
        padding: 8,
        color: "#707070",
        textAlign: "center",
        fontSize: 13
      }}
    >
      There are more items. Please start typing to narrow down this list
    </div>
  );

  debounceLoadOptions = _debounce((inputValue, callback) => {
    this.props
      .loadOptions(inputValue, callback)
      .then(callback)
      .catch(() => callback());
  }, 200);

  handleChange = value => {
    const { onChange, isMulti } = this.props;
    onChange(value ?? (isMulti ? [] : value));
  };

  valueContainer = ({ children, ...props }) => (
    <components.ValueContainer {...props}>
      {children}
      {props.selectProps.inputSuffix && (
        <InputAdornment style={{ position: "absolute", right: 0 }}>
          {props.selectProps.inputSuffix}
        </InputAdornment>
      )}
    </components.ValueContainer>
  );

  menuList = ({ children, ...props }) => (
    <components.MenuList {...props} className="react-select-menu-list">
      {children.length > this.props.maxOptions ? (
        <>
          {children.slice(0, this.props.maxOptions)}
          {this.MoreItemsMessage}
        </>
      ) : (
        children
      )}
    </components.MenuList>
  );

  placeholder = ({ children, ...props }) => (
    <components.Placeholder {...props}>
      {props.isFocused && !props.hasValue ? this.FocusedPlaceholder : children}
    </components.Placeholder>
  );

  render() {
    const {
      isMulti,
      async,
      defaultOptions,
      value,
      placeholder,
      options,
      selectProps,
      onFocus,
      onBlur
    } = this.props;

    const commonProps = {
      value,
      placeholder,
      styles: getStyles(this.props),
      onChange: this.handleChange,
      onFocus,
      onBlur,
      menuPlacement: "auto",
      menuPosition: "fixed",
      components: {
        ValueContainer: this.valueContainer,
        MenuList: this.menuList,
        Placeholder: this.placeholder,
        ...selectProps?.components
      },
      closeMenuOnScroll: e =>
        Boolean(
          e.target.className &&
            !e.target.className.includes("react-select-menu-list")
        )
    };

    return async ? (
      <AsyncSelect
        ref={this.setRef}
        test-id="async-select-list"
        isMulti={isMulti}
        isValidNewOption={this.isValidNewOption}
        captureMenuScroll={false}
        createOptionPosition="first"
        noOptionsMessage={this.noOptionsMessage}
        {...selectProps}
        defaultOptions={defaultOptions}
        loadOptions={this.debounceLoadOptions}
        {...commonProps}
      />
    ) : (
      <Select
        ref={this.setRef}
        isMulti={isMulti}
        test-id="select-list"
        isValidNewOption={this.isValidNewOption}
        captureMenuScroll={false}
        createOptionPosition="first"
        noOptionsMessage={this.noOptionsMessage}
        {...selectProps}
        options={options}
        {...commonProps}
      />
    );
  }

  noOptionsMessage = obj => {
    const { selectProps } = this.props;

    if (_isNil(selectProps)) {
      return null;
    }

    if (_isEmpty(obj) || _isEmpty(obj.inputValue)) {
      return selectProps.msgNoOptionsAvailable || "No options are available";
    }

    const { inputValue } = obj;

    if (
      selectProps.isCreatable !== true ||
      this.containsValue(inputValue) ||
      this.containsOptions(inputValue)
    ) {
      return (
        selectProps.msgNoOptionsMatchFilter || "No options match the filter"
      );
    }

    return (
      selectProps.msgNoValidValue ||
      "The new value is not valid (contains space)"
    );
  };

  isValidNewOption = inputValue => {
    if (_isEmpty(inputValue)) {
      return false;
    }

    if (this.containsOptions(inputValue)) {
      return false;
    }

    const hasSpaces = SelectDropdown.spaces.test(inputValue);
    return hasSpaces === false;
  };

  containsOptions(inputValue) {
    return _some(this.props.options, option =>
      this.equalsIgnoringCase(inputValue, option.value)
    );
  }

  containsValue(inputValue) {
    const { value } = this.props;

    if (_isArray(value) === false) {
      return false;
    }

    return _some(value, option =>
      this.equalsIgnoringCase(inputValue, option.value)
    );
  }

  equalsIgnoringCase(a, b) {
    return a.localeCompare(b, undefined, SelectDropdown.SENSITIVITY) === 0;
  }
}

export default SelectDropdown;
