import { takeEvery, all } from "redux-saga/effects";

import { Process, isActionLoad, isActionUpdate } from "srx-shared";

import * as RestApi from "@api";
import { Document } from "@items/types";

export function* loadDocumentsSaga() {
  const proc = new Process.Load(Document, RestApi.ListDocuments, {
    pageble: true
  });
  yield proc.start();
  try {
    yield proc.callApi();
    const session = yield proc.session();
    const data = proc.data || {};

    (data.entities || []).forEach(document => {
      document.attachment = {
        id: document.id,
        token: session.idToken
      };
    });
    yield proc.setEntities(data);
    yield proc.setTotalPages();
    yield proc.stop();
  } catch (ex) {
    yield proc.fail(ex);
  }
}

export function* updateDocumentSaga(action) {
  const proc = new Process.Update(Document, RestApi.ChangeDocumentStatus);
  yield proc.start();
  try {
    const { id, data } = action;
    yield proc.callApi(id, data);
    yield proc.stop();
    yield loadDocumentsSaga();
  } catch (ex) {
    yield proc.fail(ex);
  }
}

function* documentsSaga() {
  yield all([
    takeEvery(isActionLoad(Document), loadDocumentsSaga),
    takeEvery(isActionUpdate(Document), updateDocumentSaga)
  ]);
}

export default documentsSaga;
