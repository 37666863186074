import { takeEvery, all } from "redux-saga/effects";

import { Process, isActionLoad, isActionUpdate } from "srx-shared";

import * as RestApi from "@api";
import { Discounts } from "@items/types";

function* loadDiscountsSaga(action) {
  const proc = new Process.Load(Discounts, RestApi.GetDiscounts);
  yield proc.start();
  try {
    yield proc.callApi(action.filteringOptions.id);
    yield proc.setItemData("values", proc.data);
    yield proc.stop();
  } catch (ex) {
    yield proc.fail(ex);
  }
}

function* saveDiscountsSaga(action) {
  const proc = new Process.Update(Discounts, RestApi.UpdateDiscounts);
  yield proc.start();
  try {
    yield proc.callApi(action.id, action.data);
    yield proc.setItemData("values", action.data);
    yield proc.stop();
  } catch (ex) {
    yield proc.fail(ex);
  }
}

function* discountsSaga() {
  yield all([
    takeEvery(isActionLoad(Discounts), loadDiscountsSaga),
    takeEvery(isActionUpdate(Discounts), saveDiscountsSaga)
  ]);
}

export default discountsSaga;
