import React from "react";

import { Grid } from "@material-ui/core";
import * as PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { Selectors } from "srx-shared";
import { Message } from "srx-shared/mui";

import { Storage } from "@items/types";

NoAssignedIotHubWarning.propTypes = {
  form: PropTypes.object
};

export function NoAssignedIotHubWarning(props) {
  const { noWeight } = props.form.values;

  const storage = useSelector(state =>
    Selectors.selectActiveItem(state, Storage)
  );

  if (noWeight === "true" || Boolean(storage.iotHub)) return null;

  return (
    <Grid item xs={12} style={{ padding: "3px 12px" }}>
      <Message variant="warning">
        Can`t assign smartshelf to this storage because there is not IoT Hub
        assigned
      </Message>
    </Grid>
  );
}
