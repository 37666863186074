import * as V from "yup";

import { mapFormFieldsFor } from "srx-shared";

import { ShipToTemplateSettings } from "@items/types";

const ValidationText = props => (
  <div
    style={{
      width: "100%",
      paddingLeft: 10,
      fontSize: "0.75rem",
      color: "#555"
    }}
  >
    Enable delivery validation
  </div>
);

export const PutAwaySettingsItem = {
  type: ShipToTemplateSettings,
  fieldsConfig: {
    validationText: {
      name: "validationText",
      size: 12,
      type: "component",
      Component: ValidationText
    },
    deliveryLocationValidationLabel: {
      name: "deliveryLocationValidationLabel",
      label: "LABEL",
      type: "switch",
      size: 3,
      initialValue: false,
      noHelperText: true
    },
    deliveryLocationValidationVending: {
      name: "deliveryLocationValidationVending",
      label: "VENDING",
      type: "switch",
      size: 3,
      initialValue: false,
      noHelperText: true
    },
    deliveryLocationValidationButton: {
      name: "deliveryLocationValidationButton",
      label: "BUTTON",
      type: "switch",
      size: 3,
      initialValue: false,
      noHelperText: true
    },
    deliveryLocationValidationRfid: {
      name: "deliveryLocationValidationRfid",
      label: "RFID",
      type: "switch",
      size: 3,
      initialValue: false,
      noHelperText: true
    },
    email: {
      name: "email",
      label: "Delivery validation notification email",
      size: 8,
      initialValue: ""
    }
  },
  schema: V.object({
    deliveryLocationValidationLabel: V.boolean(),
    deliveryLocationValidationVending: V.boolean(),
    deliveryLocationValidationButton: V.boolean(),
    deliveryLocationValidationRfid: V.boolean(),
    deliveryLocationValidationStorage: V.boolean(),
    email: V.string().label("Email").max(255).listOfEmails().nullable()
  }),
  fields: [
    "validationText",
    "deliveryLocationValidationLabel",
    "deliveryLocationValidationVending",
    "deliveryLocationValidationButton",
    "deliveryLocationValidationRfid",
    "email"
  ]
};
PutAwaySettingsItem.formConfig = mapFormFieldsFor(PutAwaySettingsItem);
