import { mapFormFieldsFor } from "srx-shared";

import { ShipToTemplateSettings } from "@redux/items/types";

export const PricingViewSettingsItem = {
  type: ShipToTemplateSettings,
  fieldsConfig: {
    enablePricingView: {
      label: "Pricing View",
      type: "switch",
      size: 3
    }
  },
  fields: ["enablePricingView"]
};
PricingViewSettingsItem.formConfig = mapFormFieldsFor(PricingViewSettingsItem);
