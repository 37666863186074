import React from "react";

const SUCCESS = {
  variant: "success"
};

export const NotificationCreators = {
  successCameraConfig: description => ({
    message: (
      <div>
        <p>
          <strong>Successful operation</strong>
        </p>
        <p>{description}</p>
      </div>
    ),
    options: SUCCESS
  })
};
