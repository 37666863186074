import { DckActionCreators, DckSelectors } from "dck-redux";
import { takeLatest, all, call, put, select } from "redux-saga/effects";

import {
  Process,
  isActionLoad,
  isActionSelect,
  isActionUpdate,
  ItemTypeApplication
} from "srx-shared";

import * as RestApi from "@api";
import * as Types from "@items/types";

import { loadDistributorsShelvesSaga } from "./smartShelvesSaga";

export function* loadLockerConfigSaga(action) {
  const proc = new Process.Load(Types.LockerConfig, RestApi.LoadLockerConfig);
  yield proc.start();
  try {
    yield proc.callApi(action.id);
    yield proc.makeActive(null);
    yield proc.set({ id: 1, data: proc.data });
    const doors = proc.data
      .filter(el => !el.noWeights)
      .map(el => ({
        label: el.number,
        value: el.number
      }));
    yield put(
      DckActionCreators.setItemData(ItemTypeApplication, "doors", doors)
    );
    yield proc.makeActive(1);
    yield proc.stop();
  } catch (ex) {
    yield proc.fail(ex);
  }
}

function* getLockerSettingsSaga(action) {
  const proc = new Process.Load(Types.LockerSettings, RestApi.GetHardware);
  yield proc.start();
  try {
    yield proc.makeActive(null);
    yield proc.callApi(action.id);
    yield proc.set(proc.data, action.id);

    const { distributorId } = proc.data || {};
    if (distributorId) {
      yield loadDistributorsShelvesSaga({
        filteringOptions: { distributorId, type: "LOCKER" }
      });
    }

    yield proc.makeActive(action.id);
    yield proc.stop();
  } catch (ex) {
    yield proc.fail(ex);
  }
}

export function* updateLockerConfigSaga(action) {
  const proc = new Process.Update(
    Types.LockerConfig,
    RestApi.UpdateLockerConfig
  );
  yield proc.start();
  try {
    const id = String(action.id);
    const doors = action.data;
    yield proc.callApi(id, doors);
    yield proc.stop();
  } catch (ex) {
    yield proc.fail(ex);
  }
}

function* assignSmartShelfSaga(action) {
  const lockerId = String(action.id);
  const door = action.data;
  const proc = new Process.Update(
    Types.ShelfAssociation,
    RestApi.AssignSmartShelf
  );
  yield proc.start();
  try {
    yield proc.callApi(lockerId, door);
    yield proc.stop();
  } catch (ex) {
    yield proc.fail(ex);
  }
}

function* updateLockerDoorSaga(action) {
  const lockerId = String(action.id);
  const door = action.data;
  const { distributorId } = door;

  const proc = new Process.Update(Types.LockerDoor, RestApi.UpdateLockerConfig);
  yield proc.start();
  try {
    yield proc.callApi(lockerId, [door]);

    if (!door.noWeight) {
      yield assignSmartShelfSaga(action);
    }

    yield loadLockerConfigSaga(action);

    yield loadDistributorsShelvesSaga({
      filteringOptions: { distributorId, type: "LOCKER" }
    });

    yield proc.stop();
  } catch (ex) {
    yield proc.fail(ex);
  }
}

function* loadStorageLocationsSaga(section) {
  const { hardware, number } = section;
  const proc = new Process.Load(Types.LockerDoor, RestApi.GetStorageLocations);
  yield proc.start();
  try {
    yield proc.set([]);
    yield proc.callApi(hardware.id, number);
    yield proc.set(proc.data);
    yield proc.stop();
  } catch (ex) {
    yield proc.fail(ex);
  }
}

const getShelves = state =>
  DckSelectors.getItemData(state, ItemTypeApplication, "distributorShelves");

function* loadAvailableDistributorShelvesSaga(action) {
  const { door } = action.filteringOptions || {};
  const { smartShelfHardware: assignedShelf } = door;

  const proc = new Process.Load(Types.LockerDoor);
  yield proc.start();
  try {
    const shelves = yield select(getShelves);

    const availableShelves = (shelves || [])
      .filter(el => !el.doorConfiguration)
      .map(el => ({
        value: el.id,
        label: el.serialNumber,
        address: el.address
      }));

    if (assignedShelf) {
      availableShelves.push({
        value: assignedShelf.id,
        label: assignedShelf.serialNumber,
        address: assignedShelf.address
      });
    }
    yield put(
      DckActionCreators.setItemData(
        ItemTypeApplication,
        "availableDistributorShelves",
        availableShelves
      )
    );
    yield proc.stop();
    yield call(loadStorageLocationsSaga, door);
  } catch (ex) {
    yield proc.fail(ex);
  }
}

function* lockerConfigSaga() {
  yield all([
    takeLatest(isActionSelect(Types.LockerConfig), loadLockerConfigSaga),
    takeLatest(isActionSelect(Types.LockerSettings), getLockerSettingsSaga),
    takeLatest(isActionUpdate(Types.LockerDoor), updateLockerDoorSaga),
    takeLatest(isActionUpdate(Types.LockerConfig), updateLockerConfigSaga),
    takeLatest(
      isActionLoad(Types.LockerDoor),
      loadAvailableDistributorShelvesSaga
    )
  ]);
}

export default lockerConfigSaga;
