import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

import { ActionButton } from "../../ActionButton";
import { styles } from "./styles";

const UploadButtonComponent = ({
  onImport,
  id,
  importDisabled,
  labelForImportButton = "",
  processReset,
  noIcon = false,
  fileTypes,
  style = {},
  classes
}) => {
  return (
    <div style={style}>
      <input
        id={id || "file-upload"}
        data-testid="import-button"
        className={classes.input}
        type="file"
        title=" " // trick to hide browser's default tooltip "No file choosen"
        disabled={importDisabled}
        accept={fileTypes}
        onChange={event => {
          processReset && processReset();
          if (event.target && event.target.files[0]) {
            onImport(event.target.files[0]);
            event.target.value = null;
          }
        }}
      />
      <label htmlFor={id || "file-upload"} style={{ marginBottom: 0 }}>
        <ActionButton
          variant="import"
          title={labelForImportButton}
          disabled={importDisabled}
          noIcon={noIcon}
          component="span"
        />
      </label>
    </div>
  );
};

UploadButtonComponent.propTypes = {
  onImport: PropTypes.func,
  noIcon: PropTypes.bool,
  id: PropTypes.any,
  importDisabled: PropTypes.bool,
  labelForImportButton: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ]),
  fileTypes: PropTypes.string,
  processReset: PropTypes.func,
  classes: PropTypes.object.isRequired,
  style: PropTypes.object
};

export const UploadButton = withStyles(styles)(UploadButtonComponent);
