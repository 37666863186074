export const styles = theme => ({
  formControl: {
    width: "100%"
  },
  label: {
    paddingTop: 8,
    fontSize: "0.75rem",
    color: "#555"
  },
  horizontalGroup: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "row",
    justifyContent: "space-between"
  },
  verticalGroup: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    justifyContent: "space-between"
  },
  radio: {
    marginTop: 4,
    marginLeft: 8,
    padding: 4
  },
  checkbox: {
    padding: 6,
    marginLeft: 6
  },
  helperText: {
    minHeight: 18,
    fontSize: "0.75rem",
    color: theme.palette.primary.light
  },
  option: {
    paddingRight: 4
  },
  message: {
    fontSize: 13,
    color: "#707070"
  },
  emptyIcon: {
    marginRight: 8
  }
});
