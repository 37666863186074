import React from "react";

import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";
import PropTypes from "prop-types";
import { compose } from "redux";

import { styles } from "./styles";

export default class PageButtonComponent extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    active: PropTypes.bool,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    page: PropTypes.any
  };
  render() {
    const { classes, active, disabled, onClick, page } = this.props;
    return (
      <Button
        classes={{ root: classes.pageButton }}
        onClick={onClick}
        size="small"
        disabled={disabled || active}
      >
        {active ? <b>{page}</b> : page}
      </Button>
    );
  }
}

export const PageButton = compose(
  withStyles(styles),
  withWidth()
)(PageButtonComponent);
