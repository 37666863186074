import * as V from "yup";

import { mapFormFieldsFor } from "srx-shared";

import { isLockerV3 } from "@comp/Hardware/helpers";
import { DoorConfig } from "@items/types";

import { LocationsWarning } from "./LocationsWarning";

// hidden fields: doorSerialNumbers, shelfSerialNumbers, initialDoorSerial

V.addMethod(V.string, "uniqueDoorSerialNumber", function () {
  return this.test(
    "doorSerialNumbers",
    "Value is already in use",
    function (value) {
      return (
        value === "" ||
        value === null ||
        value === this.parent.initialDoorSerial ||
        !(this.parent.doorSerialNumbers || []).includes(value)
      );
    }
  );
});

export const DoorConfigItem = {
  type: DoorConfig,
  name: "DoorConfig",
  title: "Door Configuration"
};

// form fields
DoorConfigItem.fieldsConfig = {
  locationsWarning: {
    name: "locationsWarning",
    type: "component",
    Component: LocationsWarning,
    size: 12
  },
  noWeight: {
    name: "noWeight",
    type: "radio",
    options: [
      { label: "with weights", value: "false" },
      { label: "without weights", value: "true" }
    ],
    size: 12,
    noHelperText: true,
    checkDisabled: isLockerV3
  },
  doorSerial: {
    name: "doorSerial",
    label: "Door Serial Number",
    size: 12
  },
  shelfSerial: {
    name: "shelfSerial",
    label: "Smart Shelf Serial Number",
    type: "select",
    dict: "availableDistributorShelves",
    size: 12,
    checkDisabled: ({ values: { noWeight } }) => noWeight === "true",
    SelectProps: {
      isClearable: true
    }
  }
};

DoorConfigItem.schema = V.object({
  doorSerial: V.string()
    .label("Door Serial Number")
    .uniqueDoorSerialNumber()
    .max(255)
    .nullable(),
  shelfSerial: V.number().nullable()
});

DoorConfigItem.generalForm = mapFormFieldsFor(DoorConfigItem, [
  "noWeight",
  "locationsWarning",
  "doorSerial",
  "shelfSerial"
]);
