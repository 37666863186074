/* eslint react/display-name: "off" */
/* eslint react/prop-types: "off" */
/* eslint no-template-curly-in-string: "off" */
import * as V from "yup";

import { mapFormFieldsFor } from "srx-shared";

import { Range } from "@items/types";
import { getAvoidDotsError } from "@utils";

export const RangeItem = {
  type: Range
};

RangeItem.fieldsConfig = {
  rangeFrom: {
    label: "Min Number of Cameras",
    initialValue: "",
    size: 6
  },
  rangeTo: {
    label: "Max Number of Cameras",
    initialValue: "",
    size: 6
  }
};

V.addMethod(V.number, "avoidDots", function () {
  return this.transform(getAvoidDotsError);
});

RangeItem.schema = V.object({
  rangeFrom: V.number()
    .label("Min Number of Cameras")
    .typeError("${label} must be a number")
    .required()
    .min(0)
    .lessThan(10000000)
    .avoidDots()
    .test({
      name: "Comparison with Max",
      message: "Min Number of Cameras must be less than Max",
      test: function (value) {
        return value < this.parent.rangeTo;
      }
    }),
  rangeTo: V.number()
    .label("Max Number of Cameras")
    .typeError("${label} must be a number")
    .required()
    .min(0)
    .lessThan(10000000)
    .avoidDots()
    .test({
      name: "Comparison with Min",
      message: "Max Number of Cameras must be more than Min",
      test: function (value) {
        return value > this.parent.rangeFrom;
      }
    })
});

RangeItem.fieldsForForm = ["rangeFrom", "rangeTo"];

RangeItem.generalForm = mapFormFieldsFor(RangeItem, RangeItem.fieldsForForm);
