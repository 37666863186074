export const Distributor = "Distributor";
export const User = "User";
export const BaseFee = "BaseFee";
export const TriggersMonthlyFee = "TriggersMonthlyFee";
export const CamerasMonthlyFee = "CamerasMonthlyFee";
export const ShiptoFee = "ShiptoFee";
export const Discounts = "Discounts";
export const FreezeVariables = "FreezeVariables";
export const Document = "Document";
export const ItemsCount = "ItemsCount";
export const CustomerType = "CustomerType";
export const MarketType = "MarketType";
export const BinType = "BinType";
export const Locker = "Locker";
export const AvailableLockerDoor = "AvailableLockerDoor";
export const LockerType = "LockerType";
export const LockerConfig = "LockerConfig";
export const LockerSettings = "LockerSettings";
export const LockerDoor = "LockerDoor";
export const VendingConfig = "VendingConfig";
export const Hardware = "Hardware";
export const CostSavings = "CostSavings";
export const SrxVmiOperationalCosts = "SrxVmiOperationalCosts";
export const TraditionalVmiOperationalCosts = "TraditionalVmiOperationalCosts";
export const DistributorInventory = "DistributorInventory";
export const SuggestedMinMaxFormula = "SuggestedMinMaxFormula";
export const OperationalCosts = "OperationalCosts";
export const SupplyForce = "SupplyForce";
export const IoTHub = "IoTHub";
export const Camera = "Camera";
export const IoTHubAccessKey = "IoTHubAccessKey";
export const IoTHubImage = "IoTHubImage";
export const UpcGtinCatalog = "UpcGtinCatalog";
export const Range = "Range";
export const SmartShelf = "SmartShelf";
export const ShelfAssociation = "ShelfAssociation";
export const DistributorShelves = "DistributorShelves";
export const DoorConfig = "DoorConfig";
export const HardwareStates = "HardwareStates";
export const Storage = "Storage";
export const StorageConfig = "StorageConfig";
export const StorageSection = "StorageSection";
export const SectionConfig = "SectionConfig";
export const SmartShelfDetails = "SmartShelfDetails";
export const WeightHistory = "WeightHistory";
export const DistributorsWithStorages = "DistributorsWithStorages";
export const AttachPolicy = "AttachPolicy";
export const BillingERP = "BillingERP";
export const ShipToTemplate = "ShipToTemplate";
export const ShipToTemplateSettings = "ShipToTemplateSettings";
export const VmiListSettingsStructure = "VmiListSettingsStructure";
export const RlSubmitStatus = "RlSubmitStatus";
