import React, { useState, useEffect } from "react";

import MoreIcon from "@material-ui/icons/MoreHorizOutlined";
import cn from "classnames";
import PropTypes from "prop-types";

import { IconButton } from "../../IconButton";

// TdStickyFirst

TdStickyFirst.propTypes = {
  className: PropTypes.string,
  sorted: PropTypes.bool,
  selectable: PropTypes.bool,
  stickyFirstWidth: PropTypes.number
};

function TdStickyFirst(props) {
  const { className, sorted, selectable, children, stickyFirstWidth, ...rest } =
    props;

  return (
    <div
      className={cn(
        "rt-td",
        className,
        "td-sticky-first",
        sorted && "td-sorted",
        selectable && "td-sticky-first-selectable"
      )}
      role="gridcell"
      {...rest}
    >
      <div className="td-container">{children}</div>
    </div>
  );
}

// TdStickyLast

TdStickyLast.propTypes = {
  className: PropTypes.string
};

function TdStickyLast(props) {
  const { className, children, ...rest } = props;

  return (
    <div
      className={cn("rt-td", className, "td-sticky-last")}
      role="gridcell"
      {...rest}
    >
      <div className="td-container">{children}</div>
    </div>
  );
}

// TdStickyLastFloat

TdStickyLastFloat.propTypes = {
  className: PropTypes.string,
  stickyLastWidth: PropTypes.number,
  loading: PropTypes.bool
};

function TdStickyLastFloat(props) {
  const { className, children, loading, stickyLastWidth, ...rest } = props;

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    loading && setVisible(false);
  }, [loading]);

  return (
    <div className="td-sticky-last-more">
      <div
        className="td-sticky-last-float-container"
        style={{
          width: stickyLastWidth,
          visibility: visible ? "visible" : "hidden"
        }}
      >
        {children}
      </div>
      <div
        className={cn(
          className,
          "rt-td td-container",
          !visible && "td-sticky-last-more-shadow"
        )}
        {...rest}
      >
        <IconButton
          style={{
            marginLeft: 4,
            padding: 4,
            color: visible ? "#bbb" : "#888"
          }}
          tip={visible ? "" : "Actions"}
          tipPlacement="left"
          icon={<MoreIcon />}
          data-testid="sticky-last-more"
          onClick={() => setVisible(!visible)}
        />
      </div>
    </div>
  );
}

// TdDesktop

TdDesktop.propTypes = {
  className: PropTypes.string,
  loading: PropTypes.bool,
  selectable: PropTypes.bool,
  width: PropTypes.string,
  stickyFirstWidth: PropTypes.number,
  stickyLastWidth: PropTypes.number,
  stickyLastFloat: PropTypes.bool,
  stickyLastDisableForScreenSizes: PropTypes.arrayOf(PropTypes.string),
  columns: PropTypes.array,
  column: PropTypes.string,
  sorting: PropTypes.array
};

function TdDesktop(props) {
  const {
    className,
    width,
    sorting,
    children,
    column,
    columns,
    loading,
    selectable,
    stickyFirstWidth,
    stickyLastWidth,
    stickyLastFloat,
    stickyLastDisableForScreenSizes = [],
    ...rest
  } = props;
  //
  const [position, columnName] = column.split("-");
  const isFirstColumn = Number(position) === (selectable ? 1 : 0);
  const isLastColumn = Number(position) === columns.length - 1;
  const allowStickyLast =
    isLastColumn && !stickyLastDisableForScreenSizes.includes(width);

  const sorted = (sorting || []).map(col => col.name).includes(columnName);

  if (isFirstColumn && stickyFirstWidth > 0) {
    return (
      <TdStickyFirst
        className={className}
        sorted={sorted}
        selectable={selectable}
        stickyFirstWidth={stickyFirstWidth}
        {...rest}
      >
        {children}
      </TdStickyFirst>
    );
  }

  if (allowStickyLast && stickyLastWidth > 0) {
    return stickyLastFloat ? (
      <TdStickyLastFloat
        className={className}
        loading={loading}
        stickyLastWidth={stickyLastWidth}
        {...rest}
      >
        {children}
      </TdStickyLastFloat>
    ) : (
      <TdStickyLast className={className} {...rest}>
        {children}
      </TdStickyLast>
    );
  }

  return (
    <div
      className={cn("rt-td", className, sorted && "td-sorted")}
      role="gridcell"
      {...rest}
    >
      <div className="td-container">{children}</div>
    </div>
  );
}

// TdMobile

TdMobile.propTypes = {
  className: PropTypes.string,
  loading: PropTypes.bool,
  selectable: PropTypes.bool,
  width: PropTypes.string,
  stickyFirstWidth: PropTypes.number,
  stickyLastWidth: PropTypes.number,
  stickyLastFloat: PropTypes.bool,
  stickyLastDisableForScreenSizes: PropTypes.arrayOf(PropTypes.string),
  columns: PropTypes.array,
  column: PropTypes.string,
  style: PropTypes.object
};

function TdMobile(props) {
  const {
    className,
    children,
    column,
    columns = [],
    style,
    stickyFirstWidth,
    stickyLastWidth,
    stickyLastFloat,
    stickyLastDisableForScreenSizes,
    loading,
    selectable,
    ...rest
  } = props;
  //
  const columnName = column.split("-")[1];
  const currentCol = columns.find(col => col.accessor === columnName);

  return (
    <div
      className={cn("rt-td", "rt-td-mobile", className)}
      role="gridcell"
      style={{ ...style, maxWidth: "unset" }}
      {...rest}
    >
      <div className="td-container">
        {currentCol && currentCol.Header && (
          <div
            style={{
              minWidth: "120px",
              width: "20%",
              overflow: "hidden",
              textOverflow: "ellipsis"
            }}
          >
            <b>{currentCol.Header}</b>
          </div>
        )}
        {children}
      </div>
    </div>
  );
}

// TdComponent

TdComponent.displayName = "Td";

TdComponent.propTypes = {
  mobile: PropTypes.bool
};

export function TdComponent(props) {
  const { children, mobile, ...rest } = props;
  const Td = mobile ? TdMobile : TdDesktop;

  return <Td {...rest}>{children}</Td>;
}
