import { all, takeEvery } from "redux-saga/effects";

import {
  Process,
  isActionLoad,
  isActionAdd,
  isActionUpdate,
  isActionDelete
} from "srx-shared";

import { ActionTypes } from "@actions";
import * as RestApi from "@api";
import { UpcGtinCatalog } from "@items/types";

function* loadUpcGtinCatalogItemsSaga() {
  const proc = new Process.Load(
    UpcGtinCatalog,
    RestApi.ListUpcGtinCatalogItems,
    { pageble: true }
  );
  yield proc.start();
  try {
    yield proc.callApi();
    yield proc.setEntities();
    yield proc.setTotalPages();
    yield proc.stop();
  } catch (ex) {
    yield proc.fail(ex);
  }
}

function* addUpcGtinCatalogItemSaga(action) {
  const proc = new Process.Add(UpcGtinCatalog, RestApi.AddUpcGtinCatalogItem);
  yield proc.start();
  try {
    yield proc.callApi(action.data);
    yield proc.stop();
    yield loadUpcGtinCatalogItemsSaga();
  } catch (ex) {
    yield proc.fail(ex);
  }
}

function* updateUpcGtinCatalogItemSaga(action) {
  const proc = new Process.Update(
    UpcGtinCatalog,
    RestApi.UpdateUpcGtinCatalogItem
  );
  yield proc.start();
  try {
    const { id, data } = action;
    yield proc.callApi(id, data);
    yield proc.stop();
    yield loadUpcGtinCatalogItemsSaga();
  } catch (ex) {
    yield proc.fail(ex);
  }
}

function* deleteUpcGtinCatalogItemSaga(action) {
  const proc = new Process.Delete(
    UpcGtinCatalog,
    RestApi.RemoveUpcGtinCatalogItem
  );
  yield proc.start();
  try {
    yield proc.callApi(action.id);
    yield proc.stop();
    yield loadUpcGtinCatalogItemsSaga();
  } catch (ex) {
    yield proc.fail(ex);
  }
}

function* upcGtinCatalogSaga() {
  yield all([
    takeEvery(isActionLoad(UpcGtinCatalog), loadUpcGtinCatalogItemsSaga),
    takeEvery(isActionAdd(UpcGtinCatalog), addUpcGtinCatalogItemSaga),
    takeEvery(isActionUpdate(UpcGtinCatalog), updateUpcGtinCatalogItemSaga),
    takeEvery(isActionDelete(UpcGtinCatalog), deleteUpcGtinCatalogItemSaga),
    takeEvery(
      action =>
        action.type === ActionTypes.IMPORT_COMPLETED &&
        action.itemType === UpcGtinCatalog,
      loadUpcGtinCatalogItemsSaga
    )
  ]);
}

export default upcGtinCatalogSaga;
