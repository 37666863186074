import { useState, useEffect, useMemo } from "react";

import { DckActionCreators } from "dck-redux";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import {
  deepMerge,
  Selectors,
  useBreadcrumbs,
  useDispatchActions,
  usePageTitle,
  useSuccessProcessCallback
} from "srx-shared";
import { SettingsContainer } from "srx-shared/mui";

import { ShipToTemplateSettings } from "@items/types";
import { pages } from "@routes/pages";

import { AdjustMovingSuggestedMinMaxSettings } from "./Cards/AdjustMovingSuggestedMinMaxSettings";
import { ShipToSettingsItem } from "./ShipToSettingsItem";
import { ShipToTemplateNameForm } from "./ShipToTemplateNameForm";
import { defaultSettings } from "./settingsStub";

const settingNames = {
  autoSubmitSettings: "Auto Submit Schedule",
  replenishmentListSettings: "Reorder List Settings",
  multiLocationsSettings: "Multi-Locations",
  checkoutSoftwareSettings: "Reorder Controls",
  putAwaySettings: "Put away settings",
  serialNumberSettings: "Lot & Serialization Settings",
  pricingSourceSettings: "Pricing information sources",
  allowSettings: "QR Code Kit & Checkout Portal",
  pricingViewSettings: "Pricing View",
  criticalMinAlertSettings: "Stock Out & Critical Min Alerts",
  rfidAutoCheckoutCartSettings: "Automated checkout cart for the RFID tags",
  rfidTransitSettings: "RFID settings",
  orderCloseSettings: "Order Close Logic",
  rlSubmitIntegrationSettings: "Reorder List Submit Integration",
  cribCrawlSettings: "CribCrawl Settings",
  vmiListSettings: "VMI List",
  vmiIntegrationSetting: "VMI List Integration"
};

const actions = {
  addSettings: data => DckActionCreators.itemAdd(ShipToTemplateSettings, data),
  updateSettings: (id, data) =>
    DckActionCreators.itemSave(ShipToTemplateSettings, id, data),
  setSettingsToState: settings =>
    DckActionCreators.setItemData(ShipToTemplateSettings, "settings", settings),
  clearSettings: () =>
    DckActionCreators.setItemData(ShipToTemplateSettings, "settings", null),
  getSettings: id => DckActionCreators.itemSelect(ShipToTemplateSettings, id)
};

export default function ShipToTemplate() {
  const {
    addSettings,
    setSettingsToState,
    getSettings,
    clearSettings,
    updateSettings
  } = useDispatchActions(actions);

  const { push } = useHistory();

  const { id } = useParams();

  usePageTitle(pages.shipToTemplates.title);

  const breadcrumbList = useMemo(
    () => [
      { title: "ShipTo Templates", to: pages.shipToTemplates.path },
      { title: `${id ? "Edit" : "Create"} ShipTo Template` }
    ],
    [id]
  );

  useBreadcrumbs(breadcrumbList);

  const [invalidSettings, setInvalidSettings] = useState({});

  const settings = useSelector(state =>
    Selectors.getItemData(state, ShipToTemplateSettings, "settings")
  );

  const [currentSettings, setCurrentSettings] = useState(settings);

  useEffect(() => {
    if (!id) {
      setSettingsToState(defaultSettings);
    } else {
      getSettings(id);
    }

    return () => {
      clearSettings();
    };
  }, [setSettingsToState, getSettings, clearSettings, id]);

  useEffect(() => {
    setCurrentSettings(settings);
  }, [settings]);

  useSuccessProcessCallback(ShipToTemplateSettings, "add", () => {
    push(pages.shipToTemplates.path);
  });

  useSuccessProcessCallback(ShipToTemplateSettings, "update", () => {
    push(pages.shipToTemplates.path);
  });

  const handleSubmit = data => {
    const nextData = { ...currentSettings, ...data };
    if (id) {
      updateSettings(id, nextData);
    } else {
      addSettings(nextData);
    }
  };

  const handleChange = (section, data) => {
    if (!data.isValid) {
      setInvalidSettings({
        ...invalidSettings,
        [section]: settingNames[section]
      });
    } else {
      const { [section]: validSection, ...rest } = invalidSettings;
      setInvalidSettings(rest);
    }

    const settings = data.values;
    const useDefault = data.useDefaults;

    const nextSettings = deepMerge({}, currentSettings);

    nextSettings[section] =
      useDefault === null
        ? { settings }
        : { ...nextSettings[section], settings, useDefault };

    setCurrentSettings(nextSettings);
  };

  if (!settings) return null;

  const initialValues = {
    templateName: settings.templateName,
    status: settings.status
  };

  return (
    <>
      <ShipToTemplateNameForm
        invalidSettings={invalidSettings}
        onSubmit={handleSubmit}
        initialValues={initialValues}
      />
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          marginBottom: 50
        }}
      >
        <SettingsContainer
          item={ShipToSettingsItem}
          settings={settings}
          onChange={handleChange}
        >
          <AdjustMovingSuggestedMinMaxSettings
            title="Inventory Status"
            isSwitch
          />
          <AdjustMovingSuggestedMinMaxSettings title="Suggested Min & Max Formula" />
        </SettingsContainer>
      </div>
    </>
  );
}
