import { Grid, TextField } from "@material-ui/core";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { Selectors, Formatters } from "srx-shared";

import { BillingERP } from "@items/types";

import { useStyles } from "./styles";

CustomDateField.propTypes = {
  field: PropTypes.object,
  fieldConfig: PropTypes.object
};

export function CustomDateField(props) {
  const { fieldConfig, field } = props;

  const cls = useStyles();

  const error = useSelector(state =>
    Selectors.getProcErrorMessage(state, BillingERP, "update")
  );

  const value = field.value ? Formatters.datetime(field.value) : "";

  return (
    <Grid item sm={fieldConfig.size || 6} xs={12}>
      <TextField
        value={value}
        label={fieldConfig.label}
        fullWidth
        error={Boolean(error)}
        InputLabelProps={{
          classes: {
            root: cls.label
          }
        }}
        InputProps={{
          classes: {
            root: cls.input
          }
        }}
        inputProps={{
          disabled: true
        }}
      />
    </Grid>
  );
}
