import * as V from "yup";

import { mapFormFieldsFor, toBoolean } from "srx-shared";
import { DAYS, TIME_EVERY_OPTIONS } from "srx-shared/dictionaries";

import { ShipToTemplateSettings } from "@items/types";

import { Timezone } from "../../Common/Timezone";
import { AutoSubmitImmediateMessage } from "./AutoSubmitImmediateMessage";
import { ImmediatelyMovedStatusMessage } from "./ImmediatelyMovedStatusMessage";

const DEFAULT_WEEK_DAYS = [
  "MONDAY",
  "TUESDAY",
  "WEDNESDAY",
  "THURSDAY",
  "FRIDAY"
];

const isSubmitImmediatelyTurnOn = form =>
  toBoolean(form.values.submitImmediately);

const handleChangeSubmitImmediately = (value, { values, setValues }) => {
  const nextValues = {
    ...values,
    submitImmediately: value,
    freqDays: DEFAULT_WEEK_DAYS
  };
  setValues(nextValues, true);
};

export const AutoSubmitScheduleSettingsItem = {
  type: ShipToTemplateSettings,
  fieldsConfig: {
    autoSubmit: {
      type: "radio",
      initialValue: true,
      options: [
        { label: "Auto-Submit All Locations", value: "true" },
        { label: "Customer Per SKU", value: "false" },
        { label: "Customer Submit All Locations", disabled: true }
      ],
      size: 12,
      noHelperText: true
    },
    autoSubmitAsOrder: {
      label: "Auto submit Order Processing",
      type: "radio",
      size: 12,
      options: [
        { label: "Auto-submit as ORDER", value: "true" },
        { label: "Auto-submit as QUOTE", value: "false" }
      ],
      noHelperText: true
    },
    immediatelyMovedStatusMessage: {
      Component: ImmediatelyMovedStatusMessage
    },
    submitImmediately: {
      name: "submitImmediately",
      type: "radio",
      size: 12,
      options: [{ label: "Submit Immediately", value: "true" }],
      noHelperText: true,
      onChange: handleChangeSubmitImmediately
    },
    autoSubmitImmediateMessage: {
      Component: AutoSubmitImmediateMessage
    },
    submitBySchedule: {
      name: "submitImmediately",
      type: "radio",
      size: 4,
      options: [{ label: "Submit by Schedule", value: "false" }],
      noHelperText: true
    },
    time: {
      label: "Time",
      size: 4,
      type: "select",
      options: TIME_EVERY_OPTIONS,
      initialValue: "19:00",
      checkDisabled: isSubmitImmediatelyTurnOn
    },
    timezone: {
      Component: Timezone
    },
    freqDays: {
      label: "Week days",
      size: 12,
      type: "checkGroup",
      options: DAYS,
      initialValue: DEFAULT_WEEK_DAYS,
      checkDisabled: isSubmitImmediatelyTurnOn
    }
  },
  schema: V.object({
    freqDays: V.array()
      .typeError("Please select week day(s)")
      .label("Week days")
      .when("submitImmediately", (value, schema) =>
        String(value) === "true" ? schema : schema.required()
      )
      .nullable()
  }),
  fields: [
    "autoSubmit",
    "autoSubmitAsOrder",
    "submitImmediately",
    "immediatelyMovedStatusMessage",
    "autoSubmitImmediateMessage",
    "submitBySchedule",
    "time",
    "timezone",
    "freqDays"
  ]
};

AutoSubmitScheduleSettingsItem.formConfig = mapFormFieldsFor(
  AutoSubmitScheduleSettingsItem
);
