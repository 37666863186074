export const styles = {
  root: {
    position: "relative"
  },
  label: {
    fontSize: 10,
    color: "#555",
    position: "absolute",
    top: 15
  },
  field: {
    display: "flex",
    alignItems: "flex-end"
  },
  divider: {
    margin: "0px -6px",
    position: "relative",
    bottom: 15
  },
  startDateInput: {
    fontSize: 14,
    paddingRight: 10,
    "& input": {
      textAlign: "center"
    }
  },
  endDateInput: {
    fontSize: 14,
    paddingLeft: 10,
    "& input": {
      textAlign: "center"
    }
  },
  clearButton: {
    padding: 0,
    position: "relative",
    bottom: 12
  },
  clearIcon: {
    height: 20,
    width: 20
  }
};
