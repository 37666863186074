import { useState } from "react";

import {
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Tooltip
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import SortIcon from "@material-ui/icons/Sort";
import PropTypes from "prop-types";
import { ReactTableDefaults } from "react-table";

const { ThComponent: DefaultThComponent } = ReactTableDefaults;

const useStyles = makeStyles({
  label: {
    color: "#707070",
    fontSize: 16,
    padding: "4px 16px"
  },
  icon: {
    marginLeft: 12
  }
});

ThWithMultiSortComponent.propTypes = {
  columnProps: PropTypes.object
};

export function ThWithMultiSortComponent(props) {
  const { columnProps, children, ...rest } = props;
  const { id, sortingOptions, sortedBy, setSortedBy } = columnProps;

  const cls = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = e => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleClose = e => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const handleSelect = (e, value) => {
    e.stopPropagation();
    setAnchorEl(null);
    setSortedBy(id, value);
  };

  const option = sortingOptions.find(el => el.value === sortedBy);

  const title = option ? `Sorted by ${option.label}` : "";

  return (
    <DefaultThComponent {...rest}>
      {children}
      <Tooltip title={title}>
        <IconButton size="small" onClick={handleClick}>
          <SortIcon />
        </IconButton>
      </Tooltip>
      {anchorEl && (
        <Menu id="sorting-menu" anchorEl={anchorEl} open onClose={handleClose}>
          <div className={cls.label}>Sorted by</div>
          {sortingOptions.map(el => (
            <MenuItem
              key={el.value}
              selected={sortedBy === el.value}
              onClick={e => handleSelect(e, el.value)}
            >
              {el.label}
              {sortedBy === el.value && <CheckIcon className={cls.icon} />}
            </MenuItem>
          ))}
        </Menu>
      )}
    </DefaultThComponent>
  );
}
