import React from "react";

import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";

const buttons = {
  add: { type: "submit", label: "Add", color: "secondary" },
  save: { type: "submit", label: "Save", color: "secondary" },
  next: { type: "submit", label: "Next", color: "secondary" },
  submit: { type: "submit", label: "Submit", color: "secondary" },
  confirm: { type: "submit", label: "Confirm", color: "secondary" },
  validate: { label: "Validate", color: "secondary" },
  reset: { type: "reset", label: "Reset", color: "default" },
  cancel: { type: "reset", label: "Cancel", color: "default" }
};

export const FormButton = props => {
  const { variant, ...restProps } = props;
  const buttonProps = buttons[variant];
  const buttonExtend = {
    variant: "contained",
    style: { width: 100, marginLeft: 16 }
  };

  return (
    <Button {...buttonProps} {...buttonExtend} {...restProps}>
      {restProps.label || buttonProps.label}
    </Button>
  );
};

FormButton.propTypes = {
  variant: PropTypes.string
};
FormButton.defaultProps = {
  variant: "save"
};

export default FormButton;
