export const styles = theme => ({
  container: {
    display: "flex",
    alignItems: "center",
    color: "#888",
    fontSize: "10pt",
    cursor: "pointer"
  },
  statusIcon: {
    fontSize: "22px",
    margin: "0 4px 0 8px"
  }
});
