export const styles = theme => ({
  toolbar: {
    display: "flex",
    justifyContent: "center",
    position: "absolute",
    height: 50,
    width: 150,
    zIndex: 10,
    right: "30%",
    top: 85
  }
});
