import React from "react";

import {
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio
} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import cn from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";

import { Selectors } from "../../..";
import { ItemTypeApplication } from "../../../config";
import { styles } from "./styles";

function OptionsComponent(props) {
  const {
    classes,
    style,
    name,
    value,
    label = null,
    options,
    disabled,
    error = false,
    helperText = "",
    noHelperText = false,
    onChange
  } = props;

  const handleChange = (e, value) => onChange && onChange(value);

  return (
    <div className={classes.root} style={style}>
      <FormControl component="fieldset" className={classes.formControl}>
        {label && (
          <div className={cn(classes.groupLabel, disabled && classes.disabled)}>
            {label}
          </div>
        )}
        <RadioGroup
          aria-label={label}
          data-testid="radio-group"
          name={name}
          className={classes.group}
          value={String(value)}
          onChange={handleChange}
        >
          {(options || []).map((option, index) => (
            <FormControlLabel
              key={index}
              classes={{ root: classes.option, label: classes.label }}
              value={option.value}
              control={
                <Radio color="primary" size="small" className={classes.radio} />
              }
              label={option.label}
              disabled={Boolean(disabled || option.disabled)}
            />
          ))}
        </RadioGroup>
      </FormControl>
      {!noHelperText && (
        <div
          className={classes.helperText}
          style={{ color: error && "#F44336" }}
        >
          {helperText}
        </div>
      )}
    </div>
  );
}
OptionsComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  style: PropTypes.object,
  name: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  helperText: PropTypes.string,
  noHelperText: PropTypes.bool,
  options: PropTypes.array,
  value: PropTypes.any,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  dict: PropTypes.string
};

const mapStateToProps = (state, ownProps) => ({
  options: ownProps.dict
    ? Selectors.getItemData(state, ItemTypeApplication, ownProps.dict)
    : ownProps.options
});

export const OptionsField = compose(
  connect(mapStateToProps),
  withStyles(styles)
)(OptionsComponent);
