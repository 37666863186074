import React from "react";

import MuiExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PropTypes from "prop-types";

class ExpansionPanelComponent extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.object,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    fixed: PropTypes.bool,
    children: PropTypes.any
  };

  static defaultProps = {
    title: ""
  };

  render() {
    const { title, children, fixed, ...rest } = this.props;
    const Title = () => (
      <Typography
        color="primary"
        variant="button"
        style={{ textTransform: "capitalize", fontSize: "1.05rem" }}
      >
        {title}
      </Typography>
    );

    return (
      <MuiExpansionPanel {...rest} data-testid="card">
        {fixed && (
          <div style={{ padding: "16px 24px" }}>
            <Title />
          </div>
        )}
        {!fixed && (
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Title />
          </ExpansionPanelSummary>
        )}
        {children}
      </MuiExpansionPanel>
    );
  }
}

export const ExpansionPanel = ExpansionPanelComponent;
