import React from "react";

import {
  actionsFor,
  breadcrumbsFor,
  exportBlock,
  filtersFor,
  tableFor
} from "srx-shared";
import { PageContainer } from "srx-shared/mui";

import { ClearFilters } from "./Actions/ClearFilters";
import { Refresh } from "./Actions/Refresh";
import { Filters } from "./Filters";
import { WeightHistoryItem } from "./weightHistoryItem";

const WeightHistoryTable = tableFor(WeightHistoryItem);
const WeightHistoryFilters = filtersFor(WeightHistoryItem);
const WeightHistoryExport = exportBlock(WeightHistoryItem);

const BreadcrumbsComponent = breadcrumbsFor();
const WeightHistoryActions = actionsFor(WeightHistoryItem, {
  Custom: () => (
    <>
      <ClearFilters />
      <WeightHistoryExport />
      <Refresh />
    </>
  )
});

const renderFilters = table => (
  <div style={{ marginTop: 16 }}>
    <WeightHistoryFilters table={table} />
  </div>
);

const renderHeader = () => <Filters />;

const renderActions = table => <WeightHistoryActions table={table} />;

export default function WeightHistory() {
  return (
    <PageContainer renderHeader={renderHeader}>
      <BreadcrumbsComponent renderList={() => [{ title: "Weight History" }]} />
      <WeightHistoryTable
        renderFilters={renderFilters}
        renderActions={renderActions}
      />
    </PageContainer>
  );
}
