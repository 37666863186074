import React from "react";

import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DeleteIcon from "@material-ui/icons/DeleteOutlined";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { ActionCreators } from "../../actions";
import { DckActionCreators } from "../../redux";
import { IconButton } from "../IconButton";
import { ModalDialog } from "../ModalDialog";

class RemoveButtonComponent extends React.Component {
  static propTypes = {
    item: PropTypes.any.isRequired,
    row: PropTypes.object,
    removeItem: PropTypes.func,
    unselectAllItems: PropTypes.func,
    modalProps: PropTypes.object,
    children: PropTypes.any,
    tip: PropTypes.string,
    toolTip: PropTypes.string,
    disabled: PropTypes.bool,
    confirmButtonLabel: PropTypes.string,
    icon: PropTypes.node
  };

  state = {
    open: false
  };

  handleOpen = e => {
    e.stopPropagation();
    this.setState({ open: true });
  };

  handleClose = e => {
    e.stopPropagation();
    this.setState({ open: false });
  };

  handleConfirm = e => {
    const { row, item, removeItem, unselectAllItems } = this.props;
    e.stopPropagation();
    removeItem(item, row.id);
    unselectAllItems(item);
    this.setState({ open: false });
  };

  render() {
    const {
      item,
      tip,
      children,
      modalProps,
      icon,
      toolTip,
      disabled = false,
      confirmButtonLabel
    } = this.props;

    const Icon = icon || DeleteIcon;

    return (
      <React.Fragment>
        <IconButton
          tip={toolTip || `Delete ${tip || item.name}`}
          icon={<Icon />}
          onClick={this.handleOpen}
          disabled={disabled}
          data-testid="remove"
        />
        <ModalDialog
          open={this.state.open}
          onClose={this.handleClose}
          title={`Delete ${tip || item.title || item.name}`}
          size="md"
          {...modalProps}
        >
          <DialogContent>
            <div style={{ color: "rgba(0, 0, 0, 0.54)" }}>{children}</div>
          </DialogContent>
          <DialogActions style={{ paddingBottom: 8, paddingRight: 16 }}>
            <Button
              type="reset"
              onClick={this.handleClose}
              color="default"
              variant="contained"
              style={{ minWidth: 100, marginLeft: 16 }}
              data-testid="cancel-button"
            >
              Cancel
            </Button>
            <Button
              type="submit"
              onClick={this.handleConfirm}
              color="secondary"
              variant="contained"
              style={{ minWidth: 100, marginLeft: 16 }}
              data-testid="confirm-button"
            >
              {confirmButtonLabel || "Yes, delete"}
            </Button>
          </DialogActions>
        </ModalDialog>
      </React.Fragment>
    );
  }
}

RemoveButtonComponent.defaultProps = {
  item: {},
  row: {},
  removeItem: () => {}
};

const mapDispatchToProps = {
  removeItem: (item, id) => DckActionCreators.itemRemove(item.type, id),
  unselectAllItems: item =>
    ActionCreators.setSelectedItems(item.type, new Set())
};

export default connect(null, mapDispatchToProps)(RemoveButtonComponent);
