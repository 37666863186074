/* eslint react/display-name: "off" */
/* eslint react/prop-types: "off" */

import { Formatters } from "srx-shared";
import { Cell } from "srx-shared/mui";

import { isValidPrice, isValidValue } from "@comp/Fees/isValidPrice";
import { ShiptoFee } from "@items/types";

export const ShiptoFeeItem = {
  type: ShiptoFee,
  name: "ShiptoFee"
};

// table
ShiptoFeeItem.columns = [
  {
    Header: "Name",
    accessor: "name",
    Cell: props => <Cell {...props} />
  },
  {
    Header: "Price",
    accessor: "price",
    inline: true,
    View: ({ value }) => Formatters.price(value),
    Cell: props => <Cell {...props} />
  },
  {
    Header: "SKU",
    accessor: "salesForceSku",
    inline: true,
    View: ({ value }) => <Cell>{value}</Cell>,
    Cell: props => <Cell {...props} />
  }
];

// form fields
ShiptoFeeItem.fieldsConfig = {
  price: {
    inlineValidation: isValidPrice
  },
  salesForceSku: {
    inlineValidation: isValidValue
  }
};
