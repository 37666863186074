import React from "react";

import withWidth from "@material-ui/core/withWidth";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";

import { getValidateStatus, getItemData } from "../../../actions/selectors";
import { DckActionCreators, DckSelectors } from "../../../redux";
import { DownloadButton } from "../../ActionButton";
import { ImportButton } from "../ImportButton";

export const importBlock = ({ name, type }, ValidateDialog, params = []) => {
  const importProcess = `${type.toUpperCase()}_IMPORT`;
  class Wrapper extends React.Component {
    static propTypes = {
      resetImportProcess: PropTypes.func,
      importItem: PropTypes.func,
      importing: PropTypes.bool,
      sampleFileName: PropTypes.string,
      labelForImportButton: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string
      ]),
      hideTemplateButton: PropTypes.bool,
      importDisabled: PropTypes.bool
    };

    onImport = file => {
      const data = params.reduce((arr, param) => {
        arr[param] = this.props[param] || "";
        return arr;
      }, {});
      this.props.importItem({ file, ...data });
    };

    render() {
      const {
        resetImportProcess,
        importItem,
        importing,
        sampleFileName,
        labelForImportButton,
        importDisabled,
        hideTemplateButton
      } = this.props;
      return (
        <>
          {!hideTemplateButton && (
            <DownloadButton
              sampleFileName={sampleFileName || name.toLowerCase()}
            />
          )}
          <ImportButton
            labelForImportButton={labelForImportButton || ""}
            onImport={this.onImport}
            processReset={resetImportProcess}
            importDisabled={importDisabled || importing}
          />
          <ValidateDialog
            itemType={type}
            onCancel={resetImportProcess}
            onContinue={validateStatus => importItem({ validateStatus })}
          />
        </>
      );
    }
  }

  const mapStateParams = state => {
    if (params.length === 0) return {};
    const mapping = params.reduce(
      (mapping, param) => ({
        ...mapping,
        [param]: getItemData(state, type, param) || null
      }),
      {}
    );
    return mapping;
  };

  const mapStateToProps = state => ({
    ...mapStateParams(state),
    importing: DckSelectors.selectProcessRunning(state, importProcess),
    validateStatus: getValidateStatus(state, type)
  });

  const mapDispatchToProps = {
    resetImportProcess: () =>
      DckActionCreators.asyncProcessReset(importProcess),
    importItem: data => DckActionCreators.itemsImport(type, data)
  };

  return compose(
    connect(mapStateToProps, mapDispatchToProps),
    withWidth()
  )(Wrapper);
};
