import { DckSelectors } from "dck-redux";
import { takeLatest, all, select, put } from "redux-saga/effects";

import {
  Process,
  isActionLoad,
  isActionSelect,
  isActionAdd,
  isActionDelete,
  isActionUpdate,
  ActionCreators,
  ItemTypeApplication
} from "srx-shared";

import { ActionTypes } from "@actions";
import * as RestApi from "@api";
import {
  SmartShelf,
  DistributorShelves,
  SmartShelfDetails
} from "@items/types";

import { loadDistributorLockersAndStorages } from "./devicesSaga";

function* getCurrentSmartShelf() {
  return yield select(state =>
    DckSelectors.selectActiveItem(state, SmartShelf)
  );
}

export function* loadDistributorsShelvesSaga(action) {
  const id = action.filteringOptions && action.filteringOptions.distributorId;
  const type = action.filteringOptions && action.filteringOptions.type;
  if (!id) return;

  const proc = new Process.Load(
    DistributorShelves,
    RestApi.GetDistributorsShelves
  );
  yield proc.start();
  try {
    yield put(
      ActionCreators.setItemData(ItemTypeApplication, "distributorShelves", [])
    );

    yield proc.callApi(id);

    const dict = ((proc.data || {}).entities || [])
      .filter(
        shelf =>
          !shelf.doorConfiguration ||
          !type ||
          shelf.doorConfiguration.hardware.type === type
      )
      .map(shelf => ({
        label: shelf.serialNumber,
        value: shelf.id,
        ...shelf
      }));

    yield put(
      ActionCreators.setItemData(
        ItemTypeApplication,
        "distributorShelves",
        dict
      )
    );
    yield proc.stop();
  } catch (ex) {
    yield proc.fail(ex);
  }
}

function* loadDistributorShelvesSaga(action) {
  const proc = new Process.Load(
    DistributorShelves,
    RestApi.GetDistributorShelves
  );
  yield proc.start();
  try {
    yield proc.callApi(action.id);
    const data = proc.data.map(shelf => ({
      label: shelf.serialNumber,
      value: shelf.id
    }));
    yield proc.setItemData("distributorShelves", data);
    yield proc.stop();
  } catch (ex) {
    yield proc.fail(ex);
  }
}

function* loadSmartShelvesSaga(action) {
  const proc = new Process.Load(SmartShelf, RestApi.ListSmartShelfItems, {
    pageble: true
  });
  yield proc.start();
  try {
    yield proc.callApi();
    proc.data.entities.forEach(el => {
      if (!el.doorConfiguration) {
        el.doorConfiguration = {
          id: "",
          hardware: { id: "" }
        };
      }
      if (!el.distributor) {
        el.distributor = {
          id: ""
        };
      }
    });
    yield proc.setEntities();
    yield proc.setTotalPages();
    yield proc.stop();
  } catch (ex) {
    yield proc.fail(ex);
  }
}

function* getSmartShelfSaga(action) {
  const proc = new Process.Load(SmartShelf, RestApi.GetSmartShelf);
  yield proc.start();
  try {
    yield proc.callApi(action.id);
    yield proc.set([proc.data]);
    yield proc.stop();
  } catch (ex) {
    yield proc.fail(ex);
  }
}

function* addSmartShelfSaga(action) {
  const proc = new Process.Add(SmartShelf, RestApi.AddSmartShelf);
  yield proc.start();
  try {
    const data = { ...action.data };
    if (!data.distributor.id) {
      data.distributor = null;
    }
    yield proc.callApi(data);
    yield proc.stop();
    yield loadSmartShelvesSaga();
  } catch (ex) {
    yield proc.fail(ex);
  }
}

function* updateSmartShelfSaga(action) {
  if (!action.data.confirmed) {
    return yield validateSmartShelfSaga(action);
  }
  const proc = new Process.Update(SmartShelf, RestApi.UpdateSmartShelf);
  yield proc.start();
  try {
    const data = { ...action.data };
    if (!data.distributor.id) {
      data.distributor = null;
    }
    yield proc.callApi(data);
    yield proc.stop();
    yield loadSmartShelvesSaga();
  } catch (ex) {
    yield proc.fail(ex);
  }
}

function* deleteSmartShelfSaga(action) {
  const proc = new Process.Delete(SmartShelf, RestApi.RemoveSmartShelf);
  yield proc.start();
  try {
    yield proc.callApi(action.id);
    yield proc.stop();
    yield loadSmartShelvesSaga();
  } catch (ex) {
    yield proc.fail(ex);
  }
}

function* validateSmartShelfSaga(action) {
  const proc = new Process("VALIDATE", SmartShelf, RestApi.ValidateSmartShelf);
  yield proc.start();
  try {
    yield proc.callApi(action.data);
    if (proc.data !== "Validated successfully") {
      yield proc.setItemData("status", {
        data: action.data,
        message: proc.data
      });
    } else {
      const data = { ...action.data, confirmed: true };
      yield updateSmartShelfSaga({ data });
    }
    yield proc.stop();
  } catch (ex) {
    yield proc.fail(ex);
  }
}

function* initDataSaga() {
  const smartshelf = yield getCurrentSmartShelf();
  if (!smartshelf) return;

  const { distributor } = smartshelf;
  if (distributor.id) {
    yield loadDistributorLockersAndStorages({
      filteringOptions: {
        id: distributor.id
      }
    });
  }
}

function* getSmartShelfDetailsSaga(action) {
  const proc = new Process.Load(
    SmartShelfDetails,
    RestApi.GetSmartShelfDetails
  );
  yield proc.start();
  try {
    yield proc.callApi(action.id);
    yield proc.setItemData("details", proc.data);
    yield proc.stop();
  } catch (ex) {
    yield proc.fail(ex);
  }
}

function* pollWeightSmartShelf(action) {
  const proc = new Process.Update(
    SmartShelfDetails,
    RestApi.PollWeightSmartShelf
  );
  yield proc.start();
  try {
    yield proc.callApi(action.id);
    yield proc.stop();
  } catch (ex) {
    yield proc.fail(ex);
  }
}

function* smartShelvesSaga() {
  yield all([
    takeLatest(isActionLoad(SmartShelf), loadSmartShelvesSaga),
    takeLatest(isActionSelect(SmartShelf), getSmartShelfSaga),
    takeLatest(isActionSelect(SmartShelfDetails), getSmartShelfDetailsSaga),
    takeLatest(isActionUpdate(SmartShelfDetails), pollWeightSmartShelf),
    takeLatest(isActionAdd(SmartShelf), addSmartShelfSaga),
    takeLatest(isActionUpdate(SmartShelf), updateSmartShelfSaga),
    takeLatest(isActionDelete(SmartShelf), deleteSmartShelfSaga),
    takeLatest(isActionLoad(DistributorShelves), loadDistributorsShelvesSaga),
    takeLatest(isActionSelect(DistributorShelves), loadDistributorShelvesSaga),
    takeLatest(
      action =>
        action.type === ActionTypes.SHOW_EDIT_MODAL &&
        action.itemType === SmartShelf,
      initDataSaga
    )
  ]);
}

export default smartShelvesSaga;
