import { DckActionCreators } from "dck-redux";
import _cloneDeep from "lodash/cloneDeep";
import { takeEvery, all, call, put } from "redux-saga/effects";

import {
  Process,
  isActionLoad,
  isActionAdd,
  isActionDelete,
  isActionUpdate,
  isActionSelect,
  ItemTypeApplication
} from "srx-shared";

import * as RestApi from "@api";
import { defaultSettings as settingsStub } from "@comp/ShipToTemplates/ShipToTemplate/settingsStub";
import {
  ShipToTemplate,
  ShipToTemplateSettings,
  VmiListSettingsStructure,
  RlSubmitStatus
} from "@items/types";
import { SUBMIT_QUOTED, SUBMIT_ORDERED_AND_QUOTED } from "config";

const preProcessSettings = data => {
  const {
    replenishmentListSettings,
    vmiIntegrationSetting,
    cribCrawlSettings,
    vmiListSettings,
    rfidAutoCheckoutCartSettings,
    serialNumberSettings,
    orderCloseSettings,
    checkoutSoftwareSettings,
    autoSubmitSettings,
    multiLocationsSettings,
    pricingSourceSettings,
    rlSubmitIntegrationSettings,
    rfidTransitSettings
  } = data;

  serialNumberSettings.defaultSettings =
    settingsStub.serialNumberSettings.defaultSettings;
  if (serialNumberSettings.useDefault) {
    serialNumberSettings.settings.daysUntilAutoExpiration = 0;
    serialNumberSettings.settings.daysUntilExpirationAlarm = 0;
  }

  replenishmentListSettings.settings.orderSubmitSettings =
    replenishmentListSettings.settings.orderSubmitSettings ===
    SUBMIT_ORDERED_AND_QUOTED;

  replenishmentListSettings.defaultSettings =
    settingsStub.replenishmentListSettings.defaultSettings;

  orderCloseSettings.defaultSettings =
    settingsStub.orderCloseSettings.defaultSettings;

  checkoutSoftwareSettings.defaultSettings =
    settingsStub.checkoutSoftwareSettings.defaultSettings;

  autoSubmitSettings.defaultSettings =
    settingsStub.autoSubmitSettings.defaultSettings;

  multiLocationsSettings.defaultSettings = {
    multiLocationsEnabled: true
  };

  pricingSourceSettings.defaultSettings =
    settingsStub.pricingSourceSettings.defaultSettings;

  rlSubmitIntegrationSettings.defaultSettings =
    settingsStub.rlSubmitIntegrationSettings.defaultSettings;

  if (cribCrawlSettings.settings.defaultTrigger?.length) {
    const [triggerType] = cribCrawlSettings.settings.defaultTrigger;
    cribCrawlSettings.settings.defaultTrigger = triggerType;
  }

  const { customerSku, distributorSku, manufacturerSku } =
    vmiListSettings.settings;
  vmiListSettings.settings.jointGroup =
    customerSku && distributorSku && manufacturerSku ? customerSku : null;

  data.vmiIntegrationSetting = {
    settings: { ...vmiIntegrationSetting },
    defaultSettings: settingsStub.vmiIntegrationSetting.defaultSettings,
    useDefault: vmiIntegrationSetting.useDefault
  };

  delete rfidAutoCheckoutCartSettings.useDefault;
  delete rfidTransitSettings.useDefault;
};

const postProcessSettings = data => {
  const {
    replenishmentListSettings,
    cribCrawlSettings,
    vmiListSettings,
    vmiIntegrationSetting,
    rfidAutoCheckoutCartSettings,
    pricingViewSettings,
    criticalMinAlertSettings,
    rfidTransitSettings,
    putAwaySettings,
    allowSettings,
    serialNumberSettings,
    multiLocationsSettings
  } = data;

  replenishmentListSettings.settings.orderSubmitSettings =
    replenishmentListSettings.settings.orderSubmitSettings
      ? SUBMIT_ORDERED_AND_QUOTED
      : SUBMIT_QUOTED;

  replenishmentListSettings.defaultSettings = {
    email: "",
    pricingNotAvailableBehavior: "AS_A_QUOTE",
    orderSubmitSettings: SUBMIT_QUOTED
  };

  pricingViewSettings.settings.useDefault = pricingViewSettings.useDefault;
  criticalMinAlertSettings.settings.useDefault =
    criticalMinAlertSettings.useDefault;
  putAwaySettings.settings.useDefault = putAwaySettings.useDefault;
  allowSettings.settings.useDefault = allowSettings.useDefault;
  serialNumberSettings.settings.useDefault = serialNumberSettings.useDefault;

  if (typeof cribCrawlSettings.settings.defaultTrigger === "string")
    cribCrawlSettings.settings.defaultTrigger = [
      cribCrawlSettings.settings.defaultTrigger
    ];

  if (vmiListSettings.settings.jointGroup) {
    vmiListSettings.settings.customerSku = vmiListSettings.settings.jointGroup;
    vmiListSettings.settings.distributorSku = "VIEW";
    vmiListSettings.settings.manufacturerSku = "VIEW";
  }
  vmiListSettings.settings.useDefault = vmiListSettings.useDefault;
  multiLocationsSettings.settings.useDefault =
    multiLocationsSettings.useDefault;

  data.vmiIntegrationSetting = vmiIntegrationSetting.useDefault
    ? {
        ...vmiIntegrationSetting.defaultSettings,
        useDefault: vmiIntegrationSetting.useDefault,
        zoneId: "America/New_York"
      }
    : {
        ...vmiIntegrationSetting.settings,
        useDefault: vmiIntegrationSetting.useDefault,
        zoneId: "America/New_York"
      };

  rfidAutoCheckoutCartSettings.useDefault =
    rfidAutoCheckoutCartSettings.settings.useDefault;
  rfidTransitSettings.useDefault = rfidTransitSettings.settings.useDefault;
};

function* loadShipToTemplatesListSaga() {
  const proc = new Process.Load(ShipToTemplate, RestApi.GetShipToTemplatesList);
  yield proc.start();
  try {
    yield proc.callApi();
    yield proc.set(proc.data || []);
    yield proc.stop();
  } catch (ex) {
    yield proc.fail(ex);
  }
}

function* loadShipToTemplateSaga(action) {
  const proc = new Process.Load(
    ShipToTemplateSettings,
    RestApi.GetShipToTemplate
  );
  yield proc.start();
  try {
    yield proc.callApi(action.id);
    preProcessSettings(proc.data);
    yield proc.setItemData("settings", proc.data);
    yield proc.stop();
  } catch (ex) {
    yield proc.fail(ex);
  }
}

function* updateShipToTemplateSaga(action) {
  const proc = new Process.Update(
    ShipToTemplateSettings,
    RestApi.UpdateShipToTemplate
  );
  yield proc.start();
  try {
    const data = _cloneDeep(action.data);
    postProcessSettings(data);
    yield proc.callApi(data);
    yield proc.stop();
  } catch (ex) {
    yield proc.fail(ex);
  }
}

function* addShipToTemplateSaga(action) {
  const proc = new Process.Add(
    ShipToTemplateSettings,
    RestApi.AddShipToTemplate
  );
  yield proc.start();
  try {
    const data = _cloneDeep(action.data);
    postProcessSettings(data);
    yield proc.callApi(data);
    yield proc.stop();
  } catch (ex) {
    yield proc.fail(ex);
  }
}

function* changeTemplateStatusSaga(action) {
  const proc = new Process.Update(ShipToTemplate, RestApi.UpdateShipToTemplate);
  yield proc.start();
  try {
    const { status, id } = action.data;
    const session = yield proc.session();
    const { data } = yield RestApi.GetShipToTemplate(session.idToken, id);
    preProcessSettings(data);
    postProcessSettings(data);
    yield proc.callApi({ ...data, status });
    yield proc.stop();
    yield call(loadShipToTemplatesListSaga);
  } catch (ex) {
    yield proc.fail(ex);
  }
}

function* deleteShipToTemplateSaga(action) {
  const proc = new Process.Delete(
    ShipToTemplateSettings,
    RestApi.DeleteShipToTemplate
  );
  yield proc.start();
  try {
    yield proc.callApi(action.id);
    yield proc.stop();
    yield call(loadShipToTemplatesListSaga);
  } catch (ex) {
    yield proc.fail(ex);
  }
}

function* loadVMISettingsStructureSaga() {
  const proc = new Process.Load(
    VmiListSettingsStructure,
    RestApi.GetVmiListSettingsStructure
  );
  yield proc.start();
  try {
    yield proc.callApi();
    proc.data["jointGroup"] = "EDIT";
    yield proc.setItemData("vmiListSettingsStructure", proc.data);
    yield proc.stop();
  } catch (ex) {
    yield proc.fail(ex);
  }
}

function* getRlSubmitIntegrationStatuses(action) {
  const proc = new Process.Load(
    RlSubmitStatus,
    RestApi.GetRLSubmitIntegrationStatuses
  );
  yield proc.start();
  try {
    const { erp } = action.filteringOptions;
    yield proc.callApi(erp);
    const dict = proc.data.map(el => ({
      label: el,
      value: el
    }));
    yield put(
      DckActionCreators.setItemData(ItemTypeApplication, "rlSubmitStatus", dict)
    );
    yield proc.stop();
  } catch (ex) {
    yield proc.fail(ex);
  }
}

function* shipToTemplatesSaga() {
  yield all([
    takeEvery(isActionLoad(ShipToTemplate), loadShipToTemplatesListSaga),
    takeEvery(isActionUpdate(ShipToTemplate), changeTemplateStatusSaga),
    takeEvery(isActionUpdate(ShipToTemplateSettings), updateShipToTemplateSaga),
    takeEvery(isActionAdd(ShipToTemplateSettings), addShipToTemplateSaga),
    takeEvery(isActionDelete(ShipToTemplate), deleteShipToTemplateSaga),
    takeEvery(isActionSelect(ShipToTemplateSettings), loadShipToTemplateSaga),
    takeEvery(
      isActionLoad(VmiListSettingsStructure),
      loadVMISettingsStructureSaga
    ),
    takeEvery(isActionLoad(RlSubmitStatus), getRlSubmitIntegrationStatuses)
  ]);
}

export default shipToTemplatesSaga;
