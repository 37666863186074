/* eslint-disable react/prop-types */

import { Tooltip } from "@material-ui/core";
import CameraIcon from "@material-ui/icons/CameraAltOutlined";
import OkIcon from "@material-ui/icons/CheckCircle";
import LockerIcon from "@material-ui/icons/DeveloperBoard";
import IotHubIcon from "@material-ui/icons/DeviceHub";
import Done from "@material-ui/icons/DoneAll";
import InfoIcon from "@material-ui/icons/ErrorOutlined";
import StorageIcon from "@material-ui/icons/Kitchen";
import LabelIcon from "@material-ui/icons/LocalOfferOutlined";
import RfidIcon from "@material-ui/icons/Nfc";
import ButtonIcon from "@material-ui/icons/ToggleOn";
import WarningIcon from "@material-ui/icons/WarningOutlined";

import { Cell } from "../mui/Table/Cell";
import * as Icons from "../styles/icons";
import { notEmpty } from "../utils/index";

const locale = "en-US";

export const empty = item => item || "";

const formatIcon = (value, props, Icon) => {
  if (!value) return null;
  const color = props?.disabled ? "#aaa" : "#777";
  return (
    <Cell center>
      <Icon style={{ color }} />
    </Cell>
  );
};

export const Asset = (value, props) => formatIcon(value, props, Icons.Asset);
export const Serialized = (value, props) =>
  formatIcon(value, props, Icons.SerializationOutlined);

export const price = value => {
  value = parseFloat(value);
  if (Number.isNaN(value) || value < 0) return "N/A";
  const localized = value.toLocaleString(locale, {
    style: "currency",
    currency: "USD"
  });
  return localized;
};

export const getPrice = value => {
  value = parseFloat(value);
  if (Number.isNaN(value)) return "N/A";
  return value;
};

export const toISOStringDate = date => date.toISOString().split(["T"])[0];

export const date = timestamp =>
  timestamp
    ? new Date(timestamp).toLocaleDateString(locale, {
        weekday: "short",
        year: "numeric",
        month: "short",
        day: "numeric"
      })
    : "";

export const datetime = timestamp =>
  timestamp
    ? new Date(timestamp).toLocaleDateString(locale, {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "2-digit"
      })
    : "";

export const localDateTime = timestamp =>
  timestamp
    ? new Date(timestamp + "Z").toLocaleDateString(locale, {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "2-digit"
      })
    : "";

export const localDate = timestamp =>
  timestamp
    ? new Date(timestamp).toLocaleDateString(locale, {
        year: "numeric",
        month: "2-digit",
        day: "2-digit"
      })
    : "";

export const list = x => (x ? x.map(i => i.name).join(", ") : "-");

export const address = item =>
  item
    ? `${item.line1}${item.line2 ? " " + item.line2 : ""} ${item.city} ${
        item.state
      } ${item.zipCode}`
    : "-";

export const hubAddress = x => (notEmpty(x) ? String(x).padStart(3, "0") : "-");

export const link = (url, text, blank) => {
  const props = { href: url };
  if (blank) props["target"] = "_blank";
  return (url && <a {...props}>{text || ""}</a>) || "";
};

export const mailtolink = item => {
  return (item && <a href={`mailto:${item}`}>{item}</a>) || "";
};

export const fee = item => (item && String(item).trim() ? `$${item}` : "$0");

export const docstatus = x => {
  const COLORS = {
    APPROVED: "#00852C",
    REJECTED: "#CA1919",
    PENDING: "#555"
  };
  return <span style={{ color: COLORS[x] }}>{x}</span>;
};

export const image = src => {
  return <img src={"data:image/jpg;base64," + src} alt="" />;
};

export const coloredStatus = x => {
  return x === "SUCCESS" ? (
    <span style={{ color: "green" }}>{x}</span>
  ) : (
    <span style={{ color: "red" }}>{x}</span>
  );
};

export const coloredIconStatus = props => {
  const { value, row } = props;
  const errorMessage = row._original.errorMessage || "";
  return value === "SUCCESS" ? (
    <OkIcon
      style={{
        color: "green",
        display: "flex",
        width: "100%",
        justifyContent: "center",
        alignItems: "center"
      }}
    />
  ) : value === "DISCREPANCY" ? (
    <Tooltip title={errorMessage} placement="left">
      <WarningIcon
        style={{
          color: "orange",
          display: "flex",
          width: "100%",
          justifyContent: "center",
          alignItems: "center"
        }}
      />
    </Tooltip>
  ) : (
    <Tooltip title={errorMessage} placement="left">
      <InfoIcon
        style={{
          color: "brown",
          display: "flex",
          width: "100%",
          justifyContent: "center",
          alignItems: "center"
        }}
      />
    </Tooltip>
  );
};
export const allocationCodes = value =>
  JSON.parse(value || "[]")
    .map(code => `${code.name}: ${code.value}`)
    .join(", ");

export const BillTransactionStatus = status => {
  const styles = {
    ISSUE: {
      color: "#2795C4",
      backgroundColor: "rgba(39,149,196,0.15)"
    },
    RETURN: {
      color: "#4CAF50",
      backgroundColor: "#E4F3E5"
    },
    DISCREPANCY: {
      color: "#EF8A20",
      backgroundColor: "#FDEEDE"
    }
  };

  const style = {
    padding: "4px 8px",
    fontWeight: 600,
    ...styles[status]
  };

  return (
    <div style={{ display: "flex" }}>
      <div style={style}>{status}</div>
    </div>
  );
};

export const center = x => {
  return (
    <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
      {x}
    </div>
  );
};

export const listToString = (items, key) =>
  items
    ? items.reduce(
        (value, item) => (value ? `${value}, ${item[key]}` : item[key]),
        ""
      )
    : "-";

export const uppercaseFirstLetter = item =>
  String(item).charAt(0).toUpperCase() + String(item).slice(1).toLowerCase();

const LIST_MORE_LIMIT = 5;

export const listMore = (items, { accessor, limit = LIST_MORE_LIMIT } = {}) => {
  if (!items || items.length === 0) return "-";
  if (limit > items.length) limit = items.length;

  const toStr = list =>
    list.map(el => (accessor ? el[accessor] : el)).join(", ");

  if (items.length <= limit) return toStr(items);
  limit = limit - 1;

  const more = items.length - limit;
  const list = items.slice(0, limit);
  const listStr = toStr(list);

  return more ? (
    <>
      {listStr}
      ,&nbsp;
      <span style={{ color: "gray" }}>+{more} more</span>
    </>
  ) : (
    listStr
  );
};

const DeviceTypeIcons = {
  IOTHUB: IotHubIcon,
  RFID: RfidIcon,
  LOCKER: LockerIcon,
  STORAGE: StorageIcon,
  CAMERA: CameraIcon,
  LABEL: LabelIcon,
  BUTTON: ButtonIcon,
  VENDING: Icons.Vending
};

export const deviceTypeIcon = value => DeviceTypeIcons[value];

export const deviceType = value => {
  if (!value) return;

  const type = String(value).toUpperCase();
  const Icon = DeviceTypeIcons[value];
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {Icon && (
        <Icon style={{ fontSize: 22, marginRight: 5, color: "#707070" }} />
      )}
      <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>{type}</div>
    </div>
  );
};

export const triggerType = value => {
  if (!value) return;

  const TriggerTypeIcons = {
    RFID: RfidIcon,
    LOCKER: LockerIcon,
    SMARTSHELF: StorageIcon,
    LABEL: LabelIcon,
    BUTTON: ButtonIcon,
    VENDING: Icons.Vending
  };
  const type = String(value).toLocaleUpperCase();
  const Icon = TriggerTypeIcons[value];
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {Icon && (
        <Icon style={{ fontSize: 22, marginRight: 5, color: "inherit" }} />
      )}
      <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>{type}</div>
    </div>
  );
};

const styles = {
  padding: "3px 8px",
  borderRadius: 4,
  overflow: "hidden",
  textOverflow: "ellipsis",
  fontWeight: "bold",
  maxWidth: "140px",
  textAlign: "center"
};

export const orderStatus = value => {
  const ORDER_STATUS_STYLES = {
    ACTIVE: {
      color: "#2795C4",
      backgroundColor: "#DFEFF6"
    },
    ORDERED: {
      color: "#EF8A20",
      backgroundColor: "#FDEEDE"
    },
    QUOTED: {
      color: "#EF8A20",
      backgroundColor: "#FDEEDE"
    },
    DO_NOT_REORDER: {
      color: "#727272",
      backgroundColor: "#EAEAEA"
    },
    SHIPPED: {
      color: "#7755CC",
      backgroundColor: "#EBE6F8"
    },
    DELIVERED: {
      color: "#4CAF50",
      backgroundColor: "#E4F3E5"
    },
    BACKORDERED: {
      color: "#727272",
      backgroundColor: "#EAEAEA"
    }
  };
  return (
    <div
      style={{
        ...ORDER_STATUS_STYLES[value],
        ...styles
      }}
    >
      {value}
    </div>
  );
};

export const rfidStatus = value => {
  const RFID_STYLES = {
    ASSIGNED: {
      color: "#2795C4",
      backgroundColor: "#DFEFF6"
    },
    MANIFEST: {
      color: "#EFA90D",
      backgroundColor: "#FDF2DB"
    },
    CHECK_IN: {
      color: "#BDAB08",
      backgroundColor: "#F5F3DA"
    },
    AVAILABLE: {
      color: "#4CAF50",
      backgroundColor: "#E4F3E5"
    },
    ISSUED: {
      color: "#727272",
      backgroundColor: "#EAEAEA"
    },
    RETURN_MANIFEST: {
      color: "#EFA90D",
      backgroundColor: "#FDF2DB"
    },
    RETURN_CHECK_IN: {
      color: "#BDAB08",
      backgroundColor: "#F5F3DA"
    }
  };
  return (
    <div
      style={{
        ...RFID_STYLES[value],
        ...styles
      }}
    >
      {value}
    </div>
  );
};

export const lifecycleStatus = (value, withText = false) => {
  const LIFECYCLE_STATUS_COLOR = {
    ACTIVE: "#4caf50",
    MATURE: "#ffb74d",
    OBSOLETE: "brown"
  };
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div
        style={{
          backgroundColor: LIFECYCLE_STATUS_COLOR[value],
          height: 16,
          width: 16,
          borderRadius: 8
        }}
      />
      {withText && (
        <div
          style={{
            fontSize: 14,
            marginLeft: 6,
            color: LIFECYCLE_STATUS_COLOR[value]
          }}
        >
          {value}
        </div>
      )}
    </div>
  );
};

export const inventoryStatus = value => {
  const INVENTORY_STATUS_STYLES = {
    FROZEN: {
      color: "#727272",
      backgroundColor: "#EAEAEA",
      width: 75,
      textAlign: "center"
    },
    MOVING: {
      color: "#2795C4",
      backgroundColor: "#DFEFF6",
      width: 75,
      textAlign: "center"
    },
    SLOW: {
      color: "#EFA90D",
      backgroundColor: "#FDF2DB",
      width: 75,
      textAlign: "center"
    }
  };
  return (
    <div
      style={{
        ...INVENTORY_STATUS_STYLES[value],
        ...styles,
        width: 75,
        textAlign: "center"
      }}
    >
      {value}
    </div>
  );
};

export const supplierType = (original, value, styles) =>
  value && (
    <span
      style={{
        ...styles,
        color: "#EF8A20",
        backgroundColor: "#FDEEDE",
        padding: "2px 4px",
        textTransform: "uppercase",
        fontWeight: 600
      }}
    >
      {original.customerOwned ? "Customer Owned" : "External Supplier"}
    </span>
  );

export const serialNumberStatus = value => {
  const SERIAL_NUMBER_STATUS_COLORS = {
    ASSIGNED: "#2795C4",
    AVAILABLE: "#4CAF50",
    ISSUED: "#707070",
    EXPIRED: "#F44336",
    DISPOSED: "#773333"
  };
  return (
    <div
      style={{
        color: SERIAL_NUMBER_STATUS_COLORS[value],
        backgroundColor: `${SERIAL_NUMBER_STATUS_COLORS[value]}26`,
        width: "90px",
        textAlign: "center",
        ...styles
      }}
    >
      {value}
    </div>
  );
};

export const user = item =>
  item.firstName && item.lastName
    ? `${item.firstName} ${item.lastName}`
    : item.email;

export const join = (parts, delimiter = " | ") =>
  (parts || []).filter(Boolean).join(delimiter);

export const shipTo = (shipToName, shipToNumber) =>
  shipToName ? `${shipToName} (${shipToNumber})` : shipToNumber;

export const customer = (customerName, customerNumber) =>
  customerNumber ? `${customerName} (${customerNumber})` : customerName;

export const site = (siteName, siteNumber) =>
  siteName && siteNumber ? `${siteName} (${siteNumber})` : "-";

export const subSite = (subSiteName, subSiteNumber) =>
  subSiteName && subSiteNumber ? `${subSiteName} (${subSiteNumber})` : "-";

export const VendingTroubleshootingStatus = value => {
  const VENDING_TROUBLESHOOTING_STATUS_STYLES = {
    TEST: "#000000",
    TESTING: "#2795C4",
    DONE: "#4CAF50",
    DONE_WARN: "#EFA90D",
    FAILED: "#F44336",
    ABORTED: "#000000",
    TESTED: "#4CAF50",
    REPORTED: "#F44336"
  };
  return (
    <div
      style={{
        color: VENDING_TROUBLESHOOTING_STATUS_STYLES[value],
        backgroundColor: `${VENDING_TROUBLESHOOTING_STATUS_STYLES[value]}26`,
        textAlign: "center",
        padding: "3px 8px",
        borderRadius: 4,
        overflow: "hidden",
        textOverflow: "ellipsis",
        fontWeight: "bold"
      }}
    >
      {value === "DONE_WARN" ? "DONE" : value}
    </div>
  );
};

export const LabelWithWarningIcon = ({ label, tooltip = "" }) => (
  <>
    {label}
    <Tooltip title={tooltip}>
      <WarningIcon
        style={{ color: "orange", marginLeft: "4px", pointerEvents: "all" }}
      />
    </Tooltip>
  </>
);

export const checkMarkView = value => (
  <Done style={{ color: value ? "inherit" : "lightgray" }} />
);

export const BillStatus = status => {
  const styles = {
    ACTIVE: {
      backgroundColor: "#DFEFF6",
      color: "#2795C4"
    },
    SUBMITTED: {
      backgroundColor: "#E4F3E5",
      color: "#4CAF50"
    },
    FAILED: {
      backgroundColor: "#FEE3E1",
      color: "#F44336"
    },
    DO_NOT_BILL: {
      backgroundColor: "#EAEAEA",
      color: "#727272"
    }
  };

  const style = {
    padding: "4px 8px",
    fontWeight: 600,
    ...styles[status]
  };

  return (
    <div style={{ display: "flex" }}>
      <div style={style}>{status}</div>
    </div>
  );
};

export const TransferStatus = status => {
  const styles = {
    ACTIVE: {
      backgroundColor: "#DFEFF6",
      color: "#2795C4"
    },
    OPEN: {
      backgroundColor: "#FDEEDE",
      color: "#EF8A20"
    },
    CLOSED: {
      backgroundColor: "#E4F3E5",
      color: "#4CAF50"
    },
    DO_NOT_TRANSFER: {
      backgroundColor: "#EAEAEA",
      color: "#727272"
    },
    FAILED: {
      backgroundColor: "#FEE3E1",
      color: "#F44336"
    }
  };

  const style = {
    padding: "4px 8px",
    fontWeight: 600,
    ...styles[status]
  };

  return (
    <div style={{ display: "flex" }}>
      <div style={style}>{status}</div>
    </div>
  );
};

export const getPlanogramSectionStyles = size => {
  const height = 88;
  const marginBottom = 10;

  return {
    height: (height + marginBottom) * size - marginBottom,
    marginBottom
  };
};
