import { Grid } from "@material-ui/core";
import EditIcon from "@material-ui/icons/EditOutlined";
import PropTypes from "prop-types";

import { LinkIconButton, RemoveButton } from "srx-shared/mui";

import { ShipToTemplateItem } from "../ShipToTemplateItem";
import { ChangeStatus } from "./ChangeStatus";

export const to = id => `shipto-templates/${id}`;

Actions.propTypes = {
  row: PropTypes.object
};

export function Actions(props) {
  const { row } = props;
  const { id, templateName } = row;

  return (
    <Grid container>
      <LinkIconButton
        to={to(id)}
        icon={<EditIcon />}
        tip="Edit Template"
        data-testid="edit-template"
      />
      <ChangeStatus row={row} />
      <RemoveButton
        row={row}
        item={ShipToTemplateItem}
        tip="Template"
        modalProps={{ size: "sm" }}
        data-testid="shipto-template-remove"
      >
        Are you sure you want to delete template <b>{templateName}</b>?
      </RemoveButton>
    </Grid>
  );
}
