export const styles = theme => ({
  label: {
    paddingBottom: 2,
    fontSize: "14px",
    color: "#333"
  },
  groupLabel: {
    padding: "8px 0",
    fontSize: "0.75rem",
    color: "#555"
  },
  radio: {
    marginTop: 4,
    marginLeft: 8,
    padding: 4
  },
  checkbox: {
    padding: 5,
    marginLeft: 7
  },
  helperText: {
    minHeight: 18,
    fontSize: "0.75rem",
    color: theme.palette.primary.light
  },
  disabled: {
    color: "#bbb"
  },
  option: {
    paddingRight: 4
  },
  columnOption: {
    marginBottom: 0
  },
  optionHelperText: {
    marginLeft: 28
  }
});
