import React from "react";

import { Grid } from "@material-ui/core";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Selectors, ItemTypeApplication } from "srx-shared";
import { Message } from "srx-shared/mui";

function NoAssignedIotHubMessageComponent(props) {
  const { form, devices } = props;
  const { doorConfiguration } = form.values;
  const id = doorConfiguration?.hardware?.id;
  const device = devices?.find(el => Number(el.value) === Number(id));

  if (!device || device.iotHub) return null;

  return (
    <Grid item xs={12}>
      <Message variant="warning">
        Assigned device was not assigned to the IoTHub
      </Message>
    </Grid>
  );
}
NoAssignedIotHubMessageComponent.propTypes = {
  form: PropTypes.object,
  devices: PropTypes.array
};

const mapStateToProps = state => ({
  devices: Selectors.getItemData(state, ItemTypeApplication, "lockers")
});

export const NoAssignedIotHubMessage = connect(mapStateToProps)(
  NoAssignedIotHubMessageComponent
);
