import { Button, DialogActions } from "@material-ui/core";
import PropTypes from "prop-types";

import { ActionsSpinner } from "srx-shared/mui";

import { useStyles } from "./styles";

ActionsFooter.propTypes = {
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  updating: PropTypes.bool
};

export function ActionsFooter(props) {
  const { updating, onCancel, onSave } = props;
  const cls = useStyles();
  return (
    <div className={cls.footer}>
      <DialogActions className={cls.actions}>
        <ActionsSpinner processing={updating} />
        <Button variant="contained" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={onSave}
          disabled={updating}
        >
          Save
        </Button>
      </DialogActions>
    </div>
  );
}
