import React from "react";

import {
  withPageTitle,
  tableFor,
  filtersFor,
  editFormFor,
  editModalFor,
  createFormFor,
  actionsFor,
  importBlock,
  breadcrumbsFor
} from "srx-shared";
import { ValidateImportDialog } from "srx-shared/mui";

import { UpcGtinCatalogItem } from "@items/UpcGtinCatalog";
import { pages } from "@routes/pages";

const UpcGtinCatalogTable = tableFor(UpcGtinCatalogItem);
const UpcGtinCatalogFilters = filtersFor(UpcGtinCatalogItem);
const UpcGtinCatalogCreateForm = createFormFor(UpcGtinCatalogItem, {
  title: "Add GTIN & UPC",
  size: "md"
});
const UpcGtinCatalogImport = importBlock(
  UpcGtinCatalogItem,
  ValidateImportDialog
);
const UpcGtinCatalogActions = actionsFor(UpcGtinCatalogItem, {
  CreateForm: UpcGtinCatalogCreateForm,
  ImportBlock: UpcGtinCatalogImport
});
const UpcGtinCatalogEditForm = editFormFor(UpcGtinCatalogItem);
const UpcGtinCatalogEditModal = editModalFor(UpcGtinCatalogItem, {
  EditForm: UpcGtinCatalogEditForm
});
const Breadcrumbs = breadcrumbsFor();

const { title } = pages.upcGtinCatalog;

const UpcGtinCatalogComponent = () => {
  const renderFilters = table => <UpcGtinCatalogFilters table={table} />;
  const renderActions = table => <UpcGtinCatalogActions table={table} />;

  return (
    <>
      <Breadcrumbs renderList={() => [{ title }]} />
      <UpcGtinCatalogTable
        renderFilters={renderFilters}
        renderActions={renderActions}
      />
      <UpcGtinCatalogEditModal />
    </>
  );
};

export default withPageTitle(title)(UpcGtinCatalogComponent);
