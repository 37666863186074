import { List } from "@material-ui/core";
import PropTypes from "prop-types";

import { SideBarItem } from "../SideBarItem";
import { SupportButton } from "../SupportButton";
import { SkeletonList } from "./SkeletonList";
import { useStyles } from "./styles";

SideBarNav.propTypes = {
  items: PropTypes.array,
  signOut: PropTypes.object,
  support: PropTypes.object,
  open: PropTypes.bool,
  loading: PropTypes.bool,
  onClick: PropTypes.func
};

export function SideBarNav(props) {
  const { items, loading, signOut, support, open, onClick } = props;
  const { clsNavOpen, clsNavClosed } = useStyles();
  const clsList = open ? clsNavOpen : clsNavClosed;

  return (
    <List className={clsList}>
      {loading && <SkeletonList />}
      {!loading &&
        items &&
        items.map((item, index) => (
          <SideBarItem
            key={index}
            open={open}
            id={item.id || item.acl}
            onClick={onClick}
            {...item}
          />
        ))}
      {support && <SupportButton support={support} open={open} />}
      {signOut && <SideBarItem id="sign_out" open={open} {...signOut} />}
    </List>
  );
}
