import { useCallback, useEffect, useState } from "react";

import { Button, Grid } from "@material-ui/core";
import { PubSub } from "aws-amplify";
import { DckActionCreators, procRunning, procSuccess } from "dck-redux";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import {
  breadcrumbsFor,
  makeSequence,
  Selectors,
  useDispatchActions,
  usePageTitle,
  NotificationCreators,
  usePrevious
} from "srx-shared";

import { ActionCreators } from "@actions";
import { Hardware, AttachPolicy } from "@items/types";
import { pages } from "@routes/pages";
import { STAGE } from "config";

import { DeviceSection } from "./DeviceSection";

const actions = {
  loadVending: id => DckActionCreators.itemSelect(Hardware, id),
  setActiveVending: id => DckActionCreators.itemMakeActive(Hardware, id),
  attachPolicy: value =>
    DckActionCreators.itemAdd(AttachPolicy, { deviceId: value }),
  showErrorMessage: error =>
    ActionCreators.pushNotification(NotificationCreators.errorMessage(error))
};

const status = { white: "#fff", green: "#90ee907a", orange: "#ffa5007a" };

const sectionIds = makeSequence(6);

const defaultData = makeSequence(60).reduce(
  (data, current) =>
    Object.assign(data, {
      [current]: { currentCount: 0, color: status.white }
    }),
  {}
);

const BreadcrumbsComponent = breadcrumbsFor();

const getSectionCellCounters = (data, sectionId) => {
  const resultObj = {};
  const cellArrayIds = makeSequence(10);
  cellArrayIds.forEach(
    cellId => (resultObj[cellId] = data[cellId + 10 * (sectionId - 1)])
  );
  return resultObj;
};

export default function VendingConfiguration() {
  usePageTitle("Planogram");

  const { loadVending, setActiveVending, attachPolicy, showErrorMessage } =
    useDispatchActions(actions);

  const { id } = useParams();

  const vending = useSelector(state =>
    Selectors.selectActiveItem(state, Hardware)
  );

  const success = useSelector(state => procSuccess(state, AttachPolicy, "add"));
  const running = useSelector(state => procRunning(state, AttachPolicy, "add"));

  const prevRunning = usePrevious(running);

  const [counterData, setCounterData] = useState(defaultData);
  const [isAttachPolicy, setIsAttachPolicy] = useState(false);

  const handleNext = useCallback(
    data => {
      const { value } = data;
      if (value.data) {
        showErrorMessage(value.data.description);
        return;
      }
      setCounterData(prevData => {
        const cellNumber = value.cell;
        const currentCount =
          prevData[cellNumber].currentCount + value.count.issued;
        const color = value.count.issued ? status.green : status.orange;
        return {
          ...prevData,
          [cellNumber]: { currentCount, color }
        };
      });
    },
    [showErrorMessage]
  );

  const handleError = useCallback(
    () => showErrorMessage("Failed connection"),
    [showErrorMessage]
  );

  useEffect(() => {
    if (prevRunning && !running && success) {
      setIsAttachPolicy(true);
    }
  }, [prevRunning, running, success]);

  useEffect(() => {
    if (isAttachPolicy) {
      const sub = PubSub.subscribe(`iothub/${STAGE}/${vending.value}/events`, {
        provider: "AWSIoTProvider"
      }).subscribe({
        next: handleNext,
        error: handleError
      });
      return () => sub.unsubscribe();
    }
  }, [vending, isAttachPolicy, handleNext, handleError]);

  useEffect(() => {
    vending && attachPolicy(vending.value);
  }, [vending, attachPolicy]);

  useEffect(() => {
    loadVending(id);
    setActiveVending(id);
    return () => {
      setActiveVending(null);
    };
  }, [loadVending, setActiveVending, id]);

  const breadcrumbsList = () => [
    { title: pages.hardware.title, to: pages.hardware.path },
    { title: "Planogram" },
    { title: vending ? vending.value : "" }
  ];

  const handleReset = () => setCounterData(defaultData);

  if (!vending || !vending.iotHub) return null;

  return (
    <>
      <BreadcrumbsComponent renderList={breadcrumbsList} />
      <Grid container justify="center" style={{ userSelect: "none" }}>
        <Grid style={{ maxWidth: "100%" }}>
          {sectionIds.map(id => (
            <DeviceSection
              sectionCounters={getSectionCellCounters(counterData, id)}
              key={`row-${id}`}
              id={id}
            />
          ))}
          <Button
            color="primary"
            variant="contained"
            onClick={handleReset}
            style={{ width: "100%", marginTop: 10 }}
          >
            RESET
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
