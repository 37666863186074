import React from "react";

import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";

import { styles } from "./styles";

class SwitchComponent extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    style: PropTypes.object,
    label: PropTypes.node,
    helperText: PropTypes.string,
    noHelperText: PropTypes.bool,
    value: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func
  };

  handleChange = (e, value) => {
    const { onChange } = this.props;
    onChange && onChange(value, e);
  };

  render() {
    const {
      classes,
      style,
      value,
      label = null,
      disabled,
      helperText = "",
      noHelperText = false
    } = this.props;

    return (
      <div className={classes.root} style={style}>
        <FormControl component="fieldset" className={classes.formControl}>
          <FormControlLabel
            classes={{ label: classes.label }}
            control={
              <Switch
                color="primary"
                size="small"
                value={value}
                checked={value || false}
                className={classes.switch}
                onChange={this.handleChange}
              />
            }
            label={label}
            disabled={disabled}
          />
        </FormControl>
        {!noHelperText && (
          <div className={classes.helperText}>{helperText}</div>
        )}
      </div>
    );
  }
}

export const SwitchField = withStyles(styles)(SwitchComponent);
