import { Button, IconButton } from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import PropTypes from "prop-types";

import { useMobile } from "../../../../hooks";
import { useStyles } from "./styles";

InfoButton.propTypes = {
  url: PropTypes.string,
  title: PropTypes.string
};

export function InfoButton({ url, title }) {
  const cls = useStyles();

  const isMobile = useMobile();

  const handleClick = e => {
    e.stopPropagation();
  };

  const commonProps = {
    classes: { root: cls.button },
    onClick: handleClick,
    href: url,
    target: "_blank"
  };

  const buttonTitle = title ? title : "Learn more";

  return isMobile ? (
    <IconButton {...commonProps}>
      <InfoOutlinedIcon className={cls.icon} />
    </IconButton>
  ) : (
    <Button
      {...commonProps}
      startIcon={<InfoOutlinedIcon className={cls.icon} />}
    >
      {buttonTitle}
    </Button>
  );
}
