import React from "react";

import MuiIconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

import { styles } from "./styles";

class IconButtonComponent extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    icon: PropTypes.node.isRequired,
    tip: PropTypes.string,
    tipPlacement: PropTypes.string,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    style: PropTypes.object,
    "data-testid": PropTypes.string
  };

  static defaultProps = {
    tip: "",
    tipPlacement: "top",
    onClick: () => {}
  };

  render() {
    const { classes, tip, tipPlacement, icon, onClick, disabled, ...rest } =
      this.props;

    const testId = `${this.props["data-testid"] || "icon"}-button`;

    const button = (
      <MuiIconButton
        {...rest}
        data-testid={testId}
        classes={{ root: classes.root }}
        onClick={onClick}
        disabled={disabled}
      >
        {icon}
      </MuiIconButton>
    );
    return tip ? (
      <Tooltip title={tip} placement={tipPlacement}>
        <span>{button}</span>
      </Tooltip>
    ) : (
      button
    );
  }
}

export const IconButton = withStyles(styles)(IconButtonComponent);
