import React, { useEffect } from "react";

import { Grid } from "@material-ui/core";
import { DckSelectors, DckActionCreators } from "dck-redux";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { generalFormFor, usePrevious } from "srx-shared";
import { ActionsSpinner, FormButton, ModalDialog } from "srx-shared/mui";

import { SmartShelfItem } from "./SmartShelfItem";

const SmartShelfCreateForm = generalFormFor(
  SmartShelfItem,
  SmartShelfItem.createForm
);

const SmartShelfCreateFormComponent = props => {
  const { onClose, addingSuccess, addItem, adding, open } = props;

  const prevAdding = usePrevious(adding);

  useEffect(() => {
    if (prevAdding && !adding && addingSuccess && onClose) {
      onClose();
    }
  }, [onClose, adding, addingSuccess, prevAdding]);

  const renderActions = (form, formikProps) => {
    const { dirty } = formikProps;
    return (
      <Grid container justify="flex-end" style={{ padding: "20px 12px 0" }}>
        <ActionsSpinner processing={adding} />
        <FormButton variant="reset" disabled={!dirty || adding} />
        <FormButton variant="cancel" onClick={onClose} />
        <FormButton variant="add" disabled={adding} />
      </Grid>
    );
  };

  return (
    <ModalDialog
      open={Boolean(open)}
      onClose={onClose}
      title="Add Smart Shelf"
      size="md"
    >
      <div style={{ padding: "0 6px 16px" }}>
        <SmartShelfCreateForm
          renderActions={renderActions}
          initialData={SmartShelfItem.createForm.initialValues}
          onSubmit={addItem}
          processing={adding}
        />
      </div>
    </ModalDialog>
  );
};

SmartShelfCreateFormComponent.propTypes = {
  adding: PropTypes.bool,
  addingSuccess: PropTypes.bool,
  addItem: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool
};

const process = `${SmartShelfItem.type.toUpperCase()}_ADD`;

const mapStateToProps = state => ({
  adding: DckSelectors.selectProcessRunning(state, process),
  addingSuccess: DckSelectors.selectProcessSuccess(state, process)
});

const dispatchStateToProps = {
  addItem: data => DckActionCreators.itemAdd(SmartShelfItem.type, data)
};

export default connect(
  mapStateToProps,
  dispatchStateToProps
)(SmartShelfCreateFormComponent);
