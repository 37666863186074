/* eslint react/display-name: "off" */
/* eslint react/prop-types: "off" */
import * as V from "yup";

import { mapFormFieldsFor } from "srx-shared";

import { Camera } from "@items/types";

export const CameraConfigItem = {
  type: Camera,
  name: "Camera",
  title: "Camera"
};

// form fields
CameraConfigItem.fieldsConfig = {
  ip: {
    name: "ip",
    label: "Camera",
    required: true,
    type: "select",
    dict: "cameraDict",
    size: 12
  }
};

CameraConfigItem.schema = V.object({
  ip: V.string().label("Camera").required()
});

CameraConfigItem.generalForm = mapFormFieldsFor(CameraConfigItem, ["ip"]);
