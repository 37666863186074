import { TextField, Grid } from "@material-ui/core";
import PropTypes from "prop-types";

import { useStyles } from "./styles";

CustomTextField.propTypes = {
  field: PropTypes.object,
  fieldConfig: PropTypes.object
};

export function CustomTextField(props) {
  const { field, fieldConfig } = props;

  const cls = useStyles();

  return (
    <Grid item sm={fieldConfig.size || 6} xs={12}>
      <TextField
        value={field.value ?? ""}
        label={fieldConfig.label}
        fullWidth
        InputLabelProps={{
          classes: {
            root: cls.label
          }
        }}
        InputProps={{
          classes: {
            root: cls.input
          }
        }}
        inputProps={{
          disabled: true
        }}
      />
    </Grid>
  );
}
