import React from "react";

import { InputAdornment } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

import { styles } from "./styles";

class InlineInputComponent extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    value: PropTypes.any,
    fieldConfig: PropTypes.object,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onReset: PropTypes.func,
    onBlur: PropTypes.func
  };

  inputRef = React.createRef();

  componentDidMount() {
    const node = this.inputRef.current;
    node && node.focus();
    this.handleFocus();
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = e => {
    if (this.inputRef && !this.inputRef.current.contains(e.target)) {
      this.handleBlur();
    }
  };

  handleChange = e => {
    const { onChange, fieldConfig } = this.props;
    const { value } = e.target;
    const { inlineValidation } = fieldConfig;

    //FIXME: !!! DO NOT DISABLE CHANGE BASED ON VALIDATION !!!

    const allowedChange = Boolean(
      onChange &&
        (typeof inlineValidation === "function"
          ? inlineValidation(value)
          : true)
    );
    allowedChange && onChange(value);
  };

  handleKeyDown = e => {
    e.stopPropagation();
    e.keyCode === 27 && this.handleReset(); // reset on Esc
    e.keyCode === 13 && this.handleBlur(); // blur on Enter
  };

  handleFocus = () => this.props.onFocus && this.props.onFocus();
  handleReset = () => this.props.onReset && this.props.onReset();
  handleBlur = () => this.props.onBlur && this.props.onBlur();

  render() {
    const { classes, value, fieldConfig } = this.props;

    return (
      <>
        <input
          ref={this.inputRef}
          className={classes.inlineInput}
          value={value === null ? "" : value}
          onChange={this.handleChange}
          onKeyDown={this.handleKeyDown}
        />
        {fieldConfig.inputSuffix && (
          <InputAdornment position="end" style={{ marginLeft: 0 }}>
            {fieldConfig.inputSuffix}
          </InputAdornment>
        )}
      </>
    );
  }
}

export const InlineInput = withStyles(styles)(InlineInputComponent);
