import React from "react";

import {
  CssBaseline,
  AppBar,
  Toolbar,
  IconButton,
  withWidth
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import cn from "classnames";
import PropTypes from "prop-types";
import { compose } from "redux";

import { stub, isMobile } from "../../utils";
import { LoadingBar } from "../LoadingBar";
import { styles } from "./styles";

class AppLayoutComponent extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    withMessageBar: PropTypes.bool,
    renderMessageBar: PropTypes.func,
    renderAppBar: PropTypes.func,
    renderPageBar: PropTypes.func,
    renderSideBar: PropTypes.func
  };

  static defaultProps = {
    withMessageBar: false,
    renderMessageBar: stub,
    renderAppBar: stub,
    renderPageBar: stub,
    renderSideBar: stub
  };

  constructor(props) {
    super(props);
    this.state = {
      open: !isMobile(this)
    };
  }

  toggleSideBar = () => this.setState({ open: !this.state.open });

  render() {
    const {
      classes,
      withMessageBar,
      renderMessageBar,
      renderAppBar,
      renderPageBar,
      renderSideBar,
      children
    } = this.props;

    const mobile = isMobile(this);
    const open = this.state.open && !mobile;

    return (
      <>
        <CssBaseline />
        <LoadingBar />
        {withMessageBar && (
          <div className={classes.messageBarContainer}>
            {renderMessageBar(this)}
          </div>
        )}
        <AppBar
          className={cn(
            mobile ? classes.appBarMobile : classes.appBar,
            open && classes.appBarShift,
            !mobile && !open && classes.appBarClosed,
            withMessageBar && classes.appBarTopShift
          )}
        >
          <Toolbar disableGutters={true} className={classes.toolBar}>
            <IconButton
              color="inherit"
              onClick={this.toggleSideBar}
              className={classes.menuButton}
              data-testid="menu-button"
            >
              <MenuIcon />
            </IconButton>
            {renderAppBar(this)}
          </Toolbar>
        </AppBar>
        <div
          className={cn(
            mobile ? classes.pageBarMobile : classes.pageBar,
            open && classes.pageBarShift,
            withMessageBar && classes.pageBarTopShift
          )}
        >
          {renderPageBar(this)}
        </div>
        {renderSideBar(this)}
        <main
          className={cn(
            mobile ? classes.contentMobile : classes.content,
            open && classes.contentShift,
            withMessageBar && classes.contentTopShift
          )}
        >
          {children}
        </main>
      </>
    );
  }
}

export const AppLayout = compose(
  withStyles(styles),
  withWidth()
)(AppLayoutComponent);
