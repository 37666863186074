import _set from "lodash/set";
import { takeLatest, all, put } from "redux-saga/effects";

import {
  Sagas,
  deepMerge,
  Process,
  isActionLoad,
  ItemTypeApplication
} from "srx-shared";

import { ActionTypes, ActionCreators } from "@actions";
import * as RestApi from "@api";
import { ShiptoFee } from "@items/types";

export function* loadShiptoFeesSaga() {
  const proc = new Process.Load(ShiptoFee, RestApi.GetShiptoFees);
  yield proc.start();
  try {
    yield proc.callApi();
    const data = [...(proc.data || [])].sort((a, b) => a.id - b.id);
    yield proc.set(data);

    const dict = data.map(el => ({ label: el.name, value: el.id }));
    yield put(
      ActionCreators.setItemData(ItemTypeApplication, "shipToFeeLevels", dict)
    );
    yield proc.stop();
  } catch (ex) {
    yield proc.fail(ex);
  }
}

function* updateShiptoFeeSaga(action) {
  const proc = new Process(
    ActionTypes.INLINE_UPDATE,
    ShiptoFee,
    RestApi.UpdateShiptoFees
  );

  yield proc.start();
  try {
    const { data } = action;
    const row = deepMerge({}, data.row);
    const { id } = row;

    _set(row, data.selectedCell, data.value);

    yield proc.callApi(id, row);
    yield Sagas.updateDckItem(ShiptoFee, id, row);
    yield put(
      ActionCreators.undoPush(ShiptoFee, {
        ...data,
        row: deepMerge({}, data.original)
      })
    );
    yield proc.stop();
  } catch (ex) {
    yield proc.fail(ex);
  }
}

// undo
export function* undoUpdateShiptoFeeSaga(action) {
  const proc = new Process(
    ActionTypes.UNDO_ACTION,
    ShiptoFee,
    RestApi.UpdateShiptoFees
  );
  yield proc.start();
  try {
    const {
      data: { row }
    } = action;
    const { id } = row;

    yield proc.callApi(id, row);
    yield Sagas.updateDckItem(ShiptoFee, id, row);
    yield put(ActionCreators.undoPop(ShiptoFee));
    yield proc.stop();
  } catch (ex) {
    yield proc.fail(ex);
  }
}

function* baseFeesSaga() {
  yield all([
    takeLatest(isActionLoad(ShiptoFee), loadShiptoFeesSaga),
    takeLatest(
      action =>
        action.type === ActionTypes.INLINE_UPDATE &&
        action.itemType === ShiptoFee,
      updateShiptoFeeSaga
    ),
    takeLatest(
      action =>
        action.type === ActionTypes.UNDO_ACTION &&
        action.itemType === ShiptoFee,
      undoUpdateShiptoFeeSaga
    )
  ]);
}

export default baseFeesSaga;
