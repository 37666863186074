import React from "react";

import { Grid } from "@material-ui/core";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  withStyles
} from "@material-ui/core";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";

import {
  getPropTypesForDict,
  stateToPropsForDict
} from "../../../actions/helpers";
import * as Icons from "../../../styles/icons";
import { styles } from "./styles";

class CheckDictComponent extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    name: PropTypes.string,
    label: PropTypes.string,
    helperText: PropTypes.string,
    dict: PropTypes.array,
    value: PropTypes.array,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    column: PropTypes.bool,
    noHelperText: PropTypes.bool,
    selector: PropTypes.string
  };

  static propTypes = {
    ...getPropTypesForDict()
  };

  state = {
    options: this.props.dict
      ? this.props[this.props.dict] || []
      : [...this.props.options]
  };

  componentDidUpdate(prevProps) {
    const { dict } = this.props;
    if (JSON.stringify(prevProps[dict]) !== JSON.stringify(this.props[dict]))
      this.setState({ options: this.props[dict] });
  }

  handleChange = (value, checked) => {
    const { onChange, selector } = this.props;
    const prevValues = this.props.value || [];
    const values = checked
      ? [...prevValues, selector ? { [selector]: value } : value]
      : prevValues.filter(el =>
          selector ? el[selector] !== value : el !== value
        );

    onChange && onChange(values);
  };

  isChecked = option => {
    const { value, selector } = this.props;
    return selector
      ? (value || []).some(el => el[selector] === option.value)
      : (value || []).includes(option.value);
  };

  renderEmptyListMessage = (
    <Grid
      container
      justify="center"
      alignItems="center"
      className={this.props.classes.message}
    >
      <Icons.EmptyList className={this.props.classes.emptyIcon} /> The list is
      empty
    </Grid>
  );

  render() {
    const {
      classes,
      label = "",
      disabled,
      helperText,
      error,
      column,
      noHelperText
    } = this.props;
    const { options } = this.state;

    return (
      <FormControl component="fieldset" className={classes.formControl}>
        {
          <div
            className={classes.label}
            style={{ color: error ? "#F44336" : "gray" }}
          >
            {label}
          </div>
        }
        {options.length ? (
          <FormGroup
            className={column ? classes.verticalGroup : classes.horizontalGroup}
          >
            {options.map((option, index) => (
              <FormControlLabel
                key={index}
                className={classes.option}
                control={
                  <Checkbox
                    color="primary"
                    data-testid={option.value}
                    className={classes.checkbox}
                    checked={this.isChecked(option)}
                    onChange={(e, checked) =>
                      this.handleChange(option.value, checked)
                    }
                  />
                }
                label={option.label}
                disabled={disabled || option.disabled}
              />
            ))}
          </FormGroup>
        ) : (
          this.renderEmptyListMessage
        )}
        {!noHelperText && (
          <FormHelperText
            className={classes.helperText}
            style={{ color: error ? "#F44336" : "gray" }}
            margin="dense"
            filled={true}
          >
            {helperText}
          </FormHelperText>
        )}
      </FormControl>
    );
  }
}

export const CheckDictField = compose(
  withStyles(styles),
  connect(state => stateToPropsForDict(state))
)(CheckDictComponent);
