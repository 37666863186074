/* eslint jsx-a11y/anchor-is-valid: "off" */
import React from "react";

import { Button, Menu, MenuItem, withWidth } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import PropTypes from "prop-types";
import { compose } from "redux";

import { isMobile } from "../../../utils";
import { styles } from "./styles";

const styleExpand = { color: "#999", height: 16, width: 16 };

class PageSizeSelector extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    pageSize: PropTypes.number,
    pageSizeOptions: PropTypes.array,
    onPageSizeChange: PropTypes.func
  };
  static defaultProps = {
    pageSizeOptions: [10, 25, 50, 100]
  };

  state = {
    anchorEl: null
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = size => {
    this.setState({ anchorEl: null });
    if (size) this.props.onPageSizeChange(size);
  };

  render() {
    const { classes, pageSize, pageSizeOptions } = this.props;
    const { anchorEl } = this.state;

    return (
      <>
        {!isMobile(this) && (
          <div className={classes.rowsPerPage}>rows per page</div>
        )}
        <Button
          aria-owns={anchorEl ? "rows-per-page-selector" : undefined}
          aria-haspopup="true"
          onClick={this.handleClick}
          classes={{ root: classes.pageButton }}
        >
          <span>{pageSize}</span>
          {anchorEl ? (
            <ExpandLess style={styleExpand} />
          ) : (
            <ExpandMore style={styleExpand} />
          )}
        </Button>
        <Menu
          id="rows-per-page-selector"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "right"
          }}
          open={Boolean(anchorEl)}
          onClose={() => this.handleClose(0)}
        >
          {pageSizeOptions.map(size => (
            <MenuItem
              key={size}
              selected={Number(size) === Number(pageSize)}
              onClick={() => this.handleClose(size)}
              dense={true}
            >
              {size}
            </MenuItem>
          ))}
        </Menu>
      </>
    );
  }
}

export default compose(withStyles(styles), withWidth())(PageSizeSelector);
