import React from "react";

import { Grid } from "@material-ui/core";

import {
  tableFor,
  breadcrumbsFor,
  undoActionFor,
  actionsFor
} from "srx-shared";

import { ShiptoFeeItem } from "./ShiptoFeeItem";

const ShiptoFeesTable = tableFor(ShiptoFeeItem, {
  sortable: false,
  pageble: false
});
const UndoAction = undoActionFor(ShiptoFeeItem);
const Breadcrumbs = breadcrumbsFor();

const ShiptoFeesActions = actionsFor(ShiptoFeeItem, {
  Custom: UndoAction
});

export const ShiptoFees = () => {
  const renderActions = table => <ShiptoFeesActions table={table} />;
  return (
    <Grid container justify="center">
      <Grid item style={{ width: 500, height: "100%" }}>
        <Breadcrumbs
          renderList={() => [{ title: "Fees" }, { title: "ShipTo Fees" }]}
        />
        <ShiptoFeesTable renderActions={renderActions} />
      </Grid>
    </Grid>
  );
};
