/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";

import { DckActionCreators } from "dck-redux";
import { useParams } from "react-router-dom";

import {
  actionsFor,
  breadcrumbsFor,
  exportBlock,
  filtersFor,
  tableFor,
  useDispatchActions,
  usePageTitle
} from "srx-shared";

import { Refresh } from "@comp/Hardware/WeightHistory/Actions/Refresh";
import { WeightHistory } from "@redux/items/types";

import { TargetWeightHistoryItem } from "./targetWeightHistoryItem";

const actions = {
  clearFilters: () => DckActionCreators.clearItemSearchFilters(WeightHistory),
  setParams: (field, data) =>
    DckActionCreators.setItemData(WeightHistory, field, data)
};

const TargetWeightHistoryTable = tableFor(TargetWeightHistoryItem);
const TargetWeightHistoryFilters = filtersFor(TargetWeightHistoryItem);
const WeightHistoryExport = exportBlock(TargetWeightHistoryItem);

const BreadcrumbsComponent = breadcrumbsFor();

const TargetWeightHistoryActions = actionsFor(TargetWeightHistoryItem, {
  Custom: () => (
    <>
      <WeightHistoryExport />
      <Refresh />
    </>
  )
});

const renderFilters = table => (
  <div style={{ marginTop: 16 }}>
    <TargetWeightHistoryFilters table={table} />
  </div>
);

const renderActions = table => <TargetWeightHistoryActions table={table} />;

export default function TargetWeightHistory() {
  usePageTitle("Weight History");
  const { deviceSerialNumber, smartShelfId, cellIndex } = useParams();

  const { clearFilters, setParams } = useDispatchActions(actions);

  useEffect(() => {
    return () => {
      clearFilters();
      setParams("deviceSerialNumber", null);
      setParams("smartShelfId", null);
      setParams("cellIndex", null);
    };
  }, [clearFilters, setParams]);

  useEffect(() => {
    setParams("deviceSerialNumber", deviceSerialNumber);
    setParams("smartShelfId", smartShelfId);
    setParams("cellIndex", cellIndex);
  }, []);

  return (
    <>
      <BreadcrumbsComponent renderList={() => [{ title: "Weight History" }]} />
      <TargetWeightHistoryTable
        renderFilters={renderFilters}
        renderActions={renderActions}
      />
    </>
  );
}
