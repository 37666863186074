import React from "react";

import PropTypes from "prop-types";

import SelectMaterial from "./SelectMaterial";
import { theme } from "./styles";

export class Select extends React.PureComponent {
  static propTypes = {
    isMulti: PropTypes.bool,
    async: PropTypes.bool,
    defaultOptions: PropTypes.array,
    loadOptions: PropTypes.func,
    options: PropTypes.array,
    autoComplete: PropTypes.any,
    autoFocus: PropTypes.any,
    children: PropTypes.any,
    className: PropTypes.string,
    defaultValue: PropTypes.any,
    disabled: PropTypes.bool,
    error: PropTypes.any,
    FormHelperTextProps: PropTypes.object,
    fullWidth: PropTypes.any,
    helperText: PropTypes.string,
    id: PropTypes.any,
    InputLabelProps: PropTypes.object,
    inputRef: PropTypes.any,
    label: PropTypes.any,
    multiline: PropTypes.any,
    name: PropTypes.any,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    placeholder: PropTypes.any,
    required: PropTypes.bool,
    rows: PropTypes.any,
    rowsMax: PropTypes.any,
    select: PropTypes.any,
    SelectProps: PropTypes.object,
    type: PropTypes.any,
    value: PropTypes.any
  };

  render() {
    return (
      <SelectMaterial
        {...this.props}
        SelectProps={{
          theme,
          ...this.props.SelectProps,
          backspaceRemovesValue: false
        }}
      />
    );
  }
}
