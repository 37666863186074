import React from "react";

import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

import { styles } from "./styles";

class AppPageTitleComponent extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    title: PropTypes.any
  };
  render() {
    const { classes, title } = this.props;
    return title ? (
      <span className={classes.title}>
        <div>{title}</div>
      </span>
    ) : (
      "..."
    );
  }
}

export const AppPageTitle = withStyles(styles)(AppPageTitleComponent);
