/* eslint react/display-name: "off" */
/* eslint react/prop-types: "off" */

import { Grid } from "@material-ui/core";
import * as V from "yup";

import {
  Formatters,
  mapFiltersFor,
  mapFormFieldsFor,
  Dictionaries
} from "srx-shared";
import { Cell, GroupBox, LinkCell } from "srx-shared/mui";

import { Actions } from "@comp/Distributors/Actions";
import { Distributor } from "@items/types";
import { getAvoidDotsError } from "@utils";

import { SelectState } from "./SelectState";

V.addMethod(V.number, "avoidDots", function () {
  return this.transform(getAvoidDotsError);
});

export const DistributorItem = {
  type: Distributor,
  name: "Distributor",
  title: "Distributor"
};

export const DISTRIBUTOR_TYPES = {
  SRX: "SRX",
  NON_SRX: "External Supplier",
  CUSTOMER_OWNED: "Customer Owned"
};

const Status = value => {
  const style = {
    color:
      value === "INACTIVE" ? "brown" : value === "ACTIVE" ? "green" : "gray"
  };
  return <span style={style}>{value}</span>;
};

const CheckBox = value => <Cell center>{value ? "\u2713" : ""}</Cell>;

const StyleRow = props => {
  const { row, children } = props;
  const style = {
    display: "flex",
    width: "100%",
    height: "100%",
    color: row.status === "INACTIVE" ? "#999" : "#555"
  };
  return <div style={style}>{children}</div>;
};

const stepStyle = {
  padding: "2px 5px",
  fontSize: "small",
  backgroundColor: "#e8e8e8",
  borderRadius: "5px",
  marginRight: "5px"
};

function ActivationSteps({ row }) {
  const { userData, agreements, taxes, level, creditCardInfo } = row._original;

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        padding: "0 10px"
      }}
    >
      {userData && <div style={stepStyle}>User Data</div>}
      {agreements && <div style={stepStyle}>Agreements</div>}
      {taxes && <div style={stepStyle}>Taxes</div>}
      {level && <div style={stepStyle}>Level</div>}
      {creditCardInfo && <div style={stepStyle}>Billing Info</div>}
    </div>
  );
}

const formatCell = ({ row, value }, formatter) => (
  <StyleRow row={row._original}>
    <LinkCell rel={row._original.id}>
      {formatter ? formatter(value) : value}
    </LinkCell>
  </StyleRow>
);

// table
DistributorItem.columns = [
  {
    Header: "SRX number",
    accessor: "id",
    maxWidth: 100,
    Cell: props => formatCell(props)
  },
  {
    Header: "External Distributor Number",
    accessor: "externalDistributorNumber",
    Cell: props => formatCell(props)
  },
  {
    Header: "Name",
    accessor: "name",
    Cell: props => formatCell(props)
  },
  {
    Header: "Invoice Email",
    accessor: "invoiceEmail",
    Cell: props => formatCell(props)
  },
  {
    Header: "Primary Address",
    accessor: "address",
    sortable: false,
    minWidth: 150,
    Cell: props => formatCell(props, Formatters.address)
  },
  {
    Header: "Universal Catalog",
    accessor: "universalCatalog",
    sortable: false,
    minWidth: 150,
    Cell: props => formatCell(props, CheckBox)
  },
  {
    Header: "Process.Fee",
    accessor: "processingFee",
    sortable: false,
    maxWidth: 95,
    Cell: props => formatCell(props, CheckBox)
  },
  {
    Header: "SupplyForce",
    accessor: "supplyForce",
    sortable: false,
    maxWidth: 100,
    Cell: props => formatCell(props, CheckBox)
  },
  {
    Header: "Activation Steps",
    sortable: false,
    maxWidth: 85,
    Cell: ActivationSteps
  },
  {
    Header: "Non-SRX Distributor",
    accessor: "virtual",
    sortable: false,
    maxWidth: 150,
    Cell: props => formatCell(props, CheckBox)
  },
  {
    Header: "Billing Left",
    accessor: "billingLeftDays",
    sortable: false,
    maxWidth: 90,
    Cell: props => formatCell(props)
  },
  {
    Header: "Bill by all Ship-tos",
    accessor: "billAllShipTo",
    sortable: false,
    maxWidth: 130,
    Cell: props => formatCell(props, CheckBox)
  },
  {
    Header: "Country",
    accessor: "country",
    Cell: props => formatCell(props)
  },
  {
    Header: "Billing Level",
    accessor: "shipToFee.name",
    sortable: false,
    maxWidth: 90,
    Cell: props => formatCell(props)
  },
  {
    Header: "Last Successful Invoice Date",
    accessor: "lastInvoiceDate",
    sortable: false,
    maxWidth: 150,
    Cell: props => formatCell(props, Formatters.datetime)
  },
  {
    Header: "Account",
    accessor: "accountStatus",
    maxWidth: 75,
    Cell: props => formatCell(props)
  },
  {
    Header: "Status",
    accessor: "status",
    maxWidth: 85,
    Cell: props => formatCell(props, Status)
  },
  {
    Header: "Actions",
    width: 140,
    resizable: false,
    sortable: false,
    Cell: ({ row }) => <Actions row={row._original} />
  }
];

// layouts
DistributorItem.headers = DistributorItem.columns
  .filter(col => !col.isAction)
  .map(col => ({
    title: col.title || col.Header,
    accessor: col.accessor,
    mandatory: col.mandatory
  }));

DistributorItem.layouts = [
  {
    name: "layout1",
    title: "Layout 1",
    hiddenColumns: [],
    resized: []
  },
  {
    name: "layout2",
    title: "Layout 2",
    hiddenColumns: [],
    resized: []
  },
  {
    name: "layout3",
    title: "Layout 3",
    hiddenColumns: [],
    resized: []
  }
];

// filters
DistributorItem.filtersConfig = {
  name: {
    name: "Name"
  },
  invoiceEmail: {
    name: "Invoice Email"
  },
  universalCatalog: {
    name: "Universal Catalog",
    type: "select",
    options: [
      { label: "USED", value: true },
      { label: "NOT USED", value: false }
    ]
  },
  country: {
    name: "Country",
    type: "select",
    options: Dictionaries.COUNTRIES
  },
  status: {
    name: "Status",
    type: "select",
    options: [
      { label: "ACTIVE", value: "ACTIVE" },
      { label: "INACTIVE", value: "INACTIVE" }
    ]
  },
  distributorType: {
    name: "Type",
    type: "select",
    options: [
      { label: "SRX Distributor", value: DISTRIBUTOR_TYPES.SRX },
      { label: "External Supplier", value: DISTRIBUTOR_TYPES.NON_SRX },
      { label: "Customer as Supplier", value: DISTRIBUTOR_TYPES.CUSTOMER_OWNED }
    ]
  }
};

DistributorItem.filters = mapFiltersFor(DistributorItem, [
  "name",
  "invoiceEmail",
  "universalCatalog",
  "country",
  "status",
  "distributorType"
]);

// form fields
DistributorItem.fieldsConfig = {
  name: {
    label: "Name",
    required: true,
    initialValue: "",
    size: 6
  },
  externalDistributorNumber: {
    label: "External Distributor Number",
    required: true,
    initialValue: "",
    size: 6
  },
  invoiceEmail: {
    label: "Invoice Email",
    required: true,
    initialValue: "",
    size: 6
  },
  "address.line1": {
    label: "Address Line 1",
    required: true,
    initialValue: "",
    size: 6
  },
  "address.line2": {
    label: "Address Line 2",
    initialValue: "",
    size: 6
  },
  country: {
    label: "Country",
    required: true,
    type: "select",
    options: Dictionaries.COUNTRIES,
    size: 6
  },
  "address.state": {
    Component: props => <SelectState {...props} />,
    initialValue: ""
  },
  "address.city": {
    label: "City",
    required: true,
    initialValue: "",
    size: 6
  },
  "address.zipCode": {
    label: "Postal Code",
    required: true,
    initialValue: "",
    size: 6
  },
  processingFee: {
    label: "Processing Fee",
    type: "checkbox",
    noHelperText: true,
    initialValue: false,
    size: 3
  },
  supplyForce: {
    label: "Supply Force",
    type: "checkbox",
    noHelperText: true,
    initialValue: false,
    size: 3
  },
  coBranding: {
    label: "Customization & Co-Branding",
    type: "checkbox",
    noHelperText: true,
    initialValue: false,
    size: 3
  },
  universalCatalog: {
    label: "Use the Distributor Catalog in the Universal Catalog data",
    type: "checkbox",
    noHelperText: true,
    initialValue: true,
    size: 3
  },
  userData: {
    label: "User Data",
    type: "checkbox",
    noHelperText: true,
    initialValue: false,
    size: 2
  },
  agreements: {
    label: "Agreements",
    type: "checkbox",
    noHelperText: true,
    initialValue: false,
    size: 2
  },
  taxes: {
    label: "Taxes",
    type: "checkbox",
    noHelperText: true,
    initialValue: false,
    size: 2
  },
  level: {
    label: "Level",
    type: "checkbox",
    noHelperText: true,
    initialValue: false,
    size: 2
  },
  creditCardInfo: {
    label: "Billing Info",
    type: "checkbox",
    noHelperText: true,
    initialValue: false,
    size: 3
  },
  billingLeftDays: {
    label: "Billing Left",
    initialValue: 0,
    inputSuffix: "days",
    size: 3,
    checkDisabled: () => true
  },
  "shipToFee.id": {
    label: "Billing Level",
    required: true,
    type: "select",
    dict: "shipToFeeLevels",
    initialValue: "",
    size: 3
  },
  billAllShipTo: {
    label: "Bill by all Ship-tos",
    type: "checkbox",
    noHelperText: true,
    initialValue: true,
    size: 6
  },
  space: {
    Component: () => <div style={{ width: "100%" }} />,
    size: 6
  }
};

DistributorItem.schema = V.object({
  name: V.string().label("Name").required().max(255).nullable(),
  externalDistributorNumber: V.string()
    .label("External Distributor Number")
    .required()
    .max(255)
    .nullable(),
  invoiceEmail: V.string()
    .label("Invoice Email")
    .required()
    .email()
    .max(255)
    .nullable(),
  country: V.string().label("Country").required().nullable(),
  address: V.object({
    line1: V.string().label("Address Line 1").required().max(255).nullable(),
    line2: V.string().label("Address Line 2").max(255).nullable(),
    city: V.string().label("City").required().max(255).nullable(),
    state: V.string().label("State").required().nullable(),
    zipCode: V.string().label("Postal Code").required().max(255).nullable()
  }),
  shipToFee: V.object({
    id: V.string().label("ShipTo Fee Level").required()
  }).nullable()
});

const fields = [
  "name",
  "externalDistributorNumber",
  "invoiceEmail",
  "address.line1",
  "address.line2",
  "address.city",
  "country",
  "address.state",
  "address.zipCode",
  "processingFee",
  "supplyForce",
  "coBranding",
  "universalCatalog",
  "space",
  "userData",
  "agreements",
  "taxes",
  "level",
  "creditCardInfo",
  "billingLeftDays",
  "shipToFee.id",
  "billAllShipTo"
];

const groups = [
  {
    Component: Grid,
    container: true,
    spacing: 3,
    fields: [
      "name",
      "externalDistributorNumber",
      "invoiceEmail",
      "address.line1",
      "address.line2",
      "address.city",
      "country",
      "address.state",
      "address.zipCode",
      "processingFee",
      "supplyForce",
      "coBranding",
      "universalCatalog"
    ]
  },
  {
    Component: GroupBox,
    styles: {
      margin: "12px 0"
    },
    label: "Activation Steps",
    fields: ["userData", "agreements", "taxes", "level", "creditCardInfo"]
  }
];

const createFormConfig = {
  fields,
  groups: [
    ...groups,
    {
      Component: Grid,
      container: true,
      spacing: 3,
      fields: ["shipToFee.id", "billAllShipTo"]
    }
  ]
};

const editFormConfig = {
  fields,
  groups: [
    ...groups,
    {
      Component: Grid,
      container: true,
      spacing: 3,
      fields: ["shipToFee.id", "billAllShipTo", "billingLeftDays"]
    }
  ]
};

DistributorItem.createForm = mapFormFieldsFor(
  DistributorItem,
  createFormConfig
);

DistributorItem.editForm = mapFormFieldsFor(DistributorItem, editFormConfig);

["userData", "agreements", "taxes", "level", "creditCardInfo"].forEach(name => {
  const field = DistributorItem.editForm.fields.find(f => f.name === name);
  field.checkDisabled = () => true;
});

DistributorItem.modalEditForm = true;
