export const styles = theme => ({
  actionButton: {
    color: theme.palette.primary.light,
    height: 28,
    padding: "0 8px",
    whiteSpace: "nowrap",
    fontWeight: "bold",
    minWidth: 50,
    [theme.breakpoints.down("sm")]: {
      minWidth: 40
    }
  },
  actionIcon: {
    marginRight: 4,
    fontSize: 20
  },
  fab: {
    position: "absolute",
    bottom: 24,
    right: 24
  }
});
