import React from "react";

import { withStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";
import PropTypes from "prop-types";
import { compose } from "redux";

import { styles } from "./styles";

function SelectionInfoComponent(props) {
  const {
    classes,
    selectAll,
    totalSelected,
    totalEntities,
    totalPages,
    allSelected,
    onSelectAll,
    onClear
  } = props;

  const { messagesContainer, selectedMessage, selectedNumber, selectionLink } =
    classes;

  const total = allSelected ? totalEntities : totalSelected;
  const selectedTotal = total === totalEntities ? "all" : total;

  const renderAllSelected = () => {
    return !allSelected ? (
      <span className={selectionLink} onClick={onSelectAll}>
        select all
      </span>
    ) : (
      <span className={selectionLink} onClick={onClear}>
        clear all
      </span>
    );
  };

  return (
    <div className={messagesContainer}>
      {totalEntities > 0 && (
        <span className={selectedMessage}>
          selected <span className={selectedNumber}>{selectedTotal}</span> from{" "}
          <span className={selectedNumber}>{totalEntities}</span>
        </span>
      )}
      {totalEntities === 0 && " "}
      {selectAll && totalPages > 1 && renderAllSelected()}
    </div>
  );
}

SelectionInfoComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  selectAll: PropTypes.bool,
  totalSelected: PropTypes.number,
  totalEntities: PropTypes.number,
  totalPages: PropTypes.number,
  allSelected: PropTypes.bool,
  onClear: PropTypes.func,
  onSelectAll: PropTypes.func
};

export const SelectionInfo = compose(
  withStyles(styles),
  withWidth()
)(SelectionInfoComponent);
