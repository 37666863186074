import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
  label: {
    color: "#707070"
  },
  input: {
    color: "#333",
    "&:hover:before": {
      borderBottom: "1px solid #D4D4D4 !important"
    },
    "&:before": {
      borderBottom: "1px solid #D4D4D4"
    }
  },
  disabled: {
    color: "#333 !important"
  },
  checkbox: {
    color: `${theme.palette.primary.main} !important`
  }
}));
