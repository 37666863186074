import * as V from "yup";

import { mapFormFieldsFor } from "srx-shared";

export const ShipToTemplateNameItem = {
  fieldsConfig: {
    templateName: {
      label: "Name",
      required: true,
      initialValue: "",
      size: 4
    },
    status: {
      label: "Status",
      required: true,
      initialValue: "",
      type: "select",
      size: 4,
      options: [
        { label: "ACTIVE", value: "ACTIVE" },
        { label: "DRAFT", value: "DRAFT" }
      ]
    }
  }
};

ShipToTemplateNameItem.schema = V.object({
  templateName: V.string().label("Name").required().max(255),
  status: V.string().label("Status").required().max(255)
});

ShipToTemplateNameItem.formConfig = mapFormFieldsFor(ShipToTemplateNameItem, [
  "templateName",
  "status"
]);
