import { useEffect } from "react";

import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox
} from "@material-ui/core";
import { DckActionCreators } from "dck-redux";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { useDispatchActions } from "srx-shared";
import { Selectors } from "srx-shared";

import { VmiListSettingsStructure } from "@items/types";

import { useStyles } from "./styles";

const heads = ["Feature", "View", "Edit"];

const labels = {
  jointGroup:
    "Customer SKU, Distributor SKU, Manufacturer SKU, Short Description",
  lifecycleStatus: "Lifecycle Status",
  inventoryStatus: "Inventory Status",
  ownedBy: "Owned by",
  asset: "Asset",
  serialization: "Serialization",
  lot: "Lot",
  triggerType: "Trigger Type",
  location: "Location",
  weights: "Weights (g)",
  limits: "Limits (Min / Max / Critical Min)",
  suggestedMinMax: "Suggested Min & Max",
  packageConversion: "Package Conversion",
  ohi: "OHI",
  autoSubmit: "Auto Submit",
  surplus: "Surplus",
  createdOn: "Created on"
};

const actions = {
  loadVmiSettingsStructure: () =>
    DckActionCreators.itemsLoad(VmiListSettingsStructure)
};

const ohiDisabledConditionFields = [
  "asset",
  "serialization",
  "lot",
  "triggerType"
];

const rows = Object.entries(labels).map(([key, name]) => ({ key, name }));

TableSettings.propTypes = {
  form: PropTypes.object,
  type: PropTypes.string,
  disabled: PropTypes.bool
};

export function TableSettings(props) {
  const { form, disabled } = props;
  const { values, setValues } = form;

  const { loadVmiSettingsStructure } = useDispatchActions(actions);

  const cls = useStyles();

  const structure = useSelector(state =>
    Selectors.getItemData(
      state,
      VmiListSettingsStructure,
      "vmiListSettingsStructure"
    )
  );

  useEffect(() => {
    loadVmiSettingsStructure();
  }, [loadVmiSettingsStructure]);

  const isDisabled = !values.enableVmiList || disabled;

  const isEditField = ({ key }) => structure[key] === "EDIT";

  const isDisabledField = ({ key }) =>
    key === "ohi" ? ohiDisabledConditionFields.some(el => !values[el]) : false;

  const isAllSelected = head => {
    if (head === "View") {
      return rows.every(({ key }) => values[key]);
    } else if (head === "Edit") {
      return rows
        .filter(row => isEditField(row) && !isDisabledField(row))
        .every(({ key }) => values[key] === "EDIT");
    }
  };

  const renderMessage = key => {
    let message = null;
    if (ohiDisabledConditionFields.includes(key) && !values[key]) {
      message = "Editing of the OHI will be disabled";
    }
    return message && <div className={cls.errorLabel}>{message}</div>;
  };

  const handleChange = (key, value) => {
    const newValues = {
      ...values,
      [key]: value
    };

    const enableVmiList = rows.some(({ key }) => newValues[key]);
    if (ohiDisabledConditionFields.includes(key) && !value && newValues.ohi) {
      newValues.ohi = "VIEW";
    }
    setValues({ ...newValues, enableVmiList });
  };

  const handleChangeColumn = (head, value) => {
    const newValues = { ...values };
    if (head === "Edit") {
      const action = value ? "EDIT" : "VIEW";
      rows
        .filter(row => isEditField(row) && !isDisabledField(row))
        .forEach(({ key }) => (newValues[key] = action));
    } else if (head === "View") {
      const getAction = key => (value ? newValues[key] ?? "VIEW" : null);
      rows.forEach(({ key }) => (newValues[key] = getAction(key)));
    }

    const enableVmiList = rows.some(({ key }) => newValues[key]);
    setValues({ ...newValues, enableVmiList });
  };

  if (!structure) return null;

  return (
    <TableContainer>
      <Table className={cls.table}>
        <colgroup>
          <col width="50%" />
          <col span={2} width="25%" />
        </colgroup>
        <TableHead>
          <TableRow>
            {heads.map(head => (
              <TableCell
                key={head}
                className={cls.tableHead}
                size="small"
                align="center"
              >
                {head}
                {head === "Feature" ? null : (
                  <Checkbox
                    disabled={isDisabled}
                    checked={isAllSelected(head)}
                    color="primary"
                    onChange={(e, value) => handleChangeColumn(head, value)}
                  />
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(row => (
            <TableRow key={row.name}>
              <TableCell size="small">{row.name}</TableCell>
              <TableCell align="center" size="small">
                <Checkbox
                  disabled={isDisabled}
                  checked={["VIEW", "EDIT"].includes(values[row.key])}
                  color="primary"
                  onChange={(e, value) =>
                    handleChange(row.key, value ? "VIEW" : null)
                  }
                />
                {renderMessage(row.key)}
              </TableCell>
              <TableCell align="center" size="small">
                {structure[row.key] === "EDIT" && (
                  <Checkbox
                    disabled={isDisabled || isDisabledField(row)}
                    checked={values[row.key] === "EDIT"}
                    color="primary"
                    onChange={(e, value) =>
                      handleChange(row.key, value ? "EDIT" : "VIEW")
                    }
                  />
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
