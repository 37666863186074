/* eslint react/display-name: "off" */
/* eslint react/prop-types: "off" */
import * as V from "yup";

import {
  mapFiltersFor,
  mapFormFieldsFor,
  ItemTypeApplication,
  Formatters,
  Selectors,
  makeSequence
} from "srx-shared";
import { Cell } from "srx-shared/mui";

import { ActionCreators } from "@actions";
import Actions from "@comp/Hardware/Hardware/Actions";
import HardwareState from "@comp/Hardware/Hardware/HardwareState";
import SerialNumberField from "@comp/Hardware/Hardware/SerialNumberLabel";
import { IoTHub, Hardware } from "@items/types";
import { store } from "@redux/store";
import { iotHubDict } from "@sagas/hardwareSaga";
import { getAvoidDotsError } from "@utils";
import { DEVICE_TYPE_OPTIONS } from "config";

V.addMethod(V.number, "avoidDots", function () {
  return this.transform(getAvoidDotsError);
});

const formatDeviceName = item =>
  item.type === "IOTHUB"
    ? `${item.id}${item.deviceName ? ` / ${item.deviceName}` : ""}`
    : item.deviceName || "-";

const formatIoTHub = item =>
  item ? `${item.id}${item.deviceName ? ` / ${item.deviceName}` : ""}` : "-";

const formatUser = item =>
  item ? (
    <>
      {item.firstName} {item.lastName} <br />({item.email})
    </>
  ) : (
    "-"
  );

const formatCustomerShipto = item =>
  item.customerName && item.shipToNumber
    ? `${item.customerName} - ${item.shipToNumber}`
    : "-";

const formatAdditionalInfo = item => (
  <>
    {Boolean(item.manufacturerSerialNumber) &&
      `Manufacturer SN: ${item.manufacturerSerialNumber},`}
    <br />
    {Boolean(item.licenseKeyNumber) && `License Key: ${item.licenseKeyNumber}`}
  </>
);

const distributorIsDisabled = form => {
  const { type, iotHub } = form.values;
  return (
    ["LOCKER", "CAMERA", "VENDING"].includes(type) ||
    Boolean(iotHub && iotHub.id)
  );
};

const rowsIsDisabled = form => {
  const { type, hardwareVersion } = form.values;
  return type === "VENDING" && hardwareVersion === "v1";
};

const hardwareVersionIsDisabled = form => {
  const { type } = form.values;
  return type === "VENDING" && isEditForm(form);
};

const distributorIsEditable = ({ values: { type } }) => Boolean(type);

const iotHubIsEditable = ({ values: { type } }) =>
  ["LOCKER", "STORAGE", "CAMERA", "VENDING"].includes(type);

const manufacturerSerialNumberIsEditable = ({ values: { type } }) =>
  ["RFID"].includes(type);

const licenseKeyNumberIsEditable = ({ values: { type } }) =>
  ["RFID"].includes(type);

const deviceSubtypeIsEditable = ({ values: { type } }) =>
  ["LOCKER"].includes(type);

const deviceStorage = ({ values: { type } }) => type === "STORAGE";

const isEditForm = form => Boolean(form.initialValues.value);

const deviceLockerV2 = ({ values: { type, hardwareVersion } }) =>
  type === "LOCKER" && hardwareVersion === "v2";

const haveHardwareVersion = ({ values: { type } }) =>
  ["LOCKER", "STORAGE", "VENDING"].includes(type);

const haveRows = ({ values: { type } }) => ["VENDING"].includes(type);

const isLockerOrVending = ({ values: { type } }) =>
  ["LOCKER", "VENDING"].includes(type);

const isIotHub = ({ values: { type } }) => type === "IOTHUB";

const onChangeHardwareVersion = (value, form) => {
  const { type, metadata } = form.values;

  if (type === "VENDING" && value === "v1") {
    form.setFieldValue("rows", "6");
    return;
  }

  if (value !== "v2" || type !== "LOCKER") return;

  if (!metadata?.deviceFiles?.doors) {
    form.setFieldValue("metadata.deviceFiles.doors", "/dev/ttyUSB0");
  }
  if (!metadata?.deviceFiles?.shelves) {
    form.setFieldValue("metadata.deviceFiles.shelves", "/dev/ttyUSB1");
  }
};

const getHardwareVersionOptions = type => {
  const options = [
    {
      label: "Version 1",
      value: "v1"
    },
    {
      label: "Version 2",
      value: "v2"
    }
  ];

  if (type === "LOCKER") {
    options.push({
      label: "Version 3",
      value: "v3"
    });
  }

  return options;
};

const changePortField = field => (value, form) => {
  const { hardwareVersion, type } = form.values;

  if (hardwareVersion !== "v2" || type !== "LOCKER") {
    return;
  }

  const diffValue = value === "/dev/ttyUSB0" ? "/dev/ttyUSB1" : "/dev/ttyUSB0";
  form.setFieldValue(field, diffValue);
};

const onChangeIoTHub = (value, form) => {
  let iotHub = {};
  if (value) {
    const correctedType = Formatters.uppercaseFirstLetter(form.values.type);
    const iotHubs = Selectors.getItemData(
      store.getState(),
      IoTHub,
      `iotHubsFor${correctedType}`
    );
    iotHub =
      (form.initialValues.iotHub || {}).id === value
        ? form.initialValues.iotHub
        : iotHubs.find(el => el.id === value);
  }
  form.setFieldValue(
    "distributorId",
    (iotHub || {}).distributorId || null,
    false
  );
};

const onChangeDeviceType = (value, form) => {
  if (["LOCKER", "STORAGE", "CAMERA", "VENDING"].includes(value)) {
    const correctedType = Formatters.uppercaseFirstLetter(value);
    const iotHubs = Selectors.getItemData(
      store.getState(),
      IoTHub,
      `iotHubsFor${correctedType}`
    );
    store.dispatch(
      ActionCreators.setItemData(
        ItemTypeApplication,
        "iotHubDict",
        iotHubDict(iotHubs)
      )
    );

    const hardwareVersionOptions = getHardwareVersionOptions(value);
    store.dispatch(
      ActionCreators.setItemData(
        ItemTypeApplication,
        "hardwareVersionDict",
        hardwareVersionOptions
      )
    );

    const currentHardwareVersionOption = hardwareVersionOptions.find(
      option => option.value === form.values.hardwareVersion
    );
    if (!currentHardwareVersionOption) {
      form.setValues({
        ...form.values,
        hardwareVersion: hardwareVersionOptions[0].value
      });
    }
  }
};

const rowsOptions = size =>
  makeSequence(size).map(digit => ({
    label: String(digit),
    value: String(digit)
  }));

export const HardwareItem = {
  type: Hardware,
  name: "Hardware",
  title: "hardware"
};

// table
HardwareItem.columns = [
  {
    Header: "Status",
    accessor: "status",
    sortable: false,
    width: 60,
    Cell: ({ row }) => (
      <Cell center>
        <HardwareState item={row._original} />
      </Cell>
    )
  },
  {
    Header: "Serial Number",
    accessor: "value",
    width: 180,
    Cell: props => <Cell {...props} />
  },
  {
    Header: "Device Type",
    accessor: "type",
    maxWidth: 110,
    Cell: ({ value }) => <Cell>{Formatters.deviceType(value)}</Cell>
  },
  {
    Header: "Hardware Version",
    accessor: "hardwareVersion",
    Cell: ({ value }) => <Cell>{value}</Cell>
  },
  {
    Header: "Device Sub-Type",
    accessor: "lockerType.name",
    Cell: ({ value }) => <Cell>{value}</Cell>
  },
  {
    Header: "Device name",
    accessor: "deviceName",
    Cell: ({ row }) => <Cell>{formatDeviceName(row._original)}</Cell>
  },
  {
    Header: "IoT Hub",
    accessor: "iotHub",
    Cell: ({ value }) => <Cell>{formatIoTHub(value)}</Cell>
  },
  {
    Header: "IoT Hub Version",
    accessor: "hubSoftwareVersion",
    Cell: ({ value }) => <Cell>{value || "-"}</Cell>
  },
  {
    Header: "Distributor",
    accessor: "distributorName",
    Cell: ({ value }) => <Cell>{value || "-"}</Cell>
  },
  {
    Header: "Customer-ShipTo",
    accessor: "customerShipToNumber",
    sortable: false,
    Cell: ({ row }) => <Cell>{formatCustomerShipto(row._original)}</Cell>
  },
  {
    Header: "Distributor User",
    accessor: "distributorUser",
    sortable: false,
    Cell: ({ value }) => <Cell>{formatUser(value)}</Cell>
  },
  {
    Header: "Customer User",
    accessor: "customerUser",
    sortable: false,
    Cell: ({ value }) => <Cell>{formatUser(value)}</Cell>
  },
  {
    Header: "Additional information",
    accessor: "additionalInformation",
    sortable: false,
    Cell: ({ row }) => <Cell>{formatAdditionalInfo(row._original)}</Cell>
  },
  {
    Header: "Actions",
    width: 128,
    isAction: true,
    resizable: false,
    sortable: false,
    Cell: ({ row }) => <Actions row={row._original} />
  }
];

// filters
HardwareItem.filtersConfig = {
  type: {
    name: "Device Type",
    type: "select",
    options: DEVICE_TYPE_OPTIONS
  },
  distributorId: {
    name: "Distributor",
    type: "select",
    dict: "distributors"
  }
};
HardwareItem.filters = mapFiltersFor(HardwareItem, ["type", "distributorId"]);

// form fields
HardwareItem.fieldsConfig = {
  value: {
    Component: SerialNumberField,
    size: 12,
    initialValue: "",
    checkHidden: form => isEditForm(form)
  },
  type: {
    label: "Device Type",
    type: "select",
    options: DEVICE_TYPE_OPTIONS,
    initialValue: "",
    required: true,
    size: 8,
    onChange: onChangeDeviceType,
    checkDisabled: form => isEditForm(form)
  },
  "iotHub.id": {
    label: "IoT Hub",
    type: "select",
    initialValue: "",
    dict: "iotHubDict",
    size: 12,
    SelectProps: {
      isClearable: true
    },
    onChange: onChangeIoTHub,
    checkHidden: form => !iotHubIsEditable(form)
  },
  distributorId: {
    label: "Distributor",
    type: "select",
    dict: "distributors",
    initialValue: "",
    size: 12,
    SelectProps: {
      isClearable: true
    },
    checkHidden: form => !distributorIsEditable(form),
    checkDisabled: form => distributorIsDisabled(form)
  },
  hubSoftwareVersion: {
    label: "IoT Hub Version",
    size: 4,
    checkHidden: form => !isIotHub(form) || !isEditForm(form),
    checkDisabled: () => true
  },
  "lockerType.id": {
    label: "Device Sub-Type",
    type: "select",
    dict: "lockerTypes",
    initialValue: "",
    required: true,
    size: 12,
    checkHidden: form => !deviceSubtypeIsEditable(form),
    checkDisabled: form => isEditForm(form)
  },
  "lockerType.doorsQuantity": {
    label: "Rows Quantity (total)",
    required: true,
    size: 4,
    initialValue: 10,
    checkHidden: form => !deviceStorage(form),
    checkDisabled: form => isEditForm(form)
  },
  "lockerType.columnsQuantity": {
    label: "Columns Quantity",
    required: true,
    size: 4,
    initialValue: 2,
    checkHidden: form => !deviceStorage(form),
    checkDisabled: form => isEditForm(form)
  },
  "lockerType.cellsWithoutWeightQuantity": {
    label: "Cells Quantity",
    size: 4,
    initialValue: 4,
    helperText: "For no-weight sections",
    checkHidden: form => !deviceStorage(form)
  },
  manufacturerSerialNumber: {
    label: "Manufacturer Serial Number",
    initialValue: "",
    size: 6,
    checkHidden: form => !manufacturerSerialNumberIsEditable(form)
  },
  licenseKeyNumber: {
    label: "License Key Number",
    initialValue: "",
    size: 6,
    checkHidden: form => !licenseKeyNumberIsEditable(form)
  },
  hardwareVersion: {
    label: "Hardware Version",
    type: "select",
    dict: "hardwareVersionDict",
    initialValue: "v1",
    size: 4,
    onChange: onChangeHardwareVersion,
    checkHidden: form => !haveHardwareVersion(form),
    checkDisabled: form => hardwareVersionIsDisabled(form)
  },
  rows: {
    label: "Rows",
    type: "select",
    options: rowsOptions(6),
    initialValue: "6",
    size: 4,
    checkDisabled: form => isEditForm(form) || rowsIsDisabled(form),
    checkHidden: form => !haveRows(form)
  },
  "metadata.deviceFiles.doors": {
    label: "Lock Doors port",
    type: "select",
    options: [
      {
        label: "USB 0",
        value: "/dev/ttyUSB0"
      },
      {
        label: "USB 1",
        value: "/dev/ttyUSB1"
      }
    ],
    initialValue: "/dev/ttyUSB0",
    size: 6,
    onChange: changePortField("metadata.deviceFiles.shelves"),
    checkHidden: form => !deviceLockerV2(form)
  },
  "metadata.deviceFiles.shelves": {
    label: "Smartshelf port",
    type: "select",
    options: [
      {
        label: "USB 0",
        value: "/dev/ttyUSB0"
      },
      {
        label: "USB 1",
        value: "/dev/ttyUSB1"
      }
    ],
    initialValue: "/dev/ttyUSB1",
    size: 6,
    onChange: changePortField("metadata.deviceFiles.doors"),
    checkHidden: form => !deviceLockerV2(form)
  },
  keyCode: {
    label: "Key Code",
    initialValue: "",
    size: 6,
    checkHidden: form => !isLockerOrVending(form)
  },
  orderNumber: {
    label: "SRX Order Number",
    initialValue: "",
    size: 6,
    checkHidden: form => !isLockerOrVending(form)
  },
  receivedDate: {
    type: "date",
    label: "Date Received",
    initialValue: "",
    size: 6,
    PickerProps: {
      minDate: new Date(2000, 0, 1),
      maxDate: new Date(2099, 11, 31, 23, 59, 59)
    },
    checkHidden: form => !isLockerOrVending(form),
    formatValue: value =>
      value instanceof Date && !Number.isNaN(value.getTime())
        ? value.getTime()
        : value
  },
  shippedDate: {
    type: "date",
    label: "Date Shipped",
    initialValue: "",
    size: 6,
    PickerProps: {
      minDate: new Date(2000, 0, 1),
      maxDate: new Date(2099, 11, 31, 23, 59, 59)
    },
    checkHidden: form => !isLockerOrVending(form),
    formatValue: value =>
      value instanceof Date && !Number.isNaN(value.getTime())
        ? value.getTime()
        : value
  }
};

HardwareItem.schema = V.object({
  type: V.string().label("Device Type").required().nullable(),
  lockerType: V.object({
    id: V.string().label("Device Sub-Type").when("$type", {
      is: "LOCKER",
      then: V.string().required()
    }),
    doorsQuantity: V.number()
      .label("Value")
      .when("$type", {
        is: "STORAGE",
        then: V.number()
          .typeError("Value must be an integer number")
          .required()
          .avoidDots()
          .min(1)
          .max(24)
          .nullable()
      }),
    columnsQuantity: V.number()
      .label("Value")
      .when("$type", {
        is: "STORAGE",
        then: V.number()
          .typeError("Value must be an integer number")
          .required()
          .avoidDots()
          .min(1)
          .max(8)
          .nullable()
      }),
    cellsWithoutWeightQuantity: V.number()
      .label("Value")
      .when("$type", {
        is: "STORAGE",
        then: V.number()
          .typeError("Value must be an integer number")
          .avoidDots()
          .min(1)
          .max(99)
          .nullable()
      })
  }).nullable(),
  distributorId: V.string()
    .label("Distributor")
    .when("type", {
      is: "IOTHUB",
      then: V.string().required()
    })
    .nullable(),
  manufacturerSerialNumber: V.string()
    .label("Manufacturer Serial Number")
    .max(255)
    .nullable(),
  licenseKeyNumber: V.string().label("License Key Number").max(255).nullable(),
  keyCode: V.string().label("Key Code").max(255).nullable(),
  orderNumber: V.string().label("SRX Order Number").max(255).nullable(),
  receivedDate: V.date()
    .typeError("Enter the correct date (e.g. 01/31/2020)")
    .label("Date Received")
    .transform((value, originalValue) =>
      typeof originalValue === "number"
        ? new Date(originalValue)
        : originalValue
    )
    .min(
      new Date(2000, 0, 1),
      "Date Received field must be later than 2000-01-01"
    )
    .max(
      new Date(2099, 11, 31, 23, 59, 59),
      "Date Received field must be at earlier than 2099-12-31"
    )
    .nullable(),
  shippedDate: V.date()
    .typeError("Enter the correct date (e.g. 01/31/2020)")
    .label("Date Shipped")
    .transform((value, originalValue) =>
      typeof originalValue === "number"
        ? new Date(originalValue)
        : originalValue
    )
    .min(
      new Date(2000, 0, 1),
      "Date Shipped field must be later than 2000-01-01"
    )
    .max(
      new Date(2099, 11, 31, 23, 59, 59),
      "Date Shipped field must be at earlier than 2099-12-31"
    )
    .nullable()
});

HardwareItem.createForm = mapFormFieldsFor(HardwareItem, [
  "value",
  "type",
  "hardwareVersion",
  "rows",
  "metadata.deviceFiles.doors",
  "metadata.deviceFiles.shelves",
  "iotHub.id",
  "distributorId",
  "lockerType.id",
  "lockerType.doorsQuantity",
  "lockerType.columnsQuantity",
  "lockerType.cellsWithoutWeightQuantity",
  "manufacturerSerialNumber",
  "licenseKeyNumber",
  "keyCode",
  "orderNumber",
  "receivedDate",
  "shippedDate"
]);

HardwareItem.editForm = mapFormFieldsFor(HardwareItem, [
  "value",
  "type",
  "hardwareVersion",
  "hubSoftwareVersion",
  "rows",
  "metadata.deviceFiles.doors",
  "metadata.deviceFiles.shelves",
  "lockerType.id",
  "iotHub.id",
  "distributorId",
  "lockerType.doorsQuantity",
  "lockerType.columnsQuantity",
  "lockerType.cellsWithoutWeightQuantity",
  "manufacturerSerialNumber",
  "licenseKeyNumber",
  "keyCode",
  "orderNumber",
  "receivedDate",
  "shippedDate"
]);

HardwareItem.modalEditForm = true;
