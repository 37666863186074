import * as V from "yup";

import { mapFormFieldsFor, reListOfEmails } from "srx-shared";

import { ShipToTemplateSettings } from "@items/types";

export const PRICING_SETTINGS = [
  {
    name: "Submit as Quote for Pricing",
    label: "Submit as Quote for Pricing",
    value: "AS_A_QUOTE"
  },
  { name: "Display N/A", label: "Display N/A", value: "NOT_AVAILABLE" },
  {
    name: "Display Distributor’s currency",
    label: "Display Distributor’s currency",
    value: "NULL_PRICE"
  }
];

V.addMethod(V.string, "listOfEmails", function () {
  return this.test(
    "listOfEmails",
    "Please enter a valid email or emails, separated by comma",
    value => {
      return value ? reListOfEmails.test(value) : true;
    }
  );
});

export const ReorderListSettingsItem = {
  type: ShipToTemplateSettings,
  fieldsConfig: {
    pricingNotAvailableBehavior: {
      label: "Behavior when pricing is not available",
      type: "radio",
      initialValue: "AS_A_QUOTE",
      options: PRICING_SETTINGS,
      noHelperText: true,
      size: 12
    },
    orderSubmitSettings: {
      label: "Submit Reorder List as ORDER",
      type: "switch",
      initialValue: false,
      noHelperText: true,
      size: 6
    },
    email: {
      label: "Submitted Reorder List Email",
      initialValue: "",
      size: 6
    }
  },
  schema: V.object({
    email: V.string().label("Email").max(255).listOfEmails().nullable()
  }),
  fields: ["pricingNotAvailableBehavior", "orderSubmitSettings", "email"]
};

ReorderListSettingsItem.formConfig = mapFormFieldsFor(ReorderListSettingsItem);
