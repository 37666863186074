import { STAGE } from "../config";

export const API_HOST =
  process.env.REACT_APP_API_HOST || `https://api-${STAGE}.storeroomlogix.com`;

export const API_BASE_PATH = `${API_HOST}/admin-portal/admin`;

export const IOTHUB_API_HOST =
  STAGE === "prod"
    ? `https://iothub-api.storeroomlogix.com/${STAGE}`
    : `https://iothub-api.${STAGE}.storeroomlogix.com/${STAGE}`;

export const EndPoint = {
  ListDistributorsUrl: (page, size, filters, sorting) =>
    `${API_BASE_PATH}/distributors?page=${page}&size=${size}${filters}${sorting}`,
  GetDistributorsMinInfoUrl: () => `${API_BASE_PATH}/distributors/min-info`,
  GetDistributorUrl: id => `${API_BASE_PATH}/distributors/${id}`,
  AddDistributorUrl: () => `${API_BASE_PATH}/distributors/create`,
  UpdateDistributorUrl: id => `${API_BASE_PATH}/distributors/${id}/update`,
  DeleteDistributorUrl: id => `${API_BASE_PATH}/distributors/${id}/delete`,

  ActivateDistributorUrl: id => `${API_BASE_PATH}/distributors/${id}/activate`,
  DeactivateDistributorUrl: id =>
    `${API_BASE_PATH}/distributors/${id}/deactivate`,

  ListUsersUrl: (distributorId, page, size, filters, sorting) =>
    `${API_BASE_PATH}/distributors/${distributorId}/users/pageable?page=${page}&size=${size}${filters}${sorting}`,
  ListUsersWithStoragesUrl: () => `${API_BASE_PATH}/distributors/with-storages`,
  AddUserUrl: distributorId =>
    `${API_BASE_PATH}/distributors/${distributorId}/users/admin/create`,
  UpdateUserUrl: (distributorId, userId) =>
    `${API_BASE_PATH}/distributors/${distributorId}/users/${userId}/update`,
  RemoveUserUrl: (distributorId, userId) =>
    `${API_BASE_PATH}/distributors/${distributorId}/users/${userId}/delete`,

  GetBaseFeesUrl: () => `${API_BASE_PATH}/fees/base`,
  GetShiptoFeesUrl: () => `${API_BASE_PATH}/fees/shipto`,
  UpdateShiptoFeesUrl: id => `${API_BASE_PATH}/fees/shipto/${id}`,

  ListTriggersMonthlyFeesUrl: (page, size, filters, sorting) =>
    `${API_BASE_PATH}/fees/monthly/triggers?page=${page}&size=${size}${filters}${sorting}`,
  ListCamerasMonthlyFeesUrl: (page, size, filters, sorting) =>
    `${API_BASE_PATH}/fees/monthly/cameras?page=${page}&size=${size}${filters}${sorting}`,
  SaveBaseFeesUrl: id => `${API_BASE_PATH}/fees/base/${id}/update`,
  SaveTriggersMonthlyFeeUrl: id =>
    `${API_BASE_PATH}/fees/monthly/triggers/${id}/update`,
  SaveCamerasMonthlyFeeUrl: id =>
    `${API_BASE_PATH}/fees/monthly/cameras/${id}/update`,
  AddTriggersMonthlyFeeUrl: () =>
    `${API_BASE_PATH}/fees/monthly/triggers/create`,
  AddCamerasMonthlyFeeUrl: () => `${API_BASE_PATH}/fees/monthly/cameras/create`,
  RemoveTriggersMonthlyFeeUrl: id =>
    `${API_BASE_PATH}/fees/monthly/triggers/${id}/delete`,
  RemoveCamerasMonthlyFeeUrl: id =>
    `${API_BASE_PATH}/fees/monthly/cameras/${id}/delete`,
  GetDiscountsUrl: distributorId =>
    `${API_BASE_PATH}/distributors/${distributorId}/discounts`,
  UpdateDiscountsUrl: distributorId =>
    `${API_BASE_PATH}/distributors/${distributorId}/discounts/update`,
  ListDocumentsUrl: (page, size, filters, sorting) =>
    `${API_BASE_PATH}/distributors/documents/pageable?page=${page}&size=${size}${filters}${sorting}`,
  GetDocumentAttachmentUrl: id =>
    `${API_BASE_PATH}/distributors/documents/${id}/link`,
  ChangeDocumentStatusUrl: id =>
    `${API_BASE_PATH}/distributors/documents/${id}/update-status`,

  ListCustomerTypesUrl: (page, size, filters, sorting) =>
    `${API_BASE_PATH}/customer-types?page=${page}&size=${size}${filters}${sorting}`,
  AddCustomerTypeUrl: () => `${API_BASE_PATH}/customer-types/create`,
  UpdateCustomerTypeUrl: id => `${API_BASE_PATH}/customer-types/${id}/update`,
  RemoveCustomerTypeUrl: id => `${API_BASE_PATH}/customer-types/${id}/delete`,

  ListBinTypesUrl: (page, size, filters, sorting) =>
    `${API_BASE_PATH}/bin-types?page=${page}&size=${size}${filters}${sorting}`,
  BinTypeUrl: (id = "") => `${API_BASE_PATH}/bin-types/${id}`,

  ListLockerTypesUrl: (page, size, filters, sorting) =>
    `${API_BASE_PATH}/locker-types?page=${page}&size=${size}${filters}${sorting}`,
  LockerTypeUrl: (id = "") => `${API_BASE_PATH}/locker-types/${id}`,
  GetLockerTypesUrl: () => `${API_BASE_PATH}/locker-types/all`,

  TestVendingIssueItemsUrl: () => `${IOTHUB_API_HOST}/vending/issue-items`,

  ListMarketTypesUrl: (page, size, filters, sorting) =>
    `${API_BASE_PATH}/market-types?page=${page}&size=${size}${filters}${sorting}`,
  AddMarketTypeUrl: () => `${API_BASE_PATH}/market-types/create`,
  UpdateMarketTypeUrl: id => `${API_BASE_PATH}/market-types/${id}/update`,
  RemoveMarketTypeUrl: id => `${API_BASE_PATH}/market-types/${id}/delete`,

  FreezeVariablesUrl: distributorId =>
    `${API_BASE_PATH}/distributors/${distributorId}/freeze-variables`,

  //Hardware
  ListHardwareUrl: (page, size, filters, sorting) =>
    `${API_BASE_PATH}/distributors/hardware?page=${page}&size=${size}${filters}${sorting}`,
  HardwareUrl: (id = "") => `${API_BASE_PATH}/distributors/hardware/${id}`,
  ListIoTHubsUrl: type =>
    `${API_BASE_PATH}/distributors/hardware/iotHubs?type=${type}`,

  //Cost Savings
  DefaultTraditionalVmiOperationalCostsUrl: () =>
    `${API_BASE_PATH}/distributors/cost-savings/current-distributor-operational`,
  DefaultSrxVmiOperationalCostsUrl: () =>
    `${API_BASE_PATH}/distributors/cost-savings/distributor-operational`,
  DefaultDistributorInventoryUrl: () =>
    `${API_BASE_PATH}/distributors/cost-savings/distributor-inventory`,
  DefaultSuggestedMinMaxFormulaUrl: () =>
    `${API_BASE_PATH}/distributors/cost-savings/suggested-min-max-formula`,
  DefaultOperationalCostsUrl: () =>
    `${API_BASE_PATH}/distributors/cost-savings/operational-costs`,
  DefaultSupplyForceUrl: () =>
    `${API_BASE_PATH}/distributors/cost-savings/supply-force`,

  //Cost Savings for Distributors
  TraditionalVmiOperationalCostsUrl: distributor =>
    `${API_BASE_PATH}/distributors/${distributor}/cost-savings/current-distributor-operational`,
  SrxVmiOperationalCostsUrl: distributor =>
    `${API_BASE_PATH}/distributors/${distributor}/cost-savings/distributor-operational`,
  DistributorInventoryUrl: distributor =>
    `${API_BASE_PATH}/distributors/${distributor}/cost-savings/distributor-inventory`,
  SuggestedMinMaxFormulaUrl: distributor =>
    `${API_BASE_PATH}/distributors/${distributor}/cost-savings/suggested-min-max-formula`,
  OperationalCostsUrl: distributor =>
    `${API_BASE_PATH}/distributors/${distributor}/cost-savings/operational-costs`,

  GetListOfConfigurableCameraUrl: () =>
    `${IOTHUB_API_HOST}/camera/list-configurable`,
  ConfigureCameraUrl: () => `${IOTHUB_API_HOST}/camera/configure`,
  GetCameraConfigStatusUrl: () => `${IOTHUB_API_HOST}/camera/get-config-status`,

  GenerateIotHubAccessKeyUrl: () => `${IOTHUB_API_HOST}/raspberry/key`,

  GetDownloadIotHubImageUrl: () =>
    `${IOTHUB_API_HOST}/raspberry/claim-raspberry-image-url`,

  //Hardware statuses
  HardwareStates: () => `${IOTHUB_API_HOST}/generic/list-devices`,
  DeviceState: () => `${IOTHUB_API_HOST}/generic/get-device-state`,
  RenewDeviceState: () => `${IOTHUB_API_HOST}/generic/renew-device-state`,

  //Universal Catalog
  ListUpcGtinCatalogItemsUrl: (page, size, filters, sorting) =>
    `${API_BASE_PATH}/upc-gtin-catalog?page=${page}&size=${size}${filters}${sorting}`,
  UpcGtinCatalogItemUrl: (id = "") => `${API_BASE_PATH}/upc-gtin-catalog/${id}`,
  GetUploadUpcGtinCatalogsUrl: () =>
    `${API_BASE_PATH}/upc-gtin-catalog/upload-url`,

  //Import
  ImportStatusUrl: (fileFolder, fileName, type) =>
    `${API_BASE_PATH}/import-status/${fileFolder}/${fileName}?type=${type}`,
  ParseFileUrl: (folder, fileName) =>
    `${API_BASE_PATH}/parse/${folder}/${fileName}`,

  //Lockers
  DistributorLockersUrl: distributorId =>
    `${API_BASE_PATH}/distributors/${distributorId}/lockers`,
  AvailableLockerDoorsUrl: lockerId =>
    `${API_BASE_PATH}/distributors/lockers/${lockerId}/available-doors`,
  LockerConfigUrl: lockerId =>
    `${API_BASE_PATH}/distributors/lockers/${lockerId}/configuration`,
  GetLockerLocationsUrl: (lockerId, doorNumber) =>
    `${API_BASE_PATH}/distributors/hardware/${lockerId}/doors/${doorNumber}/locations`,

  //Storages
  DistributorStoragesUrl: distributorId =>
    `${API_BASE_PATH}/distributors/${distributorId}/storage`,
  StorageConfigUrl: storageId =>
    `${API_BASE_PATH}/distributors/storage/${storageId}/configuration`,
  GetStorageLocationsUrl: (storageId, sectionNumber) =>
    `${API_BASE_PATH}/distributors/hardware/${storageId}/doors/${sectionNumber}/locations`,

  //Smart shelves
  AssignSmartShelfUrl: lockerId =>
    `${API_BASE_PATH}/distributors/lockers/${lockerId}/configuration/shelf-association`,
  ListSmartShelvesUrl: (page, size, filters, sorting) =>
    `${API_BASE_PATH}/distributors/smart-shelves?page=${page}&size=${size}${filters}${sorting}`,
  SmartShelfUrl: (id = "") =>
    `${API_BASE_PATH}/distributors/smart-shelves/${id}`,
  ValidateSmartShelfUrl: () =>
    `${API_BASE_PATH}/distributors/smart-shelves/validate-update`,
  DistributorShelves: distributorId =>
    `${API_BASE_PATH}/distributors/${distributorId}/shelves`,
  SmartShelfDetailsfUrl: (id = "") =>
    `${API_BASE_PATH}/distributors/smart-shelves/${id}/details`,
  SmartShelfPollWeightUrl: (id = "") =>
    `${API_BASE_PATH}/distributors/smart-shelves/${id}/poll-weight`,

  //Weight history
  WeightHistoryUrl: (page, size, filters, sorting) =>
    `${API_BASE_PATH}/distributors/hardware/weights-history?page=${page}&size=${size}${filters}${sorting}`,
  ExportWeightHistoryUrl: (filters, sorting) =>
    `${API_BASE_PATH}/distributors/hardware/weights-history/export?${filters}${sorting}`,

  //Attach policy
  AttachPolicyUrl: () => `${IOTHUB_API_HOST}/generic/attach-policy`,

  //Billing
  SendInvoiceToErpUrl: distributorId =>
    `${API_BASE_PATH}/distributors/${distributorId}/send-invoice-to-ilx`,

  //ShipTo templates
  ShipToTemplatesUrl: (id = "") => `${API_BASE_PATH}/ship-to-templates/${id}`,

  VmiListSettingsStructureUrl: () =>
    `${API_BASE_PATH}/customers/vmilist/settings/structure`,

  RLSubmitIntegrationStatusesUrl: erp =>
    `${API_BASE_PATH}/rl-submit-integration/statuses?erp=${erp}`
};
