import { makeStyles, Tooltip } from "@material-ui/core";
import * as PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { HARDWARE_STATUS, Icons, Selectors } from "srx-shared";

import { HardwareStates } from "@redux/items/types";

const useStyles = makeStyles({
  root: {
    display: "flex",
    alignItems: "center"
  },
  number: {
    width: 28,
    height: 28,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 14,
    borderRadius: "50%",
    margin: 8,
    background: ({ colors: { background } }) => background,
    color: ({ colors: { color } }) => color
  },
  icon: {
    color: "#777"
  }
});

const colorsStatus = {
  [HARDWARE_STATUS.RED]: {
    colors: {
      background: "brown",
      color: "white"
    },
    Icon: Icons.WeightsError
  },
  [HARDWARE_STATUS.GREY]: {
    colors: { background: "#eee", color: "#777" },
    Icon: () => <Icons.Weights style={{ color: "#777" }} />
  },
  [HARDWARE_STATUS.GREEN]: {
    colors: { background: "#eee", color: "#777" },
    Icon: Icons.WeightsOk
  },
  [HARDWARE_STATUS.ORANGE]: {
    colors: {
      background: "#EF8A20",
      color: "white"
    },
    Icon: Icons.Weights
  }
};

DoorState.propTypes = {
  id: PropTypes.number,
  door: PropTypes.object,
  locker: PropTypes.object
};

export function DoorState(props) {
  const { id, door, locker } = props;
  const { noWeight, smartShelfHardware } = door;

  const hardwareState = useSelector(state =>
    Selectors.selectActiveItem(state, HardwareStates)
  );

  let status = locker?.status || HARDWARE_STATUS.GREY;

  if (status === HARDWARE_STATUS.GREEN && !door.noWeight) {
    if (door.smartShelfHardware) {
      const doorState = hardwareState
        ? hardwareState.state[0].doors.find(
            doorState => doorState.number === door.number
          )
        : null;
      if (doorState && !doorState.cells?.length) {
        status = HARDWARE_STATUS.ORANGE;
      }
    } else {
      status = HARDWARE_STATUS.GREY;
    }
  }

  const showIcon = noWeight || smartShelfHardware?.serialNumber;

  const { Icon, colors } = colorsStatus[status];

  const cls = useStyles({ colors });

  return (
    <div className={cls.root}>
      <div className={cls.number}>{id}</div>
      {showIcon && (
        <Tooltip title={noWeight ? "No weight door" : ""}>
          <div className={cls.icon}>
            {noWeight ? <Icons.NoWeights /> : <Icon />}
          </div>
        </Tooltip>
      )}
    </div>
  );
}
