export const styles = theme => ({
  footer: {
    marginTop: 12,
    display: "flex",
    justifyContent: "center"
  },
  pagination: {
    display: "flex",
    justifyContent: "flex-end",
    flexGrow: 2
  },
  pageSizeSelector: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    marginRight: 5
  },
  rowsPerPage: {
    color: "#777",
    fontSize: "small"
  },
  pageButton: {
    color: "#333",
    minWidth: 28,
    height: 24,
    margin: 2
  },
  pageButtonIcon: {
    padding: "0 2px"
  },
  pageSpan: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: "#333",
    minWidth: 28,
    height: 24,
    margin: 2,
    padding: "4px 5px"
  },
  goToInput: {
    width: 30,
    height: 28
  }
});
