import React, { useEffect } from "react";

import { Grid } from "@material-ui/core";
import { DckSelectors, DckActionCreators } from "dck-redux";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { generalFormFor, usePrevious } from "srx-shared";
import { ActionsSpinner, FormButton } from "srx-shared/mui";

import { Locker, AvailableLockerDoor } from "@items/types";

import { SmartShelfItem } from "./SmartShelfItem";

const SmartShelfEditForm = generalFormFor(
  SmartShelfItem,
  SmartShelfItem.editForm
);

const SmartShelfEditFormComponent = props => {
  const {
    itemData,
    updating,
    updatingSuccess,
    updateItem,
    loadAvailableLockers,
    loadAvailableLockerDoors,
    onClose
  } = props;

  const prevUpdating = usePrevious(updating);

  useEffect(() => {
    if (prevUpdating && !updating && updatingSuccess && onClose) {
      onClose();
    }
  }, [updatingSuccess, onClose, prevUpdating, updating]);

  const handleReset = form => {
    const { initialValues, values } = form;

    const initDistId = initialValues.distributor.id;
    const distId = values.distributor.id;
    if (initDistId && initDistId !== distId) {
      loadAvailableLockers({
        id: initDistId
      });
    }

    const initLockerId = initialValues.doorConfiguration.hardware.id;
    const lockerId = values.doorConfiguration.hardware.id;
    if (initLockerId && initLockerId !== lockerId) {
      loadAvailableLockerDoors({
        id: initLockerId,
        door: initialValues.doorConfiguration
      });
    }
  };

  const renderActions = (form, formikProps) => {
    const { dirty } = formikProps;
    return (
      <Grid container justify="flex-end" style={{ padding: "20px 12px 0" }}>
        <ActionsSpinner processing={updating} />
        <FormButton
          variant="reset"
          disabled={!dirty || updating}
          onClick={() => handleReset(formikProps)}
        />
        <FormButton variant="cancel" onClick={onClose} />
        <FormButton variant="save" disabled={!dirty || updating} />
      </Grid>
    );
  };

  return (
    <div style={{ padding: "0 6px 16px" }}>
      <SmartShelfEditForm
        renderActions={renderActions}
        initialData={itemData || {}}
        onSubmit={updateItem}
        processing={updating}
      />
    </div>
  );
};
SmartShelfEditFormComponent.propTypes = {
  itemData: PropTypes.object,
  updating: PropTypes.bool,
  updatingSuccess: PropTypes.bool,
  updateItem: PropTypes.func,
  onClose: PropTypes.func,
  loadAvailableLockers: PropTypes.func,
  loadAvailableLockerDoors: PropTypes.func
};

const process = `${SmartShelfItem.type.toUpperCase()}_UPDATE`;

const mapStateToProps = state => ({
  itemData: DckSelectors.selectActiveItem(state, SmartShelfItem.type),
  updating: DckSelectors.selectProcessRunning(state, process),
  updatingSuccess: DckSelectors.selectProcessSuccess(state, process)
});

const dispatchStateToProps = {
  updateItem: data => DckActionCreators.itemsSave(SmartShelfItem.type, data),
  loadAvailableLockers: data => DckActionCreators.itemsLoad(Locker, data),
  loadAvailableLockerDoors: data =>
    DckActionCreators.itemsLoad(AvailableLockerDoor, data)
};

export default connect(
  mapStateToProps,
  dispatchStateToProps
)(SmartShelfEditFormComponent);
