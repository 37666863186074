import React from "react";

import LinearProgress from "@material-ui/core/LinearProgress";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { loadingIndicator } from "../../actions/selectors";
import { styles } from "./styles";

class LoadingBarComponent extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    loading: PropTypes.bool
  };

  render() {
    const { classes, loading } = this.props;

    if (!loading) return null;

    return <LinearProgress classes={classes} />;
  }
}

export const LoadingBar = connect(
  state => ({
    loading: loadingIndicator(state)
  }),
  null
)(withStyles(styles)(LoadingBarComponent));
