export const styles = theme => {
  const drawerWidth = theme.mixins.sideBar.width;
  const closedDrawerWidth = theme.mixins.sideBar.minWidth;
  const appBarHeight = theme.mixins.appBar.height;
  const pageBarHeight = theme.mixins.pageBar.height;
  const barsHeight = appBarHeight + pageBarHeight;

  const messageBarHeight = theme.mixins.messageBar.height;

  const transition = {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  };
  const transitionBar = theme.transitions.create(
    ["width", "margin"],
    transition
  );
  const transitionContent = theme.transitions.create(
    ["width", "left"],
    transition
  );

  return {
    messageBarContainer: {
      display: "flex",
      width: "100%",
      height: messageBarHeight
    },
    appBar: {
      position: "absolute",
      height: appBarHeight,
      zIndex: theme.zIndex.drawer + 1,
      transition: transitionBar,
      backgroundColor: theme.palette.primary.main
    },
    appBarMobile: {
      position: "absolute",
      height: appBarHeight,
      zIndex: theme.zIndex.drawer - 1
    },
    appBarShift: {
      zIndex: theme.zIndex.drawer - 1,
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: transitionBar
    },
    appBarTopShift: {
      top: `${messageBarHeight}px !important`
    },
    appBarClosed: {
      zIndex: theme.zIndex.drawer - 1,
      marginLeft: closedDrawerWidth,
      width: `calc(100% - ${closedDrawerWidth}px)`,
      transition: transitionBar
    },
    toolBar: {
      display: "flex",
      padding: 0,
      margin: 0,
      minHeight: appBarHeight
    },
    menuButton: {
      margin: 0
    },
    pageBar: {
      position: "absolute",
      top: appBarHeight,
      height: pageBarHeight,
      width: `calc(100% - ${theme.spacing(7)}px)`,
      marginLeft: theme.spacing(7),
      zIndex: theme.zIndex.drawer - 2,
      background: "#fff",
      boxShadow: "0px 2px 10px 0px rgba(0,0,0,0.3)",
      transition: transitionBar,
      display: "flex",
      alignItems: "center"
    },
    pageBarMobile: {
      position: "absolute",
      top: appBarHeight,
      height: pageBarHeight,
      width: "100%",
      marginLeft: 0,
      zIndex: theme.zIndex.drawer - 2,
      background: "#fff",
      boxShadow: "0px 2px 10px 0px rgba(0,0,0,0.3)",
      display: "flex",
      alignItems: "center"
    },
    pageBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: transitionBar
    },
    pageBarTopShift: {
      top: `${messageBarHeight + appBarHeight}px !important`
    },
    content: {
      background: theme.color.grey,
      position: "fixed",
      top: barsHeight,
      left: theme.spacing(7),
      width: `calc(100% - ${theme.spacing(7)}px)`,
      height: `calc(100% - ${barsHeight}px)`,
      padding: theme.spacing(2),
      overflow: "auto",
      transition: transitionContent
    },
    contentMobile: {
      background: theme.color.grey,
      position: "fixed",
      top: barsHeight,
      left: 0,
      width: "100vw",
      height: `calc(100% - ${barsHeight}px)`,
      padding: theme.spacing(2),
      overflow: "auto"
    },
    contentShift: {
      left: `calc(${drawerWidth}px)`,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: transitionContent
    },
    contentTopShift: {
      top: `${messageBarHeight + barsHeight}px !important`,
      height: `calc(100% - ${messageBarHeight + barsHeight}px) !important`
    }
  };
};
