import React from "react";

import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";

import { ActionsSpinner } from "../ActionsSpinner";

export const ActionsWrapper = ({
  processing,
  disabled,
  readOnly,
  children
}) => (
  <div
    style={{
      display: "flex",
      flexGrow: 1,
      padding: "24px 0 8px 0"
    }}
  >
    {!readOnly ? (
      <>
        {children}
        <div
          style={{
            display: "flex",
            flexGrow: 1,
            justifyContent: "flex-end",
            alignItems: "center"
          }}
        >
          <ActionsSpinner processing={processing} />
          <Button type="reset" color="default" disabled={disabled}>
            Reset
          </Button>
          <Button type="submit" color="primary">
            Save
          </Button>
        </div>
      </>
    ) : null}
  </div>
);

ActionsWrapper.propTypes = {
  disabled: PropTypes.bool,
  processing: PropTypes.bool,
  readOnly: PropTypes.bool,
  children: PropTypes.any
};
