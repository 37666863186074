import { all, put, takeEvery, takeLatest } from "redux-saga/effects";

import {
  ActionCreators,
  Formatters,
  isActionLoad,
  Process,
  REST
} from "srx-shared";

import * as RestApi from "@api";
import { ActionTypes } from "@redux/actions";
import { WeightHistory } from "@redux/items/types";

function* loadWeightHistorySaga(action) {
  const proc = new Process.Load(WeightHistory, RestApi.LoadWeightHistory, {
    pageble: true
  });
  yield proc.start();
  try {
    const cell = yield proc.itemParam("cellIndex");
    const distributorId = yield proc.itemParam("distributorId");
    const smartShelfId = yield proc.itemParam("smartShelfId");
    const deviceSerialNumber = yield proc.itemParam("deviceSerialNumber");
    const page = yield proc.page();
    const size = yield proc.pageSize();
    const filters = yield proc.filters();
    let sorting = yield proc.sorting();
    if (cell) filters["cellIndex"] = [cell];
    if (distributorId) filters["distributorId"] = [distributorId];
    if (smartShelfId) filters["smartShelfId"] = [smartShelfId];
    if (deviceSerialNumber)
      filters["deviceSerialNumber"] = [deviceSerialNumber];
    if (!sorting.length) {
      sorting = [{ name: "createdAt", order: "desc" }];
    }
    yield proc.callApi(page + 1, size, filters, sorting);
    yield put(ActionCreators.setTotalPages(WeightHistory, -1));
    yield proc.setHasNext();
    yield proc.setEntities();
    yield proc.stop();
  } catch (ex) {
    yield proc.fail(ex);
  }
}

function* exportWeightHistorySaga() {
  const proc = new Process(
    "EXPORT",
    WeightHistory,
    RestApi.ExportWeightHistory
  );
  const masterFilters = ["cellIndex", "distributorId", "smartShelfId"];
  const availableSorting = [
    "createdAt",
    "deviceSerialNumber",
    "iotHubSerialNumber"
  ];
  yield proc.start();
  try {
    const cell = yield proc.itemParam("cellIndex");
    const distributorId = yield proc.itemParam("distributorId");
    const smartShelfId = yield proc.itemParam("smartShelfId");
    const deviceSerialNumber = yield proc.itemParam("deviceSerialNumber");
    const filters = yield proc.filters();
    let sorting = yield proc.sorting();
    if (cell) filters["cellIndex"] = [cell];
    if (distributorId) filters["distributorId"] = [distributorId];
    if (smartShelfId) filters["smartShelfId"] = [smartShelfId];
    if (deviceSerialNumber)
      filters["deviceSerialNumber"] = [deviceSerialNumber];

    if (!sorting.length || !availableSorting.includes(sorting[0].name)) {
      sorting = [{ name: "createdAt", order: "desc" }];
    }

    yield proc.callApi(filters, sorting);

    const link = proc.data;
    const filtersParams = Object.keys(filters)
      .filter(key => !masterFilters.includes(key))
      .map(key => {
        if (key !== "dates") {
          return `${key}_${filters[key][0]}`;
        } else {
          const dates = filters[key][0].split(",");
          const dateFrom = Formatters.datetime(+dates[0]);
          const dateTo = Formatters.datetime(+dates[1]);
          return `dateFrom_${dateFrom.replace(
            /[ ,/]/g,
            "_"
          )}_dateTo_${dateTo.replace(/[ ,/]/g, "_")}`;
        }
      });
    const fileName = "Weight_history_".concat(filtersParams.join("_"), ".csv");
    yield REST.downloadFileFromRemoteServer(
      link,
      filtersParams.length ? fileName : "Weight_history.csv"
    );
    yield proc.stop();
  } catch (ex) {
    yield proc.fail(ex);
  }
}

function* weightHistorySaga() {
  yield all([
    takeLatest(isActionLoad(WeightHistory), loadWeightHistorySaga),
    takeEvery(
      action => action.type === ActionTypes.WEIGHTHISTORY_EXPORT,
      exportWeightHistorySaga
    )
  ]);
}

export default weightHistorySaga;
