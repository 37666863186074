import * as V from "yup";

import { mapFormFieldsFor } from "srx-shared";

import { ShipToTemplateSettings } from "@items/types";

import { TableSettings } from "./TableSettings";

export const ShipToVmiListSettingsItem = {
  type: ShipToTemplateSettings,
  fieldsConfig: {
    enableVmiList: {
      name: "enableVmiList",
      label: "Enable VMI List",
      size: 6,
      type: "switch",
      noHelperText: true
    },
    tableSettings: {
      Component: props => <TableSettings {...props} />
    }
  },
  schema: V.object({}),
  fields: ["enableVmiList", "tableSettings"]
};

ShipToVmiListSettingsItem.formConfig = mapFormFieldsFor(
  ShipToVmiListSettingsItem
);
