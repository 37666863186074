import { Selectors } from "srx-shared";

import { LockerSettings } from "@redux/items/types";
import { store } from "@redux/store";

export const isLockerV3 = () =>
  Selectors.selectActiveItem(store.getState(), LockerSettings)
    .hardwareVersion === "v3";

export const getDoorColumnNumber = (door, lockerType) => {
  if (door.columnNumber) return door.columnNumber;

  const { doorsQuantity, columnsQuantity } = lockerType;
  return Math.ceil((door.number / doorsQuantity) * columnsQuantity);
};
