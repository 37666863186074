import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
  root: {
    padding: "24px 12px",
    backgroundColor: "#F7F7F7",
    whiteSpace: "normal"
  },
  cell: {
    width: 74,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "12px 0"
  },
  image: {
    width: 44,
    height: 44,
    objectFit: "contain",
    color: "#e2e2e2"
  },
  historyButton: {
    color: theme.palette.primary.light
  },
  historyIcon: {
    marginRight: 4,
    fontSize: 20
  }
}));
