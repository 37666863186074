import React from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Selectors } from "../../..";
import { ActionCreators } from "../../../actions";

export const CELL = {
  SELECTED: "SELECTED",
  EDIT: "EDIT"
};

export const CellContext = React.createContext({
  item: {},
  cellState: null,
  selectedRow: null,
  selectedCell: null,
  editableRow: null,
  setContext: () => {}
});

class CellNavigatorComponent extends React.Component {
  static propTypes = {
    item: PropTypes.object,
    rows: PropTypes.number,
    getUndoTop: PropTypes.func,
    undoAction: PropTypes.func,
    editableColumns: PropTypes.array
  };

  setContext = (state, callback) => {
    if (!state) return;
    if ("cellState" in state && state.cellState !== this.state.cellState) {
      window.dispatchEvent(
        new CustomEvent("cellState", { detail: state.cellState })
      );
    }
    this.setState(state, () => callback && callback());
  };

  selectRow = selectedRow => this.setContext({ selectedRow });
  selectCell = selectedCell => this.setContext({ selectedCell });
  setCellState = cellState => this.setContext({ cellState });

  state = {
    item: this.props.item || {},
    cellState: null,
    selectedRow: null,
    selectedCell: null,
    setContext: this.setContext
  };

  componentDidMount() {
    this.addListeners();
    window.dispatchEvent(new CustomEvent("cellState", { detail: null }));
  }

  componentWillUnmount() {
    this.removeListeners();
    this.setCellState(null);
  }

  addListeners = () => {
    window.addEventListener("keydown", this.handleKeyPress);
    window.addEventListener("undoEvent", this.handleUndo);
  };

  removeListeners = () => {
    window.removeEventListener("keydown", this.handleKeyPress);
    window.removeEventListener("undoEvent", this.handleUndo);
  };

  handleUndo = () => {
    const { getUndoTop, item, undoAction } = this.props;
    const undoTop = getUndoTop(item.type);
    if (!undoTop) return;

    const { selectedCell, selectedRow } = undoTop;
    this.setContext({ cellState: CELL.SELECTED, selectedCell, selectedRow });
    undoAction(item.type, undoTop);
  };

  handleKeyPress = e => {
    const { cellState } = this.state;
    const cellSelected = cellState === CELL.SELECTED;
    const cellEdited = cellState === CELL.EDIT;
    const enterEdit = () => this.setCellState(CELL.EDIT);
    const keyMove = key => e.key === key && cellSelected;

    // match = [condition: boolean, action: string | function]
    const matchCondition = match => match[0];

    const handleMatch = match => {
      if (!match) return;
      e.preventDefault();
      this.blurCell();
      const [, action] = match; // match[1]
      if (!action) return;
      typeof action === "string" ? this[action]() : action();
    };

    const matchMap = [
      //[!cellState, this.selectTopLeftCell],
      [keyMove("ArrowUp"), "moveUp"],
      [keyMove("ArrowDown"), "moveDown"],
      [keyMove("PageUp"), "moveTop"],
      [keyMove("PageDown"), "moveBottom"],
      [keyMove("ArrowLeft"), "moveLeft"],
      [keyMove("ArrowRight"), "moveRight"],
      [keyMove("Home"), "moveFirst"],
      [keyMove("End"), "moveLast"],
      [e.key === "Tab" && e.shiftKey && cellSelected, "movePrev"],
      [e.key === "Tab" && !e.shiftKey && cellSelected, "moveNext"],
      [e.code === "KeyZ" && e.ctrlKey && !cellEdited, this.handleUndo],
      [(e.key === "Enter" || e.key === "F2") && cellSelected, enterEdit]
      //[e.key === "Enter" && cellEdited, null]
    ];

    handleMatch(matchMap.find(matchCondition));
  };

  blurCell = () => {
    const { cellState } = this.state;
    cellState === CELL.EDIT && this.setCellState(CELL.SELECTED);
    return this;
  };

  lastRow = () => this.props.rows - 1;
  lastCell = () => (this.props.editableColumns || []).length - 1;

  cellIndex = cell => {
    const { editableColumns = [] } = this.props;
    return editableColumns.findIndex(el => el === cell);
  };

  isFirstRow = () => {
    const { selectedRow } = this.state;
    return selectedRow === 0;
  };

  isLastRow = () => {
    const { selectedRow } = this.state;
    return selectedRow === this.lastRow();
  };

  isFirstCell = () => {
    const { selectedCell } = this.state;
    return this.cellIndex(selectedCell) === 0;
  };

  isLastCell = () => {
    const { selectedCell } = this.state;
    return this.cellIndex(selectedCell) === this.lastCell();
  };

  moveUp = () => {
    const { selectedRow } = this.state;
    if (!this.isFirstRow()) {
      this.selectRow(selectedRow - 1);
    }
    return this;
  };

  moveDown = () => {
    const { selectedRow } = this.state;
    if (!this.isLastRow()) {
      this.selectRow(selectedRow + 1);
    }
    return this;
  };

  moveLeft = () => {
    const { editableColumns = [] } = this.props;
    const { selectedCell } = this.state;
    if (!this.isFirstCell()) {
      this.selectCell(editableColumns[this.cellIndex(selectedCell) - 1]);
    }
    return this;
  };

  moveRight = () => {
    const { editableColumns = [] } = this.props;
    const { selectedCell } = this.state;
    if (!this.isLastCell()) {
      this.selectCell(editableColumns[this.cellIndex(selectedCell) + 1]);
    }
    return this;
  };

  moveTop = () => {
    if (!this.isFirstRow()) {
      this.selectRow(0);
    }
    return this;
  };

  moveBottom = () => {
    if (!this.isLastRow()) {
      this.selectRow(this.lastRow());
    }
    return this;
  };

  moveFirst = () => {
    const { editableColumns = [] } = this.props;
    if (!this.isFirstCell()) {
      this.selectCell(editableColumns[0]);
    }
    return this;
  };

  moveLast = () => {
    const { editableColumns = [] } = this.props;
    if (!this.isLastCell()) {
      this.selectCell(editableColumns[this.lastCell()]);
    }
    return this;
  };

  movePrev = () =>
    this.isFirstCell()
      ? !this.isFirstRow() && this.moveLast().moveUp()
      : this.moveLeft();

  moveNext = () =>
    this.isLastCell()
      ? !this.isLastRow() && this.moveFirst().moveDown()
      : this.moveRight();

  selectTopLeftCell = () => {
    const { editableColumns = [] } = this.props;
    if (this.lastRow() >= 0 && this.lastCell() >= 0) {
      this.selectRow(0);
      this.selectCell(editableColumns[0]);
      this.setCellState(CELL.SELECTED);
    }
  };

  render() {
    return (
      <CellContext.Provider value={this.state}>
        {this.props.children}
      </CellContext.Provider>
    );
  }
}

const mapStateToProps = state => ({
  getUndoTop: itemType => Selectors.undoTop(state, itemType)
});

const mapDispatchToProps = {
  undoAction: (itemType, data) => ActionCreators.undoAction(itemType, data)
};

export const CellNavigator = connect(
  mapStateToProps,
  mapDispatchToProps
)(CellNavigatorComponent);

CellNavigator.displayName = "CellNavigator";
