import React from "react";

import Grid from "@material-ui/core/Grid";
import withStyles from "@material-ui/core/styles/withStyles";
import _get from "lodash/get";
import PropTypes from "prop-types";

import { ExpansionPanel } from "../../ExpansionPanel";
import { styles } from "./styles";

class ExpansionGroupComponent extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    contentProps: PropTypes.object,
    form: PropTypes.object,
    fields: PropTypes.array,
    expanded: PropTypes.bool,
    fixed: PropTypes.bool,
    children: PropTypes.any
  };

  state = {
    expanded: this.props.expanded
  };

  getError = field => {
    const { form } = this.props;
    return _get(form.touched, field) && _get(form.errors, field);
  };

  hasErrors = () => {
    const { fields } = this.props;
    return fields.reduce(
      (errors, field) => errors || this.getError(field),
      false
    );
  };

  componentDidUpdate(prevProps) {
    if (this.hasErrors() && !this.state.expanded) {
      this.setState({ expanded: true });
    }
  }

  handlePanelChange = (e, expanded) => {
    const { fixed } = this.props;
    !fixed && !this.hasErrors() && this.setState({ expanded });
  };

  render() {
    const {
      classes,
      children,
      contentProps = {},
      expanded,
      form,
      fields,
      ...rest
    } = this.props;

    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          padding: "0 16px 16px 0"
        }}
      >
        <ExpansionPanel
          {...rest}
          onChange={this.handlePanelChange}
          expanded={this.state.expanded}
        >
          <Grid
            container
            spacing={3}
            item
            xs={12}
            {...contentProps}
            style={{ padding: "0 0 16px 24px", ...contentProps.style }}
          >
            {children}
          </Grid>
        </ExpansionPanel>
      </div>
    );
  }
}

export const ExpansionGroup = withStyles(styles)(ExpansionGroupComponent);
