import React from "react";

import { Popover, Grid, Paper } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import CancelIcon from "@material-ui/icons/ClearOutlined";
import SubmitIcon from "@material-ui/icons/DoneOutlined";
import _pick from "lodash/pick";
import PropTypes from "prop-types";

import { IconButton } from "../IconButton";
import { styles } from "./styles";

export const inlineEditFormFor = (
  item,
  // default options
  { InlineEditForm = null } = {}
) => {
  class Wrapper extends React.PureComponent {
    static propTypes = {
      classes: PropTypes.object.isRequired,
      fieldConfig: PropTypes.object,
      anchorEl: PropTypes.any,
      value: PropTypes.any,
      row: PropTypes.object,
      original: PropTypes.object,
      disabled: PropTypes.bool,
      onChange: PropTypes.func,
      onFocus: PropTypes.func,
      onReset: PropTypes.func,
      onBlur: PropTypes.func
    };

    state = {
      open: true
    };

    componentDidMount() {
      this.setState({ open: true });
      this.handleFocus();
    }

    handleChange = value => {
      this.props.onChange && this.props.onChange(value, this.handleBlur);
    };

    handleFocus = () => this.props.onFocus && this.props.onFocus();
    handleReset = () => this.props.onReset && this.props.onReset();

    handleClose = () => {
      this.setState({ open: false });
      this.handleReset();
    };

    handleBlur = () => this.props.onBlur && this.props.onBlur();

    renderActions = form => {
      const { classes, disabled = false } = this.props;
      return (
        <div className={classes.actionsBlock}>
          <IconButton
            icon={<CancelIcon />}
            onClick={this.handleClose}
            data-testid="close"
          />
          <IconButton
            type="submit"
            color="primary"
            icon={<SubmitIcon />}
            disabled={disabled}
            data-testid="submit"
          />
        </div>
      );
    };

    render() {
      const { value, classes, anchorEl, row = {}, original = {} } = this.props;
      const originalData = value || row || original || {};
      const initialData = _pick(originalData, item.fieldsForForm);

      return (
        <Popover
          id="inline-edit-form-popover"
          open={this.state.open}
          anchorEl={anchorEl}
          transformOrigin={{
            vertical: 16,
            horizontal: 8
          }}
          onClose={this.handleClose}
        >
          <Paper style={{ padding: "16px 16px 16px 8px" }}>
            <Grid container item spacing={3} className={classes.formWrapper}>
              <InlineEditForm
                vertical={true}
                initialData={initialData}
                renderActions={this.renderActions}
                onSubmit={this.handleChange}
              />
            </Grid>
          </Paper>
        </Popover>
      );
    }
  }

  return withStyles(styles)(Wrapper);
};
