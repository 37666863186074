import { pages } from "@routes/pages";

export const menuItems = [
  pages.distributors,
  pages.shipToTemplates,
  pages.hardware,
  pages.fees,
  pages.costSavings,
  pages.documents,
  pages.customerTypes,
  pages.marketTypes,
  pages.binTypes,
  pages.lockerTypes,
  pages.upcGtinCatalog
];
