import { useState } from "react";

import { Grid, Paper } from "@material-ui/core";
import ConfigureIcon from "@material-ui/icons/SettingsOutlined";
import cn from "classnames";
import { DckActionCreators, procLoading } from "dck-redux";
import * as PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { useDispatchActions } from "srx-shared";
import { getPlanogramSectionStyles } from "srx-shared/formatters";
import { IconButton } from "srx-shared/mui";

import * as Types from "@items/types";

import { useStyles } from "../styles";
import { DoorConfigDialog } from "./DoorConfigDialog";
import DoorState from "./DoorState";
import { MergeButton } from "./MergeButton";
import { SplitButton } from "./SplitButton";
import { StatusIcon } from "./StatusIcon";

const actions = {
  loadAvailableDistributorShelves: door =>
    DckActionCreators.itemsLoad(Types.LockerDoor, { door })
};

DoorConfig.propTypes = {
  id: PropTypes.number,
  doorSerialNumbers: PropTypes.array,
  door: PropTypes.object,
  doors: PropTypes.object,
  lockerId: PropTypes.number,
  distributorId: PropTypes.number,
  locker: PropTypes.object,
  selectedDoors: PropTypes.object,
  setSelectedDoors: PropTypes.func
};

export function DoorConfig(props) {
  const {
    id,
    door,
    lockerId,
    distributorId,
    doorSerialNumbers,
    locker,
    selectedDoors,
    setSelectedDoors
  } = props;

  const cls = useStyles();

  const isLockerV3 = locker.hardwareVersion === "v3";

  const {
    noWeight,
    doorSerialNumber,
    smartShelfHardware,
    serialNumberValidation,
    smartShelfSerialNumberValidation
  } = door;

  const shelfSerialNumber = smartShelfHardware?.serialNumber;
  const shelfId = smartShelfHardware?.id;

  const [open, setOpen] = useState(false);

  const loading = useSelector(state => procLoading(state, Types.LockerConfig));

  const { loadAvailableDistributorShelves } = useDispatchActions(actions);

  const size = door.indexes.length;

  if (!size) return null;

  const isMerged = size > 1;

  const handleOpenDialog = () => {
    loadAvailableDistributorShelves(door);
    setOpen(true);
  };

  const handleCloseDialog = () => setOpen(false);

  const configProps = {
    id: door.id,
    number: door.number,
    lockerId,
    distributorId,
    noWeight,
    doorSerial: doorSerialNumber,
    shelfSerial: shelfId,
    doorSerialNumbers
  };

  const NoShelfMsg = () => (
    <Grid container alignItems="center" justify="center">
      <span className={cls.clsNotAssigned}>
        There is no smart shelf assigned
      </span>
    </Grid>
  );

  const handleClick = () => {
    if (selectedDoors.has(door)) {
      selectedDoors.delete(door);
      setSelectedDoors(new Set(selectedDoors));
    } else {
      setSelectedDoors(new Set(selectedDoors.add(door)));
    }
  };
  const isSelected = selectedDoors.has(door);

  return (
    <Paper
      style={getPlanogramSectionStyles(size)}
      className={cn(
        cls.doorPaper,
        open && cls.selectedPaper,
        noWeight && cls.noWeightPaper,
        isMerged && cls.mergedDoor,
        isSelected && cls.selectedPaper
      )}
      onClick={handleClick}
    >
      <Grid container className={cls.doorContainer}>
        <DoorState id={id} door={door} />
        {!noWeight && !shelfSerialNumber && !doorSerialNumber ? (
          <NoShelfMsg />
        ) : (
          <>
            <Grid item xs={5} className={cls.serialContainer}>
              <div className={cls.serialInput}>
                <div className={cls.clsLabel}>Door Serial Number</div>
                <div className={cls.clsText}>
                  <span>{doorSerialNumber || "-"}</span>
                </div>
              </div>
              <div className={cls.statusIcon}>
                <StatusIcon status={serialNumberValidation} />
              </div>
            </Grid>
            <Grid item xs={5} className={cls.serialContainer}>
              {!noWeight && (
                <>
                  {shelfSerialNumber && (
                    <>
                      <div className={cls.serialInput}>
                        <div className={cls.clsLabel}>
                          Smart Shelf Serial Number
                        </div>
                        <div className={cls.clsText}>{shelfSerialNumber}</div>
                      </div>
                      <div className={cls.statusIcon}>
                        <StatusIcon status={smartShelfSerialNumberValidation} />
                      </div>
                    </>
                  )}
                  {!shelfSerialNumber && <NoShelfMsg />}
                </>
              )}
            </Grid>
          </>
        )}
        <Grid
          item
          xs={1}
          className={cls.iconWrapper}
          style={{ marginRight: -4 }}
        >
          <IconButton
            icon={<ConfigureIcon />}
            tip="Configure Door"
            onClick={handleOpenDialog}
            disabled={loading}
            data-testid="configure"
          />
          {isLockerV3 && (
            <>
              <MergeButton door={door} {...props} />
              {isMerged && <SplitButton door={door} {...props} />}
            </>
          )}
        </Grid>
      </Grid>
      <DoorConfigDialog
        {...configProps}
        open={open}
        onClose={handleCloseDialog}
      />
    </Paper>
  );
}
