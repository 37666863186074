export const styles = theme => ({
  root: {
    display: "flex",
    alignItems: "center",
    borderRadius: 4,
    fontSize: 13,
    fontWeight: "bold",
    padding: "12px 16px",
    [theme.breakpoints.down("sm")]: {
      padding: "8px 12px",
      fontSize: 12
    }
  },
  icon: {
    marginRight: 12
  }
});
