/* eslint react/prop-types: "off" */
import React from "react";

import { Formatters, mapFiltersFor } from "srx-shared";
import { Cell } from "srx-shared/mui";

import { WeightHistory } from "@redux/items/types";

export const WeightHistoryItem = {
  type: WeightHistory,
  name: "WeightHistory",
  title: "Weight History"
};

// table
WeightHistoryItem.columns = [
  {
    Header: "Cell number",
    accessor: "cellIndex",
    Cell: ({ value }) => <Cell>{`Cell ${value}`}</Cell>
  },
  {
    Header: "Door/Section",
    accessor: "sectionNumber",
    Cell: ({ value, row: { deviceSerialNumber } }) => (
      <Cell>{`${
        deviceSerialNumber.includes("LOCKER") ? "Door" : "Section"
      } ${value}`}</Cell>
    )
  },
  {
    Header: "Event Type",
    accessor: "action",
    Cell: props => <Cell {...props} />
  },
  {
    Header: "Weight (g)",
    accessor: "currentWeight",
    Cell: ({ value }) => <Cell>{value !== null ? `${value} g` : "-"}</Cell>
  },
  {
    Header: "Date",
    accessor: "createdAt",
    Cell: ({ value }) => <Cell>{Formatters.datetime(value)}</Cell>
  },
  {
    Header: "IoT Hub Address",
    accessor: "shelfAddress",
    Cell: ({ value }) => <Cell>{Formatters.hubAddress(value)}</Cell>
  },
  {
    Header: "IoT Hub SN",
    accessor: "iotHubSerialNumber",
    Cell: props => <Cell {...props} />
  },
  {
    Header: "Device SN",
    accessor: "deviceSerialNumber",
    Cell: props => <Cell {...props} />
  },
  {
    Header: "Distributor",
    accessor: "distributor.name",
    Cell: props => <Cell {...props} />
  }
];

//filters
WeightHistoryItem.filtersConfig = {
  action: {
    name: "Event Type",
    type: "select",
    options: [
      { label: "CALIBRATION", value: "CALIBRATION" },
      { label: "DEBUG", value: "DEBUG" },
      { label: "OHI_PROCESSING", value: "OHI_PROCESSING" },
      { label: "OPEN_DOOR_CHANGE", value: "OPEN_DOOR_CHANGE" },
      { label: "POLLING", value: "POLLING" }
    ]
  },
  dates: {
    name: "Dates",
    type: "daterange"
  },
  deviceSerialNumber: {
    name: "Device SN"
  },
  iotHubSerialNumber: {
    name: "IoT Hub SN"
  }
};
WeightHistoryItem.filters = mapFiltersFor(WeightHistoryItem, [
  "action",
  "dates",
  "deviceSerialNumber",
  "iotHubSerialNumber"
]);
