import React from "react";

import { Paper } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

import { MergeSplitCellsButton } from "./MergeSplitCellsButton";
import { styles } from "./styles";

const StickyToolbarComponent = props => {
  const handleClick = e => e.stopPropagation();

  const { classes, smartShelf } = props;
  const { toolbar } = classes;
  return (
    <div onClick={handleClick}>
      <Paper elevation={4} className={toolbar}>
        <MergeSplitCellsButton
          smartShelf={smartShelf}
          context={props.context}
        />
      </Paper>
    </div>
  );
};

StickyToolbarComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  smartShelf: PropTypes.object,
  context: PropTypes.object
};

export const StickyToolbar = withStyles(styles)(StickyToolbarComponent);
