/* eslint react/display-name: "off" */
/* eslint react/prop-types: "off" */
import React from "react";

import * as V from "yup";

import { mapFiltersFor, mapFormFieldsFor } from "srx-shared";
import { Cell } from "srx-shared/mui";

import { Actions } from "@comp/UpcGtinCatalog/Actions";
import { UpcGtinCatalog } from "@items/types";

export const UpcGtinCatalogItem = {
  type: UpcGtinCatalog,
  name: "UpcGtinCatalog",
  title: "UPC & GTIN"
};

// table
UpcGtinCatalogItem.columns = [
  {
    Header: "UPC",
    accessor: "upc",
    sortable: false,
    Cell: props => <Cell {...props} />
  },
  {
    Header: "GTIN",
    accessor: "gtin",
    sortable: false,
    Cell: props => <Cell {...props} />
  },
  {
    Header: "Manufacturer",
    accessor: "manufacturer",
    sortable: false,
    Cell: props => <Cell {...props} />
  },
  {
    Header: "Manufacturer SKU",
    accessor: "manufacturerPartNumber",
    sortable: false,
    Cell: props => <Cell {...props} />
  },
  {
    Header: "Distributor Name",
    accessor: "distributorName",
    sortable: false,
    Cell: props => <Cell {...props} />
  },
  {
    Header: "Actions",
    maxWidth: 100,
    resizable: false,
    sortable: false,
    Cell: ({ row }) => <Actions row={row._original} />
  }
];

// filters
UpcGtinCatalogItem.filtersConfig = {
  upc: {
    name: "UPC"
  },
  gtin: {
    name: "GTIN"
  },
  manufacturer: {
    name: "Manufacturer"
  },
  manufacturerPartNumber: {
    name: "Manufacturer SKU"
  }
};
UpcGtinCatalogItem.filters = mapFiltersFor(UpcGtinCatalogItem, [
  "upc",
  "gtin",
  "manufacturer",
  "manufacturerPartNumber"
]);

// form fields
UpcGtinCatalogItem.fieldsConfig = {
  upc: {
    label: "UPC",
    initialValue: ""
  },
  gtin: {
    label: "GTIN",
    initialValue: ""
  },
  manufacturer: {
    label: "Manufacturer",
    initialValue: ""
  },
  manufacturerPartNumber: {
    label: "Manufacturer SKU",
    initialValue: ""
  }
};

UpcGtinCatalogItem.schema = V.object({
  upc: V.string().label("UPC").max(255).nullable(),
  gtin: V.string().label("GTIN").max(255).nullable(),
  manufacturer: V.string().label("Manufacturer").max(255).nullable(),
  manufacturerPartNumber: V.string()
    .label("Manufacturer SKU")
    .max(255)
    .nullable()
});

UpcGtinCatalogItem.createForm = mapFormFieldsFor(UpcGtinCatalogItem, [
  "upc",
  "gtin",
  "manufacturer",
  "manufacturerPartNumber"
]);

UpcGtinCatalogItem.editForm = mapFormFieldsFor(UpcGtinCatalogItem, [
  "upc",
  "gtin",
  "manufacturer",
  "manufacturerPartNumber"
]);

UpcGtinCatalogItem.modalEditForm = true;
