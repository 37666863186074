import React from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";

import { ActionCreators } from "../../actions/index";
import { getItemData } from "../../actions/selectors";
import { SRX_PORTAL_TITLE, ItemTypeApplication } from "../../config";

export const setDocumentTitle = (pageTitle, pageDescription) => {
  const title = [
    (typeof pageTitle === "string" ? pageTitle : pageDescription) || "",
    SRX_PORTAL_TITLE[global.window.portal] || "",
    "SRX"
  ];
  if (title[0].startsWith("SRX")) {
    global.document.title = title[0];
  } else {
    global.document.title = title.filter(Boolean).join(" - ");
  }
};

export const withPageTitle =
  (title, description, options = {}) =>
  WrappedComponent => {
    class Wrapper extends React.PureComponent {
      static propTypes = {
        setPageTitle: PropTypes.func,
        pageTitle: PropTypes.node
      };

      componentDidMount() {
        const { hidden } = options;
        this.props.setPageTitle(hidden ? " " : title);
        setDocumentTitle(title, description);
      }

      componentWillUnmount() {
        this.props.setPageTitle(null);
        setDocumentTitle(null);
      }

      componentDidUpdate() {
        if (!this.props.pageTitle && title) {
          this.props.setPageTitle(title);
          setDocumentTitle(title, description);
        }
      }

      render() {
        const { children, ...rest } = this.props;
        return <WrappedComponent {...rest}>{children}</WrappedComponent>;
      }
    }

    const mapStateToProps = state => ({
      pageTitle: getItemData(state, ItemTypeApplication, "pageTitle")
    });

    const mapDispatchToProps = {
      setPageTitle: title => ActionCreators.setPageTitle(title)
    };

    return connect(mapStateToProps, mapDispatchToProps)(Wrapper);
  };
