import { createTheme } from "srx-shared";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#325",
      light: "#769"
    },
    secondary: {
      main: "#EF7E5C"
    }
  }
});
