export const styles = theme => ({
  root: {
    display: "block",
    visibility: "visible",
    position: "absolute",
    width: "100%",
    height: theme.mixins.loadingBar.height,
    zIndex: theme.zIndex.drawer + 10,
    background: theme.mixins.loadingBar.background
  },
  bar: {
    background: theme.mixins.loadingBar.color
  }
});
