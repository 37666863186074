import { useEffect, useMemo } from "react";

import { DckActionCreators } from "dck-redux";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import {
  breadcrumbsFor,
  Selectors,
  useDispatchActions,
  usePageTitle
} from "srx-shared";

import {
  LockerConfig,
  LockerSettings,
  Hardware,
  HardwareStates
} from "@items/types";
import { pages } from "@routes/pages";

import { Planogram } from "./Planogram";
import { useStyles } from "./styles";

const actions = {
  loadLockerConfig: lockerId =>
    DckActionCreators.itemSelect(LockerConfig, lockerId),
  loadLockerSettings: lockerId =>
    DckActionCreators.itemSelect(LockerSettings, lockerId),
  loadLocker: id => DckActionCreators.itemSelect(Hardware, id),
  loadDeviceState: lockerNumber =>
    DckActionCreators.itemSelect(HardwareStates, lockerNumber)
};

const BreadcrumbsComponent = breadcrumbsFor();

export default function LockerConfiguration() {
  usePageTitle("Planogram");

  const cls = useStyles();

  const { id } = useParams();

  const locker = useSelector(state =>
    Selectors.selectActiveItem(state, LockerSettings)
  );
  const lockerConfig = useSelector(state =>
    Selectors.selectActiveItem(state, LockerConfig)
  );

  const { loadLockerConfig, loadLockerSettings, loadLocker, loadDeviceState } =
    useDispatchActions(actions);

  const doors = useMemo(
    () =>
      lockerConfig?.data.reduce(
        (acc, door) => ({ ...acc, [door.number]: door }),
        {}
      ),
    [lockerConfig]
  );

  useEffect(() => {
    loadLockerConfig(id);
    loadLockerSettings(id);
    loadLocker(id);
  }, [id, loadLocker, loadLockerConfig, loadLockerSettings]);

  useEffect(() => {
    locker && loadDeviceState(locker.value);
  }, [loadDeviceState, locker]);

  const breadcrumbsList = () => [
    { title: pages.hardware.title, to: pages.hardware.path },
    { title: "Planogram" },
    { title: locker ? locker.value : "" }
  ];
  const planogramProps = {
    doors,
    locker
  };

  return (
    <>
      {locker && <BreadcrumbsComponent renderList={breadcrumbsList} />}
      <div className={cls.container}>
        {lockerConfig && locker && <Planogram {...planogramProps} />}
      </div>
    </>
  );
}
