import React from "react";

import { Grid } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormHelperText from "@material-ui/core/FormHelperText/FormHelperText";
import withStyles from "@material-ui/core/styles/withStyles";
import cn from "classnames";
import PropTypes from "prop-types";

import { styles } from "./styles";

class CheckGroupComponent extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    name: PropTypes.string,
    label: PropTypes.node,
    helperText: PropTypes.string,
    form: PropTypes.object,
    noHelperText: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    options: PropTypes.array,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    disabled: PropTypes.bool,
    error: PropTypes.any,
    onChange: PropTypes.func,
    column: PropTypes.bool
  };

  handleChange = (value, checked) => {
    const { onChange } = this.props;
    const previousValues = this.props.value || [];
    const values = checked
      ? [...previousValues, value]
      : previousValues.filter(el => el !== value);

    onChange && onChange(values);
  };

  render() {
    const {
      classes,
      value,
      label = null,
      options,
      disabled,
      error,
      column,
      helperText = "",
      noHelperText = false,
      form
    } = this.props;

    return (
      <div className={classes.root}>
        <FormControl component="fieldset" className={classes.formControl}>
          {label && (
            <div
              className={cn(classes.groupLabel, disabled && classes.disabled)}
              style={{ color: error ? "#F44336" : "#555" }}
            >
              {label}
            </div>
          )}
          <FormGroup>
            <Grid container direction={column ? "column" : "row"}>
              {options.map((option, index) => (
                <Grid item xs={12} sm={6} md={option.size || true} key={index}>
                  <FormControlLabel
                    classes={{
                      root: cn(classes.option, column && classes.columnOption),
                      label: classes.label
                    }}
                    control={
                      <Checkbox
                        color="primary"
                        data-testid={option.value}
                        className={classes.checkbox}
                        checked={
                          value && Array.isArray(value)
                            ? value.includes(option.value)
                            : false
                        }
                        onChange={(e, checked) =>
                          this.handleChange(option.value, checked)
                        }
                      />
                    }
                    label={option.label}
                    disabled={
                      disabled ||
                      (option.disabled
                        ? typeof option.disabled === "function"
                          ? option.disabled(value, form)
                          : option.disabled
                        : false)
                    }
                  />
                </Grid>
              ))}
            </Grid>
          </FormGroup>
        </FormControl>
        {!noHelperText && (
          <FormHelperText
            className={classes.helperText}
            style={{ color: error && "#F44336" }}
            margin="dense"
            filled={true}
          >
            {helperText}
          </FormHelperText>
        )}
      </div>
    );
  }
}

export const CheckGroupField = withStyles(styles)(CheckGroupComponent);
