import React, { useContext } from "react";

import { Button } from "@material-ui/core";
import PropTypes from "prop-types";

const getSelectedCellIds = selectedCells =>
  Array.from(selectedCells.values()).sort((a, b) => a - b);

const MergeSplitCellsButtonComponent = props => {
  const context = useContext(props.context);
  const canMerge = () => {
    const { smartShelf } = props;
    const { selectedCells } = context;

    if (!smartShelf || selectedCells.size < 2) return false;

    const { cells } = smartShelf;
    const [firstId, ...restIds] = getSelectedCellIds(selectedCells);
    const lastId = restIds.pop();

    let canMerge = true;
    cells.forEach((cell, id) => {
      if (!canMerge || id < firstId || id > lastId) return;
      if (cell.location || !selectedCells.has(id)) {
        canMerge = false;
        return;
      }
    });

    return canMerge;
  };

  const canSplit = () => {
    const { selectedCells } = context;
    const { smartShelf } = props;
    if (selectedCells.size !== 1) return false;
    const cellId = selectedCells.values().next().value;
    const cell = smartShelf.cells[cellId];
    const canSplit = cell.indexes.length > 1;
    return canSplit;
  };

  const mergeCells = () => {
    const { selectedCells, handleUpdates, clearSelection } = context;
    const { smartShelf } = props;
    const cells = smartShelf.cells;
    const [firstId] = getSelectedCellIds(selectedCells);
    let indexes = [];

    selectedCells.forEach(cell => {
      indexes = indexes.concat(cells[cell].indexes);
    });
    const newCell = {
      indexes: indexes.sort()
    };

    cells.splice(firstId, selectedCells.size, newCell);
    handleUpdates && handleUpdates(cells);
    clearSelection && clearSelection();
  };

  const splitCell = () => {
    const { selectedCells, handleUpdates, clearSelection } = context;
    const { smartShelf } = props;
    const cells = smartShelf.cells;
    const cellId = selectedCells.values().next().value;
    const selectedCell = cells[cellId];
    const newCells = selectedCell.indexes.map(index => ({
      indexes: [index]
    }));
    cells.splice(cellId, selectedCells.size, ...newCells);
    handleUpdates && handleUpdates(cells);
    clearSelection && clearSelection();
  };

  let withMerge = canMerge();
  let withSplit = canSplit();
  const color = withSplit ? "secondary" : "primary";
  const disabled = !withMerge && !withSplit;
  const label = withSplit ? "SPLIT CELL" : "MERGE CELLS";
  const style = { width: 120, margin: 5 };
  const handleClick = withMerge ? mergeCells : withSplit ? splitCell : () => {};

  return (
    <Button
      color={color}
      style={style}
      disabled={disabled}
      onClick={handleClick}
    >
      {label}
    </Button>
  );
};

MergeSplitCellsButtonComponent.propTypes = {
  smartShelf: PropTypes.object,
  context: PropTypes.object
};

export const MergeSplitCellsButton = MergeSplitCellsButtonComponent;
