import React, { useEffect } from "react";
import { useMemo } from "react";

import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";

import { ActionCreators } from "../../actions";
import { ItemTypeApplication } from "../../config";
import { useDispatchActions } from "../../hooks";
import { getItemData } from "../../redux";
import { getAllowedEntries } from "../../utils";

const actions = {
  setPageTabs: tab =>
    ActionCreators.setItemData(ItemTypeApplication, "pageTabs", tab),
  setCurrentTab: tab =>
    ActionCreators.setItemData(ItemTypeApplication, "currentTab", tab)
};

PageTabContainerComponent.propTypes = {
  tabs: PropTypes.array
};
function PageTabContainerComponent(props) {
  const { tabs } = props;
  const { hash } = useLocation();
  const { replace } = useHistory();

  const acls = useSelector(state =>
    getItemData(state, ItemTypeApplication, "acls")
  );

  const { setPageTabs, setCurrentTab } = useDispatchActions(actions);

  const allowedTabs = useMemo(
    () => getAllowedEntries(acls, tabs),
    [acls, tabs]
  );

  const key = hash.replace("#", "");
  const tab = allowedTabs.find(tab => tab.key === key) || allowedTabs[0];

  useEffect(() => {
    setPageTabs(allowedTabs);
    return () => {
      setPageTabs(null);
      setCurrentTab(null);
    };
  }, [allowedTabs, setCurrentTab, setPageTabs]);

  useEffect(() => {
    setCurrentTab(tab);
  }, [tab, setCurrentTab]);

  useEffect(() => {
    if (key !== tab.key) replace(`#${tab.key}`);
  }, [key, tab, replace]);

  const { component: Component } = tab;

  if (tab.acls) {
    global.currentAcl = tab.acls;
  }

  return <Component />;
}

export const PageTabContainer = React.memo(PageTabContainerComponent);
