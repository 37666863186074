import Ok from "@material-ui/icons/CheckCircle";
import Warning from "@material-ui/icons/Warning";
import * as PropTypes from "prop-types";

export const StatusIcon = ({ status }) => {
  switch (status) {
    case "SUCCESSFUL":
      return <Ok style={{ color: "#4CAF50" }} />;
    case "WARNING":
      return <Warning style={{ color: "#EF8A20" }} />;
    case "NO":
    default:
      return null;
  }
};

StatusIcon.propTypes = {
  status: PropTypes.string
};
