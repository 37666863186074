import React from "react";

import { withStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import { compose } from "redux";

import { styles } from "./styles";

export const Link = withStyles(styles)(({ to, classes, children }) => (
  <RouterLink to={to} className={classes.link}>
    <div>{children}</div>
  </RouterLink>
));

class BreadcrumbsComponent extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    list: PropTypes.array,
    divider: PropTypes.any,
    ending: PropTypes.any
  };
  static defaultProps = {
    divider: ">",
    ending: null
  };
  render() {
    const { classes, list, ending, divider } = this.props;
    return list && list.length > 0 ? (
      <div className={classes.root}>
        <span>
          {list.map((el, index) => {
            return (
              <React.Fragment key={index}>
                {el.to ? (
                  <Link to={el.to}>{el.title}</Link>
                ) : (
                  <span className={classes.text}>
                    <div>{el.title}</div>
                  </span>
                )}
                {index + 1 < list.length && (
                  <span className={classes.divider}>{divider}</span>
                )}
              </React.Fragment>
            );
          })}
        </span>
        {ending && <span className={classes.ending}>{ending}</span>}
      </div>
    ) : null;
  }
}

export const Breadcrumbs = compose(
  withStyles(styles),
  withWidth()
)(BreadcrumbsComponent);
