import React from "react";

import cn from "classnames";
import PropTypes from "prop-types";

import { CellContext, CELL } from "../CellNavigator";
import { LinkCell } from "../LinkCell";

class TrDesktopComponent extends React.PureComponent {
  static contextType = CellContext;
  static propTypes = {
    index: PropTypes.number,
    selected: PropTypes.bool,
    className: PropTypes.string
  };

  isEditRow = () => {
    const context = this.context || {};
    const { index } = this.props;
    const { selectedRow, cellState } = context;
    return cellState === CELL.EDIT && selectedRow === index;
  };

  render() {
    const { children, className, selected, ...rest } = this.props;
    const isEditRow = this.isEditRow();
    const tds = children.map(el => ({
      ...el,
      props: { ...el.props, column: el.key }
    }));

    return (
      <div
        className={cn(
          "rt-tr",
          isEditRow && "rt-tr-edited",
          selected && "rt-tr-selected",
          className
        )}
        role="row"
        {...rest}
      >
        {tds}
      </div>
    );
  }
}

const TrMobileComponent = props => {
  const { children, className, ...rest } = props;
  const {
    props: {
      children: {
        props: { to }
      }
    }
  } = children[0];

  const tds = children.map(el => ({
    ...el,
    props: { ...el.props, column: el.key }
  }));
  const content = (
    <div
      className={cn("rt-tr", "rt-tr-mobile", className)}
      role="row"
      {...rest}
    >
      {tds}
    </div>
  );

  return to ? <LinkCell to={to}>{content}</LinkCell> : content;
};

TrMobileComponent.propTypes = {
  className: PropTypes.string
};

TrMobileComponent.displayName = "Tr";

export const TrComponent = props => {
  const { children, mobile, ...rest } = props;
  const Tr = mobile ? TrMobileComponent : TrDesktopComponent;
  return <Tr {...rest}>{children}</Tr>;
};

TrComponent.propTypes = {
  mobile: PropTypes.bool
};

TrComponent.displayName = "Tr";
