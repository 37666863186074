import { getTimes24 } from "srx-shared";

import version from "./version.json";

export const BUILD_VERSION = version;
export const STAGE = process.env.REACT_APP_STAGE || "dev";
export const PATH_TO_ASSETS = `${process.env.PUBLIC_URL}/assets`;

export const DASHBOARD_TITLE = "SRX Admin Portal";
export { TIME_EVERY_OPTIONS, DAYS } from "srx-shared";
export const DEFAULT_TIMEZONE = "America/New_York (-05:00)";

export const DEVICE_TYPE = {
  RFID: "RFID",
  LOCKER: "Locker",
  STORAGE: "Storage",
  IOTHUB: "IoTHub",
  VENDING: "Vending",
  CAMERA: "Camera"
};

export const LOCKER_RASPBERRY_URL = `http://locker.lan/${
  STAGE === "prod" ? "" : STAGE + "/"
}`;

export const IOTHUB_URL = `http://srxiothub.lan/${
  STAGE === "prod" ? "" : STAGE + "/"
}`;

export const DOCUMENT_STATUS = {
  APPROVED: "APPROVED",
  REJECTED: "REJECTED"
};

export const MAX_UNDO = 50;

export const DEVICE_TYPE_OPTIONS = [
  {
    value: "RFID",
    label: "RFID Reader",
    name: "RFID Reader"
  },
  {
    value: "LOCKER",
    label: "Locker",
    name: "Locker"
  },
  {
    value: "STORAGE",
    label: "Storage",
    name: "Storage"
  },
  {
    value: "IOTHUB",
    label: "IoT Hub",
    name: "IoTHub"
  },
  {
    value: "CAMERA",
    label: "IP Camera",
    name: "IP Camera"
  },
  {
    value: "VENDING",
    label: "Vending",
    name: "Vending"
  }
];

export const VMI_TYPES_OPTIONS = [
  "RFID",
  "LABEL",
  "BUTTON",
  "LOCKER",
  "VENDING",
  "SMARTSHELF"
].map(el => ({ value: el, label: el, name: el }));

const IOTHUB_HELP_TEXT = (imageDownloadUrl, deviceName) => (
  <>
    <div style={{ textAlign: "center" }}>
      <p>
        <b>Device name: {deviceName}</b>
      </p>
      <p>
        Please follow the instruction to provision a new IoT HUB:
        <br />
        <small>
          (The timeout for starting usage of new Serial Number is 24 hours)
        </small>
      </p>
    </div>
    <ol>
      <li>
        Download <a href={imageDownloadUrl}>OS Image</a> and write it to your
        SD-Card using{" "}
        <a
          href="https://www.balena.io/etcher/"
          rel="noopener noreferrer"
          target="_blank"
        >
          balenaEtcher
        </a>
      </li>
      <li>Plug the card to appropriate Raspberry Pi device and power on</li>
      <li>
        Connect to Locker WI-FI{" "}
        <small>it{"'"}s name starts from SRXIOTHUB_</small> with password:{" "}
        <div
          style={{ display: "flex", justifyContent: "center", marginRight: 50 }}
        >
          <b>I0tHubWiFi</b>
        </div>
      </li>
      <li>
        Open your browser and go to{" "}
        <a href={IOTHUB_URL} rel="noopener noreferrer" target="_blank">
          {IOTHUB_URL}
        </a>
      </li>
      <li>Use code below to provision IoT HUB device:</li>
    </ol>
  </>
);

export const PROVISION_HELP_TEXT = {
  IOTHUB: IOTHUB_HELP_TEXT
};

export const TIME_AFTER_OPTIONS = [
  ...getTimes24(),
  { label: "48 hours", value: "48:00" },
  { label: "72 hours", value: "72:00" }
];

export const SUBMIT_QUOTED = "QUOTED";
export const SUBMIT_ORDERED_AND_QUOTED = "ORDERED_AND_QUOTED";
