import React from "react";

import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

import { styles } from "./styles";

function SideBarBuildVersionComponent(props) {
  const { classes, open } = props;
  const version = window.SRX_PLATFORM_VERSION;

  if (!open || !version) return null;

  return <div className={classes.text}>{version}</div>;
}

SideBarBuildVersionComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool
};

export const SideBarBuildVersion = withStyles(styles)(
  SideBarBuildVersionComponent
);
