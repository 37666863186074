import React from "react";

import { Button, withWidth } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import TemplateIcon from "@material-ui/icons/AttachFile";
import ValidateIcon from "@material-ui/icons/CheckCircleOutline";
import DownloadIcon from "@material-ui/icons/CloudDownload";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import AssociateIcon from "@material-ui/icons/Link";
import SubmitIcon from "@material-ui/icons/PostAdd";
import SearchIcon from "@material-ui/icons/Search";
import ImportIcon from "@material-ui/icons/VerticalAlignBottom";
import ExportIcon from "@material-ui/icons/VerticalAlignTop";
import PropTypes from "prop-types";
import { compose } from "redux";

import { styles } from "./styles";

const buttons = {
  add: { title: "Add", icon: AddIcon },
  download: { title: "Download", icon: DownloadIcon },
  associate: { title: "Associate", icon: AssociateIcon },
  delete: { title: "Delete", icon: DeleteIcon },
  validate: { title: "Validate", icon: ValidateIcon },
  submit: { title: "Submit", icon: SubmitIcon },
  template: { title: "Template", icon: TemplateIcon },
  export: { title: "Export", icon: ExportIcon },
  search: { title: "Search", icon: SearchIcon },
  import: { title: "Import", icon: ImportIcon }
};

class ActionButtonComponent extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    variant: PropTypes.oneOf(Object.keys(buttons)),
    icon: PropTypes.any,
    title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    noIcon: PropTypes.bool
  };
  static defaultProps = {
    title: "",
    onClick: () => {},
    noIcon: false
  };

  handleClick = e => {
    const { onClick, disabled } = this.props;
    if (!disabled) onClick(e);
  };

  render() {
    const { classes, disabled, icon, title, variant, noIcon, ...rest } =
      this.props;
    const Icon = buttons[variant] ? buttons[variant].icon : icon;

    return (
      <Button
        id={`item-action-${variant}`}
        data-testid="action-button"
        size="small"
        className={classes.actionButton}
        disabled={disabled}
        onClick={this.handleClick}
        {...rest}
      >
        {!noIcon && Icon ? <Icon className={classes.actionIcon} /> : null}
        {title || buttons[variant].title}
      </Button>
    );
  }
}

export const ActionButton = compose(
  withWidth(),
  withStyles(styles)
)(ActionButtonComponent);

export default ActionButton;
