import { fromJS } from "immutable";
import { select, put } from "redux-saga/effects";

import { DckSelectors, DckActionCreators } from "../redux";

export function* updateDckItem(itemType, id, data) {
  const getItems = state => DckSelectors.getItemData(state, itemType, "items");
  let items = yield select(getItems);
  const itemIndex = items.findIndex(el => el.getIn(["id"]) === id);
  if (itemIndex === -1) return;

  items = items.set(itemIndex, fromJS(data));
  yield put(DckActionCreators.setItemData(itemType, "items", items));
}
