import React from "react";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button
} from "@material-ui/core";
import PropTypes from "prop-types";
import { withRouter } from "react-router";

import { captureException } from "../../utils/sentry";

const MESSAGE = {
  dev: "Please report about this problem to SRX Team.",
  qa: "Please report about this problem to SRX Team.",
  staging: "Please report about this problem to SRX Team.",
  prod: "An error has occurred, but it will be resolved soon. Please try again later."
};

const ENV = process.env.REACT_APP_STAGE || "dev";

class ErrorBoundaryComponent extends React.PureComponent {
  static propTypes = {
    history: PropTypes.object,
    homePath: PropTypes.string
  };

  state = { hasError: false, error: null };

  static getDerivedStateFromError(error) {
    return { hasError: true, error: error.toString() };
  }

  componentDidCatch(error) {
    captureException(error, "rendering_error");
  }

  handleClose = () => {
    this.setState({ hasError: false });
  };

  handleRedirect = () => {
    const { history, homePath } = this.props;
    history.push(homePath || "/");
    this.setState({ hasError: false });
  };

  render() {
    if (this.state.hasError) {
      return (
        <Dialog open={true} onClose={this.handleClose} fullWidth maxWidth="md">
          <DialogTitle>Oops.... Something went wrong</DialogTitle>
          <DialogContent>
            <DialogContentText>{MESSAGE[ENV]}</DialogContentText>
            {["dev", "qa", "staging"].includes(ENV) && (
              <pre
                style={{ marginTop: 12, textAlign: "center", overflow: "auto" }}
              >
                {this.state.error}
              </pre>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleRedirect} color="primary">
              Go to home page
            </Button>
            <Button onClick={this.handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      );
    }
    return this.props.children;
  }
}

export const ErrorBoundary = withRouter(ErrorBoundaryComponent);
