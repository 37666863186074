import React from "react";

import { Tab, Tabs } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import { getItemData } from "../../actions/selectors";
import { ItemTypeApplication } from "../../config";
import { useStyles } from "./styles";

function AppPageTabsComponent() {
  const classes = useStyles();
  const { push } = useHistory();
  const { pathname } = useLocation();

  const pageTabs = useSelector(state =>
    getItemData(state, ItemTypeApplication, "pageTabs")
  );
  const currentTab = useSelector(state =>
    getItemData(state, ItemTypeApplication, "currentTab")
  );

  const handleChange = (e, key) => {
    const tab = pageTabs.find(tab => tab.key === key);
    tab && push(`${pathname}#${key}`);
  };

  if (!pageTabs?.length) return null;

  return (
    <Tabs
      value={currentTab?.key || false}
      onChange={handleChange}
      classes={{
        root: classes.root,
        indicator: classes.indicator
      }}
      variant="scrollable"
    >
      {pageTabs.map(tab => (
        <Tab
          key={tab.key}
          value={tab.key}
          label={tab.title}
          classes={{
            root: classes.tab,
            selected: classes.selected
          }}
        />
      ))}
    </Tabs>
  );
}

export const AppPageTabs = React.memo(AppPageTabsComponent);
