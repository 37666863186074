export const styles = theme => ({
  root: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: "100%",
    padding: "0 10px",
    cursor: "default"
  },
  overflow: {
    overflowX: "hidden",
    textOverflow: "ellipsis"
  },
  selectable: {
    "&:hover": {
      border: "1px dotted #ccc"
    }
  },
  selected: {
    border: "2px solid #ccc"
  },
  edit: {
    border: "2px solid #69e",
    background: "#fff"
  },
  inlineInput: {
    overflow: "auto",
    background: "transparent",
    width: "inherit",
    border: "none",
    outline: "none",
    "&:focus": {
      outline: "none"
    }
  },
  inlineSelect: {},
  inlineItem: {
    fontSize: "small",
    color: "#333"
  },
  inlineSelected: {
    color: `${theme.palette.primary.light} !important`,
    background: `${theme.color.grey} !important`
  }
});
