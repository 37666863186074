export const styles = theme => {
  const drawerWidth = theme.mixins.sideBar.width;
  const appBarHeight = 44;
  const statusAppBarHeight = 72;
  const messageBarHeight = theme.mixins.messageBar.height;

  const transition = theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  });

  return {
    root: {
      display: "flex",
      height: "100%",
      position: "absolute"
    },
    header: {
      overflowX: "hidden",
      height: appBarHeight,
      minHeight: appBarHeight,
      width: drawerWidth,
      zIndex: theme.zIndex.drawer,
      transition
    },
    headerWithStatus: {
      height: statusAppBarHeight,
      minHeight: statusAppBarHeight,
      width: drawerWidth,
      zIndex: theme.zIndex.drawer,
      transition
    },
    headerMobile: {
      overflowX: "hidden",
      height: appBarHeight,
      minHeight: appBarHeight,
      width: drawerWidth
    },
    drawerPaper: {
      position: "fixed",
      border: 0,
      whiteSpace: "nowrap",
      overflowX: "hidden",
      overflowY: "hidden",
      width: drawerWidth,
      height: "100%",
      transition,
      "&:hover": {
        overflowY: "auto"
      }
    },
    drawerPaperMobile: {
      position: "fixed",
      top: 0,
      border: 0,
      whiteSpace: "nowrap",
      overflowX: "hidden",
      width: drawerWidth,
      height: "100%"
    },
    collapsed: {
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(7)
      },
      transition
    },
    topShift: {
      top: messageBarHeight,
      height: `calc(100% - ${messageBarHeight}px) !important`
    },
    customLogo: { margin: "12px 0" },
    divider: {
      margin: "6px 0 !important",
      backgroundColor: "rgba(0, 0, 0, 0.08)"
    }
  };
};
