/* eslint-disable react/prop-types */
import SvgIcon from "@material-ui/core/SvgIcon";

export const EmptyList = props => (
  <SvgIcon {...props}>
    <path d="M3,18 C3,19.1 3.9,20 5,20 L19,20 C20.1,20 21,19.1 21,18 L21,13 L19,13 L19,15 L5,15 L5,13 L3,13 L3,18 Z M14,16 L14,18 L10,18 L10,16 L14,16 Z M17.1339746,5.76794919 L18.8660254,6.76794919 L16.8660254,10.2320508 L15.1339746,9.23205081 L17.1339746,5.76794919 Z M6.8660254,5.76794919 L8.8660254,9.23205081 L7.1339746,10.2320508 L5.1339746,6.76794919 L6.8660254,5.76794919 Z M13,4 L13,8 L11,8 L11,4 L13,4 Z" />
  </SvgIcon>
);

export const Folder = props => (
  <SvgIcon {...props} viewBox="0 0 100 100">
    <path d="M52.27,33.33h24a1.51,1.51,0,1,0,0-3h-24a1.51,1.51,0,0,0,0,3Z" />
    <path d="M37.37,43.94a1.51,1.51,0,0,0-1.51-1.51h-24a1.51,1.51,0,0,0,0,3h24A1.51,1.51,0,0,0,37.37,43.94Z" />
    <path d="M90.4,6.06H9.6A9.6,9.6,0,0,0,0,15.66V84.35a9.6,9.6,0,0,0,9.6,9.59H84.09a1.52,1.52,0,0,0,0-3H9.6A6.58,6.58,0,0,1,3,84.35V39.9A6.58,6.58,0,0,1,9.6,33.33H33.69a8.52,8.52,0,0,1,6.07,2.52L46,42.05a11.52,11.52,0,0,0,8.21,3.4H90.4A6.58,6.58,0,0,1,97,52V84.35a6.58,6.58,0,0,1-6.57,6.56H76.27a1.52,1.52,0,1,0,0,3H90.4a9.6,9.6,0,0,0,9.6-9.59V15.66A9.6,9.6,0,0,0,90.4,6.06ZM84.85,42.43H54.18a8.54,8.54,0,0,1-6.07-2.52l-6.2-6.2a11.53,11.53,0,0,0-8.22-3.4H15.15v-9.1h69.7ZM97,45.06a9.55,9.55,0,0,0-6.57-2.63H87.88V19.7a1.52,1.52,0,0,0-1.52-1.52H13.64a1.52,1.52,0,0,0-1.52,1.52V30.31H9.6A9.55,9.55,0,0,0,3,32.94V15.66A6.58,6.58,0,0,1,9.6,9.09H90.4A6.58,6.58,0,0,1,97,15.66Z" />
  </SvgIcon>
);

export const Document = props => (
  <SvgIcon {...props} viewBox="0 0 100 100">
    <path d="M94.44,0H25.76a1.52,1.52,0,0,0-1.52,1.52V10.1H15.66a1.52,1.52,0,0,0-1.52,1.52V20.2H5.56A1.52,1.52,0,0,0,4,21.72V98.49A1.52,1.52,0,0,0,5.56,100H74.24a1.52,1.52,0,0,0,1.52-1.51V89.9h8.58a1.52,1.52,0,0,0,1.52-1.52V79.8h8.58A1.52,1.52,0,0,0,96,78.28V1.52A1.52,1.52,0,0,0,94.44,0ZM7.07,97V23.23H56.56V37.88a1.52,1.52,0,0,0,1.52,1.52H72.72V97ZM59.59,25.38l11,11h-11ZM82.83,86.87H75.76v-49a1.49,1.49,0,0,0-.45-1.07L59.15,20.65a1.49,1.49,0,0,0-1.07-.45H17.17V13.13H82.83Zm10.1-10.11H85.86V11.62a1.52,1.52,0,0,0-1.52-1.52H27.28V3H92.93Z" />
    <path d="M15.66,39.4H48a1.52,1.52,0,0,0,0-3H15.66a1.52,1.52,0,0,0,0,3Z" />
    <path d="M15.66,51.52H44.44a1.52,1.52,0,1,0,0-3H15.66a1.52,1.52,0,1,0,0,3Z" />
    <path d="M64.14,48.49H54.55a1.52,1.52,0,1,0,0,3h9.59a1.52,1.52,0,1,0,0-3Z" />
    <path d="M15.66,63.64H27.27a1.52,1.52,0,1,0,0-3H15.66a1.52,1.52,0,1,0,0,3Z" />
    <path d="M64.14,60.61H37.38a1.52,1.52,0,1,0,0,3H64.14a1.52,1.52,0,1,0,0-3Z" />
    <path d="M41.16,72.73H15.66a1.52,1.52,0,1,0,0,3h25.5a1.52,1.52,0,1,0,0-3Z" />
    <path d="M64.14,72.73H51.26a1.52,1.52,0,0,0,0,3H64.14a1.52,1.52,0,1,0,0-3Z" />
    <path d="M33.84,84.85H15.66a1.52,1.52,0,1,0,0,3H33.84a1.52,1.52,0,0,0,0-3Z" />
  </SvgIcon>
);

export const Location = props => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path d="M19,3 L5,3 C3.9,3 3,3.9 3,5 L3,19 C3,20.1 3.89,21 5,21 L19,21 C20.1,21 21,20.1 21,19 L21,5 C21,3.9 20.1,3 19,3 Z M19,19 L5,19 L5,16 L8.56,16 C9.25,17.19 10.53,18 12.01,18 C13.49,18 14.76,17.19 15.46,16 L19,16 L19,19 Z M19,14 L5,14 L5,11 L8.56,11 C9.25,12.19 10.53,13 12.01,13 C13.49,13 14.76,12.19 15.46,11 L19,11 L19,14 Z M14.01,14 C14.01,15.1 13.11,16 12.01,16 C10.91,16 10.01,15.1 10.01,14 L5,14 L19,14 L14.01,14 Z M19,9 L14.01,9 C14.01,10.1 13.11,11 12.01,11 C10.91,11 10.01,10.1 10.01,9 L5,9 L5,5 L19,5 L19,9 Z" />
  </SvgIcon>
);

export const Vending = props => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path d="M 6 2 C 4.895 2 4 2.895 4 4 L 4 20 C 4 21.105 4.895 22 6 22 L 18 22 C 19.105 22 20 21.105 20 20 L 20 4 C 20 2.895 19.105 2 18 2 L 6 2 z M 6 4 L 14 4 L 14 14 L 6 14 L 6 4 z M 16 4 L 18 4 L 18 12 L 16 12 L 16 4 z M 7 5 L 7 7 L 9 7 L 9 5 L 7 5 z M 11 5 L 11 7 L 13 7 L 13 5 L 11 5 z M 7 8 L 7 10 L 9 10 L 9 8 L 7 8 z M 11 8 L 11 10 L 13 10 L 13 8 L 11 8 z M 7 11 L 7 13 L 9 13 L 9 11 L 7 11 z M 11 11 L 11 13 L 13 13 L 13 11 L 11 11 z M 16 14 L 18 14 L 18 16 L 16 16 L 16 14 z M 6 16 L 14 16 L 14 20 L 6 20 L 6 16 z M 9 17 L 9 19 L 11 19 L 11 17 L 9 17 z" />
  </SvgIcon>
);

export const Locker = props => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path d="M2,0 L10,0 C11.1045695,0 12,0.8954305 12,2 L12,18 C12,19.1045695 11.1045695,20 10,20 L2,20 C0.8954305,20 0,19.1045695 0,18 L0,2 C0,0.8954305 0.8954305,0 2,0 L2,0 Z M2,2 L2,18 L10,18 L10,2 L2,2 L2,2 Z M4,11 L6,11 L6,15 L4,15 L4,11 L4,11 Z M4,4 L8,4 L8,5.5 L4,5.5 L4,4 L4,4 Z M4,7 L8,7 L8,8.5 L4,8.5 L4,7 Z" />
  </SvgIcon>
);

export const Weights = props => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path d="M7.2975,14.9465 L5.996,13.645 C5.179,14.6995 4.628,15.963 4.457,17.35 L6.3,17.35 L6.3,19.25 L2.5,19.25 L2.5,18.3 C2.5,13.702 5.768,9.8735 10.1,8.99 L10.1,7.09 L2.5,7.09 L2.5,5 L4.457,5 L19.6,5 L21.5,5 L21.5,7.09 L13.9,7.09 L13.9,8.99 C18.232,9.8735 21.5,13.702 21.5,18.3 L21.5,19.25 L17.7,19.25 L17.7,17.35 L19.543,17.35 C19.372,15.963 18.821,14.6995 18.004,13.645 L16.7025,14.9465 L15.363,13.607 L16.6645,12.296 C15.61,11.479 14.337,10.928 12.95,10.757 L12.95,12.6 L11.05,12.6 L11.05,10.757 C9.663,10.928 8.39,11.479 7.3355,12.296 L8.637,13.607 L7.2975,14.9465 Z M12,16.4 C13.049341,16.4 13.9,17.250659 13.9,18.3 C13.9,19.349341 13.049341,20.2 12,20.2 C11.696,20.2 11.411,20.1335 11.164,20.0005 L7.5065,18.3 L11.164,16.5995 C11.411,16.4665 11.696,16.4 12,16.4 Z" />
  </SvgIcon>
);

export const WeightsOk = props => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      d="M12,16.4 C13.049341,16.4 13.9,17.250659 13.9,18.3 C13.9,19.349341 13.049341,20.2 12,20.2 C11.696,20.2 11.411,20.1335 11.164,20.0005 L7.5065,18.3 L11.164,16.5995 C11.411,16.4665 11.696,16.4 12,16.4 Z M21.5,18.3 L21.5,19.25 L17.7,19.25 L17.7,17.35 L19.543,17.35 C19.4309771,16.4413695 19.1558724,15.5857406 18.7497367,14.8071512 C19.3832642,14.6679228 19.9893434,14.4516451 20.5570293,14.16996 C21.1610098,15.4180491 21.5,16.8191144 21.5,18.3 Z M10.0415244,10.9546082 L9.80511516,11.0194543 C8.90030178,11.2922604 8.06553846,11.7303846 7.3355,12.296 L8.637,13.607 L7.2975,14.9465 L5.996,13.645 C5.179,14.6995 4.628,15.963 4.457,17.35 L6.3,17.35 L6.3,19.25 L2.5,19.25 L2.5,18.3 C2.5,13.988662 5.37321585,10.3538662 9.30270623,9.18905876 C9.47854463,9.81204241 9.72827854,10.4038247 10.0415244,10.9546082 Z M9,7 L9.001,7.0894583 L2.5,7.09 L2.5,5 L9.25132979,4.9994583 C9.08724167,5.6386872 9,6.30899951 9,7 Z"
      fill="#9B9B9B"
    />
    <path
      d="M17,1 C20.318,1 23,3.682 23,7 C23,10.318 20.318,13 17,13 C13.682,13 11,10.318 11,7 C11,3.682 13.682,1 17,1 Z M19.454,4.6 L16.1,7.954 L14.546,6.406 L13.7,7.252 L16.1,9.652 L20.3,5.452 L19.454,4.6 Z"
      fill="#4CAF50"
    />
  </SvgIcon>
);

export const WeightsError = props => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      d="M12,16.4 C13.049341,16.4 13.9,17.250659 13.9,18.3 C13.9,19.349341 13.049341,20.2 12,20.2 C11.696,20.2 11.411,20.1335 11.164,20.0005 L7.5065,18.3 L11.164,16.5995 C11.411,16.4665 11.696,16.4 12,16.4 Z M21.5,18.3 L21.5,19.25 L17.7,19.25 L17.7,17.35 L19.543,17.35 C19.4309771,16.4413695 19.1558724,15.5857406 18.7497367,14.8071512 C19.3832642,14.6679228 19.9893434,14.4516451 20.5570293,14.16996 C21.1610098,15.4180491 21.5,16.8191144 21.5,18.3 Z M10.0415244,10.9546082 L9.80511516,11.0194543 C8.90030178,11.2922604 8.06553846,11.7303846 7.3355,12.296 L8.637,13.607 L7.2975,14.9465 L5.996,13.645 C5.179,14.6995 4.628,15.963 4.457,17.35 L6.3,17.35 L6.3,19.25 L2.5,19.25 L2.5,18.3 C2.5,13.988662 5.37321585,10.3538662 9.30270623,9.18905876 C9.47854463,9.81204241 9.72827854,10.4038247 10.0415244,10.9546082 Z M9,7 L9.001,7.0894583 L2.5,7.09 L2.5,5 L9.25132979,4.9994583 C9.08724167,5.6386872 9,6.30899951 9,7 Z"
      fill="#9B9B9B"
    />
    <path
      d="M16.4,8.8 L17.6,8.8 L17.6,10 L16.4,10 L16.4,8.8 Z M16.4,4 L17.6,4 L17.6,7.6 L16.4,7.6 L16.4,4 Z M16.994,1 C13.682,1 11,3.688 11,7 C11,10.312 13.682,13 16.994,13 C20.312,13 23,10.312 23,7 C23,3.688 20.312,1 16.994,1 Z"
      fill="brown"
    />
  </SvgIcon>
);

export const NoWeights = props => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path d="M12,16.4 C13.049341,16.4 13.9,17.250659 13.9,18.3 C13.9,19.349341 13.049341,20.2 12,20.2 C11.696,20.2 11.411,20.1335 11.164,20.0005 L8.465,18.746 L9.622,17.316 L11.164,16.5995 C11.411,16.4665 11.696,16.4 12,16.4 Z M21.5,18.3 L21.5,19.25 L17.7,19.25 L17.7,17.35 L19.543,17.35 C19.372,15.963 18.821,14.6995 18.004,13.645 L16.7025,14.9465 L15.363,13.607 L16.6645,12.296 C16.0462021,11.8169584 15.3527845,11.4293669 14.6053127,11.1562058 L15.8524588,9.61517009 C19.177271,11.0941461 21.5,14.4236122 21.5,18.3 Z M13.41,5 L7.84225709,11.9350019 C7.66832831,12.0486333 7.49927877,12.1691083 7.3355,12.296 L7.455,12.417 L6.258,13.907 L5.996,13.645 C5.5352712,14.2396616 5.1591342,14.9007882 4.88803341,15.6130464 L2.5,18.588 L2.5,18.3 C2.5,13.702 5.768,9.8735 10.1,8.99 L10.1,7.09 L2.5,7.09 L2.5,5 L13.41,5 Z M21.5,5 L21.5,7.09 L17.894,7.09 L19.585,5 L21.5,5 Z" />
    <polygon points="20 1 21.7003418 2.4 4.7003418 23.4 3 22" />
  </SvgIcon>
);

export const SwitchOn = props => (
  <SvgIcon {...props} viewBox="0 0 18 34" stroke="#707070">
    <rect x="3.5" y="1.5" width="11" height="31" rx="2" fill="none" />
    <rect x="0.5" y="-0.5" width="17" height="17" rx="2" fill="#E2E2E2" />
  </SvgIcon>
);

export const SwitchOff = props => (
  <SwitchOn
    {...props}
    style={{ ...props.style, transform: "rotate(180deg)" }}
  />
);

export const ProductLabel = props => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path d="M20,7 L20,17 L4,17 L4,7 L20,7 M2,6 L2,18 C2,18.5522847 2.44771525,19 3,19 L21,19 C21.5522847,19 22,18.5522847 22,18 L22,6 C22,5.44771525 21.5522847,5 21,5 L3,5 C2.44771525,5 2,5.44771525 2,6 Z M16,8 L17,8 L17,16 L16,16 L16,8 Z M13.25,8 L15.25,8 L15.25,16 L13.25,16 L13.25,8 Z M11.5,8 L12.5,8 L12.5,16 L11.5,16 L11.5,8 Z M9.75,8 L10.75,8 L10.75,16 L9.75,16 L9.75,8 Z M7,8 L9,8 L9,16 L7,16 L7,8 Z" />
  </SvgIcon>
);

export const Asset = props => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path d="M20.7389992,19.4610728 L19.4609935,20.7390784 C19.1099582,21.0880312 18.5430232,21.0880312 18.1919879,20.7390784 L7.49994063,10.0650312 C7.20726993,10.1498191 6.90460361,10.195219 6.59993665,10.2000318 C5.43730738,10.1992018 4.34664825,9.63694362 3.67155948,8.69039058 C2.99647071,7.74383754 2.8200667,6.52952572 3.19792161,5.43001074 L5.48393171,7.71602085 L5.96093382,7.23901874 L7.23893947,5.96101309 L7.71594158,5.48401098 L5.42993148,3.19800088 C6.52944645,2.82014597 7.74375827,2.99654998 8.69031131,3.67163875 C9.63686435,4.34672752 10.1991225,5.43738665 10.1999526,6.60001591 C10.1951398,6.90468287 10.1497398,7.20734919 10.064952,7.50001989 L20.7389992,18.1920672 C21.0879519,18.5431024 21.0879519,19.1100375 20.7389992,19.4610728 M3.26092189,18.1920672 C2.91196911,18.5431024 2.91196911,19.1100375 3.26092189,19.4610728 L4.53892754,20.7390784 C4.8899628,21.0880312 5.45689789,21.0880312 5.80793315,20.7390784 L10.7309549,15.8250567 L8.18394365,13.2780454 M19.1999923,3 L15.5999764,4.80000796 L15.5999764,6.60001591 L13.6469678,8.55302455 L15.4469758,10.3530325 L17.3999844,8.40002387 L19.1999923,8.40002387 L21.0000003,4.80000796 L19.1999923,3 Z" />
  </SvgIcon>
);

export const Serialization = props => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path d="M3,5 L1,5 L1,21 C1,22.1 1.9,23 3,23 L19,23 L19,21 L3,21 L3,5 Z M21,1 L7,1 C5.9,1 5,1.9 5,3 L5,17 C5,18.1 5.9,19 7,19 L21,19 C22.1,19 23,18.1 23,17 L23,3 C23,1.9 22.1,1 21,1 Z M18,6 L19,6 L19,14 L18,14 L18,6 Z M15.25,6 L17.25,6 L17.25,14 L15.25,14 L15.25,6 Z M13.5,6 L14.5,6 L14.5,14 L13.5,14 L13.5,6 Z M11.75,6 L12.75,6 L12.75,14 L11.75,14 L11.75,6 Z M9,6 L11,6 L11,14 L9,14 L9,6 Z" />
  </SvgIcon>
);

export const SerializationOutlined = props => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path d="M3,5 L1,5 L1,21 C1,22.1 1.9,23 3,23 L19,23 L19,21 L3,21 L3,5 Z M21,1 L7,1 C5.9,1 5,1.9 5,3 L5,17 C5,18.1 5.9,19 7,19 L21,19 C22.1,19 23,18.1 23,17 L23,3 C23,1.9 22.1,1 21,1 Z M21,17 L7,17 L7,3 L21,3 L21,17 Z M18,6 L19,6 L19,14 L18,14 L18,6 Z M15.25,6 L17.25,6 L17.25,14 L15.25,14 L15.25,6 Z M13.5,6 L14.5,6 L14.5,14 L13.5,14 L13.5,6 Z M11.75,6 L12.75,6 L12.75,14 L11.75,14 L11.75,6 Z M9,6 L11,6 L11,14 L9,14 L9,6 Z" />
  </SvgIcon>
);

export const Weight = props => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path d="M12,3 C14.209139,3 16,4.790861 16,7 C16,7.7290322 15.8049665,8.41251188 15.4642293,9.00110922 L18,9 C18.95,9 19.75,9.67 19.95,10.56 L21.8813477,18.3448633 C21.994375,18.8364063 22,18.9175 22,19 C22,20.1045695 21.1045695,21 20,21 L4,21 C2.9456382,21 2.08183488,20.1841222 2.00548574,19.1492623 L2.00396084,18.9179525 C2.03231689,18.640752 2.25984375,17.6939062 4.05,10.56 C4.25,9.67 5.05,9 6,9 L8.53577067,9.00110922 C8.1950335,8.41251188 8,7.7290322 8,7 C8,4.790861 9.790861,3 12,3 Z M17.2173629,11.0009251 L6.78154693,11.0000645 C6.32232246,11.0000266 5.92209883,11.312767 5.81111021,11.7583774 L4.26694226,17.958088 L4.26616627,17.9612252 L4.13877587,18.479828 C4.08344393,18.7050828 4.2211936,18.9325432 4.44644836,18.9878751 C4.4792354,18.995929 4.51287396,19 4.54663569,19 L19.0534622,19 C19.4565022,19 19.7832307,18.6732715 19.7832307,18.2702314 C19.7832307,18.2128925 19.776473,18.1557535 19.7630974,18.0999965 L19.5482945,17.204577 C19.5475614,17.201521 19.5468139,17.1984684 19.546052,17.1954194 L18.1874493,11.758495 C18.0762036,11.3133064 17.6762403,11.0009629 17.2173629,11.0009251 Z M12,5 C10.8954305,5 10,5.8954305 10,7 C10,8.1045695 10.8954305,9 12,9 C13.1045695,9 14,8.1045695 14,7 C14,5.8954305 13.1045695,5 12,5 Z" />
  </SvgIcon>
);

export const LedOff = props => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path d="M12,3 C15.5898509,3 18.5,6.01520739 18.5,9.7346542 C18.5,12.2228922 17.1976061,14.3959583 15.260597,15.5619664 C15.1712392,15.6157565 15.0504122,15.6645968 14.90399,15.7084848 L14.9,18.2 C14.9,19.5254834 13.8254834,20.6 12.5,20.6 L11.5,20.6 C10.1745166,20.6 9.1,19.5254834 9.1,18.2 L9.09668112,15.7155946 C8.96439947,15.6752247 8.85466677,15.6305542 8.77220771,15.5815813 C6.81711295,14.4204382 5.5,12.2369131 5.5,9.7346542 C5.5,6.01520739 8.41014913,3 12,3 Z M13.1,16.3 L10.9,16.3 L10.9,18.2 C10.9,18.5313708 11.1686292,18.8 11.5,18.8 L12.5,18.8 C12.8313708,18.8 13.1,18.5313708 13.1,18.2 L13.1,16.3 Z M11.9999682,5 C9.53030842,5 7.5,7.10360386 7.5,9.7320422 C7.5,11.4650522 8.3910321,13.0241916 9.79246385,13.8565107 C10.1258911,14.0545353 13.8939857,14.0453096 14.2300929,13.8429854 C15.6190723,13.006871 16.4999365,11.4552281 16.4999365,9.7320422 C16.4999365,7.10360386 14.4696281,5 11.9999682,5 Z" />
  </SvgIcon>
);

export const ConfigWizard = props => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path d="M4,6 L2,6 L2,20 C2,21.1 2.9,22 4,22 L18,22 L18,20 L4,20 L4,6 Z M20,2 L8,2 C6.9,2 6,2.9 6,4 L6,16 C6,17.1 6.9,18 8,18 L20,18 C21.1,18 22,17.1 22,16 L22,4 C22,2.9 21.1,2 20,2 Z M20,4 L20,16 L8,16 L8,4 L20,4 Z M15,5 L13,5 L12.7,6.49 C12.3,6.64 11.94,6.85 11.62,7.12 L10.17,6.63 L9.17,8.36 L10.31,9.37 C10.28,9.58 10.25,9.78 10.25,10 C10.25,10.22 10.28,10.42 10.31,10.63 L9.17,11.64 L10.17,13.37 L11.62,12.88 C11.94,13.15 12.3,13.36 12.7,13.51 L13,15 L15,15 L15.3,13.51 C15.7,13.36 16.06,13.15 16.38,12.88 L17.83,13.37 L18.83,11.64 L17.69,10.63 C17.72,10.42 17.75,10.22 17.75,10 C17.75,9.78 17.72,9.58 17.69,9.37 L18.83,8.36 L17.83,6.63 L16.38,7.12 C16.06,6.85 15.7,6.64 15.3,6.49 L15,5 Z M14,8 C15.1,8 16,8.9 16,10 C16,11.1 15.1,12 14,12 C12.9,12 12,11.1 12,10 C12,8.9 12.9,8 14,8 Z" />
  </SvgIcon>
);

export const Copy = props => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z" />
  </SvgIcon>
);

export const Hardware = props => (
  <SvgIcon {...props} viewBox="0 0 24 24" fillRule="evenodd">
    <path d="M17,7 L7,7 L7,17 L17,17 L17,7 Z M15,15 L9,15 L9,9 L15,9 L15,15 Z M23,10.9 L23,8.7 L20.8,8.7 L20.8,5.88888889 C20.8,4.54444444 19.4555556,3.2 18.1111111,3.2 L15.3,3.2 L15.3,1 L13.1,1 L13.1,3.2 L10.9,3.2 L10.9,1 L8.7,1 L8.7,3.2 L5.88888889,3.2 C4.54444444,3.2 3.2,4.54444444 3.2,5.88888889 L3.2,8.7 L1,8.7 L1,10.9 L3.2,10.9 L3.2,13.1 L1,13.1 L1,15.3 L3.2,15.3 L3.2,18.1111111 C3.2,19.4555556 4.54444444,20.8 5.88888889,20.8 L8.7,20.8 L8.7,23 L10.9,23 L10.9,20.8 L13.1,20.8 L13.1,23 L15.3,23 L15.3,20.8 L18.1111111,20.8 C19.4555556,20.8 20.8,19.4555556 20.8,18.1111111 L20.8,15.3 L23,15.3 L23,13.1 L20.8,13.1 L20.8,10.9 L23,10.9 Z" />
  </SvgIcon>
);

export const List = props => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path d="M20.1,3 L3.9,3 C3.4,3 3,3.4 3,3.9 L3,20.1 C3,20.5 3.4,21 3.9,21 L20.1,21 C20.5,21 21,20.5 21,20.1 L21,3.9 C21,3.4 20.5,3 20.1,3 L20.1,3 Z M10,7 L18,7 L18,9 L10,9 L10,7 Z M10,11 L18,11 L18,13 L10,13 L10,11 Z M10,15 L18,15 L18,17 L10,17 L10,15 Z M6,7 L8,7 L8,9 L6,9 L6,7 Z M6,11 L8,11 L8,13 L6,13 L6,11 Z M6,15 L8,15 L8,17 L6,17 L6,15 Z" />
  </SvgIcon>
);

export const Rfid = props => (
  <SvgIcon {...props} viewBox="0 0 24 24" fillRule="evenodd">
    <path d="M20,2 L4,2 C2.9,2 2,2.9 2,4 L2,20 C2,21.1 2.9,22 4,22 L20,22 C21.1,22 22,21.1 22,20 L22,4 C22,2.9 21.1,2 20,2 Z M19,5 L13,5 C11.9,5 11,5.9 11,7 L11,10.28 C10.4,10.63 10,11.26 10,12 C10,13.1 10.9,14 12,14 C13.1,14 14,13.1 14,12 C14,11.26 13.6,10.62 13,10.28 L13,7 L17,7 L17,17 L7,17 L7,7 L10,7 L10,5 L5,5 L5,19 L19,19 L19,5 Z" />
  </SvgIcon>
);

export const UsersAndGroups = props => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path d="M12,5 C10.0670034,5 8.5,6.56700338 8.5,8.5 C8.5,10.4329966 10.0670034,12 12,12 C13.9329966,12 15.5,10.4329966 15.5,8.5 C15.5,6.56700338 13.9329966,5 12,5 M5.5,8 C4.11928813,8 3,9.11928813 3,10.5 C3,11.44 3.53,12.25 4.29,12.68 C4.65,12.88 5.06,13 5.5,13 C5.94,13 6.35,12.88 6.71,12.68 C7.08,12.47 7.39,12.17 7.62,11.81 C6.89,10.86 6.5,9.7 6.5,8.5 C6.5,8.41 6.5,8.31 6.5,8.22 C6.2,8.08 5.86,8 5.5,8 M18.5,8 C18.14,8 17.8,8.08 17.5,8.22 C17.5,8.31 17.5,8.41 17.5,8.5 C17.5,9.7 17.11,10.86 16.38,11.81 C16.5,12 16.63,12.15 16.78,12.3 C16.94,12.45 17.1,12.58 17.29,12.68 C17.65,12.88 18.06,13 18.5,13 C18.94,13 19.35,12.88 19.71,12.68 C20.47,12.25 21,11.44 21,10.5 C21,9.11928813 19.8807119,8 18.5,8 M12,14 C9.66,14 5,15.17 5,17.5 L5,19 L19,19 L19,17.5 C19,15.17 14.34,14 12,14 M4.71,14.55 C2.78,14.78 0,15.76 0,17.5 L0,19 L3,19 L3,17.07 C3,16.06 3.69,15.22 4.71,14.55 M19.29,14.55 C20.31,15.22 21,16.06 21,17.07 L21,19 L24,19 L24,17.5 C24,15.76 21.22,14.78 19.29,14.55" />
  </SvgIcon>
);

export const UsersAndGroupsOutline = props => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path d="M12,5 C10.0670034,5 8.5,6.56700338 8.5,8.5 C8.5,10.4329966 10.0670034,12 12,12 C13.9329966,12 15.5,10.4329966 15.5,8.5 C15.5,6.56700338 13.9329966,5 12,5 M12,7 C12.8284271,7 13.5,7.67157288 13.5,8.5 C13.5,9.32842712 12.8284271,10 12,10 C11.1715729,10 10.5,9.32842712 10.5,8.5 C10.5,7.67157288 11.1715729,7 12,7 M5.5,8 C4.11928813,8 3,9.11928813 3,10.5 C3,11.44 3.53,12.25 4.29,12.68 C4.65,12.88 5.06,13 5.5,13 C5.94,13 6.35,12.88 6.71,12.68 C7.08,12.47 7.39,12.17 7.62,11.81 C6.89,10.86 6.5,9.7 6.5,8.5 C6.5,8.41 6.5,8.31 6.5,8.22 C6.2,8.08 5.86,8 5.5,8 M18.5,8 C18.14,8 17.8,8.08 17.5,8.22 C17.5,8.31 17.5,8.41 17.5,8.5 C17.5,9.7 17.11,10.86 16.38,11.81 C16.5,12 16.63,12.15 16.78,12.3 C16.94,12.45 17.1,12.58 17.29,12.68 C17.65,12.88 18.06,13 18.5,13 C18.94,13 19.35,12.88 19.71,12.68 C20.47,12.25 21,11.44 21,10.5 C21,9.11928813 19.8807119,8 18.5,8 M12,14 C9.66,14 5,15.17 5,17.5 L5,19 L19,19 L19,17.5 C19,15.17 14.34,14 12,14 M4.71,14.55 C2.78,14.78 0,15.76 0,17.5 L0,19 L3,19 L3,17.07 C3,16.06 3.69,15.22 4.71,14.55 M19.29,14.55 C20.31,15.22 21,16.06 21,17.07 L21,19 L24,19 L24,17.5 C24,15.76 21.22,14.78 19.29,14.55 M12,16 C13.53,16 15.24,16.5 16.23,17 L7.77,17 C8.76,16.5 10.47,16 12,16 Z" />
  </SvgIcon>
);

export const Warehouse = props => (
  <SvgIcon {...props} viewBox="0 0 24 24" fillRule="evenodd">
    <path d="M12,7 L12,3 L2,3 L2,21 L22,21 L22,7 L12,7 Z M6,19 L4,19 L4,17 L6,17 L6,19 Z M6,15 L4,15 L4,13 L6,13 L6,15 Z M6,11 L4,11 L4,9 L6,9 L6,11 Z M6,7 L4,7 L4,5 L6,5 L6,7 Z M10,19 L8,19 L8,17 L10,17 L10,19 Z M10,15 L8,15 L8,13 L10,13 L10,15 Z M10,11 L8,11 L8,9 L10,9 L10,11 Z M10,7 L8,7 L8,5 L10,5 L10,7 Z M15,11 L13,11 L13,13 L15,13 L15,11 Z M15,15 L13,15 L13,17 L15,17 L15,15 Z M19,11 L17,11 L17,13 L19,13 L19,11 Z M19,15 L17,15 L17,17 L19,17 L19,15 Z" />
  </SvgIcon>
);

export const Site = props => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <polygon points="2 9 2 22 9 22 9 14 15 14 15 22 22 22 22 9 12 1" />
  </SvgIcon>
);

export const SiteOutlined = props => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path d="M2,9 L2,22 L10,22 L10,15 L14,15 L14,22 L22,22 L22,9 L12,1 L2,9 Z M20,20 L16,20 L16,13 L8,13 L8,20 L4,20 L4,10 L12,3.5 L20,10 L20,20 Z" />
  </SvgIcon>
);

export const SubSite = props => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <g transform="translate(1.000000, 3.000000)">
      <path d="M7,3 L14,8 L14,18 L9,18 L9,12 L5,12 L5,18 L0,18 L0,8 L7,3 Z M22,0 L22,18 L16,18 L16,14 L18,14 L18,12 L16,12 L16,10 L18,10 L18,8 L16,8 L16,6.97 L9,1.97 L9,0 L22,0 Z M18,4 L16,4 L16,6 L18,6 L18,4 Z" />
    </g>
  </SvgIcon>
);

export const SubSiteOutlined = props => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path d="M8,6 L15,11 L15,21 L9,21 L9,16 L7,16 L7,21 L1,21 L1,11 L8,6 Z M23,3 L23,21 L17,21 L17,19 L21,19 L21,5 L12,5 L12,6.4 L10,4.97 L10,3 L23,3 Z M8,8.5 L3,12 L3,19 L5,19 L5,14 L11,14 L11,19 L13,19 L13,12 L8,8.5 Z M19,15 L19,17 L17,17 L17,15 L19,15 Z M19,11 L19,13 L17,13 L17,11 L19,11 Z M19,7 L19,9 L17,9 L17,7 L19,7 Z" />
  </SvgIcon>
);

export const AddSubSiteOutlined = props => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path d="M9,17 C9,18.4582754 9.38854949,19.8244115 10.0679433,21.0007031 L9,21 L9,17 L9,17 Z M8,6 L13.3958842,9.85359855 C12.7585365,10.1751185 12.1702938,10.5793619 11.6451887,11.0522962 L8,8.5 L3,12 L3,19 L5,19 L5,14 L9.57965937,14.0003567 C9.20577156,14.9263383 9,15.9387712 9,17 L9,16 L7,16 L7,21 L1,21 L1,11 L8,6 Z M18,13 L18,16 L21,16 L21,18 L18,18 L18,21 L16,21 L16,18 L13,18 L13,16 L16,16 L16,13 L18,13 Z M23,3 L23.0008854,11.704831 C22.4290653,11.0570822 21.7537914,10.5029064 21.0007031,10.0679433 L21,5 L12,5 L12,6.4 L10,4.97 L10,3 L23,3 Z M19,7 L19,9 L17.0622959,9.0002365 C17.0415495,9.00007893 17.0207841,9 17,9 L17,7 L19,7 Z" />
  </SvgIcon>
);

export const Pin = props => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path d="M 14.490234 2.4902344 A 1.0001 1.0001 0 0 0 13.792969 4.2070312 L 14.388672 4.8027344 L 8.1894531 9.7753906 L 7.2070312 8.7929688 A 1.0001 1.0001 0 0 0 6.4902344 8.4902344 A 1.0001 1.0001 0 0 0 5.7929688 10.207031 L 9.09375 13.507812 L 3.2890625 19.310547 C 2.9020625 19.697547 2.9020625 20.323937 3.2890625 20.710938 C 3.6760625 21.097938 4.3024531 21.097938 4.6894531 20.710938 L 10.492188 14.90625 L 13.792969 18.207031 A 1.0001 1.0001 0 1 0 15.207031 16.792969 L 14.310547 15.896484 L 19.214844 9.6289062 L 19.792969 10.207031 A 1.0001 1.0001 0 1 0 21.207031 8.7929688 L 15.207031 2.7929688 A 1.0001 1.0001 0 0 0 14.490234 2.4902344 z" />
  </SvgIcon>
);

export const Unpin = props => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path d="M 3.7070312 2.2929688 L 2.2929688 3.7070312 L 8.3730469 9.7871094 L 7.1835938 10.769531 L 5.7070312 9.2929688 L 4.2929688 10.707031 L 8.09375 14.507812 L 3 19.599609 L 3 21 L 4.4003906 21 L 9.4921875 15.90625 L 13.292969 19.707031 L 14.707031 18.292969 L 13.318359 16.904297 L 14.287109 15.701172 L 20.292969 21.707031 L 21.707031 20.292969 L 15.548828 14.134766 L 15.558594 14.123047 L 14.136719 12.701172 L 14.126953 12.712891 L 11.347656 9.9335938 L 11.357422 9.9238281 L 9.9355469 8.5019531 L 9.9257812 8.5117188 L 3.7070312 2.2929688 z M 14.707031 2.2929688 L 13.292969 3.7070312 L 14.40625 4.8203125 L 11.486328 7.2246094 L 12.908203 8.6464844 L 15.814453 6.2519531 L 17.761719 8.1972656 L 15.396484 11.134766 L 16.820312 12.558594 L 19.195312 9.609375 L 20.292969 10.707031 L 21.707031 9.2929688 L 14.707031 2.2929688 z M 9.7949219 11.208984 L 12.865234 14.279297 L 11.900391 15.478516 L 8.6074219 12.185547 L 9.7949219 11.208984 z" />
  </SvgIcon>
);
