import { mapFiltersFor } from "srx-shared";

import { WeightHistoryItem } from "@comp/Hardware/WeightHistory/weightHistoryItem";

export const TargetWeightHistoryItem = { ...WeightHistoryItem };

TargetWeightHistoryItem.columns = TargetWeightHistoryItem.columns.filter(
  column => !["sectionNumber", "distributor.name"].includes(column.accessor)
);

TargetWeightHistoryItem.filters = mapFiltersFor(TargetWeightHistoryItem, [
  "action",
  "dates",
  "iotHubSerialNumber"
]);
