/* eslint-disable react/prop-types */
import { useEffect, useState } from "react";

import Grid from "@material-ui/core/Grid";
import { useSelector } from "react-redux";

import { usePrevious } from "../../../hooks";
import { procName, procRunning } from "../../../redux";

export function SettingsContainer(props) {
  const {
    item,
    onSubmit = () => {},
    settings,
    children,
    onChange = () => {}
  } = props;

  const [activeSection, setActiveSection] = useState(null);

  const updating = useSelector(state =>
    procRunning(state, procName(item.type, "update"))
  );

  const prevUpdating = usePrevious(updating);

  useEffect(() => {
    if (prevUpdating && !updating) {
      setActiveSection(null);
    }
  }, [prevUpdating, updating]);

  const handleSubmit = (section, data, useDefault) => {
    setActiveSection(section);
    onSubmit(section, data, useDefault);
  };

  const handleChange = (section, data) => onChange(section, data);

  const renderSection = ({
    title,
    section,
    Form,
    readOnly,
    contentfulId,
    formConfig,
    defaultExpanded,
    renderActions,
    useDefaultTitle
  }) => {
    const data = settings[section];

    return data ? (
      <Grid key={section} item xs={12} style={{ maxWidth: 800, width: "100%" }}>
        <Form
          contentfulId={contentfulId}
          defaultExpanded={defaultExpanded}
          renderActions={renderActions}
          title={title}
          formConfig={formConfig}
          section={section}
          data={data.settings || data}
          useDefaults={"useDefault" in data ? data.useDefault : null}
          useDefaultsTitle={useDefaultTitle}
          readOnly={readOnly || false}
          defaultValues={data.defaultSettings}
          updating={updating && section === activeSection}
          onSubmit={(data, useDefault) =>
            handleSubmit(section, data, useDefault)
          }
          onChange={data => handleChange(section, data)}
        />
      </Grid>
    ) : null;
  };
  const { sections, sectionsConfig } = item;

  return settings ? (
    <Grid container justify="center" spacing={2}>
      {sections.map(section => renderSection(sectionsConfig[section]))}
      {children}
    </Grid>
  ) : null;
}
