import { useState, useEffect } from "react";

import { makeStyles, TextField } from "@material-ui/core";
import cn from "classnames";
import PropTypes from "prop-types";

import { NumberFormatCustom } from "../../../mui";
import { styles } from "./styles";

const useStyles = makeStyles(styles);

function NumberFormatField(props) {
  return <NumberFormatCustom {...props} name="jumpTo" decimalScale={0} />;
}

GoToPage.propTypes = {
  page: PropTypes.number,
  pages: PropTypes.number,
  onGoToChange: PropTypes.func
};

export function GoToPage(props) {
  const { page, pages, onGoToChange } = props;

  const [currentPage, setCurrentPage] = useState(page);

  const cls = useStyles();

  useEffect(() => {
    setCurrentPage(page + 1);
  }, [page]);

  const setPage = () => {
    if (currentPage - 1 === page) return;
    const nextPage =
      currentPage > pages ? pages : currentPage < 1 ? 1 : currentPage;
    setCurrentPage(nextPage);
    onGoToChange(nextPage - 1);
  };

  const handleKeyDown = e => e.key === "Enter" && setPage();

  const handleBlur = () => setPage();

  const handleChange = e => setCurrentPage(e.target.value);

  return (
    <>
      <div className={cn(cls.pageSizeSelector, cls.rowsPerPage)}>Go To</div>
      <TextField
        size="small"
        value={currentPage}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
        InputProps={{
          className: cls.goToInput,
          inputComponent: NumberFormatField
        }}
      />
    </>
  );
}
