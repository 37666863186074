import React from "react";

import { withStyles } from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import WarningIcon from "@material-ui/icons/Warning";
import PropTypes from "prop-types";

import { styles } from "./styles";

const MESSAGE_VARIANTS = {
  error: {
    Icon: ErrorIcon,
    styles: {
      backgroundColor: "#FFD6D6",
      color: "#B70000"
    }
  },
  warning: {
    Icon: WarningIcon,
    styles: {
      backgroundColor: "#FDF3E8",
      color: "#EF8A20"
    }
  },
  info: {
    Icon: InfoIcon,
    styles: {
      backgroundColor: "#DFEFF6",
      color: "#2795C4"
    }
  },
  default: {
    Icon: InfoIcon,
    styles: {
      backgroundColor: "#F7F7F7",
      color: "#888888"
    }
  }
};

function MessageComponent(props) {
  const { variant, classes, children } = props;

  if (!variant || !MESSAGE_VARIANTS[variant]) return null;

  const { Icon, styles } = MESSAGE_VARIANTS[variant];

  return (
    <div className={classes.root} style={styles}>
      <Icon classes={{ root: classes.icon }} />
      {children}
    </div>
  );
}
MessageComponent.propTypes = {
  variant: PropTypes.string,
  title: PropTypes.string,
  classes: PropTypes.object
};

export const Message = withStyles(styles)(MessageComponent);
