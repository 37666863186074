import PropTypes from "prop-types";

import { hubAddress as formatHubAddress } from "../../formatters";
import { SwitchOn, SwitchOff } from "../../styles/icons";
import { useStyles } from "./styles";

const reverseBits = value =>
  (value || "").toString(2).padStart(8, "0").split("").reverse();

export function DipSwitch(props) {
  const { value, noWeight, shelfSerial, shelfId, hubAddress, style } = props;
  const cls = useStyles();

  const showSwitch =
    noWeight === "false" && shelfSerial && shelfId && shelfId === shelfSerial;

  const dipSwitch = reverseBits(value);

  return (
    <div className={cls.dipSwitchContainer}>
      <div className={cls.dipSwitchBack} style={style}>
        {showSwitch && (
          <>
            <div className={cls.dipSwitchLegend}>
              <span>ON</span>
              <span>[ * ]</span>
            </div>
            <div className={cls.dipSwitchConfig}>
              {dipSwitch.map((item, index) => (
                <div key={index} className={cls.switchElemContainer}>
                  {Number(item) ? (
                    <SwitchOff className={cls.switchIcon} />
                  ) : (
                    <SwitchOn className={cls.switchIcon} />
                  )}
                  <span className={cls.switchElemNumber}>{index + 1}</span>
                </div>
              ))}
            </div>
            {hubAddress && (
              <div className={cls.dipSwitchLegend}>
                <span className={cls.hubAddress}>
                  IotHub address: {formatHubAddress(value)}
                </span>
                <span />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

DipSwitch.propTypes = {
  value: PropTypes.number,
  noWeight: PropTypes.string,
  shelfSerial: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  shelfId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hubAddress: PropTypes.bool,
  style: PropTypes.object
};
