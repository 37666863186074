import React from "react";

import PropTypes from "prop-types";

import { SkeletonItem } from "./SkeletonItem";
import { useStyles } from "./styles";

SkeletonList.propTypes = {
  length: PropTypes.number
};

export function SkeletonList(props) {
  const { length = 6 } = props;
  const { clsSkeletonList } = useStyles();
  const items = [...new Array(length).keys()];

  return (
    <div className={clsSkeletonList}>
      {items.map(item => (
        <SkeletonItem
          key={`dummy-item-${item}`}
          width={100 + Math.floor(Math.random() * 40)}
        />
      ))}
    </div>
  );
}
