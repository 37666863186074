import * as V from "yup";

import { mapFormFieldsFor, reListOfEmails } from "srx-shared";

import { ShipToTemplateSettings } from "@items/types";

V.addMethod(V.string, "listOfEmails", function () {
  return this.test(
    "listOfEmails",
    "Please enter a valid email or emails, separated by comma",
    value => {
      return value ? reListOfEmails.test(value) : true;
    }
  );
});

const isDisabledCriticalMinAlertEmails = form =>
  !form.values.enabled && !form.values.enableStockOut;

const handleChangeCriticalMinAlert = (value, form) => {
  const { values, errors, setFieldValue } = form;
  if (!value && !values["enableStockOut"] && errors["alertEmails"]) {
    setFieldValue("alertEmails", "", true);
  }
};

const handleChangeStockOutAlert = (value, form) => {
  const { values, errors, setFieldValue } = form;
  if (!value && !values["enabled"] && errors["alertEmails"]) {
    setFieldValue("alertEmails", "", true);
  }
};

export const CriticalMinAlertSettingsItem = {
  type: ShipToTemplateSettings,
  fieldsConfig: {
    enabled: {
      label: "Critical Min Alert",
      type: "switch",
      size: 4,
      onChange: handleChangeCriticalMinAlert
    },
    alertEmails: {
      label: "Critical Min Alert Emails",
      size: 7,
      initialValue: "",
      checkDisabled: isDisabledCriticalMinAlertEmails
    },
    enableStockOut: {
      label: "Stock Out Alert",
      type: "switch",
      size: 4,
      onChange: handleChangeStockOutAlert
    }
  },
  schema: V.object({
    alertEmails: V.string()
      .label("Emails")
      .nullable()
      .when("$enabled", (value, schema, { context }) => {
        const { enabled, enableStockOut } = context;
        return enabled || enableStockOut
          ? schema.max(255).listOfEmails()
          : schema;
      })
  }),
  fields: ["enabled", "alertEmails", "enableStockOut"]
};
CriticalMinAlertSettingsItem.formConfig = mapFormFieldsFor(
  CriticalMinAlertSettingsItem
);
