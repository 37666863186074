import { getTimes12 } from "../utils";

export const dictionaries = [
  "userGroups",
  "distributorUsers",
  "customerTypes",
  "customerGroups",
  "customerUsers",
  "shipTos",
  "marketTypes",
  "vmiTypes",
  "vmiTypesToCreate",
  "warehouses",
  "shiptoDistributors",
  "shiptoCustomers",
  "eventTypes",
  "eventActions",
  "eventUserTypes",
  "eventResults",
  "customerIds",
  "shiptoIds",
  "customerDictionary",
  "rfidStates",
  "minShipTos",
  "iotHubDict",
  "hardwareVersionDict",
  "binTypesDict",
  "customerSuperUsers",
  "checkoutGroups",
  "iotHubIds",
  "deviceTypes",
  "distributors",
  "lockerTypes",
  "lockers",
  "lockerDoors",
  "availableDeviceSections",
  "distributorShelves",
  "availableDistributorShelves",
  "cameraDict",
  "shipToFeeLevels",
  "rlSubmitStatus",
  "existingPartSku",
  "inventoryChangeReason",
  "erpEclipseStatuses",
  "labelCodes",
  "groupsDict",
  "checkoutUsersDict",
  "availableSuppliers",
  "virtualSuppliersDict",
  "availableShipTos",
  "availableOrderStatus",
  "checkoutUsersReportDict",
  "userPositionsDict",
  "customerUsersDict",
  "reportTypesDict",
  "rfidShipTosDict",
  "orderStatusDict",
  "sitesDict",
  "availableSubSitesDict",
  "shortDistUsers",
  "availableSupplierDict",
  "availableShipTosDict",
  "allocationCodesDict",
  "updateBillStatusOptions",
  "updateTransferStatusOptions"
];

export const OWNERS_OF_AGREEMENT = [
  { name: "DISTRIBUTOR", label: "Distributor", value: "DISTRIBUTOR" },
  { name: "CUSTOMER", label: "Customer", value: "CUSTOMER" },
  { name: "BOTH", label: "Both", value: "BOTH" }
];

export const PERIODS_OF_AGREEMENT = [
  { name: "MONTHLY", label: "Monthly", value: "MONTHLY" },
  { name: "BI_WEEKLY", label: "Bi-Weekly", value: "BI_WEEKLY" },
  { name: "WEEKLY", label: "Weekly", value: "WEEKLY" }
];

export const EXPANDED_PERIODS_OF_AGREEMENT = [
  ...PERIODS_OF_AGREEMENT,
  {
    name: "IMMEDIATELY",
    label: "Immediately when available",
    value: "IMMEDIATELY"
  }
];

export const COST_SAVINGS_MEASURES_FOR_AGREEMENT = [
  { name: "CALENDAR_YEAR", label: "Calendar Year", value: "CALENDAR_YEAR" },
  {
    name: "ANNUAL_WITH_START_DATE",
    label: "Annually with a start date of:",
    value: "ANNUAL_WITH_START_DATE"
  },
  {
    name: "BASED_ON_AGREEMENT",
    label: "Based on the date range of the agreement",
    value: "BASED_ON_AGREEMENT"
  }
];

export const DAYS = [
  { name: "Monday", label: "Monday", value: "MONDAY" },
  { name: "Tuesday", label: "Tuesday", value: "TUESDAY" },
  { name: "Wednesday", label: "Wednesday", value: "WEDNESDAY" },
  { name: "Thursday", label: "Thursday", value: "THURSDAY" },
  { name: "Friday", label: "Friday", value: "FRIDAY" },
  { name: "Saturday", label: "Saturday", value: "SATURDAY" },
  { name: "Sunday", label: "Sunday", value: "SUNDAY" }
];

export const TIME_EVERY_OPTIONS = [...getTimes12("AM"), ...getTimes12("PM")];

export const PERIODS_OF_NOTIFICATION = [
  { name: "WEEKLY", label: "Weekly", value: "WEEKLY" },
  { name: "DAILY", label: "Daily", value: "DAILY" },
  { name: "INSTANTLY", label: "Instantly", value: "INSTANTLY" }
];

export const REPORT_FREQUENCY = [
  { name: "DAILY", label: "Daily", value: "DAILY" },
  { name: "WEEKLY", label: "Weekly", value: "WEEKLY" },
  { name: "MONTHLY", label: "Monthly", value: "MONTHLY" },
  { name: "QUARTERLY", label: "Quarterly", value: "QUARTERLY" },
  { name: "ANNUALLY", label: "Annually", value: "ANNUALLY" }
];

export const REORDER_LIST_REPORTS = [
  {
    name: "ACTIVE_REORDER_LIST",
    label: "Active Reorder List",
    value: "ACTIVE_REORDER_LIST"
  },
  {
    name: "SUBMITTED_REORDER_LIST",
    label: "Submitted Reorder List",
    value: "SUBMITTED_REORDER_LIST"
  },
  {
    name: "OPEN_QUOTES_AND_ORDERS",
    label: "Open Quotes & Orders",
    value: "OPEN_QUOTES_AND_ORDERS"
  }
];

export const DATASOURCE_TYPES = [
  {
    name: "DISTRIBUTOR_WAREHOUSE",
    label: "Distributor - Warehouse",
    value: "DISTRIBUTOR_WAREHOUSE"
  },
  {
    name: "CUSTOMER_SHIPTO",
    label: "Customer - ShipTo",
    value: "CUSTOMER_SHIPTO"
  }
];

export const COUNTRIES = ["USA", "Canada"].map(item => ({
  label: item,
  value: item
}));

export const US_STATES = [
  {
    label: "Alabama",
    value: "AL"
  },
  {
    label: "Alaska",
    value: "AK"
  },
  {
    label: "American Samoa",
    value: "AS"
  },
  {
    label: "Arizona",
    value: "AZ"
  },
  {
    label: "Arkansas",
    value: "AR"
  },
  {
    label: "California",
    value: "CA"
  },
  {
    label: "Colorado",
    value: "CO"
  },
  {
    label: "Connecticut",
    value: "CT"
  },
  {
    label: "Delaware",
    value: "DE"
  },
  {
    label: "District Of Columbia",
    value: "DC"
  },
  {
    label: "Federated States Of Micronesia",
    value: "FM"
  },
  {
    label: "Florida",
    value: "FL"
  },
  {
    label: "Georgia",
    value: "GA"
  },
  {
    label: "Guam",
    value: "GU"
  },
  {
    label: "Hawaii",
    value: "HI"
  },
  {
    label: "Idaho",
    value: "ID"
  },
  {
    label: "Illinois",
    value: "IL"
  },
  {
    label: "Indiana",
    value: "IN"
  },
  {
    label: "Iowa",
    value: "IA"
  },
  {
    label: "Kansas",
    value: "KS"
  },
  {
    label: "Kentucky",
    value: "KY"
  },
  {
    label: "Louisiana",
    value: "LA"
  },
  {
    label: "Maine",
    value: "ME"
  },
  {
    label: "Marshall Islands",
    value: "MH"
  },
  {
    label: "Maryland",
    value: "MD"
  },
  {
    label: "Massachusetts",
    value: "MA"
  },
  {
    label: "Michigan",
    value: "MI"
  },
  {
    label: "Minnesota",
    value: "MN"
  },
  {
    label: "Mississippi",
    value: "MS"
  },
  {
    label: "Missouri",
    value: "MO"
  },
  {
    label: "Montana",
    value: "MT"
  },
  {
    label: "Nebraska",
    value: "NE"
  },
  {
    label: "Nevada",
    value: "NV"
  },
  {
    label: "New Hampshire",
    value: "NH"
  },
  {
    label: "New Jersey",
    value: "NJ"
  },
  {
    label: "New Mexico",
    value: "NM"
  },
  {
    label: "New York",
    value: "NY"
  },
  {
    label: "North Carolina",
    value: "NC"
  },
  {
    label: "North Dakota",
    value: "ND"
  },
  {
    label: "Northern Mariana Islands",
    value: "MP"
  },
  {
    label: "Ohio",
    value: "OH"
  },
  {
    label: "Oklahoma",
    value: "OK"
  },
  {
    label: "Oregon",
    value: "OR"
  },
  {
    label: "Palau",
    value: "PW"
  },
  {
    label: "Pennsylvania",
    value: "PA"
  },
  {
    label: "Puerto Rico",
    value: "PR"
  },
  {
    label: "Rhode Island",
    value: "RI"
  },
  {
    label: "South Carolina",
    value: "SC"
  },
  {
    label: "South Dakota",
    value: "SD"
  },
  {
    label: "Tennessee",
    value: "TN"
  },
  {
    label: "Texas",
    value: "TX"
  },
  {
    label: "Utah",
    value: "UT"
  },
  {
    label: "Vermont",
    value: "VT"
  },
  {
    label: "Virgin Islands",
    value: "VI"
  },
  {
    label: "Virginia",
    value: "VA"
  },
  {
    label: "Washington",
    value: "WA"
  },
  {
    label: "West Virginia",
    value: "WV"
  },
  {
    label: "Wisconsin",
    value: "WI"
  },
  {
    label: "Wyoming",
    value: "WY"
  }
];

export const CANADA_STATES = [
  { label: "Alberta", value: "AB" },
  { label: "British Columbia", value: "BC" },
  { label: "Manitoba", value: "MB" },
  { label: "New Brunswick", value: "NB" },
  { label: "Newfoundland and Labrador", value: "NL" },
  { label: "Northwest Territories", value: "NT" },
  { label: "Nova Scotia", value: "NS" },
  { label: "Nunavut", value: "NU" },
  { label: "Ontario", value: "ON" },
  { label: "Prince Edward Island", value: "PE" },
  { label: "Quebec", value: "QC" },
  { label: "Saskatchewan", value: "SK" },
  { label: "Yukon", value: "YT" }
];
