import { mapFormFieldsFor } from "srx-shared";

import { ShipToTemplateSettings } from "@items/types";

export const MultiLocationsSettingsItem = {
  type: ShipToTemplateSettings,
  fieldsConfig: {
    multiLocationsEnabled: {
      label: "Super Ship-to",
      type: "switch",
      size: 3
    }
  },
  fields: ["multiLocationsEnabled"]
};
MultiLocationsSettingsItem.formConfig = mapFormFieldsFor(
  MultiLocationsSettingsItem
);
