import React from "react";

import {
  tableFor,
  filtersFor,
  breadcrumbsFor,
  withPageTitle
} from "srx-shared";

import { DocumentItem } from "@items/Document";
import { pages } from "@routes/pages";

const DocumentsTable = tableFor(DocumentItem);
const DocumentsFilters = filtersFor(DocumentItem);
const Breadcrumbs = breadcrumbsFor();

const Documents = () => {
  const renderFilters = table => <DocumentsFilters table={table} />;

  return (
    <>
      <Breadcrumbs renderList={() => [{ title: "Documents" }]} />
      <DocumentsTable renderFilters={renderFilters} />
    </>
  );
};

export default withPageTitle(pages.documents.title)(Documents);
