import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
  button: {
    color: theme.palette.primary.light,
    fontSize: 13,
    fontWeight: "bold",
    textTransform: "none",
    whiteSpace: "nowrap",
    "&:hover": {
      color: theme.palette.primary.light
    },
    "&:focus": {
      outline: "none",
      color: theme.palette.primary.light,
      textDecoration: "none"
    },
    [theme.breakpoints.down("sm")]: {
      padding: "2px 8px"
    }
  },
  icon: {
    height: 24,
    width: 24,
    [theme.breakpoints.down("sm")]: {
      height: 18,
      width: 18
    }
  }
}));
