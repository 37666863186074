import { connect } from "react-redux";

import { Selectors } from "srx-shared";

import { Distributor } from "@items/types";

const DistributorNameComponent = ({ currentDistributor }) =>
  currentDistributor ? currentDistributor.name : "...";

const mapStateToProps = state => ({
  currentDistributor: Selectors.selectActiveItem(state, Distributor)
});

export default connect(mapStateToProps)(DistributorNameComponent);
