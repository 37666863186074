import React from "react";

import Checkbox from "@material-ui/core/Checkbox";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

import { styles } from "./styles";

function SelectorComponent(props) {
  const { classes, header, selected, partial, onChange } = props;
  const { headSelector, rowSelector } = classes;

  return (
    <Checkbox
      color="primary"
      disableRipple={true}
      checked={Boolean(selected)}
      indeterminate={Boolean(partial)}
      classes={{ root: header ? headSelector : rowSelector }}
      onChange={onChange}
    />
  );
}

SelectorComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  header: PropTypes.bool,
  selected: PropTypes.bool,
  partial: PropTypes.bool,
  onChange: PropTypes.func
};

export const Selector = withStyles(styles)(SelectorComponent);
