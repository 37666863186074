import React, { useState } from "react";

import { Modal, Button, IconButton } from "@material-ui/core";
import VideoIcon from "@material-ui/icons/PlayCircleOutline";
import PropTypes from "prop-types";
import ReactPlayer from "react-player/lazy";

import { useMobile } from "../../../../hooks";
import { useStyles } from "./styles";

WatchVideoButton.propTypes = {
  url: PropTypes.string
};

export function WatchVideoButton({ url }) {
  const cls = useStyles();

  const isMobile = useMobile();

  const [showVideoPlayer, setShowVideoPlayer] = useState(false);

  if (!url) return null;

  const handleClick = e => {
    e.stopPropagation();
    setShowVideoPlayer(true);
  };

  const handleClose = e => {
    e.stopPropagation();
    setShowVideoPlayer(false);
  };

  return (
    <>
      {isMobile ? (
        <IconButton classes={{ root: cls.button }} onClick={handleClick}>
          <VideoIcon className={cls.icon} />
        </IconButton>
      ) : (
        <Button
          classes={{ root: cls.button }}
          startIcon={<VideoIcon className={cls.icon} />}
          onClick={handleClick}
        >
          Watch a video
        </Button>
      )}

      <Modal
        onClose={handleClose}
        open={showVideoPlayer}
        disableEnforceFocus
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <ReactPlayer url={url} controls style={{ outline: "none" }} />
      </Modal>
    </>
  );
}
