import React from "react";

import { withPageTitle } from "srx-shared";
import { PageTabContainer } from "srx-shared/mui";

import { pages } from "@routes/pages";

import { hardwareTabs } from "./hardwareTabs";

function HardwareComponent() {
  return <PageTabContainer tabs={hardwareTabs} />;
}

export default withPageTitle(pages.hardware.title)(HardwareComponent);
