import React from "react";

import { withStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";
import { fromJS } from "immutable";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";

import { Selectors } from "../..";
import { ActionCreators } from "../../actions";
import { DckActionCreators, DckSelectors } from "../../redux";
import { isMobile } from "../../utils";
import { TableFilters } from "./TableFilters";
import { AddFilter, ApplyFilters, ClearFilters } from "./actionButtons";
import { styles } from "./styles";

export const filtersFor = (
  item,
  {
    clearAll = true,
    autoApply = true,
    autoApplyDelay = 1000,
    resetCurrentPage = false
  } = {}
) => {
  class Wrapper extends React.Component {
    static propTypes = {
      classes: PropTypes.object.isRequired,
      setSelectedFilter: PropTypes.func,
      setItemFilter: PropTypes.func,
      setItemFilters: PropTypes.func,
      setCurrentPage: PropTypes.func,
      removeItemFilter: PropTypes.func,
      itemFilters: PropTypes.any,
      table: PropTypes.object,
      loading: PropTypes.bool,
      selectedFilters: PropTypes.object
    };

    static defaultProps = {
      table: {
        reload: () => {
          //Mock table prop if table prop is not provided
        }
      }
    };

    state = {
      disabled: false,
      filters: [],
      lastIndex: 0
    };

    componentDidMount() {
      const filters = item.filters.map((el, index) => {
        const filterValues = this.props.itemFilters[el.field];
        return filterValues
          ? {
              ...el,
              value: el.isMulti ? filterValues : filterValues[0],
              selected: index + 1
            }
          : { ...el, value: el.isMulti ? [] : "", selected: 0 };
      });
      filters
        .filter(el => Boolean(el.selected))
        .forEach(el => this.props.setSelectedFilter(el.field, true));
      this.setState({ filters, lastIndex: item.filters.length });
    }

    componentDidUpdate(prevProps) {
      if (prevProps.loading && !this.props.loading)
        this.setState({ disabled: false });
      if (prevProps.selectedFilters !== this.props.selectedFilters) {
        item.filters
          .filter(
            ({ field }) =>
              Boolean(prevProps.selectedFilters[field]) !==
              Boolean(this.props.selectedFilters[field])
          )
          .forEach(filter => {
            this.setSelected(
              { field: filter.field },
              Boolean(this.props.selectedFilters[filter.field])
            );
          });
      }
      if (prevProps.itemFilters !== this.props.itemFilters) {
        Object.keys(this.props.itemFilters).forEach(filter =>
          this.setSelected(
            {
              field: filter
            },
            true
          )
        );
      }
    }

    resetCurrentPage = () => {
      resetCurrentPage && this.props.setCurrentPage(1);
    };

    setSelected = (filter, selected) => {
      const filters = [...this.state.filters];
      const found = filters.find(el => el.field === filter.field);
      if (found) {
        if (Boolean(found.selected) === Boolean(selected)) return;
        if (!selected) {
          found.selected = 0;
        } else {
          const lastIndex = this.state.lastIndex + 1;
          found.selected = lastIndex;
          this.setState({ lastIndex });
        }
        this.setState({ filters });
        if (Boolean(this.props.selectedFilters[filter.field]) !== selected)
          this.props.setSelectedFilter(filter.field, selected);
      }
    };

    applyFilter = (filter, value) => {
      const { setItemFilter, table } = this.props;
      const filters = [...this.state.filters];
      this.setState({ disabled: true }, () => {
        this.resetCurrentPage();
        setItemFilter(
          filter.field,
          filter.isMulti ? value.split(",") : [value]
        );
        if (filter.closeAfterApply) {
          const foundClose = filters.find(
            el => el.field === filter.closeAfterApply
          );
          if (foundClose) {
            this.handleClose(foundClose);
          }
        }
        table.reload();
      });
    };

    handleSelect = filter => this.setSelected(filter, true);

    handleClose = filter => {
      const { itemFilters, removeItemFilter, table } = this.props;
      this.setSelected(filter, false);
      if (itemFilters[filter.field]) {
        this.setState({ disabled: true }, () => {
          this.resetCurrentPage();
          removeItemFilter(filter.field);
          table.reload();
        });
      }
    };

    handleChange = (filter, value) => {
      value = String(value).trim();
      this.applyFilter(filter, value);
    };

    handleApply = () => {
      //TODO: this.props.table.loadItems({ page: 0 });
    };

    hasApplied = () =>
      this.state.filters.reduce(
        (applied, el) => applied || Boolean(el.selected),
        false
      );

    handleClearAll = () => {
      const { table, itemFilters } = this.props;
      this.setState({ disabled: true }, () => {
        item.filters.forEach(({ field }) => delete itemFilters[field]);
        this.resetCurrentPage();
        this.props.setItemFilters(fromJS(itemFilters));
        const filters = item.filters.map((el, index) => ({
          ...el,
          value: el.isMulti ? [] : "",
          selected: 0
        }));
        this.setState({ filters, lastIndex: item.filters.length });
        table.reload();
      });
    };

    render() {
      const { classes, itemFilters } = this.props;
      const { filters, disabled } = this.state;
      const mobile = isMobile(this);

      return (
        <div className={classes.root}>
          <TableFilters
            item={item}
            filters={filters}
            values={itemFilters}
            onClose={this.handleClose}
            onChange={this.handleChange}
            disabled={disabled}
            autoApply={autoApply}
            autoApplyDelay={autoApplyDelay}
          />
          <div className={classes.actions}>
            <AddFilter
              item={item}
              filters={filters}
              onSelect={this.handleSelect}
              disabled={disabled}
              mobile={mobile}
            />
            {!autoApply && (
              <ApplyFilters
                onClick={this.handleApply}
                disabled={disabled}
                mobile={mobile}
              />
            )}
            {clearAll && this.hasApplied() && (
              <ClearFilters
                onClick={this.handleClearAll}
                disabled={disabled}
                mobile={mobile}
              />
            )}
          </div>
        </div>
      );
    }
  }

  const mapStateToProps = state => ({
    loading: DckSelectors.selectProcessRunning(
      state,
      `${item.type.toUpperCase()}_LOAD`
    ),
    itemFilters: Selectors.selectItemSearchFilters(state, item.type),
    selectedFilters: Selectors.selectedFilters(state)
  });

  const mapDispatchToProps = {
    setCurrentPage: currentPage =>
      ActionCreators.setCurrentPage(item.type, currentPage),
    setItemFilter: (field, values) =>
      DckActionCreators.setItemSearchFilters(item.type, field, values),
    removeItemFilter: field =>
      DckActionCreators.removeItemSearchFilter(item.type, field),
    setSelectedFilter: (field, selected) =>
      ActionCreators.setSelectedFilter(field, selected),
    setItemFilters: filters =>
      DckActionCreators.setItemData(item.type, "filters", filters)
  };

  return compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles),
    withWidth()
  )(Wrapper);
};

export const mapFiltersFor = (item, names) =>
  names.map(field => ({
    field,
    ...item.filtersConfig[field]
  }));
