import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  root: {
    margin: 5,
    border: "1px solid white",
    padding: 4,
    width: 508,
    height: 76
  },
  content: {
    height: "100%"
  },
  notAssigned: {
    fontSize: 13,
    color: "#AAAAAA",
    textAlign: "center"
  },
  label: {
    fontSize: 10,
    color: "#777",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "noWrap"
  },
  text: {
    marginTop: 8,
    fontSize: 14,
    color: "#333",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "noWrap"
  }
});
