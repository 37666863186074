import { BaseFees } from "./BaseFees";
import { CamerasMonthlyFees } from "./CamerasMonthlyFees";
import { ShiptoFees } from "./ShiptoFees";
import { TriggersMonthlyFees } from "./TriggersMonthlyFees";

export const feesTabs = [
  {
    title: "Base Fees",
    key: "base-fees",
    component: BaseFees
  },
  {
    title: "Triggers Monthly Fees",
    key: "triggers-fees",
    component: TriggersMonthlyFees
  },
  {
    title: "Cameras Monthly Fees",
    key: "cameras-fees",
    component: CamerasMonthlyFees
  },
  {
    title: "ShipTo Fees",
    key: "shipto-fees",
    component: ShiptoFees
  }
];
