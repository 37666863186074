/* eslint react/prop-types: "off" */
import * as V from "yup";

import { mapFormFieldsFor, reListOfEmails } from "srx-shared";

import { ShipToTemplateSettings } from "@items/types";

import { ErpSelect } from "./ErpSelect";
import { StatusSelect } from "./StatusSelect";

V.addMethod(V.string, "listOfEmails", function () {
  return this.test(
    "listOfEmails",
    "Please enter a valid email or emails, separated by comma",
    value => {
      return value ? reListOfEmails.test(value) : true;
    }
  );
});

const isNotSelectErpEmail = form => form.values.integration !== "ERP_EMAIL";

export const RLSubmitSettingsItem = {
  type: ShipToTemplateSettings,
  fieldsConfig: {
    integration: {
      name: "integration",
      label: "Set rule for how reorder lists are sent",
      type: "radio",
      size: 12,
      initialValue: "EMAIL",
      options: [
        {
          label: "Email",
          value: "EMAIL",
          disabled: false
        },
        {
          label: "ERP email",
          value: "ERP_EMAIL",
          disabled: false
        },
        {
          label: "ERP API (no connection added)",
          value: "API",
          disabled: true
        }
      ],
      noHelperText: true
    },
    "metadata.erp": {
      name: "metadata.erp",
      label: "ERP",
      Component: ErpSelect,
      options: [
        {
          label: "Eclipse",
          value: "ECLIPSE"
        }
      ],
      size: 3,
      initialValue: "",
      required: true,
      checkHidden: isNotSelectErpEmail
    },
    "metadata.erpEmail": {
      name: "metadata.erpEmail",
      label: "ERP Email",
      size: 3,
      initialValue: "",
      checkHidden: isNotSelectErpEmail
    },
    "metadata.orderStatus": {
      name: "metadata.orderStatus",
      label: "Order Status",
      Component: StatusSelect,
      dict: "rlSubmitStatus",
      size: 3,
      initialValue: "",
      required: true,
      checkHidden: isNotSelectErpEmail
    },
    "metadata.quoteStatus": {
      name: "metadata.quoteStatus",
      label: "Quote Status",
      Component: StatusSelect,
      dict: "rlSubmitStatus",
      size: 3,
      initialValue: "",
      required: true,
      checkHidden: isNotSelectErpEmail
    }
  },
  schema: V.object({
    metadata: V.object({
      erp: V.string()
        .label("ERP")
        .when("$integration", {
          is: "ERP_EMAIL",
          then: schema => schema.required()
        }),
      erpEmail: V.string()
        .label("ERP Email")
        .when("$integration", {
          is: "ERP_EMAIL",
          then: schema => schema.listOfEmails().max(1023).nullable()
        }),
      orderStatus: V.string()
        .label("Order Status")
        .when("$integration", {
          is: "ERP_EMAIL",
          then: schema => schema.required()
        }),
      quoteStatus: V.string()
        .label("Quote Status")
        .when("$integration", {
          is: "ERP_EMAIL",
          then: schema => schema.required()
        })
    })
  })
};

RLSubmitSettingsItem.fields = [
  "integration",
  "metadata.erp",
  "metadata.erpEmail",
  "metadata.orderStatus",
  "metadata.quoteStatus"
];

RLSubmitSettingsItem.formConfig = mapFormFieldsFor(RLSubmitSettingsItem);
