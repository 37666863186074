/* eslint react/display-name: "off" */
/* eslint react/prop-types: "off" */
import React from "react";

import { EditButton, RemoveButton } from "srx-shared/mui";

import { UpcGtinCatalogItem } from "@items/UpcGtinCatalog";

export const Actions = ({ row }) => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      width: "100%",
      margin: "3px 0"
    }}
  >
    <EditButton
      row={row}
      item={UpcGtinCatalogItem}
      tip="UPC & GTIN"
      disabled={Boolean(row.distributorName)}
    />
    <RemoveButton
      row={row}
      item={UpcGtinCatalogItem}
      tip="UPC & GTIN"
      disabled={Boolean(row.distributorName)}
      modalProps={{ size: "sm" }}
    >
      Are you sure you want to delete item:
      <br />
      <pre>
        UPC: <b>{row.upc}</b>, GTIN: <b>{row.gtin}</b>
      </pre>
    </RemoveButton>
  </div>
);
