import React from "react";

import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

import { styles } from "./styles";

class SideBarLogoComponent extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    open: PropTypes.bool,
    logo: PropTypes.string,
    icon: PropTypes.string
  };

  static defaultProps = {
    open: true
  };

  render() {
    const { classes, open, logo, icon } = this.props;

    return (
      <div className={classes.image}>
        {open ? (
          <img className={classes.logo} src={logo} alt="" />
        ) : (
          <img className={classes.icon} src={icon} alt="" />
        )}
      </div>
    );
  }
}

export const SideBarLogo = withStyles(styles)(SideBarLogoComponent);
