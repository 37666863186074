import React from "react";

import { Grid, Paper } from "@material-ui/core";
import { DckSelectors, DckActionCreators } from "dck-redux";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { compose } from "redux";

import { breadcrumbsFor, generalFormFor } from "srx-shared";
import { ActionsSpinner, FormButton } from "srx-shared/mui";

import { pages } from "@routes/pages";

import DistributorName from "../DistributorName";
import { DiscountsItem } from "./DiscountsItem";

const DiscountsForm = generalFormFor(DiscountsItem);
const Breadcrumbs = breadcrumbsFor();

class Discounts extends React.PureComponent {
  static propTypes = {
    discounts: PropTypes.object,
    updating: PropTypes.bool,
    loadDiscounts: PropTypes.func,
    updateDiscounts: PropTypes.func,
    match: PropTypes.object
  };

  componentDidMount() {
    const {
      loadDiscounts,
      match: { params }
    } = this.props;
    loadDiscounts(params.id);
  }

  handleSave = data => {
    const {
      updateDiscounts,
      match: { params }
    } = this.props;
    updateDiscounts(params.id, data);
  };

  breadcrumbsList = () => [
    { title: pages.distributors.title, to: pages.distributors.path },
    { title: <DistributorName /> },
    { title: "Discounts" }
  ];

  renderActions = (form, formikProps) => {
    const { updating } = this.props;
    const { dirty } = formikProps;
    const disabled = !dirty || updating;
    return (
      <div
        style={{
          marginTop: 20,
          display: "flex",
          justifyContent: "flex-end",
          marginRight: 12
        }}
      >
        <ActionsSpinner processing={updating} />
        <FormButton variant="reset" disabled={disabled} />
        <FormButton variant="save" disabled={disabled} />
      </div>
    );
  };

  render() {
    const { discounts, updating } = this.props;
    return (
      <Grid container justify="center">
        <Breadcrumbs renderList={this.breadcrumbsList} />
        <Paper style={{ padding: 24, maxWidth: 600 }}>
          <DiscountsForm
            renderActions={this.renderActions}
            initialData={discounts}
            onSubmit={this.handleSave}
            processing={updating}
          />
        </Paper>
      </Grid>
    );
  }
}

const process = `${DiscountsItem.type.toUpperCase()}_UPDATE`;

const mapStateToProps = state => ({
  discounts: DckSelectors.getItemData(state, DiscountsItem.type, "values"),
  updating: DckSelectors.selectProcessRunning(state, process)
});

const mapDispatchToProps = {
  updateDiscounts: (id, data) =>
    DckActionCreators.itemSave(DiscountsItem.type, id, data),
  loadDiscounts: id => DckActionCreators.itemsLoad(DiscountsItem.type, { id })
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(Discounts);
