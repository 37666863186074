import * as React from "react";

import InputLabel from "@material-ui/core/InputLabel";
import { withStyles } from "@material-ui/core/styles";
import _isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";

import { styles } from "./styles";

const SelectLabel = props => {
  const {
    id,
    label,
    hasInputFocus,
    isDisabled,
    shrink,
    error,
    inputLabelProps,
    classes
  } = props;

  if (_isEmpty(label)) {
    return null;
  }

  return (
    <InputLabel
      classes={{
        root: classes.label,
        disabled: classes.disabled,
        shrink: classes.shrink,
        focused: classes.focused,
        error: classes.error
      }}
      disabled={isDisabled}
      focused={hasInputFocus}
      htmlFor={id}
      {...inputLabelProps}
      error={error}
      shrink={shrink}
    >
      {label}
    </InputLabel>
  );
};

SelectLabel.propTypes = {
  id: PropTypes.any,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  hasInputFocus: PropTypes.bool,
  isDisabled: PropTypes.bool,
  error: PropTypes.bool,
  shrink: PropTypes.bool,
  inputLabelProps: PropTypes.object,
  classes: PropTypes.object
};

export default withStyles(styles)(SelectLabel);
