import React from "react";

import { Button, Paper, Grid, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import MoveRightIcon from "@material-ui/icons/FastForwardOutlined";
import MoveLeftIcon from "@material-ui/icons/FastRewindOutlined";
import PropTypes from "prop-types";

import { IconButton } from "../IconButton";
import { Loading } from "../Loading";
import { styles } from "./styles";

const join = (a, b) => (a.length === 0 ? [...b] : [...a, ...b]);

class DualListComponent extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    available: PropTypes.array,
    selected: PropTypes.array,
    availableLabel: PropTypes.string,
    selectedLabel: PropTypes.string,
    loading: PropTypes.bool,
    onMove: PropTypes.func,
    disabled: PropTypes.bool,
    noItemsText: PropTypes.string
  };

  static defaultProps = {
    noItemsText: "No items to select"
  };

  state = {
    leftSelected: [],
    rightSelected: [],
    leftList: [],
    rightList: []
  };

  componentDidMount() {
    this.updateLists();
  }

  componentDidUpdate(prevProps) {
    if (
      ["available", "selected"].some(key => prevProps[key] !== this.props[key])
    ) {
      this.updateLists();
    }
  }

  updateLists = () => {
    const { selected, available } = this.props;
    const leftList = selected ? [...selected] : [];
    const rightList = available
      ? selected
        ? available.filter(
            a =>
              selected.filter(s => String(a.value) === String(s.value))
                .length === 0
          )
        : [...available]
      : [];
    this.setState({ leftList, rightList });
  };

  onMoveLeft = () => {
    const { rightList, rightSelected, leftList } = this.state;
    const left = join(
      leftList,
      rightList.filter(op => rightSelected.includes(String(op.value)))
    );
    const right = rightList.filter(
      op => !rightSelected.includes(String(op.value))
    );
    this.props.onMove(left, right);
    this.setState({
      leftList: left,
      rightList: right,
      rightSelected: []
    });
  };

  onMoveAllLeft = () => {
    const { rightList, leftList } = this.state;
    const left = join(leftList, rightList);
    this.props.onMove(left, []);
    this.setState({
      leftList: left,
      rightList: [],
      rightSelected: []
    });
  };

  onMoveRight = () => {
    const { rightList, leftSelected, leftList } = this.state;
    const right = join(
      rightList,
      leftList.filter(op => leftSelected.includes(String(op.value)))
    );
    const left = leftList.filter(
      op => !leftSelected.includes(String(op.value))
    );
    this.props.onMove(left, right);
    this.setState({
      leftList: left,
      rightList: right,
      leftSelected: []
    });
  };

  onMoveAllRight = () => {
    const { rightList, leftList } = this.state;
    const right = join(rightList, leftList);
    this.props.onMove([], right);
    this.setState({
      leftList: [],
      rightList: right,
      leftSelected: []
    });
  };

  selectedOptions = e => {
    const options = e.target.options;
    const values = [];
    for (let i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) values.push(String(options[i].value));
    }
    return values;
  };

  onSelectInLeft = e =>
    this.setState({
      leftSelected: this.selectedOptions(e)
    });

  onSelectInRight = e =>
    this.setState({
      rightSelected: this.selectedOptions(e)
    });

  renderLeftList = () => {
    const { leftList, leftSelected } = this.state;
    const { classes } = this.props;
    return leftList.length > 0 ? (
      <select
        className={classes.selectList}
        style={{ height: 200 }}
        multiple
        onChange={this.onSelectInLeft}
        value={leftSelected}
        disabled={this.props.disabled}
      >
        {leftList.map(op => (
          <option
            value={op.value}
            key={`left-${op.value}`}
            className={classes.selectListOption}
          >
            {op.label}
          </option>
        ))}
      </select>
    ) : (
      <div className={classes.disabledLabel}>Select from available list...</div>
    );
  };

  renderRightList = () => {
    const { loading, classes, noItemsText } = this.props;
    const { rightList, rightSelected } = this.state;
    return loading ? (
      <Loading />
    ) : rightList.length > 0 ? (
      <select
        className={classes.selectList}
        style={{ height: 200 }}
        multiple
        onChange={this.onSelectInRight}
        value={rightSelected}
        disabled={this.props.disabled}
      >
        {rightList.map(op => (
          <option
            value={op.value}
            key={`right-${op.value}`}
            className={classes.selectListOption}
          >
            {op.label}
          </option>
        ))}
      </select>
    ) : (
      <div className={classes.disabledLabel}>{noItemsText}</div>
    );
  };

  render() {
    const { disabled, availableLabel, selectedLabel, classes } = this.props;
    const { leftList, rightList, leftSelected, rightSelected } = this.state;

    return (
      <Grid container spacing={2}>
        <Grid item xs={12} md>
          <div className={classes.listTitle}>
            <Typography
              variant="subtitle1"
              className={disabled ? classes.disabledLabel : ""}
            >
              {selectedLabel || ""}
            </Typography>
            <Button
              onClick={this.onMoveAllRight}
              disabled={leftList.length === 0 || disabled}
            >
              Clear all
            </Button>
          </div>
          <Paper variant="outlined" className={classes.selectWrapper}>
            {this.renderLeftList()}
          </Paper>
        </Grid>
        <Grid item xs={12} md="auto">
          <div className={classes.buttonsWrapper}>
            <IconButton
              styles={{ marginLeft: 10 }}
              disabled={rightSelected.length === 0 || disabled}
              onClick={this.onMoveLeft}
              icon={<MoveLeftIcon />}
              data-testid="move-left"
            />
            <IconButton
              styles={{ marginLeft: 10 }}
              disabled={leftSelected.length === 0 || disabled}
              onClick={this.onMoveRight}
              icon={<MoveRightIcon />}
              data-testid="move-right"
            />
          </div>
        </Grid>
        <Grid item xs={12} md>
          <div className={classes.listTitle}>
            <Typography
              variant="subtitle1"
              className={disabled ? classes.disabledLabel : ""}
            >
              {availableLabel || ""}
            </Typography>
            <Button
              onClick={this.onMoveAllLeft}
              disabled={rightList.length === 0 || disabled}
            >
              Select all
            </Button>
          </div>
          <Paper variant="outlined" className={classes.selectWrapper}>
            {this.renderRightList()}
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

export const DualList = withStyles(styles)(DualListComponent);
