import React from "react";

import {
  tableFor,
  filtersFor,
  editFormFor,
  editModalFor,
  createFormFor,
  actionsFor,
  breadcrumbsFor
} from "srx-shared";

import { AdminUserItem } from "@items/AdminUser";
import { pages } from "@routes/pages";

import DistributorName from "../DistributorName";

const AdminUserTable = tableFor(AdminUserItem);
const AdminUserFilters = filtersFor(AdminUserItem);
const AdminUserCreateForm = createFormFor(AdminUserItem, {
  title: "Add Admin User",
  size: "sm"
});
const AdminUserActions = actionsFor(AdminUserItem, {
  CreateForm: AdminUserCreateForm
});
const AdminUserEditForm = editFormFor(AdminUserItem);
const AdminUserEditModal = editModalFor(AdminUserItem, {
  EditForm: AdminUserEditForm
});
const BreadcrumbsComponent = breadcrumbsFor();

const AdminUsers = () => {
  const breadcrumbsList = () => [
    { title: pages.distributors.title, to: pages.distributors.path },
    { title: <DistributorName /> },
    { title: "Admin Users" }
  ];
  const renderFilters = table => <AdminUserFilters table={table} />;
  const renderActions = table => <AdminUserActions table={table} />;

  return (
    <>
      <BreadcrumbsComponent renderList={breadcrumbsList} />
      <AdminUserTable
        renderFilters={renderFilters}
        renderActions={renderActions}
      />
      <AdminUserEditModal />
    </>
  );
};

export default AdminUsers;
