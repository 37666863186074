import React from "react";

import cn from "classnames";
import ReactTable from "react-table";

import propTypes from "./propTypes";

export function Table(props) {
  const { className, selectable } = props;
  return (
    <ReactTable
      {...props}
      className={cn(className, selectable && "rthfc -se")}
    />
  );
}

ReactTable.propTypes = propTypes;
Table.propTypes = propTypes;
