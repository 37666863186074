/* eslint react/prop-types: "off" */
import { Grid } from "@material-ui/core";

import { mapFormFieldsFor } from "srx-shared";
import { Message } from "srx-shared/mui";

import { ShipToTemplateSettings } from "@items/types";

const onLabelOptionsChange = (values, form) =>
  values.includes("ENABLE_REORDER_CONTROLS") &&
  form.setFieldValue(
    "labelOptions",
    values.filter(value => value !== "ENABLE_SCAN_TO_ORDER"),
    false
  );

export const CheckoutSoftwareSettingsItem = {
  type: ShipToTemplateSettings,
  fieldsConfig: {
    reorderControls: {
      name: "reorderControls",
      label: "Reorder Controls for ShipTo",
      type: "radio",
      size: 12,
      initialValue: "ADD_AT_MIN",
      noHelperText: true,
      options: [
        { label: "Add at Min", value: "ADD_AT_MIN" },
        { label: "Add as Issued", value: "ADD_AS_ISSUED" }
      ]
    },
    labelOptions: {
      name: "labelOptions",
      label: "Labels Options",
      type: "checkGroup",
      size: 12,
      initialValue: ["ENABLE_SCAN_TO_ORDER"],
      onChange: onLabelOptionsChange,
      options: [
        { label: "Track OHI", value: "TRACK_OHI" },
        {
          label: "Enable Scan to Order",
          value: "ENABLE_SCAN_TO_ORDER",
          disabled: value => value.includes("ENABLE_REORDER_CONTROLS")
        },
        {
          label: "Enable Reorder Controls",
          value: "ENABLE_REORDER_CONTROLS"
        }
      ]
    },
    infoMessage: {
      Component: ({ form }) => {
        const { labelOptions } = form.values;

        if (!labelOptions.includes("ENABLE_REORDER_CONTROLS")) return null;

        return (
          <Grid item xs={12} style={{ padding: "0 12px" }}>
            <Message variant="info">
              Scan to Order available only when Reorder Controls are off
            </Message>
          </Grid>
        );
      }
    }
  },
  fields: ["reorderControls", "labelOptions", "infoMessage"]
};
CheckoutSoftwareSettingsItem.formConfig = mapFormFieldsFor(
  CheckoutSoftwareSettingsItem
);
