import React from "react";

import { connect } from "react-redux";

import {
  getPropTypesForDict,
  stateToPropsForDict
} from "../../../actions/helpers";
import { Select } from "../../Select";

class SelectFieldComponent extends React.Component {
  static propTypes = {
    ...getPropTypesForDict()
  };

  state = {
    options: this.props.dict
      ? this.props[this.props.dict] || []
      : this.props.options || []
  };

  componentDidUpdate(prevProps, prevState) {
    const { dict, options, fieldProps = {} } = this.props;
    if (prevProps[dict] !== this.props[dict]) {
      this.setState({ options: this.props[dict] });
    } else if (JSON.stringify(prevProps.options) !== JSON.stringify(options)) {
      this.setState({ options });
    }
    if (prevState.options !== this.state.options) {
      const option =
        this.state.options.find(el => el.value === fieldProps.value) || {};
      this.props.onChange(option.value || "");
    }
  }

  getValue = value => {
    const {
      fieldProps: { selector }
    } = this.props;
    return value && selector ? value[selector] : value;
  };

  getCurrentOption = options => {
    const {
      fieldProps: { value }
    } = this.props;

    const currentOption = options.find(
      opt => String(this.getValue(opt.value)) === String(this.getValue(value))
    );
    return currentOption;
  };

  onChange = value => {
    const { options } = this.state;
    const {
      onChange,
      fieldProps: { selector }
    } = this.props;

    const values = options
      .filter(opt => value.indexOf(opt.value[selector]) !== -1)
      .map(opt => opt.value);

    onChange(values);
  };

  render() {
    const {
      onChange,
      fieldProps,
      fieldProps: { selector },
      FormHelperTextProps,
      isAsync,
      isMulti
    } = this.props;

    let options = [...this.state.options];

    const optionsWithSelector =
      selector && options
        ? options.map(opt => ({ ...opt, value: opt.value[selector] }))
        : options;

    return (
      <>
        {isAsync && (
          <Select
            {...fieldProps}
            async={true}
            FormHelperTextProps={FormHelperTextProps}
            onChange={onChange}
          />
        )}
        {options && !isAsync && isMulti && (
          <Select
            isMulti
            {...fieldProps}
            FormHelperTextProps={FormHelperTextProps}
            onChange={selector ? this.onChange : onChange}
            value={optionsWithSelector.filter(
              opt =>
                fieldProps.value &&
                fieldProps.value.some(
                  value => String(this.getValue(value)) === String(opt.value)
                )
            )}
            options={optionsWithSelector}
          />
        )}
        {options && !isAsync && !isMulti && (
          <Select
            {...fieldProps}
            FormHelperTextProps={FormHelperTextProps}
            onChange={onChange}
            value={this.getCurrentOption(options)}
            options={options}
          />
        )}
      </>
    );
  }
}

export const SelectField = connect(
  state => stateToPropsForDict(state),
  null
)(SelectFieldComponent);
