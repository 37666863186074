import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
  sectionBoundary: {
    border: "1px solid rgba(0, 0, 0, 0.12)",
    borderRadius: 5,
    padding: "8px 16px"
  },
  appAndPlayStoreImage: {
    width: 120,
    height: 40,
    cursor: "pointer"
  },
  srxCheckoutImage: {
    height: 48,
    width: 48
  },
  gridTextStyle: {
    fontSize: 20,
    color: theme.palette.primary.main,
    overflow: "hidden",
    textOverflow: "ellipsis"
  }
}));
