import { takeEvery, all } from "redux-saga/effects";

import { Process, isActionSelect, isActionUpdate } from "srx-shared";

import * as RestApi from "@api";
import {
  SrxVmiOperationalCosts,
  DistributorInventory,
  TraditionalVmiOperationalCosts,
  OperationalCosts,
  SuggestedMinMaxFormula,
  SupplyForce
} from "@items/types";

function* loadCostSavingItemSaga(action) {
  const { itemType, id } = action;
  const url = id ? `Get${itemType}` : `GetDefault${itemType}`;
  const proc = new Process.Load(itemType, RestApi[url]);

  yield proc.start();
  try {
    yield proc.callApi(id);
    yield proc.setItemData("data", proc.data);
    yield proc.stop();
  } catch (ex) {
    yield proc.fail(ex);
  }
}

function* saveCostSavingItemSaga(action) {
  const { itemType, id, data } = action;
  const url = id ? `Update${itemType}` : `UpdateDefault${itemType}`;

  const proc = new Process.Update(itemType, RestApi[url]);
  yield proc.start();
  try {
    yield proc.callApi(data, id);
    yield proc.stop();
    yield loadCostSavingItemSaga(action);
  } catch (ex) {
    yield proc.fail(ex);
  }
}

function* costSavingSaga() {
  yield all([
    takeEvery(isActionSelect(SrxVmiOperationalCosts), loadCostSavingItemSaga),
    takeEvery(isActionUpdate(SrxVmiOperationalCosts), saveCostSavingItemSaga),
    takeEvery(isActionSelect(DistributorInventory), loadCostSavingItemSaga),
    takeEvery(isActionUpdate(DistributorInventory), saveCostSavingItemSaga),
    takeEvery(
      isActionSelect(TraditionalVmiOperationalCosts),
      loadCostSavingItemSaga
    ),
    takeEvery(
      isActionUpdate(TraditionalVmiOperationalCosts),
      saveCostSavingItemSaga
    ),
    takeEvery(isActionSelect(OperationalCosts), loadCostSavingItemSaga),
    takeEvery(isActionUpdate(OperationalCosts), saveCostSavingItemSaga),
    takeEvery(isActionSelect(SuggestedMinMaxFormula), loadCostSavingItemSaga),
    takeEvery(isActionUpdate(SuggestedMinMaxFormula), saveCostSavingItemSaga),
    takeEvery(isActionSelect(SupplyForce), loadCostSavingItemSaga),
    takeEvery(isActionUpdate(SupplyForce), saveCostSavingItemSaga)
  ]);
}

export default costSavingSaga;
