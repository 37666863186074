export const styles = theme => ({
  tableHeader: {
    display: "flex",
    justifyContent: "space-between",
    padding: 10,
    fontSize: "13px",
    color: "#707070"
  },
  selectAllBtn: {
    textTransform: "none",
    padding: 0,
    fontSize: 13,
    fontWeight: "bold",
    color: theme.palette.primary.main,
    "& disabled": {
      opacity: 0.5,
      color: theme.palette.primary.main
    }
  },
  selectableTable: {
    "& > div.rt-table ": {
      boxShadow: "none"
    }
  },
  selectIconButton: {
    color: theme.palette.primary.main,
    marginRight: 12,
    "& disabled": {
      color: "rgba(0, 0, 0, 0.26)"
    }
  },
  selectableHeader: {
    fontWeight: 500,
    display: "flex",
    flexDirection: "column",
    "& > .rt-tr > .rt-th": {
      padding: "8px 10px",
      outline: "none !important",
      fontSize: "13px",
      color: "#707070"
    }
  }
});
