import { call } from "redux-saga/effects";

import { ApiUpload } from "../api";
import { Process } from "../processes";
import { readFile } from "../utils";

const MEGABYTE = 1024 * 1024;

const getErrorMessage = fileTypes => {
  if (fileTypes.includes("image") && fileTypes.includes("pdf")) {
    return "You can only upload PNG, JPG or PDF files";
  } else if (fileTypes.includes("image")) {
    return "You can only upload PNG, JPG files";
  } else if (fileTypes.includes("pdf")) {
    return "You can only upload PDF files";
  }
};

export async function uploadFile(url, data, contentType) {
  const headers = contentType ? { "Content-Type": contentType } : {};
  try {
    await ApiUpload(url, null, data, headers);
  } catch {
    throw Error("Unable to upload file... aborted");
  }
}

// @params
// action.data{ file{}, fileTypes[], maxFileSize, documentType, params }
// RestApi{ Get<action.itemType>UrlForUpload, Update<action.itemType>, UploadFile }
// function* postProcessCallback(response.data)
export function* uploadFileToS3(action, RestApi, postProcessCallback) {
  const thisItem = action.itemType;
  const proc = new Process.Import(thisItem);
  const { file, fileTypes, maxFileSize, params, ...rest } = action.data;

  try {
    yield proc.start();
    const error = {};
    if (fileTypes) {
      if (
        !(
          (fileTypes.includes("image") &&
            ["image/png", "image/jpeg"].includes(file.type)) ||
          (fileTypes.includes("pdf") && ["application/pdf"].includes(file.type))
        )
      ) {
        error.message = getErrorMessage(fileTypes);
      }
    }
    if (error.message) {
      throw error;
    }
    if (maxFileSize && file.size > maxFileSize) {
      error.message = `Maximum allowed file size is ${
        maxFileSize / MEGABYTE
      } MB`;
      throw error;
    }
    const binaryData = yield readFile(file);
    const session = yield proc.session();
    global.currentAction = "edit";
    const response = yield call(
      RestApi[`Get${thisItem}UrlForUpload`],
      session.idToken,
      params
    );
    yield uploadFile(response.data.url, binaryData, file.type);
    const data = { ...response.data, ...rest };
    const api = RestApi[`Update${thisItem}`];
    if (api) {
      yield call(api, session.idToken, data, params);
    }
    global.currentAction = null;
    yield proc.stop();
    if (postProcessCallback) yield call(postProcessCallback, data);
  } catch (error) {
    yield proc.fail(error);
  }
}
