import React, { useState } from "react";

import PropTypes from "prop-types";

import { useStyles } from "./styles";

Logo.propTypes = {
  logo: PropTypes.string,
  divider: PropTypes.bool
};
function Logo(props) {
  const { logo } = props;

  const cls = useStyles();

  const [loadFailed, setLoadFailed] = useState(false);

  if (loadFailed || !logo || logo === "-1") return null;

  return (
    <>
      <div className={cls.logoContainer}>
        <div className={cls.aspectRatio}>
          <img
            className={cls.image}
            src={logo}
            alt="appbar-logo"
            onError={e => {
              e.target.src = "";
              setLoadFailed(true);
            }}
          />
        </div>
      </div>
      <div id="divider" />
    </>
  );
}

AppBarLogos.propTypes = {
  logos: PropTypes.array,
  onClickLogo: PropTypes.func
};
export function AppBarLogos(props) {
  const { logos, onClickLogo } = props;

  const cls = useStyles();

  if (!logos?.length) return null;

  return (
    <div className={cls.root} onClick={onClickLogo}>
      {logos.map((logo, index) => (
        <Logo key={index} logo={logo} />
      ))}
    </div>
  );
}
