export const styles = theme => ({
  root: {
    padding: 7
  },
  lightTooltip: {
    backgroundColor: "white",
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11
  }
});
