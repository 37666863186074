import React, { useState, useCallback } from "react";

import { Grid, TextField } from "@material-ui/core";
import { DckActionCreators } from "dck-redux";
import _debounce from "lodash/debounce";
import PropTypes from "prop-types";
import * as V from "yup";

import { useDispatchActions } from "srx-shared";

import { Hardware } from "@items/types";
import { getAvoidDotsError } from "@utils";

import { useStyles } from "./styles";

V.addMethod(V.number, "avoidDots", function () {
  return this.transform(getAvoidDotsError);
});

const actions = {
  updateStorage: data => DckActionCreators.itemSave(Hardware, null, data)
};

TroubleshootingStorageConfig.propTypes = {
  storage: PropTypes.object
};
export function TroubleshootingStorageConfig(props) {
  const { storage } = props;
  const { pollPeriod, weightDelta } = storage.troubleshootingConfig;

  const cls = useStyles();

  const [values, setValues] = useState({
    pollPeriod: pollPeriod ?? 1800,
    weightDelta: weightDelta ?? 10
  });

  const { updateStorage } = useDispatchActions(actions);

  const debounceUpdateStorage = useCallback(_debounce(updateStorage, 1000), []);

  const handleChange = async (value, field) =>
    V.number()
      .avoidDots()
      .min(0)
      .max(Number.MAX_SAFE_INTEGER)
      .isValid(Number(value))
      .then(result => {
        if (result) {
          const nextValues = { ...values, [field]: Number(value) };
          setValues(nextValues);
          debounceUpdateStorage({
            ...storage,
            troubleshootingConfig: nextValues
          });
        }
      });

  const renderTextField = (field, label) => (
    <Grid item sm={6} xs={12}>
      <TextField
        label={label}
        value={values[field]}
        fullWidth
        InputLabelProps={{
          classes: { root: cls.label }
        }}
        onChange={e => handleChange(e.target.value, field)}
      />
    </Grid>
  );

  return (
    <Grid container spacing={2}>
      {renderTextField("pollPeriod", "Polling Period for Debug mode (sec)")}
      {renderTextField(
        "weightDelta",
        "Minimum weight difference to report WEIGHT_PROCESSING (g)"
      )}
    </Grid>
  );
}
