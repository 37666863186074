import { Button, Grid } from "@material-ui/core";
import PropTypes from "prop-types";

import { PATH_TO_ASSETS } from "config";

import { useStyles } from "./styles";

const ENV = process.env.REACT_APP_STAGE || "dev";

const URLS = {
  WEB_PORTAL:
    ENV === "prod"
      ? "https://app.storeroomlogix.com/checkout"
      : "https://app-bulk.storeroomlogix.com/checkout",
  GOOGLE_PLAY:
    "https://play.google.com/store/apps/details?id=com.storeroomlogix.checkout",
  APP_STORE: "https://apps.apple.com/us/app/srx-checkout/id1548270034"
};

LinksComponent.propTypes = {
  form: PropTypes.object
};

export function LinksComponent(props) {
  const cls = useStyles();

  if (!props.form.values.enableCheckoutSoftware) return null;

  const handleClick = url => window.open(url);

  return (
    <>
      <Grid item xs={12}>
        <Grid
          container
          spacing={1}
          alignItems="center"
          className={cls.sectionBoundary}
        >
          <Grid item style={{ display: "flex" }}>
            <img
              src="/assets/srx-checkout-app.png"
              alt="SRX Logo"
              className={cls.srxCheckoutImage}
            />
          </Grid>
          <Grid item xs className={cls.gridTextStyle}>
            Checkout Web Portal
          </Grid>
          <Grid item>
            <Button
              color="secondary"
              variant="contained"
              onClick={() => handleClick(URLS.WEB_PORTAL)}
            >
              Open
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          spacing={1}
          alignItems="center"
          className={cls.sectionBoundary}
        >
          <Grid item style={{ display: "flex" }}>
            <img
              src={`${PATH_TO_ASSETS}/srx-checkout-app.png`}
              alt="SRX Logo"
              className={cls.srxCheckoutImage}
            />
          </Grid>
          <Grid item xs className={cls.gridTextStyle}>
            Checkout Mobile App
          </Grid>
          <Grid item xs={12} sm="auto" />
          <Grid item>
            <img
              src={`${PATH_TO_ASSETS}/google-play-badge.png`}
              alt="Download from Play Store"
              className={cls.appAndPlayStoreImage}
              onClick={() => handleClick(URLS.GOOGLE_PLAY)}
            />
          </Grid>
          <Grid item>
            <img
              src={`${PATH_TO_ASSETS}/app-store-badge.png`}
              alt="Download from Apple Store"
              className={cls.appAndPlayStoreImage}
              onClick={() => handleClick(URLS.APP_STORE)}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
