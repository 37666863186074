import React from "react";

import { DckActionCreators } from "dck-redux";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
  tableFor,
  filtersFor,
  editModalFor,
  actionsFor,
  breadcrumbsFor
} from "srx-shared";

import { Distributor } from "@items/types";

import ConfirmModalForm from "./ConfirmModalForm";
import SmartShelfCreateForm from "./SmartShelfCreateForm";
import SmartShelfEditForm from "./SmartShelfEditForm";
import { SmartShelfItem } from "./SmartShelfItem";

const SmartShelvesTable = tableFor(SmartShelfItem);
const SmartShelfFilters = filtersFor(SmartShelfItem);
const SmartShelfActions = actionsFor(SmartShelfItem, {
  CreateForm: SmartShelfCreateForm
});
const SmartShelfEditModal = editModalFor(SmartShelfItem, {
  EditForm: SmartShelfEditForm
});
const BreadcrumbsComponent = breadcrumbsFor();

class SmartShelveComponent extends React.PureComponent {
  static propTypes = {
    loadDistributorsDict: PropTypes.func
  };

  componentDidMount() {
    this.props.loadDistributorsDict();
  }

  renderFilters = table => <SmartShelfFilters table={table} />;
  renderActions = table => <SmartShelfActions table={table} />;

  render() {
    return (
      <>
        <BreadcrumbsComponent
          renderList={() => [{ title: "Manage Smart Shelves" }]}
        />
        <SmartShelvesTable
          renderFilters={this.renderFilters}
          renderActions={this.renderActions}
        />
        <SmartShelfEditModal />
        <ConfirmModalForm />
      </>
    );
  }
}

const mapDispatchToProps = {
  loadDistributorsDict: () =>
    DckActionCreators.itemsLoad(Distributor, { dict: true })
};

export default connect(null, mapDispatchToProps)(SmartShelveComponent);
