import React from "react";

import cn from "classnames";
import PropTypes from "prop-types";

import { TbodyEditable } from "../TbodyEditable";

const TbodyDesktopComponent = props => {
  const { className, children, editableColumns, ...rest } = props;
  return (
    <div className={cn("rt-tbody", className)} {...rest}>
      {children}
    </div>
  );
};

TbodyDesktopComponent.propTypes = {
  className: PropTypes.string,
  editableColumns: PropTypes.any
};

TbodyDesktopComponent.displayName = "TbodyDesktop";

export const TbodyComponent = props => {
  const { children, editable, mobile, ...rest } = props;
  const className = cn(props.className, mobile && "rt-tbody-mobile");
  const Tbody = editable ? TbodyEditable : TbodyDesktopComponent;
  return (
    <Tbody {...rest} className={className}>
      {children}
    </Tbody>
  );
};

TbodyComponent.propTypes = {
  mobile: PropTypes.bool,
  editable: PropTypes.bool,
  className: PropTypes.string
};

TbodyComponent.displayName = "Tbody";
