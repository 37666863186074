/* eslint react/display-name: "off" */
/* eslint react/prop-types: "off" */
/* eslint no-template-curly-in-string: "off" */
import * as V from "yup";

import { mapFormFieldsFor } from "srx-shared";

import { Discounts } from "@items/types";
import { getAvoidDotsError } from "@utils";

V.addMethod(V.number, "avoidDots", function () {
  return this.transform(getAvoidDotsError);
});

export const DiscountsItem = {
  type: Discounts,
  name: "Discounts"
};

// form fields
DiscountsItem.fieldsConfig = {
  buttonCost: {
    label: "Button Cost",
    required: true,
    initialValue: "0",
    inputSuffix: "%",
    size: 6
  },
  yearlyFlatFee: {
    label: "Yearly Flat Fee Discount",
    required: true,
    initialValue: "0",
    inputSuffix: "%",
    size: 6
  },
  triggersMonthlyFee: {
    label: "Monthly Triggers Fee Discount",
    required: true,
    initialValue: "0",
    inputSuffix: "%",
    size: 6
  },
  camerasMonthlyFee: {
    label: "Monthly Cameras Fee Discount",
    required: true,
    initialValue: "0",
    inputSuffix: "%",
    size: 6
  },
  shipToMonthlyFee: {
    label: "Monthly ShipTo Fee Discount",
    required: true,
    initialValue: "0",
    inputSuffix: "%",
    size: 6
  }
};

DiscountsItem.schema = V.object({
  buttonCost: V.number()
    .label("Button Cost")
    .typeError("${label} must be a number")
    .required()
    .min(0)
    .max(100)
    .avoidDots(),
  yearlyFlatFee: V.number()
    .label("Yearly Flat Fee Discount")
    .typeError("${label} must be a number")
    .required()
    .min(0)
    .max(100)
    .avoidDots(),
  triggersMonthlyFee: V.number()
    .label("Monthly Triggers Fee Discount")
    .typeError("${label} must be a number")
    .required()
    .min(0)
    .max(100)
    .avoidDots(),
  camerasMonthlyFee: V.number()
    .label("Monthly Cameras Fee Discount")
    .typeError("${label} must be a number")
    .required()
    .min(0)
    .max(100)
    .avoidDots(),
  shipToMonthlyFee: V.number()
    .label("Monthly ShipTo Fee Discount")
    .typeError("${label} must be a number")
    .required()
    .min(0)
    .max(100)
    .avoidDots()
});

DiscountsItem.generalForm = mapFormFieldsFor(DiscountsItem, [
  "buttonCost",
  "yearlyFlatFee",
  "triggersMonthlyFee",
  "camerasMonthlyFee",
  "shipToMonthlyFee"
]);
