import { useState } from "react";

import { Grid, Tooltip } from "@material-ui/core";
import WarningIcon from "@material-ui/icons/WarningOutlined";
import * as PropTypes from "prop-types";
import { useSelector } from "react-redux";

import {
  ActionCreators,
  makeSequence,
  Selectors,
  useDispatchActions
} from "srx-shared";
import { SwitchField } from "srx-shared/mui";

import { getDoorColumnNumber, isLockerV3 } from "@comp/Hardware/helpers";
import { LockerConfig } from "@redux/items/types";

import { DoorConfig } from "./DoorConfig";
import { RefreshState } from "./RefreshState";
import { TroubleshootingDoor } from "./TroubleshootingDoor";
import { TroubleshootingLockerConfig } from "./TroubleshootingLockerConfig";
import { useStyles } from "./styles";

const actions = {
  setTrShootingMode: value =>
    ActionCreators.setItemData(LockerConfig, "trShootingMode", value)
};

Planogram.propTypes = {
  doors: PropTypes.object,
  locker: PropTypes.object
};

export function Planogram(props) {
  const { doors, locker } = props;
  const { id: lockerId, distributorId, lockerType, iotHub } = locker;

  const cls = useStyles();
  const { columnsQuantity } = lockerType || {};
  const { setTrShootingMode } = useDispatchActions(actions);

  const trShootingMode = useSelector(state =>
    Selectors.getItemData(state, LockerConfig, "trShootingMode")
  );

  const columnIds = makeSequence(columnsQuantity);
  const serialNumbers = [];

  const [selectedDoors, setSelectedDoors] = useState(new Set());

  Object.values(doors).forEach(door => {
    if (door.doorSerialNumber) {
      serialNumbers.push(door.doorSerialNumber);
    }
  });

  const trShootingAvailable = Boolean(iotHub);

  const trShooting = Boolean(trShootingAvailable && trShootingMode);

  const renderDoor = ({ number: id }) => {
    const Section = trShooting ? TroubleshootingDoor : DoorConfig;
    return (
      <Section
        key={`door-${id}`}
        lockerId={lockerId}
        distributorId={distributorId}
        door={doors[id]}
        doors={doors}
        doorSerialNumbers={serialNumbers}
        id={id}
        locker={locker}
        selectedDoors={selectedDoors}
        setSelectedDoors={setSelectedDoors}
      />
    );
  };

  const renderColumn = columnNumber => {
    const columnDoors = Object.values(doors).filter(
      door => getDoorColumnNumber(door, lockerType) === columnNumber
    );

    return (
      <div key={`column-${columnNumber}`} className={cls.columnContainer}>
        {columnDoors.map(renderDoor)}
      </div>
    );
  };

  const handleChangeMode = value => setTrShootingMode(value);

  const title = !iotHub ? "Locker is not assigned to IoTHub" : "";
  return (
    <>
      <Grid container style={{ marginBottom: 20 }}>
        <Grid item xl={4} lg={3} md={4} sm={12} style={{ display: "flex" }}>
          {!isLockerV3() && (
            <>
              <SwitchField
                value={trShooting}
                label="Troubleshooting view"
                disabled={!trShootingAvailable}
                noHelperText
                onChange={handleChangeMode}
              />
              {!trShootingAvailable && (
                <Tooltip title={title}>
                  <WarningIcon style={{ color: "orange" }} />
                </Tooltip>
              )}
            </>
          )}
        </Grid>
        <Grid item xl={4} md={6} xs={12}>
          {trShooting && <TroubleshootingLockerConfig locker={locker} />}
        </Grid>
        <Grid item style={{ flexGrow: 1 }}>
          <RefreshState locker={locker} />
        </Grid>
      </Grid>
      <Grid container justify="center">
        {columnIds.map(renderColumn)}
      </Grid>
    </>
  );
}
