import React, { useState } from "react";

import { Grid, Paper } from "@material-ui/core";
import ConfigureIcon from "@material-ui/icons/SettingsOutlined";
import cn from "classnames";
import { DckActionCreators, procLoading } from "dck-redux";
import * as PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { Formatters, useDispatchActions } from "srx-shared";
import { IconButton } from "srx-shared/mui";

import * as Types from "@items/types";

import { useStyles } from "../styles";
import { SectionConfigDialog } from "./SectionConfigDialog";
import { SectionState } from "./SectionState";

const actions = {
  loadAvailableDistributorShelves: section =>
    DckActionCreators.itemsLoad(Types.StorageSection, { section })
};

export function SectionConfig(props) {
  const { id, section, storageId, distributorId } = props;
  const cls = useStyles();
  const {
    doorContainer,
    doorPaper,
    selectedPaper,
    noWeightPaper,
    iconWrapper,
    clsLabel,
    clsText,
    clsNotAssigned,
    serialInput,
    serialContainer
  } = cls;

  const { loadAvailableDistributorShelves } = useDispatchActions(actions);

  const loading = useSelector(state => procLoading(state, Types.Storage));

  const { noWeight, smartShelfHardware } = section;

  const address = Formatters.hubAddress(smartShelfHardware?.address);

  const shelfSerialNumber = smartShelfHardware?.serialNumber;
  const shelfId = smartShelfHardware?.id;

  const [open, setOpen] = useState(false);

  const handleOpenDialog = () => {
    loadAvailableDistributorShelves(section);
    setOpen(true);
  };

  const handleCloseDialog = () => setOpen(false);

  const configProps = {
    id: section.id,
    number: section.number,
    storageId,
    distributorId,
    noWeight,
    address,
    shelfSerial: shelfId
  };

  return (
    <Paper
      className={cn(
        doorPaper,
        open && selectedPaper,
        noWeight && noWeightPaper
      )}
    >
      <Grid container className={cn(doorContainer)}>
        <SectionState id={id} section={section} />
        {!noWeight && !shelfSerialNumber ? (
          <Grid container item xs={10} alignItems="center" justify="center">
            <span className={clsNotAssigned}>
              There is no smart shelf assigned
            </span>
          </Grid>
        ) : (
          <>
            <Grid item xs={4} className={serialContainer}>
              {(noWeight || shelfSerialNumber) && (
                <div className={serialInput}>
                  <div className={clsLabel}>IoT Hub Address</div>
                  <div className={clsText}>
                    <span>{address}</span>
                  </div>
                </div>
              )}
            </Grid>
            <Grid item xs={6} className={serialContainer}>
              {!noWeight && (
                <div className={serialInput}>
                  <div className={clsLabel}>Smart Shelf Serial Number</div>
                  <div className={clsText}>{shelfSerialNumber}</div>
                </div>
              )}
            </Grid>
          </>
        )}
        <Grid item xs={1} className={iconWrapper}>
          <IconButton
            icon={<ConfigureIcon />}
            tip="Configure Section"
            onClick={handleOpenDialog}
            disabled={loading}
            data-testid="configure"
          />
        </Grid>
      </Grid>
      <SectionConfigDialog
        {...configProps}
        open={open}
        onClose={handleCloseDialog}
      />
    </Paper>
  );
}

SectionConfig.propTypes = {
  id: PropTypes.number,
  section: PropTypes.object,
  storageId: PropTypes.number,
  distributorId: PropTypes.number
};
