import React from "react";

import {
  Grid,
  DialogActions,
  DialogContent,
  Button,
  List,
  ListItem,
  ListItemText
} from "@material-ui/core";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { ActionCreators } from "../../actions";
import { getValidateStatus } from "../../actions/selectors";
import { ModalDialog } from "../ModalDialog";

class ValidateDialogComponent extends React.PureComponent {
  static propTypes = {
    itemType: PropTypes.string.isRequired,
    validateStatus: PropTypes.func,
    resetValidateStatus: PropTypes.func,
    resetLoadingIndicator: PropTypes.func,
    onCancel: PropTypes.func,
    onContinue: PropTypes.func
  };

  getValidateStatus = () => {
    const { validateStatus, itemType } = this.props;
    return (validateStatus && validateStatus(itemType)) || {};
  };

  handleCancelImport = () => {
    this.props.resetValidateStatus(this.props.itemType);
    this.props.resetLoadingIndicator();
    this.props.onCancel();
  };

  handleContinueImport = () => {
    const validateStatus = this.getValidateStatus();
    this.props.resetValidateStatus(this.props.itemType);
    this.props.onContinue(validateStatus);
  };

  noRecordsToImport = () => {
    const validateStatus = this.getValidateStatus();
    if (validateStatus.status === "Validated") return false;
    return Boolean(
      validateStatus.description &&
        (validateStatus.description.includes(
          "Validation finished. 0 records will import successfully"
        ) ||
          validateStatus.description.includes(
            "Validation finished with errors"
          ))
    );
  };

  render() {
    const validateStatus = this.getValidateStatus();
    return (
      <ModalDialog
        title={`Validation status: ${
          validateStatus.status === "Validated" ? "valid" : "errors"
        }`}
        size="sm"
        open={Boolean(validateStatus.status)}
        onClose={this.handleCancelImport}
      >
        <DialogContent style={{ paddingBottom: 12 }}>
          <Grid container spacing={1}>
            {validateStatus.description && (
              <Grid item xs={12}>
                {validateStatus.description}
              </Grid>
            )}
            {validateStatus.status === "ValidatedWithErrors" &&
              validateStatus.validationResult && (
                <Grid item xs={12}>
                  <List>
                    {validateStatus.validationResult.map((item, index) => (
                      <ListItem key={index} style={{ padding: "4px 16px" }}>
                        <ListItemText>{`${item.lineNumber} : ${item.errorMessage}`}</ListItemText>
                      </ListItem>
                    ))}
                  </List>
                </Grid>
              )}
          </Grid>
        </DialogContent>
        <DialogActions style={{ padding: "0 16px 16px 0" }}>
          <Button
            onClick={this.handleCancelImport}
            color="default"
            variant="contained"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={this.handleContinueImport}
            disabled={this.noRecordsToImport()}
          >
            Continue import
          </Button>
        </DialogActions>
      </ModalDialog>
    );
  }
}

const mapStateToProps = state => ({
  validateStatus: itemType => getValidateStatus(state, itemType)
});

const mapDispatchToProps = {
  resetLoadingIndicator: () => ActionCreators.resetLoadingIndicator(),
  resetValidateStatus: itemType =>
    ActionCreators.setValidateStatus(itemType, null)
};

export const ValidateImportDialog = connect(
  mapStateToProps,
  mapDispatchToProps
)(ValidateDialogComponent);
