/* eslint react/display-name: "off" */
/* eslint react/prop-types: "off" */
import React from "react";

import { EditButton, RemoveButton } from "srx-shared/mui";

export const Actions = ({ row, item }) => {
  const title = `${item.type.split("Type")[0]} Type`;
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
        margin: "3px 0"
      }}
    >
      <EditButton row={row} item={item} tip={title} />
      <RemoveButton
        row={row}
        item={item}
        tip={title}
        modalProps={{ size: "sm" }}
      >
        Are you sure you want to delete {title}:
        <br />
        <pre>
          Type Name: <b>{row.name}</b>
        </pre>
      </RemoveButton>
    </div>
  );
};
