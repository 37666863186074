import { Grid, FormControlLabel, Checkbox } from "@material-ui/core";
import PropTypes from "prop-types";

import { useStyles } from "./styles";

CustomCheckbox.propTypes = {
  field: PropTypes.object,
  fieldConfig: PropTypes.object
};

export function CustomCheckbox(props) {
  const { field, fieldConfig } = props;

  const cls = useStyles();

  return (
    <Grid item xs={fieldConfig.size || 6}>
      <FormControlLabel
        control={
          <Checkbox
            checked={field.value}
            classes={{ disabled: cls.checkbox }}
          />
        }
        label={fieldConfig.label}
        labelPlacement="end"
        classes={{ disabled: cls.disabled }}
        disabled
      />
    </Grid>
  );
}
