import { useRef, useEffect, useMemo, useState, useCallback } from "react";

import { useMediaQuery } from "@material-ui/core";
import produce from "immer";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";

import { ActionCreators } from "../actions";
import { getItemData } from "../actions/selectors";
import { ItemTypeApplication } from "../config";
import { Breadcrumbs } from "../mui";
import { setDocumentTitle } from "../mui/AppPageTitle/withPageTitle";
import {
  itemMakeActive,
  itemSelect,
  procRunning,
  procSuccess,
  itemSet
} from "../redux";

export { usePermissions } from "./usePermissions";
export { useDebounce } from "./useDebounce";
export { useTableParams } from "./useTableParams";
export { useCustomQuery } from "./useCustomQuery";
export { useCustomMutation } from "./useCustomMutation";

export function usePrevious(value, initValue) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current === undefined ? initValue : ref.current;
}

export function useOptItem(itemType, data) {
  const dispatch = useDispatch();
  return id => {
    if (data) {
      dispatch(itemSet(itemType, id, data));
    }
    dispatch(itemMakeActive(itemType, id));
  };
}

export function useSelectItem(itemType) {
  const dispatch = useDispatch();
  return id => dispatch(itemSelect(itemType, id));
}

export function usePageTitle(title, description, options = {}) {
  const { hidden } = options;

  const dispatch = useDispatch();

  const pageTitle = useSelector(state =>
    getItemData(state, ItemTypeApplication, "pageTitle")
  );

  useEffect(() => {
    const setPageTitle = title => dispatch(ActionCreators.setPageTitle(title));

    setPageTitle(hidden ? " " : title);
    setDocumentTitle(title, description);
    return () => {
      setPageTitle(null);
      setDocumentTitle(null);
    };
  }, [description, dispatch, hidden, pageTitle, title]);

  return null;
}

export function useMobile() {
  return useMediaQuery(theme => theme.breakpoints.down("sm"));
}

export function useDispatchActions(actionCreators) {
  const dispatch = useDispatch();
  return useMemo(
    () => bindActionCreators(actionCreators, dispatch),
    [actionCreators, dispatch]
  );
}

export function useSuccessProcessCallback(itemType, action, callback) {
  const success = useSelector(state => procSuccess(state, itemType, action));
  const processing = useSelector(state => procRunning(state, itemType, action));
  const prevProcessing = usePrevious(processing);

  const isSuccess = prevProcessing && !processing && success;

  useEffect(() => {
    isSuccess && callback();
  }, [isSuccess, callback]);

  return null;
}

export function usePersistentRef(any, rerender = false) {
  const [, setRenderCount] = useState(0);

  const ref = useRef(any);

  return useMemo(() => {
    const set = any => {
      if (any === ref.current) return;
      ref.current = any;
      rerender && setRenderCount(value => value + 1);
    };

    const get = () => ref.current;

    return { set, get };
  }, [rerender]);
}

export function useBreadcrumbs(items) {
  const dispatch = useDispatch();

  const setPageBar = useCallback(
    value =>
      dispatch(
        ActionCreators.setItemData(ItemTypeApplication, "pageBar", value)
      ),
    [dispatch]
  );

  useEffect(() => {
    setPageBar(<Breadcrumbs list={items} />);
    return () => setPageBar(null);
  }, [items, setPageBar]);
}

export function useImmer(initialValue) {
  const [value, setValue] = useState(initialValue);
  return [
    value,
    useCallback(updater => {
      if (typeof updater === "function") setValue(produce(updater));
      else setValue(updater);
    }, [])
  ];
}
