import * as React from "react";

import FormControl from "@material-ui/core/FormControl/FormControl";
import _isEmpty from "lodash/isEmpty";
import _isFunction from "lodash/isFunction";
import _isNil from "lodash/isNil";
import PropTypes from "prop-types";

import { SelectCreatable } from "../../Select/SelectCreatable";
import SelectHelperText from "../../Select/SelectHelperText";
import SelectLabel from "../../Select/SelectLabel";

export class SelectCreatableField extends React.Component {
  constructor(props) {
    super(props);

    const value = props.fieldProps.value;

    this.state = {
      hasInputFocus: false,
      values: _isNil(value) ? [] : value
    };
  }

  static propTypes = {
    fieldProps: PropTypes.object,
    FormHelperTextProps: PropTypes.object,
    id: PropTypes.any,
    InputLabelProps: PropTypes.object,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func
  };

  componentDidUpdate(prevProps) {
    if (prevProps.fieldProps.value !== this.props.fieldProps.value) {
      const value = this.props.fieldProps.value;
      this.setState({ values: _isNil(value) ? [] : value });
    }
  }

  render() {
    const {
      id,
      FormHelperTextProps,
      fieldProps: {
        SelectProps,
        disabled,
        error,
        fullWidth,
        helperText,
        label,
        InputLabelProps,
        ...other
      }
    } = this.props;

    const helperTextId = id && helperText ? `${id}-helper-text` : undefined;
    const shrink = this.hasInputFocus() || this.hasValue();
    const { hasInputFocus, values } = this.state;

    const isDisabled = disabled || (!!SelectProps && SelectProps.isDisabled);
    const selectPlaceholder = label ? "" : "";
    return (
      <FormControl
        aria-describedby={helperTextId}
        error={error}
        fullWidth={fullWidth}
        {...other}
      >
        <SelectLabel
          label={label}
          shrink={shrink}
          hasInputFocus={hasInputFocus}
          isDisabled={isDisabled}
        />
        <SelectCreatable
          value={values}
          placeholder={selectPlaceholder}
          selectProps={{
            ...SelectProps,
            isDisabled
          }}
          data-testid="select-dropdown"
          error={error}
          onChange={this.handleChange}
          onFocus={this.handleGotFocus}
          onBlur={this.handleLostFocus}
        />
        <SelectHelperText
          id={helperTextId}
          helperText={helperText}
          formHelperTextProps={FormHelperTextProps}
        />
      </FormControl>
    );
  }

  hasInputFocus() {
    return this.state.hasInputFocus === true;
  }

  hasValue() {
    return _isEmpty(this.state.values) === false;
  }

  handleChange = values => {
    this.setState({
      values
    });

    const { onChange } = this.props;
    if (_isFunction(onChange)) {
      onChange(values);
    }
  };

  handleGotFocus = event => {
    this.setState({
      hasInputFocus: true
    });

    const { onFocus } = this.props;

    if (_isFunction(onFocus)) {
      onFocus(event);
    }
  };

  handleLostFocus = event => {
    this.setState({
      hasInputFocus: false
    });

    const { onBlur } = this.props;

    if (_isFunction(onBlur)) {
      onBlur(event);
    }
  };
}
