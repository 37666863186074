import React from "react";

import { Button, DialogContent, DialogActions } from "@material-ui/core";
import PropTypes from "prop-types";

import { ModalDialog, ActionsSpinner } from "srx-shared/mui";

UnassignDialog.propTypes = {
  number: PropTypes.number,
  storage: PropTypes.bool,
  onClose: PropTypes.func,
  onUnassign: PropTypes.func,
  updating: PropTypes.bool
};

export function UnassignDialog(props) {
  const { onClose, number, storage, onUnassign, updating } = props;

  return (
    <ModalDialog
      open
      onClose={onClose}
      title={`Unassign ${storage ? "Section" : "Door"} ${number}`}
      size="xs"
    >
      <DialogContent style={{ color: "#707070" }}>
        {`Do you want to unassign this smartshelf from the ${
          storage ? "storage" : "locker"
        }? Please note,
        you will be redirected to the sections views`}
      </DialogContent>
      <DialogActions>
        <ActionsSpinner processing={updating} />
        <Button variant="contained" onClick={onClose}>
          Cancel
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="secondary"
          disabled={updating}
          onClick={onUnassign}
        >
          Unassign
        </Button>
      </DialogActions>
    </ModalDialog>
  );
}
