import { Tooltip } from "@material-ui/core";
import OkIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/ErrorOutlined";
import PropTypes from "prop-types";

SettingsStatusIcon.propTypes = {
  isError: PropTypes.bool,
  settingsErrors: PropTypes.array
};

export function SettingsStatusIcon(props) {
  const { isError, settingsErrors } = props;

  const message = !isError
    ? "All settings are correct"
    : `Invalid values, please check: ${settingsErrors.join(", ")}`;

  const icon = !isError ? (
    <OkIcon style={{ color: "green" }} />
  ) : (
    <ErrorIcon style={{ color: "brown" }} />
  );

  return (
    <Tooltip title={message} placement="bottom">
      {icon}
    </Tooltip>
  );
}
