import { takeLatest, all, call, put } from "redux-saga/effects";

import {
  Process,
  isActionLoad,
  isActionAdd,
  isActionUpdate,
  isActionDelete,
  isActionSelect,
  ItemTypeApplication
} from "srx-shared";

import { ActionTypes, ActionCreators } from "@actions";
import * as RestApi from "@api";
import { DISTRIBUTOR_TYPES } from "@comp/Distributors/DistributorItem";
import {
  Distributor,
  DistributorsWithStorages,
  BillingERP
} from "@items/types";

import { mockResponse } from "./mocks";
import { loadShiptoFeesSaga } from "./shiptoFeesSaga";

const getFilters = filters => {
  const { distributorType, ...nextFilters } = filters;
  if (!distributorType) return nextFilters;

  const distributorTypeValue = distributorType[0];
  if (distributorTypeValue === DISTRIBUTOR_TYPES.SRX) {
    nextFilters["virtual"] = ["false"];
  } else if (distributorTypeValue === DISTRIBUTOR_TYPES.NON_SRX) {
    nextFilters["virtual"] = ["true"];
  } else if (distributorTypeValue === DISTRIBUTOR_TYPES.CUSTOMER_OWNED) {
    nextFilters["customerOwned"] = ["true"];
  }
  return nextFilters;
};

function* loadDistributorsSaga(action) {
  if (action && action.filteringOptions && action.filteringOptions.dict) {
    yield call(loadDistributorsDictSaga);
    return;
  }
  const proc = new Process.Load(Distributor, RestApi.ListDistributors, {
    mockResponse
  });
  yield proc.start();
  try {
    const page = yield proc.page();
    const pageSize = yield proc.pageSize();
    const sorting = yield proc.sorting();
    let filters = yield proc.filters();
    filters = getFilters(filters);
    yield proc.callApi(page + 1, pageSize, filters, sorting);
    proc.data.entities.forEach(el => {
      if (!el.shipToFee) {
        el.shipToFee = { id: "" };
      }
    });

    yield proc.setEntities();
    yield proc.setTotalPages();
    yield proc.stop();
    yield call(loadShiptoFeesSaga);
  } catch (ex) {
    yield proc.fail(ex);
  }
}

export function* loadDistributorsDictSaga() {
  const proc = new Process.Load(Distributor, RestApi.GetDistributorsMinInfo);
  yield proc.start();
  try {
    yield proc.callApi();
    const data = (proc.data || []).map(el => ({
      name: el.name,
      label: el.name,
      value: el.id
    }));
    yield put(
      ActionCreators.setItemData(ItemTypeApplication, "distributors", data)
    );
    yield proc.stop();
  } catch (ex) {
    yield proc.fail(ex);
  }
}

function* loadDistributorsWithStorages() {
  const proc = new Process.Load(
    DistributorsWithStorages,
    RestApi.ListUsersWithStorages
  );
  yield proc.start();
  try {
    yield proc.callApi();
    const data = proc.data.map(user => ({ label: user.name, value: user.id }));
    yield proc.setItemData("data", data);
    yield proc.stop();
  } catch (ex) {
    yield proc.fail(ex);
  }
}

function* getDistributorSaga(action) {
  const proc = new Process.Load(Distributor, RestApi.GetDistributor);
  yield proc.start();
  try {
    yield proc.callApi(action.id);
    yield proc.set(proc.data, action.id);
    yield proc.makeActive(action.id);
    yield proc.stop();
  } catch (ex) {
    yield proc.fail(ex);
  }
}

function* addDistributorsSaga(action) {
  const proc = new Process.Add(Distributor, RestApi.AddDistributor);
  yield proc.start();
  try {
    const data = { ...action.data, status: "ACTIVE", billBy: "SHIP_TO" };
    yield proc.callApi(data);
    yield proc.stop();
    yield call(loadDistributorsSaga);
  } catch (ex) {
    yield proc.fail(ex);
  }
}

function* saveDistributorSaga(action) {
  const proc = new Process.Update(Distributor, RestApi.UpdateDistributor);
  yield proc.start();
  try {
    const data = { ...action.data, billBy: "SHIP_TO" };
    yield proc.callApi(data);
    yield proc.stop();
    yield call(loadDistributorsSaga);
  } catch (ex) {
    yield proc.fail(ex);
  }
}

function* deleteDistributorsSaga(action) {
  const proc = new Process.Delete(Distributor, RestApi.RemoveDistributor);
  yield proc.start();
  try {
    yield proc.callApi(action.id);
    yield proc.stop();
    yield call(loadDistributorsSaga);
  } catch (ex) {
    yield proc.fail(ex);
  }
}

function* setDistributorStatusSaga(action) {
  const proc = new Process(
    "SET_DISTRIBUTOR_STATUS",
    Distributor,
    RestApi.SetDistributorStatus
  );
  yield proc.start();
  try {
    const { id, status } = action;
    yield proc.callApi(id, status);
    yield proc.stop();
    yield call(loadDistributorsSaga);
  } catch (ex) {
    yield proc.fail(ex);
  }
}

function* sendInvoiceToErpSaga(action) {
  const proc = new Process.Update(BillingERP, RestApi.SendInvoiceToErp);
  yield proc.start();
  try {
    yield proc.callApi(action.id);
    yield proc.stop();
    yield call(getDistributorSaga, { id: action.id });
  } catch (ex) {
    yield proc.fail(ex);
  }
}

function* distributorsSaga() {
  yield all([
    takeLatest(isActionLoad(Distributor), loadDistributorsSaga),
    takeLatest(
      isActionLoad(DistributorsWithStorages),
      loadDistributorsWithStorages
    ),
    takeLatest(isActionSelect(Distributor), getDistributorSaga),
    takeLatest(isActionAdd(Distributor), addDistributorsSaga),
    takeLatest(isActionUpdate(Distributor), saveDistributorSaga),
    takeLatest(isActionDelete(Distributor), deleteDistributorsSaga),
    takeLatest(isActionUpdate(BillingERP), sendInvoiceToErpSaga),
    takeLatest(
      action => action.type === ActionTypes.SET_DISTRIBUTOR_STATUS,
      setDistributorStatusSaga
    )
  ]);
}

export default distributorsSaga;
