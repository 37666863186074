import { useRef, useEffect } from "react";

import { Grid, Button, DialogContent } from "@material-ui/core";
import { DckActionCreators, procUpdating, procSuccessUpdate } from "dck-redux";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { generalFormFor, useDispatchActions } from "srx-shared";
import { ActionsSpinner, ModalDialog } from "srx-shared/mui";

import { LockerDoor } from "@items/types";

import { DoorConfigItem } from "./DoorConfigItem";

const actions = {
  updateLockerDoor: (id, data) =>
    DckActionCreators.itemSave(LockerDoor, id, data)
};

const DoorConfigForm = generalFormFor(DoorConfigItem);

DoorConfigDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  id: PropTypes.number,
  number: PropTypes.number,
  lockerId: PropTypes.number,
  distributorId: PropTypes.number,
  noWeight: PropTypes.bool,
  doorSerial: PropTypes.string,
  shelfSerial: PropTypes.number,
  doorSerialNumbers: PropTypes.array,
  shelfSerialNumbers: PropTypes.array
};

export function DoorConfigDialog(props) {
  const {
    open,
    onClose,
    number,
    noWeight,
    doorSerial,
    shelfSerial,
    doorSerialNumbers,
    shelfSerialNumbers
  } = props;

  const updating = useSelector(state => procUpdating(state, LockerDoor));

  const updateSuccess = useSelector(state =>
    procSuccessUpdate(state, LockerDoor)
  );

  const { updateLockerDoor } = useDispatchActions(actions);

  const prevUpdateSuccess = useRef();
  useEffect(() => {
    if (!prevUpdateSuccess.current) {
      prevUpdateSuccess.current = updateSuccess;
    } else {
      onClose && onClose();
      prevUpdateSuccess.current = false;
    }
    return () => (prevUpdateSuccess.current = false);
  });

  const handleSubmit = data => {
    const { lockerId, distributorId, id, number } = props;
    const noWeight = data.noWeight === "true";
    const doorConfig = {
      id,
      number,
      noWeight,
      doorSerialNumber: data.doorSerial,
      smartShelfHardware: {
        id: data.shelfSerial && !noWeight ? data.shelfSerial : null
      },
      distributorId
    };
    updateLockerDoor && updateLockerDoor(lockerId, doorConfig);
  };

  const renderActions = (form, formikProps) => {
    const { dirty } = formikProps;
    const disabled = !dirty || updating;
    return (
      <Grid container justify="flex-end" style={{ padding: "32px 0 0 0" }}>
        <ActionsSpinner processing={updating} />
        <Button
          variant="contained"
          color="default"
          onClick={props.onClose}
          style={{ marginRight: 16 }}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="secondary"
          disabled={disabled}
        >
          Save
        </Button>
      </Grid>
    );
  };

  const initialData = {
    noWeight: noWeight ? "true" : "false",
    doorSerial: doorSerial || "",
    initialDoorSerial: doorSerial || "",
    shelfSerial: shelfSerial || null,
    doorSerialNumbers,
    shelfSerialNumbers
  };

  return (
    <ModalDialog
      open={open}
      onClose={onClose}
      title={`Configure Door #${number}`}
      size="xs"
    >
      <DialogContent>
        <DoorConfigForm
          initialData={initialData}
          renderActions={renderActions}
          onSubmit={handleSubmit}
        />
      </DialogContent>
    </ModalDialog>
  );
}
