import React from "react";

import {
  tableFor,
  editFormFor,
  editModalFor,
  breadcrumbsFor,
  filtersFor,
  createFormFor,
  actionsFor,
  withPageTitle
} from "srx-shared";

export const typesManagementPageFor = (
  item,
  page = {},
  options = { size: "sm" }
) => {
  const title = `${item.type.split("Type")[0]} Type`;
  const TypesManagementTable = tableFor(item);
  const TypesManagementFilters = filtersFor(item);
  const TypesManagementCreateForm = createFormFor(item, {
    title: `Create ${title}`,
    size: options.size
  });
  const TypesManagementEditForm = editFormFor(item);
  const TypesManagementEditModal = editModalFor(item, {
    EditForm: TypesManagementEditForm
  });
  const TypesManagementActions = actionsFor(item, {
    CreateForm: TypesManagementCreateForm
  });
  const Breadcrumbs = breadcrumbsFor();

  const TypesManagementPage = () => {
    const renderFilters = table => <TypesManagementFilters table={table} />;

    const renderActions = table => <TypesManagementActions table={table} />;

    return (
      <>
        <Breadcrumbs renderList={() => [{ title }]} />
        <TypesManagementTable
          renderFilters={renderFilters}
          renderActions={renderActions}
        />
        <TypesManagementEditModal size={options.size} />
      </>
    );
  };

  return withPageTitle(page.title)(TypesManagementPage);
};
