import React, { Component } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";

import { dispatchToPropsForApp } from "../../actions/helpers";

import { Breadcrumbs } from "./index";

export const breadcrumbsFor = item => {
  class BreadcrumpsWrapper extends Component {
    static propTypes = {
      reload: PropTypes.bool,
      ending: PropTypes.any,
      setPageBar: PropTypes.func,
      renderList: PropTypes.func
    };

    breadcrumbs = () => (
      <Breadcrumbs list={this.props.renderList()} ending={this.props.ending} />
    );

    setPageBar = breadcrumbs =>
      this.props.setPageBar && this.props.setPageBar(breadcrumbs);

    componentDidMount() {
      this.setPageBar(this.breadcrumbs());
    }

    componentDidUpdate(prevProps) {
      if (this.props.reload) {
        this.setPageBar(this.breadcrumbs());
      }
    }

    componentWillUnmount() {
      this.setPageBar(null);
    }

    render() {
      return null;
    }
  }

  return connect(null, dispatchToPropsForApp)(BreadcrumpsWrapper);
};
