export const styles = theme => ({
  actionsContainer: {
    display: "flex",
    flexGrow: 2,
    alignItems: "flex-end",
    justifyContent: "flex-end",
    minHeight: 48,
    marginTop: -12,
    marginBottom: 8,
    flexWrap: "wrap"
  }
});
