import { ItemTypeApplication } from "../config";

import { ActionTypes } from "./index";

const defaultAppState = {
  notifications: [],
  hideNotifications: false
};

export const Reducer = (state = defaultAppState, action) => {
  const itemField = action.field;
  const stateItemData =
    itemField && state[itemField] ? state[itemField] || {} : {};

  switch (action.type) {
    // item data
    case ActionTypes.SET_ITEM_DATA:
      return {
        ...state,
        [itemField]: {
          ...stateItemData,
          [action.itemType]: action.data
        }
      };

    // filters
    case ActionTypes.SET_SELECTED_FILTER:
      return {
        ...state,
        selectedFilters: {
          ...state.selectedFilters,
          [action.field]: action.selected
        }
      };

    // loading indicator
    case ActionTypes.SHOW_LOADING_INDICATOR:
      return {
        ...state,
        loadingIndicator: (state.loadingIndicator || 0) + 1
      };
    case ActionTypes.HIDE_LOADING_INDICATOR:
      return {
        ...state,
        loadingIndicator: Math.max(0, (state.loadingIndicator || 1) - 1)
      };
    case ActionTypes.RESET_LOADING_INDICATOR:
      return {
        ...state,
        loadingIndicator: 0
      };
    // modals
    case ActionTypes.SHOW_EDIT_MODAL:
      return {
        ...state,
        modal: {
          [action.itemType]: true
        }
      };
    case ActionTypes.HIDE_EDIT_MODAL:
      return {
        ...state,
        modal: {
          [action.itemType]: false
        }
      };
    case ActionTypes.RESET_EDIT_MODAL:
      return {
        ...state,
        modal: null
      };
    // notifications
    case ActionTypes.PUSH_NOTIFICATION:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            ...action.notification
          }
        ],
        hideNotifications: false
      };
    case ActionTypes.HIDE_NOTIFICATIONS:
      return {
        ...state,
        hideNotifications: true
      };
    case ActionTypes.REMOVE_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.filter(
          notification => notification.key !== action.key
        )
      };
    default:
      return state;
  }
};

export const displayReducer = (state = {}, action) => {
  const itemField = action.field;
  const stateItemData =
    itemField && state[itemField] ? state[itemField] || {} : {};

  switch (action.type) {
    // item data
    case ActionTypes.SET_DISPLAY_PARAM:
      return {
        ...state,
        [itemField]: {
          ...stateItemData,
          [action.itemType]: action.data
        }
      };

    default:
      return state;
  }
};

export const undoReducer = (state = {}, action) => {
  const itemType = action.itemType || ItemTypeApplication;
  const undoBuffer = state[itemType];
  if (!undoBuffer) return state;
  const newState = { ...state };

  switch (action.type) {
    case ActionTypes.UNDO_RESET: {
      undoBuffer.reset();
      return newState;
    }
    case ActionTypes.UNDO_PUSH: {
      undoBuffer.push(action.data);
      return newState;
    }
    case ActionTypes.UNDO_POP: {
      undoBuffer.pop();
      return newState;
    }
    default:
      return state;
  }
};
