import React from "react";

import { Grid, Typography } from "@material-ui/core";

export default (props: any): JSX.Element => {
  return (
    <Grid container justify="center">
      <Typography color="primary">{props.field.value}</Typography>
    </Grid>
  );
};
