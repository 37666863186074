/* eslint react/display-name: "off" */
/* eslint react/prop-types: "off" */
//import React from "react";
//import PropTypes from "prop-types";
import * as V from "yup";

import { mapFormFieldsFor, settingsFormFor } from "srx-shared";

import {
  SrxVmiOperationalCosts,
  DistributorInventory,
  SuggestedMinMaxFormula,
  OperationalCosts,
  TraditionalVmiOperationalCosts,
  SupplyForce
} from "@items/types";
import { getAvoidDotsError, checkPrecision } from "@utils";

export const CostSavingsItem = {};

V.addMethod(V.number, "checkPrecision", function () {
  return this.test(
    "testPrecision",
    "Number must have 8 digits after decimal point",
    value => checkPrecision(value, 8)
  );
});

V.addMethod(V.number, "avoidDots", function () {
  return this.transform(getAvoidDotsError);
});

const SrxVmiOperationalCostsItem = {
  type: SrxVmiOperationalCosts,
  fieldsConfig: {
    binCheckSeconds: {
      label: "Check a Bin",
      initialValue: "",
      inputSuffix: "seconds"
    },
    createReorderListSeconds: {
      label: "Create reorder list (per line)",
      initialValue: "",
      inputSuffix: "seconds"
    },
    createQuoteOrOrderMinutes: {
      label: "Create a quote or order",
      initialValue: "",
      inputSuffix: "minutes"
    },
    addLineToQuoteOrOrderSeconds: {
      label: "Add line to quote or order",
      initialValue: "",
      inputSuffix: "seconds"
    },
    deliveryTimePerOrderMinutes: {
      label: "Delivery time per order",
      initialValue: "",
      inputSuffix: "minutes"
    },
    putAwaySkuSeconds: {
      label: "Put Away SKU (per line)",
      initialValue: "",
      inputSuffix: "seconds"
    }
  },
  schema: V.object({
    binCheckSeconds: V.number()
      .typeError("Value must be an integer number")
      .label("Value")
      .integer()
      .avoidDots()
      .min(0)
      .max(9999999999)
      .required("Enter a number"),
    createReorderListSeconds: V.number()
      .typeError("Value must be an integer number")
      .label("Value")
      .integer()
      .avoidDots()
      .min(0)
      .max(9999999999)
      .required("Enter a number"),
    createQuoteOrOrderMinutes: V.number()
      .typeError("Value must be an integer number")
      .label("Value")
      .integer()
      .avoidDots()
      .min(0)
      .max(9999999999)
      .required("Enter a number"),
    addLineToQuoteOrOrderSeconds: V.number()
      .typeError("Value must be an integer number")
      .label("Value")
      .integer()
      .avoidDots()
      .min(0)
      .max(9999999999)
      .required("Enter a number"),
    deliveryTimePerOrderMinutes: V.number()
      .typeError("Value must be an integer number")
      .label("Value")
      .integer()
      .avoidDots()
      .min(0)
      .max(9999999999)
      .required("Enter a number"),
    putAwaySkuSeconds: V.number()
      .typeError("Value must be an integer number")
      .label("Value")
      .integer()
      .avoidDots()
      .min(0)
      .max(9999999999)
      .required("Enter a number")
  })
};

const DistributorInventoryItem = {
  type: DistributorInventory,
  fieldsConfig: {
    allSkuInventoryLevel: {
      label: "Default SKU Inventory level",
      initialValue: ""
    },
    allSkuLeadTimes: {
      label: "Default SKU Lead Time",
      initialValue: ""
    },
    allSkuStockStatus: {
      label: "Default SKU Stock Status",
      initialValue: "",
      type: "radio",
      options: [
        { label: "Stock", value: "true" },
        { label: "Non-stock", value: "false" }
      ]
    },
    customerSkuWhoPurchase: {
      label: "Default Number of Customers who purchase SKU",
      initialValue: ""
    }
  },
  schema: V.object({
    allSkuInventoryLevel: V.number()
      .typeError("Value must be an number")
      .label("Value")
      .min(0)
      .max(10000000)
      .required("Enter a number"),
    allSkuLeadTimes: V.number()
      .typeError("Value must be an integer number")
      .label("Value")
      .integer()
      .avoidDots()
      .min(0)
      .max(10000000)
      .required("Enter a number"),
    customerSkuWhoPurchase: V.number()
      .typeError("Value must be an integer number")
      .label("Value")
      .integer()
      .avoidDots()
      .min(0)
      .max(10000000)
      .required("Enter a number")
  })
};

const SuggestedMinMaxFormulaItem = {
  type: SuggestedMinMaxFormula,
  fieldsConfig: {
    averageGrossMarginPercent: {
      label: "Average Gross Margin",
      initialValue: "",
      inputSuffix: "%"
    },
    minDifferenceMinMaxPercent: {
      label: "Minimum Difference between Min & Max",
      initialValue: "",
      inputSuffix: "%"
    },
    safetyStockUsagePercent: {
      label: "Safety Stock Of Usage",
      initialValue: "",
      inputSuffix: "%"
    },
    daysCheckingBin: {
      label: "Number of Days Checking Bin Inventory",
      initialValue: "",
      inputSuffix: "per week"
    },
    dayPuttingAway: {
      label: "Number of Days Putting Away Inventory",
      initialValue: "",
      inputSuffix: "per week"
    }
  },
  schema: V.object({
    averageGrossMarginPercent: V.number()
      .typeError("Value must be an number")
      .label("Value")
      .min(0)
      .max(10000000)
      .required("Enter a number"),
    minDifferenceMinMaxPercent: V.number()
      .typeError("Value must be an number")
      .label("Value")
      .min(0)
      .max(10000000)
      .required("Enter a number"),
    safetyStockUsagePercent: V.number()
      .typeError("Value must be an number")
      .label("Value")
      .min(0)
      .max(10000000)
      .required("Enter a number"),
    daysCheckingBin: V.number()
      .typeError("Value must be an integer number")
      .label("Value")
      .integer()
      .avoidDots()
      .min(1)
      .max(7)
      .required("Enter a number"),
    dayPuttingAway: V.number()
      .typeError("Value must be an integer number")
      .label("Value")
      .integer()
      .avoidDots()
      .min(1)
      .max(7)
      .required("Enter a number")
  })
};

const OperationalCostsItem = {
  type: OperationalCosts,
  fieldsConfig: {
    insideSalesRate: {
      label: "CSR /Inside Sales Rate",
      initialValue: "",
      inputSuffix: "$ / hourly"
    },
    deliveryAndPutAwayRate: {
      label: "Delivery & Put Away Rate",
      initialValue: "",
      inputSuffix: "$ / hourly"
    },
    scanningAndMaterialOrderRate: {
      label: "Scanning & Material Order Rate",
      initialValue: "",
      inputSuffix: "$ / hourly"
    },
    carryingCostPercentage: {
      label: "Carrying Cost Percentage",
      initialValue: "",
      inputSuffix: "%"
    }
  },
  schema: V.object({
    insideSalesRate: V.number()
      .typeError("Value must be an number")
      .label("Value")
      .min(0)
      .max(10000000)
      .required("Enter a number"),
    deliveryAndPutAwayRate: V.number()
      .typeError("Value must be an number")
      .label("Value")
      .min(0)
      .max(10000000)
      .required("Enter a number"),
    scanningAndMaterialOrderRate: V.number()
      .typeError("Value must be an number")
      .label("Value")
      .min(0)
      .max(10000000)
      .required("Enter a number"),
    carryingCostPercentage: V.number()
      .typeError("Value must be a number")
      .label("Value")
      .moreThan(0)
      .max(10000000)
      .required("Enter a number")
      .checkPrecision()
  })
};

const TraditionalVmiOperationalCostsItem = {
  ...SrxVmiOperationalCostsItem,
  type: TraditionalVmiOperationalCosts
};

const SupplyForceItem = {
  ...OperationalCostsItem,
  type: SupplyForce
};

// sections
CostSavingsItem.sectionsConfig = {
  srxVmiOperationalCosts: {
    title: "SRX VMI Operational Costs",
    section: "srxVmiOperationalCosts",
    Form: settingsFormFor(
      mapFormFieldsFor(SrxVmiOperationalCostsItem, [
        "binCheckSeconds",
        "createReorderListSeconds",
        "createQuoteOrOrderMinutes",
        "addLineToQuoteOrOrderSeconds",
        "deliveryTimePerOrderMinutes",
        "putAwaySkuSeconds"
      ])
    )
  },
  traditionalVmiOperationalCosts: {
    title: "Traditional VMI Operational Costs",
    section: "traditionalVmiOperationalCosts",
    Form: settingsFormFor(
      mapFormFieldsFor(TraditionalVmiOperationalCostsItem, [
        "binCheckSeconds",
        "createReorderListSeconds",
        "createQuoteOrOrderMinutes",
        "addLineToQuoteOrOrderSeconds",
        "deliveryTimePerOrderMinutes",
        "putAwaySkuSeconds"
      ])
    )
  },
  suggestedMinMaxFormula: {
    title: "Suggested Min & Max Formula Defaults",
    section: "suggestedMinMaxFormula",
    Form: settingsFormFor(
      mapFormFieldsFor(SuggestedMinMaxFormulaItem, [
        "averageGrossMarginPercent",
        "minDifferenceMinMaxPercent",
        "safetyStockUsagePercent",
        "daysCheckingBin",
        "dayPuttingAway"
      ])
    )
  },
  distributorInventory: {
    title: "Distributor Inventory Defaults",
    section: "distributorInventory",
    Form: settingsFormFor(
      mapFormFieldsFor(DistributorInventoryItem, [
        "allSkuInventoryLevel",
        "allSkuLeadTimes",
        "allSkuStockStatus",
        "customerSkuWhoPurchase"
      ])
    )
  },
  operationalCosts: {
    title: "Operational Costs",
    section: "operationalCosts",
    Form: settingsFormFor(
      mapFormFieldsFor(OperationalCostsItem, [
        "insideSalesRate",
        "deliveryAndPutAwayRate",
        "scanningAndMaterialOrderRate",
        "carryingCostPercentage"
      ])
    )
  },
  supplyForce: {
    title: "Supply Force Defaults",
    section: "supplyForce",
    Form: settingsFormFor(
      mapFormFieldsFor(SupplyForceItem, [
        "insideSalesRate",
        "deliveryAndPutAwayRate",
        "scanningAndMaterialOrderRate",
        "carryingCostPercentage"
      ])
    )
  }
};

CostSavingsItem.sections = [
  "srxVmiOperationalCosts",
  "traditionalVmiOperationalCosts",
  "suggestedMinMaxFormula",
  "distributorInventory",
  "operationalCosts",
  "supplyForce"
];
