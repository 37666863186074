import _set from "lodash/set";
import { takeLatest, all, put } from "redux-saga/effects";

import { Sagas, deepMerge, Process, isActionLoad } from "srx-shared";

import { ActionTypes, ActionCreators } from "@actions";
import * as RestApi from "@api";
import { BaseFee } from "@items/types";

import { mockResponse } from "./mocks";

function* loadBaseFeesSaga() {
  const proc = new Process.Load(BaseFee, RestApi.GetBaseFees, { mockResponse });
  yield proc.start();
  try {
    yield proc.callApi();
    yield proc.setEntities();
    yield proc.stop();
  } catch (ex) {
    yield proc.fail(ex);
  }
}

function* updateBaseFeeSaga(action) {
  const proc = new Process(
    ActionTypes.INLINE_UPDATE,
    BaseFee,
    RestApi.SaveBaseFees
  );
  yield proc.start();
  try {
    const { data } = action;
    const row = deepMerge({}, data.row);
    const { id } = row;

    _set(row, data.selectedCell, Number(data.value));
    yield proc.callApi(id, row);
    yield Sagas.updateDckItem(BaseFee, id, row);
    yield put(
      ActionCreators.undoPush(BaseFee, {
        ...data,
        row: deepMerge({}, data.original)
      })
    );
    yield proc.stop();
  } catch (ex) {
    yield proc.fail(ex);
  }
}

// undo
export function* undoUpdateBaseFeeSaga(action) {
  const proc = new Process(
    ActionTypes.UNDO_ACTION,
    BaseFee,
    RestApi.SaveBaseFees
  );
  yield proc.start();
  try {
    const {
      data: { row }
    } = action;
    const { id } = row;

    yield proc.callApi(id, row);
    yield Sagas.updateDckItem(BaseFee, id, row);
    yield put(ActionCreators.undoPop(BaseFee));
    yield proc.stop();
  } catch (ex) {
    yield proc.fail(ex);
  }
}

function* baseFeesSaga() {
  yield all([
    takeLatest(isActionLoad(BaseFee), loadBaseFeesSaga),
    takeLatest(
      action =>
        action.type === ActionTypes.INLINE_UPDATE &&
        action.itemType === BaseFee,
      updateBaseFeeSaga
    ),
    takeLatest(
      action =>
        action.type === ActionTypes.UNDO_ACTION && action.itemType === BaseFee,
      undoUpdateBaseFeeSaga
    )
  ]);
}

export default baseFeesSaga;
