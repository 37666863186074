import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 100,
    height: theme.mixins.appBar.height + 1,
    display: "flex",
    paddingTop: 0,
    flexGrow: 2,
    justifyContent: "center"
  },
  tab: {
    minWidth: 50,
    padding: "0 16px"
  },
  selected: {
    fontWeight: "bold",
    background: "rgba(255,255,255,0.1)"
  },
  indicator: {
    height: theme.mixins.appBar.indicator.height,
    background: theme.palette.primary.contrastText
  }
}));
