/* eslint-disable react/prop-types */
import { Grid, Tooltip } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import * as V from "yup";

import { reListOfEmails, mapFormFieldsFor } from "srx-shared";
import { Message } from "srx-shared/mui";

import { ShipToTemplateSettings } from "@items/types";
import { getAvoidDotsError } from "@utils";
import { TIME_EVERY_OPTIONS, DAYS } from "config";

import { Timezone } from "../Common/Timezone";

V.addMethod(V.string, "listOfEmails", function () {
  return this.test(
    "listOfEmails",
    "Please enter a valid email or emails, separated by comma",
    value => (value ? reListOfEmails.test(value) : true)
  );
});

V.addMethod(V.number, "avoidDots", function () {
  return this.transform(getAvoidDotsError);
});

export const ORDER_STATUS_TIMING = [
  {
    name: "Daily",
    label: "Daily",
    value: "DAILY"
  },
  { name: "Weekly", label: "Weekly", value: "WEEKLY" }
];

const MAX_ROUND_BUY_VALUE = 5;
const DEFAULT_SYNC_MAX_OPTIONS = [...Array(MAX_ROUND_BUY_VALUE)].map(
  (_, index) => ({
    value: index + 1,
    label: `${index + 1}xRoundBuy`
  })
);
const DEFAULT_SYNC_MIN_OPTIONS = [
  { label: "0", value: 0 },
  ...DEFAULT_SYNC_MAX_OPTIONS.slice(0, MAX_ROUND_BUY_VALUE - 1)
];

const toggleLabels = {
  autoValidation: {
    label: "Enable Auto-Validation",
    tooltip:
      "Data received from ERP system will be automatically validated and all items that passed the validation will be added to the VMI list"
  },
  cribCrawlAutoSequenceLocation: {
    label: "Enable Auto-Sequence Location",
    tooltip:
      "Next location value will be incremented based on the last value in CribCrawl list"
  },
  autoDeletion: {
    label: "Enable Auto-Deletion",
    tooltip:
      "Items will be automatically deleted from the CribCrawl / VMI lists if they do not exist in ERP system anymore"
  }
};

const ToggleLabel = ({ name }) => (
  <span style={{ display: "flex" }}>
    {toggleLabels[name].label}
    <Tooltip title={toggleLabels[name].tooltip}>
      <InfoIcon style={{ marginLeft: "8px" }} />
    </Tooltip>
  </span>
);

export const FieldWrapper = props => {
  const { fieldConfig, children, form } = props;
  const { hidden, checkHidden = () => false } = fieldConfig;
  const isHidden = hidden || checkHidden(form);

  return !isHidden && children;
};

const SectionLabel = props => (
  <FieldWrapper {...props}>
    <Grid
      item
      xs={props.fieldConfig.size || 12}
      style={{
        paddingTop: "30px",
        paddingBottom: 0,
        color: "gray",
        fontSize: 13
      }}
    >
      {props.fieldConfig.label}
    </Grid>
  </FieldWrapper>
);

const ERPSourceMessage = props => (
  <FieldWrapper {...props}>
    <Grid item xs={12}>
      <Message variant="warning">
        ERP system is the source for data and all changes to VMI list can be
        made through ERP only
      </Message>
    </Grid>
  </FieldWrapper>
);

export const isErpApiDisabled = form => !form.values.useErpApi;

export const VMIListIntegrationSettingsItem = {
  type: ShipToTemplateSettings,
  fieldsConfig: {
    sourcesLabel: {
      label: "Set how items added to the VMI list",
      Component: SectionLabel
    },
    useErpApi: {
      label: "Use ERP API (Train)",
      type: "checkbox",
      size: 4,
      onChange: (value, form) =>
        form.setValues({
          ...form.values,
          useErpApi: value,
          manual: !value,
          importing: !value,
          min: 0,
          max: 1,
          emails: ""
        })
    },
    manual: {
      label: "Manual",
      type: "checkbox",
      size: 4,
      checkDisabled: () => true
    },
    importing: {
      label: "Import",
      type: "checkbox",
      size: 4,
      checkDisabled: () => true
    },
    erpSourceMessage: {
      Component: ERPSourceMessage,
      checkHidden: isErpApiDisabled
    },
    interval: {
      label: "Sync Recurrence",
      size: 4,
      type: "select",
      options: ORDER_STATUS_TIMING,
      checkHidden: isErpApiDisabled
    },
    time: {
      label: "Time",
      size: 4,
      type: "select",
      options: TIME_EVERY_OPTIONS,
      checkHidden: isErpApiDisabled
    },
    zoneId: {
      Component: props => (
        <FieldWrapper {...props}>
          <Timezone />
        </FieldWrapper>
      ),
      checkHidden: isErpApiDisabled
    },
    dayOfWeek: {
      label: "Week days",
      size: 12,
      type: "radio",
      initialValue: "MONDAY",
      options: DAYS,
      noHelperText: true,
      checkHidden: form =>
        isErpApiDisabled(form) || form.values.interval === "DAILY"
    },
    automationlabel: {
      label: "Set automated actions applied to VMI items",
      Component: SectionLabel,
      checkHidden: isErpApiDisabled
    },
    autoValidation: {
      label: <ToggleLabel name="autoValidation" />,
      type: "switch",
      size: 12,
      checkHidden: isErpApiDisabled
    },
    cribCrawlAutoSequenceLocation: {
      label: <ToggleLabel name="cribCrawlAutoSequenceLocation" />,
      type: "switch",
      size: 12,
      checkHidden: isErpApiDisabled
    },
    autoDeletion: {
      label: <ToggleLabel name="autoDeletion" />,
      type: "switch",
      size: 12,
      checkHidden: isErpApiDisabled
    },
    defaultValueLabel: {
      label:
        "Set the default values to apply at automatic or on-demand sync if data is missing in the ERP system",
      Component: SectionLabel,
      checkHidden: isErpApiDisabled
    },
    triggerType: {
      label: "Trigger Type",
      type: "select",
      size: 4,
      initialValue: "LABEL",
      options: [{ label: "LABEL", value: "LABEL" }],
      checkDisabled: () => true,
      checkHidden: isErpApiDisabled
    },
    min: {
      label: "Min",
      type: "select",
      size: 4,
      initialValue: 0,
      options: DEFAULT_SYNC_MIN_OPTIONS,
      checkHidden: isErpApiDisabled
    },
    max: {
      label: "Max",
      type: "select",
      size: 4,
      initialValue: 1,
      options: DEFAULT_SYNC_MAX_OPTIONS,
      checkHidden: isErpApiDisabled
    },
    emailsLabel: {
      label:
        "Specify emails to receive alerts related to CribCrawl / VMI lists",
      Component: SectionLabel,
      checkHidden: isErpApiDisabled
    },
    emails: {
      label: "Email to Receive Alerts",
      size: 12,
      checkHidden: isErpApiDisabled
    }
  },
  schema: V.object({
    min: V.number()
      .typeError("Min must be an integer number")
      .label("Min")
      .required()
      .lessThan(1000000)
      .moreThan(-1, "Min must be a positive number")
      .avoidDots()
      .test({
        name: "Comparison with Min",
        message: "Min must be less than Max",
        test: function (value) {
          return value < this.parent.max;
        }
      }),
    max: V.number()
      .typeError("Max must be an integer number")
      .label("Max")
      .required()
      .lessThan(1000000)
      .moreThan(0)
      .avoidDots()
      .nullable()
      .test({
        name: "Comparison with Min",
        message: "Max must be greater than Min",
        test: function (value) {
          return value > this.parent.min;
        }
      }),
    emails: V.string()
      .label("Email to Recieve Alerts")
      .max(255)
      .listOfEmails()
      .nullable()
  }),
  fields: [
    "sourcesLabel",
    "useErpApi",
    "manual",
    "importing",
    "erpSourceMessage",
    "interval",
    "time",
    "zoneId",
    "dayOfWeek",
    "automationlabel",
    "autoValidation",
    "cribCrawlAutoSequenceLocation",
    "autoDeletion",
    "defaultValueLabel",
    "triggerType",
    "min",
    "max",
    "emailsLabel",
    "emails"
  ]
};

VMIListIntegrationSettingsItem.formConfig = mapFormFieldsFor(
  VMIListIntegrationSettingsItem
);
