import React from "react";

import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/icons/HistoryRounded";
import classNames from "classnames";
import PropTypes from "prop-types";

import { ColoredButton } from "../ColoredButton";
import { styles } from "./styles";

const textMessage = status => {
  switch (status) {
    case "Trial":
      return "days left";
    case "Active":
      return "days till renewal";
    default:
      return "days";
  }
};

const getButtonType = status => {
  switch (status) {
    case "Trial":
      return "blue";
    case "Active":
      return "green";
    case "Pending":
      return "amber";
    case "Blocked":
      return "red";
    case "Deleted":
    default:
      return "grey";
  }
};

const AccountStatusComponent = ({
  status,
  billingLeftDays,
  label = "",
  withText = false,
  className,
  classes
}) => (
  <div className={classNames(classes.container, className && className)}>
    <span className={classes.statusText}>{label}</span>
    <ColoredButton text={status} type={getButtonType(status)} />
    {billingLeftDays ? (
      <>
        <Icon className={classes.statusIcon} />
        <span className={classes.statusText}>{` ${billingLeftDays}${
          withText ? ` ${textMessage(status)}` : " d"
        }`}</span>
      </>
    ) : null}
  </div>
);

AccountStatusComponent.propTypes = {
  status: PropTypes.string,
  label: PropTypes.string,
  billingLeftDays: PropTypes.number,
  withText: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string
};

export const AccountStatus = withStyles(styles)(AccountStatusComponent);
