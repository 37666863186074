import * as V from "yup";

import { getTimes12, mapFormFieldsFor } from "srx-shared";

import { ShipToTemplateSettings } from "@items/types";
import { DAYS } from "config";

import { Timezone } from "../Common/Timezone";

const TIME_EVERY_OPTIONS = [...getTimes12("AM"), ...getTimes12("PM")];

export const RfidAutoTransitSettingsItem = {
  type: ShipToTemplateSettings,
  fieldsConfig: {
    enabled: {
      label:
        "Automatically change status from CHECK_IN, RETURN_CHECK_IN to AVAILABLE",
      size: 12,
      type: "checkbox",
      initialValue: false,
      noHelperText: true
    },
    freqDays: {
      label: "Week days",
      size: 12,
      type: "checkGroup",
      options: DAYS,
      initialValue: ["MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY"]
    },
    time: {
      label: "Time",
      size: 4,
      type: "select",
      options: TIME_EVERY_OPTIONS,
      initialValue: "19:00"
    },
    timezone: {
      Component: () => <Timezone title="Ship-to timezone" />,
      size: 4
    }
  },
  schema: V.object({
    freqDays: V.array()
      .typeError("Please select week day(s)")
      .label("Week days")
      .required()
      .nullable()
  }),
  fields: ["enabled", "freqDays", "time", "timezone"]
};

RfidAutoTransitSettingsItem.formConfig = mapFormFieldsFor(
  RfidAutoTransitSettingsItem
);
