import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
  paper: {
    padding: 24,
    width: "100%",
    maxWidth: 800,
    whiteSpace: "noWrap",
    [theme.breakpoints.down("xs")]: {
      padding: "16px 16px 8px"
    }
  },
  title: {
    color: "#707070",
    fontSize: 13
  },
  confSection: {
    padding: "24px 12px",
    backgroundColor: "#F7F7F7"
  },
  justifyEnd: {
    display: "flex",
    justifyContent: "flex-end"
  },
  doorConfig: {
    padding: "12px 16px",
    backgroundColor: "#F7F7F7",
    whiteSpace: "normal",
    minWidth: 200
  },
  number: {
    width: 24,
    height: 24,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 12,
    borderRadius: "50%",
    marginRight: 12,
    background: ({ colors }) => colors?.background,
    color: ({ colors }) => colors?.color
  },
  openDoor: {
    color: ({ open }) => (open ? "#4CAF50" : "#F44336"),
    backgroundColor: ({ open }) => (open ? "#E4F3E5" : "#FEE3E1"),
    borderRadius: 2,
    fontSize: 16,
    fontWeight: "bold",
    display: "flex",
    padding: "3px 8px",
    justifyContent: "center"
  },
  iconWrapper: {
    display: "flex",
    alignItems: "center",
    padding: "0 8px 0 4px"
  },
  root: {
    display: "flex",
    flexDirection: "column",
    margin: -16,
    height: "calc(100% + 32px)",
    width: "calc(100% + 32px)"
  },
  container: {
    flexGrow: 1,
    minHeight: 100,
    overflow: "auto",
    position: "relative",
    display: "flex",
    justifyContent: "center"
  },
  form: {
    maxWidth: 800,
    padding: "16px 0",
    position: "absolute"
  },
  footer: {
    height: 76,
    backgroundColor: "#FFFFFF",
    boxShadow: "0 -2px 6px 0 rgba(0,0,0,0.3)",
    display: "flex",
    justifyContent: "center",
    zIndex: 2
  },
  actions: {
    maxWidth: 800,
    width: "100%"
  }
}));
