import PropTypes from "prop-types";

import { Formatters } from "srx-shared";

import { useStyles } from "./styles";

TroubleshootingStatusInfo.propTypes = {
  data: PropTypes.object
};

export function TroubleshootingStatusInfo({ data }) {
  const cls = useStyles();

  if (!data) return null;

  const { status, updatedBy, updatedAt } = data;

  const renderRow = (label, value) => (
    <div className={cls.row}>
      {label}: <span className={cls.value}>{value}</span>
    </div>
  );

  return (
    <div className={cls.root}>
      {Formatters.VendingTroubleshootingStatus(status)}
      {renderRow("User", updatedBy || "N/A")}
      {renderRow("Last Updated", Formatters.datetime(updatedAt))}
    </div>
  );
}
