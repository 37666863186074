import { red, grey } from "@material-ui/core/colors";

const green = "#54b257";
const blue = "#2f99c6";
const amber = "#f19a3f";
const orange = "#EF8A20";
const greenBackGround = "#e4f3e5";
const blueBackGround = "#dfeff6";
const amberBackGround = "#fdeede";
const orangeBackground = "#ffefdd";

export const styles = theme => ({
  coloredButton: {
    margin: "5px",
    padding: "2px 10px",
    fontWeight: 500
  },
  greenButton: {
    color: green,
    backgroundColor: greenBackGround,
    "&:hover": {
      color: green,
      backgroundColor: greenBackGround
    },
    "&:disabled": {
      color: green,
      backgroundColor: greenBackGround
    }
  },
  amberButton: {
    color: amber,
    backgroundColor: amberBackGround,
    "&:hover": {
      color: amber,
      backgroundColor: amberBackGround
    },
    "&:disabled": {
      color: amber,
      backgroundColor: amberBackGround
    }
  },
  blueButton: {
    color: blue,
    backgroundColor: blueBackGround,
    "&:hover": {
      color: blue,
      backgroundColor: blueBackGround
    },
    "&:disabled": {
      color: blue,
      backgroundColor: blueBackGround
    }
  },
  redButton: {
    color: red[600],
    backgroundColor: red[100],
    "&:hover": {
      color: red[600],
      backgroundColor: red[100]
    },
    "&:disabled": {
      color: red[600],
      backgroundColor: red[100]
    }
  },
  greyButton: {
    color: grey[600],
    backgroundColor: grey[100],
    "&:hover": {
      color: grey[600],
      backgroundColor: grey[100]
    },
    "&:disabled": {
      color: grey[600],
      backgroundColor: grey[100]
    }
  },
  orangeButton: {
    color: orange,
    backgroundColor: orangeBackground,
    "&:hover": {
      color: orange,
      backgroundColor: orangeBackground
    },
    "&:disabled": {
      color: orange,
      backgroundColor: orangeBackground
    }
  }
});
