import React from "react";

import Button from "@material-ui/core/Button";
import withStyles from "@material-ui/core/styles/withStyles";
import classNames from "classnames";
import PropTypes from "prop-types";

import { styles } from "./styles";

const ColoredButtonComponent = ({
  size = "medium",
  type,
  text,
  classes,
  className
}) => (
  <Button
    data-testid="status-button"
    variant="contained"
    size={size}
    disabled={true}
    className={classNames(
      classes.coloredButton,
      classes[`${type.toLowerCase()}Button`],
      className && className
    )}
  >
    {text}
  </Button>
);

ColoredButtonComponent.propTypes = {
  type: PropTypes.string,
  text: PropTypes.string,
  classes: PropTypes.object.isRequired,
  size: PropTypes.string,
  className: PropTypes.string
};

export const ColoredButton = withStyles(styles)(ColoredButtonComponent);
