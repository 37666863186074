import React from "react";

import { makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";

const useStyles = makeStyles(() => ({
  clsPageContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%"
  },
  clsPageContent: {
    display: "flex",
    width: "100%",
    flexGrow: 1,
    overflowY: "hidden",
    minHeight: 400
  }
}));

PageContainer.propTypes = {
  renderHeader: PropTypes.func
};

export function PageContainer(props) {
  const { renderHeader, children } = props;
  const { clsPageContainer, clsPageContent } = useStyles();

  return (
    <div className={clsPageContainer}>
      {renderHeader && renderHeader()}
      <div className={clsPageContent}>{children}</div>
    </div>
  );
}
