/* eslint react/prop-types: "off" */
import { withWidth } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import ConfigureIcon from "@material-ui/icons/Settings";
import PlanogramIcon from "@material-ui/icons/Storage";
import { DckActionCreators } from "dck-redux";

import { isMobile } from "srx-shared";
import {
  IconButton,
  LinkIconButton,
  EditButton,
  RemoveButton
} from "srx-shared/mui";

import { store } from "@redux/store";
import { pages } from "@routes/pages";
import { DEVICE_TYPE } from "config";

import { HardwareItem } from "./HardwareItem";
import { HardwareContext } from "./context";

const path = (type, id) =>
  `${pages.hardware.path}/${(type || "").toLowerCase()}/${id}`;

const isNotEditable = item => item.type === "CAMERA";

const itemMakeActive = id =>
  store.dispatch(DckActionCreators.itemMakeActive(HardwareItem.type, id));

const isPlanogram = row =>
  (((row.type === "LOCKER" && row.iotHub) || row.type === "STORAGE") &&
    row.lockerType) ||
  (row.type === "VENDING" && row.hardwareVersion !== "v2" && row.iotHub);

const Actions = ({ row, width }) => (
  <HardwareContext.Consumer>
    {context => {
      const handleClick = (type, id) => {
        itemMakeActive(id);
        context.showDialog(type, true);
      };
      const deviceType = DEVICE_TYPE[row.type];

      return (
        <div
          style={{
            display: "flex",
            justifyContent: isMobile(width) ? "center" : "flex-end",
            width: "100%",
            margin: "3px 8px"
          }}
        >
          {row.type === "IOTHUB" && (
            <IconButton
              icon={<InfoIcon />}
              tip="Info"
              onClick={() => handleClick(DEVICE_TYPE.IOTHUB, row.id)}
              data-testid="info"
            />
          )}
          {row.type === "CAMERA" && row.iotHub && (
            <IconButton
              icon={<ConfigureIcon />}
              tip="Configure"
              onClick={() => handleClick(DEVICE_TYPE.CAMERA, row.id)}
              data-testid="configure"
            />
          )}
          {isPlanogram(row) && (
            <LinkIconButton
              tip={`Go to ${row.type} Planogram`}
              to={path(row.type, row.id)}
              icon={<PlanogramIcon />}
              data-testid="planogram"
            />
          )}
          <EditButton
            row={row}
            item={HardwareItem}
            tip={deviceType}
            disabled={isNotEditable(row)}
          />
          <RemoveButton
            row={row}
            item={HardwareItem}
            tip={deviceType}
            modalProps={{ size: "sm" }}
          >
            Are you sure you want to delete {deviceType}:
            <br />
            <pre>
              <b>{row.value}</b>
            </pre>
          </RemoveButton>
        </div>
      );
    }}
  </HardwareContext.Consumer>
);

export default withWidth()(Actions);
