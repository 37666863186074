import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  table: {
    fontSize: 13,
    color: "#333333",
    "& td, th": {
      border: "1px solid rgba(224, 224, 224, 1)"
    }
  },
  tableHead: {
    fontWeight: "bold",
    color: "#707070"
  },
  errorLabel: {
    fontSize: 12,
    color: "red"
  }
}));
