import React, { useState, useEffect } from "react";

import { Grid } from "@material-ui/core";
import { DckActionCreators, procUpdating } from "dck-redux";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import {
  breadcrumbsFor,
  Selectors,
  useDispatchActions,
  usePageTitle
} from "srx-shared";

import {
  Storage,
  StorageConfig,
  SmartShelfDetails,
  StorageSection
} from "@items/types";
import { pages } from "@routes/pages";

import { ActionsFooter } from "./ActionsFooter";
import { SmartShelfInfo } from "./SmartShelfInfo";
import { useStyles } from "./styles";

const BreadcrumbsComponent = breadcrumbsFor();

const { itemsLoad, itemSelect, itemSave } = DckActionCreators;

const actions = {
  loadStorage: storageId => itemsLoad(Storage, { storageId }),
  loadStorageConfig: storageId => itemsLoad(StorageConfig, { storageId }),
  loadSmartShelfDetails: id => itemSelect(SmartShelfDetails, id),
  updateStorageSection: (id, data) => itemSave(StorageSection, id, data)
};

export default function SectionConfig() {
  const { sectionId, storageId } = useParams();

  const deviceLink = `${pages.hardware.path}/storage/${storageId}`;

  const title = `Section ${sectionId}`;

  const { push } = useHistory();

  const cls = useStyles();

  usePageTitle(title);

  const {
    loadStorage,
    loadStorageConfig,
    loadSmartShelfDetails,
    updateStorageSection
  } = useDispatchActions(actions);

  const device = useSelector(state =>
    Selectors.selectActiveItem(state, Storage)
  );
  const deviceConfig = useSelector(state =>
    Selectors.selectActiveItem(state, StorageConfig)
  );
  const updating = useSelector(state => procUpdating(state, StorageSection));

  const [values, setValues] = useState({});

  const section = deviceConfig?.data.find(
    el => el.number === Number(sectionId)
  );

  useEffect(() => {
    loadStorage(storageId);
    loadStorageConfig(storageId);
  }, [storageId, loadStorage, loadStorageConfig]);

  useEffect(() => {
    if (section) {
      const { smartShelfHardware, weightPolling, debugMode } = section;
      const smartShelfId = smartShelfHardware?.id;
      smartShelfId && loadSmartShelfDetails(smartShelfId);
      setValues({ weightPolling, debugMode });
    }
  }, [section, loadSmartShelfDetails]);

  const handleCancel = () => push(deviceLink);

  const handleSave = () =>
    updateStorageSection(device.id, {
      ...section,
      ...values
    });

  const handleUnassign = () => {
    const sectionConfig = { ...section, smartShelfHardware: { id: null } };
    updateStorageSection(device.id, sectionConfig);
    push(deviceLink);
  };

  const breadcrumbsList = () => [
    { title: pages.hardware.title, to: pages.hardware.path },
    { title: "Planogram" },
    {
      title: device.value || "",
      to: deviceLink
    },
    { title },
    { title: "Section Settings" }
  ];

  if (!device || !section?.smartShelfHardware) return null;

  return (
    <div className={cls.root}>
      <div className={cls.container}>
        <div className={cls.form}>
          <Grid
            container
            justify="center"
            spacing={2}
            direction="column"
            alignItems="center"
          >
            <BreadcrumbsComponent renderList={breadcrumbsList} />
            <SmartShelfInfo
              section={section}
              values={values}
              setValues={setValues}
              address={section.smartShelfHardware.address}
              onUnassign={handleUnassign}
              updating={updating}
              storage
            />
          </Grid>
        </div>
      </div>
      <ActionsFooter
        updating={updating}
        onSave={handleSave}
        onCancel={handleCancel}
      />
    </div>
  );
}
