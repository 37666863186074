import PropTypes from "prop-types";

import { ItemTypeApplication } from "../config";
import { dictionaries } from "../dictionaries";
import { DckActionTypes } from "../redux";
import { getItemData } from "./selectors";

import { ActionCreators } from "./index";

/*
helpers for take* sagas effects

without helper:
  takeEvery(
    action =>
      action.type === DckActionTypes.ITEMS_LOAD &&
      action.itemType === ItemType,
    loadItemsSaga
  )

with helper:
  takeEvery(isActionLoad(ItemType), loadItemsSaga)
*/

const checkActionType = actionType => itemType => action =>
  action.type === actionType && action.itemType === itemType;

const checkActionTypes = actionTypes => itemType => action =>
  actionTypes.includes(action.type) && action.itemType === itemType;

export const isActionLoad = checkActionType(DckActionTypes.ITEMS_LOAD);
export const isActionAdd = checkActionType(DckActionTypes.ITEM_ADD);
export const isActionUpdate = checkActionTypes([
  DckActionTypes.ITEM_SAVE,
  DckActionTypes.ITEMS_SAVE
]);
export const isActionDelete = checkActionTypes([
  DckActionTypes.ITEMS_REMOVE,
  DckActionTypes.ITEM_REMOVE
]);
export const isActionImport = checkActionType(DckActionTypes.ITEMS_IMPORT);
export const isActionSelect = checkActionTypes([
  DckActionTypes.ITEM_SELECT,
  DckActionTypes.ITEMS_SELECT
]);
export const isActionActive = checkActionType(DckActionTypes.ITEM_MAKE_ACTIVE);

export const getPropTypesForDict = () =>
  dictionaries.reduce(
    (props, dict) => ({ ...props, [dict]: PropTypes.array }),
    {}
  );

export const stateToPropsForDict = state =>
  dictionaries.reduce(
    (props, dict) => ({
      ...props,
      [dict]: getItemData(state, ItemTypeApplication, dict)
    }),
    {}
  );

export const stateToPropsForApp = state => ({
  pageTitle: getItemData(state, ItemTypeApplication, "pageTitle"),
  appPageTabs: getItemData(state, ItemTypeApplication, "pageTabs"),
  appCurrentTab: getItemData(state, ItemTypeApplication, "currentTab"),
  pageBar: getItemData(state, ItemTypeApplication, "pageBar")
});

export const dispatchToPropsForApp = {
  setPageTitle: title => ActionCreators.setPageTitle(title),
  setAppPageTabs: tabs =>
    ActionCreators.setItemData(ItemTypeApplication, "pageTabs", tabs),
  setAppCurrentTab: tab =>
    ActionCreators.setItemData(ItemTypeApplication, "currentTab", tab),
  setPageBar: component =>
    ActionCreators.setItemData(ItemTypeApplication, "pageBar", component)
};
