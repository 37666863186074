import { takeLatest, all } from "redux-saga/effects";

import { isActionAdd, Process } from "srx-shared";

import * as RestApi from "@api";
import { AttachPolicy } from "@items/types";

function* attachDevicePolicySaga(action) {
  const proc = new Process.Add(AttachPolicy, RestApi.AttachPolicy);
  yield proc.start();
  try {
    yield proc.callApi(action.data);
    yield proc.stop();
  } catch (ex) {
    yield proc.fail(ex);
  }
}

function* attachPolicySaga() {
  yield all([takeLatest(isActionAdd(AttachPolicy), attachDevicePolicySaga)]);
}
export default attachPolicySaga;
