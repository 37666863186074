import React, { useContext } from "react";

import { useTheme, withStyles } from "@material-ui/core/styles";
import cn from "classnames";
import PropTypes from "prop-types";

import { styles } from "./styles";

export const CELL_WIDTH = 64;
export const CELL_NO_WEIGHTS_WIDTH = 25;
export const CELL_HEIGHT = 64;
export const CELL_MARGIN = 3;

export const calcCellWidth = (size, noWeight = false) => {
  const width = noWeight ? CELL_NO_WEIGHTS_WIDTH : CELL_WIDTH;
  return Number(size) > 1 ? size * width + (size - 1) * CELL_MARGIN : width;
};
const styleLockerCell = {
  width: CELL_WIDTH,
  height: CELL_HEIGHT,
  marginLeft: CELL_MARGIN
};

const SmartShelfCellComponent = props => {
  const context = useContext(props.context);
  const { classes, cell } = props;
  const { lockerCellContainer, cellIdWrapper, cellId, emptyCellId } = classes;

  const { palette } = useTheme();

  const isSelected = () => {
    const { selectedCells } = context;
    const { location } = props;
    const selected = selectedCells.has(location);
    return selected;
  };

  const handleClick = e => {
    const { selectedDoor, selectedCells, setSelectedDoor, setSelectedCells } =
      context;
    const { door, location } = props;

    e.stopPropagation();

    if (isSelected()) {
      selectedCells.delete(location);
      if (selectedCells.size === 0) setSelectedDoor(0);
    } else {
      if (selectedDoor !== door) {
        selectedCells.clear();
        setSelectedDoor(door);
      }
      selectedCells.add(location);
    }
    setSelectedCells(selectedCells);
  };

  const getCellLabel = cell => {
    if (!cell.indexes || !cell.indexes.length) return "";
    if (cell.indexes.length > 1) {
      const sorted = cell.indexes.sort();
      return `${sorted[0]} - ${sorted[sorted.length - 1]}`;
    } else {
      return cell.indexes[0];
    }
  };

  const border = isSelected()
    ? `1px solid ${palette.primary.main}`
    : "1px dashed #ddd";

  const cellSize = cell.size || cell.indexes ? cell.indexes.length : 1;
  const width = calcCellWidth(cellSize, cell.noWeight);
  const cellLabel = getCellLabel(cell);

  return (
    <div
      className={lockerCellContainer}
      style={{
        ...styleLockerCell,
        border,
        width
      }}
      onClick={handleClick}
      data-cell={props.location}
      data-cell-no-weights={cell.noWeight}
    >
      +
      <div className={cellIdWrapper}>
        <div className={cn(cellId, emptyCellId)}>{cellLabel}</div>
      </div>
    </div>
  );
};
SmartShelfCellComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  door: PropTypes.number,
  cell: PropTypes.object,
  location: PropTypes.number,
  context: PropTypes.object
};
export const SmartShelfCell = withStyles(styles)(SmartShelfCellComponent);
