import { Button, Grid, TextField } from "@material-ui/core";
import CachedIcon from "@material-ui/icons/Cached";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";
import { DckActionCreators } from "dck-redux";
import PropTypes from "prop-types";

import { Icons, useDispatchActions } from "srx-shared";
import { DipSwitch, ExpansionPanel, SwitchField } from "srx-shared/mui";

import { SmartShelfDetails } from "@redux/items/types";

import { SmartShelfConfig } from "./SmartShelfConfig";
import { UnassignButton } from "./UnassignButton";
import { useStyles } from "./styles";

const actions = {
  loadSmartShelfDetails: id =>
    DckActionCreators.itemSelect(SmartShelfDetails, id),
  pollWeightSmartShelf: id => DckActionCreators.itemSave(SmartShelfDetails, id)
};

SmartShelfInfo.propTypes = {
  values: PropTypes.object,
  setValues: PropTypes.func,
  section: PropTypes.object,
  address: PropTypes.number,
  onUnassign: PropTypes.func,
  storage: PropTypes.bool,
  updating: PropTypes.bool
};

export function SmartShelfInfo(props) {
  const { values, setValues, section, address, onUnassign, storage, updating } =
    props;
  const { id: smartShelfId, serialNumber } = section.smartShelfHardware;

  const cls = useStyles();

  const { loadSmartShelfDetails, pollWeightSmartShelf } =
    useDispatchActions(actions);

  const handleWeightPolling = value =>
    setValues({ weightPolling: value, debugMode: false });

  const handleDebugMode = value => setValues({ ...values, debugMode: value });

  const handleRefresh = () => loadSmartShelfDetails(smartShelfId);

  const handlePollWeight = () => pollWeightSmartShelf(smartShelfId);

  return (
    <Grid item xs={12}>
      <ExpansionPanel defaultExpanded title="Smart Shelf Info">
        <Grid
          container
          spacing={2}
          alignItems="center"
          style={{ padding: "0 24px 24px" }}
        >
          <Grid item xs={12} sm={9}>
            <TextField
              value={serialNumber ?? ""}
              label="Smart Shelf Serial Number"
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={3} className={cls.justifyEnd}>
            <UnassignButton
              number={section.number}
              onUnassign={onUnassign}
              updating={updating}
              storage={storage}
            />
          </Grid>
          <Grid item xs sm={4} md={3}>
            <SwitchField
              value={values.weightPolling ?? true}
              label="Weight board"
              noHelperText
              onChange={handleWeightPolling}
            />
          </Grid>
          <Grid item xs sm={8} md={9}>
            <SwitchField
              value={values.debugMode ?? false}
              label="Debug mode"
              noHelperText
              disabled={!values.weightPolling}
              onChange={handleDebugMode}
            />
          </Grid>
          <Grid item xs={12} sm={6} className={cls.title}>
            Smart Shelf Configuration
          </Grid>
          <Grid item xs={12} sm={6} className={cls.justifyEnd}>
            <Button
              variant="text"
              color="primary"
              startIcon={<CachedIcon />}
              onClick={handleRefresh}
            >
              Refresh
            </Button>
            <Button
              variant="text"
              color="primary"
              startIcon={<Icons.Weight />}
              onClick={handlePollWeight}
            >
              Poll weight
            </Button>
          </Grid>
          <Grid item xs={12}>
            <div style={{ overflow: "auto" }}>
              <SmartShelfConfig />
            </div>
          </Grid>

          <Grid item xs sm={6} className={cls.title}>
            Dip Switch Configuration
          </Grid>
          <Grid item xs sm={6} className={cls.justifyEnd}>
            <Button variant="text" startIcon={<SwapHorizIcon />} disabled>
              Test Connection
            </Button>
          </Grid>
          <Grid item xs={12}>
            <div className={cls.confSection}>
              <DipSwitch
                noWeight="false"
                shelfSerial={serialNumber}
                shelfId={serialNumber}
                value={address}
                style={{ backgroundColor: "white" }}
              />
            </div>
          </Grid>
        </Grid>
      </ExpansionPanel>
    </Grid>
  );
}
