export const styles = theme => ({
  appbar: {
    display: "flex",
    flexGrow: 1,
    overflow: "auto"
  },
  mobileAppBar: {
    display: "flex",
    overflow: "auto",
    paddingRight: 16
  }
});
