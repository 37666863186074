import React from "react";

import withWidth from "@material-ui/core/withWidth";
import cn from "classnames";
import { fromJS } from "immutable";
import _debounce from "lodash/debounce";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";

import { ActionCreators } from "../../actions";
import * as Selectors from "../../actions/selectors";
import { DEFAULT_PAGE_SIZE, MAX_PAGE_SIZE, NO_DATA_TEXT } from "../../config";
import { DckActionCreators, procLoading } from "../../redux";
import { fillMap, isMobile } from "../../utils";
import Expander from "./Expander";
import ContextButton from "./Header/ContextButton";
import { HeaderContextMenu } from "./Header/ContextMenu";
import Pagination from "./Pagination";
import { SelectionInfo } from "./SelectionInfo";
import { Selector } from "./SelectionInfo/Selector";
import { TbodyComponent } from "./TbodyComponent";
import { TdComponent } from "./TdComponent";
import { ThComponent } from "./ThComponent";
import { TrComponent } from "./TrComponent";

import { Table } from "./index";

const DEBOUNCE_DELAY = 3000;

const getDefaultSortedByData = columns => {
  const sortedBy = {};
  columns
    .filter(({ accessor, id }) => accessor || id)
    .forEach(column => {
      const { multiSort, sortingOptions } = column;
      const id = column.id || column.accessor;
      sortedBy[id] = multiSort ? sortingOptions[0].value : id;
    });
  return sortedBy;
};

export const tableFor = (
  item,
  // default options
  {
    params = [],
    className = "-highlight",
    sortable = true,
    pageble = true,
    mobileView = true,
    layouts = false,
    selectable = false,
    expandAll = false,
    SubComponent = null,
    noDataText = ""
  } = {}
) => {
  class Wrapper extends React.Component {
    static propTypes = {
      renderFilters: PropTypes.func,
      renderActions: PropTypes.func,
      columnsFilter: PropTypes.func,
      HeaderContextMenuComponent: PropTypes.any,
      readOnly: PropTypes.bool,
      selectAll: PropTypes.bool,
      stickyFirstWidth: PropTypes.number,
      stickyLastWidth: PropTypes.number,
      stickyLastFloat: PropTypes.bool,
      stickyLastDisableForScreenSizes: PropTypes.arrayOf(PropTypes.string),
      //redux state props
      data: PropTypes.array,
      sorting: PropTypes.array,
      loading: PropTypes.bool,
      totalEntities: PropTypes.number,
      totalPages: PropTypes.number,
      currentPage: PropTypes.number,
      pageSize: PropTypes.number,
      selected: PropTypes.any,
      allSelected: PropTypes.bool,
      hasNext: PropTypes.bool,
      width: PropTypes.string,
      currentLayout: PropTypes.object,
      layoutsList: PropTypes.array,
      //redux dispatch props
      undoReset: PropTypes.func,
      loadItems: PropTypes.func,
      setTotalPages: PropTypes.func,
      setCurrentPage: PropTypes.func,
      setPageSize: PropTypes.func,
      setSorting: PropTypes.func,
      setSelectedItems: PropTypes.func,
      setAllSelected: PropTypes.func,
      setCurrentLayout: PropTypes.func,
      setItemLayouts: PropTypes.func,
      setRef: PropTypes.func
    };

    static defaultProps = {
      stickyFirstWidth: 0, // default: disabled
      stickyLastWidth: 0, // default: disabled
      stickyLastFloat: false,
      stickyLastDisableForScreenSizes: ["xl"], // default: disabled sticky last on 'xl' screens
      renderFilters: () => {},
      renderActions: () => {},
      HeaderContextMenuComponent: HeaderContextMenu,
      selectAll: true
    };

    state = {
      loaded: false,
      resized: [],
      showHeaderContextMenu: false,
      anchorHeaderContextMenu: null,
      hiddenColumns: {},
      expandedAll: false,
      pagesSelected: [],
      expanded: {},
      sorted: [],
      sortedBy: getDefaultSortedByData(item.columns)
    };

    columnWithExpanders = {
      expander: true,
      width: 30,
      Header: () => (
        <Expander
          expanded={this.state.expandedAll}
          onClick={this.handleExpandAll}
        />
      )
    };

    columnWithSelectors = {
      width: 35,
      filterable: false,
      sortable: false,
      resizable: false,
      fixed: true,

      Header: "",
      Cell: ({ row }) => (
        <Selector
          selected={this.isItemSelected(row._original.id)}
          onChange={() => this.handleSelectItem(row._original.id)}
        />
      )
    };

    // Header selector

    hasSelected = () => {
      const { data, selected } = this.props;
      const hasSelected =
        data && selected && data.length > 0 && selected.size > 0;
      return hasSelected;
    };

    isPageSelected = () => {
      const { data, selected } = this.props;
      return this.hasSelected() && selected.size === data.length;
    };

    isPagePartialSelected = () => {
      const { data, selected } = this.props;
      return this.hasSelected() && selected.size < data.length;
    };

    handleSelectAllPage = checked =>
      this.isPageSelected() ? this.clearSelection() : this.selectAllPageItems();

    clearSelection = () => {
      const { setSelectedItems, setAllSelected } = this.props;
      setSelectedItems(new Set());
      setAllSelected(false);
    };

    selectAllPageItems = () => {
      const {
        data,
        setSelectedItems,
        totalPages,
        currentPage,
        hasNext,
        setAllSelected,
        selectAll
      } = this.props;
      const ids = (data || []).map(el => el.id);
      setSelectedItems(new Set(ids));
      if (
        (totalPages === 1 && selectAll) ||
        (totalPages === -1 && !hasNext && !currentPage && selectAll)
      )
        setAllSelected(true);
    };

    selectAll = () => {
      const { setAllSelected } = this.props;
      this.selectAllPageItems();
      setAllSelected(true);
    };

    // Item selector

    isItemSelected = id => {
      const { selected } = this.props;
      return Boolean(selected) && selected.has(id);
    };

    handleSelectItem = id => {
      const { selected, setSelectedItems, setAllSelected } = this.props;
      if (!selected) return;

      const cloned = new Set(selected);
      if (this.isItemSelected(id)) {
        cloned.delete(id);
        setAllSelected(false);
      } else {
        cloned.add(id);
      }
      setSelectedItems(cloned);
    };

    // Layouts

    isColumnVisible = column =>
      this.props.currentLayout.hiddenColumns &&
      this.props.currentLayout.hiddenColumns.indexOf(column.accessor) === -1;

    makeColumnFixed = column => ({
      ...column,
      className: cn(column.className, !this.mobile() && "rthfc-td-fixed"),
      headerClassName: cn(
        column.headerClassName,
        !this.mobile() && "rthfc-th-fixed"
      )
    });

    columns = () => {
      let columns = [...item.columns];
      if (SubComponent) columns.unshift(this.columnWithExpanders);
      if (selectable) {
        columns.unshift(this.columnWithSelectors);
        columns = columns.map(column =>
          column.fixed ? this.makeColumnFixed(column) : column
        );
      }
      if (layouts) {
        columns = columns.filter(this.isColumnVisible);
      }

      if (this.props.columnsFilter) {
        columns = columns.filter(this.props.columnsFilter);
      }

      const allowStickyLast =
        !layouts &&
        !this.props.stickyLastDisableForScreenSizes.includes(this.props.width);

      if (!layouts && !SubComponent && this.props.stickyFirstWidth) {
        const firstIndex = selectable ? 1 : 0;
        columns[firstIndex] = {
          ...columns[firstIndex],
          width: this.props.stickyFirstWidth,
          mandatory: true,
          resizable: false
        };
      }

      if (!columns[columns.length - 1]) {
        return columns;
      }

      if (
        !allowStickyLast &&
        !columns[columns.length - 1].width &&
        this.props.stickyLastWidth
      ) {
        columns[columns.length - 1].width = this.props.stickyLastWidth;
      }

      if (allowStickyLast && this.props.stickyLastWidth) {
        columns[columns.length - 1] = {
          ...columns[columns.length - 1],
          width: this.props.stickyLastWidth,
          resizable: false,
          sortable: false
        };
      }

      if (allowStickyLast && this.props.stickyLastFloat) {
        columns[columns.length - 1] = {
          ...columns[columns.length - 1],
          Header: "",
          width: 40,
          resizable: false,
          sortable: false
        };
      }

      return columns;
    };

    editableColumns = () =>
      this.columns()
        .filter(column => column.inline)
        .map(column => column.accessor);

    devWarnings = () => {
      if (layouts && this.props.stickyFirstWidth > 0) {
        console.warn(
          `Table for ${item.name}: can not use sticky first column with layouts`
        );
      }
      if (layouts && this.props.stickyLastWidth > 0) {
        console.warn(
          `Table for ${item.name}: can not use sticky last column with layouts`
        );
      }
      if (SubComponent && this.props.stickyFirstWidth > 0) {
        console.warn(
          `Table for ${item.name}: can not use sticky first column with expandable rows`
        );
      }
      if (this.props.stickyLastFloat && !this.props.stickyLastWidth) {
        console.warn(
          `Table for ${item.name}: need to set stickyLastWidth when using stickyLastFloat`
        );
      }
    };

    componentDidMount() {
      if (process.env.NODE_ENV === "development") {
        this.devWarnings();
      }

      if (expandAll) {
        this.setState({ expandedAll: true });
        this.handleExpandAll(true);
      }
      if (layouts) {
        this.setDefaultLayouts();
      }
      if (this.props.sorting.length) {
        const sortedBy = { ...this.state.sortedBy };
        this.props.sorting.forEach(
          ({ name, column }) => (sortedBy[column] = name)
        );
        const sorted = this.props.sorting.map(el => ({
          id: el.column,
          desc: el.order === "desc"
        }));
        this.setState({ ...this.state, sorted, sortedBy });
      }
      document.addEventListener("keydown", this.reloadTableByKeyCombination);
      this.props.setRef && this.props.setRef(this);
    }

    setDefaultLayouts = () => {
      const { layoutsList, currentLayout } = this.props;
      if (layoutsList?.length && currentLayout) {
        this.handleResizedChange(currentLayout.resized);
      } else {
        this.props.setItemLayouts(item.layouts);
        this.props.setCurrentLayout(item.layouts[0]);
      }
    };

    componentDidUpdate(prevProps) {
      if (prevProps.loading && !this.props.loading)
        this.setState({ loaded: true });

      if (params.length === 0) return false;

      const reload = params.some(
        param => prevProps[param] !== this.props[param]
      );
      if (reload) this.reload();
    }

    handleExpandedChange = expanded => this.setState({ expanded });

    handleExpandAll = isExpanded => {
      this.handleExpandedChange(isExpanded ? fillMap(MAX_PAGE_SIZE, true) : {});
      this.setState({ expandedAll: isExpanded });
    };

    handlePageChange = page => {
      if (expandAll) this.handleExpandAll(this.state.expandedAll);
      if (selectable) this.clearSelection();
      this.props.setCurrentPage(page);
    };

    handlePageSizeChange = (pageSize, page) => {
      this.props.setPageSize(pageSize);
      this.props.setCurrentPage(page);
      this.clearSelection();
    };

    reloadTableByKeyCombination = e => {
      if (e.keyCode === 32 && e.ctrlKey && e.shiftKey) this.loadItems();
    };

    reload = () => this.loadItems({ page: 0 });

    loadItems = ({ page = null } = {}) => {
      const { setCurrentPage, loadItems, undoReset } = this.props;

      if (selectable) this.clearSelection();

      undoReset && undoReset();

      if (page !== null && setCurrentPage) setCurrentPage(page);

      // prevent reactTable's double fetch with controlled component
      loadItems &&
        setTimeout(
          function () {
            if (!this.props.loading) loadItems();
          }.bind(this),
          10
        );
    };

    //implicit params are required
    handleFetchData = (state, inst) => this.loadItems();

    setTableSorting = sorted => {
      this.setState({ sorted });
      const sorting = sorted.map(field => ({
        name: this.state.sortedBy[field.id],
        order: field.desc ? "desc" : "asc",
        column: field.id
      }));
      this.props.setSorting(sorting);
    };

    setSortedBy = (columnId, sortedField) => {
      const sortedBy = { ...this.state.sortedBy };
      if (this.state.sortedBy[columnId] === sortedField) return;
      sortedBy[columnId] = sortedField;
      this.setState({ sortedBy }, () => {
        const isSorted = this.state.sorted.find(el => el.id === columnId);
        isSorted && this.setTableSorting([...this.state.sorted]);
      });
    };

    handleResizedChange = resized => {
      const { stickyFirstWidth } = this.props;

      if (stickyFirstWidth > 0) {
        // fix resized value of first sticky column
        const columns = this.columns();
        const firstIndex = selectable ? 1 : 0;
        const firstColumn = columns[firstIndex];
        if (firstColumn) {
          const firstColumnId =
            columns[firstIndex].accessor || columns[firstIndex].id;
          const firstResized = resized.find(el => el.id === firstColumnId);
          if (firstResized) {
            firstResized.value = stickyFirstWidth;
          }
        }
      }

      this.setState({ resized });
      if (layouts) {
        this.debounceChange("resized", resized);
      }
    };

    updateLayoutParams = (param, value) => {
      const currentLayout = this.props.currentLayout;
      currentLayout[param] = value;
      this.updateLayouts(currentLayout);
    };

    debounceChange = _debounce(this.updateLayoutParams, DEBOUNCE_DELAY);
    cancelDebounce = () => this.debounceChange.cancel();

    updateLayouts = currentLayout => {
      let { layoutsList } = this.props;
      layoutsList = layoutsList.map(layout =>
        layout.name === currentLayout.name ? currentLayout : layout
      );
      this.props.setCurrentLayout(currentLayout);
      this.props.setItemLayouts(layoutsList);
    };

    handleContextButtonClick = e =>
      this.setState({
        showHeaderContextMenu: true,
        anchorHeaderContextMenu: e.currentTarget
      });

    TheadComponent = ({
      children,
      className,
      width,
      stickyFirstWidth,
      stickyLastWidth,
      stickyLastFloat,
      stickyLastDisableForScreenSizes,
      columns,
      ...rest
    }) => {
      const { HeaderContextMenuComponent } = this.props;

      const firstIndex = selectable ? 1 : 0;
      const firstColumn = columns[firstIndex] || {};
      const firstColumnId = firstColumn.accessor || firstColumn.id;

      const isFirstSortable =
        sortable && ("sortable" in firstColumn ? firstColumn.sortable : true);

      const currentSorted = [...this.state.sorted];
      let firstSorting = currentSorted.find(el => el.id === firstColumnId);

      const allowStickyFirst =
        !layouts && !SubComponent && stickyFirstWidth > 0;

      const lastColumn = columns[columns.length - 1];
      const allowStickyLast =
        !layouts &&
        lastColumn &&
        stickyLastWidth > 0 &&
        !stickyLastDisableForScreenSizes.includes(width);

      return (
        <>
          <div
            style={{
              position: "sticky",
              left: 0,
              top: 0,
              height: 0,
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              zIndex: 3
            }}
          >
            {selectable && (
              <div className="th-sticky-select">
                <Selector
                  header
                  selected={this.isPageSelected()}
                  partial={this.isPagePartialSelected()}
                  onChange={() => this.handleSelectAllPage()}
                />
              </div>
            )}
            {allowStickyFirst && (
              <div
                className={cn(
                  "th-sticky-first",
                  isFirstSortable && "th-cursor-pointer",
                  firstSorting &&
                    (firstSorting.desc ? "th-sort-desc" : "th-sort-asc")
                )}
                style={{
                  width: stickyFirstWidth,
                  left: selectable ? 35 : 0
                }}
                onClick={e => {
                  if (!isFirstSortable) return;

                  const currentSorting = {
                    id: firstColumnId,
                    desc: firstSorting ? !firstSorting.desc : false
                  };

                  if (e.shiftKey) {
                    const firstSortingIndex = currentSorted.findIndex(
                      el => el.id === firstColumnId
                    );
                    if (firstSortingIndex === -1) {
                      currentSorted.push(currentSorting);
                    } else {
                      currentSorted[firstSortingIndex] = currentSorting;
                    }
                    this.setTableSorting(currentSorted);
                  } else {
                    this.setTableSorting([currentSorting]);
                  }
                }}
              >
                <div className="rt-resizable-header-content">
                  {firstColumn.Header || ""}
                </div>
              </div>
            )}
            {layouts && (
              <>
                <ContextButton
                  onClick={this.handleContextButtonClick}
                  disabled={this.state.showHeaderContextMenu}
                />
                <HeaderContextMenuComponent
                  {...this.props}
                  item={item}
                  table={this}
                  applySorting={this.applyLayoutSorting}
                  updateLayouts={this.updateLayouts}
                />
              </>
            )}
            {allowStickyLast && (
              <div
                className="th-sticky-last"
                style={{ width: stickyLastFloat ? 40 : stickyLastWidth }}
              >
                {lastColumn.Header}
              </div>
            )}
          </div>
          <div className={cn("rt-thead", className)} {...rest}>
            {children}
          </div>
        </>
      );
    };

    MobileTheadComponent = () => null;

    mobile = () => mobileView && isMobile(this);

    getPaginationProps = () => ({
      hasNext: this.props.hasNext
    });

    getTdProps = () => ({
      selectable,
      width: this.props.width,
      stickyFirstWidth: SubComponent ? 0 : this.props.stickyFirstWidth,
      stickyLastWidth: this.props.stickyLastWidth,
      stickyLastFloat: this.props.stickyLastFloat,
      stickyLastDisableForScreenSizes:
        this.props.stickyLastDisableForScreenSizes,
      loading: this.props.loading,
      mobile: this.mobile(),
      columns: this.columns(),
      sorting: this.props.sorting,
      readOnly: this.props.readOnly
    });

    getTheadProps = () => ({
      width: this.props.width,
      stickyFirstWidth: SubComponent ? 0 : this.props.stickyFirstWidth,
      stickyLastWidth: this.props.stickyLastWidth,
      stickyLastFloat: this.props.stickyLastFloat,
      stickyLastDisableForScreenSizes:
        this.props.stickyLastDisableForScreenSizes,
      columns: this.columns()
    });

    getTrProps = (finalState, rowInfo) => ({
      index: rowInfo.index,
      mobile: this.mobile(),
      selected: this.isItemSelected(rowInfo.original.id)
    });

    getTbodyProps = finalState => {
      const { readOnly } = this.props;
      const editableColumns = this.editableColumns();
      return {
        item,
        mobile: this.mobile(),
        rows: (finalState.data || []).length,
        editable: !readOnly && editableColumns.length > 0,
        editableColumns
      };
    };

    getTheadThProps = (finalState, rowInfo, column) => ({
      columnProps: {
        id: column.id,
        multiSort: column.multiSort,
        sortingOptions: column.sortingOptions,
        sortedBy: this.state.sortedBy[column.id],
        setSortedBy: this.setSortedBy
      }
    });

    componentWillUnmount() {
      this.props.setCurrentPage(0);
      this.clearSelection();
      this.cancelDebounce();
      document.removeEventListener("keydown", this.reloadTableByKeyCombination);
    }

    render() {
      const TheadComponent = this.mobile()
        ? this.MobileTheadComponent
        : this.TheadComponent;

      const { selected, totalEntities, totalPages, allSelected, loading } =
        this.props;
      const totalSelected = selected ? selected.size : 0;

      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%"
          }}
        >
          <div style={{ display: "flex", width: "100%", flexWrap: "wrap" }}>
            <div style={{ display: "flex", flexGrow: 1, flexWrap: "wrap" }}>
              {this.props.renderFilters(this)}
            </div>
            <div
              style={{
                display: "flex",
                flexGrow: 1,
                justifyContent: "flex-end"
              }}
            >
              {selectable && (
                <div
                  style={{
                    display: "flex",
                    flexWrap: "nowrap",
                    flexGrow: 1,
                    paddingBottom: 2,
                    alignItems: "flex-end",
                    justifyContent: "flex-start"
                  }}
                >
                  <SelectionInfo
                    selectAll={this.props.selectAll}
                    totalSelected={totalSelected}
                    totalEntities={totalEntities}
                    totalPages={loading ? 0 : totalPages}
                    allSelected={allSelected}
                    onClear={this.clearSelection}
                    onSelectAll={this.selectAll}
                  />
                </div>
              )}
              <div style={{ display: "flex" }}>
                {this.props.renderActions(this)}
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              flexGrow: 2,
              overflowY: "hidden"
            }}
          >
            <Table
              columns={this.columns()}
              data={this.props.data}
              loading={this.state.loaded && this.props.loading}
              manual={true}
              sortable={sortable}
              sorted={this.state.sorted}
              resized={this.state.resized}
              expanded={this.state.expanded}
              showPagination={pageble}
              pages={this.props.totalPages}
              page={this.props.currentPage}
              pageSize={this.props.pageSize}
              onFetchData={this.handleFetchData}
              onExpandedChange={this.handleExpandedChange}
              onPageChange={this.handlePageChange}
              onPageSizeChange={this.handlePageSizeChange}
              onSortedChange={this.setTableSorting}
              onResizedChange={this.handleResizedChange}
              PaginationComponent={Pagination}
              getPaginationProps={this.getPaginationProps}
              getTdProps={this.getTdProps}
              getTrProps={this.getTrProps}
              getTbodyProps={this.getTbodyProps}
              getTheadThProps={this.getTheadThProps}
              getTheadProps={this.getTheadProps}
              TdComponent={TdComponent}
              TrComponent={TrComponent}
              ThComponent={ThComponent}
              TheadComponent={TheadComponent}
              TbodyComponent={TbodyComponent}
              SubComponent={SubComponent}
              minRows={0}
              className={className}
              defaultPageSize={DEFAULT_PAGE_SIZE}
              noDataText={this.props.loading ? "" : noDataText || NO_DATA_TEXT}
              loadingText={null}
              uniqueId={`${item.type}-react-table`}
              selectable={selectable}
            />
          </div>
        </div>
      );
    }
  }

  const mapStateParams = state => {
    if (params.length === 0) return {};
    const mapping = params.reduce(
      (mapping, param) => ({
        ...mapping,
        [param]: Selectors.getItemData(state, item.type, param) || null
      }),
      {}
    );
    return mapping;
  };

  const sorting = Selectors.sorting(item.type);

  const mapStateToProps = state => ({
    ...mapStateParams(state),
    data: Selectors.selectAllItems(state, item.type),
    loading: procLoading(state, item.type),
    selected: Selectors.getSelectedItems(state, item.type),
    sorting: sorting(state),
    currentPage: Selectors.getCurrentPage(state, item.type) || 0,
    totalPages: Selectors.getTotalPages(state, item.type) || 0,
    totalEntities: Selectors.getTotalEntities(state, item.type) || 0,
    allSelected: Selectors.getAllSelected(state, item.type) || false,
    pageSize: Selectors.getPageSize(state, item.type) || DEFAULT_PAGE_SIZE,
    hasNext: Selectors.getHasNextPage(state, item.type),
    currentLayout: Selectors.getCurrentLayout(state, item.type),
    layoutsList: Selectors.getAllLayouts(state, item.type)
  });

  const mapDispatchToProps = {
    undoReset: () => ActionCreators.undoReset(item.type),
    loadItems: filteringOptions =>
      DckActionCreators.itemsLoad(item.type, filteringOptions),
    clearItems: () => DckActionCreators.itemsSet(item.type, []),
    setTotalPages: totalPages =>
      ActionCreators.setTotalPages(item.type, totalPages),
    setCurrentPage: page => ActionCreators.setCurrentPage(item.type, page),
    setPageSize: pageSize => ActionCreators.setPageSize(item.type, pageSize),
    setAllSelected: value => ActionCreators.setAllSelected(item.type, value),
    setSelectedItems: selectedItems =>
      ActionCreators.setSelectedItems(item.type, selectedItems),
    setSorting: sortFields =>
      DckActionCreators.setItemSortingOptions(item.type, fromJS(sortFields)),
    setCurrentLayout: layout =>
      ActionCreators.setCurrentLayout(item.type, layout),
    setItemLayouts: layouts => ActionCreators.setAllLayouts(item.type, layouts)
  };

  return compose(
    connect(mapStateToProps, mapDispatchToProps),
    withWidth()
  )(Wrapper);
};
