import React, { useEffect } from "react";

import { Grid, Tooltip } from "@material-ui/core";
import { DckActionCreators, procLoading } from "dck-redux";
import { useSelector } from "react-redux";

import { Selectors, useDispatchActions, makeSequence } from "srx-shared";
import { SelectField } from "srx-shared/mui";

import {
  WeightHistory,
  DistributorsWithStorages,
  DistributorShelves
} from "@items/types";

const { itemsLoad, setItemData, itemSelect } = DckActionCreators;

const actions = {
  loadWeightHistory: () => itemsLoad(WeightHistory),
  loadDistributorsWithStorages: () => itemsLoad(DistributorsWithStorages),
  loadDistributorShelves: id => itemSelect(DistributorShelves, id),
  clearFilters: () => DckActionCreators.clearItemSearchFilters(WeightHistory),
  setParams: (field, data) => setItemData(WeightHistory, field, data)
};

const cells = makeSequence(8).map(el => ({ label: el, value: el }));

export function Filters() {
  const distributorsWithStorages = useSelector(state =>
    Selectors.getItemData(state, DistributorsWithStorages, "data")
  );
  const distributorShelves = useSelector(state =>
    Selectors.getItemData(state, DistributorShelves, "distributorShelves")
  );
  const cell = useSelector(state =>
    Selectors.getItemData(state, WeightHistory, "cellIndex")
  );
  const distributorId = useSelector(state =>
    Selectors.getItemData(state, WeightHistory, "distributorId")
  );
  const smartShelfId = useSelector(state =>
    Selectors.getItemData(state, WeightHistory, "smartShelfId")
  );

  const loadingDistributors = useSelector(state =>
    procLoading(state, WeightHistory)
  );

  const loadingDistributorShelves = useSelector(state =>
    procLoading(state, DistributorShelves)
  );

  const {
    loadWeightHistory,
    loadDistributorsWithStorages,
    loadDistributorShelves,
    clearFilters,
    setParams
  } = useDispatchActions(actions);

  useEffect(() => {
    loadWeightHistory();
  }, [cell, smartShelfId, distributorId, loadWeightHistory]);

  useEffect(() => {
    loadDistributorsWithStorages();
  }, [loadDistributorsWithStorages]);

  useEffect(() => {
    if (distributorId) {
      loadDistributorShelves(distributorId);
    }
  }, [distributorId, loadDistributorShelves]);

  useEffect(() => {
    return () => {
      clearFilters();
      setParams("deviceSerialNumber", null);
      setParams("smartShelfId", null);
      setParams("cellIndex", null);
      setParams("distributorId", null);
    };
  }, [clearFilters, setParams]);

  const handleChangeCell = value => {
    if (cell === value) return;
    setParams("cellIndex", value);
  };

  const handleChangeShelf = value => {
    if (smartShelfId === value) return;
    setParams("smartShelfId", value);
  };

  const handleChangeDistributor = value => {
    if (distributorId === value) return;
    setParams("distributorId", value);
    setParams("smartShelfId", null);
  };

  return (
    <Grid container spacing={3}>
      <Grid item sm={4} xs={6} id="distributor-select">
        <SelectField
          fieldProps={{
            label: "Distributor",
            value: distributorId || null,
            margin: "none",
            fullWidth: true,
            placeholder: "All",
            disabled: loadingDistributors,
            SelectProps: {
              isClearable: true,
              inputId: "distributor-select"
            }
          }}
          onChange={handleChangeDistributor}
          options={distributorsWithStorages}
        />
      </Grid>
      <Tooltip title={!distributorId ? "Choose distributor" : ""}>
        <Grid item sm={4} xs={6} id="shelf-select">
          <SelectField
            fieldProps={{
              label: "Smartshelf",
              value: smartShelfId || null,
              margin: "none",
              fullWidth: true,
              placeholder: "All",
              disabled: !distributorId || loadingDistributorShelves,
              SelectProps: {
                isClearable: true,
                inputId: "shelf-select"
              }
            }}
            onChange={handleChangeShelf}
            options={distributorShelves || []}
          />
        </Grid>
      </Tooltip>
      <Grid item sm={4} xs={6} id="cell-select">
        <SelectField
          fieldProps={{
            label: "Cell Number",
            value: cell || null,
            margin: "none",
            fullWidth: true,
            placeholder: "All",
            SelectProps: {
              isClearable: true,
              inputId: "cell-select"
            }
          }}
          onChange={handleChangeCell}
          options={cells}
        />
      </Grid>
    </Grid>
  );
}
