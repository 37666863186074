import { DckActionCreators } from "dck-redux";
import { takeEvery, all, put } from "redux-saga/effects";

import {
  Process,
  isActionLoad,
  isActionAdd,
  isActionUpdate,
  isActionDelete,
  isActionSelect,
  ItemTypeApplication
} from "srx-shared";

import * as RestApi from "@api";
import { BinType, MarketType, CustomerType, LockerType } from "@items/types";

const setDefaults = data => {
  const filtered = { ...data };
  if (
    "cellsWithoutWeightQuantity" in filtered &&
    !filtered.cellsWithoutWeightQuantity
  ) {
    filtered.cellsWithoutWeightQuantity = filtered.cellsQuantity;
  }
  return filtered;
};

function* loadManagedTypesSaga(action) {
  const { itemType } = action;
  const proc = new Process.Load(itemType, RestApi[`List${itemType}s`], {
    pageble: true
  });
  yield proc.start();
  try {
    yield proc.callApi();
    yield proc.setEntities();
    yield proc.setTotalPages();
    yield proc.stop();
  } catch (ex) {
    yield proc.fail(ex);
  }
}

function* addManagedTypeSaga(action) {
  const { itemType, data } = action;
  const proc = new Process.Add(itemType, RestApi[`Add${itemType}`]);
  yield proc.start();
  try {
    yield proc.callApi(setDefaults(data));
    yield proc.stop();
    yield loadManagedTypesSaga(action);
  } catch (ex) {
    yield proc.fail(ex);
  }
}

function* saveManagedTypeSaga(action) {
  const { itemType, data, id } = action;
  const proc = new Process.Update(itemType, RestApi[`Update${itemType}`]);
  yield proc.start();
  try {
    yield proc.callApi(id, setDefaults(data));
    yield proc.stop();
    yield loadManagedTypesSaga(action);
  } catch (ex) {
    yield proc.fail(ex);
  }
}

function* deleteManagedTypeSaga(action) {
  const { itemType, id } = action;
  const proc = new Process.Delete(itemType, RestApi[`Remove${itemType}`]);
  yield proc.start();
  try {
    yield proc.callApi(id);
    yield proc.stop();
    yield loadManagedTypesSaga(action);
  } catch (ex) {
    yield proc.fail(ex);
  }
}

function* getLockerTypes() {
  const proc = new Process.Load(LockerType, RestApi.GetLockerTypes);
  yield proc.start();
  try {
    yield proc.callApi();
    const data = proc.data.map(el => ({
      label: el.name,
      value: el.id
    }));
    yield put(
      DckActionCreators.setItemData(ItemTypeApplication, "lockerTypes", data)
    );
    yield proc.stop();
  } catch (ex) {
    yield proc.fail(ex);
  }
}

function* managedTypesSaga() {
  yield all([
    takeEvery(isActionLoad(BinType), loadManagedTypesSaga),
    takeEvery(isActionAdd(BinType), addManagedTypeSaga),
    takeEvery(isActionUpdate(BinType), saveManagedTypeSaga),
    takeEvery(isActionDelete(BinType), deleteManagedTypeSaga),

    takeEvery(isActionLoad(MarketType), loadManagedTypesSaga),
    takeEvery(isActionAdd(MarketType), addManagedTypeSaga),
    takeEvery(isActionUpdate(MarketType), saveManagedTypeSaga),
    takeEvery(isActionDelete(MarketType), deleteManagedTypeSaga),

    takeEvery(isActionLoad(CustomerType), loadManagedTypesSaga),
    takeEvery(isActionAdd(CustomerType), addManagedTypeSaga),
    takeEvery(isActionUpdate(CustomerType), saveManagedTypeSaga),
    takeEvery(isActionDelete(CustomerType), deleteManagedTypeSaga),

    takeEvery(isActionLoad(LockerType), loadManagedTypesSaga),
    takeEvery(isActionAdd(LockerType), addManagedTypeSaga),
    takeEvery(isActionUpdate(LockerType), saveManagedTypeSaga),
    takeEvery(isActionDelete(LockerType), deleteManagedTypeSaga),
    takeEvery(isActionSelect(LockerType), getLockerTypes)
  ]);
}

export default managedTypesSaga;
