import { Grid } from "@material-ui/core";
import { useFormikContext } from "formik";
import _get from "lodash/get";
import PropTypes from "prop-types";

import { Dictionaries } from "srx-shared";
import { LabelComponent, SelectField } from "srx-shared/mui";

import { useStyles } from "./styles";

SelectState.propTypes = {
  field: PropTypes.object
};

export function SelectState(props) {
  const {
    field: { name, value }
  } = props;

  const { errors, touched, setFieldValue, values } = useFormikContext();

  const { country } = values;

  const cls = useStyles();

  const options =
    country === "USA" ? Dictionaries.US_STATES : Dictionaries.CANADA_STATES;

  const handleChange = value => setFieldValue("address.state", value, true);

  const error = Boolean(_get(touched, name) && _get(errors, name));

  const helperText = error ? _get(errors, name) : "";

  return (
    <Grid item xs={12} sm={6}>
      <SelectField
        fieldProps={{
          disabled: !country,
          label: <LabelComponent label="State" required />,
          value,
          helperText,
          error,
          fullWidth: true
        }}
        FormHelperTextProps={{
          classes: {
            root: cls.helperText,
            error: cls.helperTextError
          }
        }}
        onChange={handleChange}
        options={options}
      />
    </Grid>
  );
}
