export const styles = theme => ({
  root: {
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    paddingLeft: 15,
    lineHeight: "15px",
    fontSize: "10pt"
  },
  link: {
    color: theme.palette.primary.main,
    display: "inline-flex",
    "& > div": {
      maxWidth: 300,
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap"
    },
    "&:hover": {
      color: theme.palette.primary.main
    }
  },
  text: {
    color: "#707070",
    display: "inline-flex",
    "& > div": {
      maxWidth: 300,
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap"
    }
  },
  divider: {
    padding: "0 10px",
    color: "#ccc"
  },
  ending: {
    marginLeft: "auto",
    paddingRight: 15,
    color: "#707070"
  }
});
