import { takeEvery, all } from "redux-saga/effects";

import { Process, isActionLoad, isActionUpdate } from "srx-shared";

import * as RestApi from "@api";
import { FreezeVariables } from "@items/types";

function* loadFreezeVariablesSaga(action) {
  const proc = new Process.Load(FreezeVariables, RestApi.GetFreezeVariables);
  yield proc.start();
  try {
    yield proc.callApi(action.filteringOptions.id);
    yield proc.setItemData("values", proc.data);
    yield proc.stop();
  } catch (ex) {
    yield proc.fail(ex);
  }
}

function* saveFreezeVariablesSaga(action) {
  const proc = new Process.Update(
    FreezeVariables,
    RestApi.UpdateFreezeVariables
  );
  yield proc.start();
  try {
    yield proc.callApi(action.id, action.data);
    yield proc.setItemData("values", action.data);
    yield proc.stop();
  } catch (ex) {
    yield proc.fail(ex);
  }
}

function* freezeVariablesSaga() {
  yield all([
    takeEvery(isActionLoad(FreezeVariables), loadFreezeVariablesSaga),
    takeEvery(isActionUpdate(FreezeVariables), saveFreezeVariablesSaga)
  ]);
}

export default freezeVariablesSaga;
