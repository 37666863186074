import React from "react";

import EditIcon from "@material-ui/icons/EditOutlined";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import { ActionCreators } from "../../actions";
import { useOptItem } from "../../hooks";
import { IconButton } from "../IconButton";

const { showEditModal } = ActionCreators;

export function EditButton(props) {
  const { row, tip, item, ...rest } = props;
  const dispatch = useDispatch();
  const optItem = useOptItem(item?.type, row);

  const handleClick = () => {
    optItem(row.id);
    dispatch(showEditModal(item.type));
  };

  if (!item || !row) return null;

  return (
    <IconButton
      tip={`Edit ${tip || item.name}`}
      icon={<EditIcon />}
      onClick={handleClick}
      data-testid="edit"
      {...rest}
    />
  );
}

EditButton.propTypes = {
  item: PropTypes.any.isRequired,
  row: PropTypes.object,
  tip: PropTypes.string
};
