import ReactDOM from "react-dom";
import { PersistGate } from "redux-persist/lib/integration/react";

import { Sentry, SRX_PORTAL } from "srx-shared";

import { Application } from "./app";
import { BUILD_VERSION, STAGE } from "./config";
import { history, persistor } from "./redux/store";
import { unregister } from "./serviceWorker";

window.SRX_PLATFORM_VERSION = BUILD_VERSION;
window.SRX_PORTAL = SRX_PORTAL.ADMIN;

if (process.env.NODE_ENV === "production") {
  Sentry.init();
  Sentry.setTag("portal-version", BUILD_VERSION);
  Sentry.setTag("stage", STAGE);
  Sentry.setTag("portal", SRX_PORTAL.ADMIN);
}

ReactDOM.render(
  <PersistGate loading={null} persistor={persistor}>
    <Application history={history} />
  </PersistGate>,
  document.getElementById("root")
);

unregister();
