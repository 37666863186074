import { useCallback, useMemo, useState } from "react";

import { Grid, Paper } from "@material-ui/core";
import ConfigureIcon from "@material-ui/icons/SettingsOutlined";
import { DckActionCreators } from "dck-redux";
import _debounce from "lodash/debounce";
import * as PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { Selectors, useDispatchActions } from "srx-shared";
import { LinkIconButton, SwitchField } from "srx-shared/mui";

import { HardwareStates, LockerDoor } from "@items/types";
import { pages } from "@routes/pages";

import { DoorState } from "./DoorState";
import { useStyles } from "./styles";

const actions = {
  updateStorageSection: (id, data) =>
    DckActionCreators.itemSave(LockerDoor, id, data)
};

TroubleshootingDoor.propTypes = {
  id: PropTypes.number,
  door: PropTypes.object,
  lockerId: PropTypes.number,
  locker: PropTypes.object
};
export function TroubleshootingDoor(props) {
  const { id, door, lockerId, locker } = props;
  const { smartShelfHardware, noWeight, weightPolling, debugMode } = door;
  const { serialNumber } = smartShelfHardware || {};

  const [values, setValues] = useState({ weightPolling, debugMode });

  const hardwareState = useSelector(state =>
    Selectors.selectActiveItem(state, HardwareStates)
  );

  const open = useMemo(
    () =>
      hardwareState?.state[0].doors.find(
        doorState => doorState.number === door.number
      )?.isOpen,
    [door, hardwareState]
  );

  const cls = useStyles({ open });

  const { updateStorageSection } = useDispatchActions(actions);

  const debounceUpdate = useCallback(_debounce(updateStorageSection, 1000), []);

  const handleSave = values => {
    setValues(values);
    debounceUpdate(lockerId, { ...door, ...values });
  };

  const handleWeightPolling = value =>
    handleSave({ weightPolling: value, debugMode: false });

  const handleDebugMode = value => handleSave({ ...values, debugMode: value });

  return (
    <Paper className={cls.root}>
      <Grid container alignItems="center" wrap="nowrap" className={cls.content}>
        <Grid
          item
          xs={
            !serialNumber && !noWeight ? 1 : serialNumber && !noWeight ? 3 : 10
          }
        >
          <DoorState id={id} door={door} locker={locker} />
        </Grid>
        {!serialNumber && !noWeight && (
          <Grid item xs={9} className={cls.notAssigned}>
            Please, assign smartshelf
          </Grid>
        )}
        {serialNumber && !noWeight && (
          <>
            <Grid item xs>
              <SwitchField
                value={values["weightPolling"]}
                label="Weight polling"
                noHelperText
                onChange={handleWeightPolling}
              />
            </Grid>
            <Grid item>
              <SwitchField
                value={values["debugMode"]}
                label="Debug mode"
                noHelperText
                disabled={!values["weightPolling"]}
                onChange={handleDebugMode}
              />
            </Grid>
          </>
        )}
        <Grid item xs={2} className={cls.openDoor}>
          {open ? "Open" : "Closed"}
        </Grid>
        <Grid item xs={1} className={cls.linkButton}>
          <LinkIconButton
            to={`${pages.hardware.path}/locker/${lockerId}/door/${id}`}
            icon={<ConfigureIcon />}
            color="primary"
            data-testid="door-settings"
          />
        </Grid>
      </Grid>
    </Paper>
  );
}
