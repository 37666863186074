import { useState } from "react";

import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Typography,
  Grid,
  TextField
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PropTypes from "prop-types";

import { DualList, SwitchField } from "srx-shared/mui";

AdjustMovingSuggestedMinMaxSettings.propTypes = {
  isSwitch: PropTypes.bool,
  title: PropTypes.string
};

export function AdjustMovingSuggestedMinMaxSettings(props) {
  const { title, isSwitch = false } = props;

  const [expanded, setExpanded] = useState(false);

  const handleExpand = () => setExpanded(!expanded);

  const renderTitle = () => (
    <Typography
      color="primary"
      component="span"
      style={{
        fontSize: 17,
        marginRight: 24
      }}
    >
      {title}
    </Typography>
  );

  return (
    <Grid item xs={12} style={{ maxWidth: 800, width: "100%" }}>
      <ExpansionPanel expanded={expanded} onChange={handleExpand}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Grid container alignItems="center">
            <Grid item style={{ display: "flex" }}>
              {renderTitle()}
            </Grid>
          </Grid>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails style={{ padding: "0 16px 16px" }}>
          <Grid container spacing={2}>
            {isSwitch && (
              <Grid item xs={12}>
                <SwitchField label="Enable adjustment" value={false} disabled />
              </Grid>
            )}
            <Grid item xs={12} sm>
              <TextField
                label="Current Shipto"
                value=""
                fullWidth
                InputProps={{
                  disabled: true,
                  style: {
                    color: "#333333"
                  }
                }}
              />
            </Grid>
            <Grid item style={{ width: 38, boxSizing: "content-box" }} />
            <Grid item xs={12}>
              <DualList
                noItemsText="No Shipto avaliable"
                available={null}
                availableLabel="Available Shiptos"
                selectedLabel="Including Shiptos"
                selected={null}
                disabled
              />
            </Grid>
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </Grid>
  );
}
