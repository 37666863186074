/* eslint react/display-name: "off" */
/* eslint react/prop-types: "off" */
/* eslint no-template-curly-in-string: "off" */
import React from "react";

import { DckActionCreators } from "dck-redux";
import * as V from "yup";

import { mapFiltersFor, mapFormFieldsFor, Formatters } from "srx-shared";
import { Cell, TitleComponent } from "srx-shared/mui";

import Actions from "@comp/Hardware/SmartShelves/Actions";
import SmartShelfComponent from "@comp/Hardware/SmartShelves/SmartShelfFormComponent";
import { SmartShelf, Locker, AvailableLockerDoor } from "@items/types";
import { store } from "@redux/store";
import { getAvoidDotsError } from "@utils";

import { NoAssignedIotHubMessage } from "./NoAssignedIotHubMessage";

const lockerIsDisabled = ({ values: { distributor } }) => !distributor.id;

const doorIsDisabled = ({ values: { doorConfiguration } }) =>
  !doorConfiguration.hardware.id;

const cellQuantityIsDisabled = ({ values: { id } }) => Boolean(id);

const onChangeDistributor = (value, form) => {
  const { setFieldValue } = form;
  if (value) {
    store.dispatch(
      DckActionCreators.itemsLoad(Locker, {
        id: value
      })
    );
  } else {
    setFieldValue("distributor.id", "", false);
    setFieldValue("doorConfiguration.hardware.id", "", false);
    setFieldValue("doorConfiguration.id", "", false);
  }
};

const onChangeLocker = (value, form) => {
  const { initialValues, setFieldValue } = form;
  if (value) {
    store.dispatch(
      DckActionCreators.itemsLoad(AvailableLockerDoor, {
        id: value,
        door:
          initialValues.doorConfiguration.hardware.id === value
            ? initialValues.doorConfiguration
            : null
      })
    );
  } else {
    setFieldValue("doorConfiguration.id", "", false);
    setFieldValue("doorConfiguration.hardware.id", "", false);
  }
};

const onChangeCellsQuantity = (value, form) => {
  if (value > 0 && value < 9) {
    const cells = new Array(Number(value)).fill(0).map((el, index) => ({
      indexes: [index + 1]
    }));
    form.setFieldValue("cells", cells, false);
  }
};

const formatDeviceName = ({ value, deviceName }) =>
  `${value} ${deviceName ? ` / ${deviceName}` : ""}`;

V.addMethod(V.number, "avoidDots", function () {
  return this.transform(getAvoidDotsError);
});

export const SmartShelfItem = {
  type: SmartShelf,
  name: "SmartShelf",
  title: "Smart Shelf"
};

// table
SmartShelfItem.columns = [
  {
    Header: "Serial Number",
    accessor: "serialNumber",
    Cell: props => <Cell {...props} />
  },
  {
    Header: "Assigned Device",
    accessor: "doorConfiguration.hardware",
    sortable: false,
    Cell: ({ value }) => <Cell>{value.id && formatDeviceName(value)}</Cell>
  },
  {
    Header: "Assigned Device Type",
    accessor: "doorConfiguration.hardware.type",
    sortable: false,
    Cell: ({ value }) => <Cell>{value && Formatters.deviceType(value)}</Cell>
  },
  {
    Header: "Qnty of Cells",
    accessor: "cellsQuantity",
    maxWidth: 110,
    Cell: props => <Cell {...props} />
  },
  {
    Header: "Distributor",
    accessor: "distributor.name",
    sortable: false,
    Cell: props => <Cell {...props} />
  },
  {
    Header: "Actions",
    width: 90,
    isAction: true,
    resizable: false,
    sortable: false,
    Cell: ({ row }) => <Actions row={row._original} />
  }
];

// filters
SmartShelfItem.filtersConfig = {
  distributorId: {
    name: "Distributor",
    type: "select",
    dict: "distributors"
  },
  lockerSerial: {
    name: "Assigned Device"
  }
};
SmartShelfItem.filters = mapFiltersFor(SmartShelfItem, [
  "distributorId",
  "lockerSerial"
]);

// form fields
SmartShelfItem.fieldsConfig = {
  serialNumber: {
    label: "Smart Shelf Serial Number",
    required: true,
    initialValue: "",
    size: 6
  },
  "distributor.id": {
    label: "Distributor",
    type: "select",
    dict: "distributors",
    initialValue: "",
    size: 6,
    onChange: onChangeDistributor,
    SelectProps: {
      isClearable: true
    }
  },
  label: {
    size: 12,
    type: "component",
    Component: () => (
      <TitleComponent
        text="Smart Shelf Configuration"
        size={12}
        style={{ paddingTop: 22, paddingBottom: 0 }}
      />
    )
  },
  cellsQuantity: {
    label: "Cells Quantity",
    initialValue: "4",
    required: true,
    size: 6,
    onChange: onChangeCellsQuantity,
    checkDisabled: cellQuantityIsDisabled
  },
  cells: {
    Component: SmartShelfComponent,
    initialValue: [
      { indexes: [1] },
      { indexes: [2] },
      { indexes: [3] },
      { indexes: [4] }
    ],
    size: 12
  },
  "doorConfiguration.hardware.id": {
    label: "Assign To",
    type: "select",
    dict: "lockers",
    onChange: onChangeLocker,
    initialValue: "",
    size: 6,
    checkDisabled: lockerIsDisabled,
    SelectProps: {
      isClearable: true
    }
  },
  "doorConfiguration.id": {
    label: "Door/Section Number",
    type: "select",
    dict: "lockerDoors",
    initialValue: "",
    size: 6,
    checkDisabled: doorIsDisabled
  },
  noAssignedIotHubMessage: {
    Component: NoAssignedIotHubMessage
  }
};

SmartShelfItem.schema = V.object({
  serialNumber: V.string()
    .label("Smart Shelf Serial Number")
    .max(255)
    .required(),
  cellsQuantity: V.number()
    .label("Cells Quantity")
    .typeError("${label} must be an number")
    .avoidDots()
    .min(1)
    .max(8)
    .required(),
  doorConfiguration: V.object({
    id: V.string()
      .label("Door/Section Number")
      .when("hardware.id", (hardware, schema) =>
        hardware ? schema.required() : schema
      )
  })
});

SmartShelfItem.createForm = mapFormFieldsFor(SmartShelfItem, [
  "serialNumber",
  "distributor.id",
  "label",
  "cellsQuantity",
  "cells",
  "doorConfiguration.hardware.id",
  "doorConfiguration.id",
  "noAssignedIotHubMessage"
]);

SmartShelfItem.editForm = mapFormFieldsFor(SmartShelfItem, [
  "serialNumber",
  "distributor.id",
  "label",
  "cellsQuantity",
  "cells",
  "doorConfiguration.hardware.id",
  "doorConfiguration.id",
  "noAssignedIotHubMessage"
]);
