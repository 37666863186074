import React from "react";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import { Form as FormWrapper } from "formik";
import PropTypes from "prop-types";

import { FormField } from "./FormField";
import { styles } from "./styles";

const WrapperComponent = props => {
  const style = props.vertical
    ? {
        display: "flex",
        justifyContent: "space-between"
      }
    : {};
  return <div style={style}>{props.children}</div>;
};

const EmptyContainer = props => props.children;

WrapperComponent.propTypes = {
  vertical: PropTypes.bool
};

const GroupComponent = ({ children }) => children;

class FormContainerComponent extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    children: PropTypes.any
  };
  render() {
    const { classes, children } = this.props;
    return (
      <Grid container justify="center" item xs={12}>
        <Paper elevation={1} className={classes.paper}>
          {children}
        </Paper>
      </Grid>
    );
  }
}

export const FormContainer = withStyles(styles)(FormContainerComponent);

class FormComponent extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    Component: PropTypes.any,
    InnerContainer: PropTypes.any,
    id: PropTypes.string,
    form: PropTypes.object,
    readOnly: PropTypes.bool,
    useDefaults: PropTypes.bool,
    processing: PropTypes.bool,
    defaultValues: PropTypes.object,
    handleSubmit: PropTypes.func,
    renderActions: PropTypes.func,
    vertical: PropTypes.bool,
    spacing: PropTypes.number,
    values: PropTypes.object,
    onChange: PropTypes.func,
    isValid: PropTypes.bool
  };
  static defaultProps = {
    renderActions: () => {},
    Component: WrapperComponent,
    InnerContainer: EmptyContainer
  };

  componentDidUpdate(prevProps) {
    const { values, useDefaults, isValid, onChange } = this.props;
    if (
      prevProps.values !== values ||
      prevProps.useDefaults !== useDefaults ||
      prevProps.isValid !== isValid
    ) {
      onChange && onChange({ values, useDefaults, isValid });
    }
  }

  render() {
    const {
      Component,
      InnerContainer,
      vertical,
      id,
      form,
      renderActions,
      useDefaults,
      readOnly,
      defaultValues = {},
      spacing = 3
    } = this.props;

    const Container = Component || WrapperComponent;

    const renderField = (fieldConfig, key) =>
      useDefaults === null ? (
        <FormField
          key={key}
          fieldConfig={fieldConfig}
          formInstance={this}
          disabled={readOnly}
        />
      ) : (
        <FormField
          key={key}
          fieldConfig={fieldConfig}
          formInstance={this}
          disabled={useDefaults || readOnly}
          defaultValue={defaultValues[fieldConfig.name]}
        />
      );

    const renderGroup = (groupConfig, key) => {
      const { fields, Component, checkHidden, ...containerProps } = groupConfig;
      const GroupContainer = Component || GroupComponent;
      const hidden = checkHidden ? checkHidden(this.props) : false;
      return !hidden ? (
        <GroupContainer
          {...containerProps}
          key={key}
          form={this.props}
          fields={fields}
        >
          {fields &&
            fields.map((field, key) =>
              renderField(
                form.fields.find(f => f.name === field),
                key
              )
            )}
        </GroupContainer>
      ) : null;
    };

    return (
      <FormWrapper id={id} style={{ width: "100%" }}>
        <Container vertical={vertical}>
          {form.groups && form.groups.map(renderGroup)}
          {!form.groups && (
            <InnerContainer>
              <Grid
                container
                spacing={spacing}
                item
                xs={12}
                style={{ margin: 0 }}
              >
                {form.fields.map(renderField)}
              </Grid>
            </InnerContainer>
          )}

          {renderActions(this)}
        </Container>
      </FormWrapper>
    );
  }
}

export const Form = withStyles(styles)(FormComponent);
