import React from "react";

import { generalFormFor, inlineEditFormFor } from "srx-shared";

import { RangeItem } from "./formTypes";

const InlineEditForm = generalFormFor(RangeItem);

const InlineForm = inlineEditFormFor(RangeItem, {
  InlineEditForm
});

export const InlineEditRange = props => <InlineForm {...props} />;

InlineEditRange.displayName = "InlineEditRange";
