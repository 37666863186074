import React, { Component } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";

import { ActionCreators } from "../../actions";
import { modalState } from "../../actions/selectors";
import { ModalDialog } from "../ModalDialog";

export const editModalFor = (
  item,
  { EditForm = null, title = "", size = "md", action } = {}
) => {
  class Wrapper extends Component {
    static propTypes = {
      showEditModal: PropTypes.bool,
      closeEditModal: PropTypes.func,
      onOpen: PropTypes.func,
      onClose: PropTypes.func
    };

    componentDidUpdate(prevProps) {
      const { showEditModal, onOpen, onClose } = this.props;
      if (prevProps.showEditModal !== this.props.showEditModal) {
        global.currentAction = this.props.showEditModal
          ? action || "edit"
          : null;

        const handle = showEditModal ? onOpen : onClose;
        handle && handle();
      }
    }

    render() {
      const { showEditModal, closeEditModal, ...restProps } = this.props;
      return (
        <ModalDialog
          open={!!showEditModal}
          onClose={closeEditModal}
          title={title || `Update ${item.title}`}
          size={size}
          {...restProps}
        >
          {EditForm && (
            <EditForm onClose={closeEditModal} onUpdate={closeEditModal} />
          )}
        </ModalDialog>
      );
    }
  }
  const mapStateToProps = state => ({
    showEditModal: modalState(state, item.type)
  });

  const dispatchStateToProps = {
    closeEditModal: () => ActionCreators.hideEditModal(item.type)
  };

  return connect(mapStateToProps, dispatchStateToProps)(Wrapper);
};
