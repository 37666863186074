import { Grid } from "@material-ui/core";
import { useField } from "formik";

import { Message } from "srx-shared/mui";
import { toBoolean } from "srx-shared/utils";

export function ImmediatelyMovedStatusMessage() {
  const [field] = useField("submitImmediately");

  if (!toBoolean(field.value)) return null;

  return (
    <Grid item xs={12} style={{ padding: "0 12px" }}>
      <Message variant="info">
        The orders will automatically be moved from ACTIVE to QUOTED/ORDERED
        status immediately after creation
      </Message>
    </Grid>
  );
}
