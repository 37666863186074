export const styles = theme => ({
  smartShelfPaper: {
    display: "inline-flex",
    backgroundColor: "#f0f0f0",
    width: "100%",
    justifyContent: "center",
    margin: 5,
    position: "relative"
  },
  noWeightsDoor: {
    background: "#bcd"
  },
  doorContainer: {
    display: "inline-flex",
    padding: 10,
    paddingLeft: 10
  },
  doorId: {
    display: "flex",
    width: 30,
    height: 30,
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    fontSize: "14px",
    background: "#f0f0f0",
    borderRadius: "50%",
    padding: 10
  },
  emptyId: {
    color: "#999"
  },
  notEmptyId: {
    color: "#555",
    border: "1px solid #ccc"
  },
  weightIcon: {
    display: "flex",
    alignItems: "center",
    alignContent: "center",
    paddingLeft: 10
  }
});
