import React from "react";

import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

import { styles } from "./styles";

const ActionsContainer = ({ classes, children }) => (
  <div className={classes.actionsContainer}>{children}</div>
);
ActionsContainer.propTypes = { classes: PropTypes.object };

export const TableActionsContainer = withStyles(styles)(ActionsContainer);
