import React from "react";

import { withPageTitle } from "srx-shared";
import { PageTabContainer } from "srx-shared/mui";

import { feesTabs } from "./feesTabs";

function FeesComponent() {
  return <PageTabContainer tabs={feesTabs} />;
}

export default withPageTitle("Fees")(FeesComponent);
