import { Grid } from "@material-ui/core";
import { procLoading } from "dck-redux";
import * as PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { Icons, Selectors, HARDWARE_STATUS } from "srx-shared";
import { ActionsSpinner } from "srx-shared/mui";

import { HardwareStates } from "@redux/items/types";

import { useStyles } from "../styles";

const colorsStatus = {
  [HARDWARE_STATUS.RED]: {
    colors: {
      background: "brown",
      color: "white"
    },
    Icon: Icons.WeightsError
  },
  [HARDWARE_STATUS.GREY]: {
    colors: { background: "#eee", color: "#777" },
    Icon: () => <Icons.Weights style={{ color: "#777" }} />
  },
  [HARDWARE_STATUS.GREEN]: {
    colors: { background: "#eee", color: "#777" },
    Icon: Icons.WeightsOk
  },
  [HARDWARE_STATUS.ORANGE]: {
    colors: {
      background: "#EF8A20",
      color: "white"
    },
    Icon: Icons.Weights
  }
};

DoorState.propTypes = {
  id: PropTypes.number,
  door: PropTypes.object,
  locker: PropTypes.object,
  open: PropTypes.bool
};

export default function DoorState(props) {
  const { id, door, locker, open } = props;

  const hardwareState = useSelector(state =>
    Selectors.selectActiveItem(state, HardwareStates)
  );

  const loading = useSelector(state => procLoading(state, HardwareStates));

  let status = locker?.status || HARDWARE_STATUS.GREY;

  if (status === HARDWARE_STATUS.GREEN && !door.noWeight) {
    if (door.smartShelfHardware) {
      const doorState = hardwareState
        ? hardwareState.state[0].doors.find(
            doorState => doorState.number === door.number
          )
        : null;
      if (doorState && !(doorState.cells && doorState.cells.length)) {
        status = HARDWARE_STATUS.ORANGE;
      }
    } else {
      status = HARDWARE_STATUS.GREY;
    }
  }

  const showIcon = door.noWeight || door.smartShelfHardware?.serialNumber;

  const { Icon, colors } = colorsStatus[status];

  const cls = useStyles({ open, colors });

  if (loading)
    return (
      <Grid container justify="center" className={cls.doorConfig}>
        <ActionsSpinner processing />
      </Grid>
    );

  return (
    <Grid item xs={12}>
      <div className={cls.doorConfig}>
        <Grid container alignItems="center">
          <Grid
            item
            xs={4}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              fontSize: 16
            }}
          >
            <div className={cls.number}>{id}</div>
            Door
          </Grid>
          <Grid
            item
            xs={4}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              fontSize: 16
            }}
          >
            {showIcon && (
              <Grid item className={cls.iconWrapper}>
                {door.noWeight ? (
                  <Icons.NoWeights style={{ color: "#777" }} />
                ) : (
                  <Icon />
                )}
              </Grid>
            )}
            {status}
          </Grid>
          <Grid item xs={4} className={cls.justifyEnd}>
            <div className={cls.openDoor}>{open ? "Open" : "Closed"}</div>
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
}
