import React from "react";

import MuiTextField from "@material-ui/core/TextField";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";

import { styles } from "./styles";

class TextFieldComponent extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    trimSpacesOnBlur: PropTypes.bool,
    onChange: PropTypes.func,
    onBlur: PropTypes.func
  };

  trimSpaces = e => {
    const { onChange, onBlur } = this.props;
    const value = String(e.target.value || "");
    const trimValue = value.trim();

    if (value !== trimValue) {
      e.target.value = trimValue;
      onChange && onChange(e);
    }
    onBlur && onBlur(e);
  };

  render() {
    const { trimSpacesOnBlur, ...rest } = this.props;

    return trimSpacesOnBlur ? (
      <MuiTextField {...rest} onBlur={this.trimSpaces} />
    ) : (
      <MuiTextField {...rest} />
    );
  }
}

export const TextField = withStyles(styles)(TextFieldComponent);
