import { takeLatest, all, put } from "redux-saga/effects";

import {
  ActionCreators,
  isActionAdd,
  NotificationCreators,
  Process
} from "srx-shared";

import * as RestApi from "@api";
import { VendingConfig } from "@items/types";

function* testVendingIssueItemsSaga(action) {
  const proc = new Process.Add(VendingConfig, RestApi.TestVendingIssueItems);
  yield proc.start();
  try {
    yield proc.callApi(action.data);
    if (proc.response.statusCode === 409) {
      throw Error(proc.response.message);
    }
    yield put(
      ActionCreators.pushNotification(
        NotificationCreators.successOperation(
          "The request was sent successfully"
        )
      )
    );
    yield proc.stop();
  } catch (ex) {
    yield proc.fail(ex.response?.message || ex);
  }
}

function* vendingConfigSaga() {
  yield all([
    takeLatest(isActionAdd(VendingConfig), testVendingIssueItemsSaga)
  ]);
}

export default vendingConfigSaga;
