import React from "react";

import { CircularProgress } from "@material-ui/core";
import PropTypes from "prop-types";

ActionsSpinner.propTypes = {
  processing: PropTypes.bool,
  width: PropTypes.number,
  color: PropTypes.string
};

export function ActionsSpinner(props) {
  const { processing, width = 32, color = "primary" } = props;
  if (!processing) return null;

  const style = { width, height: width, marginRight: 8 };

  return <CircularProgress color={color} style={style} />;
}
