import { Grid } from "@material-ui/core";
import { useField } from "formik";

import { toBoolean } from "srx-shared";
import { Message } from "srx-shared/mui";

const infoMessage =
  "You will be able to add notes and PO Number to the order list at the Labels feature  in the mobile app when turn on Auto-Submit immediately";

export function AutoSubmitImmediateMessage() {
  const [, meta] = useField("submitImmediately");

  const { value } = meta;

  if (!toBoolean(value)) return null;

  return (
    <Grid item xs={12} style={{ padding: "0 12px", marginTop: "12px" }}>
      <Message variant="info">{infoMessage}</Message>
    </Grid>
  );
}
