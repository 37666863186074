import React from "react";

import { DialogActions } from "@material-ui/core";
import { DckSelectors, DckActionCreators } from "dck-redux";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { ModalDialog, ActionsSpinner, FormButton } from "srx-shared/mui";

import { SmartShelfItem } from "./SmartShelfItem";

const ConfirmModalForm = props => {
  const { updateItem, status, resetStatus } = props;

  const handleSave = () => {
    updateItem({ ...status.data, confirmed: true });
    resetStatus();
  };

  return (
    <ModalDialog
      open={Boolean(status)}
      onClose={resetStatus}
      title="Confirmation of configuration update"
      size="sm"
    >
      <div style={{ padding: "0 16px 16px 16px" }}>
        <div style={{ margin: 10, color: "#707070" }}>
          {(status || {}).message}
        </div>
        <DialogActions>
          <ActionsSpinner />
          <FormButton variant="cancel" onClick={resetStatus} />
          <FormButton variant="confirm" onClick={handleSave} />
        </DialogActions>
      </div>
    </ModalDialog>
  );
};
ConfirmModalForm.propTypes = {
  updateItem: PropTypes.func,
  status: PropTypes.object,
  resetStatus: PropTypes.func
};

const mapStateToProps = state => ({
  status: DckSelectors.getItemData(state, SmartShelfItem.type, "status")
});

const dispatchStateToProps = {
  resetStatus: () =>
    DckActionCreators.setItemData(SmartShelfItem.type, "status", null),
  updateItem: data => DckActionCreators.itemsSave(SmartShelfItem.type, data)
};

export default connect(mapStateToProps, dispatchStateToProps)(ConfirmModalForm);
