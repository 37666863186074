/* eslint react/display-name: "off" */
/* eslint react/prop-types: "off" */
/* eslint no-template-curly-in-string: "off" */
import * as V from "yup";

import { mapFormFieldsFor } from "srx-shared";

import { FreezeVariables } from "@items/types";
import { getAvoidDotsError, checkPrecision } from "@utils";

V.addMethod(V.number, "avoidDots", function () {
  return this.transform(getAvoidDotsError);
});

V.addMethod(V.number, "checkPrecision", function () {
  return this.test(
    "testPrecision",
    "Number must have 2 digits after decimal point",
    value => checkPrecision(value, 2)
  );
});

export const FreezeVariablesItem = {
  type: FreezeVariables,
  name: "Freeze"
};

// form fields
FreezeVariablesItem.fieldsConfig = {
  monthsUntilSlow: {
    label: "Months until Slow",
    required: true,
    initialValue: "1",
    size: 6
  },
  monthsUntilFrozen: {
    label: "Months until Frozen",
    required: true,
    initialValue: "2",
    size: 6
  },
  percentOfSlowCost: {
    label: "% of Slow Cost",
    required: true,
    initialValue: "0",
    size: 6
  },
  frozenFee: {
    label: "Frozen Cost",
    required: true,
    initialValue: "0",
    size: 6,
    inputSuffix: "$"
  },
  wakeUpFee: {
    label: "Wake up fee",
    required: true,
    initialValue: "0",
    size: 6,
    inputSuffix: "$"
  },
  minButtonFee: {
    label: "Minimal button fee",
    required: true,
    initialValue: "0.35",
    size: 6,
    inputSuffix: "$"
  }
};

FreezeVariablesItem.schema = V.object({
  monthsUntilSlow: V.number()
    .label("Months until Slow")
    .typeError("${label} must be a number")
    .required()
    .min(0)
    .max(36)
    .avoidDots()
    .test({
      name: "Comparison with Frozen",
      message: "Months until Slow must be less than Months until Frozen",
      test: function (value) {
        return value === 0 || value < this.parent.monthsUntilFrozen;
      }
    }),
  monthsUntilFrozen: V.number()
    .label("Months until Frozen")
    .typeError("${label} must be a number")
    .required()
    .min(0)
    .max(36)
    .avoidDots(),
  percentOfSlowCost: V.number()
    .label("% of Slow Cost")
    .typeError("${label} must be a number")
    .required()
    .min(0)
    .max(100)
    .checkPrecision(),
  frozenFee: V.number()
    .label("Frozen Cost")
    .typeError("${label} must be a number")
    .required()
    .min(0)
    .lessThan(1000000)
    .checkPrecision(),
  wakeUpFee: V.number()
    .label("Wake up fee")
    .typeError("${label} must be a number")
    .required()
    .min(0)
    .lessThan(1000000)
    .checkPrecision(),
  minButtonFee: V.number()
    .label("Minimal button fee")
    .typeError("${label} must be a number")
    .required()
    .min(0.35)
    .lessThan(1000000)
    .checkPrecision()
});

FreezeVariablesItem.generalForm = mapFormFieldsFor(FreezeVariablesItem, [
  "monthsUntilSlow",
  "monthsUntilFrozen",
  "percentOfSlowCost",
  "frozenFee",
  "wakeUpFee",
  "minButtonFee"
]);
