import React from "react";

import { ThemeProvider } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";
import { DckActionCreators } from "dck-redux";
import { SnackbarProvider } from "notistack";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";

import {
  Notifier,
  snackbarProps,
  stateToPropsForApp,
  Selectors,
  ItemTypeApplication
} from "srx-shared";
import {
  AppBar,
  SideBar,
  AppLayout,
  ErrorBoundary,
  withNewVersionAvailable,
  ReloadAppMessage,
  VersionDiscrepancyHardReloadMessage
} from "srx-shared/mui";

import { pages } from "@routes/pages";
import { PATH_TO_ASSETS } from "config";

import { menuItems } from "./menuItems";
import { styles } from "./styles";
import { theme } from "./theme";

class App extends React.Component {
  static propTypes = {
    newVersionAvailable: PropTypes.bool,
    user: PropTypes.object,
    pageTitle: PropTypes.any,
    pageBar: PropTypes.any,
    checkAuth: PropTypes.func
  };

  componentDidMount() {
    this.props.checkAuth();
  }

  renderMessageBar = () => <ReloadAppMessage />;

  renderAppBar = () => <AppBar title={this.props.pageTitle} />;

  renderPageBar = () => this.props.pageBar || null;

  renderSideBar = ({ state, toggleSideBar }) => (
    <SideBar
      withMessageBar={this.props.newVersionAvailable}
      open={state.open}
      onClose={toggleSideBar}
      UserProps={{
        userInfo: this.props.user,
        style: {
          height: 48,
          minHeight: 48
        }
      }}
      NavProps={{
        items: menuItems,
        signOut: pages.signOut,
        support: pages.support
      }}
      LogoProps={{
        logo: `${PATH_TO_ASSETS}/srx-logo.svg`,
        icon: `${PATH_TO_ASSETS}/srx-icon.svg`
      }}
    />
  );

  snackbarRef = React.createRef();

  render() {
    const { children, user } = this.props;
    if (!user) return null;

    return (
      <ThemeProvider theme={theme}>
        <SnackbarProvider {...snackbarProps(this.snackbarRef)}>
          <VersionDiscrepancyHardReloadMessage />
          <AppLayout
            withMessageBar={this.props.newVersionAvailable}
            renderMessageBar={this.renderMessageBar}
            renderAppBar={this.renderAppBar}
            renderPageBar={this.renderPageBar}
            renderSideBar={this.renderSideBar}
          >
            <ErrorBoundary homePath="/distributors">{children}</ErrorBoundary>
            <Notifier />
          </AppLayout>
        </SnackbarProvider>
      </ThemeProvider>
    );
  }
}

const mapStateToProps = state => ({
  ...stateToPropsForApp(state),
  user: Selectors.getItemData(state, ItemTypeApplication, "user")
});

const mapDispatchToProps = {
  checkAuth: () => DckActionCreators.checkAuthenticated()
};

export default compose(
  withNewVersionAvailable,
  withWidth(),
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(App);
