import React from "react";

import Grid from "@material-ui/core/Grid";
import { DckActionCreators, DckSelectors } from "dck-redux";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { compose } from "redux";

import { withPageTitle } from "srx-shared";
import { ExpansionPanel } from "srx-shared/mui";

import { CostSavingsItem } from "@items/CostSavings";
import {
  SrxVmiOperationalCosts,
  TraditionalVmiOperationalCosts,
  DistributorInventory,
  SuggestedMinMaxFormula,
  OperationalCosts,
  SupplyForce
} from "@items/types";
import { pages } from "@routes/pages";

class CostSavingsComponent extends React.PureComponent {
  static propTypes = {
    getData: PropTypes.func,
    resetData: PropTypes.func,
    updateData: PropTypes.func
  };

  componentDidMount() {
    const { getData, resetData } = this.props;
    resetData(SrxVmiOperationalCosts);
    resetData(TraditionalVmiOperationalCosts);
    resetData(DistributorInventory);
    resetData(SuggestedMinMaxFormula);
    resetData(OperationalCosts);
    resetData(SupplyForce);

    getData(SrxVmiOperationalCosts);
    getData(TraditionalVmiOperationalCosts);
    getData(DistributorInventory);
    getData(SuggestedMinMaxFormula);
    getData(OperationalCosts);
    getData(SupplyForce);
  }

  handleSubmit = (section, data) => {
    const itemType = section.charAt(0).toUpperCase() + section.slice(1);
    this.props.updateData(itemType, data);
  };

  renderSection = ({ title, section, Form }) => {
    const data = this.props[`${section}Data`];
    const updating = this.props[`${section}Updating`];
    if (!data) return null;

    return (
      <Grid key={section} item xs={12} style={{ maxWidth: 800 }}>
        <ExpansionPanel title={title} defaultExpanded>
          <Form
            data={data}
            updating={updating}
            onSubmit={data => this.handleSubmit(section, data)}
            useDefaults={null}
          />
        </ExpansionPanel>
      </Grid>
    );
  };

  render() {
    const { sections, sectionsConfig } = CostSavingsItem;
    return (
      <div style={{ display: "flex", width: "100%" }}>
        <Grid container spacing={2} justify="center">
          {sections.map(section => this.renderSection(sectionsConfig[section]))}
        </Grid>
      </div>
    );
  }
}

const getSelector = state => itemType =>
  DckSelectors.getItemData(state, itemType, "data");

const getSelectorProcessRunning = state => itemType =>
  DckSelectors.selectProcessRunning(state, `${itemType.toUpperCase()}_UPDATE`);

const mapStateToProps = state => {
  const selector = getSelector(state);
  const selectorProcessRunning = getSelectorProcessRunning(state);
  return {
    srxVmiOperationalCostsData: selector(SrxVmiOperationalCosts),
    traditionalVmiOperationalCostsData: selector(
      TraditionalVmiOperationalCosts
    ),
    distributorInventoryData: selector(DistributorInventory),
    suggestedMinMaxFormulaData: selector(SuggestedMinMaxFormula),
    operationalCostsData: selector(OperationalCosts),
    supplyForceData: selector(SupplyForce),
    srxVmiOperationalCostsUpdating: selectorProcessRunning(
      SrxVmiOperationalCosts
    ),
    traditionalVmiOperationalCostsUpdating: selectorProcessRunning(
      TraditionalVmiOperationalCosts
    ),
    distributorInventoryUpdating: selectorProcessRunning(DistributorInventory),
    suggestedMinMaxFormulaUpdating: selectorProcessRunning(
      SuggestedMinMaxFormula
    ),
    operationalCostsUpdating: selectorProcessRunning(OperationalCosts),
    supplyForceUpdating: selectorProcessRunning(SupplyForce)
  };
};

const mapDispatchToProps = {
  getData: itemType => DckActionCreators.itemSelect(itemType),
  updateData: (itemType, data) =>
    DckActionCreators.itemSave(itemType, null, data),
  resetData: itemType => DckActionCreators.setItemData(itemType, "data", null)
};

export default compose(
  withPageTitle(pages.costSavings.title),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(CostSavingsComponent);
