import React from "react";

import { DialogContent } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import RefreshIcon from "@material-ui/icons/Refresh";
import { DckActionCreators, DckSelectors } from "dck-redux";
import * as PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";

import { IconButton, ModalDialog } from "srx-shared/mui";

import { IoTHubAccessKey, IoTHubImage } from "@items/types";
import { DEVICE_TYPE, PROVISION_HELP_TEXT } from "config";

import { styles } from "./styles";

class HelpDialog extends React.PureComponent {
  static propTypes = {
    item: PropTypes.object,
    show: PropTypes.bool,
    iotHubImageUrl: PropTypes.string,
    onClose: PropTypes.func,
    accessKey: PropTypes.string,
    accessKeyLoading: PropTypes.bool,
    generateAccessKey: PropTypes.func,
    removeAccessKey: PropTypes.func,
    getIotHubImageUrl: PropTypes.func,
    classes: PropTypes.object
  };

  componentDidMount() {
    this.props.getIotHubImageUrl();
  }

  handleRegenerateKey = () => {
    const { item, generateAccessKey } = this.props;
    generateAccessKey({ id: item.value });
  };

  handleClose = () => {
    const { onClose, removeAccessKey } = this.props;
    removeAccessKey();
    onClose && onClose();
  };

  renderAccessKeyBlock = () => {
    const { classes, accessKey, accessKeyLoading } = this.props;
    return (
      <div className={classes.accessKeyBlock}>
        Access Key:
        <span style={{ fontWeight: "bold", marginLeft: 4 }}>
          {accessKey || "************"}
        </span>
        {!accessKey && (
          <IconButton
            icon={<RefreshIcon />}
            tip="Regenerate key"
            onClick={this.handleRegenerateKey}
            disabled={accessKeyLoading}
            data-testid="regenerate"
          />
        )}
      </div>
    );
  };

  render() {
    const { item, show, iotHubImageUrl, classes } = this.props;
    const deviceName = `${item.id}${
      item.deviceName ? ` / ${item.deviceName}` : ""
    }`;

    return (
      <ModalDialog
        open={show}
        onClose={this.handleClose}
        title={`${DEVICE_TYPE[item.type]} provision information`}
        size="sm"
      >
        <DialogContent classes={{ root: classes.dialogContent }}>
          <div>
            {PROVISION_HELP_TEXT[item.type](iotHubImageUrl, deviceName)}
          </div>
          <div className={classes.block}>
            <span className={classes.serialNumber}>{item.value}</span>
            {this.renderAccessKeyBlock()}
          </div>
        </DialogContent>
      </ModalDialog>
    );
  }
}

const process = `${IoTHubAccessKey.toUpperCase()}_LOAD`;

const mapStateToProps = state => ({
  accessKey: DckSelectors.getItemData(state, IoTHubAccessKey, "key"),
  accessKeyLoading: DckSelectors.selectProcessRunning(state, process),
  iotHubImageUrl: DckSelectors.getItemData(state, IoTHubImage, "downloadUrl")
});

const mapDispatchToProps = {
  generateAccessKey: params =>
    DckActionCreators.itemsLoad(IoTHubAccessKey, params),
  removeAccessKey: () =>
    DckActionCreators.setItemData(IoTHubAccessKey, "key", null),
  getIotHubImageUrl: () => DckActionCreators.itemsLoad(IoTHubImage)
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(HelpDialog);
