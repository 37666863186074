export const styles = theme => ({
  divider: {
    margin: "6px 0 !important",
    backgroundColor: "rgba(0, 0, 0, 0.08)"
  },
  root: {
    color: theme.palette.primary.main,
    fontSize: 14,
    height: 44,
    paddingLeft: 24,
    "&.-selected": {
      fontWeight: "bold",
      color: theme.palette.primary.light,
      backgroundColor: theme.color.grey
    },
    "&:hover": {
      color: theme.palette.primary.main,
      backgroundColor: theme.color.grey
    }
  },
  icon: {
    color: "inherit",
    minWidth: "40px !important",
    "& svg": {
      fontSize: 20
    }
  },
  collapsed: {
    paddingLeft: 16
  }
});
