import { DckSelectors, DckActionCreators } from "dck-redux";
import { all, takeEvery, takeLatest, select, put } from "redux-saga/effects";

import {
  Process,
  isActionLoad,
  isActionAdd,
  isActionUpdate,
  isActionDelete,
  isActionSelect,
  Formatters,
  Selectors,
  ItemTypeApplication
} from "srx-shared";

import { ActionCreators, ActionTypes } from "@actions";
import * as RestApi from "@api";
import { Hardware, IoTHub, HardwareStates } from "@items/types";
import { DEVICE_TYPE } from "config";

import { getIoTHubAccessKeySaga } from "./devicesSaga";
import { mockResponse } from "./mocks";

export function* getCurrentHardware() {
  return yield select(state => DckSelectors.selectActiveItem(state, Hardware));
}

function* getIotHubsByType(type) {
  const correctedType = Formatters.uppercaseFirstLetter(type);
  const iotHubs = yield select(state =>
    Selectors.getItemData(state, IoTHub, `iotHubsFor${correctedType}`)
  );
  return iotHubs || [];
}

function* updateIoTHubsDict() {
  yield put(DckActionCreators.itemsLoad(IoTHub, DEVICE_TYPE.CAMERA));
  yield put(DckActionCreators.itemsLoad(IoTHub, DEVICE_TYPE.LOCKER));
  yield put(DckActionCreators.itemsLoad(IoTHub, DEVICE_TYPE.STORAGE));
  yield put(DckActionCreators.itemsLoad(IoTHub, DEVICE_TYPE.VENDING));
}

export function* loadHardwaresSaga(action) {
  const proc = new Process.Load(Hardware, RestApi.ListHardware, {
    pageble: true,
    mockResponse
  });
  yield proc.start();
  try {
    const page = yield proc.page();
    const pageSize = yield proc.pageSize();
    let sorting = yield proc.sorting();
    let filters = yield proc.filters();
    if (sorting[0]?.name === "hardwareVersion") {
      sorting = [{ name: "version", order: sorting[0].order }];
    }
    yield proc.callApi(page + 1, pageSize, filters, sorting);
    if ((action || {}).item) {
      const data = [...proc.data.entities];
      const foundedItem = data.find(el => el.id === action.item.id);
      if (!foundedItem) {
        data.push(action.item);
      } else {
        foundedItem.status = "Pending";
        foundedItem.statusDescription = "The IoT hub not been provisioned yet";
      }
      yield proc.set(data);
    } else {
      yield proc.setEntities();
    }
    yield proc.setTotalPages();
    yield proc.stop();
    yield updateIoTHubsDict();
  } catch (ex) {
    yield proc.fail(ex);
  }
}

function* getHardwareSaga(action) {
  const proc = new Process.Load(Hardware, RestApi.GetHardware);
  yield proc.start();
  try {
    yield proc.callApi(action.id);
    const data = proc.data;
    yield proc.set(data, data.id);
    yield proc.makeActive(data.id);
    yield proc.stop();
  } catch (ex) {
    yield proc.fail(ex);
  }
}

function* getHardwareStatesSaga(action) {
  const proc = new Process.Load(HardwareStates, RestApi.GetDeviceState);
  yield proc.start();
  try {
    yield proc.callApi({ id: action.id });
    yield proc.set(proc.data, 1);
    yield proc.makeActive(1);
    yield proc.stop();
  } catch (ex) {
    yield proc.fail(ex);
  }
}

function* renewHardwareStateSaga(action) {
  const proc = new Process.Update(HardwareStates, RestApi.RenewDeviceState);
  yield proc.start();
  try {
    const { id, value } = action.data;
    yield proc.callApi({ id: value });
    yield proc.stop();
    yield getHardwareSaga({ id });
    yield getHardwareStatesSaga({ id: value });
  } catch (ex) {
    yield proc.fail(ex);
  }
}

function* addHardwareSaga(action) {
  const proc = new Process.Add(Hardware, RestApi.AddHardware);
  yield proc.start();
  try {
    const {
      type,
      hardwareVersion,
      distributorId,
      deviceId,
      iotHub,
      lockerType
    } = action.data;

    if (!distributorId) {
      action.data.distributorId = null;
      action.data.distributorName = null;
    }

    if (!deviceId) {
      action.data.deviceId = null;
      action.data.deviceName = null;
    }

    if (!(iotHub || {}).id) action.data.iotHub = null;

    if (type === "LOCKER" && !(lockerType || {}).id) {
      action.data.lockerType = null;
    }

    if (type !== "LOCKER" || hardwareVersion !== "v2") {
      action.data.metadata = null;
    }

    yield proc.callApi(action.data);

    if (type === "IOTHUB") {
      const data = { ...proc.data };
      data.status = "Pending";
      data.statusDescription = "The IoT hub not been provisioned yet";
      yield proc.set(data, data.id);
      yield proc.makeActive(data.id);
      yield getIoTHubAccessKeySaga({ data: { id: data.value } });
      yield loadHardwaresSaga({ item: proc.data });
    } else {
      yield loadHardwaresSaga();
    }
    yield proc.stop();
  } catch (ex) {
    yield proc.fail(ex);
  }
}

function* updateHardwareSaga(action) {
  const proc = new Process.Update(Hardware, RestApi.UpdateHardware);
  yield proc.start();
  try {
    const {
      type,
      hardwareVersion,
      distributorId,
      deviceId,
      iotHub,
      lockerType
    } = action.data;

    if (!distributorId) {
      action.data.distributorId = null;
      action.data.distributorName = null;
    }

    if (!deviceId) {
      action.data.deviceId = null;
      action.data.deviceName = null;
    }

    if (!(iotHub || {}).id) action.data.iotHub = null;

    if (!(lockerType || {}).id) action.data.lockerType = null;

    if (type !== "LOCKER" || hardwareVersion !== "v2") {
      action.data.metadata = null;
    }

    yield proc.callApi(action.data);
    yield proc.stop();
    yield loadHardwaresSaga();
  } catch (ex) {
    yield proc.fail(ex);
  }
}

function* deleteHardwareSaga(action) {
  const proc = new Process.Delete(Hardware, RestApi.RemoveHardware);
  yield proc.start();
  try {
    yield proc.callApi(action.id);
    yield proc.stop();
    yield loadHardwaresSaga();
  } catch (ex) {
    yield proc.fail(ex);
  }
}

const iotHubLabel = hub => {
  const { id = "", deviceName = "", value = "", distributorName = "" } = hub;
  const name = deviceName ? `${deviceName} (${value})` : `(${value})`;
  const distributor = distributorName ? `/ ${distributorName}` : "";

  return [id, name, distributor].filter(Boolean).join(" ");
};

export const iotHubDict = hubs =>
  (hubs || []).map(hub => ({
    label: iotHubLabel(hub),
    value: hub.id
  }));

function* initDataSaga() {
  const hardware = yield getCurrentHardware();
  if (!hardware) return;

  if (["CAMERA", "LOCKER", "STORAGE", "VENDING"].includes(hardware.type)) {
    const { type, iotHub } = hardware;
    let iotHubs = yield getIotHubsByType(type);
    if (iotHub && !iotHubs.some(el => el.id === iotHub.id)) {
      iotHubs = [iotHub, ...iotHubs];
    }
    yield put(
      ActionCreators.setItemData(
        ItemTypeApplication,
        "iotHubDict",
        iotHubDict(iotHubs)
      )
    );
  }
}

function* HardwareSaga() {
  yield all([
    takeEvery(isActionLoad(Hardware), loadHardwaresSaga),
    takeEvery(isActionSelect(Hardware), getHardwareSaga),
    takeEvery(isActionDelete(Hardware), deleteHardwareSaga),
    takeEvery(isActionAdd(Hardware), addHardwareSaga),
    takeEvery(isActionUpdate(Hardware), updateHardwareSaga),
    takeEvery(
      action =>
        action.type === ActionTypes.SHOW_EDIT_MODAL &&
        action.itemType === Hardware,
      initDataSaga
    ),
    takeLatest(isActionSelect(HardwareStates), getHardwareStatesSaga),
    takeEvery(isActionUpdate(HardwareStates), renewHardwareStateSaga)
  ]);
}

export default HardwareSaga;
