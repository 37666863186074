import { takeLatest, all, put } from "redux-saga/effects";

import {
  Process,
  isActionLoad,
  isActionSelect,
  isActionUpdate,
  ItemTypeApplication
} from "srx-shared";

import { ActionCreators } from "@actions";
import * as RestApi from "@api";
import { Camera } from "@items/types";
import { NotificationCreators } from "@utils/notifications";

export function* loadConfigurableCamera(action) {
  const proc = new Process.Load(Camera, RestApi.GetListOfConfigurableCamera);
  yield proc.start();
  try {
    yield put(
      ActionCreators.setItemData(ItemTypeApplication, "cameraDict", [])
    );
    yield proc.callApi({ id: action.filteringOptions.id });
    const cameraDict = proc.data.map(el => ({
      label: `${el.name} (${el.ip})`,
      value: el.ip
    }));
    yield proc.set(proc.data);
    yield put(
      ActionCreators.setItemData(ItemTypeApplication, "cameraDict", cameraDict)
    );
    yield proc.stop();
  } catch (ex) {
    yield proc.fail(ex);
  }
}

export function* configureCameraSaga(action) {
  const proc = new Process.Update(Camera, RestApi.ConfigureCamera);
  yield proc.start();
  try {
    yield proc.callApi(action.data);
    yield put(
      ActionCreators.pushNotification(
        NotificationCreators.successCameraConfig(
          "The camera has been configured"
        )
      )
    );
    yield proc.stop();
  } catch (ex) {
    yield proc.fail(ex);
  }
}

export function* getCameraConfigStatusSaga(action) {
  const proc = new Process.Load(Camera, RestApi.GetCameraConfigStatus);
  yield proc.start();
  try {
    //yield proc.callApi({id: action.id});
    //MOCK
    proc.data = { status: "success" };
    yield proc.setItemData("status", proc.data.status);
    yield proc.stop();
  } catch (ex) {
    yield proc.fail(ex);
  }
}

function* cameraConfigSaga() {
  yield all([
    takeLatest(isActionLoad(Camera), loadConfigurableCamera),
    takeLatest(isActionSelect(Camera), getCameraConfigStatusSaga),
    takeLatest(isActionUpdate(Camera), configureCameraSaga)
  ]);
}

export default cameraConfigSaga;
