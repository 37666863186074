export const getAvoidDotsError = (currentValue, originalValue) => {
  const notValid = originalValue && String(originalValue).match(/\d+\./);
  return notValid ? originalValue : currentValue;
};

export const checkPrecision = (value, precision = 8) => {
  let count = 0;
  if (String(value).includes("e-")) {
    const [num, pow] = String(value).split("e-");
    count += Number(pow);
    if (String(num).includes(".")) {
      const fraction = String(num).split(".")[1];
      count += fraction.length;
    }
  } else if (String(value).includes(".")) {
    const fraction = String(value).split(".")[1];
    count += fraction.length;
  }
  return count <= precision;
};
