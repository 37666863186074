import React from "react";

import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import withStyles from "@material-ui/core/styles/withStyles";
import { Formik, yupToFormErrors, validateYupSchema } from "formik";
import _get from "lodash/get";
import _set from "lodash/set";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";

import { withPermissions } from "../../acl";
import * as Selectors from "../../actions/selectors";
import { DckSelectors, DckActionCreators } from "../../redux";
//import { getItemData } from "../../actions/selectors";
import { isObject, isEmptyObject } from "../../utils";
import { ExpansionPanel } from "../ExpansionPanel";
import { ModalDialog } from "../ModalDialog";
import { ActionsSpinner } from "./ActionsSpinner";
import { ActionsWrapper } from "./ActionsWrapper";
import { OptionsField } from "./OptionsField";
import { SwitchField } from "./SwitchField";
import { styles } from "./styles";

import { Form } from "./index";

export const validateForm = schema => values =>
  !isEmptyObject(schema) &&
  validateYupSchema(values, schema, false, values).then(
    () => null,
    errors => yupToFormErrors(errors)
  );

const FormContent = withStyles(styles)(({ classes, children, ...rest }) => (
  <DialogContent classes={{ root: classes.formContent }} {...rest}>
    {children}
  </DialogContent>
));

export const settingsFormFor = form => {
  class Wrapper extends React.Component {
    static propTypes = {
      section: PropTypes.string,
      onSubmit: PropTypes.func,
      data: PropTypes.object,
      defaultValues: PropTypes.object,
      useDefaults: PropTypes.bool,
      readOnly: PropTypes.bool,
      updating: PropTypes.bool
    };

    static defaultProps = {
      section: "",
      onSubmit: () => {},
      useDefaults: false,
      defaultValues: {}
    };

    state = {
      useDefaults: this.props.useDefaults
    };

    componentDidUpdate(prevProps) {
      if (prevProps.useDefaults !== this.props.useDefaults)
        this.setState({ useDefaults: this.props.useDefaults });
    }

    handleUseDefaults = form => {
      const { defaultValues } = this.props;
      const { useDefaults } = this.state;
      if (!useDefaults) {
        Object.keys(defaultValues).forEach(key =>
          form.props.setFieldValue(key, defaultValues[key])
        );
      }
      this.setState({ useDefaults: !useDefaults });
    };

    renderActions = form => {
      const { readOnly, updating } = this.props;
      const { useDefaults } = this.state;
      return (
        <ActionsWrapper
          processing={updating}
          disabled={useDefaults || !form.props.dirty}
          readOnly={readOnly}
        >
          {useDefaults !== null && (
            <SwitchField
              label="Use defaults"
              onChange={() => this.handleUseDefaults(form)}
              value={useDefaults}
            />
          )}
        </ActionsWrapper>
      );
    };

    render() {
      const { section, onSubmit, data, defaultValues, readOnly } = this.props;
      const { useDefaults } = this.state;
      const id = [section, form.item.type.toLowerCase(), "settings-form"]
        .filter(Boolean)
        .join("-");

      return (
        <div
          style={{
            display: "flex",
            flexGrow: 1,
            flexDirection: "column",
            marginTop: "-8px",
            padding: "0 16px 4px 24px"
          }}
        >
          <Formik
            enableReinitialize
            initialValues={useDefaults ? defaultValues : data}
            onSubmit={data => onSubmit(data, useDefaults)}
            validate={validateForm(form.schema)}
          >
            {props => (
              <Form
                {...props}
                form={form}
                useDefaults={useDefaults}
                readOnly={readOnly}
                defaultValues={defaultValues}
                renderActions={this.renderActions}
                id={id}
              />
            )}
          </Formik>
        </div>
      );
    }
  }
  return Wrapper;
};

export const settingsFormWithMultipleDefaultsFor = form => {
  class Wrapper extends React.Component {
    static propTypes = {
      onSubmit: PropTypes.func,
      data: PropTypes.object,
      useValues: PropTypes.string,
      readOnly: PropTypes.bool,
      updating: PropTypes.bool,
      defaultsSettings: PropTypes.array
    };

    static defaultProps = {
      onSubmit: () => {},
      useValues: "",
      defaultsSettings: []
    };

    getUseValues = () =>
      (this.props.defaultsSettings || []).find(
        setting => setting.useValues === this.props.useValues
      ) || {};

    state = {
      useValues: this.props.useValues,
      valuesUsed: this.getUseValues().field
    };

    componentDidUpdate(prevProps) {
      if (prevProps.useValues !== this.props.useValues) {
        const valueToUse = this.getUseValues();

        this.setState({
          useValues: this.props.useValues,
          valuesUsed: valueToUse.field
        });
      }
    }

    handleChangeDefaults = (value, form) => {
      const { data, defaultsSettings } = this.props;
      const { valuesUsed } = this.state;

      if (value && value !== valuesUsed) {
        Object.keys(data[value]).forEach(key =>
          form.props.setFieldValue(key, data[value][key])
        );
        const valueToUse =
          (defaultsSettings || []).find(setting => setting.field === value) ||
          {};

        this.setState({ valuesUsed: value, useValues: valueToUse.useValues });
      }
    };

    renderActions = form => {
      const { readOnly, updating, defaultsSettings } = this.props;
      const { valuesUsed, useValues } = this.state;
      return (
        <ActionsWrapper
          processing={updating}
          disabled={(useValues && useValues !== "CURRENT") || !form.props.dirty}
          readOnly={readOnly}
        >
          {defaultsSettings && (
            <OptionsField
              value={valuesUsed}
              options={defaultsSettings.map(setting => {
                return { ...setting, value: setting.field };
              })}
              noHelperText={true}
              onChange={value => this.handleChangeDefaults(value, form)}
            />
          )}
        </ActionsWrapper>
      );
    };

    render() {
      const { onSubmit, data, readOnly } = this.props;
      const { valuesUsed, useValues } = this.state;
      return (
        <div
          style={{
            display: "flex",
            flexGrow: 1,
            flexDirection: "column",
            marginTop: "-8px",
            padding: "0 16px 4px 24px"
          }}
        >
          <Formik
            enableReinitialize
            initialValues={valuesUsed ? data[valuesUsed] : data.currentValues}
            validationSchema={form.schema}
            onSubmit={data => onSubmit(data, useValues)}
          >
            {props => (
              <Form
                {...props}
                form={form}
                useDefaults={useValues && useValues !== "CURRENT"}
                readOnly={readOnly}
                defaultValues={data.defaultValues}
                renderActions={this.renderActions}
                id={`${form.item.type.toLowerCase()}-settings-form`}
              />
            )}
          </Formik>
        </div>
      );
    }
  }
  return Wrapper;
};

export const settingsSectionsFor = item => {
  class Wrapper extends React.Component {
    static propTypes = {
      updating: PropTypes.bool,
      settings: PropTypes.object,
      onSubmit: PropTypes.func,
      acls: PropTypes.object,
      hasPermission: PropTypes.func
    };
    static defaultProps = {
      onSubmit: () => {}
    };

    state = {
      activeSection: null
    };

    componentDidUpdate(prevProps) {
      if (prevProps.updating && !this.props.updating) {
        this.setState({ activeSection: null });
      }
    }

    handleSubmit = (section, data, useDefault) => {
      this.setState({ activeSection: section });
      this.props.onSubmit(section, data, useDefault);
    };

    renderSection = ({ title, section, Form, readOnly, acl }) => {
      const { settings, updating, hasPermission } = this.props;
      const data = settings[section];
      return data && (!acl || hasPermission(acl, "VIEW")) ? (
        <Grid
          key={section}
          item
          xs={12}
          style={{ maxWidth: 800, width: "100%" }}
        >
          <ExpansionPanel title={title}>
            <Form
              section={section}
              data={data.settings || data}
              useDefaults={"useDefault" in data ? data.useDefault : null}
              readOnly={
                readOnly || (acl && !hasPermission(acl, "EDIT")) || false
              }
              defaultValues={data.defaultSettings}
              updating={updating && section === this.state.activeSection}
              onSubmit={(data, useDefault) =>
                this.handleSubmit(section, data, useDefault)
              }
            />
          </ExpansionPanel>
        </Grid>
      ) : null;
    };
    render() {
      const { settings, children } = this.props;
      const { sections, sectionsConfig } = item;
      return settings ? (
        <Grid container item justify="center" spacing={2}>
          {sections.map(section => this.renderSection(sectionsConfig[section]))}
          {children}
        </Grid>
      ) : null;
    }
  }

  const process = `${item.type.toUpperCase()}_UPDATE`;

  const mapStateToProps = state => ({
    updating: DckSelectors.selectProcessRunning(state, process)
  });

  return compose(withPermissions(), connect(mapStateToProps, null))(Wrapper);
};

export const editFormFor = item => {
  const editForm = item["editForm"];
  const inModal = !!item["modalEditForm"];
  class Wrapper extends React.Component {
    static propTypes = {
      itemData: PropTypes.object,
      readOnly: PropTypes.bool,
      updateItem: PropTypes.func,
      onClose: PropTypes.func,
      labelCancel: PropTypes.string,
      onReset: PropTypes.func,
      labelReset: PropTypes.string,
      updating: PropTypes.bool,
      updatingSuccess: PropTypes.bool
    };

    componentDidUpdate(prevProps) {
      const { updating, updatingSuccess, onClose } = this.props;
      if (prevProps.updating && !updating && updatingSuccess && onClose)
        onClose();
    }

    renderActions = (form, formikProps) => {
      const { readOnly, onClose, updating, labelCancel, onReset, labelReset } =
        this.props;
      const { dirty, values } = formikProps;

      const isEmpty = editForm.fields.every(field => {
        const value = _get(values, field.name);
        return value === null || value === "";
      });

      return !readOnly ? (
        <DialogActions style={{ margin: "24px 4px 8px" }}>
          <ActionsSpinner processing={updating} />
          {!inModal && (
            <Button
              type="reset"
              color="default"
              variant="contained"
              onClick={onReset ? onReset : () => {}}
            >
              {labelReset || "Reset"}
            </Button>
          )}
          {inModal && (
            <Button
              type="reset"
              color="default"
              variant="contained"
              onClick={onClose ? onClose : () => {}}
            >
              {labelCancel || "Cancel"}
            </Button>
          )}
          <Button
            type="submit"
            color="primary"
            variant="contained"
            disabled={!dirty || isEmpty || updating}
          >
            Save
          </Button>
        </DialogActions>
      ) : null;
    };

    render() {
      const { itemData, updateItem, updating, readOnly } = this.props;
      return (
        itemData && (
          <Formik
            enableReinitialize
            initialValues={itemData || {}}
            onSubmit={data => updateItem((itemData || {}).id, data)}
            validate={validateForm(editForm.schema)}
          >
            {formikProps => (
              <Form
                {...formikProps}
                readOnly={readOnly}
                form={editForm}
                Component={inModal ? DialogContent : null}
                renderActions={form => this.renderActions(form, formikProps)}
                processing={inModal ? updating : null}
                id={`${item.type.toLowerCase()}-edit-form`}
              />
            )}
          </Formik>
        )
      );
    }
  }

  const process = `${item.type.toUpperCase()}_UPDATE`;

  const mapStateToProps = state => ({
    itemData: Selectors.selectActiveItem(state, item.type),
    updating: DckSelectors.selectProcessRunning(state, process),
    updatingSuccess: DckSelectors.selectProcessSuccess(state, process)
  });

  const dispatchStateToProps = {
    updateItem: (id, data) => DckActionCreators.itemSave(item.type, id, data)
  };

  return connect(mapStateToProps, dispatchStateToProps)(Wrapper);
};

export const createFormFor = (
  item,
  {
    title = "",
    size = "md",
    checkDirty = true,
    labelCreate = "Add",
    labelIteration = "Batch Add",
    labelCancel = "Cancel"
  } = {}
) => {
  const createForm = item["createForm"];
  class Wrapper extends React.Component {
    static propTypes = {
      initialValues: PropTypes.object,
      adding: PropTypes.bool,
      addingSuccess: PropTypes.bool,
      addItem: PropTypes.func,
      open: PropTypes.bool,
      onCreate: PropTypes.func,
      onClose: PropTypes.func,
      onIteration: PropTypes.func
    };

    handleIteration = form => {
      const { onIteration } = this.props;
      onIteration && onIteration(form);
    };

    componentDidUpdate(prevProps) {
      const { adding, addingSuccess, onClose, onIteration } = this.props;
      if (prevProps.adding && !adding && addingSuccess && !onIteration) {
        onClose();
      }
    }

    renderActions = (form, formikProps) => {
      const { adding, onCreate, onClose, onIteration } = this.props;
      const { dirty } = formikProps;
      return (
        <DialogActions style={{ margin: "24px 4px 8px" }}>
          <ActionsSpinner processing={adding} />
          <Button onClick={onClose} color="default" variant="contained">
            {labelCancel}
          </Button>
          {onIteration ? (
            <Button
              type="submit"
              color="primary"
              variant="contained"
              disabled={adding}
              onClick={() => this.handleIteration(form)}
            >
              {labelIteration}
            </Button>
          ) : null}
          <Button
            type="submit"
            color="secondary"
            variant="contained"
            disabled={(checkDirty && !dirty) || adding}
            onClick={() => onCreate && onCreate()}
          >
            {labelCreate}
          </Button>
        </DialogActions>
      );
    };

    render() {
      const { open, onClose, initialValues, addItem, adding } = this.props;
      return (
        <ModalDialog open={open} onClose={onClose} title={title} size={size}>
          <Formik
            initialValues={initialValues || createForm.initialValues}
            onSubmit={addItem}
            validate={validateForm(createForm.schema)}
          >
            {formikProps => (
              <Form
                {...formikProps}
                form={createForm}
                Component={FormContent}
                renderActions={form => this.renderActions(form, formikProps)}
                processing={adding}
                id={`${item.type.toLowerCase()}-create-form`}
              />
            )}
          </Formik>
        </ModalDialog>
      );
    }
  }

  const process = `${item.type.toUpperCase()}_ADD`;

  const mapStateToProps = state => ({
    adding: DckSelectors.selectProcessRunning(state, process),
    addingSuccess: DckSelectors.selectProcessSuccess(state, process)
  });

  const dispatchStateToProps = {
    addItem: data => DckActionCreators.itemAdd(item.type, data)
  };

  return connect(mapStateToProps, dispatchStateToProps)(Wrapper);
};

export const generalFormFor = (item, form) => {
  if (!form) form = item["generalForm"];
  class Wrapper extends React.PureComponent {
    static propTypes = {
      initialData: PropTypes.any,
      itemData: PropTypes.object,
      readOnly: PropTypes.bool,
      renderActions: PropTypes.func,
      InnerContainer: PropTypes.any,
      onSubmit: PropTypes.func,
      processing: PropTypes.bool,
      vertical: PropTypes.bool,
      spacing: PropTypes.number,
      innerRef: PropTypes.object
    };

    static defaultProps = {
      renderActions: () => {}
    };

    handleSubmit = data => this.props.onSubmit && this.props.onSubmit(data);

    render() {
      const {
        initialData,
        itemData,
        vertical,
        renderActions,
        InnerContainer,
        readOnly,
        processing,
        spacing,
        innerRef
      } = this.props;

      return (
        <Formik
          enableReinitialize
          initialValues={initialData || itemData || {}}
          validate={validateForm(form.schema)}
          onSubmit={this.handleSubmit}
          innerRef={innerRef}
        >
          {formikProps => (
            <Form
              {...formikProps}
              vertical={vertical}
              readOnly={readOnly}
              form={form}
              InnerContainer={InnerContainer}
              renderActions={form => renderActions(form, formikProps)}
              processing={processing}
              id={`${item.type.toLowerCase()}-general-form`}
              spacing={spacing}
            />
          )}
        </Formik>
      );
    }
  }

  const mapStateToProps = state => ({
    itemData: Selectors.selectActiveItem(state, item.type)
  });

  return connect(mapStateToProps, null)(Wrapper);
};

export const mapFormFieldsFor = (item, formConfig) => {
  let names, groups;

  if (isObject(formConfig)) {
    names = formConfig.fields;
    groups = formConfig.groups;
  } else {
    names = formConfig;
  }

  const fields = (names || item.fields).map(name => ({
    name,
    ...item.fieldsConfig[name]
  }));

  const schema = item.schema || {};
  const initialValues = {};
  fields.forEach(f => _set(initialValues, f.name, f.initialValue));
  return { item, groups, fields, schema, initialValues };
};

export const mapInitialSettingsFor = item =>
  item
    ? item.sections.reduce((values, section) => {
        const { initialValues } = item.sectionsConfig[section].formConfig;
        return {
          ...values,
          [section]: {
            defaultSettings: initialValues,
            settings: initialValues
          }
        };
      }, {})
    : null;
