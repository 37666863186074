import React, { useState, useEffect } from "react";

import { Grid } from "@material-ui/core";
import { DckActionCreators, procUpdating } from "dck-redux";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import {
  breadcrumbsFor,
  Selectors,
  useDispatchActions,
  usePageTitle
} from "srx-shared";

import {
  SmartShelfDetails,
  LockerSettings,
  LockerConfig,
  LockerDoor
} from "@items/types";
import { pages } from "@routes/pages";

import { ActionsFooter } from "./ActionsFooter";
import { DoorSettings } from "./DoorSettings";
import { SmartShelfInfo } from "./SmartShelfInfo";
import { useStyles } from "./styles";

const BreadcrumbsComponent = breadcrumbsFor();

const { itemSelect, itemSave } = DckActionCreators;

const actions = {
  loadLocker: lockerId => itemSelect(LockerSettings, lockerId),
  loadLockerConfig: lockerId => itemSelect(LockerConfig, lockerId),
  loadSmartShelfDetails: id => itemSelect(SmartShelfDetails, id),
  updateLockerDoor: (id, data) => itemSave(LockerDoor, id, data)
};

export default function DoorConfig() {
  const { lockerId, doorId } = useParams();

  const deviceLink = `${pages.hardware.path}/locker/${lockerId}`;

  const title = `Door ${doorId}`;

  const { push } = useHistory();

  const cls = useStyles();

  usePageTitle(title);

  const {
    loadLocker,
    loadLockerConfig,
    loadSmartShelfDetails,
    updateLockerDoor
  } = useDispatchActions(actions);

  const device = useSelector(state =>
    Selectors.selectActiveItem(state, LockerSettings)
  );
  const deviceConfig = useSelector(state =>
    Selectors.selectActiveItem(state, LockerConfig)
  );
  const updating = useSelector(state => procUpdating(state, LockerDoor));

  const [values, setValues] = useState({});

  const section = deviceConfig?.data.find(el => el.number === Number(doorId));

  useEffect(() => {
    loadLocker(lockerId);
    loadLockerConfig(lockerId);
  }, [loadLocker, lockerId, loadLockerConfig]);

  useEffect(() => {
    if (section) {
      const { smartShelfHardware, weightPolling, debugMode } = section;
      const smartShelfId = smartShelfHardware?.id;
      smartShelfId && loadSmartShelfDetails(smartShelfId);
      setValues({ weightPolling, debugMode });
    }
  }, [section, loadSmartShelfDetails]);

  const handleCancel = () => push(deviceLink);

  const handleSave = () =>
    updateLockerDoor(device.id, {
      ...section,
      ...values
    });

  const handleUnassign = () => {
    const sectionConfig = { ...section, smartShelfHardware: { id: null } };
    updateLockerDoor(device.id, sectionConfig);
    push(deviceLink);
  };

  const breadcrumbsList = () => [
    { title: pages.hardware.title, to: pages.hardware.path },
    { title: "Planogram" },
    {
      title: device.value || "",
      to: deviceLink
    },
    { title },
    { title: "Door Settings" }
  ];

  if (!device) return null;

  return (
    <div className={cls.root}>
      <div className={cls.container}>
        <div className={cls.form}>
          <Grid
            container
            justify="center"
            spacing={2}
            direction="column"
            alignItems="center"
          >
            <BreadcrumbsComponent renderList={breadcrumbsList} />
            <DoorSettings
              door={section}
              locker={device}
              values={values}
              setValues={setValues}
            />
            {section?.smartShelfHardware && (
              <SmartShelfInfo
                section={section}
                values={values}
                setValues={setValues}
                address={section.number}
                onUnassign={handleUnassign}
                updating={updating}
              />
            )}
          </Grid>
        </div>
      </div>
      <ActionsFooter
        updating={updating}
        onSave={handleSave}
        onCancel={handleCancel}
      />
    </div>
  );
}
