import { useEffect, useState } from "react";

import {
  ExpansionPanel,
  ExpansionPanelSummary,
  DialogActions,
  Button,
  Grid,
  ExpansionPanelDetails,
  Typography
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Formik, yupToFormErrors, validateYupSchema } from "formik";
import PropTypes from "prop-types";
import { useContentful } from "react-contentful";

import { useDebounce } from "../../../hooks";
import { isEmptyObject } from "../../../utils";
import { ContentfulId } from "../../ContentfulId";
import { ActionsSpinner } from "../ActionsSpinner";
import { SwitchField } from "../SwitchField";
import { Form } from "../index";
import { InfoButton } from "./SettingsInfo/InfoButton";
import { WatchVideoButton } from "./SettingsInfo/WatchVideoButton";

const validateForm = schema => values =>
  !isEmptyObject(schema) &&
  validateYupSchema(values, schema, false, values).then(
    () => null,
    errors => yupToFormErrors(errors)
  );

SettingsCard.propTypes = {
  form: PropTypes.object,
  section: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  formConfig: PropTypes.object,
  contentfulId: PropTypes.string,
  onSubmit: PropTypes.func,
  data: PropTypes.object,
  defaultValues: PropTypes.object,
  useDefaults: PropTypes.bool,
  readOnly: PropTypes.bool,
  updating: PropTypes.bool,
  renderActions: PropTypes.func,
  onChange: PropTypes.func,
  defaultExpanded: PropTypes.bool,
  useDefaultsTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  disabledUseDefaults: PropTypes.bool
};

export function SettingsCard(props) {
  const {
    formConfig: form,
    title = "",
    section = "",
    onSubmit,
    onChange,
    useDefaults = false,
    defaultValues = {},
    updating,
    data,
    readOnly,
    contentfulId,
    renderActions,
    defaultExpanded,
    useDefaultsTitle = "Use Defaults",
    disabledUseDefaults = false
  } = props;

  const [isDefaults, setIsDefaults] = useState(useDefaults);
  const [isExpanded, setIsExpanded] = useState(Boolean(defaultExpanded));

  const debounceSubmit = useDebounce(onSubmit, 1000);

  const { data: contentfulData } = useContentful({
    contentType: "settingsCard",
    query: {
      "fields.id": contentfulId
    },
    skip: !contentfulId,
    parser: response =>
      !(response instanceof Promise) && response.items[0]?.fields
  });

  useEffect(() => {
    setIsDefaults(useDefaults);
  }, [useDefaults]);

  const handleUseDefaults = formikProps => {
    if (!isDefaults) {
      formikProps.setValues(defaultValues, true);
    }
    onSubmit && debounceSubmit({ ...defaultValues }, !isDefaults);
    setIsExpanded(true);
    setIsDefaults(!isDefaults);
  };

  const handleExpand = () => setIsExpanded(!isExpanded);

  const renderSwitch = formikProps =>
    isDefaults !== null && (
      <SwitchField
        style={{ marginTop: "5px" }}
        label={useDefaultsTitle}
        onChange={() => handleUseDefaults(formikProps)}
        value={isDefaults}
        disabled={disabledUseDefaults}
      />
    );

  const id = [section, form.item.type.toLowerCase(), "settings-form"]
    .filter(Boolean)
    .join("-");

  const renderDefaultActions = formikProps => {
    if (readOnly) return null;

    const isDisabled = !formikProps.dirty;
    return (
      <DialogActions>
        <ActionsSpinner processing={updating} />
        <Button type="reset" form={id} color="default" disabled={isDisabled}>
          Reset
        </Button>
        <Button type="submit" form={id} color="primary" disabled={isDisabled}>
          Save
        </Button>
      </DialogActions>
    );
  };

  const renderTitle = () => (
    <Typography
      color="primary"
      component="span"
      style={{
        fontSize: 17,
        marginRight: 24
      }}
    >
      {title}
    </Typography>
  );

  return (
    <Formik
      enableReinitialize
      initialValues={useDefaults ? defaultValues : data}
      onSubmit={data => onSubmit(data, isDefaults)}
      validate={validateForm(form.schema)}
    >
      {props => (
        <ExpansionPanel expanded={isExpanded} onChange={handleExpand}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Grid container alignItems="center">
              <Grid item style={{ display: "flex" }}>
                {renderTitle()}
              </Grid>
              <Grid item xs style={{ display: "flex", flexWrap: "nowrap" }}>
                <ContentfulId id={contentfulId}>
                  {contentfulData?.videoLink && (
                    <WatchVideoButton url={contentfulData.videoLink} />
                  )}
                  {contentfulData?.articleLink && (
                    <InfoButton url={contentfulData.articleLink} />
                  )}
                </ContentfulId>
              </Grid>
              <Grid item>{renderSwitch(props)}</Grid>
            </Grid>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails style={{ paddingTop: 0 }}>
            <Form
              {...props}
              form={form}
              useDefaults={isDefaults}
              readOnly={readOnly}
              defaultValues={defaultValues}
              id={id}
              onChange={onChange}
            />
          </ExpansionPanelDetails>
          {renderActions ? renderActions(props) : renderDefaultActions(props)}
        </ExpansionPanel>
      )}
    </Formik>
  );
}
