export const styles = theme => ({
  root: {
    display: "flex",
    alignItems: "flex-end",
    minHeight: 48,
    marginTop: -12,
    marginBottom: 8,
    flexWrap: "wrap"
  },
  filters: {
    display: "flex",
    alignItems: "flex-end",
    flexWrap: "wrap"
  },
  filter: {
    display: "flex",
    alignItems: "flex-end",
    flexWrap: "nowrap"
  },
  actions: {
    display: "flex",
    marginTop: 8
  },
  actionButton: {
    color: theme.palette.primary.light,
    height: 28,
    padding: "0 8px",
    marginRight: 5,
    whiteSpace: "nowrap",
    minWidth: 70,
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      minWidth: 40
    }
  },
  closeButton: {
    marginRight: theme.spacing(),
    padding: 4,
    color: "#888"
  },
  closeIcon: {
    fontSize: 20
  },
  textField: {
    margin: 0,
    minWidth: 170,
    fontSize: "11pt",
    [theme.breakpoints.down("sm")]: {
      minWidth: 125,
      width: 125
    }
  },
  selectField: {
    minWidth: 170,
    [theme.breakpoints.down("sm")]: {
      minWidth: 125
    }
  },
  dateField: {
    minWidth: 90,
    maxWidth: 150,
    [theme.breakpoints.down("sm")]: {
      minWidth: 125
    }
  },
  selectLabel: {
    top: -1
  },
  select: {
    paddingTop: 0,
    marginTop: 3
  },
  dropdownListItem: {
    color: theme.palette.primary.main,
    "&:focus, &:hover": {
      color: theme.palette.primary.light,
      fontWeight: "bold"
    }
  }
});
