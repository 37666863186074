import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  paperWrapper: {
    display: "flex",
    alignItems: "center"
  },
  paper: {
    display: "flex",
    margin: "5px 0",
    paddingLeft: 10,
    alignItems: "center",
    width: 723,
    position: "relative"
  },
  rowIndex: {
    width: 30,
    height: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#cfcfcf",
    borderRadius: 15,
    color: "#777",
    fontSize: 16
  },
  rowContainer: {
    display: "inline-flex",
    padding: 10
  }
});
